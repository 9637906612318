import { useTranslation } from 'react-i18next';

import { Box, Grid, Typography } from '@mui/material';
import {
  GridView as GridViewIcon,
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import { statusFilters, getStatusIcon, invoiceStatusCode } from 'src/dashboard-new/utils';
import { invoiceStatusesKeys } from 'src/config';
import styles from './style';

interface PropTypes {
  selectedStatus: string;
  loading: boolean;
  handleOpenFilters: () => void;
  handleStatusFilterChange: (_s: string) => void;
}

const DocumentsTableHeader = (props: PropTypes) => {
  const { ready, t } = useTranslation();
  const { user } = useAuth();

  const isToValidateStatusAllowed = user?.customisations.includes('toValidateStatus');
  const isSplitAllowed = user?.integrations.includes('split-document');

  const { selectedStatus, handleStatusFilterChange } = props;

  return (
    <Grid sx={styles.filtersRow}>
      <Box sx={styles.statusBtnsContainer} className="hide-scroll">
        {statusFilters.map((filter) => {
          if (isToValidateStatusAllowed && filter.id === invoiceStatusesKeys.reviewed) {
            return null;
          }
          if (!isToValidateStatusAllowed && filter.id === invoiceStatusesKeys.tovalidate) {
            return null;
          }
          if (!isSplitAllowed && filter.id === invoiceStatusesKeys.splitRequest) {
            return null;
          }

          const isSelected = selectedStatus === filter.id;

          return (
            <Box
              key={filter.id}
              sx={{
                ...styles.statusBtn,
                ...(isSelected && styles.statusBtnActive),
              }}
              onClick={() => handleStatusFilterChange(filter.id)}
            >
              <Box sx={styles.statusBtnContent}>
                {filter.id === invoiceStatusesKeys.all ? (
                  <Box sx={styles.statusIcon}>
                    <GridViewIcon sx={{ color: '#3E8AFF', ...styles.statusIcon }} />
                  </Box>
                ) : (
                  <Box sx={styles.statusIcon}>
                    {getStatusIcon(invoiceStatusCode[filter.id])}
                  </Box>
                )}

                <Typography
                  sx={{
                    ...styles.statusText,
                    ...(isSelected && { color: '#3E8AFF' }),
                  }}
                >
                  {ready && t(filter.label)}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Grid >
  )
}

export default DocumentsTableHeader
