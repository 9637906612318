import theme from "src/themeNew";

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0',
    margin: '0px',
    '& .MuiDialog-paper': {
      margin: '0px',
      borderRadius: '10px',
    },
    '& .MuiPaper-root': {
      height: '100%',
      maxHeight: '100%',
    }
  },
  container: {
    height: '100%',
    width: '500px',
    display: 'flex',
    flexDirection: 'column',
  },
  modalHeader: {
    height: '72px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderBottom: '1px solid #4C4E641F',
    padding: '16px',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerTitle: {
    fontSize: '18px',
    fontWeight: '500',
    color: theme.palette.text.primary,
    lineHeight: '28px',
    letter: '-0.25px',
  },
  closeBtn: {
    padding: '0px',
  },
  closeIcon: {
    height: '18px',
    width: '18px',
    color: theme.palette.text.secondary,
  },
  modalContent: {
    flex: 1,
    padding: '20px 24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px'
  },
  filterRow: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '36px',
  },
  filterLabel: {
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '32px',
    letterSpacing: '-0.25px',
    color: theme.palette.text.primary,
  },
  autoComplete: {
    width: '70%',
    '& .MuiOutlinedInput-root': {
      padding: '0px 2px 0px 0px !important',
      backgroundColor: theme.palette.background.white,
      '& fieldset': {
        border: `1px solid ${theme.palette.border.input} !important`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.border.input} !important`,
      },
    }
  },
  autoCompleteTextField: {
    '& .MuiOutlinedInput-input': {
      fontSize:'14px',
      color: theme.palette.text.primary,
    }
  },
  dropZoneContainer: {
    border: `2px dashed ${theme.palette.primary.main}`,
    minHeight: '256px',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
  },
  uploadIcon: {
    color: theme.palette.text.secondary,
    height: '32px',
    width: '32px',
  },
  dropZoneTitle: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '22px',
    color: theme.palette.text.primary,
  },
  dropZoneOr: {
    fontSize: '15px',
    fontWeight: '500',
    lineHeight: '22px',
    color: theme.palette.text.disabled,
  },
  clickToUploadBtn: {
    color: theme.palette.primary.main,
    fontSize: '13px',
    lineHeight: '18px',
    minWidth: '156px',
    height: '34px',
    textTransform: 'none',
    borderRadius: '4px',
    border: `1px ${theme.palette.primary.main} solid`,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      border: `1px ${theme.palette.primary.main} solid`,
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.light,
      border: `1px ${theme.palette.primary.main} solid`,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.light,
      border: `1px ${theme.palette.primary.main} solid`,
    },
    '&:disabled': {
      color: theme.palette.primary.main,
      border: `1px ${theme.palette.primary.main} solid`,
      opacity: 0.45,
    }
  },
  filesToUploadContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: '12px',
    width: '100%',
    flexWrap: 'wrap',
  },
  filesToUploadName: {
    fontSize: '13px',
    fontWeight: '500',
    lineHeight: '22px',
    color: theme.palette.text.disabled,
    textAlign: 'center',
  },
  dropZoneDescr: {
    fontSize: '13px',
    fontWeight: '500',
    lineHeight: '22px',
    color: theme.palette.text.disabled,
    textAlign: 'center',
  },
  modalFooter: {
    height: '102px',
    width: '100%',
    padding: '32px 20px',
    border: `1px solid ${theme.palette.border.footer}`,
    boxShadow: `0px 2px 6px 0px ${theme.palette.boxShadow.primary}`
  },
  uploadBtn: {
    minWidth: '108px',
    color: theme.palette.text.white,
    backgroundColor: theme.palette.primary.main,
    fontSize: '15px',
    lineHeight: '22px',
    height: '38px',
    textTransform: 'none',
    borderRadius: '4px',
    padding: '8px 22px',
    '&:hover': {
      color: theme.palette.text.white,
      backgroundColor: theme.palette.primary.dark,
    },
    '&:focus': {
      color: theme.palette.text.white,
      backgroundColor: theme.palette.primary.dark,
    },
    '&:active': {
      color: theme.palette.text.white,
      backgroundColor: theme.palette.primary.dark,
    },
    '&:disabled': {
      color: theme.palette.text.white,
      backgroundColor: theme.palette.primary.main,
      opacity: 0.45,
    }
  },
  spinner: {
    color: theme.palette.primary.main,
  },
}

export default styles;
