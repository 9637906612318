import { useContext, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Axios from 'axios';

import {
  Button, CircularProgress, Grid, IconButton, Modal, Tooltip, Typography
} from '@mui/material';
import { AddCircle as PlusIcon, HelpOutline as KBLinkIcon } from '@mui/icons-material';

import { appendContactSupport, axiosHeaders } from 'src/utils/helpers';
import ConfigContext from 'src/contexts/ConfigContext';
import { RootState } from 'src/types';
import { kbLinks } from 'src/dashboard-new/utils';
import SelectDocTypeItem from 'src/doc-types/components/SelectDocTypeItem/SelectDocTypeItem';
import styles from './style';

const SelectDocumentType = ({ open }: { open: boolean }) => {
  const { API, BRAND_NAME } = useContext(ConfigContext);
  const { t, ready, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const docTypesLoading: boolean = useSelector((state: RootState) => state.docTypes.docTypesLoading);
  const _docTypes: Record<string, any>[] = useSelector((state: RootState) => state.docTypes.docTypes);
  const documentTypes: Record<string, any>[] = useMemo(() => _docTypes, [_docTypes]);

  const [documentType, setDocumentType] = useState('');
  const [loading, setLoading] = useState(false);

  const confirmDocumentType = async () => {
    try {
      setLoading(true);

      const response = await Axios.get(
        `${API.selectDocumentType}${documentType}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(
          t('DOCUMENT_TYPE_SELECT_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );

        setTimeout(() => {
          setLoading(false);
          window.location.reload();
        }, 5000);
      }
    } catch (error) {
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, t('DOCUMENT_TYPE_SELECT_FAILED'), t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );

      setLoading(false);
    }
  };

  const openKBArticle = () => {
    window.open(
      `${kbLinks[BRAND_NAME]?.
        replace(
          '__LANG__',
          i18n.language.split('-')[0] !== 'en' ? `/${i18n.language.split('-')[0]}` : ''
        )}-article/doctype-manager-product-documentation`, '_blank'
      );
  };

  return (
    <Modal
      open={open}
      sx={styles.modal}
    >
      <Grid sx={styles.paperModal}>
        <Grid sx={styles.modalHeader}>
          <Grid sx={styles.headerRow}>
            <Typography
              variant="h2"
              sx={styles.header}
            >
              {ready && t('SELECT_DOCUMENT_TYPE_TITLE')}
            </Typography>
            <Tooltip title={t('DOC_TYPES_KB_LINK_TOOLTIP')}>
              <span>
                <IconButton sx={styles.kbIconBtn} onClick={() => openKBArticle()}>
                  <KBLinkIcon sx={styles.kbIcon} />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          <Typography
            variant="caption"
            sx={styles.captionHeader}
          >
            {ready && t('SELECT_DOCUMENT_TYPE_CAPTION')}
          </Typography>
        </Grid>
        <Grid sx={styles.modalBody}>
          <Grid container spacing={2} sx={styles.listContainer} className="y-scroll">
            <Grid
              item
              xs={6}
              md={4}
              lg={3}
              key={'create-new-doc-type'}
              onClick={() => navigate('/doc-types')}
            >
              <Grid sx={styles.listItem}>
                <Typography sx={{ ...styles.listItemTitle, WebkitLineClamp: 2 }}>
                  {ready && t('SELECT_DOCUMENT_TYPE_CREATE_NEW')}
                </Typography>
                <Grid sx={styles.plusIconContainer}>
                  <PlusIcon sx={styles.plusIcon} />
                </Grid>
              </Grid>
            </Grid>
            {
              documentTypes.map((item) => {
                if (!item.isActive) {
                  return null;
                }

                return (
                  <SelectDocTypeItem
                    key={item.documentType}
                    item={item}
                    isSelected={documentType === item.documentType}
                    selectDocType={() => setDocumentType(item.documentType)}
                  />
                );
              })
            }
          </Grid>
        </Grid>
        <Grid sx={styles.modalFooter}>
          {
            docTypesLoading || loading ? (
              <Grid sx={styles.action}>
                <CircularProgress size={20} />
              </Grid>
            ) : (
              <Button
                sx={styles.sendBtn}
                variant="contained"
                onClick={confirmDocumentType}
                disabled={!documentType}
              >
                {ready && t('DOCUMENT_TYPE_SELECT_CONFIRM')}
              </Button>
            )
          }
        </Grid>
      </Grid>
    </Modal>
  );
};

export default SelectDocumentType;
