import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Box, Button, CircularProgress, Grid, InputAdornment, TextField, Tooltip } from '@mui/material';
import {
  Search as SearchIcon,
  Settings as SettingsIcon,
  ListAlt as LogsIcon,
  FilterAlt as FilterIcon,
  Add as UploadIcon,
  FileDownload as DownloadAllIcon
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import ConfigureOverviewFields from 'src/documents-new/components/ConfigureOverviewFields/ConfigureOverviewFields';
import { isActionPermitted, permissions } from 'src/utils/helpers';
import { CompanyType, RootState } from 'src/types';
import styles from './style';

interface PropTypes {
  query: string;
  configureFieldsOpen: boolean;
  configureFieldsRef: any;
  isFiltersApplied: boolean;
  selectedCompaniesList: CompanyType[];
  loading: boolean;
  appliedDocTypes: string[];
  toggleConfigureFields: () => void;
  handleFieldsMenuClose: () => void;
  handleQueryChange: (_event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleOpenFilters: () => void;
  handleDownloadAll: () => void;
  handleUpload: (_files: File[] | null, _docType: string) => Promise<void>;
  handleUploadPopupOpen: () => void;
}

const SearchBar = (props: PropTypes) => {
  const { ready, t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();

  const {
    query,
    configureFieldsOpen,
    configureFieldsRef,
    isFiltersApplied,
    selectedCompaniesList,
    loading,
    appliedDocTypes,
    handleOpenFilters,
    toggleConfigureFields,
    handleFieldsMenuClose,
    handleQueryChange,
    handleDownloadAll,
    handleUpload: _handleUpload,
    handleUploadPopupOpen,
  } = props;

  const isPassportOrID = user?.documentType === 'id';
  
  const docTypesForUpload: Record<string, any>[] = useSelector((state: RootState) => state.docTypes.companyDocTypes);

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files) return;
    const fileArray = Array.from(files);
    _handleUpload(fileArray, docTypesForUpload[0]?.documentType);
  };

  return (
    <Grid sx={styles.searchBarRow}>
      <Box className="search-bar" sx={styles.searchBar}>
        <Tooltip title={t(isPassportOrID ? 'DASHBOARD_SEARCH_ID_SEARCH_TOOLTIP' : 'DASHBOARD_SEARCH_INVOICE_SEARCH_TOOLTIP')}>
          <TextField
            onChange={handleQueryChange}
            placeholder={t(isPassportOrID ? 'DASHBOARD_SEARCH_ID_SEARCH' : 'DASHBOARD_SEARCH_INVOICE_SEARCH')}
            value={query}
            variant="outlined"
            sx={{
              '&.MuiTextField-root': styles.searchBarTextField,
              '& .MuiInputBase-root': styles.searchBarInputRoot,
              '& .MuiInputBase-input': styles.searchBarInput,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ marginRight: '0px' }}>
                  <SearchIcon sx={styles.searchIcon} />
                </InputAdornment>
              ),
              sx: {
                '& .MuiInputBase-input': styles.searchInput,
              }
            }}
          />
        </Tooltip>
      </Box>
      <Box sx={styles.btnsContainer}>
        <Button
          onClick={handleOpenFilters}
          sx={isFiltersApplied ? styles.filterBtnActive : styles.filterBtn}
          startIcon={<FilterIcon sx={styles.filterIcon} />}
        >
          {t('DASHBOARD_INVOICES_FILTERED')}
        </Button>
        {/* Configure fields is shown only in documents list page */}
        {location.pathname.includes('/documents') && <Button
          ref={configureFieldsRef}
          startIcon={<SettingsIcon sx={styles.settingsIcon} />}
          sx={styles.settingsBtn}
          onClick={toggleConfigureFields}
        >
          {t('DASHBOARD_INVOICES_CONFIGURE_FIELDS')}
        </Button>}
        <ConfigureOverviewFields
          configureFieldsOpen={configureFieldsOpen}
          configureFieldsRef={configureFieldsRef}
          appliedDocTypes={appliedDocTypes}
          handleClose={handleFieldsMenuClose}
        />
        <Button
          startIcon={<LogsIcon sx={styles.logsIcon} />}
          onClick={() => navigate('/documents/logs')}
          sx={styles.logsBtn}
        >
          {t('DASHBOARD_INVOICES_LOGS')}
        </Button>
        {/* The download button is hidden until the endpoint is ready */}
        {false && <Tooltip title={t('DASHBOARD_DOWNLOAD_ALL_TOOLTIP')}>
          <Button
            startIcon={<DownloadAllIcon sx={styles.downloadAllIcon} />}
            onClick={handleDownloadAll}
            sx={styles.downloadAllBtn}
          >
            {t('DASHBOARD_DOWNLOAD_ALL_TITLE')}
          </Button>
        </Tooltip>}
        <Tooltip
          title={!isActionPermitted(permissions.invoiceUpload, user?.permissions) ? ready && t('INVOICE_UPLOAD_NO_PERMISSION')
            : user?.isUploadBlocked ? ready && t('INVOICE_UPLOAD_BLOCKED_LIMIT_REACHED_TOOLTIP')
              : selectedCompaniesList.length > 0 ? t('INVOICE_UPLOAD_DISABLED_COMPANY_FILTER')
                : ''}
        >
          <span>
            {docTypesForUpload.length === 1 ? (
              <Button
                sx={styles.uploadBtn}
                component="label"
                startIcon={!loading && <UploadIcon sx={styles.uploadIcon} />}
                disabled={!isActionPermitted(permissions.invoiceUpload, user?.permissions) || user?.isUploadBlocked || selectedCompaniesList.length > 0}
              >
                {loading ? (
                  <CircularProgress sx={styles.spinner} size={20} />
                ) : (
                  <>
                    <input
                      id="upload-input"
                      accept="image/png,image/jpeg,image/jpg,image/tiff,application/pdf,image/heic"
                      type="file"
                      multiple
                      onChange={handleUpload}
                      style={{ display: 'none' }}
                      required
                    />
                    {t('DASHBOARD_INVOICES_UPLOAD_INVOICE')}
                  </>
                )}
              </Button>
              ) : (
              <Button
                variant="contained"
                sx={styles.uploadBtn}
                onClick={handleUploadPopupOpen}
                startIcon={<UploadIcon sx={styles.uploadIcon} />}
                disabled={!isActionPermitted(permissions.invoiceUpload, user?.permissions) || user?.isUploadBlocked || selectedCompaniesList.length > 0}
              >
                {t('DASHBOARD_INVOICES_UPLOAD_INVOICE')}
              </Button>
            )}
          </span>
        </Tooltip>
      </Box>
    </Grid>

  )
}

export default SearchBar
