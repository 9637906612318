import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from 'redux';
import Axios from 'axios';

import { Box, Button, Checkbox, CircularProgress, ClickAwayListener, Grid, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import {
  DragIndicator as ReorderIcon,
} from '@mui/icons-material';

import { CustomError, FieldType, RootState } from 'src/types';
import { useConfig } from 'src/hooks/useConfig';
import { useAuth } from 'src/hooks/useAuth';
import { appendContactSupport, axiosHeaders } from 'src/utils/helpers';
import { fetchDocOverviewFields } from 'src/shared/redux/fields/fieldsActions';
import styles from './style';

interface PropTypes {
  configureFieldsOpen: boolean;
  configureFieldsRef: React.RefObject<HTMLDivElement>;
  appliedDocTypes: string[];
  handleClose: () => void;
}

const procysIDField: FieldType = {
  key: 'procysID',
  appDbField: 'procys_id',
  assistantKey: 'procys_id',
  dataType: 'text',
  exportMapKey: 'INVOICE_PROCYS_ID',
  label: 'INVOICE_LIST_TABLE_HEADER_ASST_ID',
  isActive: true,
  isMandatory: true,
  isAvailable: true,
  position: -3,
};

const statusField: FieldType = {
  key: 'status',
  appDbField: 'status',
  assistantKey: 'status',
  dataType: 'float',
  exportMapKey: 'INVOICE_STATUS',
  label: 'INVOICE_LIST_TABLE_HEADER_STATUS',
  isActive: true,
  isMandatory: true,
  isAvailable: true,
  position: -2,
};

const uploadedDateField: FieldType = {
  key: 'appUploadedDate',
  appDbField: 'uploaded_date',
  assistantKey: 'uploaded_date',
  dataType: 'date',
  exportMapKey: 'INVOICE_UPLOAD_DATE',
  label: 'INVOICE_EDIT_FORM_UPLOAD_DATE',
  isActive: true,
  isMandatory: true,
  isAvailable: true,
  position: -1,
};

const ConfigureOverviewFields = (props: PropTypes) => {
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { API } = useConfig();
  const { user } = useAuth();
  const [isSaving, setIsSaving] = useState(false);

  const { configureFieldsOpen, configureFieldsRef, appliedDocTypes, handleClose: _handleClose } = props;

  const docType: string = useSelector((state: RootState) => state.fields.docType);
  const _overViewFields: FieldType[] = useSelector((state: RootState) => state.fields.docOverviewFields);
  const overViewFields: FieldType[] = useMemo(() => _overViewFields, [_overViewFields]);

  const [fields, setFields] = useState<FieldType[]>(overViewFields.map((field, index) => ({ ...field, position: index + 1 })));

  useEffect(() => {
    setFields(overViewFields.map((field, index) => ({ ...field, position: index + 1 })));
  }, [overViewFields]);

  const handleClose = () => {
    setFields(overViewFields.map((field, index) => ({ ...field, position: index + 1 })));
    _handleClose();
  };

  const adjustFieldsOrder = (sourceIndex: number, destinationIndex?: number) => {
    const existingFields = [...fields];
    if (destinationIndex === undefined) {
      return;
    }
    const newFields = [];
    if (sourceIndex > destinationIndex) {
      for (let i = 0; i < existingFields.length; i++) {
        if (existingFields[i].position < sourceIndex && existingFields[i].position >= destinationIndex) {
          existingFields[i].position += 1;
          newFields.push(existingFields[i]);
        } else {
          if (existingFields[i].position === sourceIndex) {
            existingFields[i].position = destinationIndex;
            newFields.push(existingFields[i]);
          } else {
            newFields.push(existingFields[i]);
          }
        }
      }
    }

    if (sourceIndex < destinationIndex) {
      for (let i = 0; i < existingFields.length; i++) {
        if (existingFields[i].position > sourceIndex && existingFields[i].position <= destinationIndex) {
          existingFields[i].position -= 1;
          newFields.push(existingFields[i]);
        } else {
          if (existingFields[i].position === sourceIndex) {
            existingFields[i].position = destinationIndex;
            newFields.push(existingFields[i]);
          } else {
            newFields.push(existingFields[i]);
          }
        }
      }
    }

    setFields(newFields);
  };

  const handleCheckboxChange = (key: string) => {
    const newFields = fields.map((field) => {
      if (field.key === key) {
        return { ...field, isActive: !field.isActive };
      }
      return field;
    });
    setFields(newFields);
  };

  const saveFieldsSelection = async () => {
    if (isSaving) {
      return;
    }
    setIsSaving(true);

    const selectedFields = fields.filter((f) => f.isActive).map((field) => ({ name: field.key, position: field.position }));
    try {
      const response = await Axios.post(
        `${API.fieldsV2}/doc-types/${appliedDocTypes.length > 0 ? appliedDocTypes[0] : docType}/sections/overview/${user?.companyID}`,
        { fields: selectedFields },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        _handleClose();
        dispatch(fetchDocOverviewFields(user?.companyID || '', appliedDocTypes.length > 0 ? appliedDocTypes[0] : docType) as unknown as UnknownAction);
        enqueueSnackbar(
          t('SAVE_FIELDS_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
      }
    } catch (e) {
      const error = e as CustomError;
      let errorMessage = appendContactSupport(window.config.support_email, t('SAVE_FIELDS_FAILURE'), t);
      if (error && error.response && error.response.data) {
        errorMessage = t(error.response.data.i18n || '');
      }

      enqueueSnackbar(
        errorMessage,
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Popper
      open={configureFieldsOpen}
      anchorEl={configureFieldsRef?.current}
      transition
      sx={styles.root}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <Grid>
                <DragDropContext onDragEnd={(e) => adjustFieldsOrder(e?.source?.index, e?.destination?.index)}>
                  <Droppable droppableId="droppable" direction="vertical">
                    {(droppableProvided) => (
                      <MenuList
                        id="configure-fields-menu"
                        className="y-scroll"
                        autoFocusItem
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                        sx={styles.fieldsContainer}
                      >
                        {[procysIDField, statusField, uploadedDateField, ...fields
                          .filter((f) => f.key !== 'status' && f.key !== 'appUploadedDate' && f.isAvailable)
                          .sort((a, b) => (a.position > b.position ? 1 : -1))]
                          .map((field) => (
                            <Draggable
                              key={field.key}
                              draggableId={field.key.toString()}
                              index={field.position}
                              isDragDisabled={field.key === 'status' || field.key === 'appUploadedDate'}
                            >
                              {(draggableProvided) => (
                                <MenuItem
                                  key={field.key}
                                  sx={styles.fieldItem}
                                  ref={draggableProvided.innerRef}
                                  onClick={() => handleCheckboxChange(field.key)}
                                  {...draggableProvided.draggableProps}
                                >
                                  <Box sx={styles.iconContainer} {...draggableProvided.dragHandleProps}>
                                    <ReorderIcon sx={styles.icon} />
                                  </Box>
                                  <Checkbox
                                    sx={styles.checkBox}
                                    checked={Boolean(field.isActive) || field.key === 'status' || field.key === 'appUploadedDate'}
                                    disabled={field.key === 'procysID' || field.key === 'status' || field.key === 'appUploadedDate'}
                                  />
                                  {ready && t(field.label)}
                                </MenuItem>
                              )}
                            </Draggable>
                          ))
                        }
                        {droppableProvided.placeholder}
                      </MenuList>
                    )}
                  </Droppable>
                </DragDropContext>
                <Box sx={styles.footer}>
                  <Button
                    sx={styles.cancelButton}
                    onClick={handleClose}
                  >
                    {ready && t('DASHBOARD_INVOICES_CANCEL')}
                  </Button>
                  <Button
                    sx={styles.saveButton}
                    onClick={saveFieldsSelection}
                    disabled={isSaving}
                    startIcon={isSaving ? <CircularProgress size={20} color="inherit" /> : null}
                  >
                    {ready && t('MANAGE_FIELD_SAVE')}
                  </Button>
                </Box>
              </Grid>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )
}

export default ConfigureOverviewFields
