import { useContext, useState, useMemo } from 'react';
import { UnknownAction } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Axios from 'axios';

import {
  CircularProgress, Grid, IconButton, Modal, Tooltip, Typography
} from '@mui/material';
import { HelpOutline as KBLinkIcon } from '@mui/icons-material';

import { appendContactSupport, axiosHeaders } from 'src/utils/helpers';
import { AuthContext } from 'src/contexts/AuthContext';
import ConfigContext from 'src/contexts/ConfigContext';
import { RootState } from 'src/types';
import { kbLinks } from 'src/dashboard-new/utils';
import { fetchDocTypesForImport, fetchCompanyDocTypes } from 'src/doc-types/actions';
import SelectDocTypeItem from '../SelectDocTypeItem/SelectDocTypeItem';
import SubmitBtn from 'src/shared/buttons/SubmitBtn';
import CancelBtn from 'src/shared/buttons/CancelBtn';
import EmptyText from 'src/shared/components/EmptyText/EmptyText';
import styles from './style';

interface PropTypes {
  open: boolean;
  handleClose: () => void;
  getDocTypes: (_pageNo?: number, _pageLimit?: number) => Promise<void>;
}

const ImportDocumentTypes = (props: PropTypes) => {
  const { API, BRAND_NAME, IS_BRAND_PROCYS } = useContext(ConfigContext);
  const { t, ready, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const { user } = useContext(AuthContext);

  const { open, handleClose, getDocTypes } = props;

  const docTypesLoading: boolean = useSelector((state: RootState) => state.docTypes.docTypesLoading);
  const _docTypesForImport: Record<string, any>[] = useSelector((state: RootState) => state.docTypes.docTypesForImport);
  const docTypesForImport: Record<string, any>[] = useMemo(() => _docTypesForImport, [_docTypesForImport]);

  const [selectedDocTypes, setSelectedDocTypes] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const confirmDocumentType = async () => {
    try {
      setLoading(true);

      const body = {
        documentTypes: selectedDocTypes,
        company: user?.companyID
      };

      const response = await Axios.post(
        `${API.importDocumentTypes}`,
        body,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(
          t('DOCUMENT_TYPE_IMPORT_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );

        dispatch(fetchCompanyDocTypes() as unknown as UnknownAction);
        dispatch(fetchDocTypesForImport() as unknown as UnknownAction);
        setSelectedDocTypes([]);
        getDocTypes();
        handleClose();
        setLoading(false);
      }
    } catch (error) {
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, t('DOCUMENT_TYPE_IMPORT_FAILED'), t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );

      setLoading(false);
    }
  };

  const openKBArticle = () => {
    window.open(
      `${kbLinks[BRAND_NAME]?.
        replace(
          '__LANG__',
          i18n.language.split('-')[0] !== 'en' ? `/${i18n.language.split('-')[0]}` : ''
        )}-article/doctype-manager-product-documentation`, '_blank'
      );
  };

  const onDocTypeSelect = (docType: string) => {
    if (selectedDocTypes.includes(docType)) {
      setSelectedDocTypes(selectedDocTypes.filter((item) => item !== docType));
    } else {
      setSelectedDocTypes([...selectedDocTypes, docType]);
    }
  }

  return (
    <Modal
      open={open}
      sx={styles.modal}
    >
      <Grid sx={styles.paperModal}>
        <Grid sx={styles.modalHeader}>
          <Grid sx={styles.headerRow}>
            <Typography
              variant="h2"
              sx={styles.header}
            >
              {ready && t('IMPORT_DOCUMENT_TYPE_TITLE')}
            </Typography>
            {IS_BRAND_PROCYS && (
              <Tooltip title={t('DOC_TYPES_KB_LINK_TOOLTIP')}>
                <span>
                  <IconButton sx={styles.kbIconBtn} onClick={() => openKBArticle()}>
                    <KBLinkIcon sx={styles.kbIcon} />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </Grid>
          <Typography
            variant="caption"
            sx={styles.captionHeader}
          >
            {ready && t('IMPORT_DOCUMENT_TYPE_CAPTION')}
          </Typography>
        </Grid>
        <Grid sx={styles.modalBody}>
          {docTypesForImport.length > 0 ? (
            <Grid container spacing={2} sx={styles.listContainer} className="y-scroll">
              {
                docTypesForImport.map((item) => {
                  if (!item.isActive) {
                    return null;
                  }

                  return (
                    <SelectDocTypeItem
                      key={item.documentType}
                      item={item}
                      isSelected={selectedDocTypes.includes(item.documentType)}
                      selectDocType={() => onDocTypeSelect(item.documentType)}
                    />
                  );
                })
              }
            </Grid>
          ) : <EmptyText str={'DOCUMENT_TYPE_IMPORT_EMPTY_TEXT'} />}
        </Grid>
        <Grid sx={styles.modalFooter}>
          {
            docTypesLoading || loading ? (
              <Grid sx={styles.action}>
                <CircularProgress size={20} />
              </Grid>
            ) : (
              <SubmitBtn
                title={t('DOCUMENT_TYPE_SELECT_CONFIRM')}
                disabled={selectedDocTypes.length < 1}
                onClick={confirmDocumentType}
              />
            )
          }
          <CancelBtn
            title={t('DOC_TYPES_TABLE_ADD_NEW_CANCEL')}
            onClick={handleClose}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ImportDocumentTypes;
