import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box, Button, IconButton, Tooltip, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem
} from '@mui/material';
import {
  ArrowDropDown as ArrowDropDownIcon,
  NotInterested as RejectIcon,
  ArrowForward as ArrowForwardIcon,
  MailOutline as SendToEmailIcon,
  Inventory as ArchiveIcon,
  DeleteOutline as DeleteIcon,
  Save as SaveIcon,
  ChevronLeft as ArrowLeftIcon,
  ChevronRight as ArrowRightIcon,
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import { DocumentDeliveryFormatType, FieldType, DocType } from 'src/types';
import { isActionPermitted, permissions } from 'src/utils/helpers';
import { getIsError } from 'src/document-edit/utils';
import { documentTypes } from 'src/config';
import styles from './style';

interface PropTypes {
  doc: DocType;
  details: Record<string, string>;
  renderedFields: FieldType[];
  statusChangeLoading: boolean;
  isSubmitClicked: boolean;
  deliverFormats: DocumentDeliveryFormatType[];
  submitting: boolean;
  previousInvoice: DocType | null;
  nextInvoice: DocType | null;
  decimalSeparator: string;
  handleSubmit: () => void;
  handleAutoSaveInvoice: (_toStatus?: number) => void;
  handleSelectStatus: (_status: number, _id: number) => void;
  handleDataFormatToExportClick: (_format: DocumentDeliveryFormatType) => void;
  setSendEmailModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleNavigateInvoice: (_inv: DocType | null, _direction: number) => void;
  moveNext?:Function;
  movePrevious?:Function;
  disableMove?:boolean[];
}

const DocumentEditActionButtons = (props: PropTypes) => {
  const { ready, t } = useTranslation();

  const {
    doc,
    details,
    renderedFields,
    statusChangeLoading,
    isSubmitClicked,
    deliverFormats,
    submitting,
    previousInvoice,
    nextInvoice,
    decimalSeparator,
    handleSubmit,
    handleAutoSaveInvoice,
    handleSelectStatus,
    handleDataFormatToExportClick,
    setSendEmailModalOpen,
    handleNavigateInvoice,
    moveNext,
    movePrevious,
    disableMove,
  } = props;


  const { user } = useAuth();

  const isToValidateStatusAllowed = user?.customisations.includes('toValidateStatus') || false;
  const isCanDelete = user?.customisations.includes('allowDelete') || false;
  const [dataFormatOptionsToExportOpen, setDataFormatOptionsToExportOpen] = useState(false);
  const dataFormatOptionsToExportRef = useRef(null);
  const isTwinfield = user?.accountingSystem === 'twinfield';
  const isHacienda = user?.integrations.includes('hacienda-certification');

  const invalidTwinfieldSupplier = (isTwinfield
    && (details.documentType === documentTypes.invoice
    || details.documentType === documentTypes.creditNote)
    && !details.supplier);

  const amountRegex = new RegExp(`^-?\\d*[${decimalSeparator === '.' ? '.' : `.${decimalSeparator}`}]?\\d+$`);
  const email = /^[^\s@]+@[^\s@]+\.[^\s@]{1,}$/;
  const urlRegex = /^[-a-zA-Z0-9@:/%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/;
  const vatRegex = /^[A-Z0-9]+$/;
  const currencyRegex = /^[A-Za-z]{2,3}$/;
  const numbersOnly = /^\d+$/;

  const isAutoSaveEnabled = user?.customisations.includes('autoSaveDocument');

  const isBtnDisabledClosedPeriod = isHacienda && doc.closedPeriod;

  const isButtonDisabled = () => doc.status < 500
    || submitting
    || renderedFields.some((field) => getIsError(field.key, field.dataType, details[field.key], user, decimalSeparator));

  const isSubmitBtnDisabled = () => {
    if (submitting) return true;
    if (isAutoSaveEnabled && isActionPermitted(permissions.invoiceSave, user?.permissions)) {
      return false;
    }
    if (isBtnDisabledClosedPeriod) return true;
    return isSubmitClicked || isButtonDisabled() || !isActionPermitted(permissions.invoiceSave, user?.permissions);
  };

  const getTooltipTitle = () => (
    ((details.senderEmail !== undefined && details.senderEmail !== '')
      && (!email.test(details.senderEmail)
        && t('INVOICE_EDIT_FORM_SAVE_TOOLTIP_INVALID_EMAIL')))
    || ((details.senderKvk !== undefined
        && details.senderKvk !== '')
        && (!numbersOnly.test(details.senderKvk)
        && t('INVOICE_EDIT_FORM_SAVE_TOOLTIP_INVALID_KVK')))
    || ((details.senderWebsite !== undefined
        && details.senderWebsite !== '')
        && (!urlRegex.test(details.senderWebsite)
        && t('INVOICE_EDIT_FORM_SAVE_TOOLTIP_INVALID_URL')))
    || ((details.senderVatNumber !== undefined
        && details.senderVatNumber !== '')
        && (details.senderVatNumber === ''
        || (!vatRegex.test(details.senderVatNumber)
        && t('INVOICE_EDIT_FORM_SAVE_TOOLTIP_INVALID_VAT'))))
    || ((details.amount !== undefined
        && details.amount !== '')
        && (!amountRegex.test(details.amount)
        && t('INVOICE_EDIT_FORM_SAVE_TOOLTIP_INVALID_AMOUNT')))
    || ((details.vatAmount !== undefined
        && details.vatAmount !== '')
        && (!amountRegex.test(details.vatAmount)
        && t('INVOICE_EDIT_FORM_SAVE_TOOLTIP_INVALID_VAT_AMOUNT')))
    || ((details.discountAmount !== undefined
        && details.discountAmount !== '')
        && (!amountRegex.test(details.discountAmount)
        && t('INVOICE_EDIT_FORM_SAVE_TOOLTIP_INVALID_DISCOUNT_AMOUNT')))
    || ((details.currency !== undefined
        && details.currency !== '')
        && (!currencyRegex.test(details.currency)
        && t('INVOICE_EDIT_FORM_SAVE_TOOLTIP_INVALID_CURRENCY')))
    || (renderedFields.some((field) => (field.dataType === 'date' && getIsError(field.key, field.dataType, details[field.key], user, decimalSeparator)))
      && t('INVOICE_EDIT_FORM_SAVE_TOOLTIP_INVALID_DATE'))
    || (details?.description?.length > 64
        && t('INVOICE_EDIT_FORM_SAVE_TOOLTIP_INVALID_DESCRIPTION'))
    || (invalidTwinfieldSupplier && t('INVOICE_EDIT_FORM_SAVE_TOOLTIP_INVALID_SUPPLIER'))
    || (isBtnDisabledClosedPeriod && t('INVOICE_EDIT_FORM_SAVE_TOOLTIP_CLOSED_PERIOD'))
    || (isSubmitClicked && t('INVOICE_EDIT_FORM_SAVE_TOOLTIP_NO_EDIT'))
    || t('NO_WHITE_SPACE_BTN_TOOLTIP')
  );

  const handleDataformatOptionsToExportToggle = () => {
    setDataFormatOptionsToExportOpen((prevOpen: boolean) => !prevOpen);
  };

  const handleDataformatOptionsToExportClose = () => {
    setDataFormatOptionsToExportOpen(false);
  };

  const handleSave = async () => {
    try {
      await handleSubmit();
    } catch (e) {
      // do nothing
    }
  };

  const saveBtn = () => (
    <Tooltip
      title={!isActionPermitted(permissions.invoiceSave, user?.permissions)
        ? t('INVOICE_EDIT_FORM_SAVE_TOOLTIP_PERMISSION')
        : (isSubmitBtnDisabled())
          ? getTooltipTitle()
          : t('INVOICE_EDIT_FORM_SAVE_BTN_TOOLTIP')}
    >
      <div>
        <Button
          variant="outlined"
          sx={styles.saveButton}
          onClick={handleSave}
          disabled={isSubmitBtnDisabled()}
        >
          <SaveIcon sx={styles.saveButtonIcon} />
        </Button>
      </div>
    </Tooltip>
  );

  const sendToValidateBtn = () => (
    <Tooltip
      title={!isActionPermitted(permissions.invoiceSave, user?.permissions)
        ? t('INVOICE_EDIT_FORM_REVIEW_TOOLTIP_PERMISSION')
        : isBtnDisabledClosedPeriod
          ? t('ACTION_DISABLED_PERIOD_CLOSED_HACIENDA')
          : ''}
    >
      <div>
        <Button
          sx={styles.sendToValidateButton}
          variant="contained"
          onClick={() => handleAutoSaveInvoice(501)}
          disabled={isBtnDisabledClosedPeriod || isButtonDisabled() || !isActionPermitted(permissions.invoiceReview, user?.permissions)}
          endIcon={<ArrowForwardIcon sx={styles.arrowForwardIcon} />}
        >
          {ready && t('INVOICE_EDIT_FORM_REVIEW')}
        </Button>
      </div>
    </Tooltip>
  );

  const validateBtn = () => (
    <Tooltip
      title={!isActionPermitted(permissions.invoiceValidate, user?.permissions)
        ? t('INVOICE_EDIT_FORM_VALIDATE_TOOLTIP_PERMISSION')
        : isBtnDisabledClosedPeriod
          ? t('ACTION_DISABLED_PERIOD_CLOSED_HACIENDA')
          : ''}
    >
      <div>
        <Button
          sx={styles.validateButton}
          variant="contained"
          onClick={() => handleAutoSaveInvoice(502)}
          disabled={isBtnDisabledClosedPeriod
            || isButtonDisabled()
            || !isActionPermitted(permissions.invoiceValidate, user?.permissions)
            || statusChangeLoading
          }
          endIcon={<ArrowForwardIcon sx={styles.arrowForwardIcon} />}
        >
          {ready && t('INVOICE_EDIT_FORM_VALIDATE')}
        </Button>
      </div>
    </Tooltip>
  );

  const readyToExportBtn = () => (
    <Tooltip
      title={!isActionPermitted(permissions.invoiceExport, user?.permissions)
        ? t('INVOICE_EXPORT_TOOLTIP_NO_PERMISSION')
        : isBtnDisabledClosedPeriod
          ? t('ACTION_DISABLED_PERIOD_CLOSED_HACIENDA')
          : ''}
    >
      <div>
        <Button
          sx={styles.sendToValidateButton}
          variant="contained"
          onClick={() => handleAutoSaveInvoice(502)}
          disabled={isBtnDisabledClosedPeriod
            || isButtonDisabled()
            || !isActionPermitted(permissions.invoiceValidate, user?.permissions)
            || statusChangeLoading
          }
          endIcon={<ArrowForwardIcon sx={styles.arrowForwardIcon} />}
        >
          {ready && t('INVOICE_EDIT_FORM_READY_TO_EXPORT')}
        </Button>
      </div>
    </Tooltip>
  );

  const sendToEmailBtn = () => (
    <Tooltip
      title={!isActionPermitted(permissions.invoiceExport, user?.permissions)
        ? t('INVOICE_EXPORT_TOOLTIP_NO_PERMISSION')
        : t('INVOICE_SEND_TO_EMAIL_TOOLTIP')}
    >
      <div>
        <Button
          sx={styles.sendToEmailBtn}
          variant="contained"
          onClick={() => setSendEmailModalOpen(true)}
          disabled={isButtonDisabled() || !isActionPermitted(permissions.invoiceExport, user?.permissions)}
        >
          <SendToEmailIcon sx={styles.sendToEmailIcon} />
        </Button>
      </div>
    </Tooltip>
  );

  const exportBtn = () => (
    <>
      <Tooltip
        title={!isActionPermitted(permissions.invoiceExport, user?.permissions)
          ? t('INVOICE_EXPORT_TOOLTIP_NO_PERMISSION')
          : ''}
      >
        <div>
          <Button
            sx={styles.exportBtn}
            variant="contained"
            onClick={handleDataformatOptionsToExportToggle}
            disabled={isButtonDisabled() || !isActionPermitted(permissions.invoiceExport, user?.permissions)}
            endIcon={<ArrowDropDownIcon />}
            ref={dataFormatOptionsToExportRef}
          >
            {ready && t('INVOICE_EXPORT_BTN')}
          </Button>
        </div>
      </Tooltip>
      <Popper
        open={dataFormatOptionsToExportOpen}
        anchorEl={dataFormatOptionsToExportRef.current}
        role={undefined}
        transition
        style={{ zIndex: 111111 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleDataformatOptionsToExportClose}>
                <MenuList autoFocusItem>
                  {deliverFormats.map((option) => {
                    if (!option.exportViaApp) {
                      return null;
                    }

                    return (
                      <Tooltip
                        key={option.id}
                        title={(option.key === 'gstock' && doc.exportedToGstock) ? t('GSTOCK_EXPORT_DISABLED_TOOLTIP') : ''}
                        placement="left"
                      >
                        <span>
                          <MenuItem
                            key={option.id}
                            onClick={() => { setDataFormatOptionsToExportOpen(false); handleDataFormatToExportClick(option); }}
                            sx={styles.exportOptionBtn}
                            disabled={option.key === 'gstock' && doc.exportedToGstock}
                          >
                            {`${t('INVOICE_EDIT_FORM_EXPORT_TO')} ${option.label}`}
                          </MenuItem>
                        </span>
                      </Tooltip>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );

  const rejectBtn = () => (
    <Tooltip
      title={!isActionPermitted(permissions.invoiceValidate, user?.permissions)
        ? t('INVOICE_EDIT_FORM_REJECT_TOOLTIP_PERMISSION')
        : isBtnDisabledClosedPeriod
          ? t('ACTION_DISABLED_PERIOD_CLOSED_HACIENDA')
          : t('INVOICE_EDIT_FORM_REJECT_TOOLTIP')}
    >
      <div>
        <Button
          sx={styles.rejectBtn}
          variant="outlined"
          onClick={() => handleSelectStatus(510, doc.id || 0)}
          disabled={isBtnDisabledClosedPeriod
            || isButtonDisabled()
            || !isActionPermitted(permissions.invoiceValidate, user?.permissions)
            || statusChangeLoading
          }
        >
          <RejectIcon sx={styles.rejectIcon} />
        </Button>
      </div>
    </Tooltip>
  );

  const archiveBtn = () => (
    <Tooltip title={!isActionPermitted(permissions.invoiceArchive, user?.permissions)
      ? t('INVOICE_EDIT_FORM_ARCHIVE_TOOLTIP_PERMISSION')
      : isBtnDisabledClosedPeriod
        ? t('ACTION_DISABLED_PERIOD_CLOSED_HACIENDA')
        : t('INVOICE_EDIT_FORM_ARCHIVE_TOOLTIP')}
    >
      <Button
        variant="outlined"
        sx={styles.archiveBtn}
        onClick={() => handleSelectStatus(600, doc.id || 0)}
        disabled={isBtnDisabledClosedPeriod || isButtonDisabled() || !isActionPermitted(permissions.invoiceArchive, user?.permissions) || statusChangeLoading}
      >
        <ArchiveIcon sx={styles.archiveIcon} />
      </Button>
    </Tooltip>
  );

  const deleteBtn = () => (
    isCanDelete && <Tooltip title={!isActionPermitted(permissions.invoiceDelete, user?.permissions)
      ? t('INVOICE_EDIT_FORM_DELETE_TOOLTIP_PERMISSION')
      : isBtnDisabledClosedPeriod
        ? t('ACTION_DISABLED_PERIOD_CLOSED_HACIENDA')
        : t('INVOICE_EDIT_FORM_DELETE_TOOLTIP')}
    >
      <Button
        variant="outlined"
        sx={styles.deleteBtn}
        onClick={() => handleSelectStatus(900, doc.id || 0)}
        disabled={isBtnDisabledClosedPeriod
          || isButtonDisabled()
          || !isActionPermitted(permissions.invoiceDelete, user?.permissions)
          || statusChangeLoading
        }
      >
        <DeleteIcon sx={styles.deleteIcon} />
      </Button>
    </Tooltip>
  );

  return (
    <Box mt={3} sx={styles.buttonContainer}>
      <Tooltip title={disableMove && Array.isArray(disableMove) && disableMove[0] === true ?
        t('INVOICE_EDIT_FORM_NO_PREV_TOOLTIP') : Array.isArray(disableMove)?"": previousInvoice === null ? t('INVOICE_EDIT_FORM_NO_PREV_TOOLTIP') : ''}>
        <div>
          <IconButton
            sx={styles.navButton}
            onClick={disableMove
              ? (() => movePrevious? movePrevious(): function(){})
              : (() => handleNavigateInvoice(previousInvoice, -1))}
            disabled={disableMove && Array.isArray(disableMove) ? disableMove[0] :Array.isArray(disableMove)?false: previousInvoice === null}
          >
            <ArrowLeftIcon style={styles.btnIconArrowLeft} />
          </IconButton>
        </div>
      </Tooltip>
      <Tooltip title={disableMove && Array.isArray(disableMove) && disableMove[1] ?
        t('INVOICE_EDIT_FORM_NO_NEXT_TOOLTIP') : Array.isArray(disableMove)?"": nextInvoice === null ?
        t('INVOICE_EDIT_FORM_NO_NEXT_TOOLTIP') : ''}>
        <div>
          <IconButton
            sx={styles.navButton}
            onClick={disableMove
              ? (()=> moveNext ? moveNext() : function(){})
              : (()=>handleNavigateInvoice(nextInvoice, +1))}
            disabled={disableMove && Array.isArray(disableMove)? disableMove[1] : Array.isArray(disableMove)?false: nextInvoice === null}
          >
            <ArrowRightIcon style={styles.btnIconArrowRight} />
          </IconButton>
        </div>
      </Tooltip>
      {doc.status === 500 &&
        <>
          {saveBtn()}
          {isToValidateStatusAllowed ?
            sendToValidateBtn() :
            <>
              {rejectBtn()}
              {readyToExportBtn()}
            </>
          }
        </>
      }
      {doc.status === 501 &&
        <>
          {saveBtn()}
          {isToValidateStatusAllowed &&
            <>
              {rejectBtn()}
              {validateBtn()}
            </>
          }
        </>
      }
      {doc.status === 502 &&
        <>
          {saveBtn()}
          {rejectBtn()}
          {!user?.isDocunecta && sendToEmailBtn()}
          {exportBtn()}
        </>
      }
      {doc.status === 503 &&
        <>
          {archiveBtn()}
          {!user?.isDocunecta && sendToEmailBtn()}
          {exportBtn()}
        </>
      }
      {doc.status === 509 &&
        <>
          {saveBtn()}
          {!isToValidateStatusAllowed &&
            <>
              {rejectBtn()}
              {readyToExportBtn()}
            </>
          }
        </>
      }
      {doc.status === 510 &&
        <>
          {archiveBtn()}
          {deleteBtn()}
        </>
      }
      {(doc.status === 600) && deleteBtn()}
    </Box>
  );
};

export default DocumentEditActionButtons;
