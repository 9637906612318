import { ChangeEvent, useContext, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useTranslation, } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { DragDropContext, Droppable, Draggable, DropResult, DraggableProvided } from '@hello-pangea/dnd';
import Axios from 'axios';

import {
  Box, Button, Grid, TextField, Typography, IconButton, Select, ListItem, FormControlLabel, Switch, Tooltip
} from '@mui/material';
import {
  ArrowBack as BackIcon,
  DragIndicator as ReorderIcon,
  Visibility as ActiveIcon,
  VisibilityOff as InactiveIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  ArrowForward as SaveIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from '@mui/icons-material';

import ConfigContext from 'src/contexts/ConfigContext';
import { AuthContext } from 'src/contexts/AuthContext';
import { CustomError, FieldType } from 'src/types';
import {
  createFieldMap, fieldsDataTypes, getManageFieldsHeader, receiverRelatedFields, senderRelatedFields, sortManageFields
} from 'src/document-edit/utils';
import { appendContactSupport, axiosHeaders } from 'src/utils/helpers';
import { userRoles } from 'src/config';
import AddNewFieldModal from 'src/document-edit/components/AddNewFieldModal/AddNewFieldModal';
import styles from './style';
import { fieldSearch } from 'src/utils/commonUtils';

interface PropTypes {
  documentType: string;
  section: string;
  fields: FieldType[];
  parameterCompany: string;
  docID?: string;
  fetchFields: () => void;
  handleCloseManageFields: () => void;
}

const ManageFieldsNew = (props: PropTypes) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {user} = useContext(AuthContext);

  const {
    documentType,
    section,
    fields,
    parameterCompany,
    docID,
    fetchFields,
    handleCloseManageFields,
  } = props;
  const { API } = useContext(ConfigContext);

  const showApplyToAll = (documentType === 'invoice' || documentType === 'sales_invoice' || documentType === 'purchase_order') && !user?.isSubCompany;

  const [fieldActiveMap, setFieldActiveMap] =
    useState(createFieldMap(fields.filter((f) => ![...senderRelatedFields, ...receiverRelatedFields].includes(f.key))));
  const [senderFieldActiveMap, setSenderFieldActiveMap] = useState(createFieldMap(fields.filter((f) => senderRelatedFields.includes(f.key))));
  const [receiverFieldActiveMap, setReceiverFieldActiveMap] = useState(createFieldMap(fields.filter((f) => receiverRelatedFields.includes(f.key))));
  
  // @TODO: discuss with team
  const [isApplyToAll, setIsApplyToAll] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showSenderFields, setShowSenderFields] = useState<boolean>(false);
  const [showReceiverFields, setShowReceiverFields] = useState<boolean>(false);
  const [senderFields, setSenderFields] = useState<FieldType[]>([]);
  const [receiverFields, setReceiverFields] = useState<FieldType[]>([]);

  const [openAddNewFieldModal, setOpenAddNewFieldModal] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const handleSearch = (val: string) => {
    setSearchQuery(val);
  };

  const handleAddNewFieldModalClose = () => {
    setOpenAddNewFieldModal(false);
  };

  const companyID = parameterCompany || user?.companyID;
  const applyToAllCustomisations: Record<string, string> = {
    invoice: 'applyParentFieldsHeaders',
    'invoice-line': 'applyParentFieldsLines',
    'invoice-line-display': 'applyParentFieldsLinesDisplay',
    supplier: 'applyParentFieldsSupplier',
    gla: 'applyParentFieldsGLA',
    vat: 'applyParentFieldsVAT',
    cc: 'applyParentFieldsCC',
  };

  useEffect(() => {
    setFieldActiveMap(createFieldMap(fields.filter((f) => ![...senderRelatedFields, ...receiverRelatedFields].includes(f.key))));
    setSenderFields(fields.filter((f) => senderRelatedFields.includes(f.key)));
    setReceiverFields(fields.filter((f) => receiverRelatedFields.includes(f.key)));
    setSenderFieldActiveMap(createFieldMap(fields.filter((f) => senderRelatedFields.includes(f.key))));
    setReceiverFieldActiveMap(createFieldMap(fields.filter((f) => receiverRelatedFields.includes(f.key))));
  }, [fields]);

  useEffect(() => {
    setIsApplyToAll(
      Boolean(user?.customisations?.some((c) => c === applyToAllCustomisations[section]))
    );
  }, [section]);

  const saveFieldsSelection = async () => {
    const activeFields = Object.keys(fieldActiveMap)
      .filter((key) => fieldActiveMap[key].active)
      .map((key) => ({ name: key, position: fieldActiveMap[key].position }));
    const activeSenderFields = Object.keys(senderFieldActiveMap)
      .filter((key) => senderFieldActiveMap[key].active)
      .map((key) => ({ name: key, position: senderFieldActiveMap[key].position }));
    const activeReceiverFields = Object.keys(receiverFieldActiveMap)
      .filter((key) => receiverFieldActiveMap[key].active)
      .map((key) => ({ name: key, position: receiverFieldActiveMap[key].position }));
    try {
      let url = `${API.fieldsV2}/doc-types/${documentType}/sections/${section}/${companyID}`;
      if (documentType === 'dimension') {
        url = `${API.fields}/${section}/${companyID}`;
      }
      if (user?.userRole === userRoles.annotator && docID) {
        url += `?docID=${docID}`;
      }
      const response = await Axios.post(
        url,
        { fields: [ ...activeFields, ...activeSenderFields, ...activeReceiverFields ] },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        fetchFields();
        enqueueSnackbar(
          t('SAVE_FIELDS_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
        handleCloseManageFields();
      }
    } catch (e) {
      const error = e as CustomError;
      let errorMessage = appendContactSupport(window.config.support_email, t('SAVE_FIELDS_FAILURE'), t);
      if (error && error.response && error.response.data) {
        errorMessage = t(error.response.data.i18n || '');
      }

      enqueueSnackbar(
        errorMessage,
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  const onChangeApplyToAll = async (e: ChangeEvent<HTMLInputElement>) => {
    e.persist();
    const { checked } = e.target;
    setLoading(true);
    try {
      const response = await Axios.put(
        `${API.customisations}`,
        { customisation: applyToAllCustomisations[documentType], enabled: checked },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        fetchFields();
        enqueueSnackbar(
          t(checked ? 'MANAGE_FIELDS_APPLY_TO_ALL_SUCCESS' : 'MANAGE_FIELDS_UNAPPLY_TO_ALL_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 3000
          }
        );
        setLoading(false);
        setIsApplyToAll(checked);
      }
    } catch (e) {
      const error = e as CustomError;
      let errorMessage = appendContactSupport(window.config.support_email, t('MANAGE_FIELDS_APPLY_TO_ALL_FAILURE'), t);
      if (error && error.response && error.response.data) {
        errorMessage = t(error.response.data.i18n || error.response.data.message || '');
      }

      enqueueSnackbar(
        appendContactSupport(window.config.support_email, errorMessage, t),
        {
          variant: 'error',
          autoHideDuration: 3000
        }
      );
      setLoading(false);
    }
  };

  const handleActivateField = (
    key: string,
    setFunction: Dispatch<SetStateAction<Record<string, {
      active: boolean;
      position: number;
    }>>>
  ) => {
    if (key === 'supplier' && fieldActiveMap.supplier.active) {
      setShowSenderFields(false);
    }
    if (key === 'receiver' && fieldActiveMap.receiver.active) {
      setShowReceiverFields(false);
    }
    setFunction((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        active: !prevState[key].active,
      },
    }));
  };

  const handleAdjustFieldPosition = (
    e: DropResult,
    setFunction: Dispatch<SetStateAction<Record<string, {
      active: boolean;
      position: number;
    }>>>
  ) => {
    if (!e.destination || e.destination.index === e.source.index) {
      return;
    }
    const destination = e.destination?.index;
    const source = e.source.index;
    const sourceKey = e.draggableId;
    setFunction((prevState) => {
      const sourceIndex = source + 1;
      const destinationIndex = destination + 1;
      const fields = Object.keys(prevState)
        .map((key) => ({ key, position: key === sourceKey ? sourceIndex : prevState[key].position }));
      const newFields = [];
      if (sourceIndex > destinationIndex) {
        for (let i = 0; i < fields.length; i++) {
          if (fields[i].position < sourceIndex && fields[i].position >= destinationIndex) {
            newFields.push({ ...fields[i], position: fields[i].position + 1 });
          } else {
            if (fields[i].position === sourceIndex) {
              newFields.push({ ...fields[i], position: destinationIndex });
            } else {
              newFields.push({ ...fields[i] });
            }
          }
        }
      }
  
      if (sourceIndex < destinationIndex) {
        for (let i = 0; i < fields.length; i++) {
          if (fields[i].position > sourceIndex && fields[i].position <= destinationIndex) {
            newFields.push({ ...fields[i], position: fields[i].position - 1 });
          } else {
            if (fields[i].position === sourceIndex) {
              newFields.push({ ...fields[i], position: destinationIndex });
            } else {
              newFields.push({ ...fields[i] });
            }
          }
        }
      }
      
      const res = newFields.reduce((acc: Record<string, {active: boolean, position: number}>, field) => {
        acc[field.key] = { ...prevState[field.key], position: field.position };
        return acc;
      }, {});
      return res;
    });
  };

  const isFieldActive = (field: FieldType, activeState: Record<string, {active: boolean, position: number}>) => {
    const { key, isMandatory } = field;
    return isMandatory || activeState[key].active;
  };

  const isFieldDisabled = (field: FieldType) => {
    return field?.isMandatory;
  };

  const getTooltipTitle = (field: FieldType) => {
    if (field?.isMandatory) {
      return `${t(field.label)} ${t('MANAGE_FIELDS_CANNOT_REMOVE_TOOLTIP')}`;
    }
    return '';
  };

  const onClickFieldLabel = (key: string) => {
    if (key === 'supplier' && fieldActiveMap.supplier.active) {
      if (showSenderFields) {
        setShowSenderFields(false);
      } else {
        setShowSenderFields(true);
      }
    } else if (key === 'receiver' && fieldActiveMap.receiver.active) {
      if (showReceiverFields) {
        setShowReceiverFields(false);
      } else {
        setShowReceiverFields(true);
      }
    }
  };

  const renderFieldRow = (
    field: FieldType,
    draggableProvided: DraggableProvided,
    activeState: Record<string, {active: boolean, position: number}>,
    setFunction: Dispatch<SetStateAction<Record<string, {
      active: boolean;
      position: number;
    }>>>
  ) => {
    const { key, dataType } = field;
    const isEnabled = isFieldActive(field, activeState);
    return (
      <Box key={key} sx={styles.fieldRow}>
        <Box sx={styles.leftContainer}>
          {((key === 'supplier' && showSenderFields) || (key === 'receiver' && showReceiverFields)) ? (
            <Tooltip title={t('MANAGE_FIELDS_DRAGGABLE_DISABLED_CLIENT')}>
              <div style={styles.dragIconContainer}>
                <ReorderIcon sx={styles.dragIcon} />
              </div>
            </Tooltip>
          ) : (
            <div {...draggableProvided.dragHandleProps} style={styles.dragIconContainer}>
              <ReorderIcon sx={styles.dragIcon} />
            </div>
          )}
          <Tooltip title={getTooltipTitle(field)} placement="top">
            <span>
              <IconButton onClick={() => handleActivateField(key, setFunction)} disabled={isFieldDisabled(field)}>
                {isEnabled ? <ActiveIcon sx={styles.visibilityIcon} /> : <InactiveIcon sx={styles.visibilityIcon} />}
              </IconButton>
            </span>
          </Tooltip>
          <Box
            sx={{ ...styles.fieldLabelContainer, cursor: (key === 'supplier' || key === 'receiver') ? 'pointer' : 'default' }}
            onClick={() => onClickFieldLabel(key)}
          >
            <Typography sx={isEnabled ? styles.fieldLabelActive : styles.fieldLabelInactive}>
              {t(field.label)}
            </Typography>
            {key === 'supplier'
              && (showSenderFields
                ? <ArrowDropUpIcon sx={isEnabled ? styles.dropDownIconActive : styles.dropDownIconInactive} />
                : <ArrowDropDownIcon sx={isEnabled ? styles.dropDownIconActive : styles.dropDownIconInactive} />)}
            {key === 'receiver'
              && (showReceiverFields
                ? <ArrowDropUpIcon sx={isEnabled ? styles.dropDownIconActive : styles.dropDownIconInactive} />
                : <ArrowDropDownIcon sx={isEnabled ? styles.dropDownIconActive : styles.dropDownIconInactive} />)}
          </Box>
        </Box>
        <Box sx={styles.rightContainer}>
          <Select
            sx={styles.selectStyle}
            value={dataType || ''}
            disabled
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
            }}
          >
            {
              fieldsDataTypes.map((d) => (
                <ListItem style={styles.selectItem} key={d.key} value={d.key}>
                  {t(d.label)}
                </ListItem>
              ))
            }
          </Select>
          <IconButton disabled sx={styles.deleteBtn}>
            <DeleteIcon sx={styles.deleteIcon} />
          </IconButton>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box sx={styles.main}>
        <Grid sx={styles.header}>
          <IconButton sx={styles.backIconContainer} onClick={handleCloseManageFields}>
            <BackIcon sx={styles.backIcon} />
          </IconButton>
          <Typography sx={styles.headerText}>{t(getManageFieldsHeader(section))}</Typography>
        </Grid>
        <Tooltip title={t('MANAGE_FIELDS_SEARCH_HELPER')}>
          <TextField
            fullWidth
            sx={styles.searchBox}
            onChange={(e) => handleSearch(e.target.value)}
            placeholder={t('MANAGE_FIELDS_SEARCH_HELPER')}
            value={searchQuery}
            variant="outlined"
            InputProps={{
              sx: {
                '& .MuiOutlinedInput-notchedOutline': styles.searchInput,
                '& .MuiOutlinedInput-input': styles.searchBarInput
              }
            }}
          />
        </Tooltip>
        <Grid className="y-scroll" sx={styles.fieldsListContainer}>
          <DragDropContext onDragEnd={(e) => handleAdjustFieldPosition(e, setFieldActiveMap)}>
            <Droppable droppableId="droppable" direction="vertical">
              {(droppableProvided) => (
                <Grid
                  ref={droppableProvided.innerRef} 
                  {...droppableProvided.droppableProps}
                >
                {
                  sortManageFields(documentType, fieldSearch(fields, t, searchQuery), fieldActiveMap, t)
                  .map((field, i) => {
                    const { key } = field;
                    if (senderRelatedFields.includes(key) || receiverRelatedFields.includes(key) || key === 'line') {
                      return;
                    }
                    return (
                      <Draggable
                        key={key}
                        draggableId={key}
                        index={i}
                      >
                        {(draggableProvided) => (
                          <Box ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
                            {renderFieldRow(field, draggableProvided, fieldActiveMap, setFieldActiveMap)}
                            {key === 'supplier' && showSenderFields && (
                              <DragDropContext onDragEnd={(e) => handleAdjustFieldPosition(e, setSenderFieldActiveMap)}>
                                <Droppable droppableId="droppable" direction="vertical">
                                  {(droppableProvided2) => (
                                    <Grid sx={styles.clientFieldsListContainer} ref={droppableProvided2.innerRef} {...droppableProvided2.droppableProps}>
                                      {senderFields.sort((a, b) => senderFieldActiveMap[a.key].position - senderFieldActiveMap[b.key].position).map((sf) => (
                                        <Draggable
                                          key={sf.key}
                                          draggableId={sf.key}
                                          index={senderFields.indexOf(sf)}
                                        >
                                          {(draggableProvided2) => (
                                            <Box ref={draggableProvided2.innerRef} {...draggableProvided2.draggableProps}>
                                              {renderFieldRow(sf, draggableProvided2, senderFieldActiveMap, setSenderFieldActiveMap)}
                                            </Box>
                                          )}
                                        </Draggable>
                                      ))}
                                    </Grid>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            )}
                            {key === 'receiver' && showReceiverFields && (
                              <DragDropContext onDragEnd={(e) => handleAdjustFieldPosition(e, setReceiverFieldActiveMap)}>
                                <Droppable droppableId="droppable" direction="vertical">
                                  {(droppableProvided2) => (
                                    <Grid sx={styles.clientFieldsListContainer} ref={droppableProvided2.innerRef} {...droppableProvided2.droppableProps}>
                                      {receiverFields
                                        .sort((a, b) => receiverFieldActiveMap[a.key].position - receiverFieldActiveMap[b.key].position)
                                        .map((sf) => (
                                          <Draggable
                                            key={sf.key}
                                            draggableId={sf.key}
                                            index={receiverFields.indexOf(sf)}
                                          >
                                            {(draggableProvided2) => (
                                              <Box ref={draggableProvided2.innerRef} {...draggableProvided2.draggableProps}>
                                                {renderFieldRow(sf, draggableProvided2, receiverFieldActiveMap, setReceiverFieldActiveMap)}
                                              </Box>
                                            )}
                                          </Draggable>
                                      ))}
                                    </Grid>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            )}
                          </Box>
                        )}
                      </Draggable>
                    );
                  })
                }
                {droppableProvided.placeholder}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
        </Grid>
        {(section === 'header') && <Box sx={styles.addBtnContainer}>
          <Button sx={styles.addFieldBtn} onClick={() => setOpenAddNewFieldModal(true)}>
            <AddIcon sx={styles.addIcon} />
            {t('MANAGE_FIELDS_NEW_ADD_FIELD')}
          </Button>
        </Box>}
        <Box sx={styles.actionBtnContainer}>
          <Button sx={styles.cancelBtn} onClick={handleCloseManageFields}>
            {t('INVOICE_EDIT_FORM_CANCEL')}
          </Button>
          <Button sx={styles.saveBtn} onClick={saveFieldsSelection}>
            {t('MANAGE_FIELD_SAVE')}
            <SaveIcon sx={styles.saveIcon} />
          </Button>
        </Box>
        {showApplyToAll && (
          <Box sx={styles.switchBtnContainer}>
            <FormControlLabel
              sx={styles.formControlLabel}
              control={<Switch checked={isApplyToAll} onChange={(e) => onChangeApplyToAll(e)} />}
              label={t('MANAGE_FIELDS_APPLY_TO_ALL')}
              disabled={loading}
            />
          </Box>
        )}
      </Box>
      <AddNewFieldModal
        open={openAddNewFieldModal}
        fields={fields}
        docType={documentType}
        handleClose={handleAddNewFieldModalClose}
      />
    </>
  );
};

export default ManageFieldsNew;
