import theme from "../../../themeNew";

const styles = {
  filtersRow: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 20px 0px 20px',
    backgroundColor: '#FFFFFF',
    height: '48px',
  },
  statusBtnsContainer: {
    display: 'flex',
    gap: '6px',
    overflowX: 'scroll',
    width: '100%',
    justifyContent: 'space-between',
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: '1px',
      backgroundColor: '#E5E5E5',
      zIndex: 1,
    },
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    scrollbarWidth: 'none',
  },
  statusBtn: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    minWidth: 'fit-content',
    borderBottom: '2px solid transparent',
    position: 'relative',
    zIndex: 2,
    '&:hover': {
      borderBottom: '2px solid #C4D3E8',
      '& .MuiTypography-root': {
        color: '#3E8AFF',
      },
    },
  },
  statusBtnActive: {
    borderBottom: `2px solid ${theme.palette.primary.main} !important`,
    '& .MuiTypography-root': {
      color: '#3E8AFF !important',
    },
    '& svg': {
      color: '#3E8AFF !important',
    }
  },
  statusBtnContent: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
  },
  statusIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20px',
    height: '20px',
  },
  statusIconPlaceholder: {
    width: '20px',
    height: '20px',
  },
  statusText: {
    fontSize: '15px',
    lineHeight: '20px',
    whiteSpace: 'nowrap',
    fontWeight: '500',
    color: theme.palette.text.primary
  },
};

export default styles;
