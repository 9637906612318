import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';

import { useConfig } from 'src/hooks/useConfig';
import { useAuth } from 'src/hooks/useAuth';
import SideBarLayout from 'src/shared/layouts/SideBarLayout';
import DocumentsListContainer from 'src/documents-new/DocumentsListContainer';
import SelectDocumentType from './components/SelectDocumentType/SelectDocumentType';
import styles from './style';

const DocumentsListNew = () => {
  const { t } = useTranslation();
  const { BRAND_NAME } = useConfig();
  const { user } = useAuth();

  return (
    <>
      {
        !user?.documentType && <SelectDocumentType open />
      }
      <SideBarLayout title={`${t('DASHBOARD_ACTION_INVOICES')} | ${BRAND_NAME}`}>
        <Grid sx={styles.root}>
          <DocumentsListContainer />
        </Grid>
      </SideBarLayout>
    </>
  )
}

export default DocumentsListNew