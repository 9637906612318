import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikErrors } from 'formik';

import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Chip, FormControl,
  FormHelperText, Grid, InputAdornment, MenuItem, Select, TextField, Tooltip, Autocomplete, Typography,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon, Edit as EditIcon, AddBox as PlusIcon
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import { isActionPermitted, permissions } from 'src/utils/helpers';
import { supplierData, vatCodeFields, vatCodeTypes } from 'src/config';
import { CostCenterType, GLAccountType, VatGroupType } from 'src/types';
import { filterAutocompleteOptionsCostCenter, filterAutocompleteOptionsGLA, sectionHasError } from 'src/dimensions-new/utils';
import theme from 'src/theme';

type PropsType = {
  errors: Record<string, string>;
  handleBlur:React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleSubmit: () => void;
  setErrors: (_errors: FormikErrors<Record<string, string>>) => void;
  isSubmitting: boolean;
  touched: Record<string, boolean | string>;
  values: Record<string,boolean | string>;
  vatCode: VatGroupType | null;
  handleClose: () => void;
  handleChangeAutocomplete: (_e:React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | 
    React.SyntheticEvent<Element, Event>, _prop:string, _values:Record<string,string | boolean>, _handleChange:Function) => void;
  costCenters: CostCenterType[];
  glaccounts: GLAccountType[];
  handleEditCostCenter: (_c:string) => void ;
  handleAddCostCenter: (_c:string) => void ;
  newlyAddedVal: string;
  newlyAddingProp: string;
  isDisabled: boolean;
}

const VatCodeTwinfield = (props: PropsType) => {
  const { t, ready } = useTranslation();

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setErrors,
    isSubmitting,
    touched,
    values,
    vatCode,
    handleClose,
    handleChangeAutocomplete,
    costCenters,
    glaccounts,
    handleEditCostCenter,
    handleAddCostCenter,
    newlyAddedVal,
    newlyAddingProp,
    isDisabled
  } = props;
  
  const auth = useAuth();
  const {user} = auth;

  useEffect(() => {
    if (newlyAddedVal && newlyAddingProp) {
      handleChange({ target: { name: newlyAddingProp, value: newlyAddedVal } } as 
        React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>);
    }
  }, [newlyAddedVal]);

  return (
    <form style={styles.form} className="y-scroll">
      <Grid container sx={styles.fieldsContainer}>
        {
          vatCodeFields.map((m:Record<string, any>,index:number) => (
            <Accordion
              key={m.title}
              sx={styles.accordionGroup}
              defaultExpanded={index===0?true:false}
              className="twinfield-accordion"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={m.title}
                id={m.title}
              >
                <Grid sx={styles.accordionHeading}>
                  {ready && t(m.title)}
                  {
                    sectionHasError(m.fields, errors, touched) && (
                      <Chip
                        color="error"
                        size="small"
                        label="Error"
                        sx={styles.errorChip}
                      />
                    )
                  }
                </Grid>
              </AccordionSummary>
              <AccordionDetails sx={{padding: '0 24px 16px 48px'}}>
                <Grid sx={styles.eachFieldContainer}>
                  {
                    m.fields.map((f:Record<string,any>) => {
                      switch (f.key) {
                        case 'type':
                          return (
                            <Grid item key={f.key} sx={styles.eachField}>
                              <Typography>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</Typography>
                              <FormControl variant="outlined" sx={styles.formControl}>
                                <Select
                                  disabled={isDisabled}
                                  labelId={`${f.key}-label`}
                                  id={f.key}
                                  error={Boolean(touched[f.key] && errors[f.key])}
                                  value={values[f.key]}
                                  onClick={() => setErrors({})}
                                  onChange={handleChange as any}
                                  name={f.key}
                                  required={f.required}
                                  sx={styles.selectStyle}
                                >
                                  {
                                    vatCodeTypes.map((s) => {
                                      if (s) {
                                        return (
                                          <MenuItem key={s.name} value={s.name}>
                                            {t(s.label)}
                                          </MenuItem>
                                        );
                                      }

                                      return null;
                                    })
                                  }
                                </Select>
                                <FormHelperText error>
                                  {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                          );
                        case 'ruleCostCenter':
                          return (
                            <Grid item sx={styles.eachField} key={f.key}>
                              <Typography>{ready && t(f.label)}</Typography>
                              <FormControl variant="outlined" sx={styles.formControl}>
                                <Autocomplete
                                  disabled={isDisabled}
                                  fullWidth
                                  onChange={(e, v) => handleChangeAutocomplete(e, 'ruleCostCenter', 
                                    v as unknown as Record<string, any>, handleChange)}
                                  value={values?.ruleCostCenter && costCenters && 
                                    costCenters.find((e:CostCenterType) => e.code === values?.ruleCostCenter) || null}
                                  options={costCenters}
                                  getOptionLabel={
                                    (option: CostCenterType) => (option && option.code ? `${option.code} | ${option.name}` : '')
                                  }
                                  filterOptions={(options, state) => filterAutocompleteOptionsCostCenter(options, state)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: ( isDisabled ? <></> :
                                          <>
                                            {values[f.key] !== supplierData.defaultCostCenters[0].code && (
                                              <InputAdornment
                                                position="end"
                                                onClick={() => handleEditCostCenter(values[f.key] as string)}
                                                sx={styles.editIconContainer}
                                              >
                                                <EditIcon sx={styles.editDimensionIcon} />
                                              </InputAdornment>
                                            )}
                                            <InputAdornment position="end" sx={styles.plusIconContainer}>
                                              <PlusIcon sx={styles.plusIcon} onClick={() => handleAddCostCenter(f.key)} />
                                            </InputAdornment>
                                          </>
                                        ),
                                      }}
                                      sx={styles.autoCompleteInput}
                                    />
                                  )}
                                />
                                <FormHelperText error>
                                  {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                          );
                        case 'ruleAccount':
                          return (
                            <Grid item sx={styles.eachField} key={f.key}>
                              <Typography>{ready && t(f.label)}</Typography>
                              <FormControl variant="outlined" sx={styles.formControl}>
                                <Autocomplete
                                  fullWidth
                                  disabled={isDisabled}
                                  onChange={(e, v) => handleChangeAutocomplete(e, 'ruleAccount', v as 
                                    unknown as Record<string, any>, handleChange)}
                                  value={glaccounts && values?.ruleAccount && glaccounts.find((e: GLAccountType) => e?.code === values?.ruleAccount) || null}
                                  options={glaccounts}
                                  getOptionLabel={
                                    (option) => (option && option.code ? `${option.code} | ${option.name}` : '')
                                  }
                                  filterOptions={(options, state) => filterAutocompleteOptionsGLA(options, state)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      InputProps={{
                                        ...params.InputProps
                                      }}
                                      sx={styles.autoCompleteInput}
                                    />
                                  )}
                                />
                              </FormControl>
                            </Grid>
                          );
                        default:
                          return (
                            <Tooltip key={f.key} title={vatCode && f.key === 'code' ? t('DIMENSION_CODE_CANNOT_EDIT') : ''}>
                              <Grid item sx={styles.eachField} key={f.key}>
                                <Typography>{ready && t(f.label)}</Typography>
                                <TextField
                                  error={Boolean(touched[f.key] && errors[f.key])}
                                  fullWidth
                                  helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                  name={f.key}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  required={f.required}
                                  value={values[f.key]}
                                  variant="outlined"
                                  sx={styles.inputField}
                                  disabled={isDisabled ? true : vatCode ? (vatCode?.code && f.key) === 'code' : false}
                                />
                              </Grid>
                            </Tooltip>
                          );
                      }
                    })
                  }
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))
        }
        <Grid >
          {errors.submit && (
          <Box mt={3}>
            <FormHelperText error>
              {errors.submit}
            </FormHelperText>
          </Box>
          )}
        { 
          isDisabled ? <></> :
          <Box mt={2} display="flex" justifyContent="flex-end" gap="10px" p="0 16px 20px">
            <Button
              variant="text"
              onClick={()=>handleClose()}
              sx={{...styles.yearField,textTransform: 'none'}}
            >
              {ready && t('VAT_CODES_CANCEL')}
            </Button>
            <Button
              sx={{ backgroundColor: '#3E8AFF',textTransform: 'none' }}
              variant="contained"
              disabled={isSubmitting || !isActionPermitted(permissions.dimensionEdit, user?.permissions)}
              onClick={()=>handleSubmit()}
            >
              {ready && t(vatCode && vatCode?.code ? 'VAT_CODES_SAVE_CHANGES' : 'VAT_CODES_ADD_NEW')}
            </Button> 
          </Box>
          }
        </Grid>
      </Grid>
    </form>
  );
};

const styles = {
  form: {
    backgroundColor: '',
    maxHeight: 'calc(94dvh - 76px)',
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  eachFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  eachField: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '5px 0',
    width: '100%',
    flex: 1,
    '& > p': {
      fontSize: '13px',
      color: '#4C4E64DE',
    }
  },
  yearField: {
    color: theme.palette.secondary.main
  },
  accordionGroup: {
    width: '100%',
    backgroundColor: 'tranparent',
    boxShadow: 'none',
    borderTop: 'none',
    margin: '0 !important',
    borderBottom: '1px solid #E0E0E0',
    '&::before': {
      display: 'none',
      opacity: '0'
    },
  },
  accordionHeading: {
    color: '#4C4E64',
    fontWeight: '500',
    fontSize: '14px',
  },
  formControl: {
    width: '300px'
  },
  autoCompleteInput: {
    width: '300px',
    color: '#4C4E64 !important',
    fontSize: '13px',
    lineHeight: '20px',
    backgroundColor: '#ffffff',
    '& .MuiAutocomplete-input': {
      color: '#4C4E64 !important',
    },
    '& .MuiOutlinedInput-root': {
      padding: '0px 2px 0px 0px !important',
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: `2px solid #C2CFE099`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid #C2CFE099`,
      }
    },
  },
  selectStyle: {
    color: '#4C4E64 !important',
    fontSize: '13px',
    width: '100%',
    lineHeight: '20px',
    backgroundColor: '#ffffff',
    '&.MuiAutocomplete-input': {
      color: '#4C4E64 !important',
    },
    '&.MuiOutlinedInput-root': {
      padding: '0px 2px 0px 0px !important',
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: `2px solid #C2CFE099`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid #C2CFE099`,
      }
    },
  },
  inputField: {
    width: '300px',
    '& .MuiOutlinedInput-input': {
      fontSize: '13px !important',
      lineHeight: '32px !important',
      color: '#4C4E64 !important',
      fontWeight: '400',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: `2px solid #C2CFE099`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid #C2CFE099`,
      },
    },
  },
  errorChip: {
    marginLeft: '16px',
    fontSize: '12px',
    color: '#ffffff',
    backgroundColor: '#f44336'
  },
  btnIcon: {
    height: '15px',
    width: '15px',
    marginRight: '4px',
    color: '#bebebe',
  },
  plusIconContainer: {
    marginRight: '5px'
  },
  plusIcon: {
    height: '24px',
    width: '24px',
    color: theme.palette.secondary.main,
    cursor: 'pointer'
  },
  editDimensionIcon: {
    fontSize: '18px',
    color: '#757575',
    border: '1px solid #334D6E',
    borderRadius: '3px',
    cursor: 'default'
  },
  editIconContainer: {
    marginRight: '-8px'
  },
};


export default VatCodeTwinfield;
