import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Axios from 'axios';

import { Button, Menu, MenuItem, Typography } from '@mui/material';
import {
	ArrowDropDown as DownIcon,
} from '@mui/icons-material';

import useWidth from 'src/hooks/useWidth';
import { useConfig } from 'src/hooks/useConfig';
import { useAuth } from 'src/hooks/useAuth';
import { CustomError, UserCompanyType } from 'src/types';
import { appendContactSupport, axiosHeaders, getLocalisedErrorString } from 'src/utils/helpers';
import styles from './style';

const CompanySwitch = (props: { width?: string }) => {
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { API, SUPPORT_EMAIL } = useConfig();
  const { user } = useAuth();
  const { width } = props;
  
  const companyBtnRef = useRef(null);
  const companyBtnWidth = useWidth(companyBtnRef, 0);
  const [anchorElCompany, setAnchorElCompany] = useState<null | HTMLElement>(null);
  const [companies, setCompanies] = useState<UserCompanyType[]>([]);

  const handleOpenCompanyMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElCompany(event.currentTarget);
  };

  const handleCloseCompanyMenu = () => {
    setAnchorElCompany(null);
  };

  const getOffices = useCallback(async () => {
    try {
      const response = await Axios.get(
        `${API.getCompaniesByUser}${user?.email}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data.length > 0) {
          const companiesResp = response.data.data;
          companiesResp.sort((a:any, b:any) => {
            if (a.name > b.name) {
              return 1;
            }
            return -1;
          });
          const listCompanies = [];
          for (let i = 0; i < companiesResp.length; i++) {
            const company = companiesResp[i];
            if (company?.companyID?.toString() !== user?.companyID && company.isMember) {
              listCompanies.push(company);
            }
          }
          setCompanies(listCompanies);
        }
      }
    } catch (e) {
      console.error('Error getting companies: ', e);
    }
  }, [API.getCompaniesByUser, user?.companyID, user?.email]);

  useEffect(() => {
    if (user && user.role === 'user') {
			getOffices();
		}
  },[user?.role])

  const changeOffice = async (id: string) => {
    try {
      const response = await Axios.get(
        `${API.changeCompany}/${encodeURIComponent(id)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(t('ACCOUNT_SETTINGS_COMPANIES_CHANGE_SUCCESS'), {
          variant: 'success',
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (error) {
			const e  = error as CustomError;
      enqueueSnackbar(appendContactSupport(
        SUPPORT_EMAIL,
        getLocalisedErrorString(e?.response?.data?.i18n || 'ACCOUNT_SETTINGS_COMPANIES_CHANGE_FAILURE', t),
        t
      ), {
        variant: 'error',
      });
    }
  };

  const handleSelectOffice = (office:any) => {
    if (office.companyID !== user?.companyID) {
      changeOffice(office.companyID);
    }
    handleCloseCompanyMenu();
  }

  return(
    <>
      <Button
        ref={companyBtnRef}
        onClick={handleOpenCompanyMenu}
        sx={{ ...styles.companyBtn, width }}
        endIcon={<DownIcon sx={styles.dropdownIcon} />}
      >
        <Typography sx={styles.companyBtnText}>{user?.company}</Typography>
      </Button>
      <Menu
        id="companies-menu"
        anchorEl={anchorElCompany}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElCompany)}
        onClose={handleCloseCompanyMenu}
        sx={{
          ".MuiMenu-paper": {
            ...styles.menuPaper,
            width: companyBtnWidth,
          },
          ...styles.menuContainer
        }}
      >
        <MenuItem sx={{ ...styles.menuItemDescr, width: companyBtnWidth }} disabled>
          {ready && t('ACCOUNT_SETTINGS_MY_COMPANY')}
        </MenuItem>
        {companies.map((office:any) => {
          if (office.companyID?.toString() !== user?.companyID && office?.isMember) {
            return (
              <MenuItem sx={{ ...styles.menuItem, minWidth: companyBtnWidth }} key={office.companyID} onClick={() => handleSelectOffice(office)}>
                {office.name}
              </MenuItem>
            )}
            return null;
          })
        }
      </Menu>
    </>
  )
};

export default CompanySwitch;
