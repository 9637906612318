import theme from "src/themeNew";


const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 20px 10px 20px',
    backgroundColor: '#FFFFFF',
  },
  tableHead: {
    height: '36px',
  },
  tableHeadRow: {
  },
  tableHeadCellCommon: {
    height: '36px',
    borderBottom: 'none',
    color: '#4C4E64DE',
    lineHeight: '24px',
    fontSize: '12px',
    backgroundColor: '#C2CFE033',
    whiteSpace: 'nowrap',
  },
  tableCellCheckBoxContainer: {
    width: '70px',
    minWidth: '70px',
    padding: '0px 0px 0px 16px',
    borderTopLeftRadius: '6px !important',
  },
  tableHeadCellStatusContainer: {
    minWidth: '160px',
    padding: '0 6px !important',
    borderImage: 'linear-gradient(transparent 25%, #4C4E641F 25% 75%, transparent 75%) 0 1 0 0 / 2px'
  },
  tableHeadCellDateUploadedContainer: {
    minWidth: '136px',
    padding: '0 6px !important',
    borderImage: 'linear-gradient(transparent 25%, #4C4E641F 25% 75%, transparent 75%) 0 1 0 0 / 2px'
  },
  tableHeadCell: {
    padding: '0 6px !important',
    borderImage: 'linear-gradient(transparent 25%, #4C4E641F 25% 75%, transparent 75%) 0 1 0 0 / 2px'
  },
  tableHeadCellLast: {
    minWidth: '70px',
    padding: '0 6px !important',
    borderTopRightRadius: '6px !important',
  },
  checkBox: {
    padding: '0px',
    color: '#C2CFE099',
    '&.Mui-checked': {
      borderRadius: '4px',
    },
  },
  dropDownIconContainer: {
    padding: '2px',
  },
  checkboxDropdown: {
    zIndex: 111111,
    boxShadow: '0px 3px 14px 2px #4C4E641F',
    borderRadius: '10px',
    overflow: 'hidden',
  },
  selectAllComponent: {
    padding: '2px 0px !important'
  },
  selectAllRow: {
    height: '24px',
    fontSize: '14px',
    fontWeight: '400',
    color: '#4C4E64DE',
    lineHeight: '21px',
    letter: '0.15px',
    borderRadius: '10px',
    margin: '2px 0px',
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'default',
    }
  },
  formcontrolLabel: {
    '& .MuiFormControlLabel-label': {
      color: '#4C4E64DE !important',
      fontSize: '14px !important',
      fontWeight: '400 !important',
      lineHeight: '21px',
      letter: '0.15px',
    }
  },
  sortIcon: {
    color: '#4C4E648A',
    height: '20px',
    width: '20px',
    padding: '0px',
  },
  sortIconActive: {
    color: '#3E8AFF',
    height: '20px',
    width: '20px',
    padding: '0px',
  },
  tableBodyCellCommon: {
    height: '36px',
    borderBottom: 'none',
    color: '#4C4E64',
    lineHeight: '32px',
    fontSize: '14px',
    letter: '-0.25px',
    backgroundColor: '#FFFFFF',
    whiteSpace: 'nowrap',
  },
  tableBodyCellStatus: {
    minWidth: '160px',
    padding: '6px 6px 6px 8px !important',
    borderImage: 'linear-gradient(transparent 25%, #4C4E641F 25% 75%, transparent 75%) 0 1 0 0 / 2px'
  },
  tableBodyCellDateUploaded: {
    minWidth: '120px',
    padding: '6px 6px 6px 8px !important',
    borderImage: 'linear-gradient(transparent 25%, #4C4E641F 25% 75%, transparent 75%) 0 1 0 0 / 2px'
  },
  tableBodyCell: {
    minWidth: '120px',
    padding: '6px 6px 6px 8px !important',
    borderImage: 'linear-gradient(transparent 25%, #4C4E641F 25% 75%, transparent 75%) 0 1 0 0 / 2px',
    cursor: 'pointer',
  },
  tableBodyCellLast: {
    minWidth: '70px',
    padding: '6px 6px 6px 8px !important',
  },
  defaultCellContent: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  uploadedDateTxt: {
    color: '#4C4E64',
    lineHeight: '32px',
    fontSize: '14px',
    letter: '-0.25px',
  },
  tablePagination: {
    '.MuiTablePagination-selectLabel': {
      fontSize: '12px !important',
      color: '#4C4E6499 !important',
      fontWeight: '400',
    },
    '.MuiTablePagination-input': {
      margin: '0px 16px 0px 0px',
    },
    '.MuiTablePagination-select': {
      fontSize: '12px !important',
      color: '#4C4E64DE !important',
      fontWeight: '400',
    },
    '.MuiTablePagination-displayedRows': {
      fontSize: '12px !important',
      color: '#4C4E64DE !important',
      fontWeight: '400',
    },
    '.MuiTablePagination-actions': {
      color: '#6D788D !important',
    }
  },
  tableFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  selectedCount: {
    color: '#4C4E64',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '22px',
  },
  deselectAllIconBtn: {
    backgroundColor: theme.palette.primary.main,
    padding: '3px',
    borderRadius: '20px',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    }
  },
  deselectAllIcon: {
    color: '#FFFFFF',
    height: '12px',
    width: '12px',
  },
  selectedCountContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px'
  },
  previewIconBtn: {
    padding: '2px',
  },
  moreIconBtn: {
    margin: '0 6px',
    padding: '2px',
    height: '20px',
    width: '20px',
  },
  moreIcon: {
    color: '#6D788D',
    height: '18px',
    width: '18px',
  },
  menu: {
    '& .MuiMenu-list': {
      backgroundColor: '#FFFFFF',
      borderRadius: '10px',
      padding: '4px',
      boxShadow: '0px 3px 14px 2px #4C4E641F',
    }
  },
  menuItem: {
    height: '36px',
    backgroundColor: '#FFFFFF',
    color: '#4C4E64DE',
    fontSize: '14px',
    fontWeight: '400',
  },
  kbIcon: {
    color: '#4C4E648A',
    height: '20px',
    width: '20px',
    marginRight: '8px',
  },
  select: {
    '&.MuiInputBase-root': {
      height: '32px',
      borderRadius: '10px',
      border: '1px solid #4C4E6438',
      padding: '0px 8px !important',
      backgroundColor: '#FFFFFF',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiSelect-select': {
      padding: '4px !important',
      borderWidth: '0px',
    },
    '& .MuiSelect-icon': {
      position: 'unset',
      color: '#4C4E648A'
    },
  },
  userImage: {
    flexShrink: 0,
    height: '20px',
    width: '20px',
    fontWeight: '500',
    fontSize: '14px',
  },
  usernameText: {
    marginLeft: '8px',
    color: '#4C4E64',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
  },
  ownerMenuContainer: {
    '& .MuiPaper-root': {
      borderRadius: '4px',
      boxShadow: '0px 3px 14px 2px #4C4E641F',
      border: '1px solid #4C4E6438',
    }
  },
  splitRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 8px 0px 0px',
  },
  splitBtn: {
    backgroundColor: '#FFFFFF',
    padding: '4px 9px 4px 9px',
    borderRadius: '10px',
    height: '32px',
    textTransform: 'none',
    color: '#4C4E64',
    fontWeight: '500',
    fontSize: '13px',
    border: '1px solid #D8D8DD',
    marginLeft: '8px',
  },
  splitIcon: {
    color: '#6D788D',
    height: '18px',
    width: '18px',
    rotate: '270deg',
    marginLeft: '4px',
  },
  duplicateIcon: {
    color: '#6D788D',
    height: '18px',
    width: '18px',
    marginLeft: '4px',
  }
}

export default styles;
