import React, {
  createContext, useContext, useEffect, useState
} from 'react';
import { useDispatch } from 'react-redux';
import { UnknownAction } from 'redux';
import { useNavigate } from 'react-router-dom';

import ConfigContext from 'src/contexts/ConfigContext';
import authService from 'src/utils/authService';
import LoadingScreen from 'src/shared/screens/LoadingScreen';
import { AuthValuesType, UserDataType } from 'src/types';
import { fetchCostCenters } from 'src/dimensions/costcenters/actions';
import { fetchGlas } from 'src/dimensions/glas/actions';
import { fetchVatCodes } from 'src/dimensions/vatcodes/actions';
import { fetchHeaderFields, fetchLineFields, fetchDocOverviewFields, fetchSupplierFields } from 'src/shared/redux/fields/fieldsActions';
import { fetchDocTypes, fetchDocTypesForImport, fetchCompanyDocTypes } from 'src/doc-types/actions';
import AnnouncementBanner from 'src/dashboard-new/components/AnnouncementBanner/AnnouncementBanner';
import { fetchAllSuppliers } from 'src/dimensions-new/actions';
import { userRoles } from 'src/config';

const defaultProvider: AuthValuesType = {
  user: null,
};

const AuthContext = createContext(defaultProvider);

function AuthProvider({ children }: { children : React.JSX.Element }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { API, LOGIN_PANEL_URL } = useContext(ConfigContext);
  const [user, setUser] = useState<UserDataType | null>(defaultProvider.user);

  const loadData = (userData: any) => {
    const { userRole, companyID, documentType, customisations } = userData;
    const isCdc = customisations.includes('cdcDocumentView');
    const userDocType = isCdc ? 'cdc-document' : documentType;
    dispatch(fetchDocTypes(companyID) as unknown as UnknownAction);
    dispatch(fetchCompanyDocTypes() as unknown as UnknownAction);
    dispatch(fetchDocTypesForImport() as unknown as UnknownAction);
    dispatch(fetchHeaderFields(companyID, userDocType) as unknown as UnknownAction);
    dispatch(fetchLineFields(companyID, userDocType) as unknown as UnknownAction);
    dispatch(fetchDocOverviewFields(companyID, userDocType) as unknown as UnknownAction);
    dispatch(fetchSupplierFields(companyID) as unknown as UnknownAction);
    dispatch(fetchVatCodes(companyID) as unknown as UnknownAction);
    dispatch(fetchGlas(companyID) as unknown as UnknownAction);
    dispatch(fetchCostCenters(companyID) as unknown as UnknownAction);
    if (userRole !== userRoles.annotator) {
      fetchAllSuppliers(companyID);
    }
  };

  if (!authService.isAuthenticated()) {
    authService.logout(LOGIN_PANEL_URL);
  }

  useEffect(() => {
    const checkAuth = async () => {
      if (authService.isAuthenticated()) {
        const data = {
          token: authService.getAccessToken(),
          role: authService.getUserRole(),
          userRef: authService.getUserRef()
        };
        const response = await authService.loginInWithToken(API.verifyToken, data);
        if (response.success && response.data) {
          authService.setSession(response.data.accessToken, response.data.role, response.data.email);
          const userData = response.data;
          if (response.data.avatarName && response.data.avatarName !== '') {
            userData.avatar = `${API.getProfileImage}${response.data.avatarName}`;
          }
          setUser(userData);
          loadData(userData);
        } else {
          authService.logout(LOGIN_PANEL_URL);
        }
      } else {
        authService.logout(LOGIN_PANEL_URL);
      }
    };

    if (!user) {
      checkAuth();
    }
  }, [user, navigate]);

  if (!user) {
    return <LoadingScreen />;
  }

  if (user && user.role === 'op') {
    if (!window.location.pathname.includes('send-email')) {
      navigate('/send-email');
    }
  }

  const values = {
    user,
    setUser,
  };

  return (
    <AuthContext.Provider value={values}>
      <>
        <AnnouncementBanner />
        {children}
      </>
    </AuthContext.Provider>);
}

export { AuthContext, AuthProvider };
