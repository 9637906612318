import { useContext, useEffect, useState } from 'react';
// import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';

import { Backdrop, Box, CircularProgress, IconButton, Modal, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import { axiosHeaders, isActionPermitted, permissions } from 'src/utils/helpers';
import ConfigContext from 'src/contexts/ConfigContext';
import CostCenterTwinfield from 'src/dimensions-new/costcenters/components/CostCenterTwinField';
import CostCenterStandard from 'src/dimensions-new/costcenters/components/CostCenterStandard';
import { CostCenterType } from 'src/types';

type PropsType = {
  costCenterModalOpen: boolean;
  selectedCostCenter: string;
  handleClose: () => void;
  getAllCostCenters: ( _page?: number, _limit?: number, _search?: string, _loadFullScreen?: boolean) => void;
  onSuccess: (_id: string) => void;
  isEdit?: boolean;
  isDisabled?: boolean;
}

function CostCenterModal(props : PropsType) {
  const { t } = useTranslation();
  const {
    costCenterModalOpen,
    selectedCostCenter,
    handleClose,
    getAllCostCenters,
    onSuccess,
  } = props;

  const isEdit = props?.isEdit || false;
  const isDisabled = props?.isDisabled || false;

  const { API } = useContext(ConfigContext);

  const auth = useAuth();
  const {user} = auth;

  const [costCenter, setCostCenter] = useState<CostCenterType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const getCostCenterByCode = async (code : string) => {
    setLoading(true);
    try {
      const response = await Axios.get(
        `${API.costCenterByCode}/${encodeURIComponent(code)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setCostCenter(response.data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isActionPermitted(permissions.dimensionEdit, user?.permissions)) {
      if (selectedCostCenter) {
        getCostCenterByCode(selectedCostCenter as string);
      } else {
        setCostCenter(null);
      }
    }
  }, [selectedCostCenter]);

  return (
    <>
      <Modal
        sx={styles.modal}
        open={costCenterModalOpen}
        onClose={() =>handleClose()}
      >
        <Box sx={styles.root}>
          <Box sx={styles.wrapper}>
            <Box sx={styles.topBar}>
              <Typography sx={styles.headerTitle}>
                {costCenter?.name
                  ? `${t('COST_CENTER')} - ${costCenter ? costCenter.name : ''} (${costCenter?.code})`
                  : t('COST_CENTER_ADD_NEW_COST_CENTER')}
              </Typography>
              <IconButton onClick={()=>handleClose()}>
                <CloseIcon sx={styles.closeIcon} />
              </IconButton>
            </Box>
            {
              user?.interfacePreference === 'twinfield_interface'
                ? (
                  <CostCenterTwinfield
                    costCenter={costCenter}
                    getAllCostCenters={getAllCostCenters}
                    handleClose={handleClose}
                    onSuccess={onSuccess}
                    setLoading={setLoading}
                    isEdit={isEdit}  
                    isDisabled={isDisabled}              
                  />
                ) : (
                  <CostCenterStandard
                    costCenter={costCenter}
                    getAllCostCenters={getAllCostCenters}
                    handleClose={handleClose}
                    onSuccess={onSuccess}
                    setLoading={setLoading}
                    loading={loading}
                    isEdit={isEdit} 
                    isDisabled={isDisabled}
                  />
                )
            }
          </Box>
        </Box>
      </Modal>
      <Backdrop open={loading} sx={{ zIndex: 111111, color: '#fff' }}>
        <CircularProgress  />
      </Backdrop>
    </>
  );
}

export default CostCenterModal;

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 0px'
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100dvw',
    height: '90dvh',
  },
  wrapper: {
    height: '100%',
    width: '700px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0px 4px 18px 3px #4C4E641F, 0px 10px 14px 1px #4C4E6424, 0px 6px 6px -3px #4C4E6433',
    paddingBlockEnd: '10px'
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 24px',
    borderBottom: '1px solid #4C4E641F'
  },
  headerTitle: {
    color: '#4C4E64',
    fontSize: '20px',
    fontWeight: '500',
    textTransform: 'capitalize',
    lineHeight: '32px'
  },
  closeIcon: {
    width: '20px',
    height: '20px',
    color: 'rgba(51, 77, 110, 0.5)'
  },
};


