import React, { useState, useEffect, useRef } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AnnounceKit from "announcekit-react";

import {
    Grid, AppBar, Button, Box, Tooltip, IconButton, Avatar, Menu, MenuItem, Typography, Badge
} from '@mui/material';
import {
	People as PeopleIcon,
	Description as ReceiptIcon,
	PermIdentityOutlined as ProfileIcon,
	Settings as SettingsIcon,
	Mail as MailIcon,
	CalendarToday as PlanIcon,
	Logout as LogoutIcon,
  HelpOutlineRounded as HelpCenterIcon,
  NotificationsNone as NotificationsNoneIcon,
	Business as CompanyIcon,
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import authService from 'src/utils/authService';
import { useConfig } from 'src/hooks/useConfig';
import { externalLinks } from 'src/config';
import CompanySwitch from 'src/settings/components/CompanySwitch/CompanySwitch';
import styles from './style';

type AccountListType = {
	id: number;
	label: string;
	href: string;
	icon?: any,
}

const accounts: AccountListType[] = [
	{
		id: 0,
		label: 'Company',
		href: '',
		icon: <CompanyIcon sx={styles.menuIcon} />
	},
	{
		id: 1,
		label: 'User',
		href: '',
		icon: <ProfileIcon sx={styles.menuIcon} />
	},
	{
		id: 2,
		label: 'Email',
		href: '',
		icon: <MailIcon sx={styles.menuIcon} />
	},
	{
		id: 3,
		label: 'DASHBOARD_NAV_ACCOUNT_SETTING',
		href: '/settings',
		icon: <SettingsIcon sx={styles.menuIcon} />
	},
	{
		id: 4,
		label: 'DASHBOARD_NAV_PLAN_N_BILLING',
		href: '/subscription-plan',
		icon: <PlanIcon sx={styles.menuIcon} />
	},
	{
		id: 5,
		label: 'DASHBOARD_ACTION_LOGOUT',
		href: '',
		icon: <LogoutIcon sx={styles.menuIcon} />
	}

]

const Topbar = () => {
	const { ready, t } = useTranslation();
  const { user } = useAuth() ;
  const navigate = useNavigate();
  const { LOGIN_PANEL_URL, IS_BRAND_PROCYS } = useConfig();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
	const announceKitRef = useRef<any>(null);
  const [unread, setUnread] = useState(0);

  const handleClickSubscription = () => {
    if (((user?.isSubCompany || user?.isPartnerSubCompany) && user?.parent) || user?.customisations.includes('hideBilling')) {
      return;
    }
    navigate('/subscription-plan');
  }

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  }

  const handleLogout = () => {
    authService.logout(LOGIN_PANEL_URL);
  };

	useEffect(() => {
    if (announceKitRef.current) {
      announceKitRef.current.unread().then((unread: number) => setUnread(unread));
    }
  }, [announceKitRef]);

  const openAnnounceKitWidget = async () => {
    if (announceKitRef.current) {
      await announceKitRef.current.open();
    }
  };

  const onClickKBLink = () => {
    // Update the URL with the query parameter chat=support
    const currentUrlParams = new URLSearchParams(window.location.search);
    if (!currentUrlParams.has('chat')) {
      currentUrlParams.append('chat', 'support');
      navigate(`${window.location.pathname}?${currentUrlParams.toString()}`);
    }

    if (window && window.HubSpotConversations && window.HubSpotConversations.widget) {
      // Reference to the below here:
      // https://developers.hubspot.com/beta-docs/reference/api/conversations/chat-widget-sdk#widget.load?uuid=cf3ef811-dd96-4c1a-8fd6-f51ef9deac91
      window.HubSpotConversations.widget.load({ widgetOpen: true });
    } else {
      window.open(externalLinks.knoldgeBase.replace('__LANG__', i18n.language.split('-')[0] !== 'en'
      ? `/${i18n.language.split('-')[0]}` : ''), '_blank')
    }
  };

	const handleMenuClick = (item: AccountListType) => {
		if (item.label === 'DASHBOARD_ACTION_LOGOUT') {
			handleLogout();
		} else if (item.href !== '') {
			navigate(item.href);
		}
	};

	const renderMenuLabel = (label: string) => {
		if (label === 'Company') {
			return `${user?.companyID} - ${t('TOPBAR_USERMENU_ID')}`;
		}
		if (label === 'User') {
			return `${user?.firstname} ${user?.lastname}`;
		}
		if (label === 'Email') {
			return user?.email;
		}
		return ready && t(label);
	};

  return (
		<AppBar position='relative' sx={styles.appBar}>
			<Grid  sx={{flexGrow:1,display:{xs:'flex',md:'flex'},...styles.desktopBar}} >
				<CompanySwitch />
				<Tooltip
					title={t('TOPBAR_SUBSCRIPTION_TOOLTIP')}
					disableHoverListener={!((user?.isSubCompany || user?.isPartnerSubCompany) && user?.parent)}
				>
					<Button onClick={handleClickSubscription} sx={styles.subBtn}>{user?.subscription}</Button>
				</Tooltip>
				<Tooltip
					title={user?.maxInvoices && user?.maxInvoices > 0
						? t('TOPBAR_DOCUMENTS_UPLOADED', { invoicesCount: user?.invoicesCount || 0, maxInvoices: user?.maxInvoices || 0 })
						: t('TOPBAR_DOCUMENTS_UPLOADED_CUSTOM', { invoicesCount: user?.invoicesCount || 0 })}
				>
					<Grid sx={styles.statsContainer}>
						<ReceiptIcon sx={styles.icon} />
						<span>
						{
							user?.maxInvoices && user?.maxInvoices > 0 ?
							<>
								{user?.invoicesCount || 0}
								<span style={{...styles.fadeText}}>/{user?.maxInvoices || 0}</span>
							</>
							: `${user?.invoicesCount || 0}`
						}
						</span>
					</Grid>
				</Tooltip>
				<Tooltip title={t('TOPBAR_USERS_USED', { usersCount: user?.usersCount || 0, maxUsers: user?.maxUsers || 0 })}>
					<Grid sx={styles.statsContainer}>
						<PeopleIcon sx={styles.icon} />
						<span>
							{user?.usersCount || 0}
							<span style={{...styles.fadeText}}>/{user?.maxUsers || 0}</span>
						</span>
					</Grid>
				</Tooltip>
				<Box sx={styles.rightContainer}>
					{IS_BRAND_PROCYS && (
						<Tooltip title={t('DASHBOARD_NAV_KB_LINK')}>
							<IconButton
								sx={styles.kbIcon}
								onClick={() => onClickKBLink()}
							>
								<HelpCenterIcon />
							</IconButton>
						</Tooltip>
					)}
					{IS_BRAND_PROCYS && (
						<div style={styles.announceKitContainer}>
							<AnnounceKit
								ref={announceKitRef}
								user={{
									id: user?.id.toString() as string,
									company_id: user?.companyID,
									subscription_name: user?.subscription,
									parent_company_id: user?.parent,
									email: user?.email,
									name: `${user?.firstname} ${user?.lastname}`,
									company: user?.company,
									parent_company_name: user?.parentName,
								}}
								widget="https://announcekit.co/widgets/v2/qewhO"
							>
								<Badge className="ak-badge" badgeContent={unread} color="primary" max={9}>
									<IconButton sx={styles.notificationIcon} onClick={async () => await openAnnounceKitWidget()}>
										<NotificationsNoneIcon />
									</IconButton>
								</Badge>
							</AnnounceKit>
						</div>
					)}
					<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
						<Avatar alt={`${user?.firstname} ${user?.lastname}`} src={user?.avatar} />
					</IconButton>
					<Menu
						id="user-menu"
						anchorEl={anchorElUser}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						keepMounted
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={Boolean(anchorElUser)}
						onClose={handleCloseUserMenu}
						sx={styles.menuContainer}
					>
					{accounts.map((setting: AccountListType) => (
						<MenuItem key={setting.id} sx={styles.menuItem} onClick={() => handleMenuClick(setting)}>
							{setting.icon}
							<Typography sx={styles.menuText}>
								{renderMenuLabel(setting.label)}
							</Typography>
						</MenuItem>
					))}
					</Menu>
				</Box>
			</Grid>
		</AppBar>
	)
}

export default Topbar
