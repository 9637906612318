import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Axios from 'axios';

import { Grid } from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';
import { useConfig } from 'src/hooks/useConfig';
import { axiosHeaders } from 'src/utils/helpers';
import SideBarLayout from 'src/shared/layouts/SideBarLayout';
import DocTypeTopBar from 'src/doc-types/components/DocTypeTopBar/DocTypeTopBar';
import DocTypesTable from 'src/doc-types/components/DocTypesTable/DocTypesTable';
import AddNewDocType from './components/AddNewDocType/AddNewDocType';
import TablePagination from 'src/shared/components/TablePagination';
import DocTypeManageFields from './components/DocTypeManageFields/DocTypeManageFields';
import ImportDocumentTypes from './components/ImportDocTypes/ImportDocTypes';

const DocumentTypes = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { API, BRAND_NAME } = useConfig();
  const { user } = useAuth();

  const [docTypes, setDocTypes] = useState<Record<string, any>[]>([]);
  
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(0);

  const [loading, setLoading] = useState(true);
  const [addNewOpen, setAddNewOpen] = useState(false);
  const [importOpen, setImportOpen] = useState(false);
  const [selectedDocType, setSelectedDocType] = useState<Record<string, any> | null>(null);
  const [fields, setFields] = useState<Record<string, any>[]>([]);
  const [sectionFields, setSectionFields] = useState<Record<string, Record<string, any>[]>>({});

  const [manageFieldsOpen, setManageFieldsOpen] = useState(false);

  const onEdit = (docType: Record<string, any>) => {
    setSelectedDocType(docType);
    setAddNewOpen(true);
  };

  const handleAddNewClose = () => {
    setSelectedDocType(null);
    setAddNewOpen(false);
  };

  const onManageFields = async (docType: Record<string, any>) => {
    setSelectedDocType(docType);
    setManageFieldsOpen(true);
    await fetchSingleDocType(docType);
  };

  const handleManageFieldsClose = () => {
    setSelectedDocType(null);
    setManageFieldsOpen(false);
  };

  const fetchSingleDocType = async (docType: Record<string, any>) => {
    setLoading(true);
    try {
      const url = `${API.documentTypes}/${docType.documentType}/${user?.companyID}`;
      const response = await Axios.get(url, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response?.data?.success) {
        setFields(response.data.data.headers);
        setSectionFields(response.data.data.sections);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getDocTypes = async (pageNo = page, pageLimit = limit) => {
    setLoading(true);
    if (pageNo !== page) setPage(pageNo);
    if (pageLimit !== limit) setLimit(pageLimit);
    try {
      const url = `${API.documentTypes}/companies/${user?.companyID}?page=${pageNo + 1}&limit=${pageLimit}`;
      const response = await Axios.get(url, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response?.data?.success) {
        setDocTypes(response.data.data);
        setTotal(response.data.total || 0);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const makeDocTypeDefault = async (docType: Record<string, any>) => {
    setLoading(true);
    try {
      const url = `${API.markDocumentTypeDefault}`;
      const body = {
        documentType: docType.documentType,
        company: user?.companyID,
      };
      const response = await Axios.post(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response?.data?.success) {
        enqueueSnackbar(t('DOC_TYPES_MARK_DEFAULT_SUCCESS'), {
          variant: 'success',
        });
        getDocTypes();
      }
      setLoading(false);
    } catch (error) {
      enqueueSnackbar(t('DOC_TYPES_MARK_DEFAULT_FAILED'), {
        variant: 'error',
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user?.userRole !== 'admin') {
      navigate('/'); 
    }
    getDocTypes();
  }, []);

  const onLimitChange = (newLimit: number) => {
    getDocTypes(0, newLimit);
    setLimit(newLimit);
  };
  const onPageChange = (page: number) => {
    setPage(page);
    getDocTypes(page, limit);
  };
  
  return (
    <SideBarLayout title={`${t('DOC_TYPE_TITLE')} | ${BRAND_NAME}`} hideTopBar={manageFieldsOpen} >
      <>
        {manageFieldsOpen ? 
          <DocTypeManageFields
            open={manageFieldsOpen}
            selectedDocType={selectedDocType}
            fields={fields}
            sectionFields={sectionFields}
            fetchDocTypes={getDocTypes}
            handleClose={handleManageFieldsClose}
          /> : 
          <Grid sx={styles.root}>
            <DocTypeTopBar
              loading={loading}
              setAddNewOpen={setAddNewOpen}
              setImportOpen={setImportOpen}
            />
            <Grid sx={styles.container} className="x-scroll">
              <DocTypesTable
                loading={loading}
                docTypes={docTypes}
                onEdit={onEdit}
                onManageFields={onManageFields}
                makeDocTypeDefault={makeDocTypeDefault}
              />
              {!loading && <Grid sx={{ display: 'flex', flex: 1 }} />}
              <TablePagination
                total={total}
                limit={limit}
                page={page}
                onLimitChange={onLimitChange}
                onPageChange={onPageChange}
              />
            </Grid>
          </Grid>
        }
        <AddNewDocType
          open={addNewOpen}
          selectedDocType={selectedDocType}
          total={total}
          limit={limit}
          fetchDocTypes={getDocTypes}
          handleClose={handleAddNewClose}
        />
        <ImportDocumentTypes
          open={importOpen}
          handleClose={() => setImportOpen(false)}
          getDocTypes={getDocTypes}
        />
      </>
    </SideBarLayout> 
  )
}

export default DocumentTypes

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    backgroundColor: '#F3F5F9',
    minHeight: '90%'
  },
  container:{
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    backgroundColor: '#FFFFFF',
    overflow: 'auto',
    padding: '0px',
    height: '100%',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    boxShadow: '0px 10px 10px 0px #4C4E6438',
  },
}