import React, { useContext, useEffect, useState, useMemo } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import { useSelector } from 'react-redux';
import { Formik, FormikErrors } from 'formik';
import { useSnackbar } from 'notistack';

import { IconButton, Modal, Typography, Backdrop, CircularProgress, Box, Fade } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import { glAccountData } from 'src/config';
import { axiosHeaders, getLocalisedErrorString, isActionPermitted, permissions } from 'src/utils/helpers';
import authService from 'src/utils/authService';
import ConfigContext from 'src/contexts/ConfigContext';
import GLAccountsTwinfield from 'src/dimensions-new/glas/components//GLAccountTwinfield';
import GLAccountsStandard from 'src/dimensions-new/glas/components/GLAccountStandard';
import VatCodeModal from 'src/dimensions-new/vatcodes/components/VatCodeModal';
import { CustomError, GLAccountType, RootState, VatGroupType } from 'src/types';

type PropType = {
  gLAccountModalOpen:boolean;
  selectedGLA: GLAccountType | null;
  glaYears: Record<string, string>;
  glaPeriods: Record<string, string>[];
  handleClose: ()=> void;
  getAllGLAccounts: (_page?: number,_limit?: number, _search?: string, _loadFullScreen?:boolean) => Promise<void>;
  getVatCodes: ()=> void;
  onSuccess: (_val: string) => void;
  isDisabled: boolean;
}


function GLAccountModal({
  gLAccountModalOpen,
  selectedGLA,
  glaYears,
  glaPeriods,
  handleClose,
  getAllGLAccounts,
  getVatCodes,
  onSuccess,
  isDisabled
}: PropType) {
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { LOGIN_PANEL_URL, API } = useContext(ConfigContext);

  const auth = useAuth();
  const {user} = auth;
  
  const _vatCodes = useSelector((state:RootState) => state.vatcode.vatCodes);
  const vatCodes = [...glAccountData.vat, ...useMemo(() => _vatCodes, [_vatCodes])];

  const [years, setYears] = useState(glAccountData.year);
  const [endYears, setEndYears] = useState(glAccountData.endYear);
  const [periods, setPeriods] = useState([{ code: '0', name: `${t('GLA_PERIOD')}_0` }]);
  const [loading, setLoading] = useState<boolean>(false);
  const [vatCodeModalOpen, setVatCodeModalOpen] = useState(false);
  const [selectedVATGroupCode, setSelectedVATGroupCode] = useState<VatGroupType | null>(null);

  const [newlyAddingProp, setNewlyAddingProp] = useState<string>('');
  const [newlyAddedVal, setNewlyAddedVal] = useState<string>('');

  const getGLAYears = (data:Record<string, string>) => {
    if (data) {
      const beginFirst = parseInt(data.beginFirst, 10);
      const beginLast = parseInt(data.beginLast, 10);
      const endFirst = parseInt(data.endFirst, 10);
      const endLast = parseInt(data.endLast, 10);

      const yearsAr = [{
        code: glAccountData.year[0].code,
        name: t(glAccountData.year[0].name),
      }];
      for (let i = beginFirst; i <= beginLast; i++) {
        const iStr = i.toString();
        yearsAr.push({
          code: iStr,
          name: `${t('GLA_YEAR_LABEL')} ${iStr}`
        });
      }

      const endYearsAr = [{
        code: glAccountData.endYear[0].code,
        name: t(glAccountData.endYear[0].name),
      }];
      for (let i = endFirst; i <= endLast; i++) {
        const iStr = i.toString();
        endYearsAr.push({
          code: iStr,
          name: `${t('GLA_YEAR_LABEL')} ${iStr}`
        });
      }
      setYears(yearsAr);
      setEndYears(endYearsAr);
    }
  };

  const getGLAPeriods = (data: Record<string, string>[]) => {
    if (data) {
      const periodsAr = [];
      for (let i = 0; i < data?.length; i++) {
        const p = data[i];
        periodsAr.push({
          code: (p.position || 0).toString(),
          name: `${t('GLA_PERIOD')}_${p.position || 0}`
        });
      }

      setPeriods(periodsAr.length > 0 ? periodsAr : [{ code: '0', name: `${t('GLA_PERIOD')}_0` }]);
    }
  };

  useEffect(() => {
    if (isActionPermitted(permissions.dimensionEdit, user?.permissions)) {
      getGLAYears(glaYears);
      getGLAPeriods(glaPeriods);
    }
  }, []);

  const handleChangeAutocomplete = (e:React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | 
    React.SyntheticEvent<Element, Event>, prop:string, values:Record<string,string | boolean>, handleChange:Function) => {
    if (values && values.code) {
      handleChange({ target: { name: prop, value: values.code } });
      return;
    }

    handleChange(e, prop);
  };

  const onVatCoddeModalClose = () => {
    setVatCodeModalOpen(false);
    setSelectedVATGroupCode(null);
  };

  const handleEditVATCode = (id:string) => {
    if (id !== glAccountData.vat[0].code) {
      setSelectedVATGroupCode(vatCodes.find((v) => v.code === id));
      setVatCodeModalOpen(true);
    }
  };

  const handleAddVATCode = (prop:string) => {
    setSelectedVATGroupCode(null);
    setNewlyAddedVal('');
    setVatCodeModalOpen(true);
    setNewlyAddingProp(prop);
  };

  const onVatCodeCreated = (val:string) => {
    setNewlyAddedVal(val);
  };

  return (
    <>
      <Modal
        sx={styles.modal}
        open={gLAccountModalOpen}
        onClose={()=>handleClose()}
      >
        <Fade in={gLAccountModalOpen}>
          <div>
            <Formik
              initialValues={{
                code: selectedGLA?.code || '',
                name: selectedGLA?.name || '',
                fromYear: selectedGLA?.fromYear || '',
                fromPeriod: selectedGLA?.fromPeriod || '',
                endYear: selectedGLA?.endYear || '',
                endPeriod: selectedGLA?.endPeriod || '',
                accountNumber: selectedGLA?.accountNumber || '',
                accountHolder: selectedGLA?.accountHolder || '',
                furtherAnalysis: selectedGLA?.furtherAnalysis || glAccountData.furtherAnalysis[2].code,
                matchable: selectedGLA?.matchable || 'no',
                vat: selectedGLA?.vat || glAccountData.vat[0].code,
                vatType: selectedGLA?.vatType || glAccountData.vat[0].code,
                performanceType: selectedGLA?.performanceType || glAccountData.performanceType[0].code,
              }}
              validationSchema={Yup.object().shape({
                code: Yup.string().max(255)
                  .when('name', {
                    is: () => user?.interfacePreference === 'twinfield_interface',
                    then: ()=>Yup.string().max(255).matches(/^\S*$/, 'GLA_CODE_SPACES_NOT_ALLOWED').required('GLA_CODE_REQUIRED')
                  }as any),
                name: Yup.string().max(80, 'GLA_NAME_MAX_VALIDATION').required('GLA_NAME_REQUIRED'),
                matchable: Yup.string().max(255).required('GLA_MATCHABLE_REQUIRED'),
              })}
              onSubmit={async (values, {
                resetForm,
                setErrors,
                setStatus,
                setSubmitting
              }) => {
                try {
                  if (!authService.validateToken()) {
                    enqueueSnackbar(ready && t('PROCYS_LOGIN_SESSION_EXPIRED'), {
                      variant: 'error',
                    });
                    setTimeout(() => {
                      authService.logout(LOGIN_PANEL_URL);
                    }, 2000);
                    return;
                  }

                  setLoading(true);
                  const body = {
                    code: values.code,
                    name: values.name,
                    accountType: selectedGLA ? selectedGLA.accountType : 'pnl',
                    fromYear: values.fromYear,
                    fromPeriod: values.fromPeriod.toString(),
                    endYear: values.endYear,
                    endPeriod: values.endPeriod.toString(),
                    accountNumber: values.accountNumber,
                    accountHolder: values.accountHolder,
                    furtherAnalysis: values.furtherAnalysis,
                    matchable: values.matchable,
                    vat: values.vat,
                    vatType: values.vatType,
                    performanceType: values.performanceType,
                  };

                  const url = `${API.glAccountsByCompany}/${encodeURIComponent(user?.companyID as string)}`;
                  let response = null;
                  let snackText = '';
                  if (selectedGLA && selectedGLA?.code) {
                    response = await Axios.put(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
                    snackText = 'GLAS_UPDATE_SUCCESS';
                  } else {
                    response = await Axios.post(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
                    snackText = 'GLAS_ADD_SUCCESS';
                  }
                  if (response?.data?.success) {
                    setLoading(false);
                    resetForm();
                    setStatus({ success: true });
                    setSubmitting(false);
                    enqueueSnackbar(ready && t(snackText), {
                      variant: 'success',
                      style: { maxWidth: 400 }
                    });
                    getAllGLAccounts();
                    handleClose();
                    if (onSuccess) {
                      onSuccess(response.data.id);
                    }
                  }
                } catch (e) {
                  const error = e as CustomError;
                  setLoading(false);
                  setStatus({ success: false });
                  setSubmitting(false);
                  if (error?.response && error?.response?.data && error?.response?.data?.i18n && error?.response?.data?.i18n.indexOf('__TFMSG__') > 0) {
                    const errParts = error.response.data.i18n.split('__TFMSG__');
                    setErrors({
                      [errParts[0]]: errParts[1]
                    });
                  } else {
                    setErrors({
                      submit: getLocalisedErrorString(error?.response?.data?.i18n, t)
                    } as FormikErrors<CustomError>);
                  }
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setErrors
              }) => (
                <Box sx={styles.root}>
                  <Box sx={styles.wrapper}>
                    <Box sx={styles.topBar}>
                      <Typography sx={styles.headerTitle}>
                        {selectedGLA?.name ? `${t('GLA_TITLE')} - ${selectedGLA ? selectedGLA.name : ''} (${selectedGLA?.code})` : t('GLA_ADD_NEW_GLA')}
                      </Typography>
                      <IconButton onClick={()=>handleClose()}>
                        <CloseIcon sx={styles.closeIcon} />
                      </IconButton>
                    </Box>
                    {user?.interfacePreference === 'twinfield_interface' ? 
                    (
                      <GLAccountsTwinfield
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        isSubmitting={isSubmitting}
                        touched={touched}
                        values={values}
                        setErrors={setErrors}
                        glAccount={selectedGLA}
                        handleClose={handleClose}
                        years={years}
                        endYears={endYears}
                        periods={periods}
                        handleChangeAutocomplete={handleChangeAutocomplete}
                        vatCodes={vatCodes}
                        onSuccess={onSuccess}
                        newlyAddedVal={newlyAddedVal}
                        newlyAddingProp={newlyAddingProp}
                        handleEditVATCode={handleEditVATCode}
                        handleAddVATCode={handleAddVATCode}
                        isDisabled={isDisabled}
                      />
                    ) : (
                      <GLAccountsStandard
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        isSubmitting={isSubmitting}
                        touched={touched}
                        values={values}
                        glAccount={selectedGLA}
                        handleClose={handleClose}
                        handleChangeAutocomplete={handleChangeAutocomplete}
                        vatCodes={vatCodes}
                        newlyAddedVal={newlyAddedVal}
                        newlyAddingProp={newlyAddingProp}
                        handleEditVATCode={handleEditVATCode}
                        handleAddVATCode={handleAddVATCode}
                        isDisabled={isDisabled}
                      />)
                    }
                  </Box>
                </Box>
              )}
            </Formik>
          </div>
        </Fade>
      </Modal>
      <VatCodeModal
        vatCodeModalOpen={vatCodeModalOpen}
        handleClose={onVatCoddeModalClose}
        getAllVatCodes={getVatCodes}
        onSuccess={onVatCodeCreated}
        selectedVatCode={selectedVATGroupCode}
        isDisabled={isDisabled}
      />
      <Backdrop open={loading} sx={{ zIndex: 1111111, color: '#fff' }}>
        <CircularProgress />
      </Backdrop>
    </>
  );
}

export default GLAccountModal;

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 0px'
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100dvw',
    height: '90dvh',
  },
  wrapper: {
    height: '100%',
    width: '700px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0px 4px 18px 3px #4C4E641F, 0px 10px 14px 1px #4C4E6424, 0px 6px 6px -3px #4C4E6433',
    paddingBlockEnd: '10px'
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 24px',
    borderBottom: '1px solid #4C4E641F'
  },
  headerTitle: {
    color: '#4C4E64',
    fontSize: '20px',
    fontWeight: '500',
    textTransform: 'capitalize',
    lineHeight: '32px'
  },
  closeIcon: {
    width: '20px',
    height: '20px',
    color: '#6D788D',
  },
};


