import theme from "src/theme";

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperModal: {
    width: '900px',
    maxWidth: '90%',
    maxHeight: '94%',
    overflow: 'scroll',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: theme.shadows[5],
    padding: '16px 0',
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 16px',
    alignItems: 'center',
  },
  headerRow: {
    display: 'flex',
  },
  header: {
    marginBottom: '8px',
    color: theme.palette.text.primary,
    fontWeight: '600',
    textAlign: 'center',
    fontSize: '22px',
  },
  kbIconBtn: {
    padding: '4px',
    marginLeft: '8px',
  },
  kbIcon: {
    color: '#4C4E648A',
    height: '18px',
    width: '18px',
  },
  captionHeader: {
    marginBottom: '16px',
    color: theme.palette.text.primary,
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: '500',
    maxWidth: '480px',
    lineHeight: '16px',
  },
  caption: {
    marginTop: '4px',
    color: theme.palette.text.primary,
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: '500',
    maxWidth: '480px',
    lineHeight: '14px',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
  },
  captionSelected: {
    marginTop: '4px',
    color: '#FFFFFF', // Take from theme
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: '500',
    maxWidth: '480px',
    lineHeight: '14px',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
  },
  legacy: {
    marginTop: '4px',
    color:'#4C4E6499', // Take from theme
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: '500',
    maxWidth: '480px',
    lineHeight: '14px',
  },
  legacySelected: {
    marginTop: '4px',
    color:'#FFFFFF99', // Take from theme
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: '500',
    maxWidth: '480px',
    lineHeight: '14px',
  },
  modalBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noPadding: {
    padding: '0px 16px',
    color: theme.palette.text.primary,
    fontSize: '13px'
  },
  closeBtn: {
    padding: '8px!important',
  },
  fieldStyle: {
    paddingBottom: '24px'
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '16px 16px 0 16px',
    borderTop:'1px solid #C2CFE099',
  },
  listContainer: {
    display: 'flex',
    maxHeight: '320px',
    width: '100%',
    marginLeft: '0px',
    paddingRight: '16px',
    paddingBottom: '16px',
  },
  listItem: {
    border: '1px #e4e4e4 solid',
    borderRadius: '8px',
    padding: '8px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '192px',
  },
  listItemTitle: {
    fontSize: '15px',
    fontWeight: '600',
    textAlign: 'center',
    lineHeight: '22px',
    color: theme.palette.text.primary,
    alignItems: 'center',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
  },
  plusIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '70%',
  },
  plusIcon: {
    color: '#3CB573',
    fontSize: '40px',
  },
  sendBtn: {
    textTransform: 'none',
    backgroundColor: '#3E8AFF',
  },
  beta: {
    backgroundColor: '#EC5929',
    color: '#ffffff',
    fontSize: '10px',
    borderRadius: '20px',
    marginLeft: '8px',
    padding: '0px 8px 2px',
    position: 'relative' as const,
    top: '-6px'
  },
  action: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: '600',
    minWidth: '166px',
    height: '42px',
    boxShadow: '0px 4px 10px rgba(62, 138, 255, 0.25)',
    textTransform: 'none',
    padding: '6px 8px'
  },
};

export default styles;
