import Axios from 'axios';
import { axiosHeaders } from 'src/utils/helpers';

export const fetchAllSuppliers = async (companyID?: string) => {
  try {
    const resp = await Axios.get(
      `${window.config.backend.procys_service}v1/suppliers/find/all/${companyID}`,
      axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
    );
    if (resp.data.success) {
      window.sessionStorage.setItem('suppliers', JSON.stringify(resp.data.data));
    }
  } catch (error) {
    //
  }
};