import React, { useState , useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik, FormikErrors } from 'formik';
import { useSnackbar } from 'notistack';
import Axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { countries as AllCountries } from 'countries-list';
import cc from 'currency-codes/data';
import { UnknownAction } from "redux";

import { Modal, Box, Fade, Typography, Button, Backdrop, CircularProgress } from '@mui/material';
import { Close as CloseIcon, Category as ProductsIcon, Checklist as FieldsIcon } from '@mui/icons-material';

import { fetchVatCodes } from 'src/dimensions/vatcodes/actions';
import { fetchCostCenters } from 'src/dimensions/costcenters/actions';
import { fetchSupplierFields } from 'src/shared/redux/fields/fieldsActions';
import GLAccountModal from 'src/dimensions-new/glas/components/GLAccountModal';
import VatCodeModal from 'src/dimensions-new/vatcodes/components/VatCodeModal';
import ClientStandardForm from 'src/dimensions-new/clients/components/ClientStandardForm';
import ClientTwinFieldForm from 'src/dimensions-new/clients/components/ClientTwinFieldForm';
import CostCenterModal from 'src/dimensions-new/costcenters/components/CostCenterModal';
import ManageFieldsNew from 'src/shared/components/ManageFieldsNew/ManageFieldsNew';
import ManageClientProduct from 'src/dimensions-new/clients/components/ManageClientProduct';
import authService from 'src/utils/authService';
import { axiosHeaders, getLocalisedErrorString } from 'src/utils/helpers';
import { isActionPermitted, permissions } from 'src/utils/helpers';
import { useConfig } from 'src/hooks/useConfig';
import { fetchGlas } from 'src/dimensions/glas/actions';
import { supplierData } from 'src/config';
import { useAuth } from 'src/hooks/useAuth';
import {
  CostCenterType, SupplierType as ClientType, VatGroupType, GLAccountType, ProjectType,
  RootState, CustomError, FieldType, ProductType,
} from 'src/types';

type CurrencyType = {  
  code: string;
  countries: string[];
  currency: string;
  digits: number
  number: string;
}

type CCountryType = {
  capital: string;
  continent: string;
  currency: string[];
  languages: string[];
  name: string;
  native: string;
  phone: number[]
}

type CountryType = {
  isoCode: string;
  name: string;
}

type PropType = {
  showAddClient: boolean;
  handleClose: () => void;
  isEdit?: boolean;
  isDisabled: boolean;
  onSuccess: (_page:number) => void;
  clientId?: string;
  getAllSuppliers: ( _page?:number, _limit?:number, _search?:string, _loadFullScreen?:boolean, _loadSearch?: boolean) => void;
}


const AddClientModal = (props: PropType) => {
  const {
    showAddClient,
    handleClose,
    isEdit,
    onSuccess,
    clientId,
    getAllSuppliers,
    isDisabled,
  } = props;

  const _supplierFields: FieldType[] = useSelector((state: RootState) => state.fields.supplierFields);
  const supplierFields = useMemo(() => _supplierFields, [_supplierFields]);
  const preferredFieldsLoading: boolean = useSelector((state: RootState) => state.fields.supplierFieldsLoading);

  const [client, setClient] = useState<ClientType | null>(null);
  const [skip, setSkip] = useState<boolean | undefined>(client ? client?.skipSupplierReview :false);
  const [countriesList, setCountriesList] = useState<CountryType[]>([]);
  const [isTwinfield, setIsTwinfield] = useState<boolean>(false);
  const [projects, setProjects] = useState<ProjectType[]>(supplierData.defaultProjects);
  const [showManageFields, setShowManageFields] = useState<boolean>(false);
  const [showManageProduct, setShowManageProduct] = useState<boolean>(false);
  const [gLAccountModalOpen, setGLAccountModalOpen] = useState<boolean>(false);
  const [selectedCostCenterCode, setSelectedCostCenterCode] = useState<string>('');
  const [ccModalOpen, setCCModalOpen] = useState<boolean>(false);
  const [loading,setLoading] = useState<boolean>(false);
  const [vatCodeModalOpen, setVatCodeModalOpen] = useState<boolean>(false);
  const [selectedVATGroupCode, setSelectedVATGroupCode] = useState<VatGroupType | null>(null);
  const [currencies, setCurrencies] = useState<CurrencyType[]>([]);
  const [textFieldSelectedKey, setTextFieldSelectedKey] = useState<string>('');
  const [textFieldSelectedValue, _setTextFieldSelectedValue] = useState<string>('');
  const [xmlSelectedValue] = useState<string>('');
  const [glaPeriods, setGlaPeriods] = useState<Record<string,string>[]>([]);
  const [glaYears, setGlaYears] = useState<Record<string, string>>({});
  const [isEditCostCenter, setIsEditCostCenter] = useState<boolean>(false);

  const _glAccounts = useSelector((state:RootState) => state.gla.glAccounts);
  const glAccounts = [...supplierData.defaultGLA, ...useMemo(() => _glAccounts, [_glAccounts])];

  const _vatGroups = useSelector((state:RootState) => state.vatcode.vatCodes);
  const vatGroups = [...supplierData.defaultVatGroup, ...useMemo(() => _vatGroups, [_vatGroups])];

  const _costCenters: CostCenterType[] = useSelector((state:RootState) => state.costcenter.costCenters);
  const costCenters: CostCenterType[] = [...supplierData.defaultCostCenters, ...useMemo(() => _costCenters, [_costCenters])];

  const [selectedGLA, setSelectedGLA] = useState<GLAccountType | null>(null);
  const [newlyAddingProp,setNewlyAddingProp] = useState<string>('')
  const [newlyAddedVal, setNewlyAddedVal] = useState<string>('');
  const [products, setSupplierProducts] = useState<ProductType[] | []>((isEdit && client && client.inventories) ? client.inventories : []);
  const aCountries: Record<string,CCountryType> =  AllCountries as unknown as Record<string,CCountryType> ;
  const { ready , t } = useTranslation();
  const dispatch = useDispatch();
  const { LOGIN_PANEL_URL, API } = useConfig();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const textFieldSelectedValueRef = useRef(textFieldSelectedValue);

  const updateSupplierProducts = (products: ProductType[]) => {
    setSupplierProducts(products);
  };

  const setTextFieldSelectedValue = (val:string) => {
    textFieldSelectedValueRef.current = val;
    _setTextFieldSelectedValue(val);
  };

  const handleShowManageFields = () =>{
    setShowManageFields(true);
  }

  const handleShowManageProducts = () =>{
    setShowManageProduct(true);
  }

  const getSupplierFields = async () => {
    dispatch(fetchSupplierFields(user?.companyID || '') as unknown as UnknownAction);
  };

  const getGLAPeriods = async () => {
    try {
      const response = await Axios.get(
        `${API.glAPeriodsByCompany}/${user?.companyID}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success && response.data.data) {
        setGlaPeriods(response.data.data);
      }
    } catch (err) {
      //
    }
  };

  const getGLAYears = async () => {
    try {
      const response = await Axios.get(
        `${API.glAYearsByCompany}/${user?.companyID}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success && response.data.data) {
        setGlaYears(response.data.data);
      }
    } catch (err) {
      //
    }
  };

  const calculateGLAVal = (prop:string, val:string) => {
    if (isTwinfield) {
      return (val === supplierData.defaultGLA[0].code) ? '1600' : val;
    }
    if (isFieldAvailable(prop)) {
      return val === supplierData.defaultGLA[0].code ? '' : val;
    }
    return undefined;
  };

  const isFieldAvailable = (field:string) => {
    if (user?.interfacePreference === 'twinfield_interface') {
      return true;
    }
    return supplierFields.some((f:FieldType) => f.key === field);
  };

  const handleCloseManageFields = () => {
    setShowManageFields(false);
  };
  const handleCloseManageProduct = () =>{
    setShowManageProduct(false);
  }

  const onVatCoddeModalClose = () =>{
    setVatCodeModalOpen(false);
    setSelectedVATGroupCode(null);
  }

  const onGLAModalClose = () =>{
    setGLAccountModalOpen(false);
    setSelectedGLA(null);
  }

  const onCCModalClose = () =>{
    setCCModalOpen(false);
    setSelectedCostCenterCode('');
  }
  
  const onCCCreated = (val:string) => {
    getAllCostCenters();
    setNewlyAddedVal(val);
  };

  const handleChangeAutocomplete = (e:React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | 
    React.SyntheticEvent<Element, Event>, prop:string, 
    value:Record<string, any>, changeFunc:Function) => {
    if (value && (value.code || value.isoCode)) {
      changeFunc({ target: { name: prop, value: value.isoCode ? value.isoCode : value.code } });
    }
  };

  const handleEditVATCode = (id:string) => {
    if (id !== supplierData.defaultVatGroup[0].code) {
      setSelectedVATGroupCode(vatGroups.find((v:VatGroupType) => v.code === id) || null);
      setVatCodeModalOpen(true);
    }
  };

  const handleAddVATCode = (prop:string) => {
    setSelectedVATGroupCode(null);
    setNewlyAddedVal('');
    setVatCodeModalOpen(true);
    setNewlyAddingProp(prop);
  };

  const handleAddGLA = (prop:string) => {
    setSelectedGLA(null);
    setNewlyAddedVal('');
    setGLAccountModalOpen(true);
    setNewlyAddingProp(prop);
  };

  const handleEditGLA = (id:string) => {
    if (id !== supplierData.defaultGLA[0].code) {
      setSelectedGLA(glAccounts?.find((gla:GLAccountType) => gla?.code === id) || null);
      setGLAccountModalOpen(true);
    }
  };

  const onGLACreated = (val:string) => {
    getAllGLAccounts();
    setNewlyAddedVal(val);
  };
  const onVatCodeCreated = (val:string) => {
    setNewlyAddedVal(val);
  };

  const handleEditCostCenter = (costCenter: string) => {
    if (costCenter !== supplierData.defaultCostCenters[0]?.code) {
      setSelectedCostCenterCode(costCenter);
      setCCModalOpen(true);
      setIsEditCostCenter(true);
    }
  };

  const handleAddCostCenter = (prop:string) => {
    setCCModalOpen(true);
    setNewlyAddingProp(prop);
    setIsEditCostCenter(false);
  };

  const getAllGLAccounts = async () => {
    dispatch(fetchGlas(user?.companyID) as unknown as UnknownAction);
  };

  const getAllVatGroups = () => {
    dispatch(fetchVatCodes(user?.companyID) as unknown as UnknownAction);
  };

  const getAllCostCenters = async () => {
    dispatch(fetchCostCenters(user?.companyID) as unknown as UnknownAction);
  };

  const getAllProjects = async () => {
    try {
      const response = await Axios.get(
        `${API.projectsByCompany}/${encodeURIComponent(user?.companyID as string)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data.length > 0) {
          setProjects([...supplierData.defaultProjects, ...response.data.data]);
        }
      }
    } catch (error) {
      //
    }
  };

  const handleSkip = () =>{
    setSkip((skip)=> !skip);
  }

  const getSupplier = async () => {
    if (!isEdit) {
      return;
    }
    try {
      setLoading(true);
      const response = await Axios.get(
        `${API.suppliersV2}/${clientId}/${user?.companyID}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      
      if (response.data.success) {
        setClient(response.data.data);
        setSupplierProducts(response.data.data.inventories);
        setLoading(false)
      } else {
        handleClose();
        setLoading(false);
      }
    } catch (error) {
      handleClose();
      // setLoading(false);
    }
  };

  const formSchema = Yup.object().shape({
    creditorCode: Yup.string().max(255)
      .when('creditorName', {
        is: () => user?.interfacePreference === 'twinfield_interface',
        then: () => Yup.string().max(255).required('SUPPLIERS_CRED_CODE_REQUIRED')
      }),
    creditorName: Yup.string()
      .max(80, 'SUPPLIERS_CREDITOR_CODE_MAX_VALIDATION')
      .required('SUPPLIERS_CRED_NAME_REQUIRED'),
    fiscalName: Yup.string()
      .when('creditorName', {
        is: () => isFieldAvailable('fiscalName'),
        then: () => Yup.string().max(255, 'SUPPLIERS_FISCAL_NAME_MAX_VALIDATION')
      }),
    autoPaymentStatus: Yup.string().max(255),
    // .required('SUPPLIERS_AUTO_PAYMENT_STATUS_REQUIRED'),
    dueDays: Yup.number()
      .integer('SUPPLIERS_DUE_DAYS_TYPE_VALIDATION')
      .typeError('SUPPLIERS_DUE_DAYS_TYPE_VALIDATION')
      .min(0),
    // .required('SUPPLIERS_DUE_DAYS_REQUIRED'),
    website: Yup.string()
      .when('creditorName', {
        is: () => isFieldAvailable('website'),
        then: () => Yup.string().max(40, 'SUPPLIERS_WEBSITE_MAX_VALIDATION'),
        otherwise:() => Yup.string().required(),
      }),
    // comment: Yup.string().max(255).required('comment is required'),
    companyRegNumber: Yup.string().max(128, 'SUPPLIERS_COMPANY_REG_NUMBER_MAX_VALIDATION'),
    vatNumber: Yup.string()
      .when('creditorName', {
        is: () => isFieldAvailable('vatNumber'),
        then: () => Yup.string().max(128, 'SUPPLIERS_VAT_NUMBER_MAX_VALIDATION')
      }),
    referenceCode: Yup.string().max(40, 'SUPPLIERS_REFERENCE_CODE_MAX_VALIDATION'),
    // status: Yup.string().max(255).required('status is required'),
    // generalLedgerAccount: Yup.string().max(255).required('General Ledge Account is required'),
    // generalLedgerAccLine: Yup.string().max(255).required('General Ledge Account is required'),
    // vatGroup: Yup.string().max(255).required('VAT Group is required'),
    invoiceDescriptionRule: Yup.string()
      .max(100, 'SUPPLIERS_INVOICE_DESCRIPTION_RULE_MAX_VALIDATION'),
    // .required('SUPPLIERS_INVOICE_DESCRIPTION_RULE_REQUIRED'),
    defaultCurrency: Yup.string()
      .when('creditorName', {
        is: () => isFieldAvailable('defaultCurrency'),
        then: () => Yup.string().max(255)
      }),
    // .required('SUPPLIERS_DEFAULT_CURRENCY_REQUIRED'),
    bankCountry: user?.interfacePreference === 'twinfield_interface' ? Yup.string()
      .when('autoPaymentStatus', {
        is: (autoPaymentStatus:string) => autoPaymentStatus !== supplierData.apStatuses[2].code,
        then: () => Yup.string().max(255).required('SUPPLIERS_BANK_COUNTRY_REQUIRED')
      }) : Yup.string().max(255),
    ascription: user?.interfacePreference === 'twinfield_interface' ? Yup.string()
      .when('autoPaymentStatus', {
        is: (autoPaymentStatus:string) => autoPaymentStatus === supplierData.apStatuses[0].code || autoPaymentStatus === supplierData.apStatuses[1].code,
        then: () => Yup.string().required('SUPPLIERS_ASCRIPTION_REQUIRED')
      })
      .max(40, 'SUPPLIERS_ASCRIPTION_MAX_VALIDATION') : Yup.string().max(40, 'SUPPLIERS_ASCRIPTION_MAX_VALIDATION'),
    iban: user?.interfacePreference === 'twinfield_interface' ? Yup.string()
      .when('autoPaymentStatus', {
        is: (autoPaymentStatus:string) => autoPaymentStatus === supplierData.apStatuses[0].code,
        then: () => Yup.string().required('SUPPLIERS_IBAN_REQUIRED')
      })
      .max(40, 'SUPPLIERS_IBAN_MAX_VALIDATION') : Yup.string().max(40, 'SUPPLIERS_IBAN_MAX_VALIDATION'),
    bankAccNumber: Yup.string().max(40, 'SUPPLIERS_BANK_ACC_NUMBER_MAX_VALIDATION'),
    bicSwift: user?.interfacePreference === 'twinfield_interface' ? Yup.string()
      .when('autoPaymentStatus', {
        is: (autoPaymentStatus:string) => autoPaymentStatus === supplierData.apStatuses[1].code,
        then: () => Yup.string().required('SUPPLIERS_BIC_SWIFT_REQUIRED')
      })
      .max(16, 'SUPPLIERS_BIC_SWIFT_MAX_VALIDATION') : Yup.string().max(16, 'SUPPLIERS_BIC_SWIFT_MAX_VALIDATION'),
    nationalBankCode: Yup.string().max(20, 'SUPPLIERS_NATIONAL_BANK_CODE_MAX_VALIDATION'),
    bankName: user?.interfacePreference === 'twinfield_interface' ? Yup.string()
      .when('autoPaymentStatus', {
        is: (autoPaymentStatus:string) => autoPaymentStatus === supplierData.apStatuses[1].code,
        then: () => Yup.string().required('SUPPLIERS_BANK_NAME_REQUIRED')
      })
      .max(40, 'SUPPLIERS_BANK_NAME_MAX_VALIDATION') : Yup.string().max(40, 'SUPPLIERS_BANK_NAME_MAX_VALIDATION'),
    bankCity: user?.interfacePreference === 'twinfield_interface' ? Yup.string()
      .when('autoPaymentStatus', {
        is: (autoPaymentStatus:string) => autoPaymentStatus === supplierData.apStatuses[1].code,
        then: () => Yup.string().required('SUPPLIERS_BANK_CITY_REQUIRED')
      })
      .max(40, 'SUPPLIERS_BANK_CITY_MAX_VALIDATION') : Yup.string().max(40, 'SUPPLIERS_BANK_CITY_MAX_VALIDATION'),
    bankStreet: user?.interfacePreference === 'twinfield_interface' ? Yup.string()
      .when('autoPaymentStatus', {
        is: (autoPaymentStatus:string) => autoPaymentStatus === supplierData.apStatuses[1].code,
        then: () => Yup.string().required('SUPPLIERS_BANK_STREET_REQUIRED')
      })
      .max(128, 'SUPPLIERS_BANK_STREET_MAX_VALIDATION') : Yup.string().max(128, 'SUPPLIERS_BANK_STREET_MAX_VALIDATION'),
    bankHouseNumber: user?.interfacePreference === 'twinfield_interface' ? Yup.string()
      .when('autoPaymentStatus', {
        is: (autoPaymentStatus:string) => autoPaymentStatus === supplierData.apStatuses[1].code,
        then: () => Yup.string().required('SUPPLIERS_BANK_HOUSE_NUMBER_REQUIRED')
      })
      .max(128, 'SUPPLIERS_BANK_HOUSE_NUMBER_MAX_VALIDATION') : Yup.string().max(128, 'SUPPLIERS_BANK_HOUSE_NUMBER_MAX_VALIDATION'),
    postcode: Yup.string().max(16, 'SUPPLIERS_BANK_POSTCODE_MAX_VALIDATION'),
    state: Yup.string().max(40, 'SUPPLIERS_BANK_STATE_MAX_VALIDATION'),
    country: Yup.string().max(255),
    // .required('SUPPLIERS_COUNTRY_REQUIRED'),
    city: user?.interfacePreference === 'twinfield_interface' ? Yup.string()
      .when('autoPaymentStatus', {
        is: (autoPaymentStatus:string) => autoPaymentStatus === supplierData.apStatuses[1].code,
        then: () => Yup.string().required('SUPPLIERS_CITY_REQUIRED')
      })
      .max(40, 'SUPPLIERS_CITY_MAX_VALIDATION') : Yup.string().max(40, 'SUPPLIERS_CITY_MAX_VALIDATION'),
    postalCode: Yup.string().max(16, 'SUPPLIERS_POSTAL_CODE_MAX_VALIDATION'),
    address1: user?.interfacePreference === 'twinfield_interface' ? Yup.string()
      .when('autoPaymentStatus', {
        is: (autoPaymentStatus:string) => autoPaymentStatus === supplierData.apStatuses[1].code,
        then: () => Yup.string().required('SUPPLIERS_ADDRESS1_REQUIRED')
      })
      .max(128, 'SUPPLIERS_ADDRESS1_MAX_VALIDATION') : Yup.string().max(128, 'SUPPLIERS_ADDRESS1_MAX_VALIDATION'),
    address2: Yup.string().max(128, 'SUPPLIERS_ADDRESS2_MAX_VALIDATION'),
    // poBox: Yup.string().max(255).required('poBox is required'),
    // poBoxPostalCode: Yup.string().max(255).required('poBoxPostalCode is required'),
    // poBoxCity: Yup.string().max(255).required('poBoxCity is required'),
    contactPersonName: Yup.string().max(128, 'SUPPLIERS_CONTACT_PERSON_MAX_VALIDATION'),
    telephoneNumber: Yup.string()
      .when('creditorName', {
        is: () => isFieldAvailable('telephoneNumber'),
        then: () => Yup.string().max(25, 'SUPPLIERS_TELEPHONE_NUMBER_MAX_VALIDATION')
      }),
    faxNumber: Yup.string()
      .when('creditorName', {
        is: () => isFieldAvailable('faxNumber'),
        then: () => Yup.string().max(25, 'SUPPLIERS_FAX_NUMBER_MAX_VALIDATION')
      }),
    mobileTelNumber: Yup.string().max(128, 'SUPPLIERS_MOBILE_TEL_NUMBER_MAX_VALIDATION'),
    emailAddress: Yup.string()
      .when('creditorName', {
        is: () => isFieldAvailable('emailAddress'),
        then: () => Yup.string().max(200, 'SUPPLIERS_EMAIL_ADDRESS_MAX_VALIDATION')
      }),
    discountDays: Yup.number()
      .integer('SUPPLIERS_DISCOUNT_DAYS_TYPE_VALIDATION')
      .typeError('SUPPLIERS_DISCOUNT_DAYS_TYPE_VALIDATION'),
    discountPercentage: Yup.number().typeError('SUPPLIERS_DISCOUNT_PERCENTAGE_TYPE_VALIDATION'),
    custom1: Yup.string()
      .when('creditorName', {
        is: () => isFieldAvailable('custom1'),
        then: () => Yup.string().max(255, 'SUPPLIERS_CUSTOM_FIELD_01_MAX_VALIDATION')
      }),
    custom2: Yup.string()
      .when('creditorName', {
        is: () => isFieldAvailable('custom2'),
        then: () => Yup.string().max(255, 'SUPPLIERS_CUSTOM_FIELD_02_MAX_VALIDATION')
      }),
    custom3: Yup.string()
      .when('creditorName', {
        is: () => isFieldAvailable('custom3'),
        then: () => Yup.string().max(255, 'SUPPLIERS_CUSTOM_FIELD_03_MAX_VALIDATION')
      }),
  })

  const formInitialValues = {
    skipSupplierReview: !isEdit ? false :  client?.skipSupplierReview || false,
    creditorCode: !isEdit ? '' :  client?.creditorCode || '',
    creditorName: !isEdit ? '' : client?.creditorName || '',
    fiscalName: !isEdit ? '' : client?.fiscalName || '',
    autoPaymentStatus: !isEdit ? '' : client?.autoPaymentStatus || supplierData.apStatuses[2].code,
    dueDays: !isEdit ? '' : client?.dueDays || '',
    website: !isEdit ? '' : client?.website || '',
    comment: !isEdit ? '' : client?.comment || '',
    companyRegNumber: !isEdit ? '' : client?.companyRegNumber || '',
    vatNumber: !isEdit ? '' : client?.vatNumber || '',
    referenceCode: !isEdit ? '' : client?.referenceCode || '',
    status: !isEdit ? '' : client?.status || '',
    generalLedgerAccount: !isEdit ? '' : client?.generalLedgerAccount || supplierData.defaultGLA[0].code,
    generalLedgerAccLine: !isEdit ? '' : client?.generalLedgerAccLine || supplierData.defaultGLA[0].code,
    vatGroup: !isEdit ? '' : selectedVATGroupCode?.code || client?.vatGroup || supplierData.defaultVatGroup[0].code,
    vatGroupLine: !isEdit ? '' : client?.vatGroupLine || supplierData.defaultVatGroup[0].code,
    costCenter: !isEdit ? '' : client?.costCenter || supplierData.defaultCostCenters[0].code,
    project: !isEdit ? '' : client?.project || supplierData.defaultProjects[0].code,
    invoiceDescriptionRule: !isEdit ? '' : client?.invoiceDescriptionRule || '',
    invoiceLineDescriptionRule: !isEdit ? '' : client?.invoiceLineDescriptionRule || '',
    defaultCurrency: !isEdit ? '' : client?.defaultCurrency || '',
    bankCountry: !isEdit ? '' : client?.bankCountry || '',
    ascription: !isEdit ? '' : client?.ascription || '',
    iban: !isEdit ? '' : client?.iban || '',
    bankAccNumber: !isEdit ? '' : client?.bankAccNumber || '',
    bicSwift: !isEdit ? '' : client?.bicSwift || '',
    nationalBankCode: !isEdit ? '' : client?.nationalBankCode || '',
    bankName: !isEdit ? '' : client?.bankName || '',
    bankCity: !isEdit ? '' : client?.bankCity || '',
    bankStreet: !isEdit ? '' : client?.bankStreet || '',
    bankHouseNumber: !isEdit ? '' : client?.bankHouseNumber || '',
    country: !isEdit ? '' : client?.country || 'ES',
    city: client?.city || '',
    postalCode: !isEdit ? '' : client?.postalCode || '',
    address1: !isEdit ? '' : client?.address1 || '',
    address2: !isEdit ? '' : client?.address2 || '',
    poBox: !isEdit ? '' : client?.poBox || '',
    poBoxPostalCode: !isEdit ? '' : client?.poBoxPostalCode || '',
    poBoxCity: !isEdit ? '' : client?.poBoxCity || '',
    contactPersonName: !isEdit ? '' : client?.contactPersonName || '',
    telephoneNumber: !isEdit ? '' : client?.telephoneNumber || '',
    faxNumber: !isEdit ? '' : client?.faxNumber || '',
    mobileTelNumber: !isEdit ? '' : client?.mobileTelNumber || '',
    emailAddress: !isEdit ? '' : client?.emailAddress || '',
    postcode: !isEdit ? '' : client?.postcode || '',
    state: !isEdit ? '' : client?.state || '',
    addressName: !isEdit ? '' : client?.addressName || '',
    shortname: !isEdit ? '' : client?.shortname || '',
    discountDays: !isEdit ? '' : client?.discountDays || '',
    discountPercentage: !isEdit ? '' : client?.discountPercentage || '',
    matchType:!isEdit ?'' : client?.matchtype || '',
    custom1: !isEdit ? '' : client?.custom1 || '',
    custom2: !isEdit ? '' : client?.custom2 || '',
    custom3: !isEdit ? '' : client?.custom3 || '',
  }

  useEffect(() => {
    setIsTwinfield(user?.accountingSystem === 'twinfield');
    getAllProjects();
    getGLAPeriods();
    getGLAYears();
    const allCountries: CountryType[] = Object.keys(aCountries as Record<string,CCountryType>)?.map((key:string) => ({
      isoCode: key,
      name: aCountries[key]?.name,
    }));
    setCountriesList(allCountries);
    setCurrencies( cc.sort((a:CurrencyType, b:CurrencyType) => {
        if (a.code < b.code) {
          return -1;
        }
        return 1;
      })
    );
  }, []);

  useEffect(() => {
    if(isEdit && clientId){
      if (isActionPermitted(permissions.dimensionEdit, user?.permissions) && clientId && clientId.toString() !== '-1') {
        getSupplier();
      }
    }
  }, [clientId]);

  if(isEdit && clientId && !client){
    return null
  }
  
  return (
    <>
     <Modal
      open={showAddClient}
      sx={styles.modal}
      onClose={() => handleClose()}
     >
      <Fade in={showAddClient}>
        <div>
          <Formik
            initialValues={formInitialValues}
            validationSchema={formSchema}
            onSubmit={isEdit?
              //if edit call save api endpoint
              async (values, {
                resetForm,
                setErrors,
                setStatus,
                setSubmitting
              }) => {
                try {
                  if (!authService.validateToken()) {
                    enqueueSnackbar(ready && t('PROCYS_LOGIN_SESSION_EXPIRED'), {
                      variant: 'error',
                    });
                    setTimeout(() => {
                      authService.logout(LOGIN_PANEL_URL);
                    }, 2000);
                    return;
                  }
                  setLoading(true);
                  const body = {
                    skipSupplierReview: skip,
                    creditorCode: values.creditorCode,
                    creditorName: values.creditorName,
                    fiscalName: values.fiscalName,
                    autoPaymentStatus: values.autoPaymentStatus,
                    dueDays: values.dueDays,
                    website: values.website,
                    comment: values.comment,
                    companyRegNumber: values.companyRegNumber,
                    vatNumber: values.vatNumber,
                    referenceCode: values.referenceCode,
                    status: values.status,
                    generalLedgerAccount: (values.generalLedgerAccount === supplierData.defaultGLA[0].code && isTwinfield)
                      ? '1600'
                      : values.generalLedgerAccount === supplierData.defaultGLA[0].code
                        ? ''
                        : values.generalLedgerAccount,
                    generalLedgerAccLine: (values.generalLedgerAccLine === supplierData.defaultGLA[0].code && isTwinfield)
                      ? '1600'
                      : values.generalLedgerAccLine === supplierData.defaultGLA[0].code
                        ? ''
                        : values.generalLedgerAccLine,
                    vatGroup: values.vatGroup === supplierData.defaultVatGroup[0].code ? '' : values.vatGroup,
                    vatGroupLine: values.vatGroupLine === supplierData.defaultVatGroup[0].code ? '' : values.vatGroupLine,
                    costCenter: values.costCenter === supplierData.defaultCostCenters[0].code ? '' : values.costCenter,
                    project: values.project === supplierData.defaultProjects[0].code ? '' : values.project,
                    invoiceDescriptionRule: values.invoiceDescriptionRule,
                    invoiceLineDescriptionRule: values.invoiceLineDescriptionRule,
                    defaultCurrency: values.defaultCurrency,
                    bankCountry: values.bankCountry,
                    ascription: values.ascription,
                    iban: values.iban,
                    bankAccNumber: values.bankAccNumber,
                    bicSwift: values.bicSwift,
                    nationalBankCode: values.nationalBankCode,
                    bankName: values.bankName,
                    bankCity: values.bankCity,
                    bankStreet: values.bankStreet,
                    bankHouseNumber: values.bankHouseNumber,
                    country: values.country,
                    city: values.city,
                    postalCode: values.postalCode,
                    address1: values.address1,
                    address2: values.address2,
                    poBox: values.poBox,
                    poBoxPostalCode: values.poBoxPostalCode,
                    poBoxCity: values.poBoxCity,
                    contactPersonName: values.contactPersonName,
                    telephoneNumber: values.telephoneNumber,
                    faxNumber: values.faxNumber,
                    mobileTelNumber: values.mobileTelNumber,
                    emailAddress: values.emailAddress,
                    postcode: values.postcode,
                    state: values.state,
                    addressName: values.addressName,
                    shortname: values.shortname,
                    discountDays: values.discountDays,
                    discountPercentage: values.discountPercentage,
                    matchType: values.matchType,
                    custom1: values.custom1,
                    custom2: values.custom2,
                    custom3: values.custom3,
                    inventories: products
                  };
      
                  // Validate DueDays and DiscountDays
                  const dueDays = parseInt(values.dueDays, 10);
                  const discountDays = parseInt(values.discountDays, 10);
                  if (dueDays > 0 && discountDays > 0 && dueDays < discountDays) {
                    setStatus({ success: false });
                    setSubmitting(false);
                    setErrors({
                      discountDays: 'SUPPLIERS_DISCOUNT_DAYS_DUEDAYS_VALIDATION'
                    });
                    return;
                  }
      
                  const response = await Axios.put(
                    `${API.suppliers}`,
                    body,
                    axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
                  );
                  if (response.data.success) {
                    setLoading(false);
                    resetForm();
                    setStatus({ success: true });
                    setSubmitting(false);
                    enqueueSnackbar(ready && t('SUPPLIERS_UPDATE_SUCCESS'), {
                      variant: 'success',
                      style: { maxWidth: 400 }
                    });
                    getAllSuppliers();
                    handleClose();
                  }
                } catch (e) {
                  const error = e as CustomError
                  setLoading(false);
                  setStatus({ success: false });
                  setSubmitting(false);
                  if (error && error?.response && error?.response?.data && error?.response?.data?.i18n && 
                    error?.response?.data?.i18n?.indexOf('__TFMSG__') > 0) {
                    const errParts = error?.response?.data?.i18n?.split('__TFMSG__');
                    if(errParts)
                    setErrors({
                      [errParts[0]]: errParts[1]
                    });
                  } else {
                    setErrors({
                      submit: getLocalisedErrorString(getLocalisedErrorString(error?.response?.data?.i18n, t))
                    } as FormikErrors<Record<string,string>>);
                  }
                }
              }
              :async (values, {
                resetForm,
                setErrors,
                setStatus,
                setSubmitting
              }) => {
                if (!authService.validateToken()) {
                  enqueueSnackbar(ready && t('PROCYS_LOGIN_SESSION_EXPIRED'), {
                    variant: 'error',
                  });
                  setTimeout(() => {
                    authService.logout(LOGIN_PANEL_URL);
                  }, 2000);
                  return;
                }
                setLoading(true);
                try {
                  const body = {
                    skipSupplierReview: skip,
                    creditorCode: isFieldAvailable('creditorCode') ? values.creditorCode : undefined,
                    creditorName: values.creditorName,
                    fiscalName: isFieldAvailable('fiscalName') ? values.fiscalName : undefined,
                    autoPaymentStatus: values.autoPaymentStatus,
                    dueDays: isFieldAvailable('dueDays') ? values.dueDays : undefined,
                    website: isFieldAvailable('website') ? values.website : undefined,
                    comment: isFieldAvailable('comment') ? values.comment : undefined,
                    companyRegNumber: isFieldAvailable('companyRegNumber') ? values.companyRegNumber : undefined,
                    vatNumber: isFieldAvailable('vatNumber') ? values.vatNumber : undefined,
                    referenceCode: isFieldAvailable('referenceCode') ? values.referenceCode : undefined,
                    status: isFieldAvailable('status') ? values.status : undefined,
                    generalLedgerAccount: (values.generalLedgerAccount === supplierData.defaultGLA[0].code && isTwinfield)
                      ? '1600'
                      : values.generalLedgerAccount === supplierData.defaultGLA[0].code
                        ? ''
                        : values.generalLedgerAccount,
                    generalLedgerAccLine: calculateGLAVal('generalLedgerAccLine', values.generalLedgerAccLine),
                    vatGroup: values.vatGroup === supplierData.defaultVatGroup[0].code ? '' : values.vatGroup,
                    vatGroupLine: !isFieldAvailable('vatGroupLine')
                      ? undefined
                      : values.vatGroupLine === supplierData.defaultVatGroup[0].code ? '' : values.vatGroupLine,
                    costCenter: !isFieldAvailable('costCenter')
                      ? undefined
                      : values.costCenter === supplierData.defaultCostCenters[0].code ? '' : values.costCenter,
                    project: !isFieldAvailable('project')
                      ? undefined
                      : values.project === supplierData.defaultProjects[0].code ? '' : values.project,
                    invoiceDescriptionRule: isFieldAvailable('invoiceDescriptionRule') ? values.invoiceDescriptionRule : undefined,
                    invoiceLineDescriptionRule: isFieldAvailable('invoiceLineDescriptionRule') ? values.invoiceLineDescriptionRule : undefined,
                    defaultCurrency: isFieldAvailable('defaultCurrency') ? values.defaultCurrency : undefined,
                    bankCountry: isFieldAvailable('bankCountry') ? values.bankCountry : undefined,
                    ascription: isFieldAvailable('ascription') ? values.ascription : undefined,
                    iban: isFieldAvailable('iban') ? values.iban : undefined,
                    bankAccNumber: isFieldAvailable('bankAccNumber') ? values.bankAccNumber : undefined,
                    bicSwift: isFieldAvailable('bicSwift') ? values.bicSwift : undefined,
                    nationalBankCode: isFieldAvailable('nationalBankCode') ? values.nationalBankCode : undefined,
                    bankName: isFieldAvailable('bankName') ? values.bankName : undefined,
                    bankCity: isFieldAvailable('bankCity') ? values.bankCity : undefined,
                    bankStreet: isFieldAvailable('bankStreet') ? values.bankStreet : undefined,
                    bankHouseNumber: isFieldAvailable('bankHouseNumber') ? values.bankHouseNumber : undefined,
                    country: values.country,
                    city: isFieldAvailable('city') ? values.city : undefined,
                    postalCode: isFieldAvailable('postalCode') ? values.postalCode : undefined,
                    address1: isFieldAvailable('address1') ? values.address1 : undefined,
                    address2: isFieldAvailable('address2') ? values.address2 : undefined,
                    poBox: isFieldAvailable('poBox') ? values.poBox : undefined,
                    poBoxPostalCode: isFieldAvailable('poBoxPostalCode') ? values.poBoxPostalCode : undefined,
                    poBoxCity: isFieldAvailable('poBoxCity') ? values.poBoxCity : undefined,
                    contactPersonName: isFieldAvailable('contactPersonName') ? values.contactPersonName : undefined,
                    telephoneNumber: isFieldAvailable('telephoneNumber') ? values.telephoneNumber : undefined,
                    faxNumber: isFieldAvailable('faxNumber') ? values.faxNumber : undefined,
                    mobileTelNumber: isFieldAvailable('mobileTelNumber') ? values.mobileTelNumber : undefined,
                    emailAddress: isFieldAvailable('emailAddress') ? values.emailAddress : undefined,
                    postcode: isFieldAvailable('postcode') ? values.postcode : undefined,
                    state: isFieldAvailable('state') ? values.state : undefined,
                    addressName: isFieldAvailable('addressName') ? values.addressName : undefined,
                    shortname: isFieldAvailable('shortname') ? values.shortname : undefined,
                    discountDays: isFieldAvailable('discountDays') ? values.discountDays : undefined,
                    discountPercentage: isFieldAvailable('discountPercentage') ? values.discountPercentage : undefined,
                    matchType: isFieldAvailable('matchType') ? values.matchType : undefined,
                    custom1: isFieldAvailable('custom1') ? values.custom1 : undefined,
                    custom2: isFieldAvailable('custom2') ? values.custom2 : undefined,
                    custom3: isFieldAvailable('custom3') ? values.custom3 : undefined,
                    inventories: products
                  };

                  // Validate DueDays and DiscountDays
                  const dueDays = parseInt(values.dueDays, 10);
                  const discountDays = parseInt(values.discountDays, 10);
                  if (dueDays > 0 && discountDays > 0 && dueDays < discountDays) {
                    setStatus({ success: false });
                    setSubmitting(false);
                    setErrors({
                      discountDays: 'SUPPLIERS_DISCOUNT_DAYS_DUEDAYS_VALIDATION'
                    });
                    return;
                  }

                  const response = await Axios.post(
                    `${API.suppliers}`,
                    body,
                    axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
                  );
                  if (response.data.success) {
                    setLoading(false);
                    resetForm();
                    setStatus({ success: true });
                    setSubmitting(false);
                    enqueueSnackbar(ready && t('SUPPLIERS_ADD_SUCCESS'), {
                      variant: 'success',
                      style: { maxWidth: 400 }
                    });
                    onSuccess(response.data.id);
                    getAllSuppliers();
                  }
                } catch (e) {
                  const error = e as CustomError;
                  setLoading(false);
                  setStatus({ success: false });
                  setSubmitting(false);
                  if (error && error?.response && error?.response?.data &&
                    error?.response?.data?.i18n && error?.response?.data?.i18n?.indexOf('__TFMSG__') > 0) {
                    const errParts = error?.response?.data?.i18n?.split('__TFMSG__');
                    setErrors({
                      [errParts[0]]: errParts[1]
                    });
                  } else {
                    setErrors({
                      submit: getLocalisedErrorString( getLocalisedErrorString(error?.response?.data?.i18n, t))
                    } as FormikErrors<Record<string,string>>);
                  }
                }
              }
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <Box sx={styles.root}>
                <Box sx={styles.wrapper}>
                  <Box sx={styles.topBar}>
                    <Typography sx={styles.topTitle}>{!isEdit?ready && t('DIMENSION_NEW_CLIENT'): 
                    t('DASHBOARD_NAV_CLIENT') + ' - ' +  client?.creditorName + `(${client?.creditorCode})`}</Typography>
                    <Button sx={styles.topIconContainer}
                      onClick={()=>handleClose()}
                    >
                      <CloseIcon sx={styles.topIcon} />              
                    </Button>
                  </Box>
                  {
                    showManageFields ? 
                    <Box sx={styles.manageFieldsContainer}>
                      <ManageFieldsNew
                        documentType="dimension"
                        section="supplier"
                        fields={supplierFields}
                        fetchFields={getSupplierFields}
                        handleCloseManageFields={handleCloseManageFields}
                        parameterCompany={user && user?.companyID ? user?.companyID : '' }
                      />
                    </Box>
                    : 
                    user?.interfacePreference === 'twinfield_interface' ?
                    <>
                      <Box sx={styles.topManager}>
                        {isDisabled ? <></> :
                        <Button sx={styles.topManagerBtn} onClick={handleShowManageProducts}>
                          <ProductsIcon sx={styles.topManagerBtnIcon} />
                          <Typography sx={styles.topManagerBtnText}>
                            {t('SUPPLIER_FORM_MANAGE_PRODUCTS')}
                          </Typography>
                        </Button>}
                      </Box>
                      <ClientTwinFieldForm
                        isInvoiceEditForm={false}
                        isEdit={Boolean(isEdit)}
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleSubmit={handleSubmit}
                        isSubmitting={isSubmitting}
                        handleEditSupplierClose={handleClose}
                        handleChange={handleChange}
                        handleChangeAutocomplete={handleChangeAutocomplete}
                        handleEditGLA={handleEditGLA}
                        handleAddGLA={handleAddGLA}
                        glAccounts={glAccounts}
                        handleEditVATCode={handleEditVATCode}
                        handleAddVATCode={handleAddVATCode}
                        vatGroups={vatGroups}
                        handleEditCostCenter={handleEditCostCenter}
                        handleAddCostCenter={handleAddCostCenter}
                        costCenters={costCenters}
                        projects={projects}
                        currencies={currencies}
                        countries={countriesList}
                        xmlSelectedValue={xmlSelectedValue}
                        textFieldSelectedKey={textFieldSelectedKey}
                        textFieldSelectedValueRef={textFieldSelectedValueRef}
                        setTextFieldSelectedKey={setTextFieldSelectedKey}
                        setTextFieldSelectedValue={setTextFieldSelectedValue}
                        newlyAddedVal={newlyAddedVal}
                        newlyAddingProp={newlyAddingProp}
                        isDisabled={isDisabled}
                      />  
                    </>:
                    <>
                      <Box sx={styles.topManager}>
                        <Button sx={styles.topManagerBtn} onClick={handleShowManageFields}>
                          <FieldsIcon sx={styles.topManagerBtnIcon} />
                          <Typography sx={styles.topManagerBtnText}>
                            {t('INVOICE_EDIT_FORM_MANAGE_FIELDS')}
                          </Typography>
                        </Button>
                        {isDisabled ? <></> :
                        <Button sx={styles.topManagerBtn} onClick={handleShowManageProducts}>
                          <ProductsIcon sx={styles.topManagerBtnIcon} />
                          <Typography sx={styles.topManagerBtnText}>
                            {t('SUPPLIER_FORM_MANAGE_PRODUCTS')}
                          </Typography>
                        </Button>}
                      </Box>
                      <ClientStandardForm
                        handleSubmit={handleSubmit}
                        handleClose={handleClose}
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        setTextFieldSelectedKey={setTextFieldSelectedKey}
                        textFieldSelectedKey={textFieldSelectedKey}
                        isEdit={Boolean(isEdit)}
                        setTextFieldSelectedValue={setTextFieldSelectedValue}
                        handleChangeAutocomplete={handleChangeAutocomplete}
                        glAccounts={glAccounts}
                        vatGroups={vatGroups}
                        currencies={currencies}
                        countries={countriesList}
                        handleEditGLA={handleEditGLA}
                        handleAddGLA={handleAddGLA}
                        handleAddVATCode={handleAddVATCode}
                        handleEditVATCode={handleEditVATCode}
                        supplierFields={supplierFields}
                        projects={projects}
                        handleEditCostCenter={handleEditCostCenter}
                        handleAddCostCenter={handleAddCostCenter}
                        costCenters={costCenters}
                        isSubmitting={isSubmitting}
                        preferredFieldsLoading={preferredFieldsLoading}
                        skip={skip}
                        handleSkip={handleSkip}
                        isDisabled={isDisabled}
                        newlyAddedVal={newlyAddedVal}
                        newlyAddingProp={newlyAddingProp}
                        selectedVatCode={selectedVATGroupCode}
                      />
                    </>
                    }
                </Box>
              </Box>  
            )}
          </Formik>
        </div>
      </Fade>
    </Modal>
    <ManageClientProduct
      open={showManageProduct}
      handleClose={handleCloseManageProduct}
      products={products}
      updateProducts={updateSupplierProducts}
      supplier={client}
    />
    <GLAccountModal
      gLAccountModalOpen={gLAccountModalOpen}
      handleClose={onGLAModalClose}
      getAllGLAccounts={getAllGLAccounts}
      getVatCodes={getAllVatGroups}
      onSuccess={onGLACreated}
      selectedGLA={selectedGLA}
      glaYears={glaYears}
      glaPeriods={glaPeriods}    
      isDisabled={isDisabled}  
    />
    <VatCodeModal
      vatCodeModalOpen={vatCodeModalOpen}
      handleClose={onVatCoddeModalClose}
      getAllVatCodes={getAllVatGroups}
      onSuccess={onVatCodeCreated}
      selectedVatCode={selectedVATGroupCode}
      isDisabled={isDisabled}
    />
    <CostCenterModal
      costCenterModalOpen={ccModalOpen}
      handleClose={onCCModalClose}
      getAllCostCenters={getAllCostCenters}
      onSuccess={onCCCreated}
      selectedCostCenter={selectedCostCenterCode}
      isEdit={isEditCostCenter}
    />
    <Backdrop open={loading} sx={{ zIndex: 111111, color: '#ffffff' }}>
      <CircularProgress />
    </Backdrop>
  </>

   
  )
}

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 0px'
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100dvw',
    height: '90dvh',
  },
  wrapper: {
    height: '100%',
    width: '700px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0px 4px 18px 3px #4C4E641F, 0px 10px 14px 1px #4C4E6424, 0px 6px 6px -3px #4C4E6433',
    paddingBlockEnd: '10px'
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 24px',
  },
  topTitle: {
    color: '#4C4E64',
    fontSize: '20px',
    fontWeight: '500',
    textTransform: 'capitalize',
    lineHeight: '32px'
  },
  topIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItem: 'center',
    padding: '0',
    minWidth: '28px',
    maxWidth: '28px',
    width: '28px',
    height: '28px'
  },
  topIcon: {
    color: '#6D788D',
    fontSize: '16px',
    width: '20px',
    height: '20px'
  },
  topManager: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '12px',
    padding: '12px 16px',
    borderTop: '1px solid #4C4E641F',
    borderBottom: '1px solid #4C4E641F'
  },
  topManagerBtn: {
    display: 'flex',
    width: 'calc(50% - 6px)',
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: '30px',
    textTransform: 'capitalize',
    gap: '12px',
    fontSize: '13px'
  },
  topManagerBtnText: {
    fontSize: '13px',
    fontWeight: '500',
    color: '#3E8AFF'
  },
  topManagerBtnIcon: {
    width: '20px',
    height: '20px'
  },
  manageFieldsContainer: {
    backgroundColor: '#fff',
    position: 'relative',
    top: '-20px',
    maxHeight: 'calc(90dvh - 70px)',
    minHeight: 'calc(90dvh - 70px)',
  }
}

export default AddClientModal
