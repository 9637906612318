import theme from "src/themeNew";

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0',
    margin: '0px',
    '& .MuiDialog-paper': {
      margin: '0px',
      borderRadius: '0',
    },
    '& .MuiPaper-root': {
      height: '100%',
      maxHeight: '100%',
    }
  },
  container: {
    height: '100%',
    width: '370px',
    display: 'flex',
    flexDirection: 'column'
  },
  modalHeader: {
    height: '72px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderBottom: `1px solid ${theme.palette.border.header}`,
    padding: '16px',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerTitle: {
    fontSize: '18px',
    fontWeight: '500',
    color: theme.palette.text.primary,
    lineHeight: '28px',
    letter: '-0.25px',
  },
  closeBtn: {
    padding: '0px',
  },
  closeIcon: {
    height: '18px',
    width: '18px',
    color: theme.palette.text.secondary,
  },
  modalContent: {
    flex: 1,
    padding: '20px 24px',
  },
  contentText: {
    fontSize: '14px',
    fontWeight: '500',
    color: theme.palette.text.primary,
    lineHeight: '24px',
  },
  jsonContainer: {
    position: 'relative',
    width: '100%',
    bgcolor: theme.palette.code.light,
    borderRadius: '2px',
  },
  jsonPre: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word' as const,
    backgroundColor: theme.palette.code.light,
    color: theme.palette.text.primary,
    fontSize: '13px',
    fontWeight: '400',
  },
  copyBtnContainer: {
    position: 'relative',
    width: '100%',
    height: '40px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '20px',
  },
  copyIconContainer: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    display: 'flex',
    alignItems: 'center',
  },
  copyBtn: {
    padding: '6px',
  },
  copyIcon: {
    width: '18px',
    height: '18px',
  },
  checkIcon: {
    color: '#4caf50',
    width: '18px',
    height: '18px',
  },
  detailRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: '8px'
  },
  labelWrapper: {
    display: 'flex',
  },
  labelText: {
    fontSize: '14px',
    fontWeight: '500',
    color: theme.palette.text.primary,
  },
  inputField: {
    fontSize: '14px',
    color: theme.palette.text.primary,
    border: 0,
    backgroundColor: theme.palette.code.light,
    padding: '8px 12px',
    flex: 1,
    margin: '0px',
    borderRadius: '4px'
  },
}

export default styles;
