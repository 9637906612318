import { TFunction } from "i18next";
import { FieldType } from "src/types";

export const fieldSearch = (fields: FieldType[], t: TFunction<"translation", undefined>, query: string) => {
  if (!query.trim()) return fields;

  const lowerCaseQuery = query.toLowerCase();
  
  const filtered = fields.filter(({ key, label }) => {
    return (
      (key && key.toLowerCase().includes(lowerCaseQuery)) ||
      (label && t(label).toLowerCase().includes(lowerCaseQuery))
    );
  });

  return filtered;
};
