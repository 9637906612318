import { useEffect, useState } from 'react';
import Axios from 'axios';
import { PDFDocument } from 'pdf-lib';

import {
  Backdrop, Modal, CircularProgress, Box
} from '@mui/material';

import { useConfig } from 'src/hooks/useConfig';
import PdfView from 'src/documents-new/components/SplitMenu/PdfView/PdfView';
import SideMenu from './SideMenu/SideMenu';
import { DocType } from 'src/types';
import styles from './style';

type PropsType = {
  duplicateMenuOpen:boolean;
  handleClose:Function;
  doc:any;
  handleProcessDocument: (_doc: DocType, _action: string) => Promise<void>
}

const DuplicateMenu = ( props:PropsType ) => {
  const { duplicateMenuOpen, handleClose, doc, handleProcessDocument } = props;
  const { API } = useConfig();
  const [loading, setLoading] = useState<boolean>(true);
  const [document, setDocument] = useState<any>(null);
  const [totalPages, setTotalPages] = useState<number>(0);
  
  async function getPageCount(res: any) : Promise<number>{
    let formPdfBytes = await res.arrayBuffer();
    const pdfDoc = await PDFDocument.load(formPdfBytes);
    const pageCount = pdfDoc.getPageCount();
    return pageCount;
  }
  const getImage = async (name:string) => {
    if (!name) {
      return;
    }
    setLoading(true);
    
    try {
      const response = await Axios.get(
        `${API.getInvoiceImage}${name}`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `bearer ${localStorage.getItem('PROCYS_accessToken')}`
          }
        }
      );
      const blob = response.data;
      const file = window.URL.createObjectURL(blob);
      let doc = await getPageCount(blob);
      setTotalPages(doc);
      setDocument(file);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const closeSplitMenu = ()=>{
    handleClose();
  }

  useEffect(()=>{
    const getStarted = async () => {
      const nameParts = doc?.documentFile?.split('.');
      let imageName = '';
      if (nameParts && nameParts[nameParts.length - 1] !== 'pdf') {
        imageName = `${nameParts[0]}-display.pdf`;
      } else {
        imageName = doc.documentFile;
      }
      await getImage(imageName);
      setLoading(false);
    }
    getStarted();
  },[doc])

  return (
    <>
      <Modal
        style={styles.modal}
        open={duplicateMenuOpen}
        onClose={() => closeSplitMenu()}
      >
        <Box sx={styles.root} id='pdf-viewer-div' className="dashed-grid-paper">
          <SideMenu onClose={closeSplitMenu} doc={doc} handleProcessDocument={handleProcessDocument} />
          <PdfView document={document} totalPages={totalPages}  />
        </Box>
      </Modal>
      <Backdrop style={styles.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default DuplicateMenu