import theme from "src/themeNew";

const styles = {
  tableWrapper: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  contentWrapper: {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  emptyText: {
    color: theme.palette.text.primary,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500,
    flex: 1,
    padding: '32px',
    textAlign: 'center',
  },
  table: {
  },
  tableHeadRow: {
    width: '100%',
    backgroundColor: theme.palette.background.tableHeader,
    padding: '19px 15px 19px 0!important',
    alignItems: 'center',
    height: '56px',
  },
  tableBodyRow: {
    width: '100%',
    backgroundColor: theme.palette.background.white,
    padding: '20px 15px 20px 0',
    alignItems: 'center',
    borderRadius: '8px 8px 0 0',
    height: '56px',
  },
  tableHeadRowDisplay: {
    borderImage: 'linear-gradient(transparent 30%, #4C4E641F 30% 70%, transparent 70%) 0 1 0 0/2px',
    fontSize: '12px',
    color: theme.palette.text.primary,
    lineHeight: '1.2',
    borderBottom: 'none',
    textOverflow: 'ellipsis',
    overflow: 'clip visible',
    whiteSpace: 'nowrap',
    paddingRight: '10px !important',
    textTransform: 'uppercase',
  },
  tableRowDisplay: {
    fontSize: '12px',
    color: theme.palette.text.primary,
    lineHeight: '1.2',
    borderBottom: '1px solid #4C4E641F',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingRight: '10px!important',
  },
  tableCellRow: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  text: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: '400',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingRight: '10px',
    lineHeight: '22px'
  },
  actionIconContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    width: '100%',
    justifyContent: 'flex-end',
    paddingRight: '10px'
  },
  statusChipActive: {
    height: '32px',
    minWidth: '64px',
    borderRadius: '500px',
    padding: '6px 12px',
    backgroundColor: theme.palette.background.primaryLight,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  statusChipInactive: {
    height: '32px',
    minWidth: '64px',
    borderRadius: '500px',
    padding: '6px 12px',
    backgroundColor: theme.palette.background.grayLight,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  statusLabelActive: {
    fontSize: '13px',
    fontWeight: '500',
    lineHeight: '20px',
    color: theme.palette.primary.main,
  },
  statusLabelInactive: {
    fontSize: '13px',
    fontWeight: '500',
    lineHeight: '20px',
    color: theme.palette.text.secondary,
  },
  defaultChip: {
    height: '24px',
    borderRadius: '500px',
    padding: '4px 8px',
    backgroundColor: theme.palette.background.primaryLight,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  defaultLabel: {
    fontSize: '10px',
    fontWeight: '500',
    lineHeight: '16px',
    color: theme.palette.primary.main,
  },
  defaultIcon: {
    color: theme.palette.primary.main,
    fontSize: '20px',
  },
}

export default styles;
