import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Tooltip, Typography } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

import ConfigContext from 'src/contexts/ConfigContext';
import styles from './style';

interface PropTypes {
  item: Record<string, string>;
  isSelected: boolean;
  selectDocType: (_docType: string) => void;
}

const SelectDocTypeItem = (props: PropTypes) => {
  const { t, ready, i18n } = useTranslation();
  const { BRAND_NAME } = useContext(ConfigContext);

  const { item, isSelected, selectDocType } = props;

  return (
    <Grid
      item
      xs={6}
      md={4}
      lg={3}
      key={item.id}
      onClick={() => selectDocType(item.documentType)}
    >
      <Grid sx={isSelected ? styles.listItemSelected : styles.listItem}>
        <Tooltip title={ready && (i18n.exists(`${item.documentType}_DOCTYPE_TITLE`) ? t(`${item.documentType}_DOCTYPE_TITLE`) : item.name)}>
          <Typography sx={isSelected ? styles.listItemTitleSelected : styles.listItemTitle}>
            {ready && (i18n.exists(`${item.documentType}_DOCTYPE_TITLE`) ? t(`${item.documentType}_DOCTYPE_TITLE`) : item.name)}
          </Typography>
        </Tooltip>
        {
          item.company
            ? <Tooltip title={ready && `${t('DOC_TYPE_OWNED_BY')}: ${item.from === 'CUSTOMERBRAND' ? BRAND_NAME : item.from}`}>
                <Typography sx={isSelected ? styles.captionSelected : styles.caption}>
                  {ready && `${t('DOC_TYPE_OWNED_BY')}: ${item.from === 'CUSTOMERBRAND' ? BRAND_NAME : item.from}`}
                </Typography>
              </Tooltip>
            : <Tooltip title={ready && t('DEPRECATED_DOCUMENT_TYPE_DESCRIPTION')}>
                <Typography sx={isSelected ? styles.legacySelected : styles.legacy}>
                  {ready && `[@${t('DEPRECATED_DOCUMENT_TYPE')}]`}
                </Typography>
              </Tooltip>
        }
        <Grid sx={styles.pointsContainer}>
          <Grid sx={isSelected ? styles.itemPointSelected : styles.itemPoint}>
            <CheckCircle style={isSelected ? styles.listItemIconSelected : styles.listItemIcon} />
            <Tooltip title={
                  ready &&
                    (i18n.exists(`${item.documentType}_DOCTYPE_DESCRIPTION`) ? t(`${item.documentType}_DOCTYPE_DESCRIPTION`) : item.description)
                }
            >
              <Typography sx={isSelected ? styles.listItemPointSelected : styles.listItemPoint}>
                {
                  ready &&
                    (i18n.exists(`${item.documentType}_DOCTYPE_DESCRIPTION`) ? t(`${item.documentType}_DOCTYPE_DESCRIPTION`) : item.description)
                }
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SelectDocTypeItem;
