import { useTranslation } from 'react-i18next';

import {
  Grid, Table, TableBody, TableHead, Box, TableRow, TableCell, Tooltip, Typography, CircularProgress
} from '@mui/material';
import { RiListSettingsLine, RiPencilLine } from '@remixicon/react';
import StarBorderIcon from '@mui/icons-material/StarBorder';

import PrimaryActionBtn from 'src/shared/buttons/PrimaryActionBtn';
import theme from 'src/themeNew';
import styles from './style';

type PropType= {
  loading: boolean;
  docTypes: Record<string, any>[];
  onEdit: (_docType: Record<string, any>) => void;
  onManageFields: (_docType: Record<string, any>) => void;
  makeDocTypeDefault: (_docType: Record<string, any>) => void;
}

const DocTypesTable = (props: PropType) => {
  const { ready, t } = useTranslation();
  const { loading, docTypes, onEdit, onManageFields, makeDocTypeDefault } = props;

  const statusChip = (active: boolean) => (
    <Box sx={active ? styles.statusChipActive : styles.statusChipInactive}>
      <Typography sx={active ? styles.statusLabelActive : styles.statusLabelInactive}>
        {ready && t(active ? 'DOC_TYPES_TABLE_ACTIVE' : 'DOC_TYPES_TABLE_INACTIVE')}
      </Typography>
    </Box>
  );

  const defaultChip = () => (
    <Box sx={styles.defaultChip}>
      <Typography sx={styles.defaultLabel}>
        {ready && t('DOC_TYPES_DEFAULT_TAG')}
      </Typography>
    </Box>
  );

  if (loading) return (
    <Grid sx={styles.contentWrapper}>
      <CircularProgress />
    </Grid>
  );

  return (
    <Grid sx={styles.tableWrapper} className="xy-scroll">
      {docTypes.length > 0 ?
      (
        <Table size="small" sx={styles.table}>
          <TableHead>
            <TableRow sx={styles.tableHeadRow}>
              <TableCell sx={{ ...styles.tableHeadRowDisplay, width: '20%' }}>
                {ready && t('DOC_TYPES_TABLE_TYPE')}
              </TableCell>
              <TableCell sx={{ ...styles.tableHeadRowDisplay, width: '10%' }}>
                {ready && t('DOC_TYPES_TABLE_STATUS')}
              </TableCell>
              <TableCell sx={{ ...styles.tableHeadRowDisplay, width: '40%' }}>
                {ready && t('DOC_TYPES_TABLE_DESCRIPTION')}
              </TableCell>
              <TableCell sx={{ ...styles.tableHeadRowDisplay, width: '30%', borderImage:'none' }}>
                {ready && t('DOC_TYPES_TABLE_ACTION')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {docTypes.map((docType: Record<string, any>) =>
            <TableRow key={docType.documentType} sx={styles.tableBodyRow}>
              <TableCell sx={{ ...styles.tableRowDisplay, maxWidth: '250px' }}>
                <Box sx={styles.tableCellRow}>
                  <Tooltip title={docType.name}>
                    <Typography sx={styles.text}>
                      {docType.name}
                    </Typography>
                  </Tooltip>
                  {docType.isDefault && defaultChip()}
                </Box>
              </TableCell>
              <TableCell sx={styles.tableRowDisplay}>
                {statusChip(Boolean(docType.isActive))}
              </TableCell>
              <TableCell sx={{ ...styles.tableRowDisplay, maxWidth: '600px' }}>
                <Tooltip title={docType.description}>
                  <Typography sx={styles.text}>
                    {docType.description}
                  </Typography>
                </Tooltip>
              </TableCell>
              <TableCell sx={styles.tableRowDisplay}>
                <Box sx={styles.actionIconContainer}>
                  <PrimaryActionBtn
                    title={t('DOC_TYPES_TABLE_EDIT')}
                    onClick={() => onEdit(docType)}
                    startIcon={<RiPencilLine color={theme.palette.primary.main} size="20" />}
                  />
                  <PrimaryActionBtn
                    title={t('DOC_TYPES_TABLE_MANAGE_FIELDS')}
                    onClick={() => onManageFields(docType)}
                    startIcon={<RiListSettingsLine color={theme.palette.primary.main} size="20" />}
                  />
                  {docType.isActive && !docType.isDefault && (
                    <Tooltip title={!docType.hasFields ? t('DOC_TYPES_TABLE_MAKE_DEFAULT_DISABLED_TOOLTIP') : ''}>
                      <span>
                        <PrimaryActionBtn
                          title={t('DOC_TYPES_TABLE_MAKE_DEFAULT')}
                          onClick={() => makeDocTypeDefault(docType)}
                          startIcon={<StarBorderIcon sx={styles.defaultIcon} />}
                          disabled={!docType.hasFields}
                        />
                      </span>
                    </Tooltip>
                  )}
                </Box>
              </TableCell>
            </TableRow>
          )}
          </TableBody>
        </Table>
      ) :
      <Grid sx={styles.contentWrapper}>
        <Typography sx={styles.emptyText}>{t('DOC_TYPES_TABLE_EMPTY')}</Typography>
      </Grid>}
    </Grid>
  )
}

export default DocTypesTable
