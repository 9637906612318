import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikErrors } from 'formik';

import {
  Accordion, AccordionDetails, AccordionSummary, Box, Button, Chip, FormControl,
  FormHelperText, Grid, InputAdornment, MenuItem, Select, TextField, Tooltip, Autocomplete,
  Typography
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon, AddBox as PlusIcon, Edit as EditIcon } from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import { isActionPermitted, permissions } from 'src/utils/helpers';
import { glAccountData, glaFields } from 'src/config';
import { GLAccountType, VatGroupType } from 'src/types';
import { filterAutocompleteOptionsVatGroups, sectionHasError } from 'src/dimensions-new/utils';
import theme from 'src/theme';

type PropsType = {
  errors: Record<string,string>;
  handleBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;
  handleSubmit: () => void;
  isSubmitting: boolean;
  touched: Record<string,string | boolean>;
  values: Record<string, string | boolean>;
  setErrors: (_errors: FormikErrors<Record<string, string>>) => void;
  glAccount: GLAccountType | null;
  handleClose: () => void;
  years: Record<string, string>[];
  endYears: Record<string, string>[];
  periods: Record<string, string | number>[];
  handleChangeAutocomplete: (_e:React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | 
    React.SyntheticEvent<Element, Event>, _prop:string, _values:Record<string,string | boolean>, _handleChange:Function) => void;
  vatCodes: VatGroupType[];
  newlyAddedVal: string;
  newlyAddingProp: string;
  handleEditVATCode: (_id: string) => void;
  handleAddVATCode: (_id: string) => void;
  onSuccess: (_val:string) => void;
  isDisabled: boolean | undefined;
}

const GLAccountsTwinfield = (props : PropsType) => {
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    touched,
    values,
    setErrors,
    glAccount,
    handleClose,
    years,
    endYears,
    periods,
    handleChangeAutocomplete,
    vatCodes,
    newlyAddedVal,
    newlyAddingProp,
    handleEditVATCode,
    handleAddVATCode,
    isDisabled
  } = props;

  const { t, ready } = useTranslation();
  
  const auth = useAuth();
  const {user} = auth;

  useEffect(() => {
    if (newlyAddedVal && newlyAddingProp) {
      handleChange({ target: { name: newlyAddingProp, value: newlyAddedVal } } as 
        React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>);
    }
  }, [newlyAddedVal]);

  return (
    <form style={styles.form} className="y-scroll">
      <Grid container sx={styles.fieldsContainer}>
        {glaFields.map((m:Record<string, any>,index:number) => (
          <Accordion
            key={m.title}
            sx={styles.accordionGroup}
            defaultExpanded={index===0?true:false}
            className="twinfield-accordion"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={m.title}
              id={m.title}
            >
              <Grid sx={styles.accordionHeading}>
                {ready && t(m.title)}
                {sectionHasError(m.fields, errors, touched) && (
                  <Chip
                    color="error"
                    size="small"
                    label="Error"
                    sx={styles.errorChip}
                  />
                )}
              </Grid>
            </AccordionSummary>
            <AccordionDetails sx={{padding: '0 24px 0px 48px'}}>
              <Grid sx={styles.eachFieldContainer}>
                {
                  m.fields.map((f:Record<string,any>) => {
                    switch (f.key) {
                      case 'fromYear':
                        return (
                          <Grid item key={f.key} sx={styles.eachField}>
                            <Typography>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</Typography>
                            <FormControl variant="outlined" sx={styles.formControl}>
                              <Select
                                disabled={isDisabled}
                                labelId={`${f.key}-label`}
                                id={f.key}
                                error={Boolean(touched[f.key] && errors[f.key])}
                                value={values[f.key]}
                                onClick={() => setErrors({})}
                                onChange={ handleChange as any }
                                name={f.key}
                                required={f.required}
                                sx={styles.selectStyle}
                              >
                                {
                                  years.map((s) => {
                                    if (s) {
                                      return (
                                        <MenuItem key={s.code} value={s.code}>
                                          {t(s.name)}
                                        </MenuItem>
                                      );
                                    }

                                    return null;
                                  })
                                }
                              </Select>
                              <FormHelperText error>
                                {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        );
                      case 'endYear':
                        return (
                          <Grid item key={f.key} sx={styles.eachField}>
                            <Typography>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</Typography>
                            <FormControl variant="outlined" sx={styles.formControl}>
                              <Select
                                disabled={isDisabled}
                                labelId={`${f.key}-label`}
                                id={f.key}
                                error={Boolean(touched[f.key] && errors[f.key])}
                                value={values[f.key]}
                                onClick={() => setErrors({})}
                                onChange={handleChange as any}
                                name={f.key}
                                required={f.required}
                                sx={styles.selectStyle}
                              >
                                {
                                  endYears.map((s) => {
                                    if (s) {
                                      return (
                                        <MenuItem key={s.code} value={s.code}>
                                          {t(s.name)}
                                        </MenuItem>
                                      );
                                    }

                                    return null;
                                  })
                                }
                              </Select>
                              <FormHelperText error>
                                {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        );
                      case 'fromPeriod': case 'endPeriod':
                        return (
                          <Grid item key={f.key} sx={styles.eachField}>
                            <Typography>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</Typography>
                            <FormControl variant="outlined" sx={styles.formControl}>
                              <Select
                                disabled={isDisabled}
                                labelId={`${f.key}-label`}
                                id={f.key}
                                error={Boolean(touched[f.key] && errors[f.key])}
                                value={values[f.key]}
                                onChange={handleChange as any}
                                name={f.key}
                                required={f.required}
                                sx={styles.selectStyle}
                              >
                                {
                                  periods.map((s) => {
                                    if (s) {
                                      return (
                                        <MenuItem key={s.code} value={s.code}>
                                          {t(s.name as string)}
                                        </MenuItem>
                                      );
                                    }

                                    return null;
                                  })
                                }
                              </Select>
                              <FormHelperText error>
                                {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        );
                      case 'furtherAnalysis':
                        return (
                          <Grid item key={f.key} sx={styles.eachField}>
                            <Typography>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</Typography>
                            <FormControl variant="outlined" sx={styles.formControl}>
                              <Select
                                disabled={isDisabled}
                                labelId={`${f.key}-label`}
                                id={f.key}
                                error={Boolean(touched[f.key] && errors[f.key])}
                                value={values[f.key]}
                                onChange={handleChange as any}
                                name={f.key}
                                required={f.required}
                                sx={styles.selectStyle}
                              >
                                {
                                  glAccountData.furtherAnalysis && glAccountData.furtherAnalysis.length > 0
                                    && glAccountData.furtherAnalysis.map((s) => {
                                      if (s) {
                                        return (
                                          <MenuItem key={s.code} value={s.code}>
                                            {t(s.name)}
                                          </MenuItem>
                                        );
                                      }

                                      return null;
                                    })
                                }
                              </Select>
                              <FormHelperText error>
                                {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        );
                      case 'matchable':
                        return (
                          <Grid item key={f.key} sx={styles.eachField}>
                            <Typography>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</Typography>
                            <FormControl variant="outlined" sx={styles.formControl}>
                              <Select
                                disabled={isDisabled}
                                labelId={`${f.key}-label`}
                                id={f.key}
                                error={Boolean(touched[f.key] && errors[f.key])}
                                value={values[f.key]}
                                onChange={handleChange as any}
                                name={f.key}
                                required={f.required}
                                sx={styles.selectStyle}
                              >
                                <MenuItem value="yes">
                                  {t('GLA_MATCHABLE_MATCHABLE')}
                                </MenuItem>
                                <MenuItem value="no">
                                  {t('GLA_MATCHABLE_NOT_MATCHABLE')}
                                </MenuItem>
                              </Select>
                              <FormHelperText error>
                                {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        );
                      case 'performanceType':
                        return (
                          <Grid item key={f.key} sx={styles.eachField}>
                            <Typography>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</Typography>
                            <FormControl variant="outlined" sx={styles.formControl}>
                              <Select
                                disabled={isDisabled}
                                labelId={`${f.key}-label`}
                                id={f.key}
                                error={Boolean(touched[f.key] && errors[f.key])}
                                value={values[f.key]}
                                onChange={handleChange as any}
                                name={f.key}
                                required={f.required}
                                sx={styles.selectStyle}
                              >
                                {
                                  glAccountData.performanceType && glAccountData.performanceType.length > 0
                                    && glAccountData.performanceType.map((s) => {
                                      if (s) {
                                        return (
                                          <MenuItem key={s.code} value={s.code}>
                                            {t(s.name)}
                                          </MenuItem>
                                        );
                                      }

                                      return null;
                                    })
                                }
                              </Select>
                              <FormHelperText error>
                                {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        );
                      case 'vat':
                        return (
                          <Grid item key={f.key} sx={styles.eachField}>
                            <Typography>{ready && t(f.label)}</Typography>
                            <FormControl variant="outlined" sx={styles.formControl}>
                              <Autocomplete
                                disabled={isDisabled}
                                fullWidth
                                onChange={(e, v) => handleChangeAutocomplete(e, 'vat', v as unknown as Record<string, string | boolean>, handleChange)}
                                value={vatCodes && values.vat && vatCodes.find((e:VatGroupType) => e.code === values?.vat) || null}
                                options={vatCodes}
                                getOptionLabel={(option: VatGroupType) => (option && option.code ? `${option.code} | ${option.name}`  : '')}
                                filterOptions={(options, state) => filterAutocompleteOptionsVatGroups(options, state)}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (isDisabled ? <></> :
                                        <>
                                          {values[f.key] !== glAccountData.vat[0].code && (
                                            <InputAdornment
                                              position="end"
                                              onClick={() => handleEditVATCode(values[f.key] as string)}
                                              sx={styles.editIconContainer}
                                            >
                                              <EditIcon sx={styles.editDimensionIcon} />
                                            </InputAdornment>
                                          )}
                                          <InputAdornment position="end" sx={styles.plusIconContainer}>
                                            <PlusIcon sx={styles.plusIcon} onClick={() => handleAddVATCode(f.key)} />
                                          </InputAdornment>
                                        </>
                                      ),
                                    }}
                                    sx={styles.autoCompleteInput}
                                  />
                                )}
                              />
                              <FormHelperText error>
                                {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        );
                      case 'vatType':
                        return (
                          <Grid item key={f.key} sx={styles.eachField}>
                            <Typography>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</Typography>
                            <FormControl variant="outlined" sx={styles.formControl}>
                              <Select
                                disabled={isDisabled}
                                labelId={`${f.key}-label`}
                                id={f.key}
                                error={Boolean(touched[f.key] && errors[f.key])}
                                value={values[f.key]}
                                onChange={handleChange as any}
                                name={f.key}
                                required={f.required}
                                sx={styles.selectStyle}
                              >
                                {
                                  glAccountData.vatType && glAccountData.vatType.length > 0
                                    && glAccountData.vatType.map((s) => {
                                      if (s) {
                                        return (
                                          <MenuItem key={s.code} value={s.code}>
                                            {t(s.name)}
                                          </MenuItem>
                                        );
                                      }

                                      return null;
                                    })
                                }
                              </Select>
                              <FormHelperText error>
                                {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        );
                      default:
                        return (
                          <Tooltip key={f.key} title={glAccount && f.key === 'code' ? t('DIMENSION_CODE_CANNOT_EDIT') : ''}>
                            <Grid item key={f.key} sx={styles.eachField}>
                              <Typography>{ready && t(f.label)}</Typography>
                              <TextField
                                error={Boolean(touched[f.key] && errors[f.key])}
                                fullWidth
                                helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                name={f.key}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                required={f.required}
                                value={values[f.key]}
                                variant="outlined"
                                sx={styles.inputField}
                                disabled={isDisabled ? true : glAccount ? f.key === 'code' : false}
                              />
                            </Grid>
                          </Tooltip>
                        );
                    }
                  })
                }
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
        <Grid
          item
          xs={12}
        >
          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>
                {errors.submit}
              </FormHelperText>
            </Box>
          )}
          {
            isDisabled ? <></> :
            <Box mt={2} display="flex" justifyContent="flex-end" p="0px 16px 20px">
              <Button
                variant="text"
                onClick={()=>handleClose()}
                sx={{...styles.yearField,textTransform: 'none'}}
              >
                {ready && t('GLAS_CANCEL')}
              </Button>
              <Button
                sx={{ backgroundColor: '#3E8AFF',color: '#fff',textTransform: 'none'}}
                variant="contained"
                disabled={isSubmitting || !isActionPermitted(permissions.dimensionEdit, user?.permissions)}
                onClick={()=>handleSubmit()}
              >
                {ready && t(glAccount && glAccount?.code ? 'GLAS_SAVE_CHANGES' : 'GLA_ADD_NEW_GLA')}
              </Button>
            </Box>
          }
        </Grid>
      </Grid>
    </form>
  );
};


export default GLAccountsTwinfield;

const styles = {
  form: {
    backgroundColor: '',
    maxHeight: 'calc(94dvh - 76px)',
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  eachFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  eachField: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '5px 0',
    width: '100%',
    flex: 1,
    '& > p': {
      fontSize: '13px',
      color: '#4C4E64DE',
    }
  },
  snackAction: {
    color: 'white'
  },
  expiryRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  monthField: {
    marginRight: '16px'
  },
  yearField: {
    color: theme.palette.secondary.main
  },
  errorText: {
    color: '#f44336'
  },
  accordionGroup: {
    width: '100%',
    backgroundColor: 'tranparent',
    boxShadow: 'none',
    borderTop: 'none',
    margin: '0 !important',
    borderBottom: '1px solid #E0E0E0',
    '&::before': {
      display: 'none',
      opacity: '0'
    },
  },
  accordionHeading: {
    color: '#4C4E64',
    fontWeight: '500',
    fontSize: '14px',
  },
  formControl: {
    width: '300px'
  },
  autoCompleteInput: {
    width: '300px',
    color: '#4C4E64 !important',
    fontSize: '13px',
    lineHeight: '20px',
    backgroundColor: '#ffffff',
    '& .MuiAutocomplete-input': {
      color: '#4C4E64 !important',
    },
    '& .MuiOutlinedInput-root': {
      padding: '0px 2px 0px 0px !important',
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: `2px solid #C2CFE099`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid #C2CFE099`,
      }
    },
  },
  selectStyle: {
    color: '#4C4E64 !important',
    fontSize: '13px',
    width: '100%',
    lineHeight: '20px',
    backgroundColor: '#ffffff',
    '&.MuiAutocomplete-input': {
      color: '#4C4E64 !important',
    },
    '&.MuiOutlinedInput-root': {
      padding: '0px 2px 0px 0px !important',
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: `2px solid #C2CFE099`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid #C2CFE099`,
      }
    },
  },
  inputField: {
    width: '300px',
    '& .MuiOutlinedInput-input': {
      fontSize: '13px !important',
      lineHeight: '32px !important',
      color: '#4C4E64 !important',
      fontWeight: '400',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: `2px solid #C2CFE099`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid #C2CFE099`,
      },
    },
  },
  errorChip: {
    marginLeft: '16px',
    fontSize: '12px',
    color: '#ffffff',
    backgroundColor: '#f44336'
  },
  btnIcon: {
    height: '15px',
    width: '15px',
    marginRight: '4px',
    color: '#bebebe',
  },
  plusIcon: {
    height: '24px',
    width: '24px',
    color: theme.palette.secondary.main,
    cursor: 'pointer'
  },
  plusIconContainer: {
    marginRight: '5px'
  },
  editDimensionIcon: {
    fontSize: '18px',
    color: '#757575',
    border: '1px solid #334D6E',
    borderRadius: '3px',
    cursor: 'default'
  },
  editIconContainer: {
    marginRight: '-8px'
  },
};
