import { useState, useEffect, Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import FileSaver from 'file-saver';
import { useSnackbar } from 'notistack';

import { Box, Grid, Backdrop, CircularProgress } from '@mui/material';

import { axiosDeleteHeaders, axiosHeaders, isActionPermitted, permissions } from 'src/utils/helpers';
import { useAuth } from 'src/hooks/useAuth';
import { useConfig } from 'src/hooks/useConfig';
import { fetchCostCenterList } from 'src/dimensions/costcenters/actions';
import TablePagination from 'src/dimensions-new/components/TablePagination';
import LoadingIndicator from 'src/shared/components/LoadingIndicator';
import TopDisplay from 'src/dimensions-new/components/TopDisplay';
import Search from 'src/dimensions-new/components/Search';
import CostCentersTable from 'src/dimensions-new/components/DimensionsTable';
import CostCenterModal from 'src/dimensions-new/costcenters/components/CostCenterModal';

type CostCenterType = {
  code: string;
  name: string;
};
type StateType = {
  costcenter: {
    costCenters: CostCenterType[];
    total: number;
  };
};
const CostCenters = (props: { showImport: boolean, setShowImport: Dispatch<SetStateAction<boolean>> }) => {
  const { showImport, setShowImport } = props;
  const { ready, t } = useTranslation();
  const [resetSearch] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [ccModalOpen, setCCModalOpen] = useState<boolean>(false);
  const [selectedCostCenterCode, setSelectedCostCenterCode] = useState<string>('');
  const [data, setData] = useState<CostCenterType[]>([]);
  const [, setNewlyAddedVal] = useState<string>('');
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const { user } = useAuth();
  const { API } = useConfig();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const title = ready ? t('DASHBOARD_NAV_COST_CENTERS') : '';
  const _costCenters = useSelector((state: StateType) => state.costcenter.costCenters);
  const costCenters = useMemo(() => _costCenters, [_costCenters]);
  const total = useSelector((state: StateType) => state.costcenter.total);

  const handleChange = async (str: string) => {
    setQuery(str);
    await getAllCostCenters(page, limit, query, false);
  };
  const getAllCostCenters = async (
    page = 0,
    limit = 10,
    search = query,
    loadFullScreen = true
  ) => {
    if (loadFullScreen) {
      setLoading(true);
    }
    dispatch(fetchCostCenterList(user?.companyID, page, limit, search) as any);
    setLoading(false);
  };

  const handleAddCostCenter = () => {
    setSelectedCostCenterCode('');
    setIsDisabled(false);
    setIsEdit(false);
    setCCModalOpen(true);
  };

  const onCCCreated = (val: string) => {
    getAllCostCenters();
    setNewlyAddedVal(val);
  };

  const onEdit = (costCenter: string) => {
    setIsEdit(true);
    setIsDisabled(false);
    setSelectedCostCenterCode(costCenter);
    setCCModalOpen(true);
  };

  const onView = (costCenter: string) => {
    setIsEdit(true);
    setIsDisabled(true);
    setSelectedCostCenterCode(costCenter);
    setCCModalOpen(true);
  };

  const onCCModalClose = () => {
    setCCModalOpen(false);
    setSelectedCostCenterCode('');
  };

  const onLimitChange = async (limit: number) => {
    setLimit(limit);
    setShowLoading(true);
    await getAllCostCenters(0, limit, query, false);
    setShowLoading(false);
  };
  const onPageChange = async (page: number) => {
    setPage(page);
    setShowLoading(true);
    await getAllCostCenters(page, limit, query, false);
    setShowLoading(false);
  };

  const handleDelete = async (selectedCostCenter: CostCenterType) => {
    try {
      setLoading(true);
      const response = await Axios.delete(
        `${API.costCentersByCompany}/${user?.companyID}`,
        axiosDeleteHeaders(localStorage.getItem('PROCYS_accessToken'), selectedCostCenter),
      );

      if (response.data.success) {
        getAllCostCenters();
        enqueueSnackbar(t('DIMENSION_DELETE_SUCCESS'), {
          variant: 'success',
          persist: false
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(t('DIMENSION_DELETE_FAILED'), {
        variant: 'error',
        persist: false
      });
    }
  };

  const handleExport = async (format: string, closeMenu: () => void) => {
    try {
      const response = await Axios.get(
        `${API.dimensions}costcenters/export/${format}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      const fileName = `${user?.company} Cost Centers.${format}`;
      const blob = new Blob([response.data], {
        type: 'text/plain;charset=utf-8'
      });
      FileSaver.saveAs(blob, fileName);
      closeMenu();
    } catch (error) {
      closeMenu();
      enqueueSnackbar(t('DIMENSION_EXPORT_FAIL'), {
        variant: 'error',
        persist: false
      });
    }
  };

  useEffect(() => {
    if (isActionPermitted(permissions.dimensionRead, user?.permissions)) {
      getAllCostCenters();
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (costCenters && Array.isArray(costCenters)) {
      setData(costCenters.slice(0, limit));
    }
  }, [costCenters]);

  return (
    <>
      <>
        {!showLoading ? (
          <Box sx={styles.root} className="x-scroll">
            <Grid sx={styles.wrapper}>
              <TopDisplay
                title={title}
                showImport={showImport}
                setShowImport={setShowImport}
                type="costcenters"
                onExport={handleExport}
                onAdd={handleAddCostCenter}
              />
              <Search onChange={handleChange} reset={resetSearch} />
              <CostCentersTable
                data={data}
                type="costcenters"
                onDelete={handleDelete}
                deleteText="DIMENSION_DELETE_COSTCENTERS_BTN"
                onEdit={onEdit}
                onView={onView}
              />
              <TablePagination
                total={total || 0}
                limit={limit || 10}
                page={page || 0}
                onLimitChange={onLimitChange}
                onPageChange={onPageChange}
              />
            </Grid>
            <Backdrop
              style={{ zIndex: 111111, color: '#ffffff' }}
              open={loading}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        ) : (
          <LoadingIndicator />
        )}
      </>
      <CostCenterModal
        costCenterModalOpen={ccModalOpen}
        handleClose={onCCModalClose}
        getAllCostCenters={getAllCostCenters}
        onSuccess={onCCCreated}
        selectedCostCenter={selectedCostCenterCode}
        isEdit={isEdit}
        isDisabled={isDisabled}
      />
    </>
  );
};
const styles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '20px'
  },
  wrapper: {
    backgroundColor: '#fff',
    minWidth: '545px'
  },
  top: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '5px 0',
    gap: '10px',
    marginBlock: '0 20px'
  },
  topLeft: {
    fontSize: '16px',
    color: '#4C4E64',
    fontWeight: '500',
    marginInlineEnd: 'auto'
  },
  btns: {
    gap: '8px',
    color: '#3E8AFF',
    textTransform: 'Capitalize',
    fontSize: '13px',
    paddingInline: '10px'
  },
  addBtn: {
    background: '#3E8AFF',
    color: '#fff',
    paddingInline: '15px',
    gap: '8px',
    textTransform: 'Capitalize',
    fontSize: '13px',
    '&:hover': {
      backgroundColor: '#3E8AFF',
      color: '#fff'
    },
    menuContainer: {
      boxShadow: ' 0px 4px 8px -4px #4C4E646B',
      width: '200px'
    },
    exportIconContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      fontSize: '14px',
      color: '#4C4E64DE'
    },
    backdrop: {
      zIndex: 111111,
      color: '#ffffff'
    }
  }
};
export default CostCenters;
