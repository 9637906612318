import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Backdrop, CircularProgress } from '@mui/material';

import SideBarLayout from 'src/shared/layouts/SideBarLayout';
import { useConfig } from 'src/hooks/useConfig';
import { RootState } from 'src/types';
import { useAuth } from 'src/hooks/useAuth';
import { userRoles } from 'src/config';
import { fetchHeaderFields, fetchLineFields } from 'src/shared/redux/fields/fieldsActions';
import { UnknownAction } from 'redux';
import DocumentEdit from 'src/document-edit/DocumentEdit';

const DocumentEditContainer = () => {
  const params = useParams<{ id: string }>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { BRAND_NAME } = useConfig();
  const { user } = useAuth();

  const fieldsLoading: boolean = useSelector((state: RootState) => state.fields.fieldsLoading);
  const docType: string = useSelector((state: RootState) => state.fields.docType);
  const companyDocTypes: Record<string, any>[] = useSelector((state: RootState) => state.docTypes.companyDocTypes);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const id = params && params.id ? params.id : '0';
    if (user?.userRole === userRoles.annotator || companyDocTypes.length > 1) {
      dispatch(fetchHeaderFields(user?.companyID || '', docType, id) as unknown as UnknownAction);
      dispatch(fetchLineFields(user?.companyID || '', docType, id) as unknown as UnknownAction);
    }
  }, []);

  return (
    <SideBarLayout title={`${t('INVOICE_EDIT_PAGE_TITLE')} | ${BRAND_NAME}`} hideTopBar={true}>
      <DocumentEdit
        id={params && params.id ? params.id : '0'}
        setLoading={setLoading}
      />
      <Backdrop style={styles.backdrop} open={loading || fieldsLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </SideBarLayout>
  );
}

export default DocumentEditContainer;

const styles = {
  backdrop: {
    zIndex: 1111111,
    color: '#ffffff',
  },
}
