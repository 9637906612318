import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { ArrowBack as BackIcon, Add as AddIcon, HelpOutline as KBLinkIcon }from '@mui/icons-material';

import { kbLinks } from 'src/dashboard-new/utils';
import ConfigContext from 'src/contexts/ConfigContext';
import SubmitBtn from 'src/shared/buttons/SubmitBtn';
import theme from 'src/themeNew';

interface PropTypes {
  loading: boolean;
  setAddNewOpen: (_value: boolean) => void;
  setImportOpen: (_value: boolean) => void;
}

const DocTypeTopBar = (props: PropTypes) => {
  const { ready, t, i18n } = useTranslation();
  const navigate = useNavigate();

  const { BRAND_NAME, IS_BRAND_PROCYS } = useContext(ConfigContext);

  const { loading, setAddNewOpen, setImportOpen } = props;
  
    const openKBArticle = () => {
      window.open(
        `${kbLinks[BRAND_NAME]?.
          replace(
            '__LANG__',
            i18n.language.split('-')[0] !== 'en' ? `/${i18n.language.split('-')[0]}` : ''
          )}-article/doctype-manager-product-documentation`, '_blank'
        );
    };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.titleRow}>
        <IconButton sx={styles.iconContainer} onClick={() => navigate(-1)}>
          <BackIcon style={styles.icon} />
        </IconButton>
        <Typography style={styles.title}>
          {ready && t('DOC_TYPE_MANAGER_TITLE')}
        </Typography>
        {IS_BRAND_PROCYS && (
          <Tooltip title={t('DOC_TYPES_KB_LINK_TOOLTIP')}>
            <span>
              <IconButton sx={styles.kbIconBtn} onClick={() => openKBArticle()}>
                <KBLinkIcon sx={styles.kbIcon} />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </Box>
      <Box sx={styles.btnContainer}>
        <SubmitBtn
          title={t('DOC_TYPE_IMPORT_NEW')}
          onClick={() => setImportOpen(true)}
          disabled={loading}
          startIcon={<AddIcon sx={styles.addIcon} />}
        />
        <SubmitBtn
          title={t('DOC_TYPE_ADD_NEW')}
          onClick={() => setAddNewOpen(true)}
          disabled={loading}
          startIcon={<AddIcon sx={styles.addIcon} />}
        />
      </Box>
    </Box>
  )
}

const styles = {
  root: {
    height: '78px',
    width: '100%',
    backgroundColor: theme.palette.background.white,
    borderRadius: '8px 8px 0 0',
    padding: '15px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: '0px 2px 10px 0px #4C4E6438',
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {},
  icon: {
    color: theme.palette.text.secondary,
    height: '24px',
    width: '24px',
  },
  title: {
    textDecoration:'none',
    color: theme.palette.text.primary,
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '32px',
    flex: 1,
  },
  addIcon: {
    color: theme.palette.text.white,
    height: '18px',
    width: '18px',
  },
  kbIconBtn: {
    padding: '4px',
    marginLeft: '8px',
  },
  kbIcon: {
    color: '#4C4E648A',
    height: '18px',
    width: '18px',
  },
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '8px',
  }
}

export default DocTypeTopBar