import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, IconButton, Typography } from '@mui/material';
import { ArrowBack as BackIcon }from '@mui/icons-material';

const SettingsTopBar = (props: { title: string }) => {
  const { ready, t } = useTranslation();
  const navigate = useNavigate();

  const { title } = props;

  return (
    <Box sx={styles.root}>
      <IconButton sx={styles.iconContainer} onClick={() => navigate(-1)}>
        <BackIcon style={styles.icon} />
      </IconButton>
      <Typography style={styles.title}>
        {ready && t(title)}
      </Typography>
    </Box>
  )
}

const styles = {
  root: {
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: '8px 8px 0 0',
    padding: '15px 10px',
    borderBlockEnd: '1px solid #4C4E641F',
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {},
  icon: {
    color:'#6D788D',
    height: '24px',
    width: '24px',
  },
  title: {
    textDecoration:'none',
    color: '#4C4E64',
    fontSize: '16px',
    fontWeight: '500',
  }
}

export default SettingsTopBar