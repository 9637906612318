import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { UnknownAction } from 'redux';
import Axios from 'axios';

import {
  Box, List, ListItem, ListItemText, MenuItem, Select, TextField, Typography
} from '@mui/material';
import {
  FiberManualRecord as FiberManualRecordIcon
} from '@mui/icons-material';

import CommonModal from 'src/shared/components/CommonModal/CommonModal';
import { useAuth } from 'src/hooks/useAuth';
import { useConfig } from 'src/hooks/useConfig';
import { CustomError, FieldType } from 'src/types';
import { appendContactSupport, axiosHeaders } from 'src/utils/helpers';
import { fetchHeaderFields } from 'src/shared/redux/fields/fieldsActions';
import styles from './style';

interface PropTypes {
  open: boolean;
  fields: FieldType[];
  docType: string;
  handleClose: () => void;
}

const instructions = [
  'ADD_NEW_FIELD_INSTRUCTION_1',
  'ADD_NEW_FIELD_INSTRUCTION_2',
  'ADD_NEW_FIELD_INSTRUCTION_3',
];

const fieldSections = [
  { key: 'header', label: 'ADD_NEW_FIELD_SECTION_HEADER' }
];

const fieldDataTypes = [
  { key: 'text', label: 'ADD_NEW_FIELD_TEXT' },
  { key: 'float', label: 'ADD_NEW_FIELD_NUMBER' },
];

const AddNewFieldModal = ({
  open,
  fields,
  docType,
  handleClose,
}: PropTypes) => {
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { BRAND_NAME, API } = useConfig();
  const { user } = useAuth();

  const [fieldName, setFieldName] = useState('');
  const [fieldNameError, setFieldNameError] = useState('');
  const [section] = useState(fieldSections[0].key);
  const [dataType, setDataType] = useState(fieldDataTypes[0].key);

  const regexp = /^[A-Za-z\s]+$/;

  const handleAddNewField = async () => {
    if (fieldName.trim() === '') {
      setFieldNameError(t('ADD_NEW_FIELD_FIELD_NAME_REQUIRED'));
      return;
    }
    if (!regexp.test(fieldName)) {
      setFieldNameError(t('ADD_NEW_FIELD_FIELD_NAME_INVALID'));
      return;
    }
    if (fields.find((field) => field.key.toLowerCase() === fieldName.replace(/\s+/g, '').toLowerCase())) {
      setFieldNameError(t('ADD_NEW_FIELD_FIELD_NAME_EXIST'));
      return;
    }

    try {
      const response = await Axios.post(
        `${API.fieldsAddNew}`,
        {
          field: fieldName,
          type: dataType,
          section,
          documentType: user?.documentType
        },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        dispatch(fetchHeaderFields(user?.companyID || '', docType) as unknown as UnknownAction);
        handleClose();
        enqueueSnackbar(
          t('ADD_NEW_FIELD_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
      }
    } catch (e) {
      const error = e as CustomError;
      let errorMessage = appendContactSupport(window.config.support_email, t('ADD_NEW_FIELD_FAILURE'), t);
      if (error && error.response && error.response.data) {
        errorMessage = t(error.response.data.i18n || '');
      }

      enqueueSnackbar(
        errorMessage,
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  return (
    <CommonModal
      open={open}
      title={t('ADD_NEW_FIELD_TITLE')}
      cancelText={t('INVOICE_DELETE_CANCEL')}
      submitText={t('MANAGE_FIELD_SAVE')}
      handleClose={handleClose}
      onSubmit={handleAddNewField}
      showSubmitIcon
      width='500px'
    >
      <Typography sx={styles.contentText}>
        {ready && t('ADD_NEW_FIELD_INSTRUCTION_TITLE', { brand: BRAND_NAME })}
      </Typography>
      <List style={styles.list}>
        {instructions.map((item) => (
          <ListItem key={item} style={styles.listItem}>
            <FiberManualRecordIcon style={styles.bulletPoint} />
            <ListItemText
              disableTypography
              primary={(
                <Typography style={styles.listItemText}>
                  {ready && t(item)}
                </Typography>
              )}
            />
          </ListItem>
        ))}
      </List>
      <Box sx={styles.divider} />
      <Box sx={styles.fieldDetailsContainer}>
        <Typography sx={styles.fieldDetailsTitle}>{t('ADD_NEW_FIELD_GENERAL')}</Typography>
        <Box sx={styles.fieldDetailsRow}>
          <Typography sx={styles.contentText}>{t('ADD_NEW_FIELD_FIELD_NAME')}</Typography>
          <TextField
            id="field-name"
            value={fieldName}
            variant="outlined"
            sx={{
              '&.MuiTextField-root': styles.textFieldRoot,
              '& .MuiOutlinedInput-root': styles.inputRoot,
              '& .MuiOutlinedInput-input': styles.input,
              '& .MuiFormHelperText-root': styles.fieldNameError,
            }}
            onChange={(e) => {setFieldName(e.target.value); setFieldNameError('');}}
            helperText={fieldNameError}
            error={!!fieldNameError}
          />
        </Box>
        <Box sx={styles.fieldDetailsRow}>
          <Typography sx={styles.contentText}>{t('ADD_NEW_FIELD_FIELD_SECTION')}</Typography>
          <Select
            sx={styles.selectStyle}
            value={section}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
            }}
          >
            {
              fieldSections.map((d) => (
                <MenuItem sx={styles.selectItem} key={d.key} value={d.key}>
                  {t(d.label)}
                </MenuItem>
              ))
            }
          </Select>
        </Box>
        <Box sx={styles.fieldDetailsRow}>
          <Typography sx={styles.contentText}>{t('ADD_NEW_FIELD_FIELD_TYPE')}</Typography>
          <Select
            sx={styles.selectStyle}
            value={dataType}
            onChange={(e) => setDataType(e.target.value)}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
            }}
          >
            {
              fieldDataTypes.map((d) => (
                <MenuItem sx={styles.selectItem} key={d.key} value={d.key}>
                  {t(d.label)}
                </MenuItem>
              ))
            }
          </Select>
        </Box>
      </Box>
    </CommonModal>
  );
};

export default AddNewFieldModal;
