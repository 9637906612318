const styles = {
  root:{
    flex: '1',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  content:{
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    padding: '0px',
    position: 'relative',
    backgroundColor: 'transparent',
  },
  foot:{
    width: '100%',
    height: '58px',
    backgroundColor: 'transparent',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    left: '0',
    bottom: '20px',
    zIndex: '2',
  },
  pdfWrapper: {
    width: 'fit-content',
    aspectRatio: '0.707/1',
    marginBottom: '70px',
    height: 'fit-content',
    border: 'none',
    padding: '5px',
    display: 'flex',
    justifyContent: 'center'
  },
  noDocMessageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    baclgroundColor: 'red',
  }
}

export default styles;