import { useTranslation } from 'react-i18next';

import { Dialog, Typography, Grid, IconButton } from '@mui/material';
import {
  Close as CloseIcon,
  Menu as MenuIcon,
  CalendarToday as CalendarTodayIcon,
  AccountCircleOutlined as AccountCircleIcon,
} from '@mui/icons-material';

import { DocType } from 'src/types';
import { formatDateCustomFormat } from 'src/utils/helpers';
import styles from './style';
import { useConfig } from 'src/hooks/useConfig';

interface PropTypes {
  open: boolean;
  document: DocType;
  handleClose: () => void;
}

function InfoModal(props: PropTypes) {
  const { t } = useTranslation();
  const { BRAND_NAME } = useConfig();

  const {
    open: showInfoModal,
    document,
    handleClose
  } = props;

  const {
    fileName, id, appUploadedDate, appUsername, ownerName,
  } = document;

  const dateFormat = 'DD-MM-YYYY hh:mm:ss';

  const formattedDate = formatDateCustomFormat(new Date((appUploadedDate || 0) * 1000).toString(), dateFormat);

  return (
    <Dialog
      open={showInfoModal}
      sx={styles.modal}
      onClose={handleClose}
    >
      <Grid sx={styles.container}>
        <Grid sx={styles.headerContainer}>
          <Typography sx={styles.name}>{fileName}</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon sx={styles.closeIcon} />
          </IconButton>
        </Grid>
        <Grid sx={styles.detailContainer}>
          <Typography sx={styles.detailLabel}>{t('DOCUMENT_INFO_MODAL_PROCYS_NUMBER', { brand: BRAND_NAME })}</Typography>
          <Grid sx={styles.rowContainer}>
            <MenuIcon sx={styles.icon} />
            <Typography sx={styles.detailText}>{id}</Typography>
          </Grid>
        </Grid>
        <Grid sx={styles.detailContainer}>
          <Typography sx={styles.detailLabel}>{t('DOCUMENT_INFO_MODAL_UPLOADED')}</Typography>
          <Grid sx={styles.rowContainer}>
            <CalendarTodayIcon sx={styles.icon} />
            <Typography sx={styles.detailText}>{formattedDate}</Typography>
          </Grid>
        </Grid>
        <Grid sx={styles.detailContainer}>
          <Typography sx={styles.detailLabel}>{t('DOCUMENT_INFO_MODAL_DOC_AUTHOR')}</Typography>
          <Grid sx={styles.rowContainer}>
            <AccountCircleIcon sx={styles.icon} />
            <Typography sx={styles.detailText}>{appUsername}</Typography>
          </Grid>
        </Grid>
        <Grid sx={styles.detailContainer}>
          <Typography sx={styles.detailLabel}>{t('DOCUMENT_INFO_MODAL_OWNED_BY')}</Typography>
          <Grid sx={styles.rowContainer}>
            <AccountCircleIcon sx={styles.icon} />
            <Typography sx={styles.detailText}>{ownerName}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default InfoModal;
