import theme from "src/themeNew";

const styles = {
  root:{
    display:'flex',
    padding:'0',
    margin:'0',
    height:'100%',
    width:'100%'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 0px'
  },
  backdrop: {
    zIndex: 111111,
    color: '#ffffff',
  },
  addDocBtnContainer: {
    zIndex: 111111,
    position: 'absolute',
    top: 10,
    right: 10,
  },
  spinner: {
    color: theme.palette.background.white,
  },
  addDocBtn: {
    color: theme.palette.primary.main,
    fontSize: '15px',
    lineHeight: '22px',
    height: '38px',
    textTransform: 'none',
    borderRadius: '4px',
    padding: '8px 22px',
    border: `1px ${theme.palette.primary.main} solid`,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      border: `1px ${theme.palette.primary.main} solid`,
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.light,
      border: `1px ${theme.palette.primary.main} solid`,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.light,
      border: `1px ${theme.palette.primary.main} solid`,
    },
    '&:disabled': {
      color: theme.palette.primary.main,
      border: `1px ${theme.palette.primary.main} solid`,
      opacity: 0.45,
    }
  },
}

export default styles;