import { alpha, createTheme } from '@mui/material/styles';
import { colors } from '@mui/material';

const typography = {
  h1: {
    fontWeight: 500,
    fontSize: 35,
    letterSpacing: '-0.24px'
  },
  h2: {
    fontWeight: 500,
    fontSize: 29,
    letterSpacing: '-0.24px'
  },
  h3: {
    fontWeight: 500,
    fontSize: 24,
    letterSpacing: '-0.06px'
  },
  h4: {
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: '-0.06px'
  },
  h5: {
    fontWeight: 500,
    fontSize: 16,
    letterSpacing: '-0.05px'
  },
  h6: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: '-0.05px'
  },
  overline: {
    fontWeight: 500
  }
};

const theme = createTheme({
  typography,
  palette: {
    type: 'light',
    action: {
      active: colors.blueGrey[600]
    },
    primary: {
      main: '#03a9f4'
    },
    secondary: {
      main: '#3E8AFF',
      green: '#00e676'
    },
    background: {
      default: colors.common.white,
      dark: '#f4f6f8',
      paper: colors.common.white
    },
    border: {
      primary: '0px solid #E1E4E8',
      secondary: '1px solid #E1E4E8',
      white: '0px solid white',
      cancel: '1px #e4e4e4 solid',
      manage: '1px #0000003b solid',
    },
    text: {
      primary: '#334D6E',
      secondary: '#464e59',
      title: '#192A3E',
      caption: '#6A707E',
      success: '#009688',
      fail: '#ff0000',
      highlight: '#03a9f4',
      highlight2: '#3E8AFF',
      header: 'rgba(51, 77, 110, 0.5)',
      disabled: alpha('#4C4E64', 0.87)
    },
    line: {
      gray: '#EBEFF2',
      primary: '#3E8AFF'
    },
    icon: {
      white: '#ffffff',
      gray: '#C2CFE0',
      primary: '#03a9f4',
      close: '#A0AEC1',
    },
    button: {
      primary: '#109CF1',
      secondary: '#FBA102',
      action: '#3E8AFF',
      cancel: '#FFFFFF'
    },
    status: {
      digitized: 'rgba(51, 77, 110, 1)',
      toreview: '#3E8AFF',
      reviewed: '#3E8AFF',
      tovalidate: '#F5B140',
      toexport: '#3CB573',
      exported: '#2A7E50',
      rejected: '#EF6C00',
      archived: '#4E342E',
      outoflimit: '#FFFFFF',
      errored: '#F7685B',
      splitRequest: '#8f8f8f',
      possibleDuplicate: '#ffc107'
    },
    statusbg: {
      digitized: '#E1E4E8',
      toreview: '#3E8AFF',
      reviewed: '#3E8AFF',
      tovalidate: '#FFB946',
      toexport: '#2ED47A',
      exported: '#2A7E50',
      rejected: '#EF6C00',
      archived: '#4E342E',
      outoflimit: '#424242',
      errored: '#F7685B',
      splitRequest: '#8f8f8f',
      possibleDuplicate: '#ffc107'
    },
    iType: {
      invoice: '#EF9A9A',
      purchaseOrder: '#33c9dc',
      creditNote: '#A5D6A7',
      attachment: '#CFD8DC'
    }
  },
});

export default theme;
