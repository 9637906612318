import { FilterOptionsState } from "@mui/material/useAutocomplete";
import { supplierData } from "src/config";
import { CostCenterType, CountryType, CurrencyType, FieldType, GLAccountType, VatGroupType } from "src/types";

export const filterAutocompleteOptionsGLA = (
  options: GLAccountType[],
  state: FilterOptionsState<GLAccountType>
) => {
  return options.filter((option) => {
    if (option.code && option.name) {
      return option.code.toLowerCase().includes(state.inputValue.toLowerCase())
        || option.name.toLowerCase().includes(state.inputValue.toLowerCase());
    }
    return false;
  })
};

export const filterAutocompleteOptionsVatGroups = (
  options: VatGroupType[],
  state: FilterOptionsState<VatGroupType>
) => {
  return options.filter((option) => {
    if (option.code && option.name) {
      return option.code.toLowerCase().includes(state.inputValue.toLowerCase())
        || option.name.toLowerCase().includes(state.inputValue.toLowerCase());
    }
    return false;
  })
};

export const filterAutocompleteOptionsCostCenter = (
  options: CostCenterType[],
  state: FilterOptionsState<CostCenterType>
) => {
  return options.filter((option) => {
    if (option.code && option.name) {
      return option.code.toLowerCase().includes(state.inputValue.toLowerCase())
        || option.name.toLowerCase().includes(state.inputValue.toLowerCase());
    }
    return false;
  })
};

export const filterAutocompleteOptionsCountry = (options: CountryType[], state: FilterOptionsState<CountryType>) => {
  return options.filter((option) => {
    if (option.isoCode && option.name) {
      return option.isoCode.toLowerCase().includes(state.inputValue.toLowerCase())
        || option.name.toLowerCase().includes(state.inputValue.toLowerCase());
    }
    return false;
  })
};

export const filterAutocompleteOptionsCurrency = (options: CurrencyType[], state: FilterOptionsState<CurrencyType>) => {
  return options.filter((option) => {
    if (option.code && option.currency) {
      return option.code.toLowerCase().includes(state.inputValue.toLowerCase())
        || option.currency.toLowerCase().includes(state.inputValue.toLowerCase());
    }
    return false;
  })
};

export const showEditIcon = (key: string, values: Record<string, any>) => {
  if (values[key] === supplierData.defaultGLA[0].code
    || values[key] === supplierData.defaultVatGroup[0].code
    || values[key] === supplierData.defaultCostCenters[0].code
  ) {
    return false;
  }
  if (values[key]) {
    return true;
  }
};

export const getAutoCompleteValue = (value: string, options: any[]) => {
  const option = options.find((option) => option.code === value);
  return option ? option : null;
}

export const sectionHasError = (
  fields: FieldType[],
  errors :Record<string,any>,
  touched: Record<string,any>
): boolean => {
  for (let i = 0; i < fields.length; i++) {
    if (errors[fields[i].key] && touched[fields[i].key]) {
      return true;
    }
  }

  return false;
};