import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { UnknownAction } from 'redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import Axios from 'axios';

import { Box, Dialog, FormControlLabel, IconButton, Switch, TextField, Tooltip, Typography } from '@mui/material';
import {
  Close as CloseIcon,
} from '@mui/icons-material';
import { RiInformationLine } from '@remixicon/react';

import { useAuth } from 'src/hooks/useAuth';
import { useConfig } from 'src/hooks/useConfig';
import { appendContactSupport, axiosHeaders, getLocalisedErrorString } from 'src/utils/helpers';
import { CustomError } from 'src/types';
import { fetchDocTypes as fetchAllDocTypes, fetchDocTypesForImport, fetchCompanyDocTypes } from 'src/doc-types/actions';
import SubmitBtn from 'src/shared/buttons/SubmitBtn';
import CancelBtn from 'src/shared/buttons/CancelBtn';
import theme from 'src/themeNew';
import styles from './style';

interface PropTypes {
  open: boolean;
  selectedDocType: Record<string, string> | null;
  total: number;
  limit: number;
  fetchDocTypes: (_pageNo?: number) => void;
  handleClose: () => void;
}

const AddNewDocType = (props: PropTypes) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const { user } = useAuth();
  const { API } = useConfig();

  const { open, selectedDocType, total, limit, fetchDocTypes, handleClose } = props;

  const [isActive, setIsActive] = useState(selectedDocType ? Boolean(selectedDocType?.isActive) : true);

  useEffect(() => {
    setIsActive(selectedDocType ? Boolean(selectedDocType?.isActive) : true);
  }, [selectedDocType]);

  return (
    <Dialog
      open={open}
      sx={styles.modal}
      onClose={handleClose}
    >
      <Formik
        initialValues={{
          name: selectedDocType?.name || '',
          description: selectedDocType?.description || '',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .min(2, t('DOC_TYPE_NAME_MIN_HELPER_TEXT'))
            .max(64, t('DOC_TYPE_NAME_MAX_HELPER_TEXT'))
            .matches(/^[a-zA-Z0-9 _-]+[a-zA-Z0-9]$/, t('DOC_TYPE_NAME_INVALID'))
            .required(t('DOC_TYPE_NAME_REQUIRED_HELPER_TEXT')),
          description: Yup.string()
            .min(16, t('DOC_TYPE_DESCRIPTION_MIN_HELPER_TEXT'))
            .max(500, t('DOC_TYPE_DESCRIPTION_MAX_HELPER_TEXT'))
            .required(t('DOC_TYPE_DESCRIPTION_REQUIRED_HELPER_TEXT')),
        })}
        onSubmit={async (values, {
          resetForm,
          setStatus,
          setSubmitting
        }) => {
          try {
            const body = {
              companyID: user?.companyID,
              name: values.name.trim(),
              description: values.description.trim(),
              isActive,
            };
            if (selectedDocType?.documentType) {
              const response = await Axios.put(
                `${API.documentTypes}/${selectedDocType.documentType}`,
                body,
                axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
              );
              if (response.data.success) {
                fetchDocTypes();
                enqueueSnackbar(t('DOC_TYPE_UPDATE_SUCCESS', { docType: values.name }), {
                  variant: 'success',
                  style: { maxWidth: 400 }
                });
                dispatch(fetchAllDocTypes(user?.companyID || '') as unknown as UnknownAction);
                dispatch(fetchCompanyDocTypes() as unknown as UnknownAction);
                dispatch(fetchDocTypesForImport() as unknown as UnknownAction);
                resetForm();
                setStatus({ success: true });
                setSubmitting(false);
                handleClose();
              }
            } else {
              const response = await Axios.post(
                `${API.documentTypes}`,
                body,
                axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
              );
              if (response.data.success) {
                const newPageNo = Math.ceil((total + 1) / limit) - 1;
                fetchDocTypes(newPageNo);
                enqueueSnackbar(t('DOC_TYPE_ADD_SUCCESS', { docType: values.name }), {
                  variant: 'success',
                  style: { maxWidth: 400 }
                });
                dispatch(fetchAllDocTypes(user?.companyID || '') as unknown as UnknownAction);
                dispatch(fetchCompanyDocTypes() as unknown as UnknownAction);
                dispatch(fetchDocTypesForImport() as unknown as UnknownAction);
                resetForm();
                setStatus({ success: true });
                setSubmitting(false);
                handleClose();
              }
            }
          } catch (e) {
            const error = e as CustomError;
            setStatus({ success: false });
            if (error?.response?.data) {
              const errorMessage = getLocalisedErrorString(error.response.data.i18n
                || appendContactSupport(
                  window.config.support_email,
                  t(selectedDocType?.documentType ? 'DOC_TYPE_UPDATE_FAILURE' : 'DOC_TYPE_ADD_FAILURE'),
                  t
                ), t);
              enqueueSnackbar(
                errorMessage,
                {
                  variant: 'error',
                  autoHideDuration: 5000
                }
              );
            }
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values
        }) => (
          <form style={{ height: '100%' }}>
            <Box sx={styles.container}>
              <Box sx={styles.modalHeader}>
                <Box sx={styles.titleContainer}>
                  <Typography sx={styles.headerTitle}>
                    {t(selectedDocType?.documentType ? 'DOC_TYPES_TABLE_EDIT_TITLE' : 'DOC_TYPES_TABLE_ADD_NEW_TITLE')}
                  </Typography>
                  <IconButton sx={styles.closeBtn} onClick={handleClose}>
                    <CloseIcon sx={styles.closeIcon} />
                  </IconButton>
                </Box>
              </Box>
              <Box sx={styles.modalContent}>
                <Box sx={styles.addNewForm}>
                  <Box sx={styles.fieldContainer}>
                    <TextField
                      fullWidth
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      label={t('DOC_TYPE_NAME')}
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      value={values.name}
                      variant="outlined"
                      sx={styles.textField}
                    />
                  </Box>
                  <Box sx={styles.fieldContainer}>
                    <TextField
                      fullWidth
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      name="description"
                      label={t('DOC_TYPE_DESCRIPTION')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      value={values.description}
                      variant="outlined"
                      multiline
                      maxRows={10}
                      sx={styles.textFieldMulti}
                    />
                  </Box>
                  <Box sx={styles.activateContainer}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isActive}
                          onChange={(e) => setIsActive(e.target.checked)}
                        />
                      }
                      label={
                        <Box sx={styles.toggleLabelContainer}>
                          <Typography sx={styles.toggleLabel}>
                            {t('DOC_TYPE_ACTIVATE_TOGGLE')}
                          </Typography>
                          <Tooltip title={t('DOC_TYPE_ACTIVATE_TOGGLE_TOOLTIP')}>
                            <span style={styles.infoIconContainer}>
                              <RiInformationLine color={theme.palette.text.primary} size="20px" />
                            </span>
                          </Tooltip>
                        </Box>
                      }
                      labelPlacement="start"
                      sx={styles.formControlLabel}
                    />
                  </Box>
                </Box>
              </Box>
              <Box sx={styles.modalFooter}>
                <Box display="flex" justifyContent="flex-end" gap="16px">
                  <CancelBtn
                    title={t('DOC_TYPES_TABLE_ADD_NEW_CANCEL')}
                    onClick={handleClose}
                  />
                  <SubmitBtn
                    title={t(selectedDocType?.documentType ? 'DOC_TYPES_TABLE_UPDATE_BTN' : 'DOC_TYPES_TABLE_ADD_NEW_BTN')}
                    disabled={isSubmitting || (values.name === '' || values.description === '') || Object.keys(errors).length > 0}
                    onClick={handleSubmit}
                  />
                </Box>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Dialog>
  );
}

export default AddNewDocType;
