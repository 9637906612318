import { useTranslation } from 'react-i18next';

import {
  Typography
} from '@mui/material';

import CommonModal from 'src/shared/components/CommonModal/CommonModal';
import styles from './style';

interface PropTypes {
  open: boolean;
  handleClose: () => void;
  proceedClose: () => void;
}

const ConfirmCancelPopup = ({
  open,
  handleClose,
  proceedClose,
}: PropTypes) => {
  const { t, ready } = useTranslation();

  return (
    <CommonModal
      open={open}
      title={t('DOC_TYPES_MANAGE_FIELDS_CONFIRM_CLOSE')}
      cancelText={t('DOC_TYPES_MANAGE_FIELDS_CONFIRM_CLOSE_CANCEL')}
      submitText={t('DOC_TYPES_MANAGE_FIELDS_CONFIRM_CLOSE_CONFIRM')}
      handleClose={handleClose}
      onSubmit={proceedClose}
    >
      <Typography sx={styles.deleteMessageText}>
        {ready && t('DOC_TYPES_MANAGE_FIELDS_CONFIRM_CLOSE_MSG')}
      </Typography>
    </CommonModal>
  );
};

export default ConfirmCancelPopup;
