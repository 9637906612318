const styles = {
  appBar:{
    backgroundColor: '#F3F5F9',
    boxShadow: 'none',
    padding: '8px 20px',
    height: '56px',
  },
  desktopBar:{
    gap: '24px',
    alignItems: 'center'
  },
  subBtn:{
    borderRadius: '8px',
    fontSize: '12px',
    color: '#3E8AFF',
    backgroundColor: '#3E8AFF26',
    padding: '3px 10px',
    textTransform: 'none',
  },
  statsContainer:{
    display: 'flex',
    color: '#4C4E64',
    alignItems: 'center',
    fontSize: '14px',
    gap: '5px',
  },
  icon:{
    fontSize: '20px',
    color:'#6D788D'
  },
  fadeText:{
    color: '#4C4E6480'
  },
  menuItem:{
    display: 'flex',
    alignItems: 'center',
    width: '256px',
    gap: '10px',
    padding: '8px 16px',
    color: '#4C4E64',
    fontSize:'14px'
  },
  menuIcon: {
    fontSize: '22px',
    color: '#4c4e64'
  },
  menuText:{
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    fontSize: '15px',
    textAlign: 'center' as const,
  },
  menuContainer:{
    '.MuiMenu-paper': {
      boxShadow:' 0px 4px 8px -4px #4C4E646B',
    },
		mt: '45px',
  },
  companyBtn:{
    display: 'flex',
    gap: '10px',
    color: '#4C4E64'
  },
  defaultMenu:{
    ':hover':{
      backgroundColor: '#fff'
    }
  },
  kbIcon: {
    padding: 0,
    color: '#90A0B7'
  },
  notificationIcon: {
    color: '#90A0B7',
  },
  rightContainer: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  announceKitContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}

export default styles;
