import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Formik, FormikErrors } from 'formik';
import { UnknownAction } from 'redux';

import { Box, CircularProgress, IconButton, Modal, Typography, Backdrop, Fade } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import { supplierData } from 'src/config';
import { axiosHeaders, getLocalisedErrorString } from 'src/utils/helpers';
import authService from 'src/utils/authService';
import { fetchCostCenters } from 'src/dimensions/costcenters/actions';
import ConfigContext from 'src/contexts/ConfigContext';
import VatCodeTwinfield from 'src/dimensions-new/vatcodes/components/VatCodeTwinField';
import VatCodeStandard from 'src/dimensions-new/vatcodes/components/VatCodeStandard';
import CostCenterModal from 'src/dimensions-new/costcenters/components/CostCenterModal';
import { CustomError, RootState, VatGroupType } from 'src/types';

type PropsType = {
  vatCodeModalOpen: boolean;
  selectedVatCode: VatGroupType | null;
  handleClose: () => void;
  onSuccess: (_str: string) => void;
  getAllVatCodes: (_page?: number, _limit?: number, _search?: string, _loadFullScreen?: boolean) => void;
  isDisabled: boolean;
}

function VatCodeModal({
  vatCodeModalOpen,
  selectedVatCode,
  handleClose,
  onSuccess,
  getAllVatCodes,
  isDisabled
}: PropsType) {
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const { LOGIN_PANEL_URL, API } = useContext(ConfigContext);
  
  const auth = useAuth();
  const {user} = auth;

  const _costCenters = useSelector((state:RootState) => state.costcenter.costCenters);
  const costCenters = [...supplierData.defaultCostCenters, ...useMemo(()=>_costCenters, [_costCenters])];
  
  const _glaccounts = useSelector((state:RootState) => state.gla.glAccounts);
  const glaccounts = [...supplierData.defaultGLA, ...useMemo(()=>_glaccounts, [_glaccounts])];

  const [ccModalOpen, setCCModalOpen] = useState<boolean>(false);
  const [selectedCostCenterCode, setSelectedCostCenterCode] = useState<string>('');
  const [isEditCostCenter, setIsEditCostCenter] = useState<boolean>(false);
  const [newlyAddingProp, setNewlyAddingProp] = useState<string>('');
  const [newlyAddedVal, setNewlyAddedVal] = useState<string>('');

  const handleChangeAutocomplete = (e:React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | 
    React.SyntheticEvent<Element, Event>, prop:string, values:Record<string,string | boolean>, handleChange:Function) => {
    if (values && values.code) {
      handleChange({ target: { name: prop, value: values.code } });
      return;
    }

    handleChange(e, prop);
  };

  const getCostCenters = () => {
    dispatch(fetchCostCenters(user?.companyID) as unknown as UnknownAction);
  };

  const onCCModalClose = () => {
    setCCModalOpen(false);
    setSelectedCostCenterCode('');
  };

  const handleEditCostCenter = (costCenter: string) => {
    if (costCenter !== supplierData.defaultCostCenters[0].code) {
      setSelectedCostCenterCode(costCenter);
      setCCModalOpen(true);
      setIsEditCostCenter(true);
    }
  };

  const handleAddCostCenter = (prop:string) => {
    setSelectedCostCenterCode('');
    setCCModalOpen(true);
    setNewlyAddingProp(prop);
    setIsEditCostCenter(false);
  };

  const onCCCreated = (val:string) => {
    setNewlyAddedVal(val);
  };

  useEffect(()=>{
    getCostCenters()
  },[])


  return (
    <>
      <Modal
        style={styles.modal}
        open={vatCodeModalOpen}
        onClose={()=>handleClose()}
      >
        <Fade in={vatCodeModalOpen}>
          <div>
            <Formik
              initialValues={{
                code: selectedVatCode?.code || '',
                name: selectedVatCode?.name || '',
                type: selectedVatCode?.type || '',
                shortName: selectedVatCode?.shortName || '',
                invoiceText: selectedVatCode?.invoiceText || '',
                ratePercentage: selectedVatCode?.ratePercentage || '',
                rateName: selectedVatCode?.rateName || '',
                rateShortName: selectedVatCode?.rateShortName || '',
                ruleAccount: selectedVatCode?.ruleAccount || supplierData.defaultGLA[0].code,
                rulePercentage: selectedVatCode?.rulePercentage || '',
                ruleCostCenter: selectedVatCode?.ruleCostCenter || supplierData.defaultCostCenters[0].code,
                ruleVatGroup: selectedVatCode?.ruleVatGroup || '',
              }}
              validationSchema={Yup.object().shape({
                code: Yup.string().max(255)
                  .when('name', {
                    is: () => user?.interfacePreference === 'twinfield_interface',
                    then: () => Yup.string().max(255).matches(/^\S*$/, 'VAT_CODE_CODE_SPACES_NOT_ALLOWED').required('VAT_CODE_CODE_REQUIRED')
                  } as any),
                name: Yup.string()
                  .max(80, 'VAT_CODE_NAME_MAX_VALIDATION')
                  .required('VAT_CODE_NAME_REQUIRED'),
                  
                type: Yup.string().max(255)
                  .when('name', {
                    is: () => user?.interfacePreference === 'twinfield_interface',
                    then: () => Yup.string().max(255).required('VAT_CODE_TYPE_REQUIRED'),
                    otherwise: () => Yup.string(),
                  } as any),
                ratePercentage: Yup.string()
                  .max(255)
                  .required('VAT_CODE_RATE_PERCENTAGE_REQUIRED')
                  .matches(/^100(\.0{1,2})?$|^(\d{1,2}(\.\d{1,2})?)$/, 'VAT_CODE_RATE_PERCENTAGE_LIMITED'),
                rateName: Yup.string().max(255)
                  .when('name', {
                    is: () => user?.interfacePreference === 'twinfield_interface',
                    then: () => Yup.string().max(255).required('VAT_CODE_RATE_NAME_REQUIRED')
                  } as any),
                rateShortName: Yup.string().max(255)
                  .when('name', {
                    is: () => user?.interfacePreference === 'twinfield_interface',
                    then: () => Yup.string().max(255),
                    otherwise:() => Yup.string(),
                  } as any),
                ruleAccount: Yup.string().max(255)
                  .when('name', {
                    is: () => user?.interfacePreference === 'twinfield_interface',
                    then: () => Yup.string().max(255).required('VAT_CODE_RULE_ACCOUNT_REQUIRED'),
                    otherwise:() => Yup.string(),
                  } as any),
                rulePercentage: Yup.string()
                .max(255)
                // .matches(/^(?:\\d{1,2}(?:\\.\\d{1,2})?|100(?:\\.0?0)?)$/, 'VAT_CODE_RULE_PERCENTAGE_LIMITED')
                .when('name', {
                  is: () => user?.interfacePreference === 'twinfield_interface',
                  then: () => Yup.string()
                    .max(255)
                    .matches(/^(?:\\d{1,2}(?:\\.\\d{1,2})?|100(?:\\.0?0)?)$/, 'VAT_CODE_RULE_PERCENTAGE_LIMITED')
                    .required('VAT_CODE_RULE_PERCENTAGE_REQUIRED')
                } as any),
              })}
              onSubmit={async (values, {
                resetForm,
                setErrors,
                setStatus,
                setSubmitting
              }) => {
                try {
                  if (!authService.validateToken()) {
                    enqueueSnackbar(ready && t('VAT_CODE_LOGIN_EXPIRED'), {
                      variant: 'error',
                    });
                    setTimeout(() => {
                      authService.logout(LOGIN_PANEL_URL);
                    }, 2000);
                    return;
                  }
                  setLoading(true);
                  const body = {
                    code: values.code,
                    name: values.name,
                    type: values.type,
                    shortName: values.shortName,
                    invoiceText: values.invoiceText,
                    ratePercentage: values.ratePercentage,
                    rateName: values.rateName,
                    rateShortName: values.rateShortName,
                    ruleAccount: values.ruleAccount,
                    rulePercentage: values.rulePercentage,
                    ruleCostCenter: values.ruleCostCenter,
                    ruleVatGroup: values.ruleVatGroup,
                  };

                  const url = `${API.vatCodesByCompany}/${encodeURIComponent(user?.companyID as string)}`;
                  let response = null;
                  let snackText = '';

                  if (selectedVatCode && selectedVatCode.name) {
                    response = await Axios.put(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
                    snackText = 'VAT_CODES_UPDATE_SUCCESS';
                  } else {
                    response = await Axios.post(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
                    snackText = 'VAT_CODES_ADD_SUCCESS';
                  }
                  if (response?.data?.success) {
                    setLoading(false);
                    getAllVatCodes();
                    resetForm();
                    setStatus({ success: true });
                    setSubmitting(false);
                    enqueueSnackbar(ready && t(snackText), {
                      variant: 'success',
                      style: { maxWidth: 400 }
                    });
                    handleClose();
                    
                    if (onSuccess) {
                      onSuccess(response.data.id);
                    }
                  }
                } catch (e) {
                  const error = e as CustomError;
                  setLoading(false);
                  setStatus({ success: false });
                  setSubmitting(false);
                  if (error && error?.response && error?.response?.data && 
                    error?.response?.data?.i18n && error.response.data.i18n.indexOf('__TFMSG__') > 0) {
                    const errParts = error.response.data.i18n.split('__TFMSG__');
                    setErrors({
                      [errParts[0]]: errParts[1]
                    });
                  } else {
                    setErrors({
                      submit: getLocalisedErrorString(error?.response?.data?.i18n, t)
                    } as FormikErrors<CustomError>);
                  }
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setErrors,
                isSubmitting,
                touched,
                values
              }) => (
                <Box sx={styles.root}>
                  <Box sx={styles.wrapper}>
                    <Box sx={styles.topBar}>
                      <Typography sx={styles.headerTitle}>
                        {selectedVatCode?.name
                          ? `${t('VAT_CODE')} - ${selectedVatCode ? selectedVatCode.name : ''} (${selectedVatCode?.code})`
                          : t('VAT_CODE_ADD_NEW')
                        }
                      </Typography>
                      <IconButton onClick={()=>handleClose()}>
                        <CloseIcon style={styles.closeIcon} />
                      </IconButton>
                    </Box>
                    {user?.interfacePreference === 'twinfield_interface'
                      ? (
                        <VatCodeTwinfield
                          errors={errors}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          handleSubmit={handleSubmit}
                          setErrors={setErrors}
                          isSubmitting={isSubmitting}
                          touched={touched}
                          values={values}
                          vatCode={selectedVatCode}
                          handleClose={handleClose}
                          handleChangeAutocomplete={handleChangeAutocomplete}
                          costCenters={costCenters}
                          handleEditCostCenter={handleEditCostCenter}
                          handleAddCostCenter={handleAddCostCenter}
                          glaccounts={glaccounts}
                          newlyAddedVal={newlyAddedVal}
                          newlyAddingProp={newlyAddingProp}
                          isDisabled={isDisabled}
                        />
                      ) : (
                        <VatCodeStandard
                          errors={errors}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          handleSubmit={handleSubmit}
                          setErrors={setErrors}
                          isSubmitting={isSubmitting}
                          touched={touched}
                          values={values}
                          vatCode={selectedVatCode}
                          handleClose={handleClose}
                          handleChangeAutocomplete={handleChangeAutocomplete}
                          costCenters={costCenters}
                          handleEditCostCenter={handleEditCostCenter}
                          handleAddCostCenter={handleAddCostCenter}
                          glaccounts={glaccounts}
                          newlyAddedVal={newlyAddedVal}
                          newlyAddingProp={newlyAddingProp}
                          isDisabled={isDisabled}
                        />
                      )
                    }
                  </Box>
                </Box>)
              }
            </Formik>
          </div>
        </Fade>
      </Modal>
      <CostCenterModal
        isEdit={isEditCostCenter}
        costCenterModalOpen={ccModalOpen}
        handleClose={onCCModalClose}
        getAllCostCenters={getCostCenters}
        onSuccess={onCCCreated}
        selectedCostCenter={selectedCostCenterCode}
      />
      <Backdrop open={loading} sx={{ zIndex: 111111, color: '#fff' }}>
        <CircularProgress />
      </Backdrop>
    </>
  );
}


export default VatCodeModal;

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100dvw',
    height: '90dvh',
  },
  wrapper: {
    height: '100%',
    width: '700px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0px 4px 18px 3px #4C4E641F, 0px 10px 14px 1px #4C4E6424, 0px 6px 6px -3px #4C4E6433',
    paddingBlockEnd: '10px'
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 24px',
    borderBottom: '1px solid #4C4E641F'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px',
    maxHeight: '100dvh'
  },
  headerTitle: {
    color: '#4C4E64',
    fontSize: '20px',
    fontWeight: '500',
    textTransform: 'capitalize',
    lineHeight: '32px'
  },
  closeIcon: {
    width: '20px',
    height: '20px',
    color: 'rgba(51, 77, 110, 0.5)',
  },
};


