import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';
import Axios from 'axios';

import {
  Avatar, Box, MenuItem, Select, Typography
} from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';
import { CustomError, SelectedDocType } from 'src/types';
import { UserDataType } from 'src/types';
import { getLocalisedErrorString, appendContactSupport, axiosHeaders } from 'src/utils/helpers';
import CommonModal from 'src/shared/components/CommonModal/CommonModal';
import { truncateString } from 'src/utils/helpers';
import styles from './style';
import { useConfig } from 'src/hooks/useConfig';

interface PropTypes {
  open: boolean;
  selectedDocs: SelectedDocType[];
  loading: boolean;
  companyFilterApplied: boolean;
  users: UserDataType[];
  handleClose: () => void;
  refreshDocuments: () => void;
  setSelectedDocs: React.Dispatch<React.SetStateAction<SelectedDocType[]>>;
}

const AssignUserModal = ({
  open,
  selectedDocs,
  loading,
  companyFilterApplied,
  users,
  handleClose,
  refreshDocuments,
  setSelectedDocs,
}: PropTypes) => {
  const { t, ready } = useTranslation();

  const { API } = useConfig();
  const { user } = useAuth();

  const [bulkOwner, setBulkOwner] = useState('');

  const onAssignUser = async () => {
    try {
      const body = {
        assignee: bulkOwner,
        company: user?.companyID,
        ids: selectedDocs.map((i) => i.id)
      };

      const response = await Axios.post(
        API.bulkDocumentOwner,
        body,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        refreshDocuments();
        setSelectedDocs([]);
        enqueueSnackbar(t('PROCYS_OWNER_CHANGE_SUCCESS'), {
          variant: 'success',
        });
      }
    } catch (e) {
      const error = e as CustomError;
      enqueueSnackbar(getLocalisedErrorString(error?.response?.data?.i18n, t)
      || appendContactSupport(window.config.support_email, t('PROCYS_OWNER_CHANGE_FAILED'), t), {
        variant: 'error',
      });
    }
  };

  const renderBulkOwner = () => {
    if (!bulkOwner) {
      return (
        <Box display="flex" justifyContent="flex-start" alignItems="flex-end">
          <Typography style={styles.labelText}>
            {t('DASHBOARD_INVOICES_ASSIGN_USER')}
          </Typography>
        </Box>
      );
    }
    const owner = users.find((u) => u.email === bulkOwner);
    return (
      <Box display="flex" alignItems="center">
        <Avatar
          variant="circular"
          alt={owner?.firstname}
          sx={styles.userImage}
          src={
            owner?.avatar && owner.avatar.indexOf('data:image') >= 0
              ? owner.avatar : `${API.getProfileImage}${owner?.avatar}`
          }
        />
        <Typography sx={styles.usernameText}>
          {truncateString(`${owner?.firstname} ${owner?.lastname}`, 20)}
        </Typography>
      </Box>
    );
  };

  return (
    <CommonModal
      open={open}
      title={t('DASHBOARD_INVOICES_ASSIGN_USER')}
      subtitle={t('ASSING_USER_MODAL_SUBTITLE', { count: selectedDocs.length })}
      cancelText={t('INVOICE_DELETE_CANCEL')}
      submitText={t('BULK_OPERATIONS_ASSIGN')}
      handleClose={handleClose}
      onSubmit={onAssignUser}
    >
      <Box sx={styles.container}>
        <Typography sx={styles.labelText}>
          {ready && t('ASSING_USER_MODAL_SELECT_LABEL')}
        </Typography>
        <Select
          disabled={companyFilterApplied || loading}
          onChange={(e) => setBulkOwner(e.target.value)}
          sx={styles.select}
          value={bulkOwner}
          variant="outlined"
          displayEmpty
          renderValue={renderBulkOwner}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            sx: styles.ownerMenuContainer
          }}
        >
          {users.map((u) => (
            <MenuItem key={u.email} value={u.email}>
              <Box display="flex" alignItems="center">
                <Avatar
                  variant="circular"
                  alt={u.firstname}
                  sx={styles.userImage}
                  src={
                    u.avatar && u.avatar.indexOf('data:image') >= 0
                      ? u.avatar : `${API.getProfileImage}${u.avatar}`
                  }
                />
                <Typography sx={styles.usernameText}>
                  {truncateString(`${u.firstname} ${u.lastname}`, 20)}
                </Typography>
              </Box>
            </MenuItem>
          ))}
        </Select>
      </Box>
    </CommonModal>
  );
};

export default AssignUserModal;
