import { useRef } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import AppRoutes from 'src/Routes';
import ConfigContext from 'src/contexts/ConfigContext';
import SnackbarCloseButton from 'src/shared/components/SnackbarCloseButton/SnackbarCloseButton';

function App() {
  const config = {
    APP_VERSION: window.config.version,
    ENVIRONMENT: window.config.env,
    BRAND_NAME: window.config.brand,
    BRAND_DOMAIN: window.config.brand_domain,
    IS_BRAND_PROCYS: window.config.brand === 'Procys',
    SUPPORT_EMAIL: window.config.support_email,
    BRAND_URL: window.config.brand_url,
    LOGIN_PANEL_URL: window.config.frontend.login_panel,
    API: {
      verifyToken: `${window.config.backend.login_service}v1/auth/tokens/validate`,
      getStatsByUser: `${window.config.backend.procys_service}v1/users/stats`,
      getAppStats: `${window.config.backend.procys_service}v1/users/stats/db`,
      getDashboardStats: `${window.config.backend.provider_service}v1/statistics/dashboard`,
      sendEmailForOP: `${window.config.backend.login_service}v1/op/send-email`,
      getStatsOverview: `${window.config.backend.procys_service}v1/stats/overview`,
      getStatsGraph: `${window.config.backend.procys_service}v1/stats/graph`,

      getAttachment: `${window.config.backend.procys_service}v1/files/attachments/`,
      getInvoiceImage: `${window.config.backend.procys_service}v1/files/documents/`,
      getInvImgByCompAndTrans: `${window.config.backend.procys_service}v1/files/invoices/images/company/`,
      getInvoiceImageByTrans: `${window.config.backend.procys_service}v1/files/invoices/images/transaction/`,
      getAllInvoices: `${window.config.backend.procys_service}v1/documents/company`,
      getArchivedInvoices: `${window.config.backend.procys_service}v1/documents/archived/company`,
      updateInvoice: `${window.config.backend.procys_service}v1/documents/`,
      updateInvoiceAI: `${window.config.backend.procys_service}v1/documents/ai/`,
      uploadDocument: `${window.config.backend.procys_service}v1/documents`,
      bulkUpload: `${window.config.backend.procys_service}v1/documents/bulk`,
      deleteDuplicates: `${window.config.backend.procys_service}v1/documents/delete/duplicates`,
      invoices: `${window.config.backend.procys_service}v1/invoices/`,
      updateIncludeLinesInExport: `${window.config.backend.procys_service}v1/documents/lines/export`,
      bulkDownload: `${window.config.backend.procys_service}v1/documents/bulk/download`,
      bulkSignedDownload: `${window.config.backend.procys_service}/v1/documents/bulk/link`,
      getAllInvoiceIds: `${window.config.backend.procys_service}v1/documents/id/company`,
      getAllArchivedInvoiceIds: `${window.config.backend.procys_service}v1/documents/archived/id/company`,
      processNoneDuplicate: `${window.config.backend.procys_service}v1/documents/assistant/resend`,
      lineBoundaries: `${window.config.backend.procys_service}v1/documents/line-boundaries`,
      getSignedDocument: `${window.config.backend.procys_service}v1/files/documents/signed/`,
      getAllDocumentIdsV2: `${window.config.backend.procys_service}v2/documents/ids/company`,
      getAllDocumentsV2: `${window.config.backend.procys_service}v2/documents/company`,

      getDocumentByIDDeprecated: `${window.config.backend.procys_service}v1/documents/assistant-id/`,
      getDocumentXMLDeprecated: `${window.config.backend.procys_service}v1/documents/xml/`,
      getDocumentByID: `${window.config.backend.procys_service}v2/documents/id/`,
      getDocumentXML: `${window.config.backend.procys_service}v2/documents/xml/`,
      documentStatus: `${window.config.backend.procys_service}v2/documents/status`,
      bulkDocumentStatus: `${window.config.backend.procys_service}v2/documents/bulk/status`,
      documentOwner: `${window.config.backend.procys_service}v2/documents/owner`,
      bulkDocumentOwner: `${window.config.backend.procys_service}v2/documents/bulk/owner`,
      getDocumentBoundaries: `${window.config.backend.procys_service}v2/documents/boundaries/`,

      export: `${window.config.backend.procys_service}v2/documents/export/`,
      exportBulk: `${window.config.backend.procys_service}v2/documents/export/bulk/`,
      exportToEmail: `${window.config.backend.procys_service}v2/documents/export/email/`,
      exportFieldMapping: `${window.config.backend.procys_service}v1/documents/export/mapping/`,

      userProfile: `${window.config.backend.login_service}v1/external/user-details`,
      updateUserByCreator: `${window.config.backend.login_service}v1/external/users/creator`,
      usersByCompany: `${window.config.backend.login_service}v1/external/users/companies`,
      userProfileImage: `${window.config.backend.login_service}v1/external/users/profile-image`,
      userAvatarImage: `${window.config.backend.login_service}v1/external/users/avatar-image`,
      changePassword: `${window.config.backend.login_service}v1/auth/change-password`,
      getLanguages: `${window.config.backend.provider_service}v1/admin/languages`,
      getLanguageJSON: `${window.config.backend.provider_service}v1/admin/languages/json`,
      getAllRoles: `${window.config.backend.provider_service}v1/admin/roles`,
      getUserRoles: `${window.config.backend.login_service}v1/users/roles`,
      revokeUser: `${window.config.backend.login_service}v1/users/add/revoke`,
      removeUser: `${window.config.backend.login_service}v1/users/remove`,
      restoreUser: `${window.config.backend.login_service}v1/users/restore`,
      deleteUser: `${window.config.backend.login_service}v1/users/delete`,

      userCards: `${window.config.backend.procys_service}v1/subscriptions/cards/customers`,
      changeDefaultPaymentMethod: `${window.config.backend.procys_service}v1/subscriptions/cards/change`,
      subscriptionDetails: `${window.config.backend.procys_service}v1/subscriptions/customers`,
      userInvoices: `${window.config.backend.procys_service}v1/subscriptions/invoices`,

      suppliers: `${window.config.backend.procys_service}v1/suppliers`,
      suppliersV2: `${window.config.backend.procys_service}v2/suppliers`,
      findSuppliers: `${window.config.backend.procys_service}v1/suppliers/find/all`,
      offices: `${window.config.backend.procys_service}v1/offices`,
      vatGroups: `${window.config.backend.procys_service}v1/vatcodes`,
      glAccounts: `${window.config.backend.procys_service}v1/glaccounts`,
      costCenters: `${window.config.backend.procys_service}v1/costcenters`,
      projects: `${window.config.backend.procys_service}v1/projects`,
      dimensions: `${window.config.backend.procys_service}v1/dimensions/`,
      processes: `${window.config.backend.procys_service}v1/processes/`,
      gstockSuppliersImport: `${window.config.backend.procys_service}v1/suppliers/import/gstock`,
      gstockProductsImport: `${window.config.backend.procys_service}v1/products/import/gstock`,

      costCentersByCompany: `${window.config.backend.procys_service}v1/dimensions/costcenters/companies`,
      costCenterByCode: `${window.config.backend.procys_service}v1/dimensions/costcenters/code`,
      projectsByCompany: `${window.config.backend.procys_service}v1/dimensions/projects/companies`,
      projectByCode: `${window.config.backend.procys_service}v1/dimensions/projects/code`,
      vatCodesByCompany: `${window.config.backend.procys_service}v1/dimensions/vatcodes/companies`,
      vatCodeByCode: `${window.config.backend.procys_service}v1/dimensions/vatcodes/code`,
      glAccountsByCompany: `${window.config.backend.procys_service}v1/dimensions/glaccounts/companies`,
      glAccountByCode: `${window.config.backend.procys_service}v1/dimensions/glaccounts/code`,
      glAYearsByCompany: `${window.config.backend.procys_service}v1/dimensions/glaccounts/years/companies`,
      glAPeriodsByCompany: `${window.config.backend.procys_service}v1/dimensions/glaccounts/periods/companies`,
      getDimensionLogsByCompany: `${window.config.backend.provider_service}v1/dimensions/`,

      inviteUser: `${window.config.backend.procys_service}v1/company-users/invite`,
      inviteUserCallback: `${window.config.backend.procys_service}v1/company-users/invite/email-confirmation/`,
      getCompaniesByUser: `${window.config.backend.procys_service}v1/company-users/users/`,
      getUsersByCompanies: `${window.config.backend.procys_service}v1/company-users/companies/`,
      getProfileImage: `${window.config.backend.login_service}v1/external/users/avatar/`,

      company: `${window.config.backend.login_service}v1/companies`,
      changeCompany: `${window.config.backend.procys_service}v1/companies/change`,
      deleteCompany: `${window.config.backend.login_service}v1/companies`,
      companyAndUser: `${window.config.backend.login_service}v1/company-and-user`,
      companyAndUserDetails: `${window.config.backend.login_service}v1/company-and-user/details`,
      companiesManaged: `${window.config.backend.procys_service}v1/companies/managed`,

      getLogsByCompany: `${window.config.backend.provider_service}v1/admin/logs/invoice/company`,
      getLogsByInvoice: `${window.config.backend.provider_service}v1/admin/logs/invoice`,
      getInvoiceLogByID: `${window.config.backend.provider_service}v1/admin/logs/invoices`,
      getUploadLogsByCompany: `${window.config.backend.provider_service}v1/admin/logs/invoice/upload/company`,
      getExportLogsByCompany: `${window.config.backend.procys_service}v1/documents/export/list`,
      downloadExportedFile: `${window.config.backend.procys_service}v1/documents/export/file`,
      downloadAll: `${window.config.backend.procys_service}v1/downloads/dashboard/`,

      getSupplierLogsByCompany: `${window.config.backend.provider_service}v1/admin/logs/supplier/company`,
      getLogsBySuppierCode: `${window.config.backend.provider_service}v1/admin/logs/supplier/code`,
      getSupplierLogByID: `${window.config.backend.provider_service}v1/admin/logs/supplier`,

      updateCompanySubscription: `${window.config.backend.provider_service}v1/admin/companies/subscriptions/change`,
      updateSubscription: `${window.config.backend.procys_service}v1/subscriptions/customers`,
      cancelSubscriptionChange: `${window.config.backend.procys_service}v1/subscriptions/cancel`,
      buyExtraInvoicesPackage: `${window.config.backend.procys_service}v1/subscriptions/customers/extra`,
      subscriptionUpgradeContact: `${window.config.backend.procys_service}v1/subscriptions/customers/contact`,

      integrations: `${window.config.backend.procys_service}v1/integrations`,
      sendAppRequest: `${window.config.backend.procys_service}v1/integrations/request`,
      sendNotifiRequest: `${window.config.backend.procys_service}v1/integrations/notify`,
      savedDetailsTf: `${window.config.backend.procys_service}v1/integrations/twinfield`,
      savedDetailsBc: `${window.config.backend.procys_service}v1/integrations/bc/manage`,
      savedDetailsGStock: `${window.config.backend.procys_service}v1/integrations/gstock`,
      savedDetailsQuickbooks: `${window.config.backend.procys_service}v1/integrations/quickbooks`,
      savedDetailsHolded: `${window.config.backend.procys_service}v1/integrations/holded`,
      savedDetailsSFTP: `${window.config.backend.procys_service}v1/integrations/sftp`,
      testSFTPConnection: `${window.config.backend.procys_service}v1/integrations/sftp/connection`,
      savedHaciendaPeriod: `${window.config.backend.procys_service}v1/integrations/hacienda`,
      quickbooksOAuth2Callback: `${window.config.backend.procys_service}v1/integrations/quickbooks/oauth2/callback`,
      twinfieldTokenCallback: `${window.config.backend.procys_service}v1/integrations/twinfield/token/callback`,
      checkQuickbooksConnection: `${window.config.backend.procys_service}v1/integrations/quickbooks/connection`,
      checkGStockConnection: `${window.config.backend.provider_service}v1/integrations/gstock/connection`,
      checkHoldedConnection: `${window.config.backend.procys_service}v1/integrations/holded/connection`,
      syncQuickbooks: `${window.config.backend.procys_service}v1/integrations/quickbooks/vendor`,
      syncSFTP: `${window.config.backend.procys_service}v1/integrations/sftp/dimension/sync`,
      syncHolded: `${window.config.backend.procys_service}v1/integrations/sync-dimensions/holded`,
      syncGstock: `${window.config.backend.procys_service}v1/integrations/gstock/sync`,
      getAPIDetails: `${window.config.backend.procys_service}v1/integrations/api/assistant/manage`,
      saveEmailBc: `${window.config.backend.procys_service}v1/integrations/bc/email`,
      saveEmailReceive: `${window.config.backend.procys_service}v1/integrations/email/receive/allowed`,
      saveEmailDeliver: `${window.config.backend.procys_service}v1/integrations/email/deliver/allowed`,
      saveDeliverFormat: `${window.config.backend.procys_service}v1/integrations/email/deliver/format`,
      getTokenPairFromAccessToken: `${window.config.backend.login_service}v1/auth/tokens/access-token`,
      getTokenPairFromRefreshToken: `${window.config.backend.login_service}v1/auth/tokens/refresh-token`,
      getDeliverFormats: `${window.config.backend.procys_service}v1/integrations/email/deliver/format`,
      getHaciendaDetails: `${window.config.backend.procys_service}v1/integrations/hacienda/details`,
      getClosingPeriods: `${window.config.backend.procys_service}v1/integrations/hacienda/closing-periods/`,

      fieldsRequest: `${window.config.backend.login_service}v1/fields/request`,
      fieldsCustomReceivers: `${window.config.backend.login_service}v1/fields/receivers/custom`,
      fields: `${window.config.backend.procys_service}v1/fields`,
      fieldsAddNew: `${window.config.backend.procys_service}v1/fields/new`,
      fieldsTraining: `${window.config.backend.procys_service}v1/fields/new/training`,
      fieldsV2: `${window.config.backend.procys_service}v2/fields`,

      selectDocumentType: `${window.config.backend.procys_service}v1/companies/documents/`,
      documentTypes: `${window.config.backend.procys_service}v2/document-types`,
      markDocumentTypeDefault: `${window.config.backend.procys_service}v2/document-types/mark/default`,
      importDocumentTypes: `${window.config.backend.procys_service}v2/document-types/all/import`,
      documentTypesMin: `${window.config.backend.procys_service}v2/document-types/min/company`,

      feedbackLogs: `${window.config.backend.provider_service}v1/logs/feedback`,

      splitDocument: `${window.config.backend.procys_service}v1/documents/upload/split`,
      aiSplit: `${window.config.backend.procys_service}v1/documents/upload/split/ai/`,
      bulkSplit: `${window.config.backend.procys_service}v1/documents/upload/split/bulk`,

      brand: `${window.config.backend.provider_service}v1/brands`,
      brandLogo: `${window.config.backend.provider_service}v1/brands/logo/`,
      brandFavicon: `${window.config.backend.provider_service}v1/brands/favicon/`,
      brandStatus: `${window.config.backend.provider_service}v1/brands/status`,

      customisations: `${window.config.backend.procys_service}v1/companies/customisations`,
      updateCommsLang: `${window.config.backend.procys_service}v1/companies/comms-language`,

      qbTerms: `${window.config.backend.procys_service}v1/custom/objects/qb-terms/companies`,
      partnerStats: `${window.config.backend.procys_service}v1/partners/customers`,
      downloadAllPartnerStats: `${window.config.backend.procys_service}v1/downloads/partner-stats`,

      updateHasSeenNewUI: `${window.config.backend.login_service}v1/meta/hasSeenNewUI`,
    }
  };

  const notistackRef = useRef(null);

  return (
    <ConfigContext.Provider value={config}>
      <SnackbarProvider
        ref={notistackRef}
        maxSnack={3}
        action={(key) => <SnackbarCloseButton snackbarKey={key} />}
        autoHideDuration={5000}
      >
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </SnackbarProvider>
    </ConfigContext.Provider>
  );
}

export default App;
