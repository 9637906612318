import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, Chip,
  FormControl, FormHelperText, Grid, InputAdornment, MenuItem, Select, TextField, Tooltip, Autocomplete, 
  Typography
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon, Edit as EditIcon, AddBox as PlusIcon } from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import { supplierData, supplierFields, userRoles } from 'src/config';
import { isActionPermitted, permissions } from 'src/utils/helpers';
import { CostCenterType, CountryType, CurrencyType, GLAccountType, ProjectType, VatGroupType } from 'src/types';
import {
  filterAutocompleteOptionsCostCenter, filterAutocompleteOptionsGLA, filterAutocompleteOptionsVatGroups,
  getAutoCompleteValue, filterAutocompleteOptionsCurrency, sectionHasError, showEditIcon
} from 'src/dimensions-new/utils';
import theme from 'src/theme';

type PropsType = {
  isInvoiceEditForm: boolean;
  isEdit: boolean;
  values: Record<string,string | boolean>
  errors: Record<string,string>
  touched: Record<string,boolean>
  handleBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;
  handleSubmit: ()=> void;
  isSubmitting: boolean;
  handleEditSupplierClose: () => void;
  handleChangeAutocomplete: (_e:React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | 
    React.SyntheticEvent<Element, Event>, _prop:string, _value:Record<string, any>, _changeFunc:Function) => void;
  handleEditGLA: (_prop: string) => void;
  handleAddGLA: (_prop: string) => void;
  glAccounts: GLAccountType[];
  handleAddVATCode: (_id: string) => void;
  handleEditVATCode: (_id: string) => void;
  vatGroups: VatGroupType[];
  handleEditCostCenter: (_cc: string) => void;
  handleAddCostCenter: (_c: string) => void;
  costCenters: CostCenterType[];
  projects: ProjectType[];
  countries: CountryType[];
  currencies: CurrencyType[];
  xmlSelectedValue:string;
  textFieldSelectedKey:string;
  textFieldSelectedValueRef:Record<string,any>;
  setTextFieldSelectedKey: (_key: string) => void;
  setTextFieldSelectedValue: (_value: string) => void;
  newlyAddedVal: string;
  newlyAddingProp: string;
  isDisabled: boolean;
};

const EditSupplierTwinfield = (props: PropsType) => {
  const { t, ready } = useTranslation();
  
  const {
    isInvoiceEditForm,
    isEdit,
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    isSubmitting,
    handleEditSupplierClose,
    handleChange,
    handleChangeAutocomplete,
    handleEditGLA,
    handleAddGLA,
    glAccounts,
    handleEditVATCode,
    handleAddVATCode,
    vatGroups,
    handleEditCostCenter,
    handleAddCostCenter,
    costCenters,
    projects,
    currencies,
    countries,
    xmlSelectedValue,
    textFieldSelectedKey,
    textFieldSelectedValueRef,
    setTextFieldSelectedKey,
    setTextFieldSelectedValue,
    newlyAddedVal,
    newlyAddingProp,
    isDisabled
  } = props;

  const auth = useAuth();
  const {user} = auth;

  const supplierFieldIds = supplierFields.map((s) => s.fields.map((f) => `${f.key}Id`)).flat();

  useEffect(() => {
    if (textFieldSelectedKey && xmlSelectedValue?.trim() && xmlSelectedValue.trim() !== values[textFieldSelectedKey]) {
      handleChange({ target: { name: textFieldSelectedKey, value: xmlSelectedValue.trim() } } as 
      React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>);
    } else if (xmlSelectedValue?.trim()) {
      let selectedKey = '';
      Object.keys(values).forEach((k) => {
        if (values[k] === xmlSelectedValue.trim()) {
          if (selectedKey) {
            selectedKey = selectedKey.concat(' ').concat(k);
          } else {
            selectedKey = k;
          }
        }
      });
      setTextFieldSelectedKey(selectedKey);
    } else if (!xmlSelectedValue) {
      setTextFieldSelectedKey('');
    }
  }, [xmlSelectedValue]);

  useEffect(() => {
    if (isInvoiceEditForm) {
      document.addEventListener('keydown', (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          setTextFieldSelectedKey('');
        }
      });
      document.addEventListener('click', (e: MouseEvent) => {
        let element = e?.target as HTMLElement;
        let id = element?.getAttribute('id') || '';
        if (!['canvasId2', ...supplierFieldIds].some((o) => o === id)) {
          setTextFieldSelectedKey('');
          if (textFieldSelectedValueRef.current === '') {
            setTextFieldSelectedValue(' ');
          } else {
            setTextFieldSelectedValue('');
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (newlyAddedVal && newlyAddingProp) {
      handleChange({ target: { name: newlyAddingProp, value: newlyAddedVal } } as 
        React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>);
    }
  }, [newlyAddedVal]);

  return (
    <form style={styles.form} className='y-scroll'>
      <Card>
        <CardContent sx={{ padding: '0 !important' }}>
          <Grid container spacing={3} sx={styles.fieldsContainer}>
            {
              supplierFields.map((m:Record<string, any>,index:number) => (
                <Accordion
                  key={m.title}
                  sx={styles.accordionGroup}
                  defaultExpanded={index === 0 ? true : false}
                  className="twinfield-accordion"
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={m.title}
                    id={m.title}
                  >
                    <Grid sx={styles.accordionHeading}>
                      {ready && t(m.title)}
                      {
                        sectionHasError(m.fields, errors, touched) && (
                          <Chip
                            color="error"
                            size="small"
                            label="Error"
                            sx={styles.errorChip}
                          />
                        )
                      }
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails sx={{padding: '0 24px 0px 48px'}}>
                    <Grid sx={styles.eachFieldContainer}>
                      {
                        m.fields.map((f:Record<string,any>) => {
                          if (user?.accountingSystem !== 'twinfield' && f.key === 'invoiceLineDescriptionRule') {
                            return null;
                          }

                          switch (f.key) {
                            case 'autoPaymentStatus':
                              return (
                                <Grid item key={f.key} sx={styles.eachField}>
                                  <Typography>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</Typography>
                                  <FormControl variant="outlined" sx={styles.formControl}>
                                    <Select
                                      disabled={isDisabled}
                                      labelId={`${f.key}-label`}
                                      id={f.key}
                                      error={Boolean(touched[f.key] && errors[f.key])}
                                      value={values[f.key]}
                                      onChange={handleChange as any}
                                      name={f.key}
                                      required={f.required}
                                      sx={styles.selectStyle}
                                    >
                                      {
                                        supplierData.apStatuses && supplierData.apStatuses.length > 0
                                          && supplierData.apStatuses.map((s) => (
                                            <MenuItem key={s.code} value={s.code}>
                                              {s.name}
                                            </MenuItem>
                                          ))
                                      }
                                    </Select>
                                    <FormHelperText error>
                                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              );
                            case 'status':
                              return (
                                <Grid item key={f.key} sx={styles.eachField}>
                                  <Typography>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</Typography>
                                  <FormControl variant="outlined" sx={styles.formControl}>
                                    <Select
                                      disabled={isDisabled}
                                      labelId={`${f.key}-label`}
                                      id={f.key}
                                      error={Boolean(touched[f.key] && errors[f.key])}
                                      value={values[f.key]}
                                      onChange={handleChange as any}
                                      name={f.key}
                                      required={f.required}
                                      sx={{ ...styles.capitalizeText, ...styles.selectStyle }}
                                    >
                                      {
                                        supplierData.statuses && supplierData.statuses.length > 0
                                          && supplierData.statuses.map((s) => (
                                            <MenuItem key={s} value={s} sx={styles.capitalizeText}>
                                              {s}
                                            </MenuItem>
                                          ))
                                      }
                                    </Select>
                                    <FormHelperText error>
                                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              );
                            case 'generalLedgerAccLine': case 'generalLedgerAccount': case 'vatGroup': case 'vatGroupLine': case 'costCenter':
                              return (
                                <Grid item sx={styles.eachField} key={f.key}>
                                  <Typography>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</Typography>
                                  <FormControl variant="outlined" sx={styles.formControl}>
                                    <Autocomplete
                                      disabled={isDisabled}
                                      id={`${f.key}Id`}
                                      fullWidth
                                      onChange={(e, v) => handleChangeAutocomplete(e, f.key, v as unknown as Record<string, string | boolean>, handleChange)}
                                      value={getAutoCompleteValue(values[f.key] as string, f.key?.includes('generalLedger') 
                                        ? glAccounts : f.key?.includes('vatGroup') ? vatGroups : costCenters)}
                                      options={f.key?.includes('generalLedger') ? glAccounts : f.key?.includes('vatGroup') ? vatGroups : costCenters}
                                      filterOptions={(options, state) => f.key?.includes('generalLedger')
                                        ? filterAutocompleteOptionsGLA(options, state) : f.key?.includes('vatGroup')
                                          ? filterAutocompleteOptionsVatGroups(options, state) : filterAutocompleteOptionsCostCenter(options, state)}
                                      getOptionLabel={(option:GLAccountType) => (option && option.code ? `${option.code} | ${option.name}` : '')}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          disabled={isDisabled}
                                          variant="outlined"
                                          InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (isDisabled ? <></> :
                                              <>
                                                {showEditIcon(f.key, values) ?
                                                  <InputAdornment
                                                    position="end"
                                                    onClick={() => f.key?.includes('generalLedger')
                                                      ? handleEditGLA(values[f.key] as string) : f.key?.includes('vatGroup')
                                                      ? handleEditVATCode(values[f.key] as string) : handleEditCostCenter(values[f.key] as string)}
                                                    sx={styles.editIconContainer}
                                                  >
                                                    <EditIcon sx={styles.editDimensionIcon} />
                                                  </InputAdornment> : <></>
                                                }
                                                <InputAdornment position="end" sx={styles.plusIconSelect}>
                                                  <PlusIcon
                                                    sx={styles.plusIcon}
                                                    onClick={() => f.key?.includes('generalLedger')
                                                      ? handleAddGLA(f.key) : f.key?.includes('vatGroup')
                                                        ? handleAddVATCode(f.key) : handleAddCostCenter(f.key)}
                                                  />
                                                </InputAdornment>
                                              </>
                                            ),
                                          }}
                                          sx={styles.autoCompleteInput}
                                          onClick={() => {
                                            setTextFieldSelectedKey(f.key);
                                            setTextFieldSelectedValue(values[f.key] as string);
                                          }}
                                          required={f.required}
                                        />
                                      )}
                                    />
                                    <FormHelperText error>
                                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              );
                            case 'project':
                              return (
                                <Grid item key={f.key} sx={styles.eachField}>
                                  <Typography>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</Typography>
                                  <FormControl variant="outlined" sx={styles.formControl}>
                                    <Select
                                      disabled={isDisabled}
                                      labelId={`${f.key}-label`}
                                      id={f.key}
                                      error={Boolean(touched[f.key] && errors[f.key])}
                                      value={values[f.key]}
                                      onChange={handleChange as any}
                                      name={f.key}
                                      required={f.required}
                                      sx={styles.selectStyle}
                                    >
                                      {
                                        projects.map((s) => {
                                          if (s) {
                                            return (
                                              <MenuItem key={s.code} value={s.code}>
                                                {`${s.code}${s.name ? ` | ${s.name}` : ''}`}
                                              </MenuItem>
                                            );
                                          }
                                          return null;
                                        })
                                      }
                                    </Select>
                                    <FormHelperText error>
                                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              );
                            case 'defaultCurrency':
                              return currencies && currencies.length > 0 && (
                                <Grid item key={f.key} sx={styles.eachField}>
                                  <Typography>{ready && `${t(f.label)}${f.required ? '*' : ''}`}</Typography>
                                  <FormControl variant="outlined" sx={styles.formControl}>
                                    <Autocomplete
                                      disabled={isDisabled}
                                      fullWidth
                                      onChange={(e, v) => handleChangeAutocomplete(e, f.key, v as unknown as Record<string, any>, handleChange)}
                                      value={(values[f.key] && currencies.length > 0) ? currencies.find((o) => o.code === values[f.key]) : null}
                                      options={currencies}
                                      getOptionLabel={(option) => `${option.code} | ${option.currency}`}
                                      filterOptions={(options, state) => filterAutocompleteOptionsCurrency(options, state)}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant="outlined"
                                          InputProps={{
                                            ...params.InputProps,
                                          }}
                                          sx={styles.autoCompleteInput}
                                        />
                                      )}
                                    />
                                    <FormHelperText error>
                                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              );
                            case 'bankCountry': case 'country':
                              let isRequired = f.required; // eslint-disable-line
                              if (f.key === 'bankCountry' && values.autoPaymentStatus === supplierData.apStatuses[2].code) {
                                isRequired = false;
                              }

                              return countries && countries.length > 0 && (
                                <Grid item key={f.key} sx={styles.eachField}>
                                  <Typography>{ready && `${t(f.label)}${isRequired ? '*' : ''}`}</Typography>
                                  <FormControl variant="outlined" sx={styles.formControl}>
                                    <Autocomplete
                                      disabled={isDisabled}
                                      fullWidth
                                      onChange={(e, v) => handleChangeAutocomplete(e, f.key, v as unknown as Record<string,boolean | string>, handleChange)}
                                      value={values[f.key] ? countries.find((o) => o.isoCode === values[f.key]) : null}
                                      options={countries}
                                      getOptionLabel={(option) => `${option.isoCode} | ${option.name}`}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant="outlined"
                                          InputProps={{
                                            ...params.InputProps,
                                          }}
                                          sx={styles.autoCompleteInput}
                                          disabled={isDisabled}
                                        />
                                      )}
                                    />
                                    <FormHelperText error>
                                      {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                              );
                            default:
                              return (
                                <Tooltip
                                  key={f.key}
                                  disableHoverListener={!isEdit}
                                  title={(f.key === 'creditorCode' && isEdit) ? t('DIMENSION_CODE_CANNOT_EDIT') : ''}
                                >
                                  <Grid item key={f.key} sx={styles.eachField}>
                                    <Typography>{ready && t(f.label)}</Typography>
                                    <TextField
                                      id={`${f.key}Id`}
                                      error={Boolean(errors[f.key] && touched[f.key])}
                                      fullWidth
                                      helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                      name={f.key}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      required={
                                        f.required
                                        || ((f.key === 'ascription' || f.key === 'bicSwift')
                                          && (
                                            values.autoPaymentStatus === supplierData.apStatuses[0].code
                                            || values.autoPaymentStatus === supplierData.apStatuses[1].code
                                          )
                                        )
                                        || (f.key === 'iban' && values.autoPaymentStatus === supplierData.apStatuses[0].code)
                                        || ((f.key === 'bankName' || f.key === 'bankCity' || f.key === 'bankStreet'
                                            || f.key === 'bankHouseNumber' || f.key === 'city' || f.key === 'address1')
                                          && values.autoPaymentStatus === supplierData.apStatuses[1].code
                                        )
                                      }
                                      value={values[f.key]}
                                      variant="outlined"
                                      disabled={isDisabled ? true : (isEdit && f.key === 'creditorCode')}
                                      sx={styles.inputField}
                                      onClick={() => { setTextFieldSelectedKey(f.key); setTextFieldSelectedValue(values[f.key] as string); }}
                                    />
                                  </Grid>
                                </Tooltip>
                              );
                          }
                        })
                      }
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))
            }
            <Grid>
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>
                    {errors.submit}
                  </FormHelperText>
                </Box>
              )}
              {user?.userRole.toLowerCase() !== userRoles.annotator && (
                isDisabled ? <></> : 
                <Box m={0} display="flex" gap="10px" justifyContent="flex-end" p="0 16px 20px">
                  <Button
                    variant="text"
                    onClick={()=>handleEditSupplierClose()}
                    sx={{...styles.yearField,textTransform: 'none'}}
                    type="button"
                  >
                    {ready && t('SUPPLIERS_CANCEL')}
                  </Button>
                  <Button
                    sx={{ backgroundColor: '#3E8AFF',textTransform: 'none' }}
                    variant="contained"
                    type="button"
                    disabled={isSubmitting || !isActionPermitted(permissions.dimensionEdit, user?.permissions)}
                    onClick={()=>handleSubmit()}
                  >
                    {ready && t(isEdit ? 'SUPPLIERS_SAVE_CHANGES' : 'DIMENSION_ADD_CLIENT')}
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default EditSupplierTwinfield;

const styles = {
  form: {
    maxHeight: 'calc(94dvh - 126px)',
    overflow: 'clip auto',
  },
  fieldsContainer: {
    display: 'flex',
    paddingTop: '25px',
    flexDirection: 'column',
    gap: '10px',
    marginLeft: '0',
    width: '100%'
  },
  snackAction: {
    color: 'white'
  },
  expiryRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  monthField: {
    marginRight: '16px'
  },
  yearField: {
    color: theme.palette.secondary.main
  },
  errorText: {
    color: '#f44336'
  },
  accordionGroup: {
    width: '100%',
    backgroundColor: 'tranparent',
    boxShadow: 'none',
    borderTop: 'none',
    borderBottom: '1px solid #E0E0E0',
    margin: '0px !important',
    '&::before': {
      display: 'none',
      opacity: '0'
    },
  },
  accordionHeading: {
    color: '#4C4E64',
    fontWeight: '500',
    fontSize: '14px',
  },
  eachFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  eachField: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '5px 0',
    width: '100%',
    flex: 1,
    '& > p': {
      fontSize: '13px',
      color: '#4C4E64DE',
    }
  },
  formControl: {
    width: '300px',
    flexShrink: '0'
  },
  formControlRoot: {
    backgroundColor: '#ffffff'
  },
  autoCompleteInput: {
    minWidth: '300px',
    maxWidth: '300px',
    color: '#4C4E64 !important',
    fontSize: '13px',
    lineHeight: '20px',
    backgroundColor: '#ffffff',
    '& .MuiAutocomplete-input': {
      color: '#4C4E64 !important',
    },
    '& .MuiOutlinedInput-root': {
      padding: '0px 2px 0px 0px !important',
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: `2px solid #C2CFE099`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid #C2CFE099`,
      }
    },
  },
  selectStyle: {
    minWidth: '300px',
    maxWidth: '300px',
    color: '#4C4E64 !important',
    fontSize: '13px',
    lineHeight: '20px',
    backgroundColor: '#ffffff',
    '&.MuiAutocomplete-input': {
      color: '#4C4E64 !important',
    },
    '&.MuiOutlinedInput-root': {
      padding: '0px 2px 0px 0px !important',
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: `2px solid #C2CFE099`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid #C2CFE099`,
      }
    },
  },
  errorChip: {
    marginLeft: '16px',
    fontSize: '12px',
    color: '#ffffff',
    backgroundColor: '#f44336'
  },
  inputField: {
    minWidth: '300px',
    maxWidth: '300px',
    '& .MuiOutlinedInput-input': {
      fontSize: '13px !important',
      lineHeight: '32px !important',
      color: '#4C4E64 !important',
      fontWeight: '400',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: `2px solid #C2CFE099`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid #C2CFE099`,
      },
    },
  },
  capitalizeText: {
    textTransform: 'capitalize'
  },
  plusIcon: {
    height: '24px',
    width: '24px',
    color: theme.palette.secondary.main,
    cursor: 'pointer'
  },
  plusIconSelect: {
    height: '24px',
    width: '24px',
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    marginRight: '5px',
  },
  editDimensionIcon: {
    fontSize: '18px',
    color: '#757575',
    border: '1px solid #334D6E',
    borderRadius: '3px',
    cursor: 'default'
  },
  editIconContainer: {
    marginRight: '-5px'
  },
  manageFieldsBtn: {
    height: '36px',
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    color: theme.palette.text.primary,
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingLeft: '16px',
    paddingRight: '16px',
    border: '1px rgba(0, 0, 0, 0.23) solid',
    borderRadius: '8px',
    fontSize: '12px',
    marginRight: '16px',
  },
  manageProductsBtn: {
    height: '36px',
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    color: theme.palette.text.primary,
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingLeft: '16px',
    paddingRight: '16px',
    border: '1px rgba(0, 0, 0, 0.23) solid',
    borderRadius: '8px',
    fontSize: '12px',
  },
  btnIcon: {
    height: '15px',
    width: '15px',
    marginRight: '4px',
    color: '#bebebe',
  },
  productBtnIcon: {
    height: '15px',
    width: '15px',
    marginRight: '4px',
    color: '#000',
  },
  cardContent: {
    padding: '16px 0px 0px 0px !important'
  },
  checkBox: {
    color: '#A0AEC1',
    paddingBottom: '0px',
    paddingTop: '0px'
  },
  checkboxContainer: {
    marginRight: '8px'
  },
  checkBoxLabel: {
    fontSize: '13px',
    color: theme.palette.text.primary
  },
  skipReviewContainer: {
    padding: '0px !important'
  }
};


