import {
  useEffect, useState, useRef
} from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useSelector } from 'react-redux';

import {
  Button, Checkbox, FormControlLabel, Grid, IconButton, Paper, SvgIcon, Table, TableBody, TableCell,
  TableHead, TableRow, TextField, Tooltip, Typography, Autocomplete
} from '@mui/material';
import {
  AddCircle as PlusCircleIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Reorder as ReorderIcon,
  SettingsOutlined as SettingsIcon,
  Undo as ResetIcon,
} from '@mui/icons-material'

import { useAuth } from 'src/hooks/useAuth';
import ManageFields from 'src/shared/components/ManageFields/ManageFields';
import EmptyText from 'src/shared/components/EmptyText/EmptyText';
import { isShowManageFields } from 'src/utils/helpers';
import {
  amountRelatedFields, lineColumnsReprocess, userRoles,
} from 'src/config';
import styles from './style';

const DisplayLines = ({
  t,
  ready,
  preferredFields,
  getLinesDisplayFields,
  sendLines,
  handleSendLinesChange,
  onAddLine,
  details,
  adjustLinesOrder,
  getTFLabel,
  glAccounts,
  vatGroups,
  costCenters,
  projects,
  onEditLine,
  onDeleteLine,
  includeLinesInExport,
  handleIncludeLinesInExportChange,
  onUpdateLine,
  parameterCompany,
  onAddLineConfirm,
  textFieldSelectedKey,
  onFocusField,
  supplier,
  setTextFieldSelectedKey,
  reprocessingLines,
  sendLinesForReprocessing,
  isLinesReprocessingBtnDisabled,
  isResetLineBoundariesBtnDisabled,
  resetLineBoundaries,
  decimalSeparator,
}) => {
  const dimensionsLists = {
    lineGLAccount: glAccounts,
    lineVatGroup: vatGroups,
    lineCostCenter: costCenters,
    lineProject: projects
  };

  const auth = useAuth();
  const {user} = auth;
  
  const docType = useSelector((state) => state.fields.docType);

  const amountRegex = new RegExp(`^-?\\d*[${decimalSeparator === '.' ? '.' : `.${decimalSeparator}`}]?\\d+$`);
  const noWhiteSpace = /.*\S.*$/;
  const leadingWhiteSpace = /^\s+.+$/;
  
  const [showManageFields, setShowManageFields] = useState(false);
  const [lineDetails, setLineDetails] = useState(details.lines || []);
  const [supplierUnitMeasurementVals, setSupplierUnitMeasurementVals] = useState(supplier?.inventories?.map((p) => p.unitMeasurement) || []);
  const [unitMeasurementVals, setUnitMeasurementVals] = useState(supplier?.inventories?.map((p) => p.unitMeasurement) || []);

  const tableRefs = {
    table1Ref: useRef(null),
    table2Ref: useRef(null),
    table3Ref: useRef(null),
    table2HeaderRef: useRef(null),
  };

  const handleScroll = (sourceTable, targetTable, targetTable2) => {
    const scrollPositionY = tableRefs[sourceTable].current.scrollTop;
    tableRefs[targetTable].current.scrollTop = scrollPositionY;
    tableRefs[targetTable2].current.scrollTop = scrollPositionY;
  };

  const handleCloseManageFields = () => {
    setShowManageFields(false);
  };

  useEffect(() => setLineDetails(details.lines), [details]);

  useEffect(() => {
    setSupplierUnitMeasurementVals([...new Set(supplier?.inventories?.filter((p) => p.unitMeasurement).map((p) => p.unitMeasurement))] || []);
    setUnitMeasurementVals([...new Set(supplier?.inventories?.filter((p) => p.unitMeasurement).map((p) => p.unitMeasurement))] || []);
  }, [supplier]);

  const handleChange = (value, line, prop) => {
    const val = value;
    if (leadingWhiteSpace.test(val)) {
      val.trimStart();
    }
    const newLineDetails = lineDetails.map((l) => {
      if (l.line === line) {
        return {
          ...l,
          [prop]: val
        };
      }
      return l;
    });
    setLineDetails(newLineDetails);
  };

  const handleOnBlur = (value, line, prop) => {
    let updatedLine;
    const val = value;
    if (leadingWhiteSpace.test(val)) {
      val.trimStart();
    }
    let product;
    if (supplier) {
      const supplierProducts = supplier?.inventories || [];
      if (prop === 'lineArticle' && supplierProducts.some((p) => p.article === val)) {
        product = supplierProducts.find((p) => p.article === val);
      }
      if (prop === 'lineArticleClient' && supplierProducts.some((p) => p.articleClient === val)) {
        product = supplierProducts.find((p) => p.articleClient === val);
      }
    }
    lineDetails.forEach((l) => {
      if (l.line === line) {
        updatedLine = {
          ...l,
          [prop]: val
        };
        if ((prop === 'lineArticle' || prop === 'lineArticleClient') && product) {
          updatedLine.lineArticle = product.article || '';
          updatedLine.lineArticleClient = product.articleClient || '';
          updatedLine.lineUnitMeasurement = product.unitMeasurement || '';
        }
      }
    });
    if (updatedLine.lineExtraCost === undefined) {
      updatedLine.lineExtraCost = false;
    }
    onUpdateLine(updatedLine);
  };

  const handleOnBlurUnitMeasurementText = (e, line, prop) => {
    const { value } = e.target;
    if (!supplierUnitMeasurementVals.includes(value)) {
      setUnitMeasurementVals([...supplierUnitMeasurementVals, value]);
    } else {
      setUnitMeasurementVals(supplierUnitMeasurementVals);
    }
    handleOnBlur(value, line, prop);
  };

  const handleChangeAutocomplete = (e, line, prop, values) => {
    if (values && values.code) {
      handleOnBlur(values.code, line, prop);
      return;
    }
    handleOnBlur(values, line, prop);
  };

  const validation = (value, prop) => {
    if (!value) {
      return true;
    }
    if (amountRelatedFields.includes(prop) && !amountRegex.test(value)) {
      return false;
    }
    if (!noWhiteSpace.test(value)) {
      return false;
    }
    return true;
  };

  const getCellClassName = (key) => {
    const fieldsLen = preferredFields.filter((field) => field.isActive && field.isAvailable).length;
    if (fieldsLen === 4) {
      if (key === 'lineDescription' || key === 'lineGLAccount') {
        return 'table2Cell4fieldsLong';
      }
      if (key === 'lineExtraCost') {
        return 'table2Cell4fieldsVeryShort';
      }
      return 'table2Cell4fieldsShort';
    }
    if (fieldsLen === 5) {
      if (key === 'lineExtraCost') {
        return 'table2CellVeryShort';
      }
      if (key === 'lineDescription' || key === 'lineGLAccount') {
        return 'table2Cell5fieldsLong';
      }
      return 'table2Cell5fieldsShort';
    }
    if (fieldsLen === 6) {
      if (key === 'lineExtraCost') {
        return 'table2CellVeryShort';
      }
      if (key === 'lineDescription' || key === 'lineGLAccount') {
        return 'table2Cell6fieldsLong';
      }
      return 'table2Cell6fieldsShort';
    }
    if (fieldsLen === 7) {
      if (key === 'lineExtraCost') {
        return 'table2CellVeryShort';
      }
      if (key === 'lineDescription' || key === 'lineGLAccount') {
        return 'table2Cell7fieldsLong';
      }
      return 'table2Cell7fieldsShort';
    }
    if (key === 'lineDescription' || key === 'lineGLAccount') {
      return 'table2CellLong';
    }
    if (key === 'lineExtraCost') {
      return 'table2CellVeryShort';
    }
    return 'table2CellShort';
  };

  const getIsSelectedClassname = (key) => (textFieldSelectedKey === `invoiceLineColumn_${key}` ? styles.textFieldHighlighted : '');

  const cursorPointerClass = (key) => (lineColumnsReprocess.includes(`invoiceLineColumn_${key}`) ? styles.cursorPointer : '');

  const tableHead1 = (
    <TableRow>
      <TableCell sx={styles.draggableIconHeader} />
      <TableCell align="center" size="medium" sx={styles.columnNumber}>
        {ready && t('INVOICE_EDIT_LINE')}
      </TableCell>
    </TableRow>
  );

  const tableHead2 = (
    <TableRow>
      {preferredFields.filter((field) => field.isActive && field.isAvailable).map((field) => (
        <TableCell
          id={`invoiceLineColumn_${field.key}`}
          key={field.key}
          sx={{
            ...getCellClassName(field.key),
            ...getIsSelectedClassname(field.key),
            ...cursorPointerClass(field.key),
            ...styles.headerHeight
          }}
          onClick={(e) => onFocusField(e, `invoiceLineColumn_${field.key}`, '')}
        >
          {ready && t(field.label)}
          {lineColumnsReprocess.includes(`invoiceLineColumn_${field.key}`) && (
            <Tooltip title={t('LINE_BOUNDARIES_RESET_TOOLTIP')}>
              <IconButton disabled={isResetLineBoundariesBtnDisabled(field.key)} onClick={() => resetLineBoundaries(field.key)}>
                <ResetIcon />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      ))}
    </TableRow>
  );

  const tableHead3 = (
    <TableRow>
      <TableCell sx={styles.draggableIconHeader}>
        <Grid style={styles.actionBtnContainer}>
          <IconButton
            onClick={() => onAddLineConfirm({ line: lineDetails?.length + 1, lineDescription: '', lineAmount: '' })}
            sx={styles.iconButton}
          >
            <PlusCircleIcon style={styles.addIcon} />
          </IconButton>
          {isShowManageFields(user, 'applyParentFieldsLinesDisplay') && (
            <IconButton onClick={() => setShowManageFields(true)} style={styles.iconButton}>
              <SettingsIcon style={styles.btnIcon} />
            </IconButton>
          )}
        </Grid>
      </TableCell>
    </TableRow>
  );

  const tableRow1 = (draggableProvided, line) => (
    <TableRow
      key={line.line}
      ref={draggableProvided.innerRef}
      {...draggableProvided.draggableProps}
    >
      <TableCell align="left" style={styles.draggableIcon}>
        <div {...draggableProvided.dragHandleProps}>
          <ReorderIcon style={styles.reorderIcon} />
        </div>
      </TableCell>
      <TableCell size="medium" style={styles.colHeight} className="edit-invoice-line-num">
        <TextField
          disabled
          value={line?.line || ''}
          variant="outlined"
          style={styles.root}
        />
      </TableCell>
    </TableRow>
  );

  const tableRow2 = (line) => (
    <TableRow key={line.line}>
      {preferredFields.filter((field) => field.isActive && field.isAvailable).map((field) => {
        if (field.key === 'lineGLAccount' || field.key === 'lineVatGroup' || field.key === 'lineCostCenter' || field.key === 'lineProject') {
          return (
            <TableCell key={field.key} sx={styles[getCellClassName(field.key)]} className="edit-invoice-line">
              <Autocomplete
                id={`invoiceLine_${line.line}_${field.key}`}
                fullWidth
                onFocus={(e) => onFocusField(e, `invoiceLine_${line.line}_${field.key}`, 'text')}
                onChange={(e, v) => handleChangeAutocomplete(e, line.line, field.key, v)}
                value={getTFLabel(dimensionsLists[field.key], line[field.key])}
                options={dimensionsLists[field.key]}
                getOptionLabel={(option) => (option && option.code ? `${option.code}${option.name ? ` | ${option.name}` : ''}` : option)}
                getOptionSelected={(option) => option.code === (line[field.key] ? line[field.key] : ' ')}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    variant="outlined"
                    style={
                      !validation(line[field.key], field.key)
                        ? styles.errorSelectStyle
                        : textFieldSelectedKey === `invoiceLine_${line.line}_${field.key}`
                          ? styles.selectFieldHighlighted
                          : styles.selectStyle
                    }
                    InputProps={{
                      ...params.InputProps,
                      style: styles.selectInputStyle,
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': styles.inputRoot,
                    }}
                  />
                )}
              />
            </TableCell>
          );
        }
        if (field.key === 'lineExtraCost') {
          return (
            <TableCell key={field.key} sx={styles[getCellClassName(field.key)]} className="edit-invoice-line">
              <Checkbox
                checked={line?.lineExtraCost || line.lineExtraCost === 'INVOICE_LINE_EDIT_YES'}
                variant="outlined"
                style={styles.checkBox}
                onChange={(e) => handleChange(e.target.checked, line.line, 'lineExtraCost')}
                onFocus={() => setTextFieldSelectedKey('')}
              />
            </TableCell>
          );
        }
        if (field.key === 'lineUnitMeasurement') {
          return (
            <TableCell key={field.key} sx={styles[getCellClassName(field.key)]} className="edit-invoice-line">
              <Autocomplete
                id={`invoiceLine_${line.line}_${field.key}`}
                fullWidth
                onFocus={(e) => onFocusField(e, `invoiceLine_${line.line}_${field.key}`, 'text')}
                onChange={(e, v) => handleChangeAutocomplete(e, line.line, field.key, v)}
                value={line[field.key] || ''}
                options={unitMeasurementVals}
                getOptionLabel={(option) => option}
                getOptionSelected={(option) => option === (line[field.key] ? line[field.key] : ' ')}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    variant="outlined"
                    style={
                        !validation(line[field.key], field.key)
                          ? styles.errorSelectStyle
                          : textFieldSelectedKey === `invoiceLine_${line.line}_${field.key}`
                            ? styles.selectFieldHighlighted
                            : styles.selectStyle
                      }
                    InputProps={{
                      ...params.InputProps,
                      style: styles.selectInputStyle,
                    }}
                    onBlur={(e) => handleOnBlurUnitMeasurementText(e, line.line, field.key)}
                    sx={{
                      '& .MuiOutlinedInput-root': styles.inputRoot,
                    }}
                  />
                )}
              />
            </TableCell>
          );
        }
        return (
          <TableCell key={field.key} sx={styles[getCellClassName(field.key)]} className="edit-invoice-line">
            <TextField
              id={`invoiceLine_${line.line}_${field.key}`}
              fullWidth
              value={line[field.key] || ''}
              variant="outlined"
              style={
                !validation(line[field.key], field.key)
                  ? styles.errorState
                  : textFieldSelectedKey === `invoiceLine_${line.line}_${field.key}`
                    ? styles.textFieldHighlited
                    : styles.inputStyle
              }
              sx={{
                '& .MuiOutlinedInput-root': styles.inputRoot,
              }}
              onChange={(e) => handleChange(e.target.value, line.line, field.key)}
              onBlur={(e) => handleOnBlur(e.target.value, line.line, field.key)}
              onFocus={(e) => onFocusField(e, `invoiceLine_${line.line}_${field.key}`, field.dataType)}
            />
          </TableCell>
        );
      })}
    </TableRow>
  );

  const tableRow3 = (line) => (
    <TableRow key={line.line}>
      <TableCell style={styles.draggableIcon} className="edit-invoice-line">
        <Grid style={styles.actionBtnContainer}>
          <Tooltip title={t('INVOICE_EDIT_FORM_EDIT_INVOICE_LINE_TOOLTIP')}>
            <IconButton onClick={() => onEditLine(line)}>
              <SvgIcon style={{ display: 'flex' }}>
                <EditIcon style={styles.editIcon} />
              </SvgIcon>
            </IconButton>
          </Tooltip>
          <Tooltip title={t('INVOICE_EDIT_FORM_DELETE_INVOICE_LINE_TOOLTIP')}>
            <IconButton onClick={() => onDeleteLine(line)}>
              <SvgIcon style={{ display: 'flex' }}>
                <DeleteIcon style={styles.deleteIcon} />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </Grid>
      </TableCell>
    </TableRow>
  );

  return (
    <>
      <Grid item xs={12} style={styles.linesContainer}>
        <Grid style={styles.lineHeader}>
          <Grid style={styles.lineTitleContainer}>
            <Typography style={styles.lineTitle}>
              {ready && t('INVOICE_EDIT_INVOICE_LINES')}
            </Typography>
            {user?.userRole?.toLowerCase() === userRoles.annotator && (
              <FormControlLabel
                value="top"
                control={(
                  <Checkbox
                    style={styles.checkBox}
                    checked={sendLines}
                    onChange={handleSendLinesChange}
                  />
                )}
                label={ready && t('INVOICE_EDIT_LINE_SEND_FOR_TRAINING')}
                labelPlacement="end"
                style={styles.checkBoxContainer}
                sx={{
                  '& .MuiFormControlLabel-label': styles.checkBoxLabelSmall,
                }}
              />
            )}
            {user?.customisations?.includes('enableLinesReprocessing') && (
              <Button
                color="secondary"
                variant="outlined"
                sx={styles.reprocessingBtn}
                disabled={reprocessingLines || isLinesReprocessingBtnDisabled()}
                onClick={sendLinesForReprocessing}
              >
                {ready && t('INVOICE_EDIT_FORM_SEND_FOR_REPROCESSING_LINES')}
              </Button>
            )}
          </Grid>
          <Tooltip title={t('INVOICE_EDIT_ADD_INVOICE_LINE_TOOLTIP')}>
            <Button
              variant="contained"
              style={styles.action}
              onClick={onAddLine}
            >
              <SvgIcon
                fontSize="small"
                style={styles.actionIcon}
              >
                <PlusCircleIcon />
              </SvgIcon>
              {ready && t('INVOICE_EDIT_ADD_INVOICE_LINE')}
            </Button>
          </Tooltip>
        </Grid>
        <Grid style={styles.linesInExtractDataCheckBoxContainer}>
          <FormControlLabel
            value="top"
            control={(
              <Checkbox
                style={styles.checkBox}
                checked={includeLinesInExport}
                onChange={handleIncludeLinesInExportChange}
              />
            )}
            label={ready && t('INVOICE_EDIT_LINE_INCLUDE_IN_EXPORT')}
            labelPlacement="end"
            style={styles.checkBoxContainer}
            sx={{
              '& .MuiFormControlLabel-label': styles.checkBoxLabelSmall,
            }}
          />
        </Grid>
        {
          lineDetails && lineDetails.length > 0 ? (
            <Grid style={styles.tablesContainer}>
              <Paper
                ref={tableRefs.table1Ref}
                style={styles.table1}
                className="hide-scroll"
                onScroll={() => handleScroll('table1Ref', 'table2Ref', 'table3Ref')}
              >
                <Table size="small" stickyHeader aria-label="sticky table">
                  <TableHead>
                    {tableHead1}
                  </TableHead>
                  <DragDropContext onDragEnd={(e) => adjustLinesOrder(e?.source?.index, e?.destination?.index)}>
                    <Droppable droppableId="droppable" direction="vertical">
                      {
                      (droppableProvided) => (
                        <TableBody
                          ref={droppableProvided.innerRef}
                          {...droppableProvided.droppableProps}
                        >
                          {
                            lineDetails.sort((a, b) => (a.line > b.line ? 1 : -1)).map((line) => (
                              <Draggable
                                key={line.line}
                                draggableId={line.line?.toString()}
                                index={line.line}
                              >
                                {(draggableProvided) => (tableRow1(draggableProvided, line))}
                              </Draggable>
                            ))
                          }
                          {droppableProvided.placeholder}
                        </TableBody>
                      )
                    }
                    </Droppable>
                  </DragDropContext>
                </Table>
              </Paper>
              <Paper
                ref={tableRefs.table2Ref}
                style={styles.table2}
                onScroll={() => handleScroll('table2Ref', 'table1Ref', 'table3Ref')}
              >
                <Table size="small" stickyHeader aria-label="sticky table">
                  <TableHead>
                    {tableHead2}
                  </TableHead>
                  <TableBody>
                    {
                      lineDetails.sort((a, b) => (a.line > b.line ? 1 : -1)).map((line) => (
                        tableRow2(line)
                      ))
                    }
                  </TableBody>
                </Table>
              </Paper>
              <Paper
                ref={tableRefs.table3Ref}
                className="hide-scroll"
                style={styles.table3}
                onScroll={() => handleScroll('table3Ref', 'table1Ref', 'table2Ref')}
              >
                <Table size="small" stickyHeader aria-label="sticky table">
                  <TableHead>
                    {tableHead3}
                  </TableHead>
                  <TableBody>
                    {
                      lineDetails.sort((a, b) => (a.line > b.line ? 1 : -1)).map((line) => (
                        tableRow3(line)
                      ))
                    }
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          ) : <EmptyText str="INVOICE_EDIT_FORM_NO_INVOICE_LINES" />
        }
      </Grid>
      <ManageFields
        open={showManageFields}
        documentType={docType}
        section="lines"
        fields={preferredFields}
        fetchFields={getLinesDisplayFields}
        closeModal={handleCloseManageFields}
        parameterCompany={parameterCompany}
      />
    </>
  );
};

export default DisplayLines;
