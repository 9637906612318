import React, { useContext } from 'react';
import { Formik, FormikErrors } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  Accordion, AccordionDetails, AccordionSummary, Box, Button, Chip, FormHelperText, Grid,
  TextField, Tooltip, Typography
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import { axiosHeaders, getLocalisedErrorString, isActionPermitted, permissions } from 'src/utils/helpers';
import authService from 'src/utils/authService';
import { costCenterFields } from 'src/config';
import ConfigContext from 'src/contexts/ConfigContext';
import { CostCenterType } from 'src/types';
import { sectionHasError } from 'src/dimensions-new/utils';
import theme from 'src/theme';

type PropsType = {
  costCenter: CostCenterType | null;
  getAllCostCenters: ( _page?: number, _limit?: number, _search?: string, _loadFullScreen?: boolean) => void;
  handleClose: () => void;
  onSuccess: (_id: string) => void;
  setLoading: (_show: boolean) => void;
  isEdit: boolean;
  isDisabled?: boolean;
}

type FormPropType = {
  errors: Record<string, any>;
  handleBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleSubmit: () => void;
  isSubmitting: boolean;
  setErrors: (_errors: FormikErrors<Record<string, string>>) => void;
  touched: Record<string, any>;
  values: Record<string, any>;
}

const CostCenterTwinfield = (props: PropsType) => {
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    costCenter,
    getAllCostCenters,
    handleClose,
    onSuccess,
    setLoading,
    isEdit,
    isDisabled,
  } = props;

  const { LOGIN_PANEL_URL, API } = useContext(ConfigContext);
 
  const auth = useAuth();
  const {user} = auth;

  if(isEdit === true && (!costCenter?.name || !costCenter?.code )){
    return <></>
  }
  
  return (
    <Formik
      initialValues={{
        code: costCenter?.code || '',
        name: costCenter?.name || '',
      }}
      validationSchema={Yup.object().shape({
        code: Yup.string()
          .max(255),
        name: Yup.string()
          .max(80, 'COST_CENTER_NAME_MAX_VALIDATION')
          .required('COST_CENTER_NAME_REQUIRED')
      })}
      onSubmit={async (values, {
        resetForm,
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        try {
          if (!authService.validateToken()) {
            enqueueSnackbar(ready && t('PROCYS_LOGIN_SESSION_EXPIRED'), {
              variant: 'error',
            });
            setTimeout(() => {
              authService.logout(LOGIN_PANEL_URL);
            }, 2000);
            return;
          }
          setLoading(true);
          const body = {
            code: values.code,
            name: values.name,
          };

          const url = `${API.costCentersByCompany}/${encodeURIComponent(user?.companyID as string)}`;
          let response = null;
          let snackText = '';

          if (costCenter && costCenter.name) {
            response = await Axios.put(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
            snackText = 'COST_CENTERS_UPDATE_SUCCESS';
          } else {
            response = await Axios.post(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
            snackText = 'COST_CENTERS_ADD_SUCCESS';
          }
          if (response?.data?.success) {
            setLoading(false);
            resetForm();
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar(ready && t(snackText), {
              variant: 'success',
              style: { maxWidth: 400 }
            });
            getAllCostCenters();
            handleClose();
            if (onSuccess) {
              onSuccess(response.data.id);
            }
          }
        } catch (error: any) {
          setLoading(false);
          setStatus({ success: false });
          setSubmitting(false);
          if (error.response.data.i18n.indexOf('__TFMSG__') > 0) {
            const errParts = error.response.data.i18n.split('__TFMSG__');
            setErrors({
              [errParts[0]]: errParts[1]
            });
          } else {
            setErrors({
              submit:getLocalisedErrorString(error.response.data.i18n, t)
            } as any);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setErrors,
        touched,
        values
      }:FormPropType) => (
        <form style={styles.form} className="y-scroll">
          <Grid container sx={styles.fieldsContainer}>
            {
              costCenterFields.map((m:Record<string,any>,index:number) => (
                <Accordion
                  key={m.title}
                  sx={styles.accordionGroup}
                  defaultExpanded={index === 0 ? true : false}
                  className='twinfield-accordion'
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={m.title}
                    id={m.title}
                  >
                    <Grid sx={styles.accordionHeading}>
                      {ready && t(m.title)}
                      {sectionHasError(m.fields, errors, touched) && (
                        <Chip
                          color="error"
                          size="small"
                          label="Error"
                          sx={styles.errorChip}
                        />
                      )}
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails sx={{padding: '0 24px 16px 48px'}}>
                    <Grid sx={styles.eachFieldContainer}>
                      {m.fields.map((f: Record<string,any>) => {
                        switch (f.key) {
                          default:
                            return (
                              <Tooltip key={f.key} title={isEdit && f.key === 'code' ? t('DIMENSION_CODE_CANNOT_EDIT') : ''}>
                                <Grid item key={f.key} sx={styles.eachField}>
                                  <Typography>{ready && t(f.label)}</Typography>
                                  <TextField
                                    error={Boolean(touched[f.key] && errors[f.key])}
                                    fullWidth
                                    helperText={touched[f.key] && (ready && t(errors[f.key]))}
                                    name={f.key}
                                    onClick={() => setErrors({})}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    required={f.required}
                                    value={values[f.key] && values[f.key] !== 'undefined' ? values[f.key] : ''}
                                    variant="outlined"
                                    sx={styles.inputField}
                                    disabled={isDisabled ? true : isEdit && f.key === 'code'}
                                  />
                                </Grid>
                              </Tooltip>
                            );
                        }
                      })}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))
            }
            <Grid
              item
              xs={12}
            >
              {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>
                  {errors.submit}
                </FormHelperText>
              </Box>
              )}
              {
                isDisabled ? <></> :
                <Box mt={2} display="flex" justifyContent="flex-end" gap="10px" p="0 16px 20px">
                  <Button
                    variant="text"
                    onClick={()=>handleClose()}
                    sx={{...styles.yearField,textTransform: 'none'}}
                  >
                    {ready && t('COST_CENTERS_CANCEL')}
                  </Button>
                  <Button
                    sx={{ backgroundColor: theme.palette.secondary.main,textTransform: 'none' }}
                    variant="contained"
                    disabled={isSubmitting || !isActionPermitted(permissions.dimensionEdit, user?.permissions)}
                    onClick={()=>handleSubmit()}
                  >
                    {ready && t(isEdit ?'COST_CENTERS_SAVE_CHANGES': 'COST_CENTERS_ADD_NEW')}
                  </Button>
                </Box>
              }
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};


const styles = {
  form: {
    backgroundColor: '',
    maxHeight: 'calc(94dvh - 76px)',
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  eachFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    padding: '0px',
  },
  eachField: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '5px 0',
    width: '100%',
    flex:1,
    '& > p': {
      fontSize: '13px',
      color: '#4C4E64DE',
    }
  },
  yearField: {
    color: theme.palette.secondary.main
  },
  accordionGroup: {
    width: '100%',
    backgroundColor: 'tranparent',
    boxShadow: 'none',
    borderTop: 'none',
    margin: '0 !important',
    borderBottom: '1px solid #E0E0E0',
    '&::before': {
      display: 'none',
      opacity: '0'
    },
  },
  accordionHeading: {
    color: '#4C4E64',
    fontWeight: '500',
    fontSize: '14px',
  },
  errorChip: {
    marginLeft: '16px',
    fontSize: '12px',
    color: '#ffffff',
    backgroundColor: '#f44336'
  },
  inputField: {
    width: '300px',
    '& .MuiOutlinedInput-input': {
      fontSize: '13px !important',
      lineHeight: '32px !important',
      color: '#4C4E64 !important',
      fontWeight: '400',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: `2px solid #C2CFE099`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid #C2CFE099`,
      },
    },
  },
};


export default CostCenterTwinfield;
