import theme from "src/themeNew";

const styles = {
  main: {
    padding: '8px 0px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0px 16px 8px 16px',
    height: '42px',
    borderBottom: '1px solid #4C4E641F'
  },
  backIconContainer: {
    padding: '4px 4px 4px 0px',
  },
  backIcon: {
    color: '#6D788D',
    size: '20px',
  },
  headerText: {
    color: '#4C4E64',
    fontSize: '16px',
    fontWeight: '600',
    marginLeft: '8px',
  },
  fieldsListContainer: {
    padding: '0px 16px 8px 16px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  clientFieldsListContainer: {
    padding: '0px 16px 8px 16px',
  },
  fieldRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '4px 0px',
  },
  leftContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dragIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dragIcon: {
    size: '20px',
    color: '#6D788D',
  },
  visibilityIcon: {
    size: '20px',
    color: '#6D788D',
    margin: '0px 8px'
  },
  fieldLabelContainer: {
    display: 'flex'
  },
  fieldLabelActive: {
    color: '#4C4E64DE',
    fontSize: '13px',
    fontWeight: '500',
  },
  fieldLabelInactive: {
    color: '#4C4E6480',
    fontSize: '13px',
    fontWeight: '500',
  },
  dropDownIconActive: {
    color: '#4C4E64DE',
    width: '20px',
    height: '20px',
  },
  dropDownIconInactive: {
    color: '#4C4E6480',
    width: '20px',
    height: '20px',
  },
  rightContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  selectStyle: {
    height: 36,
    width: 120,
    border: '2px solid #C2CFE099',
    borderRadius: '4px',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  },
  selectItem: {
    fontSize: '13px',
    fontWeight: '500',
    color: '#4C4E64',
  },
  deleteBtn: {
    padding: '8px 0px 8px 8px',
    // @TODO: To be displayed with auto-ML funcitonality
    display: 'none'
  },
  deleteIcon: {
    size: '20px',
    color: '#6D788D',
  },
  addBtnContainer: {
    padding: '16px',
    height: '64px',
  },
  addFieldBtn: {
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: '500',
    color: '#4C4E64',
    textTransform: 'none',
    backgroundColor: '#C2CFE099',
    padding: '4px 8px 4px 8px',
    borderRadius: '4px',
    height: '32px',
    width: '150px',
    '&:hover': {
      backgroundColor: '#C2CFE0',
    },
  },
  addIcon: {
    size: '14px',
    height: '20px',
    width: '20px',
    color: '#6D788D',
    marginRight: '8px',
  },
  actionBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '16px',
    height: '68px',
    boxShadow: '0px -4px 8px -4px #4C4E646B'
  },
  cancelBtn: {
    fontSize: '13px',
    fontWeight: '500',
    color: '#4C4E64',
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    padding: '4px 16px',
    border: '1px solid #4C4E6433',
    boxShadow: '0px 4px 8px -4px #4C4E646B',
    borderRadius: '4px',
    height: '36px',
    marginRight: '8px',
  },
  saveBtn: {
    fontSize: '13px',
    fontWeight: '500',
    color: '#FFFFFF',
    textTransform: 'none',
    backgroundColor: '#3E8AFF',
    padding: '4px 16px',
    border: '1px solid #4C4E6433',
    boxShadow: '0px 4px 8px -4px #4C4E646B',
    borderRadius: '4px',
    height: '36px',
    '&:hover': {
      backgroundColor: '#3e8affd9',
    },
  },
  saveIcon: {
    size: '14px',
    height: '20px',
    width: '20px',
    color: '#FFFFFF',
    margin:'0px 0px 0px 8px'
  },
  switchBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '16px',
    borderTop: '1px solid #4C4E641F',
    height: '40px',
  },
  formControlLabel: {
    marginRight: 0
  },
  searchBox: {
    backgroundColor: theme.palette.background.white,
    borderWidth: '0px',
    borderRadius: '6px',
    margin: '0px',
    padding: '8px',
    minWidth: '100px'
  },
  searchInput: {
    padding: '4px !important',
    border: `1px solid ${theme.palette.border.input} !important`,
    '&::placeholder': {
      color: theme.palette.text.disabled,
      fontSize: '15px',
      lineHeight: '24px',
      fontWeight: '400',
    }
  },
  searchBarInput: {
    padding: '8px 16px !important',
  },
};

export default styles;
