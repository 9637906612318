import {
  useState, useContext
} from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import {
  Backdrop, Button, Checkbox, CircularProgress, Container, FormControlLabel, FormGroup, Grid, TextField, Tooltip, Typography
} from '@mui/material';

import Page from 'src/shared/screens/Page';
import ConfigContext from 'src/contexts/ConfigContext';
import { axiosHeadersFormData } from 'src/utils/helpers';
import theme from "src/theme";

const SendEmailForOP = () => {
  const {
    API
  } = useContext(ConfigContext);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [dataFile, setDataFile] = useState(null);
  const [template, setTemplate] = useState('');
  const [subject, setSubject] = useState('');
  const [fromEmail, setFromEmail] = useState('"Openprovider" no-reply@sender.openprovider.com');
  const [sendAcknowledgement, setSendAcknowledgement] = useState(false);
  const [senders, setSenders] = useState('');

  const sendRequest = async () => {
    setLoading(true);

    const formData = new FormData();
    formData.append('datafile', dataFile);
    formData.append('template', template);
    formData.append('subject', subject);
    formData.append('fromEmail', fromEmail);
    formData.append('sendAcknowledgement', sendAcknowledgement);
    formData.append('senders', senders);

    try {
      const response = await axios.post(
        API.sendEmailForOP,
        formData,
        axiosHeadersFormData(localStorage.getItem('PROCYS_accessToken'), formData)
      );
      if (response.data.success) {
        enqueueSnackbar('Started to send emails successfully.', {
          variant: 'success',
        });

        setDataFile(null);
        setTemplate('');
        setSubject('');
        setSendAcknowledgement(false);
        setSenders('');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar('Error while sending email: ' + error?.response?.data?.message, {
        variant: 'error',
      });
    }
  };

  const uploadDataFile = (e) => {
    setLoading(true);

    const files = e.target.files;
    if (files.length === 0) {
      enqueueSnackbar('No file selected', {
        variant: 'error',
      });

      setLoading(false);
      return;
    }

    const file = files[0];
    if (!file.name.endsWith('.csv')) {
      enqueueSnackbar('Please select a CSV file', {
        variant: 'error',
      });

      setLoading(false);
      return;
    }

    setDataFile(file);
    setLoading(false);
  };

  return (
    <>
      <Page
        style={styles.root}
        title={'Send Email For OP'}
      >
        <Container style={styles.container}>
          <Grid style={styles.innerContainer}>
            {
              !loading && (
                <Grid>
                  <Grid style={{ marginTop: 100, marginBottom: 30 }}>
                    <Typography variant="h4" sx={{ textAlign: 'center', fontWeight: '500', color: theme.palette.text.primary }}>
                      Send Email For Openprovider
                    </Typography>
                  </Grid>
                  <TextField
                    fullWidth
                    label={'Template'}
                    name="template"
                    onChange={(e) => setTemplate(e.target.value)}
                    required
                    value={template}
                    variant="outlined"
                    sx={styles.fieldContainer}
                  />
                  <TextField
                    fullWidth
                    label={'Subject'}
                    name="subject"
                    onChange={(e) => setSubject(e.target.value)}
                    required
                    value={subject}
                    variant="outlined"
                    sx={styles.fieldContainer}
                  />
                  <TextField
                    fullWidth
                    label={'From Email'}
                    name="fromEmail"
                    onChange={(e) => setFromEmail(e.target.value)}
                    value={fromEmail}
                    variant="outlined"
                    sx={styles.fieldContainer}
                  />
                  <FormGroup sx={styles.fieldContainerFile}>
                    <Typography variant="body2" sx={styles.inputLabel}>
                      Data File (CSV):
                    </Typography>
                    <input
                      accept=".csv"
                      id="dataFile"
                      type="file"
                      onChange={uploadDataFile}
                    />
                  </FormGroup>
                  <FormGroup sx={styles.checkBoxContainer}>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={sendAcknowledgement}
                          sx={styles.checkbox}
                          onChange={(e) => setSendAcknowledgement(e.target.checked)}
                        />
                      )}
                      label={'Send Acknowledgement'}
                      sx={{
                        '& .MuiFormControlLabel-label': styles.checkboxLabel
                      }}
                    />
                  </FormGroup>
                  <Tooltip title={'Please enter COMMA separated email addresses'}>
                    <TextField
                      fullWidth
                      label={'Sender acknowledgement email to'}
                      name="senders"
                      onChange={(e) => setSenders(e.target.value)}
                      value={senders}
                      variant="outlined"
                      sx={styles.fieldContainerBottom}
                    />
                  </Tooltip>

                  <Grid style={{ marginTop: 60, display: 'flex', justifyContent: 'center' }}>
                    <Tooltip
                      title={
                        !dataFile
                          ? 'Please select data file'
                          : !template
                            ? 'Please enter template'
                            : !subject
                              ? 'Please enter subject'
                              : sendAcknowledgement && !senders
                                ? 'Please enter senders'
                                : 'Click here to send email'
                      }
                    >
                      <span>
                        <Button
                          color="primary"
                          variant="contained"
                          disabled={loading || !dataFile || !template || !subject || (sendAcknowledgement && !senders)}
                          onClick={sendRequest}
                        >
                          Send Email
                        </Button>
                      </span>
                    </Tooltip>
                  </Grid>
                </Grid>
              )
            }
          </Grid>
          <Backdrop style={styles.backdrop} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Container>
      </Page>
    </>
  );
};

export default SendEmailForOP;

const styles = {
  root: {
    backgroundColor: 'white',
    minHeight: '100vh',
  },
  container: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  innerContainer: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '60px',
  },
  backdrop: {
    zIndex: 1111,
    color: '#ffffff',
  },
  fieldContainer: {
    marginTop: '25px'
  },
  fieldContainerFile: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '25px'
  },
  fieldContainerBottom: {
    marginTop: '4px'
  },
  inputLabel: {
    fontSize: '13px',
    fontWeight: '400',
    color: theme.palette.text.primary,
    marginRight: '16px'
  },
  checkBoxContainer: {
    marginTop: '40px',
  },
  checkbox: {
    color: '#D5D5D5',
    marginLeft: '8px'
  },
  checkboxLabel: {
    fontSize: '12px !important',
    fontWeight: '500',
    color: `${theme.palette.text.header} !important`,
  },
};
