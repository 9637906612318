const styles = {
  main: {
    padding: '0px',
  },
  fieldsContainer: {
    // view height - header height (120px) - action buttons height (66px) - padding (4px)
    maxHeight: 'calc(100vh - 190px)',
    minHeight: 'calc(100vh - 190px)',
    overflow: 'scroll',
    padding: '8px 16px'
  },
  field: {
    margin: '4px 0'
  },
  fieldLabelContainer: {
    width: '100%',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
  },
  fieldValueContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  fieldLabel: {
    color: '#4C4E64DE',
    fontSize: '13px',
    fontWeight: '400',
    marginLeft: '4px',
  },
  selectText: {
    textAlign: 'right' as const,
    fontSize: '14px !important',
    lineHeight: '32px !important',
    color: '#4C4E64 !important',
    fontWeight: '500',
    paddingRight: '18px',
    '&.MuiMenuItem-root': {
      justifyContent: 'flex-end'
    }
  },
  addNewSupplierBtn: {
    justifyContent: "flex-start",
    pl: 2,
    backgroundColor: '#C2CFE033',
    textTransform: 'none',
  },
  viewLinesBtn: {
    backgroundColor: '#C2CFE099',
    color: '#4C4E64',
    fontSize: 13,
    width: '100%',
    height: '32px',
    textTransform: 'none' as const,
    boxShadow: 'none',
  },
  labelIconPlaceholder: {
    width: '18px',
    height: '18px',
  },
  errorIcon: {
    width: '18px',
    height: '18px',
    color: '#FF4D49',
  },
  notSureIcon: {
    width: '18px',
    height: '18px',
    color: '#FDB528',
  },
  trustedIcon: {
    width: '18px',
    height: '18px',
    color: '#3E8AFF',
  },
  supplierRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  editSupplierBtn: {
    height: '36px',
    width: '36px',
    backgroundColor: '#C2CFE033',
    borderRadius: '4px',
    marginLeft: '8px',
  },
  editSupplierIcon: {
    height: '16px',
    width: '16px',
    color: '#6D788D',
  },
  showDetailsBtn: {
    padding: '8px 0',
    textTransform: 'none' as const,
    color: '#4C4E64DE',
    fontSize: '13px',
    fontWeight: '500'
  },
  showDetailsIcon: {
    height: '20px',
    width: '20px',
    color: '#C2CFE0',
    marginRight: '6px',
  },
  senderDetails: {
  },
  doNotPayContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  doNotPayCheckbox: {
    padding: '0',
  },
  clientFieldLeft: {
    width: '100%',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  countryCodeContainer: {
    diplay: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px 0 0'
  },
  countryCodeInput: {
    width: '48px',
    '& .MuiOutlinedInput-input': {
      padding: '8px !important',
      textAlign: 'left' as const,
      fontSize: '14px !important',
      lineHeight: '32px !important',
      color: '#4C4E64 !important',
      fontWeight: '500',
    },
    '& .MuiAutocomplete-popupIndicator': {
      padding: '0px',
      marginRight: '-6px',
    },
  },
  countryCodeOptions: {
    width: '236px',
    '& .MuiAutocomplete-listbox': {
      '& .MuiAutocomplete-option': {
        padding: '4px 10px !important',
        width: '236px'
      },
    },
  },
  countryCodeTextField: {
    '& .MuiOutlinedInput-input': {
      textTransform: 'uppercase',
    },
  },
  taxNumberSwitchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  taxNumberSwitch: {
    '& .MuiSwitch-switchBase': {},
    '& .MuiSwitch-root': {
      height: '24px',
      width: '40px',
    }
  },
  taxNumberSwitchLabel: {
    '& .MuiFormControlLabel-label': {
      fontSize: '13px !important',
      fontWeight: '500 !important',
      color: '#4C4E64 !important',
    },
    '&.MuiFormControlLabel-root': {
      marginRight: '0px'
    }
  },
  vatDetails: {},
  vatLineRow: {
    margin: '4px 0',
    padding: '16px 0px 16px 0px',
    borderWidth: '0px 0px 1px 0px',
    borderStyle: 'solid',
    borderColor: '#D8D8DD'
  },
  addVatLineBtn: {
    height: '32px',
    background: '#C2CFE099',
    borderRadius: '4px',
    padding: '4px 8px 4px 8px',
    margin: '8px 0',
    textTransform: 'none',
    fontSize: '13px',
    fontWeight: '500',
    color: '#4C4E64',
  },
  addIcon: {
    width: '18px',
    height: '18px',
    color: '#6D788D',
    marginRight: '4px',
  },
  vatLineLabel: {
    color: '#4C4E64DE',
    fontSize: '13px',
    fontWeight: '500',
    marginLeft: '4px',
  },
  deleteIcon: {
    width: '20px',
    height: '20px',
    color: '#6D788D',
  },
  vatLineFieldsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  vatLineFieldsRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 0',
  },
  spreadBtnContainer: {
    width: '100%',
    justifyContent: 'flex-end',
    display: 'flex'
  },
  checkBox: {
    color: 'rgba(0, 0, 0, 0.26)',
    fontWeight: 500
  },
  spreadCheckBoxLabel: {
    '& .MuiFormControlLabel-label': {
      fontSize: '13px !important',
      fontWeight: '500 !important',
      color: '#4C4E64 !important',
    },
    '&.MuiFormControlLabel-root': {
      marginRight: '0px',
      display: 'flex',
      minWidth: '130px',
    }
  },
  textField: {
    '& .MuiOutlinedInput-input': {
      textAlign: 'right' as const,
      fontSize: '14px !important',
      lineHeight: '32px !important',
      color: '#4C4E64 !important',
      fontWeight: '500',
    }
  },
  listBox: {
    '& .MuiAutocomplete-option': {
      justifyContent: 'flex-end',
      textAlign: 'right' as const,
      padding: '5px 10px !important',
    },
    '& .MuiAutocomplete-option.Mui-focused': {
      backgroundColor: '#C2CFE033',
    },
    '&.MuiAutocomplete-listbox': {
      border: 'none',
      boxShadow: 'none',
      borderRadius: '0px',
      padding: '0px !important',
    }
  },
  vatLinesLabel: {
    fontSize: '11px',
    fontWeight: '500',
    lineHeight: '11px',
    textAlign: 'left',
    color: '#4C4E64DE',
    marginBottom: '2px',
  },
  vatRateContainer: {
    height: '60px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  vatAmountContainer: {
    height: '60px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    paddingLeft: '4px'
  },
  suggestionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '32px',
    padding: '0px 10px',
    opacity: 0.5
  },
  suggestionsText: {
    fontSize: '10px',
    fontWeight: '500',
    lineHeight: '32px',
    letterSpacing: '-0.25px',
    textAlign: 'right' as const,
    color: '#4C4E64',
    textTransform: 'uppercase',
  },
  newFieldContainer: {
    flex: 1,
  },
  newFieldInput: {
    textAlign: 'right' as const,
    fontSize: '14px !important',
    lineHeight: '26px !important',
    color: '#4C4E64 !important',
    fontWeight: '500',
    padding: '4px !important',
    height: '26px',
  },
  newFieldInputRoot: {
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '0px',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    background: 'linear-gradient(92.29deg, #3E8AFF 0%, #9E71FF 100%)',
    height: '34px',
    padding: '4px 2px 0px 2px',
    '& fieldset': {
      border: '0px',
      height: '30px',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: '0px',
      height: '30px',
      cursor: 'pointer',
    }
  },
  annotatedDocsCountContainer: {
    display: 'flex',
    height: '18px',
    padding: '4px 6px',
    background: 'linear-gradient(90.02deg, #3E8AFF 0.01%, #9E71FF 99.99%)',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    gap: '2px',
    whiteSpace: 'nowrap',
    alignItems: 'center',
  },
  annotatedDocsCount: {
    fontSize: '8px',
    fontWeight: '400',
    lineHeight: '10px',
    letterSpacing: '-0.25px',
    color: '#FFFFFF',
  },
  infoIcon: {
    height: '10px',
    width: '10px',
    color: '#FFFFFF',
    opacity: 0.8
  },
  sendNewFieldDataBtn: {
    height: '52px',
    width: '30px',
    minWidth: '30px',
    marginLeft: '4px',
    backgroundColor: '#3E8AFF33',
    borderRadius: '4px',
    padding: '0 7px !important',
    '&:hover': {
      backgroundColor: '#3E8AFF33',
      boxShadow: '0px 2px 10px 0px #4C4E6438',
    }
  },
  sendIcon: {
    width: '16px',
    height: '16px',
    color: '#3E8AFF'
  },
  newFieldIconContainer: {
    height: '20px',
    width: '20px',
  },
  suppliersListItem: {
    fontSize: '14px',
    lineHeight: '1.5',
    fontWeight: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    cursor: 'pointer',
  }
};

export default styles;
