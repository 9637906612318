import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';

import {
  Box, Button, Grid, Menu, MenuItem, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography
} from '@mui/material';
import {
  ArrowDropDown as DropDownIcon
} from '@mui/icons-material';

import { axiosHeaders } from 'src/utils/helpers';
import { useAuth } from 'src/hooks/useAuth';
import { useConfig } from 'src/hooks/useConfig';
import useWidth from 'src/hooks/useWidth';
import LoadingIndicator from 'src/shared/components/LoadingIndicator';
import EmptyText from 'src/shared/components/EmptyText/EmptyText';
import styles from './style';

type closingPeriod = {
  id: number;
  period: string;
  start: number;
  end: number;
  hash: string;
  company: string;
};

const Hacienda = () => {
  const { ready, t } = useTranslation();

  const { API } = useConfig();
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState<Record<string, any>[]>([]);
  const [page, setPage] = useState(1);
  const [closingPeriods, setClosingPeriods] = useState<string[]>([]);
  const [selectedClosingPeriod, setSelectedClosingPeriod] = useState<closingPeriod>();
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(25);

  const [anchorElClosingPeriod, setAnchorElClosingPeriod] = useState<null | HTMLElement>(null);

  const closingPeriodBtnRef = useRef(null);
  const closingPeriodBtnWidth = useWidth(closingPeriodBtnRef, 0);

  const fetchDetails = async (_limit: number = 25, _page: number = 1, _closingPeriod?: closingPeriod) => { // Update any
    setLoading(true);
    const closingPeriod = _closingPeriod || selectedClosingPeriod;
    try {
      const response = await Axios.get(
        `${API.getHaciendaDetails}?closingPeriod=${closingPeriod?.period || closingPeriod}&limit=${_limit}&page=${_page}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setDetails(response.data.data);
        if (response.data.total) setTotalPages(response.data.total);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchClosingPeriods = async () => {
    setLoading(true);
    try {
      const response = await Axios.get(
        `${API.getClosingPeriods}${user?.companyID}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setClosingPeriods(response.data.data);
        if (response.data.data.length > 0) {
          setSelectedClosingPeriod(response.data.data[0]);
          fetchDetails(limit, page, response.data.data[0]);
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClosingPeriods();
  }, []);

  const handlePageChange = (_event: any, newPage: number) => {
    setPage(newPage + 1);
    fetchDetails(limit, newPage + 1);
  };

  const handleLimitChange = (event: any) => {
    setPage(1);
    setLimit(event.target.value);
    fetchDetails(event.target.value, 1);
  };

  const handleOpenClosingPeriodsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElClosingPeriod(event.currentTarget);
  };

  const handleCloseClosingPeriodsMenu = () => {
    setAnchorElClosingPeriod(null);
  };

  const handleSelectClosingPeriod = (period: closingPeriod) => {
    setSelectedClosingPeriod(period);
    fetchDetails(limit, 1, period);
    handleCloseClosingPeriodsMenu();
  };

  const getHashStyle = (hashModifiedTimestamp: number, closingPeriodEnd: number) => {
    if (hashModifiedTimestamp && hashModifiedTimestamp > 0 && hashModifiedTimestamp > closingPeriodEnd) {
      return styles.rowRed;
    }

    return {};
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.titleRow}>
        <Typography sx={styles.title}>{t('ACCOUNT_SETTINGS_HACIENDA')}</Typography>
        <Box>
          <Button
            ref={closingPeriodBtnRef}
            onClick={handleOpenClosingPeriodsMenu}
            sx={styles.closingPeriodBtn}
            endIcon={<DropDownIcon sx={styles.closingPeriodBtnIcon} />}
          >
            {selectedClosingPeriod?.period || ''}
          </Button>
          <Menu
            id="closing-periods-menu"
            anchorEl={anchorElClosingPeriod}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElClosingPeriod)}
            onClose={handleCloseClosingPeriodsMenu}
            sx={{
              ".MuiMenu-paper": {
                ...styles.menuContainer,
                width: closingPeriodBtnWidth,
              },
            }}
          >
            {closingPeriods.map((period: any) => (
              <MenuItem key={period.id} sx={styles.menuItem} onClick={()=> handleSelectClosingPeriod(period)}>
                {period.period}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Box>
      <Grid sx={styles.tableContainer} className="x-scroll">
        {details && details.length > 0
          ? (<Table>
              <TableHead>
                <TableRow sx={styles.tableHeader}>
                  <TableCell sx={styles.headerCell} className="edit-invoice-line-header">
                    {ready && t('INVOICE_LIST_TABLE_HEADER_ASST_ID')}
                  </TableCell>
                  <TableCell sx={styles.headerCell} className="edit-invoice-line-header">
                    {ready && t('HACIENDA_SETTINGS_FILE_NAME')}
                  </TableCell>
                  <TableCell sx={styles.headerCell} className="edit-invoice-line-header">
                    {ready && t('HACIENDA_SETTINGS_UPLOADED_DATE')}
                  </TableCell>
                  <TableCell sx={styles.headerCell} className="edit-invoice-line-header">
                    {ready && t('INVOICE_LIST_TABLE_HEADER_CLOSED_PERIOD')}
                  </TableCell>
                  <TableCell sx={styles.headerCell} className="edit-invoice-line-header">
                    {ready && t('HACIENDA_SETTINGS_HASH')}
                  </TableCell>
                  <TableCell sx={styles.headerCell} className="edit-invoice-line-header">
                    {ready && t('HACIENDA_SETTINGS_HASH_MODIFIED_TIMESTAMP')}
                  </TableCell>
                  <TableCell sx={styles.headerCell} className="edit-invoice-line-header">
                    {ready && t('HACIENDA_SETTINGS_INVOICE_NUMBER')}
                  </TableCell>
                  <TableCell sx={styles.headerCell} className="edit-invoice-line-header">
                    {ready && t('HACIENDA_SETTINGS_INVOICE_DATE')}
                  </TableCell>
                  <TableCell align="right" sx={styles.headerCell}>
                    {ready && t('HACIENDA_SETTINGS_TOTAL_AMOUNT')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {details.map((item) => (
                  <TableRow key={item.documentID} sx={getHashStyle(parseInt(item.hashModifiedTimestamp, 10), selectedClosingPeriod?.end || 0)}>
                    <TableCell sx={styles.bodyCell} className="edit-invoice-line-body">
                      {item.documentID}
                    </TableCell>
                    <TableCell sx={styles.bodyCell} className="edit-invoice-line-body">
                      {item.originalFileName}
                    </TableCell>
                    <TableCell sx={styles.bodyCell} className="edit-invoice-line-body">
                      {item.uploadedTime}
                    </TableCell>
                    <TableCell sx={styles.bodyCell} className="edit-invoice-line-body">
                      {selectedClosingPeriod?.period || ''}
                    </TableCell>
                    {/* If the hashModifiedTimestamp > selectedClosingPeriod.endDate, then display the  */}
                    <TableCell
                      sx={styles.bodyCell}
                      className="edit-invoice-line-body"
                    >
                      {item.hash}
                    </TableCell>
                    <TableCell sx={styles.bodyCell} className="edit-invoice-line-body">
                      {item.hashModifiedTimestamp}
                    </TableCell>
                    <TableCell sx={styles.bodyCell} className="edit-invoice-line-body">
                      {item.invoiceNumber}
                    </TableCell>
                    <TableCell sx={styles.bodyCell} className="edit-invoice-line-body">
                      {item.invoiceDate}
                    </TableCell>
                    <TableCell sx={styles.bodyCell} className="edit-invoice-line-body-end">
                      {item.totalAmount}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )
        : (<EmptyText str="ACCOUNT_SETTINGS_HACIENDA_NO_DOCS" />)}
      </Grid>
      <TablePagination
        component="div"
        count={totalPages}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page - 1}
        rowsPerPage={limit}
        rowsPerPageOptions={[25, 50, 100]}
        labelRowsPerPage={t('ROWS_PER_PAGE')}
      />
    </Box>
  );
};

export default Hacienda;
