import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Backdrop, CircularProgress } from '@mui/material';

import SideBarLayout from 'src/shared/layouts/SideBarLayout';
import { useConfig } from 'src/hooks/useConfig';
import { RootState } from 'src/types';
import { useAuth } from 'src/hooks/useAuth';
import { fetchHeaderFields } from 'src/shared/redux/fields/fieldsActions';
import { UnknownAction } from 'redux';
import CDCDocumentEdit from 'src/cdc-document-edit-new/CDCDocumentEdit';
import LoadingScreen from 'src/shared/screens/LoadingScreen';

const CDCDocumentEditContainer = () => {
  const params = useParams<{ id: string }>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { BRAND_NAME } = useConfig();
  const { user } = useAuth();

  const fieldsLoading: boolean = useSelector((state: RootState) => state.fields.fieldsLoading);
  const docType: string = useSelector((state: RootState) => state.fields.docType);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const id = params && params.id ? params.id : '0';
    if (docType !== 'cdc-document' || user?.userRole === 'annotator') {
      dispatch(fetchHeaderFields(user?.companyID || '', 'cdc-document', id) as unknown as UnknownAction);
    }
  }, []);

  if (fieldsLoading) {
    return <LoadingScreen />;
  }

  return (
    <SideBarLayout title={`${t('INVOICE_EDIT_PAGE_TITLE')} | ${BRAND_NAME}`} hideTopBar={true}>
      <CDCDocumentEdit
        id={params && params.id ? params.id : '0'}
        setLoading={setLoading}
      />
      <Backdrop style={styles.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </SideBarLayout>
  );
}

export default CDCDocumentEditContainer;

const styles = {
  backdrop: {
    zIndex: 1111111,
    color: '#ffffff',
  },
}
