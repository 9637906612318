import { useContext, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';

import {
  Backdrop, Fade, Grid, IconButton, Modal, Typography
} from '@mui/material';
import {
  Close as CloseIcon
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import {
  ERRORS, supplierData,
} from 'src/config';
import {
  axiosHeaders, getLocalisedErrorString
} from 'src/utils/helpers';
import authService from 'src/utils/authService';
import { fetchCostCenters } from 'src/dimensions/costcenters/actions';
import ConfigContext from 'src/contexts/ConfigContext';
import VatCodeTwinfield from 'src/dimensions/vatcodes/components/VatCodeModal/VatCodeTwinfield';
import VatCodeStandard from 'src/dimensions/vatcodes/components/VatCodeModal/VatCodeStandard';
import CostCenterModal from 'src/dimensions/costcenters/components/CostCenterModal/CostCenterModal';
import styles from './style';

function VatCodeModal({
  vatCodeModalOpen,
  selectedVatCode,
  handleClose,
  onSuccess,
  getAllVatCodes,
}) {
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const { LOGIN_PANEL_URL, API } = useContext(ConfigContext);
  
  const auth = useAuth();
  const {user} = auth;

  const _costCenters = useSelector((state) => state.costcenter.costCenters);
  const costCenters = [...supplierData.defaultCostCenters, ...useMemo(() => _costCenters, [_costCenters])];
  const _glaccounts = useSelector((state) => state.gla.glAccounts);
  const glaccounts = [...supplierData.defaultGLA, ...useMemo(() => _glaccounts, [_glaccounts])];

  const [ccModalOpen, setCCModalOpen] = useState(false);
  const [selectedCostCenterCode, setSelectedCostCenterCode] = useState(null);

  const [newlyAddingProp, setNewlyAddingProp] = useState(null);
  const [newlyAddedVal, setNewlyAddedVal] = useState(null);

  const hasError = (fields, errors) => {
    for (let i = 0; i < fields.length; i++) {
      if (errors[fields[i].key]) {
        // Returning true until Twinfield integration
        return false;
      }
    }
    return false;
  };

  const handleChangeAutocomplete = (e, prop, values, handleChange) => {
    if (values && values.code) {
      handleChange({ target: { name: prop, value: values.code } });
      return;
    }

    handleChange(e, prop);
  };

  const getLabel = (entities, code) => {
    for (let i = 0; i < entities?.length; i++) {
      if (code === entities[i].code) {
        let namePart = '';
        if (entities[i].name) {
          namePart = ` | ${entities[i].name}`;
        }
        return `${entities[i].code}${namePart}`;
      }
    }

    return code;
  };

  const getCostCenters = () => {
    dispatch(fetchCostCenters(user.companyID));
  };

  const onCCModalClose = () => {
    setCCModalOpen(false);
    setSelectedCostCenterCode(null);
  };

  const handleEditCostCenter = (id) => {
    if (id !== supplierData.defaultCostCenters[0].code) {
      setSelectedCostCenterCode(id);
      setCCModalOpen(true);
    }
  };

  const handleAddCostCenter = (prop) => {
    setSelectedCostCenterCode(null);
    setCCModalOpen(true);
    setNewlyAddingProp(prop);
  };

  const onCCCreated = (val) => {
    setNewlyAddedVal(val);
  };

  return (
    <>
      <Modal
        style={styles.modal}
        open={vatCodeModalOpen}
        onClose={handleClose}
        closeAfterTransition
        slot={Backdrop}
        slotProps={{
          timeout: 500,
        }}
      >
        <Fade in={vatCodeModalOpen}>
          <div style={user.interfacePreference === 'twinfield_interface' ? styles.paper : styles.halfPaper}>
            <Grid style={styles.modalHeader}>
              <Typography style={styles.headerTitle}>
                {selectedVatCode?.name ? `${t('VAT_CODE')} - ${selectedVatCode ? selectedVatCode.name : ''} (${selectedVatCode?.code})` : t('VAT_CODE_ADD_NEW')}
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon style={styles.closeIcon} />
              </IconButton>
            </Grid>
            <Formik
              initialValues={{
                code: selectedVatCode?.code || '',
                name: selectedVatCode?.name || '',
                type: selectedVatCode?.type || '',
                shortName: selectedVatCode?.shortName || '',
                invoiceText: selectedVatCode?.invoiceText || '',
                ratePercentage: selectedVatCode?.ratePercentage || '',
                rateName: selectedVatCode?.rateName || '',
                rateShortName: selectedVatCode?.rateShortName || '',
                ruleAccount: selectedVatCode?.ruleAccount || supplierData.defaultGLA[0].code,
                rulePercentage: selectedVatCode?.rulePercentage || '',
                ruleCostCenter: selectedVatCode?.ruleCostCenter || supplierData.defaultCostCenters[0].code,
                ruleVatGroup: selectedVatCode?.ruleVatGroup || '',
              }}
              validationSchema={Yup.object().shape({
                code: Yup.string().max(255).matches('^\\S*$', 'VAT_CODE_CODE_SPACES_NOT_ALLOWED')
                  .when('name', {
                    is: () => user.interfacePreference === 'twinfield_interface',
                    then: Yup.string().max(255).matches('^\\S*$', 'VAT_CODE_CODE_SPACES_NOT_ALLOWED').required('VAT_CODE_CODE_REQUIRED')
                  }),
                name: Yup.string()
                  .max(80, 'VAT_CODE_NAME_MAX_VALIDATION')
                  .required('VAT_CODE_NAME_REQUIRED'),
                type: Yup.string().max(255)
                  .when('name', {
                    is: () => user.interfacePreference === 'twinfield_interface',
                    then: Yup.string().max(255).required('VAT_CODE_TYPE_REQUIRED')
                  }),
                ratePercentage: Yup.string()
                  .max(255)
                  .required('VAT_CODE_RATE_PERCENTAGE_REQUIRED')
                  .matches('^(?:\\d{1,2}(?:\\.\\d{1,2})?|100(?:\\.0?0)?)$', 'VAT_CODE_RATE_PERCENTAGE_LIMITED'),
                rateName: Yup.string().max(255)
                  .when('name', {
                    is: () => user.interfacePreference === 'twinfield_interface',
                    then: Yup.string().max(255).required('VAT_CODE_RATE_NAME_REQUIRED')
                  }),
                rateShortName: Yup.string().max(255)
                  .when('name', {
                    is: () => user.interfacePreference === 'twinfield_interface',
                    then: Yup.string().max(255).required('VAT_CODE_RATE_SHORT_NAME_REQUIRED')
                  }),
                ruleAccount: Yup.string().max(255)
                  .when('name', {
                    is: () => user.interfacePreference === 'twinfield_interface',
                    then: Yup.string().max(255).required('VAT_CODE_RULE_ACCOUNT_REQUIRED')
                  }),
                rulePercentage: Yup.string()
                  .max(255)
                  .matches('^(?:\\d{1,2}(?:\\.\\d{1,2})?|100(?:\\.0?0)?)$', 'VAT_CODE_RULE_PERCENTAGE_LIMITED')
                  .when('name', {
                    is: () => user.interfacePreference === 'twinfield_interface',
                    then: Yup.string()
                      .max(255)
                      .matches('^(?:\\d{1,2}(?:\\.\\d{1,2})?|100(?:\\.0?0)?)$', 'VAT_CODE_RULE_PERCENTAGE_LIMITED')
                      .required('VAT_CODE_RULE_PERCENTAGE_REQUIRED')
                  }),
                // ruleCostCenter: Yup.string().max(255).required('VAT_CODE_RULE_COST_CENTER_REQUIRED'),
                // ruleVatGroup: Yup.string().max(255).required('VAT_CODE_RULE_VAT_GROUP_REQUIRED'),
              })}
              onSubmit={async (values, {
                resetForm,
                setErrors,
                setStatus,
                setSubmitting
              }) => {
                try {
                  if (!authService.validateToken()) {
                    enqueueSnackbar(ready && t('VAT_CODE_LOGIN_EXPIRED'), {
                      variant: 'error',
                    });
                    setTimeout(() => {
                      authService.logout(LOGIN_PANEL_URL);
                    }, 2000);
                    return;
                  }

                  const body = {
                    code: values.code,
                    name: values.name,
                    type: values.type,
                    shortName: values.shortName,
                    invoiceText: values.invoiceText,
                    ratePercentage: values.ratePercentage,
                    rateName: values.rateName,
                    rateShortName: values.rateShortName,
                    ruleAccount: values.ruleAccount,
                    rulePercentage: values.rulePercentage,
                    ruleCostCenter: values.ruleCostCenter,
                    ruleVatGroup: values.ruleVatGroup,
                  };

                  const url = `${API.vatCodesByCompany}/${encodeURIComponent(user.companyID)}`;
                  let response = null;
                  let snackText = '';

                  if (selectedVatCode && selectedVatCode.name) {
                    response = await Axios.put(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
                    snackText = 'VAT_CODES_UPDATE_SUCCESS';
                  } else {
                    response = await Axios.post(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
                    snackText = 'VAT_CODES_ADD_SUCCESS';
                  }
                  if (response?.data?.success) {
                    resetForm();
                    setStatus({ success: true });
                    setSubmitting(false);
                    enqueueSnackbar(ready && t(snackText), {
                      variant: 'success',
                      style: { maxWidth: 400 }
                    });
                    handleClose();
                    getAllVatCodes();
                    if (onSuccess) {
                      onSuccess(response.data.id);
                    }
                  }
                } catch (error) {
                  setStatus({ success: false });
                  setSubmitting(false);
                  if (error.response.data.i18n.indexOf('__TFMSG__') > 0) {
                    const errParts = error.response.data.i18n.split('__TFMSG__');
                    setErrors({
                      [errParts[0]]: errParts[1]
                    });
                  } else {
                    setErrors({
                      submit: getLocalisedErrorString(ERRORS[error.response.data.i18n], t) || getLocalisedErrorString(error.response.data.i18n, t)
                    });
                  }
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setErrors,
                isSubmitting,
                touched,
                values
              }) => (
                user.interfacePreference === 'twinfield_interface'
                  ? (
                    <VatCodeTwinfield
                      errors={errors}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      setErrors={setErrors}
                      isSubmitting={isSubmitting}
                      touched={touched}
                      values={values}
                      vatCode={selectedVatCode}
                      handleClose={handleClose}
                      hasError={hasError}
                      handleChangeAutocomplete={handleChangeAutocomplete}
                      getLabel={getLabel}
                      costCenters={costCenters}
                      handleEditCostCenter={handleEditCostCenter}
                      handleAddCostCenter={handleAddCostCenter}
                      glaccounts={glaccounts}
                      onSuccess={onSuccess}
                      newlyAddedVal={newlyAddedVal}
                      newlyAddingProp={newlyAddingProp}
                    />
                  ) : (
                    <VatCodeStandard
                      errors={errors}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      setErrors={setErrors}
                      isSubmitting={isSubmitting}
                      touched={touched}
                      values={values}
                      vatCode={selectedVatCode}
                      handleClose={handleClose}
                      hasError={hasError}
                      handleChangeAutocomplete={handleChangeAutocomplete}
                      getLabel={getLabel}
                      costCenters={costCenters}
                      handleEditCostCenter={handleEditCostCenter}
                      handleAddCostCenter={handleAddCostCenter}
                      glaccounts={glaccounts}
                      onSuccess={onSuccess}
                      newlyAddedVal={newlyAddedVal}
                      newlyAddingProp={newlyAddingProp}
                    />
                  ))}
            </Formik>
          </div>
        </Fade>
      </Modal>
      <CostCenterModal
        costCenterModalOpen={ccModalOpen}
        handleClose={onCCModalClose}
        getAllCostCenters={getCostCenters}
        onSuccess={onCCCreated}
        selectedCostCenter={selectedCostCenterCode}
      />
    </>
  );
}

// VatCodeModal.propTypes = {
//   vatCodeModalOpen: PropTypes.bool,
//   selectedVatCode: PropTypes.string,
//   handleClose: PropTypes.func,
//   className: PropTypes.string,
//   onSuccess: PropTypes.func,
//   getAllVatCodes: PropTypes.func
// };

export default VatCodeModal;
