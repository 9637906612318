import { Suspense } from 'react';
import {
  Navigate, Route, Routes, Outlet
} from 'react-router-dom';

import { AuthProvider } from 'src/contexts/AuthContext';
import LoadingScreen from 'src/shared/screens/LoadingScreen';
import VerifyLogin from 'src/shared/screens/VerifyLogin';
import DashboardLayout from 'src/shared/layouts/DashboardLayout';

import Error404 from 'src/shared/screens/Error404';
import InvoiceImage404 from 'src/shared/screens/InvoiceImageNotFound';
import InviteUserCallback from 'src/shared/screens/InviteUserCallback';
import QuickbooksOAuth2Callback from 'src/integrations/containers/QuickbooksOAuth2Callback/QuickbooksOAuth2Callback';
import TwinfieldTokenCallback from 'src/integrations/containers/TwinfieldTokenCallback/TwinfieldTokenCallback';

import StatsDashboard from 'src/dashboard/StatsDashboard/StatsDashboard';
import DashboardView from 'src/dashboard-view/DashboardView';

import DocumentsView from 'src/documents-view/DocumentsView';
import CDCDocumentEditView from 'src/cdc-document-edit-view/CDCDocumentEditView';

import InvoiceImage from 'src/invoice-display/InvoiceDisplay';
import Attachment from 'src/documents/DocumentDisplay/DocumentDisplay';
import ExportFileDownload from 'src/documents/DownloadExportFile/DownloadExportFile';

import SettingsView from './settings-view/SettingsView';
import PlanAndBillingView from 'src/plan-n-billing-view/PlanAndBillingView';
import SubscriptionCheckout from 'src/checkout/Checkout';

import DimensionsView from './dimensions-view/DimensionsView';

import DocumentLogsList from 'src/logs/DocumentLogsList';
import DocumentLogView from 'src/logs/DocumentLogView';
import DimensionLogView from 'src/logs/DimensionLogView';
import DimensionLogsListView from 'src/logs/DimensionLogsList';

import Integrations from 'src/integrations/containers/Integrations/Integrations';
import DocumentView from 'src/document-view/DocumentView';

import DocumentTypes from 'src/doc-types/DocumentTypes';

import SendEmailForOP from './dashboard/SendEmailForOP/SendEmailForOP';

const AuthLayout = () => {
  return (
    <AuthProvider>
      <Outlet />
    </AuthProvider>
  );
};

const ParentDashboardLayout = () => {
  return (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  );
};

function AppRoutes() {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="/login" element={<VerifyLogin />} />
        <Route path="/404" element={<Error404 />} />
        <Route path="/404/invoice/image" element={<InvoiceImage404 />} />

        <Route path="/statistics/dashboard" element={<StatsDashboard />} />
        <Route path="/account/users/invite/:token" element={<InviteUserCallback />} />
        <Route path="/integrations/quickbooks/oauth2/callback" element={<QuickbooksOAuth2Callback />} />
        <Route path="/integrations/twinfield/token/callback" element={<TwinfieldTokenCallback />} />

        {/* Secure URL for accessing invoice files and attachments */}
        <Route path="/files/image/:id" element={<InvoiceImage />} />
        <Route path="/files/transaction/:transaction/" element={<InvoiceImage />} />
        <Route path="/files/company/:company/transaction/:transaction/" element={<InvoiceImage />} />
        <Route path="/files/attachment/:name" element={<Attachment />} />
        <Route path="/files/export/:filename" element={<ExportFileDownload />} />

        <Route element={<AuthLayout />}>
          <Route path="/dashboard" element={<DashboardView />} />

          <Route path="/documents" element={<DocumentsView />} />
          <Route path="/doc/:id/edit" element={<DocumentView />} />
          <Route path="/invoices/:id/edit" element={<DocumentView />} />
          <Route path="/documents/cdc/:id/edit" element={<CDCDocumentEditView />} />
          <Route path="/documents/:id/logs/" element={<DocumentLogsList />} />
          <Route path="/documents/logs/:id" element={<DocumentLogView />} />
          <Route path="/documents/logs" element={<DocumentLogsList />} />

          <Route path="/dimensions/:type" element={<DimensionsView />} />

          <Route path="/doc-types" element={<DocumentTypes />} />

          <Route element={<ParentDashboardLayout />}>
            <Route path="/dimensions/logs/:dim/:id" element={<DimensionLogView />} />
            <Route path="/dimensions/logs/:dim/:type?/code/:code" element={<DimensionLogsListView />} />
            <Route path="/dimensions/logs/:dim/:type?/company/:company" element={<DimensionLogsListView />} />

            <Route path="/subscription/checkout" element={<SubscriptionCheckout />} />
            
            <Route path="/integrations" element={<Integrations />} />
          </Route>

          <Route path="/subscription-plan" element={<PlanAndBillingView />} />

          <Route path="/settings" element={<SettingsView />} />
          <Route path="/send-email" element={<SendEmailForOP />} />
        </Route>

        {/* Catch-All */}
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </Suspense>
  );
}

export default AppRoutes;
