import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable, DropResult, DraggableProvided } from '@hello-pangea/dnd';
import { useSnackbar } from 'notistack';
import Axios from 'axios';

import { Box, Button, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import {
  ArrowBack as BackIcon,
  DragIndicator as ReorderIcon,
} from '@mui/icons-material';
import { RiEyeLine, RiEyeOffLine, RiPencilLine, RiAddCircleLine } from '@remixicon/react';

import { useAuth } from 'src/hooks/useAuth';
import { useConfig } from 'src/hooks/useConfig';
import { appendContactSupport, axiosHeaders, getLocalisedErrorString } from 'src/utils/helpers';
import { CustomError } from 'src/types';
import theme from 'src/themeNew';
import CancelBtn from 'src/shared/buttons/CancelBtn';
import SubmitBtn from 'src/shared/buttons/SubmitBtn';
import PrimaryActionBtnLarge from 'src/shared/buttons/PrimaryActionBtnLarge';
import AddNewField from '../AddNewField/AddNewField';
import styles from './style';
import APIRef from '../APIRef/APIRef';
import { fieldSearch } from 'src/doc-types/utils';

interface PropTypes {
  selectedDocType: Record<string, any> | null;
  fieldsList: Record<string, any>[];
  sectionFieldsList: Record<string, Record<string, any>[]>;
  selectedField: Record<string, any> | null;
  addNewOpen: boolean;
  showAPIRef: boolean;
  fetchDocTypes: (_pageNo?: number, _pageLimit?: number) => Promise<void>;
  setShowAPIRef: Dispatch<SetStateAction<boolean>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setAddNewOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedField: Dispatch<SetStateAction<Record<string, any> | null>>;
  setFieldsList: Dispatch<SetStateAction<Record<string, any>[]>>;
  setSectionFieldsList: Dispatch<SetStateAction<Record<string, Record<string, any>[]>>>;
  handleClose: () => void;
  handleCloseOnSave: () => void;
}

const ManageFieldsSideMenu = (props: PropTypes) => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useAuth();
  const { API } = useConfig();

  const isAPIUser = user?.integrations?.includes('api');

  const {
    selectedDocType,
    fieldsList,
    sectionFieldsList,
    selectedField,
    addNewOpen,
    showAPIRef,
    fetchDocTypes,
    setShowAPIRef,
    setLoading,
    setAddNewOpen,
    setSelectedField,
    setFieldsList,
    setSectionFieldsList,
    handleClose,
    handleCloseOnSave,
  } = props;

  const [searchQuery, setSearchQuery] = useState<string>('');

  const handleSearch = (val: string) => {
    setSearchQuery(val);
  };

  const handleActivateField = (field: Record<string, any>) => {
    if (field.section === 'header') {
      setFieldsList((prev) => prev.map((f) => {
        if ((field.key && f.key && f.key === field.key) || (!field.key && !f.key && f.position === field.position)) {
          return { ...f, isAvailable: !f.isAvailable };
        }
        return f;
      }));
    } else {
      setSectionFieldsList((prev) => {
        const newFields = { ...prev };
        newFields[field.section] = prev[field.section].map((f) => {
          if ((field.key && f.key && f.key === field.key) || (!field.key && !f.key && f.position === field.position)) {
            return { ...f, isAvailable: !f.isAvailable };
          }
          return f;
        });
        return newFields;
      });
    }
  };

  const adjustFieldPosition = (prevFields: Record<string, any>[], source: number, destination: number) => {
    const sourceIndex = source + 1;
    const destinationIndex = destination + 1;
    const currFields = prevFields.map((f, i) => ({ ...f, position: i + 1 }));
    const newFields = [];
    if (sourceIndex > destinationIndex) {
      for (let i = 0; i < currFields.length; i++) {
        if (currFields[i].position < sourceIndex && currFields[i].position >= destinationIndex) {
          newFields.push({ ...currFields[i], position: currFields[i].position + 1 });
        } else {
          if (currFields[i].position === sourceIndex) {
            newFields.push({ ...currFields[i], position: destinationIndex });
          } else {
            newFields.push({ ...currFields[i] });
          }
        }
      }
    }

    if (sourceIndex < destinationIndex) {
      for (let i = 0; i < currFields.length; i++) {
        if (currFields[i].position > sourceIndex && currFields[i].position <= destinationIndex) {
          newFields.push({ ...currFields[i], position: currFields[i].position - 1 });
        } else {
          if (currFields[i].position === sourceIndex) {
            newFields.push({ ...currFields[i], position: destinationIndex });
          } else {
            newFields.push({ ...currFields[i] });
          }
        }
      }
    }

    return newFields;
  };

  const handleAdjustFieldPosition = (
    e: DropResult,
    section: string,
  ) => {
    if (!e.destination || e.destination.index === e.source.index) {
      return;
    }
    const destination = e.destination?.index;
    const source = e.source.index;

    if (section === 'header') {
      setFieldsList((prevFields) => adjustFieldPosition(prevFields, source, destination));
    } else {
      setSectionFieldsList((prevState) => {
        return {
          ...prevState,
          [section]: adjustFieldPosition(prevState[section], source, destination),
        };
      });
    }
  };

  const onEdit = (field: Record<string, any>) => {
    setSelectedField(field);
    setAddNewOpen(true);
  };

  const handleAddNewClose = () => {
    setSelectedField(null);
    setAddNewOpen(false);
  };

  const sortFields = (_fields: Record<string, any>[]) => {
    return _fields.sort((a, b) => a.position - b.position);
  };

  const handleSaveFields = async (_fields: Record<string, any>[]) => {
    if (selectedDocType === null) {
      return;
    }
    setLoading(true);
    const body = {
      documentType: selectedDocType?.documentType,
      fields: _fields,
    };
    try {
      const url = `${API.documentTypes}/fields`;
      const response = await Axios.post(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response.data.success) {
        enqueueSnackbar(t('DOC_TYPES_FIELDS_UPDATE_SUCCESS'), {
          variant: 'success',
        });
      }
      fetchDocTypes();
      handleAddNewClose();
      handleCloseOnSave();
      setLoading(false);
      if (!user?.documentType) {
        window.location.reload();
      }
    } catch (e) {
      const error = e as CustomError;
      enqueueSnackbar(appendContactSupport(
        window.config.support_email,
        getLocalisedErrorString(error?.response?.data?.i18n || 'DOC_TYPES_FIELDS_UPDATE_FAILURE', t),
        t
      ), {
        variant: 'error',
      });
      setLoading(false);
    }
  };

  const renderFieldRow = (field: Record<string, any>, draggableProvided: DraggableProvided,) => {
    return (
      <Box key={field.key} sx={styles.fieldItem}>
        <div {...draggableProvided.dragHandleProps} style={styles.dragIconContainer}>
          <ReorderIcon sx={styles.dragIcon} />
        </div>
        <Typography sx={styles.fieldName}>{i18n.exists(field.name) ? t(field.name) : field.name}</Typography>
        <IconButton onClick={() => handleActivateField(field)} sx={styles.iconContainer}>
          {field.isAvailable
            ? <RiEyeLine size={'20px'} color={theme.palette.text.secondary} />
            : <RiEyeOffLine size={'20px'} color={theme.palette.text.secondary} />
          }
        </IconButton>
        <IconButton sx={styles.iconContainer} onClick={() => onEdit(field)}>
          <RiPencilLine size={'20px'} color={theme.palette.text.secondary} />
        </IconButton>
      </Box>
    );
  };

  return (
    <>
      <Box sx={styles.root}>
        <Box sx={styles.header}>
          <Box sx={styles.headerRow}>
            <Box sx={styles.headerLeft}>
              <IconButton sx={styles.backIconContainer} onClick={handleClose}>
                <BackIcon sx={styles.backIcon} />
              </IconButton>
              <Typography sx={styles.headerText}>{t('MANAGE_FIELDS_HEADER')}</Typography>
            </Box>
            {isAPIUser && <Button onClick={() => setShowAPIRef(true)} sx={styles.apiBtn}>
              <Typography sx={styles.apiBtnText}>{t('MANAGE_FIELDS_DOC_TYPE_API_REF')}</Typography>
            </Button>}
          </Box>
          <Box sx={styles.headerRow}>
            <Tooltip title={selectedDocType?.name}>
              <Typography sx={styles.subHeaderText}>{t('MANAGE_FIELDS_HEADER_FOR', { docType: selectedDocType?.name })}</Typography>
            </Tooltip>
          </Box>
          <Box sx={styles.addFieldBtnContainer}>
            <PrimaryActionBtnLarge
              title={t('MANAGE_FIELDS_NEW_ADD_FIELD')}
              onClick={() => setAddNewOpen(true)}
              startIcon={<RiAddCircleLine size={'16px'} />}
            />
          </Box>
          <Tooltip title={t('DOC_TYPES_MANAGE_FIELDS_SEARCH_HELPER')}>
            <TextField
              fullWidth
              sx={styles.searchBox}
              onChange={(e) => handleSearch(e.target.value)}
              placeholder={t('DOC_TYPES_MANAGE_FIELDS_SEARCH_HELPER')}
              value={searchQuery}
              variant="outlined"
              InputProps={{
                sx: {
                  '& .MuiOutlinedInput-notchedOutline': styles.searchInput,
                  '& .MuiOutlinedInput-input': styles.searchBarInput
                }
              }}
            />
          </Tooltip>
        </Box>
        <Grid className="y-scroll" sx={styles.fieldsContainer}>
          <DragDropContext onDragEnd={(e) => handleAdjustFieldPosition(e, 'header')}>
            <Droppable droppableId="droppable" direction="vertical">
              {(droppableProvided) => (
                <Grid
                  sx={styles.headerFieldsContainer}
                  ref={droppableProvided.innerRef} 
                  {...droppableProvided.droppableProps}
                >
                {
                  sortFields(fieldSearch(fieldsList, searchQuery))
                  .map((field, i) => {
                    const { key, name } = field;
                    return (
                      <Draggable
                        key={`${key}_${name}`}
                        draggableId={`${key}_${name}`}
                        index={i}
                      >
                        {(draggableProvided) => (
                          <Box ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
                            {renderFieldRow(field, draggableProvided)}
                          </Box>
                        )}
                      </Draggable>
                    );
                  })
                }
                {droppableProvided.placeholder}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
          {Object.keys(sectionFieldsList).map((sectionKey) => (
            <Box key={sectionKey}>
              <DragDropContext onDragEnd={(e) => handleAdjustFieldPosition(e, sectionKey)}>
                <Droppable droppableId="droppable" direction="vertical">
                  {(droppableProvided) => (
                    <Grid
                      sx={styles.sectionFieldsContainer}
                      ref={droppableProvided.innerRef} 
                      {...droppableProvided.droppableProps}
                    >
                    {
                      sortFields(fieldSearch(sectionFieldsList[sectionKey], searchQuery))
                      .map((field, i) => {
                        const { key, name } = field;
                        return (
                          <Draggable
                            key={`${key}_${name}`}
                            draggableId={`${key}_${name}`}
                            index={i}
                          >
                            {(draggableProvided) => (
                              <Box ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
                                {renderFieldRow(field, draggableProvided)}
                              </Box>
                            )}
                          </Draggable>
                        );
                      })
                    }
                    {droppableProvided.placeholder}
                    </Grid>
                  )}
                </Droppable>
              </DragDropContext>
            </Box>
          ))}
        </Grid>
        <Box sx={styles.actionBtnContainer}>
          <CancelBtn
            title={t('DOC_TYPES_TABLE_ADD_NEW_CANCEL')}
            onClick={handleClose}
          />
          <SubmitBtn
            title={t('DOC_TYPES_FIELDS_MANAGE_SAVE')}
            onClick={() => handleSaveFields([...fieldsList, ...Object.keys(sectionFieldsList).map((section) => sectionFieldsList[section]).flat()])}
          />
        </Box>
      </Box>
      <AddNewField
        open={addNewOpen}
        selectedField={selectedField}
        fieldsList={fieldsList}
        sectionFieldsList={sectionFieldsList}
        handleClose={handleAddNewClose}
        setFieldsList={setFieldsList}
        setSectionFieldsList={setSectionFieldsList}
        handleSaveFields={handleSaveFields}
      />
      <APIRef
        open={showAPIRef}
        docType={selectedDocType}
        fieldsList={fieldsList}
        sectionFieldsList={sectionFieldsList}
        handleClose={() => setShowAPIRef(false)}
      />
    </>
  );
};

export default ManageFieldsSideMenu;
