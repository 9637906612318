import React, { useContext, useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FileSaver from 'file-saver';
import Axios from 'axios';
import debounce from 'lodash.debounce';

import { Backdrop, CircularProgress, Grid, Link, Typography } from '@mui/material';

import { invoiceStatusesKeys, maxUploadCount } from 'src/config';
import authService from 'src/utils/authService';
import {
  sleep, isFileMIMETypeHEIC, appendContactSupport, getLocalisedErrorString, axiosHeadersFormData,
  axiosHeaders, validateEmail, sendFeedback, axiosDeleteHeaders,
} from 'src/utils/helpers';
import ConfigContext from 'src/contexts/ConfigContext';
import { useAuth } from 'src/hooks/useAuth';
import {
  axiosHeadersWithArrayBuffer, getConvertedFile, getCurrentTimeInyyyymmdd_hhmmssFormat, getHeader, readFileAsync
} from 'src/dashboard-new/utils';
import {
  CompanyType, CustomError, DocumentDeliveryFormatType, FieldType, DocType, OwnerType, RootState, SelectedDocType,
  SupplierType, UserDataType
} from 'src/types';
import DocumentsTableHeader from 'src/documents-new/components/DocumentsTableHeader/DocumentsTableHeader';
import SearchBar from 'src/documents-new/components/SearchBar/SearchBar';
import DocLimitReachedModal from 'src/documents-new/components/DocLimitReachedModal/DocLimitReachedModal';
import DocumentsTableInList from 'src/documents-new/components/DocumentsTableInList/DocumentsTableInList';
import DocumentsTable from 'src/documents-new/components/DocumentsTable/DocumentsTable';
import BulkOperations from 'src/documents-new/components/BulkOperations/BulkOperations';
import ExportToEmailModal from 'src/document-edit/components/ExportToEmailModal/ExportToEmailModal';
import BulkExportModal from 'src/documents-new/components/BulkExportModal/BulkExportModal';
import ManageExportFieldsNew from 'src/shared/components/ManageExportFieldsNew/ManageExportFieldsNew';
import FiltersModal from 'src/documents-new/components/FiltersModal/FiltersModal';
import BulkSignedDownloadLinkPopup from 'src/documents-new/components/BulkSignedDownloadLinkPopup/BulkSignedDownloadLinkPopup';
import UploadPopup from 'src/documents-new/components/UploadPopup/UploadPopup';
import styles from './style';

interface PropTypes {
  loading: boolean;
  docs: DocType[];
  total: number;
  time: string;
  containsFailedToExport: boolean;
  containsIsDuplicate: boolean;
  containsPossibleDuplicate: boolean;
  startTimeRef: React.MutableRefObject<string>;
  endTimeRef: React.MutableRefObject<string>;
  appliedDocTypes: string[];
  setLoading: (_loading: boolean) => void;
  getDocuments: (
    _thisPage?: string,
    _thisLimit?: string,
    _thisSearch?: string,
    _thisFilter?: string,
    _thisTime?: string,
    _suppliersList?: SupplierType[] | string,
    _ownersList?: OwnerType[] | string,
    _thisStartTime?: string,
    _thisEndTime?: string,
    _thisFailedToExport?: boolean,
    _thisDuplicate?: boolean,
    _thisPossibleDuplicate?: boolean,
    _thisSortBy?: string,
    _thisOrder?: string,
    _companiesList?: CompanyType[] | string[],
    _docTypesList?: Record<string, string>[] | string[],
    _bgRefresh?: boolean,
  ) => Promise<void>;
  onClearInterval: () => void;
  onStartInterval: () => void;
}

const DocumentsList = (props: PropTypes) => {
  const {
    loading,
    docs,
    time,
    total,
    startTimeRef,
    endTimeRef,
    appliedDocTypes,
    setLoading,
    getDocuments,
    onStartInterval,
    onClearInterval,
  } = props;

  const { ready, t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { API, LOGIN_PANEL_URL } = useContext(ConfigContext);
  const { user } = useAuth();

  const isHacienda = user?.integrations.includes('hacienda-certification');

  const [limitModalOpen, setLimitModalOpen] = useState(false);
  const [signedDownloadLinkModalOpen, setSignedDownloadLinkModalOpen] = useState(false);
  const [downloadLink, setDownloadLink] = useState('');

  const [docStatus, setDocStatus] = useState(invoiceStatusesKeys.all);
  const [page, setPage] = useState('0');
  const [limit, setLimit] = useState('10');
  const [query, setQuery] = useState('');

  const [failedToExport, setFailedToExport] = useState(false);
  const [isThisDuplicate, setIsThisDuplicate] = useState(false);
  const [isPossibleDuplicate, setIsPossibleDuplicate] = useState(false);

  const [sortDirection, setSortDirection] = useState('desc');
  const [sortField, setSortField] = useState('');

  const [filteredOwnersList, setFilteredOwnersList] = useState<UserDataType[]>([]);
  const [selectedOwnersList, setSelectedOwnersList] = useState<OwnerType[]>([]);
  const [filteredSuppliersList, setFilteredSuppliersList] = useState<SupplierType[]>([]);
  const [selectedSuppliersList, setSelectedSuppliersList] = useState<SupplierType[]>([]);
  const [filteredCompaniesList, setFilteredCompaniesList] = useState<CompanyType[]>([]);
  const [selectedCompaniesList, setSelectedCompaniesList] = useState<CompanyType[]>([]);
  const [filteredDocTypesList, setFilteredDocTypesList] = useState<Record<string, any>[]>([]);
  const [selectedDocTypesList, setSelectedDocTypesList] = useState<Record<string, any>[]>([]);

  const [periodFilterApplied, setPeriodFilterApplied] = useState(false);
  const [supplierFilterApplied, setSupplierFilterApplied] = useState(false);
  const [ownerFilterApplied, setOwnerFilterApplied] = useState(false);
  const [companyFilterApplied, setCompanyFilterApplied] = useState(false);
  const [docTypeFilterApplied, setDocTypeFilterApplied] = useState(false);

  const [docTypeSearch, setDocTypeSearch] = useState('');
  const [docTypeFilterPage, setDocTypeFilterPage] = useState(0);
  const [docTypeFilterTotal, setDocTypeFilterTotal] = useState(0);

  const [supplierSearch, setSupplierSearch] = useState('');
  const [supplierFilterPage, setSupplierFilterPage] = useState(0);
  const [supplierFilterTotal, setSupplierFilterTotal] = useState(0);

  const [displayDocs, setDisplayDocs] = useState(docs);
  const [selectedDocs, setSelectedDocs] = useState<SelectedDocType[]>([]);

  const [parameterCompany] = useState(user?.companyID || '');

  const [users, setUsers] = useState<UserDataType[]>([]);
  const [managedCompanies, setManagedCompanies] = useState<CompanyType[]>([]);

  const _overViewFields: FieldType[] = useSelector((state: RootState) => state.fields.docOverviewFields);
  const overViewFields: FieldType[] = useMemo(() => _overViewFields, [_overViewFields]);

  const _headerFields: FieldType[] = useSelector((state: RootState) => state.fields.headerFields);
  const headerFields: FieldType[] = useMemo(() => _headerFields, [_headerFields]);

  const _lineFields: FieldType[] = useSelector((state: RootState) => state.fields.lineFields);
  const lineFields: FieldType[] = useMemo(() => _lineFields, [_lineFields]);

  const _supplierFields: FieldType[] = useSelector((state: RootState) => state.fields.supplierFields);
  const supplierFields: FieldType[] = useMemo(() => _supplierFields, [_supplierFields]);

  const [filtersModalOpen, setFiltersModalOpen] = useState(false);

  const [configureFieldsOpen, setConfigureFieldsOpen] = useState<boolean>(false);
  const configureFieldsRef = useRef<HTMLDivElement>(null);

  const [chooseValidatorOpen, setChooseValidatorOpen] = useState<boolean>(false);
  const [selectedValidator, setSelectedValidator] = useState<UserDataType | null>(null);

  const [exportDataModalOpen, setExportDataModalOpen] = useState(false);
  const [selectedDataFormatToExport, setSelectedDataFormatToExport] = useState('');

  const [sendEmailModalOpen, setSendEmailModalOpen] = useState(false);
  const [emails, setEmails] = useState(user?.deliverEmails && user?.deliverEmails?.length > 0 ? user.deliverEmails.join(', ') : user?.email || '');
  const [selectedDataFormatToEmail, setSelectedDataFormatToEmail] = useState(user?.deliverFormat || '');
  const [deliverFormats, setDeliverFormats] = useState<DocumentDeliveryFormatType[]>([]);

  const [showExportFieldMapping, setShowExportFieldMapping] = useState(false);
  const [excelExportFieldMapping, setExcelExportFieldMapping] = useState([]);
  const [datExportFieldMapping, setDatExportFieldMapping] = useState([]);
  const [csvExportFieldMapping, setCsvExportFieldMapping] = useState([]);

  const [showCheckboxDropdown, setShowCheckboxDropdown] = useState(false);
  const [showUploadPopup, setShowUploadPopup] = useState(false);

  const noSupplierObj = {
    creditorCode: '__EMPTY__',
    creditorName: t('INVOICE_LIST_NO_SUPPLIER_OPTION')
  };

  const handleOpenFilters = () => {
    setFiltersModalOpen(true);
  };

  const handleCheckboxDropdownToggle = () => {
    setShowCheckboxDropdown(!showCheckboxDropdown);
  };

  const handleCheckboxDropdownClose = () => {
    setShowCheckboxDropdown(false);
  };

  const handleSendEmailModalClose = () => {
    setSendEmailModalOpen(false);
  };

  const handleExportFieldMappingClose = async (updated?: boolean) => {
    setShowExportFieldMapping(false);
    if (updated) {
      setTimeout(async () => {
        await getExcelExportFieldMapping();
        await getDatExportFieldMapping();
        await getCsvExportFieldMapping();
        handleSendToExportClick(true);
      }, 1000);
    }
  };

  useEffect(() => {
    setTimeout(() => setDisplayDocs(docs), 100);
  }, [docs]);

  const executeGetInvoices = (
    ty: string,
    p: string,
    l: string,
    q: string,
    tm: string,
    sp: SupplierType[],
    ow: OwnerType[],
    st: string,
    et: string,
    ef: boolean,
    dc: boolean,
    pd: boolean,
    sb: string,
    or: string,
    cm: CompanyType[],
    dt: Record<string, any>[],
    bg?: boolean,
  ) => {
    getDocuments(p, l, q, ty, tm, sp, ow, st, et, ef, dc, pd, sb, or, cm, dt, bg);
  };

  useEffect(() => {
    getDocTypes();
    getUsers();
    getSuppliers();
    getCompaniesManaged();
    getExcelExportFieldMapping();
    getDatExportFieldMapping();
    getCsvExportFieldMapping();
    getDeliverFormats();
    const queryVal = new URLSearchParams(location.search);
    let period = queryVal.get('time');
    const status = queryVal.get('status');
    const qury = queryVal.get('query');
    const pageNo = queryVal.get('page');
    const pagesLimit = queryVal.get('limit');
    const supplierFilter = queryVal.get('supplier_filter');
    const ownerFilter = queryVal.get('owner_filter');
    const companyFilter = queryVal.get('company_filter');
    const docTypeFilter = queryVal.get('doc_type_filter');
    const documentsSelected = queryVal.get('documents_selected');
    const startTime = queryVal.get('start_time');
    const endTime = queryVal.get('end_time');
    const ifailedToExport = queryVal.get('failed_to_export');
    const duplicateDocumentFilter = queryVal.get('duplicate_documents');
    const possibleDuplicateFilter = queryVal.get('possible_duplicates');
    const sortBy = queryVal.get('sort_by');
    const order = queryVal.get('order');

    const decodedQuery = decodeURIComponent(qury || '');

    const selectedInvoicesFromSession = JSON.parse(window.sessionStorage.getItem('selectedInvoices') || '[]');
    if (typeof documentsSelected === 'string' && JSON.parse(documentsSelected) && selectedInvoicesFromSession.length > 0) {
      setSelectedDocs(selectedInvoicesFromSession);
    } else if (typeof documentsSelected !== 'string' || !JSON.parse(documentsSelected)) {
      setSelectedDocs([]);
      window.sessionStorage.removeItem('selectedInvoices');
    }

    let selectedSuppliersFromSession = JSON.parse(window.sessionStorage.getItem('selectedSuppliers') || '[]');
    if (typeof supplierFilter === 'string' && JSON.parse(supplierFilter) && selectedSuppliersFromSession.length > 0) {
      setSelectedSuppliersList(selectedSuppliersFromSession);
      setSupplierFilterApplied(true);
    } else if (typeof supplierFilter !== 'string' || !JSON.parse(supplierFilter)) {
      selectedSuppliersFromSession = [];
      window.sessionStorage.removeItem('selectedSuppliers');
    }

    let selectedDocTypesFromSession = JSON.parse(window.sessionStorage.getItem('selectedDocTypes') || '[]');
    if (typeof docTypeFilter === 'string' && JSON.parse(docTypeFilter) && selectedDocTypesFromSession.length > 0) {
      setSelectedDocTypesList(selectedDocTypesFromSession);
      setDocTypeFilterApplied(true);
    } else if (typeof docTypeFilter !== 'string' || !JSON.parse(docTypeFilter)) {
      selectedDocTypesFromSession = [];
      window.sessionStorage.removeItem('selectedDocTypes');
    }

    let selectedOwnersFromSession = JSON.parse(window.sessionStorage.getItem('selectedOwners') || '[]');
    if (typeof ownerFilter === 'string' && JSON.parse(ownerFilter) && selectedOwnersFromSession.length > 0) {
      setSelectedOwnersList(selectedOwnersFromSession);
      setOwnerFilterApplied(true);
    } else if (typeof ownerFilter !== 'string' || !JSON.parse(ownerFilter)) {
      selectedOwnersFromSession = [];
      window.sessionStorage.removeItem('selectedOwners');
    }

    let selectedCompaniesFromSession = JSON.parse(window.sessionStorage.getItem('selectedCompanies') || '[]');
    if (typeof companyFilter === 'string' && JSON.parse(companyFilter) && selectedCompaniesFromSession.length > 0) {
      setSelectedCompaniesList(selectedCompaniesFromSession);
      setCompanyFilterApplied(true);
    } else if (typeof companyFilter !== 'string' || !JSON.parse(companyFilter)) {
      selectedCompaniesFromSession = [];
      window.sessionStorage.removeItem('selectedCompanies');
    }

    if (startTime !== null && endTime !== null && !Number.isNaN(parseInt(startTime)) && !Number.isNaN(parseInt(endTime))) {
      period = 'custom';
    }
    if (period !== null && period !== 'all') {
      setPeriodFilterApplied(true);
    }
    if (qury) {
      setQuery(decodeURIComponent(decodedQuery));
      onClearInterval();
    }

    if (status) {
      setDocStatus(status);
    }
    if (pageNo) {
      setPage(pageNo);
    }
    if (pagesLimit) {
      setLimit(pagesLimit);
    }
    if (typeof ifailedToExport === 'string') {
      setFailedToExport(JSON.parse(ifailedToExport));
    }
    if (typeof duplicateDocumentFilter === 'string') {
      setIsThisDuplicate(JSON.parse(duplicateDocumentFilter));
    }
    if (typeof possibleDuplicateFilter === 'string') {
      setIsPossibleDuplicate(JSON.parse(possibleDuplicateFilter));
    }
    if (sortBy) {
      setSortField(sortBy);
    }
    if (order) {
      setSortDirection(order);
    }

    executeGetInvoices(
      status || docStatus,
      pageNo || page,
      pagesLimit || limit,
      decodedQuery || query,
      period || time,
      selectedSuppliersFromSession || selectedSuppliersList,
      selectedOwnersFromSession || selectedOwnersList,
      startTime || '',
      endTime || '',
      typeof ifailedToExport === 'string' ? JSON.parse(ifailedToExport) : null,
      typeof duplicateDocumentFilter === 'string' ? JSON.parse(duplicateDocumentFilter) : null,
      typeof possibleDuplicateFilter === 'string' ? JSON.parse(possibleDuplicateFilter) : null,
      sortBy || sortField,
      order || sortDirection,
      selectedCompaniesFromSession || selectedCompaniesList,
      selectedDocTypesFromSession || selectedDocTypesList,
    );
  }, [])

  useEffect(() => {
    if (user?.deliverEmails && user.deliverEmails.length > 0) {
      setEmails(user.deliverEmails.join(', '));
    }

    if (user?.deliverFormat) {
      setSelectedDataFormatToEmail(user.deliverFormat);
    } else {
      const activeDeliverFormat = deliverFormats.find((o) => o.active);
      setSelectedDataFormatToEmail(activeDeliverFormat ? activeDeliverFormat.key : deliverFormats[0]?.key);
    }
  }, [user, deliverFormats]);

  const handleApplyFilters = (
    _docStatus: string,
    _period: string,
    _startTime: number,
    _endTime: number,
    _selectedSuppliersList: SupplierType[],
    _selectedOwnersList: OwnerType[],
    _selectedCompaniesList: CompanyType[],
    _selectedDocTypesList: Record<string, any>[],
  ) => {
    const queryVal = new URLSearchParams(location.search);
    const ifailedToExport = queryVal.get('failed_to_export');
    const duplicateDocumentFilter = queryVal.get('duplicate_documents');
    const possibleDuplicateFilter = queryVal.get('possible_duplicates');
    setDocStatus(_docStatus);
    setSelectedSuppliersList(_selectedSuppliersList);
    setSelectedOwnersList(_selectedOwnersList);
    setSelectedCompaniesList(_selectedCompaniesList);
    setSelectedDocTypesList(_selectedDocTypesList);
    setPage('0');

    executeGetInvoices(
      _docStatus,
      '0',
      limit,
      query,
      _period,
      _selectedSuppliersList,
      _selectedOwnersList,
      _period === 'custom' && _startTime !== 0 ? _startTime.toString() : '',
      _period === 'custom' && _endTime !== 0 ? _endTime.toString() : '',
      typeof ifailedToExport === 'string' ? JSON.parse(ifailedToExport) : null,
      typeof duplicateDocumentFilter === 'string' ? JSON.parse(duplicateDocumentFilter) : null,
      typeof possibleDuplicateFilter === 'string' ? JSON.parse(possibleDuplicateFilter) : null,
      sortField,
      sortDirection,
      _selectedCompaniesList,
      _selectedDocTypesList,
    );

    const url = new URL(window.location.href);

    // update page query param
    if (url.searchParams.has('page')) {
      url.searchParams.set('page', '0');
    } else {
      url.searchParams.append('page', '0');
    }

    // update document status query param
    if (docStatus !== _docStatus && _docStatus !== '') {
      if (url.searchParams.has('status')) {
        url.searchParams.set('status', _docStatus);
      } else {
        url.searchParams.append('status', _docStatus);
      }
    } else if (_docStatus === '' && url.searchParams.has('status')) {
      url.searchParams.delete('status');
    }

    // update period related query params
    if (_period !== 'all' && _period !== '') {
      setPeriodFilterApplied(true);
      if (url.searchParams.has('filter')) {
        url.searchParams.set('filter', _period);
      } else {
        url.searchParams.append('filter', _period);
      }
    } else {
      setPeriodFilterApplied(false);
      if (url.searchParams.has('filter')) {
        url.searchParams.delete('filter');
      }
    }
    if (_period !== 'custom') {
      if (url.searchParams.has('start_time')) {
        url.searchParams.delete('start_time');
      }
      if (url.searchParams.has('end_time')) {
        url.searchParams.delete('end_time');
      }
    } else {
      if (url.searchParams.has('start_time')) {
        url.searchParams.set('start_time', _startTime.toString());
      } else {
        url.searchParams.append('start_time', _startTime.toString());
      }
      if (url.searchParams.has('end_time')) {
        url.searchParams.set('end_time', _endTime.toString());
      } else {
        url.searchParams.append('end_time', _endTime.toString());
      }
    }

    // update docType filter related query params
    if (_selectedDocTypesList.length) {
      setDocTypeFilterApplied(true);
      url.searchParams.set('doc_type_filter', JSON.stringify(true));
      window.sessionStorage.setItem('selectedDocTypes', JSON.stringify(_selectedDocTypesList));
    } else {
      setDocTypeFilterApplied(false);
      url.searchParams.delete('doc_type_filter');
      window.sessionStorage.removeItem('selectedDocTypes');
    }

    // update supplier filter related query params
    if (_selectedSuppliersList.length) {
      setSupplierFilterApplied(true);
      url.searchParams.set('supplier_filter', JSON.stringify(true));
      window.sessionStorage.setItem('selectedSuppliers', JSON.stringify(_selectedSuppliersList));
    } else {
      setSupplierFilterApplied(false);
      url.searchParams.delete('supplier_filter');
      window.sessionStorage.removeItem('selectedSuppliers');
    }

    // update owner filter related query params
    if (_selectedOwnersList.length) {
      setOwnerFilterApplied(true);
      url.searchParams.set('owner_filter', JSON.stringify(true));
      window.sessionStorage.setItem('selectedOwners', JSON.stringify(_selectedOwnersList));
    } else {
      setOwnerFilterApplied(false);
      url.searchParams.delete('owner_filter');
      window.sessionStorage.removeItem('selectedOwners');
    }

    // update company filter related query params
    if (_selectedCompaniesList.length) {
      setCompanyFilterApplied(true);
      url.searchParams.set('company_filter', JSON.stringify(true));
      window.sessionStorage.setItem('selectedCompanies', JSON.stringify(_selectedCompaniesList));
    } else {
      setCompanyFilterApplied(false);
      url.searchParams.delete('company_filter');
      window.sessionStorage.removeItem('selectedCompanies');
    }

    url.toString();
    window.history.pushState({}, '', url);
    handleSelectedDocsChange([]);
    setFiltersModalOpen(false);
  };

  const getUsers = async () => {
    try {
      const url = `${API.getUsersByCompanies}${encodeURIComponent(user?.companyID || '')}`;
      const resp = await Axios.get(url, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (resp.data.success && resp.data.data?.length > 0) {
        setUsers(resp.data.data.filter((u: UserDataType) => u.status === 'Active'));
        setFilteredOwnersList(resp.data.data.filter((u: UserDataType) => u.status === 'Active'));
      }
    } catch (error) {
      setUsers([]);
      setFilteredOwnersList([]);
    }
  };

  const getDocTypes = async (search: string = '', sPage: number = 0) => {
    setDocTypeSearch(search);
    setDocTypeFilterPage(sPage);
    try {
      const response = await Axios.get(
        `${API.documentTypesMin}?page=${sPage + 1}&limit=10&search=${search}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setDocTypeFilterTotal(response.data.total ? response.data.total : 0);
        setFilteredDocTypesList(response.data.data);
      }
    } catch (error) {
      setFilteredDocTypesList([]);
    }
  };

  const getSuppliers = async (search: string = '', sPage: number = 0) => {
    setSupplierSearch(search);
    setSupplierFilterPage(sPage);
    try {
      const response = await Axios.get(
        `${API.findSuppliers}/${parameterCompany}?search=${search}&limit=10&page=${sPage + 1}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setSupplierFilterTotal(response.data.total ? response.data.total : 0);
        if (search !== '' || sPage !== 0) {
          setFilteredSuppliersList(response.data.data);
        } else {
          setFilteredSuppliersList([
            noSupplierObj,
            ...response.data.data]);
        }
      }
    } catch (error) {
      setFilteredSuppliersList([noSupplierObj]);
    }
  };

  const getCompaniesManaged = async () => {
    try {
      const response = await Axios.get(
        `${API.companiesManaged}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setManagedCompanies(response.data.data);
        setFilteredCompaniesList(response.data.data);
      }
    } catch (error) {
      setManagedCompanies([]);
      setFilteredCompaniesList([]);
    }
  };

  const getDeliverFormats = async () => {
    try {
      const response = await Axios.get(
        API.getDeliverFormats,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setDeliverFormats(response.data.data);
        }
      }
    } catch (error) {
      setDeliverFormats([]);
    }
  };

  const getExcelExportFieldMapping = async () => {
    try {
      const response = await Axios.get(
        `${API.exportFieldMapping}excel`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setExcelExportFieldMapping(response.data.data);
          return;
        }
      }
      setExcelExportFieldMapping([]);
    } catch (error) {
      setExcelExportFieldMapping([]);
    }
  };

  const getDatExportFieldMapping = async () => {
    try {
      const response = await Axios.get(
        `${API.exportFieldMapping}dat`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setDatExportFieldMapping(response.data.data);
          return;
        }
      }
      setDatExportFieldMapping([]);
    } catch (error) {
      setDatExportFieldMapping([]);
    }
  };

  const getCsvExportFieldMapping = async () => {
    try {
      const response = await Axios.get(
        `${API.exportFieldMapping}csv`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setCsvExportFieldMapping(response.data.data);
          return;
        }
      }
      setCsvExportFieldMapping([]);
    } catch (error) {
      setCsvExportFieldMapping([]);
    }
  };

  const refreshDocuments = () => {
    executeGetInvoices(
      docStatus,
      page,
      limit,
      query,
      time,
      selectedSuppliersList,
      selectedOwnersList,
      startTimeRef.current,
      endTimeRef.current,
      failedToExport,
      isThisDuplicate,
      isPossibleDuplicate,
      sortField,
      sortDirection,
      selectedCompaniesList,
      selectedDocTypesList,
    );
  };

  const goToLogsViaSnackbar = (path: string) => {
    closeSnackbar('snack_with_link');
    navigate(path);
  };

  const validateDocument = (nameParts: string[], file: File) => {
    if (nameParts[0].length > 200) {
      enqueueSnackbar(t('PROCYS_VALIDATE_FILE_NAME'), {
        variant: 'error',
      });
      return false;
    }

    if (file.size > 30 * 1024 * 1024) {
      enqueueSnackbar(t('PROCYS_VALIDATE_FILE_SIZE'), {
        variant: 'error',
      });
      return false;
    }

    return true;
  };

  const sendDocument = async (header: string, file: File, documentType: string) => {
    try {
      let f: File | Blob = file;
      if (file && isFileMIMETypeHEIC(header, file.type)) {
        f = await getConvertedFile(file);
      }

      const data: any = new FormData();
      data.append('datafile', f);
      data.append('customerId', user?.id || 1111);
      data.append('documentType', documentType);

      const response = await Axios.post(
        API.uploadDocument,
        data,
        axiosHeadersFormData(localStorage.getItem('PROCYS_accessToken'), data)
      );

      if (response.data.success) {
        executeGetInvoices(
          docStatus,
          page,
          limit,
          query,
          time,
          selectedSuppliersList,
          selectedOwnersList,
          startTimeRef.current,
          endTimeRef.current,
          failedToExport,
          isThisDuplicate,
          isPossibleDuplicate,
          sortField,
          sortDirection,
          selectedCompaniesList,
          selectedDocTypesList,
        );
        enqueueSnackbar(t('PROCYS_FILE_UPLOAD_SUCCESS'), {
          variant: 'success',
        });

        if (user?.totalInvoices === 49) {
          const currentUrlParams = new URLSearchParams(window.location.search);
          currentUrlParams.set('feedback-upload-fifty', 'success');
          navigate(`${window.location.pathname}?${currentUrlParams.toString()}`);
        }
      }
      setLoading(false);
    } catch (e) {
      const error = e as CustomError;
      enqueueSnackbar(appendContactSupport(
        window.config.support_email,
        getLocalisedErrorString(error?.response?.data?.i18n || 'PROCYS_FILE_UPLOAD_FAILURE', t),
        t
      ), {
        variant: 'error',
      });
      setLoading(false);
    }
  };

  const sendDocumentBulk = async (file: File, documentType: string) => {
    try {
      const data: any = new FormData();
      data.append('datafile', file);
      data.append('customerId', user?.id || 1111);
      data.append('documentType', documentType);

      const response = await Axios.post(
        API.uploadDocument,
        data,
        axiosHeadersFormData(localStorage.getItem('PROCYS_accessToken'), data)
      );
      return response;
    } catch (err) {
      //
    }
    return { data: { success: false } };
  };

  const uploadDocument = async (file: File, documentType: string) => {
    try {
      if (file) {
        const fileReader = new FileReader();
        fileReader.onloadend = async (e) => {
          const header = getHeader(e);
          await sendDocument(header, file, documentType);
        };
        fileReader.readAsArrayBuffer(file);
      } else {
        setLoading(false);
      }
    } catch (e) {
      const error = e as CustomError;
      enqueueSnackbar(appendContactSupport(
        window.config.support_email,
        getLocalisedErrorString(error?.response?.data?.i18n || 'PROCYS_FILE_UPLOAD_FAILURE', t),
        t
      ), {
        variant: 'error',
      });
      setLoading(false);
    }
  };

  const handleUpload = async (files: File[] | null, docType: string) => {
    setLoading(true);

    if (!authService.validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
      });
      setTimeout(() => {
        setLoading(false);
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    let limitExceeded = false;
    if (
      user
      && user.maxInvoices > 0
      && (user.maxInvoices <= user.invoicesCount || user.maxInvoices < user.invoicesCount + (files?.length || 0))) {
      limitExceeded = true;
    }

    if (files && files.length === 1) {
      const file = files[0];

      const nameParts = file.name.split('.');
      const isValid = validateDocument(nameParts, file);
      if (!isValid) {
        setLoading(false);
        return;
      }

      uploadDocument(file, docType);
    }

    if (files && files.length > maxUploadCount) {
      enqueueSnackbar(t('PROCYS_VALIDATE_FILE_COUNT'), {
        variant: 'error',
      });
      setLoading(false);
      return;
    }

    if (files && files.length > 1) {
      for (let i = 0; i < files.length; i++) {
        const nameParts = files[i].name.split('.');
        const isValid = validateDocument(nameParts, files[i]);
        if (!isValid) {
          setLoading(false);
          return;
        }
      }

      let uploadedCount = 0;
      for (let i = 0; i < files.length; i++) {
        enqueueSnackbar(t('PROCYS_UPLOADING_COUNT', { count: i + 1, total: files.length }), {
          variant: 'success',
          autoHideDuration: 2500
        });

        try {
          if (files[i]?.type === 'application/pdf') {
            const response = await sendDocumentBulk(files[i], docType);
            if (response.data.success) {
              uploadedCount++;
              if (i !== files.length - 1) {
                await sleep(500);
              }
            }
          } else {
            const header = await readFileAsync(files[i]);
            let f: File | Blob = files[i];
            if (isFileMIMETypeHEIC(header, files[i].type)) {
              f = await getConvertedFile(files[i]);
            }

            const response = await sendDocumentBulk(f as File, docType);
            if (response.data.success) {
              uploadedCount++;
              if (i !== files.length - 1) {
                await sleep(500);
              }
            }
          }
        } catch (error) {
          //
        }
      }

      if (uploadedCount <= 0) {
        enqueueSnackbar(appendContactSupport(window.config.support_email, t('PROCYS_NETWORK_ERROR_UPLOAD_INVOICE'), t), {
          variant: 'error',
        });
        setLoading(false);
        return;
      }

      enqueueSnackbar(
        <Typography sx={styles.bulkUploadSuccessText}>
          <Trans i18nKey="PROCYS_FILES_UPLOAD_SUCCESS">
            Uploaded
            {' '}
            {{ uploadedCount }}
            {' '}
            out of
            {' '}
            {{ total: files.length }}
            {' '}
            documents successfully, check errors
            {' '}
            <b style={styles.bulkUploadSuccessLink} onClick={() => goToLogsViaSnackbar('/documents/logs')}>here</b>
          </Trans>
        </Typography>, {
        variant: 'success',
        key: 'snack_with_link',
        style: styles.snackbarContainer,
      }
      );
      executeGetInvoices(
        docStatus,
        page,
        limit,
        query,
        time,
        selectedSuppliersList,
        selectedOwnersList,
        startTimeRef.current,
        endTimeRef.current,
        failedToExport,
        isThisDuplicate,
        isPossibleDuplicate,
        sortField,
        sortDirection,
        selectedCompaniesList,
        selectedDocTypesList,
      );
      setLoading(false);
    }

    if (limitExceeded) {
      setLimitModalOpen(true);
    }
  };

  const handleSelectedDocsChange = (newlySelectedInvoices: SelectedDocType[]) => {
    setSelectedDocs(newlySelectedInvoices);
    window.sessionStorage.setItem('selectedInvoices', JSON.stringify(newlySelectedInvoices));
  };

  const isSelected = (id: number) => selectedDocs.some((i) => i.id === id);

  const handleSelectOneDoc = (
    event: React.ChangeEvent<HTMLInputElement>,
    assistantID: string,
    status: number,
    id: number,
    exportedToGstock: boolean,
    isDuplicate: boolean,
    closedPeriod: string,
  ) => {
    event.stopPropagation();
    if (selectedDocs.length < 1 || selectedDocs === undefined) {
      handleSelectedDocsChange([{
        assistantID, status, id, exportedToGstock, isDuplicate, closedPeriod
      }]);
    } else if (!isSelected(id)) {
      handleSelectedDocsChange([...selectedDocs, {
        assistantID, status, id, exportedToGstock, isDuplicate, closedPeriod
      }]);
    } else {
      handleSelectedDocsChange(selectedDocs.filter((i) => i.id !== id));
    }
  };

  const handlePageChange = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage.toString());
    setDisplayDocs([]);

    executeGetInvoices(
      docStatus,
      newPage.toString(),
      limit,
      query,
      time,
      selectedSuppliersList,
      selectedOwnersList,
      startTimeRef.current,
      endTimeRef.current,
      failedToExport,
      isThisDuplicate,
      isPossibleDuplicate,
      sortField,
      sortDirection,
      selectedCompaniesList,
      selectedDocTypesList,
    );

    const url = new URL(window.location.href);
    if (url.searchParams.has('page')) {
      url.searchParams.set('page', newPage.toString());
    } else {
      url.searchParams.append('page', newPage.toString());
    }
    url.toString();
    window.history.pushState({}, '', url);
  };

  const handleLimitChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setLimit(event.target.value);
    setPage('0');

    executeGetInvoices(
      docStatus,
      '0',
      event.target.value.toString(),
      query,
      time,
      selectedSuppliersList,
      selectedOwnersList,
      startTimeRef.current,
      endTimeRef.current,
      failedToExport,
      isThisDuplicate,
      isPossibleDuplicate,
      sortField,
      sortDirection,
      selectedCompaniesList,
      selectedDocTypesList,
    );

    const url = new URL(window.location.href);
    if (url.searchParams.has('limit')) {
      url.searchParams.set('limit', event.target.value);
    } else {
      url.searchParams.append('limit', event.target.value);
    }
    if (url.searchParams.has('page')) {
      url.searchParams.set('page', '0');
    } else {
      url.searchParams.append('page', '0');
    }
    url.toString();

    window.history.pushState({}, '', url);
  };

  const debouncedSearchDocuments = useCallback(
    debounce((searchValue: string) => {
      const encoded = encodeURIComponent(searchValue);
      const url = new URL(window.location.href);
      if (searchValue !== null) {
        if (url.searchParams.has('query')) {
          url.searchParams.set('query', encoded);
        } else {
          url.searchParams.append('query', encoded);
        }
      }
      url.toString();

      executeGetInvoices(
        docStatus,
        '0',
        limit,
        searchValue,
        time,
        selectedSuppliersList,
        selectedOwnersList,
        startTimeRef.current,
        endTimeRef.current,
        failedToExport,
        isThisDuplicate,
        isPossibleDuplicate,
        sortField,
        sortDirection,
        selectedCompaniesList,
        selectedDocTypesList,
      );
      window.history.pushState({}, '', url);
    }, 500),
    [docStatus, limit, time, selectedSuppliersList, selectedOwnersList,
      failedToExport, isThisDuplicate, isPossibleDuplicate, sortField,
      sortDirection, selectedCompaniesList, selectedDocTypesList]
  );

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    event.persist();

    setQuery(value);
    setPage('0');

    if (!value) {
      onStartInterval();
    } else {
      onClearInterval();
    }

    setDisplayDocs([]);
    handleSelectedDocsChange([]);

    debouncedSearchDocuments(value);
  };

  const handleSort = (field: string) => {
    const newSortField = (sortField === field) && sortDirection === 'asc' ? '' : field;
    const order = newSortField === sortField ? 'asc' : 'desc';
    executeGetInvoices(
      docStatus,
      page,
      limit,
      query,
      time,
      selectedSuppliersList,
      selectedOwnersList,
      startTimeRef.current,
      endTimeRef.current,
      failedToExport,
      isThisDuplicate,
      isPossibleDuplicate,
      newSortField,
      order,
      selectedCompaniesList,
      selectedDocTypesList,
    );
    setSortDirection(order);
    setSortField(newSortField);

    const url = new URL(window.location.href);
    if (newSortField) {
      if (url.searchParams.has('sort_by')) {
        url.searchParams.set('sort_by', field);
      } else {
        url.searchParams.append('sort_by', field);
      }
    } else {
      url.searchParams.delete('sort_by');
    }

    if (url.searchParams.has('order')) {
      url.searchParams.set('order', order);
    } else {
      url.searchParams.append('order', order);
    }

    url.toString();
    window.history.pushState({}, '', url);
  };

  const handleStatusFilterChange = (s: string) => {
    let value = s;
    const url = new URL(window.location.href);

    // If the status is already selected, clear the filter
    if (docStatus === s) {
      value = '';
      setDocStatus(invoiceStatusesKeys.all);

      if (url.searchParams.has('status')) {
        url.searchParams.delete('status');
      }
    } else {
      setDocStatus(s);

      if (url.searchParams.has('status')) {
        url.searchParams.set('status', value);
      } else {
        url.searchParams.append('status', value);
      }
    }

    executeGetInvoices(
      value,
      '0',
      limit,
      query,
      time,
      selectedSuppliersList,
      selectedOwnersList,
      startTimeRef.current,
      endTimeRef.current,
      false,
      isThisDuplicate,
      isPossibleDuplicate,
      sortField,
      sortDirection,
      selectedCompaniesList,
      selectedDocTypesList,
    );

    setPage('0');
    setFailedToExport(false);
    setIsThisDuplicate(false);
    setIsPossibleDuplicate(false);

    if (url.searchParams.has('page')) {
      url.searchParams.delete('page');
    }
    if (url.searchParams.has('failed_to_export')) {
      url.searchParams.delete('failed_to_export');
    }
    if (url.searchParams.has('duplicate_documents')) {
      url.searchParams.delete('duplicate_documents');
    }
    if (url.searchParams.has('possible_duplicates')) {
      url.searchParams.delete('possible_duplicates');
    }
    url.toString();

    window.history.pushState({}, '', url);
    handleSelectedDocsChange([]);
  };

  const handleChangeBulkStatus = async (to: number) => {
    if (to === 501 && !chooseValidatorOpen) {
      setChooseValidatorOpen(true);
      return;
    }
    try {
      const body: any = {
        company: user?.companyID,
        status: to.toString(),
        documents: selectedDocs.filter((doc) => doc.id !== null).map((doc) => doc.id)
      };

      if (to === 501 && selectedValidator && selectedValidator.email) {
        body.assignee = selectedValidator.email;
      }

      const response = await Axios.post(
        API.bulkDocumentStatus,
        body,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        // Fetch invoices list and stats.
        executeGetInvoices(
          docStatus,
          page,
          limit,
          query,
          time,
          selectedSuppliersList,
          selectedOwnersList,
          startTimeRef.current,
          endTimeRef.current,
          failedToExport,
          isThisDuplicate,
          isPossibleDuplicate,
          sortField,
          sortDirection,
          selectedCompaniesList,
          selectedDocTypesList,
        );
        enqueueSnackbar(t(user?.isDocunecta && to === 503 ? 'DOCUNECTA_EXPORT_SUCCESS' : 'PROCYS_STATUS_CHANGE_SUCCESS'), {
          variant: 'success',
        });
        handleSelectedDocsChange([]);
        setChooseValidatorOpen(false);
      }
    } catch (e) {
      const error = e as CustomError;
      executeGetInvoices(
        docStatus,
        page,
        limit,
        query,
        time,
        selectedSuppliersList,
        selectedOwnersList,
        startTimeRef.current,
        endTimeRef.current,
        failedToExport,
        isThisDuplicate,
        isPossibleDuplicate,
        sortField,
        sortDirection,
        selectedCompaniesList,
        selectedDocTypesList,
      );
      enqueueSnackbar(appendContactSupport(window.config.support_email,
        getLocalisedErrorString(error?.response?.data?.i18n
          || (user?.isDocunecta && to === 503 ? 'DOCUNECTA_EXPORT_FAILURE' : 'INVOICE_STATUS_CHANGE_FAILED'), t),
        t), {
        variant: 'error',
      });
    }
  };

  const handleSendToEmailClick = async () => {
    const ids = [];
    for (let i = 0; i < selectedDocs.length; i++) {
      if (selectedDocs[i].id) {
        ids.push(selectedDocs[i].id);
      }
    }
    if (!emails || !emails.trim()) {
      enqueueSnackbar(t('PROCYS_VALIDATE_EMAIL_EMPTY'), {
        variant: 'error',
        autoHideDuration: 5000
      });
      return;
    }
    const emailsList = [];
    if (emails.indexOf(',') >= 0) {
      const emailsAr = emails.split(',');
      for (let i = 0; i < emailsAr.length; i++) {
        if (emailsAr[i] && emailsAr[i].trim()) {
          const resp = validateEmail(emailsAr[i].trim());
          if (!resp.isValid) {
            enqueueSnackbar(t('PROCYS_VALIDATE_EMAIL_INVALID'), {
              variant: 'error',
              autoHideDuration: 5000
            });
            return;
          }

          emailsList.push(emailsAr[i]);
        }
      }
    } else {
      const resp = validateEmail(emails.trim());
      if (!resp.isValid) {
        enqueueSnackbar(t(resp.errorMessage || 'PROCYS_VALIDATE_EMAIL_INVALID'), {
          variant: 'error',
          autoHideDuration: 5000
        });
        return;
      }

      emailsList.push(emails.trim());
    }

    try {
      setLoading(true);
      await Axios.post(
        `${API.exportToEmail}${selectedDataFormatToEmail}`,
        {
          ids,
          emails: emailsList
        },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      executeGetInvoices(
        docStatus,
        page,
        limit,
        query,
        time,
        selectedSuppliersList,
        selectedOwnersList,
        startTimeRef.current,
        endTimeRef.current,
        failedToExport,
        isThisDuplicate,
        isPossibleDuplicate,
        sortField,
        sortDirection,
        selectedCompaniesList,
        selectedDocTypesList,
      );
      sendFeedback(API.feedbackLogs, 'invoice export', user?.email, user?.companyID);
      handleSelectedDocsChange([]);
      enqueueSnackbar(t('INVOICE_SEND_TO_EMAIL_SUCCESS'), {
        variant: 'success',
      });
      setLoading(false);
    } catch (e) {
      const error = e as CustomError;
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, getLocalisedErrorString(error?.response?.data?.i18n || 'INVOICE_SEND_TO_EMAIL_FAILURE', t), t),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
      setLoading(false);
    }

    handleSendEmailModalClose();
  };

  const handleSendToExportClick = async (executeExport = false) => {
    setLoading(true);
    const ids = [];
    for (let i = 0; i < selectedDocs.length; i++) {
      if (selectedDocs[i].id) {
        ids.push(selectedDocs[i].id);
      }
    }

    const header = axiosHeaders(localStorage.getItem('PROCYS_accessToken'));

    try {
      if (((selectedDataFormatToExport === 'excel' && excelExportFieldMapping.length === 0)
        || (selectedDataFormatToExport === 'dat' && datExportFieldMapping.length === 0)
        || (selectedDataFormatToExport === 'csv' && csvExportFieldMapping.length === 0))
        && !executeExport) {
        setLoading(false);
        setExportDataModalOpen(false);
        setShowExportFieldMapping(true);
        return;
      }

      const response = await Axios.post(
        `${API.exportBulk}${selectedDataFormatToExport}`,
        { ids },
        {
          ...header,
          timeout: 300000
        }
      );

      if (response.data.success) {
        if (
          selectedDataFormatToExport !== 'gstock'
          && selectedDataFormatToExport !== 'quickbooks'
          && selectedDataFormatToExport !== 'holded'
        ) {
          enqueueSnackbar(
            <Typography style={styles.bulkUploadSuccessText}>
              <Trans i18nKey="DOCUMENT_BULK_EXPORT_STARTED_SUCCESS">
                {/* eslint-disable-next-line max-len */}
                The export is started. When it is finished, we&apos;ll send the exported file to your email. You can also find all your export requests with the files in
                {' '}
                <Link style={styles.bulkUploadSuccessLink} onClick={() => goToLogsViaSnackbar('/documents/export/logs')}>"Export logs"</Link>
              </Trans>
            </Typography>, {
            variant: 'success',
            key: 'snack_with_link',
            style: styles.snackbarContainer,
          }
          );
        } else {
          enqueueSnackbar(
            <Typography style={styles.bulkUploadSuccessText}>
              {t('DOCUMENT_BULK_EXPORT_STARTED_WILL_UPDATE_DOCUMENTS')}
            </Typography>, {
            variant: 'success',
            style: styles.snackbarContainer,
          }
          );
        }
        executeGetInvoices(
          docStatus,
          page,
          limit,
          query,
          time,
          selectedSuppliersList,
          selectedOwnersList,
          startTimeRef.current,
          endTimeRef.current,
          failedToExport,
          isThisDuplicate,
          isPossibleDuplicate,
          sortField,
          sortDirection,
          selectedCompaniesList,
          selectedDocTypesList,
        );
        sendFeedback(API.feedbackLogs, 'invoice export', user?.email, user?.companyID);
      }
      handleSelectedDocsChange([]);
      setLoading(false);
    } catch (e) {
      const error = e as CustomError;
      setLoading(false);
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, getLocalisedErrorString(error?.response?.data?.i18n || 'INVOICE_EXPORT_DATA_FAILURE', t), t), {
        variant: 'error',
      });
    }

    setExportDataModalOpen(false);
  };

  const downloadInvoicesBulk = async () => {
    setDownloadLink('');
    setLoading(true);
    const selectedAsstIDs = selectedDocs.filter((i) => i.assistantID !== null).map((i) => i.assistantID);
    if (isHacienda) {
      try {
        const response = await Axios.post(
          `${API.bulkSignedDownload}`,
          { documents: selectedAsstIDs },
          axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
        );
        if (response.data.success) {
          setDownloadLink(response.data.data);
          setSignedDownloadLinkModalOpen(true);
        }
        setLoading(false);
      } catch {
        setLoading(false);
        enqueueSnackbar(t('BULK_DOWNLOAD_FAILED'), {
          variant: 'error',
        });
      }
    } else {
      try {
        const response = await Axios.post(
          `${API.bulkDownload}`,
          { documents: selectedAsstIDs },
          axiosHeadersWithArrayBuffer(localStorage.getItem('PROCYS_accessToken'))
        );
        const blob = new Blob([response.data], { type: 'text/plain;charset=utf-8' });
        FileSaver.saveAs(blob, 'download.zip');
        setLoading(false);
      } catch {
        setLoading(false);
        enqueueSnackbar(t('BULK_DOWNLOAD_FAILED'), {
          variant: 'error',
        });
      }
    }
  };

  const handleBulkDelete = async () => {
    try {
      const body = {
        company: parameterCompany,
        IDs: selectedDocs.map((i) => i.id.toString())
      };

      const response = await Axios.delete(
        API.updateInvoice,
        axiosDeleteHeaders(localStorage.getItem('PROCYS_accessToken'), body)
      );
      if (response.data.success) {
        refreshDocuments();
        enqueueSnackbar(t('INVOICES_DELETE_SUCCESS'), {
          variant: 'success',
        });
        setSelectedDocs([]);
      }
    } catch (e) {
      const error = e as CustomError;
      enqueueSnackbar(getLocalisedErrorString(error?.response?.data?.i18n, t)
        || appendContactSupport(window.config.support_email, t('INVOICES_DELETE_FAILED'), t), {
        variant: 'error',
      });
    }
  };

  const fetchAllAsstIdsAndSelect = async () => {
    if (selectedDocs.length === total) {
      handleSelectedDocsChange([]);
      handleCheckboxDropdownClose();
      return;
    }
    setLoading(true);
    let dt = selectedDocTypesList.map((docType) => docType.documentType);
    if (location.pathname.includes('/documents')) {
      if (selectedDocTypesList.length === 0) {
        dt = [user?.documentType || ''];
      }
    }
    let thisSuppliers;
    if (Array.isArray(selectedSuppliersList)) {
      thisSuppliers = selectedSuppliersList.map((supplier) => supplier.creditorCode).join(',');
    } else if (selectedSuppliersList) {
      thisSuppliers = selectedSuppliersList;
    }
    let thisOwners;
    if (Array.isArray(selectedOwnersList)) {
      thisOwners = selectedOwnersList.map((owner) => owner.email).join(',');
    } else if (selectedOwnersList) {
      thisOwners = selectedOwnersList;
    }
    try {
      let url = `${API.getAllDocumentIdsV2}?page=1${query !== ''
        ? `&search=${query}` : ''}${docStatus !== invoiceStatusesKeys.all ? `&filter=${docStatus}` : ''}`;
      if (startTimeRef.current && startTimeRef.current !== '' && endTimeRef.current && endTimeRef.current !== '') {
        url = `${url}&startTime=${startTimeRef.current}&endTime=${endTimeRef.current}`;
      } else {
        url = `${url}${time && time !== '' ? `&time=${time}` : ''}`;
      }
      const response = await Axios.post(
        url,
        {
          suppliers: thisSuppliers,
          owners: thisOwners,
          companies: selectedCompaniesList.map((company) => company.companyId),
          docTypes: dt,
        },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        handleSelectedDocsChange(response.data.data);
      }
      handleCheckboxDropdownClose();
      setLoading(false);
    } catch {
      setLoading(false);
      enqueueSnackbar(t('BULK_SELECT_FAILED'), {
        variant: 'error',
      });
    }
  };

  const handleDownloadAll = async () => {
    setLoading(true);
    let newWindow = null;
    try {
      const body = {
        suppliers: selectedSuppliersList.map((supplier) => supplier.creditorCode).join(','),
        owners: selectedOwnersList.map((owner) => owner.email).join(','),
        isDuplicate: isThisDuplicate,
        isPossibleDuplicate: isPossibleDuplicate,
        companies: selectedCompaniesList.map((company) => company.companyId).join(',') || [],
      }

      let url = `${API.downloadAll}${encodeURIComponent(user?.companyID || '')}?`
        + `order=${sortDirection}${sortField && sortField !== ''
          ? `&sortby=${sortField}` : ''}${query && query !== ''
            ? `&search=${query}` : ''}${docStatus && docStatus !== ''
              ? `&filter=${docStatus}` : ''}${failedToExport
                ? `&failedToExport=${failedToExport.toString()}` : ''}`;
      if (startTimeRef.current && startTimeRef.current !== '' && endTimeRef.current && endTimeRef.current !== '') {
        url = `${url}&startTime=${startTimeRef.current}&endTime=${endTimeRef.current}`;
      } else {
        url = `${url}${time && time !== '' ? `&time=${time}` : ''}`;
      }

      const response = await Axios.post(
        url,
        body,
        {
          ...axiosHeadersWithArrayBuffer(localStorage.getItem('PROCYS_accessToken')),
          timeout: 300000,
        }
      );

      newWindow = window.open(response.data, '_blank');
      const fileName = 'dashboard_data_'.concat(getCurrentTimeInyyyymmdd_hhmmssFormat()).concat('.csv');
      const blob = new Blob([response.data], { type: 'blob' });
      FileSaver.saveAs(blob, fileName);
      if (newWindow) {
        newWindow.close();
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      const error = e as CustomError;
      enqueueSnackbar(getLocalisedErrorString(error?.response?.data?.i18n, t)
        || appendContactSupport(window.config.support_email, t('DOWNLOAD_ALL_FAILED'), t), {
        variant: 'error',
      });
    }
  };

  const isFiltersApplied = periodFilterApplied
    || companyFilterApplied
    || supplierFilterApplied
    || docTypeFilterApplied
    || ownerFilterApplied
    || docStatus !== invoiceStatusesKeys.all;

  return (
    <Grid sx={styles.root}>
      <Typography sx={styles.title}>{ready && t('DOCUMENTS_TABLE_TITLE')}</Typography>
      <DocumentsTableHeader
        selectedStatus={docStatus}
        loading={loading}
        handleOpenFilters={handleOpenFilters}
        handleStatusFilterChange={handleStatusFilterChange}
      />
      {selectedDocs.length > 0 ? (
        <BulkOperations
          selectedDocs={selectedDocs}
          loading={loading}
          companyFilterApplied={companyFilterApplied}
          users={users}
          refreshDocuments={refreshDocuments}
          setSelectedDocs={setSelectedDocs}
          handleChangeBulkStatus={handleChangeBulkStatus}
          setSendEmailModalOpen={setSendEmailModalOpen}
          setExportDataModalOpen={setExportDataModalOpen}
          downloadInvoicesBulk={downloadInvoicesBulk}
          handleBulkDelete={handleBulkDelete}
        />
      ) : (
        <SearchBar
          query={query}
          configureFieldsOpen={configureFieldsOpen}
          configureFieldsRef={configureFieldsRef}
          isFiltersApplied={isFiltersApplied}
          selectedCompaniesList={selectedCompaniesList}
          loading={loading}
          appliedDocTypes={appliedDocTypes}
          toggleConfigureFields={() => setConfigureFieldsOpen(!configureFieldsOpen)}
          handleFieldsMenuClose={() => setConfigureFieldsOpen(false)}
          handleQueryChange={handleQueryChange}
          handleOpenFilters={handleOpenFilters}
          handleDownloadAll={handleDownloadAll}
          handleUpload={handleUpload}
          handleUploadPopupOpen={() => setShowUploadPopup(true)}
        />
      )}
      {location.pathname.includes('/documents') ? (
        <DocumentsTableInList
          docs={docs}
          page={page}
          limit={limit}
          total={total}
          fields={overViewFields}
          sortDirection={sortDirection}
          sortField={sortField}
          loading={loading}
          status={docStatus}
          query={query}
          time={time}
          supplierFilter={supplierFilterApplied}
          companyFilter={companyFilterApplied}
          ownerFilter={ownerFilterApplied}
          docTypeFilter={docTypeFilterApplied}
          startTime={startTimeRef.current}
          endTime={endTimeRef.current}
          failedToExport={failedToExport}
          selectedDocs={selectedDocs}
          displayDocs={displayDocs}
          users={users}
          chooseValidatorOpen={chooseValidatorOpen}
          selectedValidator={selectedValidator}
          showCheckboxDropdown={showCheckboxDropdown}
          handlePageChange={handlePageChange}
          handleLimitChange={handleLimitChange}
          handleSort={handleSort}
          refreshDocuments={refreshDocuments}
          setLoading={setLoading}
          handleSelectOneDoc={handleSelectOneDoc}
          setChooseValidatorOpen={setChooseValidatorOpen}
          setSelectedValidator={setSelectedValidator}
          handleChangeBulkStatus={handleChangeBulkStatus}
          handleSelectedDocsChange={handleSelectedDocsChange}
          fetchAllAsstIdsAndSelect={fetchAllAsstIdsAndSelect}
          handleCheckboxDropdownToggle={handleCheckboxDropdownToggle}
          handleCheckboxDropdownClose={handleCheckboxDropdownClose}
        />
      ) : (
        <DocumentsTable
          docs={docs}
          page={page}
          limit={limit}
          total={total}
          fields={overViewFields}
          sortDirection={sortDirection}
          sortField={sortField}
          loading={loading}
          status={docStatus}
          query={query}
          time={time}
          supplierFilter={supplierFilterApplied}
          companyFilter={companyFilterApplied}
          ownerFilter={ownerFilterApplied}
          docTypeFilter={docTypeFilterApplied}
          startTime={startTimeRef.current}
          endTime={endTimeRef.current}
          failedToExport={failedToExport}
          selectedDocs={selectedDocs}
          displayDocs={displayDocs}
          users={users}
          chooseValidatorOpen={chooseValidatorOpen}
          selectedValidator={selectedValidator}
          showCheckboxDropdown={showCheckboxDropdown}
          handlePageChange={handlePageChange}
          handleLimitChange={handleLimitChange}
          handleSort={handleSort}
          refreshDocuments={refreshDocuments}
          setLoading={setLoading}
          handleSelectOneDoc={handleSelectOneDoc}
          setChooseValidatorOpen={setChooseValidatorOpen}
          setSelectedValidator={setSelectedValidator}
          handleChangeBulkStatus={handleChangeBulkStatus}
          handleSelectedDocsChange={handleSelectedDocsChange}
          fetchAllAsstIdsAndSelect={fetchAllAsstIdsAndSelect}
          handleCheckboxDropdownToggle={handleCheckboxDropdownToggle}
          handleCheckboxDropdownClose={handleCheckboxDropdownClose}
        />
      )}
      <FiltersModal
        open={filtersModalOpen}
        selectedStatus={docStatus}
        selectedPeriod={time}
        users={users}
        filteredOwnersList={filteredOwnersList}
        selectedOwnersList={selectedOwnersList}
        filteredSuppliersList={filteredSuppliersList}
        selectedSuppliersList={selectedSuppliersList}
        supplierSearch={supplierSearch}
        supplierFilterPage={supplierFilterPage}
        supplierFilterTotal={supplierFilterTotal}
        docTypeSearch={docTypeSearch}
        docTypeFilterPage={docTypeFilterPage}
        docTypeFilterTotal={docTypeFilterTotal}
        managedCompanies={managedCompanies}
        filteredCompaniesList={filteredCompaniesList}
        selectedCompaniesList={selectedCompaniesList}
        filteredDocTypesList={filteredDocTypesList}
        selectedDocTypesList={selectedDocTypesList}
        setFilteredOwnersList={setFilteredOwnersList}
        setFilteredCompaniesList={setFilteredCompaniesList}
        setFilteredSuppliersList={setFilteredSuppliersList}
        setFilteredDocTypesList={setFilteredDocTypesList}
        getAllSuppliers={getSuppliers}
        getAllDocTypes={getDocTypes}
        handleClose={() => setFiltersModalOpen(false)}
        handleApplyFilters={handleApplyFilters}
      />
      <DocLimitReachedModal
        open={limitModalOpen}
        handleClose={() => setLimitModalOpen(false)}
      />
      <ExportToEmailModal
        open={sendEmailModalOpen}
        emails={emails}
        selectedDataFormatToEmail={selectedDataFormatToEmail}
        deliverFormats={deliverFormats}
        handleClose={handleSendEmailModalClose}
        setEmails={setEmails}
        setSelectedDataFormatToEmail={setSelectedDataFormatToEmail}
        handleSendToEmailClick={handleSendToEmailClick}
      />
      <BulkExportModal
        open={exportDataModalOpen}
        selectedDataFormatToExport={selectedDataFormatToExport}
        deliverFormats={deliverFormats}
        handleClose={() => setExportDataModalOpen(false)}
        setSelectedDataFormatToExport={setSelectedDataFormatToExport}
        handleExportClick={handleSendToExportClick}
      />
      <ManageExportFieldsNew
        open={showExportFieldMapping}
        format={deliverFormats.filter((f) => f.key === selectedDataFormatToExport)[0]}
        fields={headerFields}
        lineFields={lineFields}
        supplierFields={supplierFields}
        excelFieldMapping={excelExportFieldMapping}
        datFieldMapping={datExportFieldMapping}
        csvFieldMapping={csvExportFieldMapping}
        handleClose={handleExportFieldMappingClose}
      />
      <BulkSignedDownloadLinkPopup
        isOpen={signedDownloadLinkModalOpen}
        link={downloadLink}
        handleClose={() => setSignedDownloadLinkModalOpen(false)}
      />
      <UploadPopup
        open={showUploadPopup}
        loading={loading}
        handleUpload={handleUpload}
        handleClose={() => setShowUploadPopup(false)}
      />
      <Backdrop style={styles.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  )
}

export default DocumentsList
