import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  Button, ClickAwayListener, Grid, Grow, MenuItem, MenuList,
  Paper, Popper, Tooltip, Typography, Alert
} from '@mui/material';
import {
  ArrowDropDown
} from '@mui/icons-material';

import useWidth from 'src/hooks/useWidth';
import { isActionPermitted, permissions } from 'src/utils/helpers';
import { AuthContext } from 'src/contexts/AuthContext';
import { UserDataType } from 'src/types';
import CommonModal from 'src/shared/components/CommonModal/CommonModal';
import styles from './style';

interface PropTypes {
  open: boolean;
  selectedValidator: UserDataType | null;
  users: UserDataType[];
  id: number;
  handleClose: () => void;
  // eslint-disable-next-line no-unused-vars
  setSelectedValidator: (validator: UserDataType) => void;
  // eslint-disable-next-line no-unused-vars
  handleStatusChange: (status: number, id: number) => void;
}

const ChooseValidatorModal = (props: PropTypes) => {
  const { ready, t } = useTranslation();
  const { user  } = useContext(AuthContext);

  const {
    open: chooseValidatorModalOpen,
    selectedValidator,
    users,
    id,
    handleClose,
    setSelectedValidator,
    handleStatusChange,
  } = props;

  const validatorOptionsRef = useRef(null);
  const [validatorOptionsOpen, setValidatorOptionsOpen] = useState(false);
  const validatorOptionsWidth = useWidth(validatorOptionsRef, 0);

  const handleValidatorOptionsClose = () => {
    setValidatorOptionsOpen(false);
  };

  const handleValidatorOptionsToggle = () => {
    setValidatorOptionsOpen(!validatorOptionsOpen);
  };

  const handleValidatorClick = (validator: UserDataType) => {
    setSelectedValidator(validator);
    setValidatorOptionsOpen(false);
  };

  const handleAddUserClick = () => {
    // @TODO: implement add user functionality
    // if (user?.usersCount < user?.maxUsers) {
    //   setAddUserOpen(true);
    // }
  }

  const onSendToValidate = () => {
    handleStatusChange(501, id || 0);
  };

  return (
    <CommonModal
      open={chooseValidatorModalOpen}
      title={t('CHOOSE_VALIDATOR_MODAL_TITLE')}
      cancelText={t('CHOOSE_VALIDATOR_MODAL_CANCEL')}
      submitText={t('CHOOSE_VALIDATOR_MODAL_SEND')}
      onSubmit={onSendToValidate}
      handleClose={handleClose}
    >
      <Typography sx={styles.instruction}>
        {ready && t('CHOOSE_VALIDATOR_MODAL_SUB_TITLE')}
      </Typography>
      <Grid sx={styles.fieldContainer}>
        <Typography sx={styles.fieldLabel}>
          {ready && t('CHOOSE_VALIDATOR_MODAL_VALIDATOR')}
        </Typography>
        <Button ref={validatorOptionsRef} sx={styles.userOptionsBtn} onClick={handleValidatorOptionsToggle}>
          {`${selectedValidator?.firstname} ${selectedValidator?.lastname}`}
          <ArrowDropDown />
        </Button>
        <Popper
          open={validatorOptionsOpen}
          anchorEl={validatorOptionsRef.current}
          role={undefined}
          transition
          style={{
            zIndex: 9999999, width: validatorOptionsWidth
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleValidatorOptionsClose}>
                  <MenuList autoFocusItem>
                    {users.map((option) => (
                      <MenuItem
                        key={option.id}
                        selected={option.id === selectedValidator?.id}
                        onClick={() => handleValidatorClick(option)}
                        sx={styles.validatorOption}
                      >
                        {`${option.firstname} ${option.lastname}`}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
      <Grid sx={styles.fieldContainer}>
        <Typography variant="caption" sx={styles.instruction}>
          {ready && t('CHOOSE_VALIDATOR_MODAL_ADD_USER_1')}
          <Tooltip title={!isActionPermitted(permissions.userAdd, user?.permissions)
            ? ready && t('CHOOSE_VALIDATOR_MODAL_ADD_USER_PERMISSION') : ''}
          >
            <Typography
              variant="caption"
              sx={isActionPermitted(permissions.userAdd, user?.permissions) ? styles.link : styles.disabledLink}
              onClick={() => isActionPermitted(permissions.userAdd, user?.permissions) && handleAddUserClick()}
            >
              {ready && t('CHOOSE_VALIDATOR_MODAL_ADD_USER_2')}
            </Typography>
          </Tooltip>
          {ready && t('CHOOSE_VALIDATOR_MODAL_ADD_USER_3')}
        </Typography>
      </Grid>
      {
        (user?.usersCount ? user?.usersCount : 0) >= (user?.maxUsers ? user?.maxUsers : 0) && (
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            sx={styles.addUserDisabled}
          >
            <Alert severity="warning" sx={styles.warningBanner}>
              <Typography sx={styles.addUserDisabledText}>
                {ready && t('ACCOUNT_SETTINGS_ADD_USER_DISABLED')}
                <strong>
                  <Link to="/subscription-plan">
                    {ready && t('ACCOUNT_SETTINGS_ADD_USER_UPDATE_LINK')}
                  </Link>
                </strong>
              </Typography>
            </Alert>
          </Grid>
        )
      }
    </CommonModal>
  );
};

export default ChooseValidatorModal;
