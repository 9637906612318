import { Dispatch, SetStateAction, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik, FormikState } from 'formik';

import {
  Autocomplete, Box, Dialog, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem,
  Select, Switch, TextField, Tooltip, Typography
} from '@mui/material';
import {
  Close as CloseIcon,
} from '@mui/icons-material';
import { RiAddCircleLine, RiCloseCircleFill } from '@remixicon/react';

import {
  reusableFields, trimObjectValues, filterReusableFields, isFieldExists, groupFieldsIntoSections,
  isSubmitBtnDisabled, editFieldInitialValue
} from 'src/doc-types/utils';
import PrimaryActionBtnLarge from 'src/shared/buttons/PrimaryActionBtnLarge';
import SubmitBtn from 'src/shared/buttons/SubmitBtn';
import CancelBtn from 'src/shared/buttons/CancelBtn';
import theme from 'src/themeNew';
import styles from './style';

interface PropTypes {
  open: boolean;
  selectedField: Record<string, string> | null;
  fieldsList: Record<string, any>[];
  sectionFieldsList: Record<string, Record<string, any>[]>;
  handleClose: () => void;
  setFieldsList: Dispatch<SetStateAction<Record<string, any>[]>>;
  setSectionFieldsList: Dispatch<SetStateAction<Record<string, Record<string, any>[]>>>;
  handleSaveFields: (_fields: Record<string, any>[]) => Promise<void>
}

const fieldSections = [
  { key: 'header', label: 'ADD_NEW_FIELD_SECTION_HEADER' },
  { key: 'lines', label: 'ADD_NEW_FIELD_SECTION_LINE' },
];

const fieldDataTypes = [
  { key: 'text', label: 'ADD_NEW_FIELD_TEXT' },
  { key: 'float', label: 'ADD_NEW_FIELD_NUMBER' },
  { key: 'date', label: 'ADD_NEW_FIELD_DATE' },
];

const AddNewField = (props: PropTypes) => {
  const { t, i18n } = useTranslation();

  const {
    open,
    selectedField,
    fieldsList,
    sectionFieldsList,
    handleClose: _handleClose,
    setFieldsList,
    setSectionFieldsList,
    handleSaveFields,
  } = props;

  const isEdit = !!selectedField;

  const [newFields, setNewFields] = useState<Record<string, any>[]>([]);
  const [selectedReusableKey, setSelectedReusableKey] = useState<string | undefined>();
  const [useAsReusableField, setUseAsReusableField] = useState<boolean>(false);

  const nameInputRef = useRef<HTMLInputElement | null>(null);

  const groupedNewFields = groupFieldsIntoSections(newFields.filter((f) => f.section !== 'header'));

  const handleClose = () => {
    _handleClose();
    setNewFields([]);
    setSelectedReusableKey(undefined);
    setUseAsReusableField(false);
  };

  const handleAddAFieldClick = (
    newField: Record<string, any>,
    resetForm: (_nextState?: Partial<FormikState<{
      name: string;
      section: string;
      type: string;
      description: string;
    }>> | undefined) => void
  ) => {
    if (Boolean(selectedReusableKey) && useAsReusableField) {
      newField['isReusable'] = true;
      newField['key'] = selectedReusableKey;
    }
    setNewFields((prev) => [...prev, trimObjectValues(newField)]);
    resetForm();
    setSelectedReusableKey(undefined);
    setUseAsReusableField(false);
  };

  const onSave = (
    values: Record<string, any>,
    resetForm: (_nextState?: Partial<FormikState<{
      name: string;
      section: string;
      type: string;
      description: string;
    }>> | undefined) => void,
    setSubmitting: (_isSubmitting: boolean) => void
  ) => {
    if (isEdit) {
      // Edit field
      if (values.section === 'header') {
        setFieldsList((prev) => {
          return prev.map((f) => {
            if ((f.key && selectedField.key && f.key === selectedField.key) || (!selectedField.key && f.position === selectedField.position)) {
              return {
                ...f,
                ...values,
              };
            }

            return f;
          });
        });
      } else {
        setSectionFieldsList((prev) => {
          const prevCopy = { ...prev };

          Object.keys(prevCopy).forEach((section) => {
            if (section === values.section) {
              prevCopy[section] = prevCopy[section].map((f) => {
                if ((f.key && selectedField.key && f.key === selectedField.key) || (!selectedField.key && !f.key && f.position === selectedField.position)) {
                  return {
                    ...f,
                    ...values,
                  };
                }

                return f;
              });
            }
          });

          return prevCopy;
        });
      }
      resetForm();
      setSubmitting(false);
      handleClose();
      return;
    }
    const updatedSectionsFields: Record<string, Record<string, any>[]> = {};

    newFields.filter((f) => f.section !== 'header').forEach((f) => {
      if (!updatedSectionsFields[f.section]) {
        updatedSectionsFields[f.section] = [];
      }

      updatedSectionsFields[f.section].push({
        ...f,
        position: (sectionFieldsList[f.section] ? sectionFieldsList[f.section].length : 0) + updatedSectionsFields[f.section].length + 1,
        isAvailable: true,
      });
    });

    setFieldsList((prev) => [
      ...prev,
      ...newFields.filter((f) => f.section === 'header').map((f, i) => {
        return {
          ...f,
          position: prev.length + i + 1,
          isAvailable: true,
        };
      })
    ]);

    setSectionFieldsList((prev) => {
      const prevCopy = { ...prev };

      Object.keys(prevCopy).forEach((section) => {
        if (updatedSectionsFields[section]) {
          prevCopy[section] = [
            ...prevCopy[section],
            ...updatedSectionsFields[section]
          ];
        }
      });

      Object.keys(updatedSectionsFields).forEach((section) => {
        if (!prevCopy[section]) {
          prevCopy[section] = updatedSectionsFields[section];
        }
      });

      return {
        ...prevCopy
      };
    })

    handleSaveFields(newFields.map((f) => { return { ...f, isAvailable: true } }));
    resetForm();
    setSubmitting(false);
    handleClose();
  };

  const handleDeleteFieldClick = (field: Record<string, any>) => {
    setNewFields((prev) => prev.filter((f) => f.name !== field.name));
  };

  const getAddFieldBtnTooltip = (values: {
    name: string;
    section: string;
    type: string;
    description: string;
  }) => {
    if (!values.name || !values.section || !values.type || !values.description) {
      return t('DOC_TYPE_FIELD_ADD_BTN_TOOLTIP_EMPTY');
    }
    if (isFieldExists(fieldsList, sectionFieldsList, newFields, values.name)) {
      return t('ADD_NEW_FIELD_FIELD_NAME_EXIST');
    }

    return null;
  };

  const handleChangeAutoComplete = (
    field: NonNullable<string | Record<string, any>>,
    setFieldValue: Function,
  ) => {
    const isReusableFieldSelected = typeof field === 'object';
    setSelectedReusableKey(isReusableFieldSelected ? field.key : undefined);
    setUseAsReusableField(isReusableFieldSelected ? true : false);
    setFieldValue('name', isReusableFieldSelected ? t(field.name) : field);
    setFieldValue('section', isReusableFieldSelected ? field.section : '');
    setFieldValue('type', isReusableFieldSelected ? field.type : '');
    setFieldValue('description', isReusableFieldSelected ? t(field.description) : '');
    if (isReusableFieldSelected) {
      setTimeout(() => {
        const el = nameInputRef.current;
        if (el) el.blur();
      }, 0);
    }
  };

  return (
    <Dialog
      open={open}
      sx={styles.modal}
      onClose={handleClose}
    >
      <Formik
        initialValues={{
          name: editFieldInitialValue('name', selectedField, t, i18n),
          section: editFieldInitialValue('section', selectedField, t, i18n),
          type: editFieldInitialValue('type', selectedField, t, i18n),
          description: editFieldInitialValue('description', selectedField, t, i18n),
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .min(2, t('DOC_TYPE_NAME_MIN_HELPER_TEXT'))
            .max(64, t('DOC_TYPE_NAME_MAX_HELPER_TEXT'))
            .matches(/^[A-Za-z0-9 _-]+$/, t('DOC_TYPE_FIELD_NAME_INVALID'))
            .required(t('DOC_TYPE_FIELD_NAME_REQUIRED')),
          section: Yup.string().required(t('DOC_TYPE_SECTION_REQUIRED_HELPER_TEXT')),
          type: Yup.string().required(t('DOC_TYPE_TYPE_REQUIRED_HELPER_TEXT')),
          description: Yup.string()
            .min(16, t('DOC_TYPE_DESCRIPTION_MIN_HELPER_TEXT'))
            .max(500, t('DOC_TYPE_DESCRIPTION_MAX_HELPER_TEXT'))
            .required(t('DOC_TYPE_DESCRIPTION_REQUIRED_HELPER_TEXT')),
        })}
        onSubmit={async () => {
          //
        }}
      >
        {({
          errors,
          isSubmitting,
          touched,
          values,
          handleBlur,
          handleChange,
          resetForm,
          setSubmitting,
          setFieldValue,
        }) => (
          <form style={{ height: '100%' }}>
            <Box sx={styles.container}>
              <Box sx={styles.modalHeader}>
                <Box sx={styles.titleContainer}>
                  <Typography sx={styles.headerTitle}>
                    {t(selectedField?.key ? 'DOC_TYPES_EDIT_FIELD_TITLE' : 'ADD_NEW_FIELD_TITLE')}
                  </Typography>
                  <IconButton sx={styles.closeBtn} onClick={handleClose}>
                    <CloseIcon sx={styles.closeIcon} />
                  </IconButton>
                </Box>
              </Box>
              <Box sx={styles.modalContent}>
                <Box sx={styles.addNewForm}>
                  <Box sx={styles.fieldContainer}>
                    <Autocomplete
                      id="name-autocomplete"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={(_, val) => handleChangeAutoComplete(val, setFieldValue)}
                      value={values.name}
                      options={filterReusableFields(values.section, reusableFields, newFields, fieldsList, sectionFieldsList)}
                      getOptionLabel={(option) => typeof option === 'string'
                        ? i18n.exists(option) ? t(option) : option
                        : i18n.exists(option.name) ? t(option.name) : option.name}
                      sx={styles.autoComplete}
                      freeSolo
                      disableClearable
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="name"
                          label={t('DOC_TYPES_ADD_FIELD_NAME')}
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            inputRef: nameInputRef,
                          }}
                          onChange={handleChange}
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                          sx={styles.autoCompleteTextField}
                        />
                      )}
                    />
                  </Box>
                  <Box sx={styles.fieldContainer}>
                    <FormControl fullWidth>
                      <InputLabel id="section-label">{`${t('DOC_TYPES_ADD_FIELD_SECTION')} *`}</InputLabel>
                      <Select
                        id="section"
                        fullWidth
                        name="section"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        sx={styles.selectStyle}
                        value={values.section}
                        required
                        labelId="section-label"
                        label={t('DOC_TYPES_ADD_FIELD_SECTION')}
                        variant="outlined"
                        disabled={isEdit || (Boolean(selectedReusableKey) && useAsReusableField)}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left'
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left'
                          },
                        }}
                      >
                        {
                          fieldSections.map((d) => (
                            <MenuItem sx={styles.selectItem} key={d.key} value={d.key}>
                              {t(d.label)}
                            </MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={styles.fieldContainer}>
                    <FormControl fullWidth>
                      <InputLabel id="type-label">{`${t('DOC_TYPES_ADD_FIELD_TYPE')} *`}</InputLabel>
                      <Select
                        id="type"
                        fullWidth
                        name="type"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        sx={styles.selectStyle}
                        value={values.type}
                        required
                        labelId="type-label"
                        label={t('DOC_TYPES_ADD_FIELD_TYPE')}
                        variant="outlined"
                        disabled={isEdit || (Boolean(selectedReusableKey) && useAsReusableField)}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left'
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left'
                          },
                        }}
                      >
                        {
                          fieldDataTypes.map((d) => (
                            <MenuItem sx={styles.selectItem} key={d.key} value={d.key}>
                              {t(d.label)}
                            </MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={styles.fieldContainer}>
                    <TextField
                      id="description"
                      autoComplete="off"
                      fullWidth
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      name="description"
                      label={t('DOC_TYPE_DESCRIPTION')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      value={values.description}
                      variant="outlined"
                      multiline
                      maxRows={4}
                      sx={styles.textFieldMulti}
                    />
                  </Box>
                  {!isEdit && (
                    <>
                      {
                        selectedReusableKey &&
                          <FormControlLabel
                            control={
                              <Switch
                                disabled={!selectedReusableKey}
                                checked={useAsReusableField}
                                onChange={(e) => setUseAsReusableField(e.target.checked)}
                              />
                            }
                            label={t('DOC_TYPE_ADD_AS_REUSABLE_FIELD', { fieldName: t(reusableFields.find((f) => f.key === selectedReusableKey)?.name || '') })}
                            labelPlacement="start"
                            sx={styles.formControlLabel}
                          />
                      }
                      <Tooltip
                        title={getAddFieldBtnTooltip(values)}
                      >
                        <span style={{ width: '100%' }}>
                          <PrimaryActionBtnLarge
                            title={t('MANAGE_FIELDS_NEW_ADD_FIELD')}
                            onClick={() => handleAddAFieldClick(values, resetForm)}
                            disabled={isSubmitting
                              || (values.name === '' || values.description === '' || values.section === '' || values.type === '')
                              || Object.keys(errors).length > 0
                              || isFieldExists(fieldsList, sectionFieldsList, newFields, values.name)
                            }
                            startIcon={<RiAddCircleLine size="16px" />}
                          />
                        </span>
                      </Tooltip>
                    </>
                  )}
                </Box>
              </Box>
              {!isEdit && <Grid className="y-scroll" sx={styles.newFieldsChipContainer}>
                {newFields.filter((f) => f.section === 'header').length > 0 && 
                  <Box sx={styles.newFieldsSectionContainer}>
                    <Typography sx={styles.sectionTitle}>
                      {t('DOC_TYPES_ADD_FIELD_SECTION_FIELDS', { sectionName: 'header' })}
                    </Typography>
                    <Box sx={styles.newFieldsChips}>
                      {newFields.filter((f) => f.section === 'header').map((field) => (
                        <Box key={field.name} sx={styles.chipBox}>
                          <Typography sx={styles.chipText}>{field.name}</Typography>
                          <IconButton
                            sx={styles.chipIconBtn}
                            onClick={() => handleDeleteFieldClick(field)}
                          >
                            <RiCloseCircleFill opacity={0.7} color={theme.palette.primary.main} size="18px" />
                          </IconButton>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                }
                {Object.keys(groupedNewFields).map((section) => (
                  <Box sx={styles.newFieldsSectionContainer}>
                    <Typography sx={styles.sectionTitle}>
                      {t('DOC_TYPES_ADD_FIELD_SECTION_FIELDS', { sectionName: section })}
                    </Typography>
                    <Box sx={styles.newFieldsChips}>
                      {groupedNewFields[section].map((field) => (
                        <Box key={field.name} sx={styles.chipBox}>
                          <Typography sx={styles.chipText}>{field.name}</Typography>
                          <IconButton
                            sx={styles.chipIconBtn}
                            onClick={() => handleDeleteFieldClick(field)}
                          >
                            <RiCloseCircleFill opacity={0.7} color={theme.palette.primary.main} size="18px" />
                          </IconButton>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ))}
              </Grid>}
              <Box sx={styles.modalFooter}>
                <Box display="flex" justifyContent="flex-end" gap="16px">
                  <CancelBtn
                    title={t('DOC_TYPES_TABLE_ADD_NEW_CANCEL')}
                    onClick={handleClose}
                  />
                  <Tooltip title={(!isEdit && newFields.length === 0) ? t('DOC_TYPE_FIELD_ADD_SAVE_BTN_TOOLTIP_NO_FIELD') : ''}>
                    <span>
                      <SubmitBtn
                        title={t(isEdit ? 'DOC_TYPES_TABLE_UPDATE_BTN' : 'DOC_TYPES_FIELDS_MANAGE_SAVE')}
                        disabled={isSubmitBtnDisabled(isSubmitting, isEdit, newFields, errors, touched)}
                        onClick={() => onSave(values, resetForm, setSubmitting)}
                      />
                    </span>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Dialog>
  );
}

export default AddNewField;
