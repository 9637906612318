import React, { Component } from "react";

import "../style/Highlight.css";

import type { Content, LTWHP } from "../types.js";

interface Props {
  id: string;
  position: {
    boundingRect: LTWHP;
    rects: Array<LTWHP>;
  };
  onClick: (
    _id: string,
    _rects: LTWHP[],
    _pageNumber: number,
    _content: {text?: string},
    _isCandidate: boolean,
    _procysValues: Array<{key: string, value: string}> | undefined,
    _fieldSeparator: string,
  ) => void
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  isScrolledTo: boolean;
  content: Content;
  pageNum: number;
  procysValues: Array<{key: string, value: string}> | undefined | undefined;
  fieldSeparator: string;
  isCandidate: boolean;
  isLinesReprocessing: boolean;
}

export class Highlight extends Component<Props> {
  render() {
    const {
      id,
      position,
      onClick,
      onMouseOver,
      onMouseOut,
      content,
      pageNum,
      procysValues,
      fieldSeparator,
      isCandidate,
      isLinesReprocessing,
    } = this.props;

    const { rects, boundingRect } = position;
    const boundingBoxes = isLinesReprocessing ? [boundingRect] : rects;

    return (
      <div
        className="Highlight"
      >
        <div className="Highlight__parts">
          {boundingBoxes.map((rect, index) => (
            <div
              id={`highlight-${id}`}
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
              onClick={() => onClick(id, position.rects, pageNum, content, true, procysValues, fieldSeparator)}
              key={index}
              style={rect}
              className={isCandidate ? 'HighlightCandidate__part' : isLinesReprocessing ? 'HighlightLinesReprocessing__part' : 'HighlightWord__part'}
            />
          ))}
          {isLinesReprocessing && rects.map((rect, index) => (
            <div
              id={`highlight-${id}`}
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
              onClick={() => onClick(id, position.rects, pageNum, content, true, procysValues, fieldSeparator)}
              key={index}
              style={rect}
              className="HighlightCandidateLP__part"
            />
          ))}
        </div>
      </div>
    );
  }
}

export default Highlight;
