import theme from "src/themeNew";

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0',
    margin: '0px',
    '& .MuiDialog-paper': {
      margin: '0px',
      borderRadius: '0',
    },
    '& .MuiPaper-root': {
      height: '100%',
      maxHeight: '100%',
    }
  },
  container: {
    height: '100%',
    width: '370px',
    display: 'flex',
    flexDirection: 'column'
  },
  modalHeader: {
    height: '72px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderBottom: '1px solid #4C4E641F',
    padding: '16px',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerTitle: {
    fontSize: '18px',
    fontWeight: '500',
    color: theme.palette.text.primary,
    lineHeight: '28px',
    letter: '-0.25px',
  },
  closeBtn: {
    padding: '0px',
  },
  closeIcon: {
    height: '18px',
    width: '18px',
    color: theme.palette.text.secondary,
  },
  modalContent: {
    flex: 1,
    padding: '20px 24px',
  },
  addNewForm: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  fieldContainer: {
  },
  textField: {
    color: theme.palette.text.primary,
    width: '100%',
    '& .MuiOutlinedInput-input': {
      fontSize: '13px',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background.white,
      padding: '4px !important',
      fontSize: '13px !important',
      '& fieldset': {
        border: `1px solid ${theme.palette.border.input} !important`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.border.input} !important`,
      }
    },
  },
  textFieldMulti: {
    color: theme.palette.text.primary,
    width: '100%',
    '& .MuiOutlinedInput-input': {
      padding: '8px 12px !important',
      fontSize: '13px',
    },
    '& .MuiOutlinedInput-root': {
      height: 'unset',
      backgroundColor: theme.palette.background.white,
      padding: '12px 4px !important',
      fontSize: '13px !important',
      '& fieldset': {
        border: `1px solid ${theme.palette.border.input} !important`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.border.input} !important`,
      }
    },
  },
  activateContainer: {
    width: '100%',
    backgroundColor: theme.palette.background.grayLight,
    height: '44px',
    borderRadius: '8px',
    padding: '4px 0px 4px 12px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  toggleLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  toggleLabel: {
    color: theme.palette.text.primary,
    fontSize: '13px',
    lineHeight: '22px',
    fontWeight: '400',
    marginRight: '8px',
  },
  formControlLabel: {
    '&.MuiFormControlLabel-root': {
      justifyContent: 'space-between',
      marginLeft: '0px',
      width: '100%',
    },
  },
  infoIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalFooter: {
    height: '102px',
    width: '100%',
    padding: '32px 20px',
    border: `1px solid ${theme.palette.border.footer}`,
    boxShadow: `0px 2px 6px 0px ${theme.palette.boxShadow.primary}`
  },
}

export default styles;
