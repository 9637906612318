import { Dispatch } from 'redux';
import Axios from "axios";
import {
  FETCH_DOC_TYPES_START,
  FETCH_DOC_TYPES_SUCCESS,
  FETCH_DOC_TYPES_FAIL,
  FETCH_DOC_TYPES_FOR_IMPORT_FAIL,
  FETCH_DOC_TYPES_FOR_IMPORT_START,
  FETCH_DOC_TYPES_FOR_IMPORT_SUCCESS,
  FETCH_COMPANY_DOC_TYPES_FAIL,
  FETCH_COMPANY_DOC_TYPES_START,
  FETCH_COMPANY_DOC_TYPES_SUCCESS,
} from "src/actionTypes";
import { axiosHeaders } from "src/utils/helpers";

const fetchDocTypesStart = () => {
  return {
    type: FETCH_DOC_TYPES_START,
  };
};

const fetchDocTypesSuccess = (docTypes: Record<string, any>[]) => {
  return {
    type: FETCH_DOC_TYPES_SUCCESS,
    payload: docTypes,
  };
};

const fetchDocTypesFail = () => {
  return {
    type: FETCH_DOC_TYPES_FAIL,
  };
}

export const fetchDocTypes = (companyID: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchDocTypesStart());
    let url = `${window.config.backend.procys_service}v2/document-types/signup/${encodeURIComponent(companyID)}`;
    try {
      const response = await Axios.get(
        url,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response?.data.success) {
        dispatch(fetchDocTypesSuccess(response.data.data));
      } else {
        dispatch(fetchDocTypesFail());
      }
    } catch (error) {
      dispatch(fetchDocTypesFail());
    }
  };
};

const fetchCompanyDocTypesStart = () => {
  return {
    type: FETCH_COMPANY_DOC_TYPES_START,
  };
};

const fetchCompanyDocTypesSuccess = (companyDocTypes: Record<string, any>[]) => {
  return {
    type: FETCH_COMPANY_DOC_TYPES_SUCCESS,
    payload: companyDocTypes,
  };
};

const fetchCompanyDocTypesFail = () => {
  return {
    type: FETCH_COMPANY_DOC_TYPES_FAIL,
  };
}

export const fetchCompanyDocTypes = () => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchCompanyDocTypesStart());
    let url = `${window.config.backend.procys_service}v2/document-types/min/company?page=1&limit=200`;
    try {
      const response = await Axios.get(
        url,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response?.data.success) {
        dispatch(fetchCompanyDocTypesSuccess(response.data.data));
      } else {
        dispatch(fetchCompanyDocTypesFail());
      }
    } catch (error) {
      dispatch(fetchCompanyDocTypesFail());
    }
  };
};

const fetchDocTypesForImportStart = () => {
  return {
    type: FETCH_DOC_TYPES_FOR_IMPORT_START,
  };
};

const fetchDocTypesForImportSuccess = (docTypesForImport: Record<string, any>[]) => {
  return {
    type: FETCH_DOC_TYPES_FOR_IMPORT_SUCCESS,
    payload: docTypesForImport,
  };
};

const fetchDocTypesForImportFail = () => {
  return {
    type: FETCH_DOC_TYPES_FOR_IMPORT_FAIL,
  };
}

export const fetchDocTypesForImport = () => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchDocTypesForImportStart());
    let url = `${window.config.backend.procys_service}v2/document-types/all/import`;
    try {
      const response = await Axios.get(
        url,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response?.data.success) {
        dispatch(fetchDocTypesForImportSuccess(response.data.data));
      } else {
        dispatch(fetchDocTypesForImportFail());
      }
    } catch (error) {
      dispatch(fetchDocTypesForImportFail());
    }
  };
};
