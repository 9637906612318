import { FieldType } from "src/types";
import {
  FETCH_HEADER_FIELDS_START,
  FETCH_HEADER_FIELDS_SUCCESS,
  FETCH_HEADER_FIELDS_FAIL,
  FETCH_LINE_FIELDS_START,
  FETCH_LINE_FIELDS_SUCCESS,
  FETCH_LINE_FIELDS_FAIL,
  FETCH_DOC_OVERVIEW_FIELDS_START,
  FETCH_DOC_OVERVIEW_FIELDS_SUCCESS,
  FETCH_DOC_OVERVIEW_FIELDS_FAIL,
  FETCH_SUPPLIER_FIELDS_START,
  FETCH_SUPPLIER_FIELDS_SUCCESS,
  FETCH_SUPPLIER_FIELDS_FAIL,
} from "src/actionTypes";

interface IState {
  fieldsLoading: boolean;
  supplierFieldsLoading: boolean;
  docType: string;
  headerFields: FieldType[];
  lineFields: FieldType[];
  docOverviewFields: FieldType[];
  supplierFields: FieldType[];
}

type ActionType =
  | {
      type: 'FETCH_HEADER_FIELDS_START'
        | 'FETCH_LINE_FIELDS_START'
        | 'FETCH_DOC_OVERVIEW_FIELDS_START'
        | 'FETCH_SUPPLIER_FIELDS_START',
      payload?: string
    }
  | {
      type: 'FETCH_LINE_FIELDS_SUCCESS'
      | 'FETCH_SUPPLIER_FIELDS_SUCCESS',
      payload: FieldType[]
    }
  | { type: 'FETCH_HEADER_FIELDS_SUCCESS'
      | 'FETCH_DOC_OVERVIEW_FIELDS_SUCCESS',
      payload: { data: FieldType[], docType: string }
    }
  | { type: 'FETCH_HEADER_FIELDS_FAIL' | 'FETCH_LINE_FIELDS_FAIL' | 'FETCH_DOC_OVERVIEW_FIELDS_FAIL' | 'FETCH_SUPPLIER_FIELDS_FAIL' };

const initialState: IState = {
  fieldsLoading: true,
  supplierFieldsLoading: false,
  docType: '',
  headerFields: localStorage.getItem('headerFields') ? JSON.parse(localStorage.getItem('headerFields') as string) : [],
  lineFields: localStorage.getItem('lineFields') ? JSON.parse(localStorage.getItem('lineFields') as string) : [],
  docOverviewFields: localStorage.getItem('docOverviewFields') ? JSON.parse(localStorage.getItem('docOverviewFields') as string) : [],
  supplierFields: localStorage.getItem('supplierFields') ? JSON.parse(localStorage.getItem('supplierFields') as string) : [],
};

const fieldsReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case FETCH_HEADER_FIELDS_START:
      return { ...state, fieldsLoading: true };
    case FETCH_HEADER_FIELDS_SUCCESS:
      return {
        ...state,
        headerFields: action.payload.data,
        docType: action.payload.docType,
        fieldsLoading: false
      };
    case FETCH_HEADER_FIELDS_FAIL:
      return { ...state, fieldsLoading: false };

    case FETCH_LINE_FIELDS_START:
      return { ...state, fieldsLoading: true };
    case FETCH_LINE_FIELDS_SUCCESS:
      return { ...state, lineFields: action.payload, fieldsLoading: false };
    case FETCH_LINE_FIELDS_FAIL:
      return { ...state, fieldsLoading: false };

    case FETCH_DOC_OVERVIEW_FIELDS_START:
      return { ...state, fieldsLoading: true };
    case FETCH_DOC_OVERVIEW_FIELDS_SUCCESS:
      return {
        ...state,
        docOverviewFields: action.payload.data,
        docType: action.payload.docType,
        fieldsLoading: false };
    case FETCH_DOC_OVERVIEW_FIELDS_FAIL:
      return { ...state, fieldsLoading: false };

    case FETCH_SUPPLIER_FIELDS_START:
      return { ...state, supplierFieldsLoading: true };
    case FETCH_SUPPLIER_FIELDS_SUCCESS:
      return { ...state, supplierFields: action.payload, supplierFieldsLoading: false };
    case FETCH_SUPPLIER_FIELDS_FAIL:
      return { ...state, supplierFieldsLoading: false };

    default:
      return state;
  }
};

export default fieldsReducer;
