import React from 'react';
import { Button } from '@mui/material';
import theme from 'src/themeNew';

const PrimaryActionBtnLarge = (props: {
  title: string,
  onClick: (_e?: any) => void,
  disabled?: boolean,
  startIcon?: React.ReactNode,
}) => (
  <Button
    variant="outlined"
    sx={styles.submitBtn}
    disabled={props.disabled}
    onClick={props.onClick}
    startIcon={props.startIcon}
  >
    {props.title}
  </Button>
);

export default PrimaryActionBtnLarge;

const styles = {
  submitBtn: {
    color: theme.palette.primary.main,
    fontSize: '15px',
    lineHeight: '22px',
    height: '38px',
    width: '100%',
    textTransform: 'none',
    borderRadius: '4px',
    padding: '8px 22px',
    border: `1px ${theme.palette.primary.main} solid`,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      border: `1px ${theme.palette.primary.main} solid`,
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.light,
      border: `1px ${theme.palette.primary.main} solid`,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.light,
      border: `1px ${theme.palette.primary.main} solid`,
    },
    '&:disabled': {
      color: theme.palette.primary.main,
      border: `1px ${theme.palette.primary.main} solid`,
      opacity: 0.45,
    }
  },
};
