import { FormikErrors, FormikTouched } from "formik";
import { i18n, TFunction } from "i18next";

export const toCamelCase = (str: string) => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word, index) => index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1))
    .join('');
};

export const hasUserModifiedData = (
  originalArray: object[],
  modifiedArray: object[],
  originalObject: Record<string, object[]>,
  modifiedObject: Record<string, object[]>
): boolean => {
  const arraysAreEqual = JSON.stringify(originalArray) === JSON.stringify(modifiedArray);

  const objectsAreEqual = JSON.stringify(originalObject) === JSON.stringify(modifiedObject);

  return !(arraysAreEqual && objectsAreEqual);
};

export const fieldSearch = (fields: Record<string, any>[], query: string) => {
  if (!query.trim()) return fields;

  const lowerCaseQuery = query.toLowerCase();

  const filtered = fields.filter(({ key, name, description }) => {
    return (
      (key && key.toLowerCase().includes(lowerCaseQuery)) ||
      (name && name.toLowerCase().includes(lowerCaseQuery)) ||
      (description && description.toLowerCase().includes(lowerCaseQuery))
    );
  });

  return filtered;
};

export const trimObjectValues = (obj: Record<string, any>): Record<string, any> => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key,
      typeof value === 'string' ? value.trim() : value,
    ])
  );
};

export const reusableFields = [
  // {
  //   key: 'supplier',
  //   name: 'INVOICE_EDIT_FORM_SUPPLIER',
  //   description: 'SUPPLIER_FIELD_DESCRIPTION',
  //   section: 'header',
  //   type: 'text',
  // },
  // {
  //   key: 'vatAmount',
  //   name: 'INVOICE_EDIT_FORM_VAT_AMOUNT',
  //   description: 'VAT_AMOUNT_FIELD_DESCRIPTION',
  //   section: 'header',
  //   type: 'float',
  // },
  // {
  //   key: 'equivalenceSurchargeAmount',
  //   name: 'INVOICE_EDIT_FORM_EQUIVALENCE_SURCHARGE_AMOUNT',
  //   description: 'EQUIVALENCE_SURCHARGE_AMOUNT_FIELD_DESCRIPTION',
  //   section: 'header',
  //   type: 'float',
  // },
  {
    key: 'currency',
    name: 'INVOICE_EDIT_FORM_CURRENCY',
    description: 'CURRENCY_FIELD_DESCRIPTION',
    section: 'header',
    type: 'text',
  },
  {
    key: 'period',
    name: 'INVOICE_EDIT_FORM_PERIOD',
    description: 'PERIOD_FIELD_DESCRIPTION',
    section: 'header',
    type: 'text',
  },
  // {
  //   key: 'lineGLAccount',
  //   name: 'INVOICE_EDIT_FORM_LINE_GLA',
  //   description: 'LINE_GLA_FIELD_DESCRIPTION',
  //   section: 'lines',
  //   type: 'text',
  // },
  // {
  //   key: 'lineCostCenter',
  //   name: 'INVOICE_EDIT_FORM_LINE_CC',
  //   description: 'LINE_CC_FIELD_DESCRIPTION',
  //   section: 'lines',
  //   type: 'text',
  // },
  // {
  //   key: 'lineVatGroup',
  //   name: 'INVOICE_EDIT_FORM_LINE_VAT_GROUP',
  //   description: 'LINE_VAT_GROUP_FIELD_DESCRIPTION',
  //   section: 'lines',
  //   type: 'text',
  // },
];

  export const filterReusableFields = (
    section: string,
    fields: Record<string, any>[],
    newFields: Record<string, any>[],
    fieldsList: Record<string, any>[],
    sectionFieldsList: Record<string, Record<string, any>[]>,
  ) => {
    const filteredFields = fields.filter((rf) => ![
      ...fieldsList,
      ...Object.keys(sectionFieldsList).map((section) => sectionFieldsList[section]).flat(),
      ...newFields,
    ].some((f) => f.key === rf.key));
    if (section) {
      return filteredFields.filter((f) => f.section === section);
    }
    return filteredFields;
  };

  export const isFieldExists = (
    fieldsList: Record<string, any>[],
    sectionFieldsList: Record<string, Record<string, any>[]>,
    newFields: Record<string, any>[],
    fieldName: string
  ) => {
    return [
      ...fieldsList,
      ...Object.keys(sectionFieldsList).map((section) => sectionFieldsList[section]).flat(),
      ...newFields,
    ].some((f) => f.name?.toLowerCase() === fieldName.toLowerCase());
  };

  export const groupFieldsIntoSections = (fields: Record<string, any>[]): Record<string, Record<string, any>[]> => {
    return fields.reduce((acc, field) => {
      if (!acc[field.section]) {
        acc[field.section] = [];
      }
      acc[field.section].push(field);
      return acc;
    }, {});
  };

  export const isSubmitBtnDisabled = (
    isSubmitting: boolean,
    isEdit: boolean,
    newFields: Record<string, any>[],
    errors: FormikErrors<{
      name: string;
      section: string;
      type: string;
      description: string;
    }>,
    touched: FormikTouched<{
      name: string;
      section: string;
      type: string;
      description: string;
    }>
  ) => {
    if (isSubmitting) return true;
    if (!isEdit && newFields.length === 0) return true;
    let hasErrors = false;
    Object.keys(errors).forEach((key) => {
      if (errors[key as keyof FormikErrors<{
        name: string;
        section: string;
        type: string;
        description: string;
      }>] && touched[key as keyof FormikTouched<{
        name: string;
        section: string;
        type: string;
        description: string;
      }>]) {
        hasErrors = true;
      }
    });
    return hasErrors;
  };

  export const editFieldInitialValue = (
    key: string,
    selectedField: Record<string, string> | null,
    t: TFunction<"translation", undefined>,
    i18n: i18n,
  ) => {
    if (!selectedField || !selectedField?.key) return '';
    if (selectedField[key] && i18n.exists(selectedField[key])) {
      return t(selectedField[key]);
    }
    return selectedField[key];
  };
