import React, {
  useCallback, useContext, useEffect, useState
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Axios from 'axios';
import AnnounceKit from "announcekit-react";

import {
  Typography, AppBar, Box, Toolbar, IconButton, Menu, MenuItem, Avatar, Button, Tooltip, Badge, ListItem,
  Grid, SvgIcon, Link
} from '@mui/material';
import {
  Menu as MenuIcon,
  HelpOutlineRounded as HelpCenterIcon,
  ExpandMore as ExpandMoreIcon,
  ChevronLeft as ChevronLeftIcon,
  Receipt as ReceiptIcon,
  People as PeopleIcon,
  AutoAwesome as NewUIIcon,
  NotificationsNone as NotificationsNoneIcon,
} from '@mui/icons-material';

import ConfigContext from 'src/contexts/ConfigContext';
import authService from 'src/utils/authService';
import { useAuth } from 'src/hooks/useAuth';
import {
  externalLinks, navItems, navItemsDimensions, navItemsSx
} from 'src/config';
import { appendContactSupport, axiosHeaders, getLocalisedErrorString } from 'src/utils/helpers';
import styles from './style';

const TopBar = () => {
  const {
    BRAND_DOMAIN, API, IS_BRAND_PROCYS, LOGIN_PANEL_URL, APP_VERSION, SUPPORT_EMAIL
  } = useContext(ConfigContext);
  const announceKitRef = React.createRef();
  const [unread, setUnread] = React.useState(0);

  const location = useLocation();
  const navigate = useNavigate();
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const auth = useAuth();
  const {user} = auth;

  const [anchorElDim, setAnchorElDim] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElCompanies, setAnchorElCompanies] = useState(null);

  const [selectedOffice, setSelectedOffice] = useState(user.companyID);
  const [companies, setCompanies] = useState([]);

  const isPassportOrID = user?.documentType === 'id';
  const isOldAnnotationInterface = user?.customisations.includes('oldAnnotationInterface');

  React.useEffect(() => {
    if (announceKitRef.current) {
      announceKitRef.current.unread().then((unread) => setUnread(unread));
    }
  }, [announceKitRef]);

  const getLogoClass = () => {
    if (IS_BRAND_PROCYS) {
      return {
        maxWidth: 100,
        maxHeight: 60
      };
    }

    if (BRAND_DOMAIN === 'docuindexa.net') {
      return {
        maxWidth: 120,
        maxHeight: 40,
        position: 'relative',
        top: -6
      };
    }

    return {
      maxWidth: 120,
      maxHeight: 40
    };
  };

  const getOffices = useCallback(async () => {
    try {
      const response = await Axios.get(
        `${API.getCompaniesByUser}${user.email}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data.length > 0) {
          const companiesResp = response.data.data;
          companiesResp.sort((a, b) => {
            if (a.name > b.name) {
              return 1;
            }
            return -1;
          });

          const listCompanies = [];
          for (let i = 0; i < companiesResp.length; i++) {
            const company = companiesResp[i];
            if (company?.companyID?.toString() !== user.companyID && company.isMember) {
              listCompanies.push(company);
            }
          }

          setCompanies(listCompanies);
        }
      }
    } catch (e) {
      console.error('Error getting companies: ', e);
    }
  }, [API.getCompaniesByUser, user.companyID, user.email]);

  useEffect(() => {
    if (user && user.role === 'user') {
      getOffices();
    }
  }, [getOffices, user.role]);

  const changeOffice = async (name) => {
    try {
      const response = await Axios.get(
        `${API.changeCompany}/${encodeURIComponent(name)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(t('ACCOUNT_SETTINGS_COMPANIES_CHANGE_SUCCESS'), {
          variant: 'success',
        });
        setTimeout(() => {
          const url = window.location.origin + window.location.pathname;
          window.history.replaceState({}, document.title, url);
          if (window.location.pathname.indexOf('documents/') >= 0) {
            window.location.href = '/documents';
          } else {
            window.location.reload();
          }
        }, 1000);
      }
    } catch (e) {
      enqueueSnackbar(appendContactSupport(
        SUPPORT_EMAIL,
        getLocalisedErrorString(e?.response?.data?.i18n || 'ACCOUNT_SETTINGS_COMPANIES_CHANGE_FAILURE', t),
        t
      ), {
        variant: 'error',
      });
    }
  };

  const handleSelectOffice = (office) => {
    // CHECK THIS
    if (office.companyID !== selectedOffice) {
      setSelectedOffice(office.companyID);
      changeOffice(office.companyID);
    }
    handlePopoverClose();
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleNavigation = (e, href) => {
    if (href === '/dimensions') {
      toggleDimMenu(e);
      return;
    }
    navigate(href);
  };

  const toggleDimMenu = (event) => {
    setAnchorElDim(event ? event.currentTarget : null);
  };

  const handleNavigateDim = (href) => {
    toggleDimMenu();
    navigate(href);
  };

  const handleNavigateUserProfile = (href) => {
    handleCloseUserMenu();
    navigate(href);
  };

  const handlePopoverOpen = (event) => {
    setAnchorElCompanies(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorElCompanies(null);
  };

  const clickOnSubscription = () => {
    if (((user.isSubCompany || user.isPartnerSubCompany) && user.parent) || user.customisations.includes('hideBilling')) {
      return;
    }
    navigate('/subscription-plan');
  };

  const onLogout = () => {
    authService.logout(LOGIN_PANEL_URL);
  };

  const updateAnnotationFlowPreference = async () => {
    if (!isOldAnnotationInterface) {
      return;
    }
    if (!authService.validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }
    try {
      const response = await Axios.put(
        `${API.customisations}`,
        { customisation: 'oldAnnotationInterface', enabled: false },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(t('ANNOTATION_INTERFACE_UPDATE_SUCCESS'), {
          variant: 'success',
        });
        navigate('/');
      } else {
        enqueueSnackbar(t('ANNOTATION_INTERFACE_UPDATE_FAILURE'), {
          variant: 'error',
        });
      }
    } catch (error) {
      enqueueSnackbar(appendContactSupport(
        window.config.support_email,
        getLocalisedErrorString(error?.response?.data?.i18n || 'ANNOTATION_INTERFACE_UPDATE_FAILURE', t),
        t
      ), {
        variant: 'error',
      });
    }
  };

  const onClickKBLink = () => {
    // Update the URL with the query parameter chat=support
    const currentUrlParams = new URLSearchParams(window.location.search);
    if (!currentUrlParams.has('chat')) {
      currentUrlParams.append('chat', 'support');
      navigate(`${window.location.pathname}?${currentUrlParams.toString()}`);
    }

    if (window && window.HubSpotConversations && window.HubSpotConversations.widget) {
      // Reference to the below here:
      // https://developers.hubspot.com/beta-docs/reference/api/conversations/chat-widget-sdk#widget.load?uuid=cf3ef811-dd96-4c1a-8fd6-f51ef9deac91
      window.HubSpotConversations.widget.load({ widgetOpen: true });
    } else {
      window.open(externalLinks.knoldgeBase.replace('__LANG__', i18n.language.split('-')[0] !== 'en'
      ? `/${i18n.language.split('-')[0]}` : ''), '_blank')
    }
  };

  const openAnnounceKitWidget = async () => {
    if (announceKitRef.current) {
      await announceKitRef.current.open();
    }
  };

  const renderAnnounceKitNotifications = () => IS_BRAND_PROCYS && (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <AnnounceKit
        ref={announceKitRef}
        user={{
          company_id: user.companyID,
          subscription_name: user.subscription,
          parent_company_id: user.parent,
          email: user.email,
          name: `${user.firstname} ${user.lastname}`,
          company: user.company,
          parent_company_name: user.parentName,
        }}
        widget="https://announcekit.co/widgets/v2/qewhO"
      >
        <Badge className="ak-badge" badgeContent={unread} color="primary" max={9}>
          <IconButton style={styles.notificationIcon} onClick={async () => await openAnnounceKitWidget()}>
            <NotificationsNoneIcon />
          </IconButton>
        </Badge>
      </AnnounceKit>
    </div>
  );

  const renderDropdownDimChild = ({href, title, icon: Icon}) => {
    if (user?.interfacePreference === 'twinfield_interface' && href !== '/dimensions/clients') return null;
    return (
      <MenuItem
        key={title}
        sx={location.pathname?.indexOf(href) >= 0 ? styles.menuActionContainerSelected : styles.menuActionContainer}
        onClick={() => handleNavigateDim(href)}
        component={Link}
        href={href}
      >
        <Icon
          style={styles.subMenuIcon}
        />
        {ready && t(title)}
      </MenuItem>
    )
  };

  const renderDimensionsMenu = () => (
    <Menu
      id="dimensions-menu"
      anchorEl={anchorElDim}
      elevation={0}
      getcontentanchorel={null}
      open={Boolean(anchorElDim)}
      onClose={() => toggleDimMenu()}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      sx={{
        ".MuiMenu-paper": styles.menuContainer,
        ".MuiMenu-list": styles.menuList
      }}
    >
      {navItemsDimensions.map((item) => renderDropdownDimChild(item))}
    </Menu>
  );

  const renderKBLink = () => IS_BRAND_PROCYS && (
    <Tooltip title={t('DASHBOARD_NAV_KB_LINK')}>
      <IconButton
        sx={styles.kbIcon}
        onClick={() => onClickKBLink()}
      >
        <HelpCenterIcon />
      </IconButton>
    </Tooltip>
  );

  const renderNavItems = () => {
    return navItems.filter((item) => !(item.href === '/dimensions' && isPassportOrID)).map((item) => {
      const {title, href, icon: Icon} = item;
      const open = location.pathname?.indexOf(href) >= 0;
      return (
        <ListItem
          sx={styles.itemLeaf}
          disableGutters
          key={title}
        >
          <Grid style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Button
              className="depth-0"
              sx={open ? {...styles.buttonLeaf, ...styles.active} : styles.buttonLeaf}
              style={{ paddingLeft: 8 }}
              onClick={(e) => handleNavigation(e, href)}
              href={href !== '/dimensions' ? href : ''}
            >
              {Icon && (
                <Icon
                  style={open ? styles.iconActive : styles.icon}
                />
              )}
              <span style={open ? styles.titleActive : styles.title}>
                {ready && t(title)}
              </span>
            </Button>
          </Grid>
        </ListItem>
      );
    })
  };

  const renderUserMenu = () => (
    <Menu
      id="user-menu"
      anchorEl={anchorElUser}
      elevation={0}
      getcontentanchorel={null}
      keepMounted
      open={Boolean(anchorElUser)}
      onClose={handleCloseUserMenu}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      sx={{
        ".MuiMenu-paper": styles.menuContainer,
      }}
    >
      <Grid style={styles.userInfoMenuitem}>
        <Avatar alt="User" src={user.avatar} style={styles.avatar} />
        <Grid style={styles.userAccount}>
          <Typography style={styles.menuName}>
            {`${user?.firstname} ${user?.lastname}`}
          </Typography>
          <Typography style={styles.menuEmail}>
            {user?.email}
          </Typography>
          {
            user.role === 'user' && (
              <MenuItem
                sx={styles.menuActionContainerTop}
                onClick={() => handleNavigateUserProfile('/settings')}
              >
                {ready && t('DASHBOARD_NAV_ACCOUNT_SETTING')}
              </MenuItem>
            )
          }
        </Grid>
      </Grid>
      <Grid
        sx={styles.menuCompanyContainer}
        aria-label="offices"
        aria-controls="office-menu"
        aria-haspopup="true"
        onClick={handlePopoverOpen}
      >
        {user.role !== 'company' && <ChevronLeftIcon style={styles.moreIcon} />}
        <Grid sx={styles.companyItems}>
          <Typography noWrap sx={styles.menuCompanyName}>
            {user?.company}
          </Typography>
          <Typography sx={styles.menuCompany}>
            {user?.companyID && `${ready && t('TOPBAR_USERMENU_ID')}: ${user?.companyID}`}
          </Typography>
          <Typography sx={styles.menuCompany}>
            {user?.vatNumber && `${ready && t('TOPBAR_USERMENU_VAT')}: ${user?.vatNumber}`}
          </Typography>
          {
            user.role === 'user' && (
              <Typography sx={styles.menuCompany}>
                {`${ready && t('TOPBAR_USERMENU_OFFICE')}: ${selectedOffice}`}
              </Typography>
            )
          }
        </Grid>
      </Grid>
      {companies && companies.length >= 1 && (
        <Menu
          id="office-menu"
          anchorEl={anchorElCompanies}
          keepMounted
          open={Boolean(anchorElCompanies)}
          getcontentanchorel={null}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            ".MuiMenu-paper": styles.paper,
            ".MuiMenu-list": styles.officeMenuPadding
          }}
        >
          {companies.map((office, i) => {
            if (office.companyID?.toString() !== user.companyID && office.isMember) {
              return (
                <MenuItem
                  key={office.companyID}
                  sx={
                    i === 0
                      ? styles.menuActionContainerTop
                      : styles.menuActionContainer
                  }
                  onClick={() => handleSelectOffice(office)}
                >
                  {office.name}
                </MenuItem>
              );
            }

            return null;
          })}
        </Menu>
      )}
      <Grid sx={{ display: {sx: 'flex', md: 'none'}, flexDirection: 'column' }}>
        {navItemsSx.filter((item) => !(item.href === '/dimensions/clients' && isPassportOrID)).map((item) => (
          <MenuItem
            key={item.href}
            style={location.pathname?.indexOf(item.href) >= 0 ? styles.menuActionContainerSelected : styles.menuActionContainer}
            onClick={() => handleNavigateUserProfile(item.href)}
            href={item.href}
          >
            <Typography sx={{ fontSize: 14 }} textAlign="center">{t(item.title)}</Typography>
          </MenuItem>
        ))}
      </Grid>
      {user.role === 'user' && !user.customisations.includes('hideBilling') &&
        (<MenuItem
          sx={window.location.href.indexOf('/subscription-plan') >= 0 ? styles.menuActionContainerSelected : styles.menuActionContainer}
          onClick={() => handleNavigateUserProfile('/subscription-plan')}
        >
          {ready && t('DASHBOARD_NAV_PLAN_N_BILLING')}
        </MenuItem>
      )}
      <Grid sx={styles.logoutMenuItem}>
        <Typography sx={styles.menuLogout} onClick={onLogout}>
          {ready && t('DASHBOARD_ACTION_LOGOUT')}
        </Typography>
        <Typography sx={styles.version}>
          {APP_VERSION}
        </Typography>
      </Grid>
    </Menu>
  );

  return (
    <AppBar style={styles.container}>
      <Toolbar disableGutters sx={styles.toolbar}>
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="user-menu"
            aria-haspopup="true"
            onClick={handleOpenUserMenu}
            color="inherit"
          >
            <MenuIcon sx={styles.menuIcon} />
          </IconButton>
          {renderUserMenu()}
          {renderKBLink()}
          {renderAnnounceKitNotifications()}
        </Box>
        <Box sx={{ flexGrow: { xs: 1, md: 0 }}}>
          <img
            src={`${API.brandLogo}${BRAND_DOMAIN}`}
            alt=""
            style={getLogoClass()}
          />
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', paddingBottom: 0 } }}>
          {renderNavItems()}
          {renderDimensionsMenu()}
        </Box>

        {!user?.disableUISwitch && user?.userRole === 'admin' && (
          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
            <Tooltip title={t(!isOldAnnotationInterface ? 'SWITCH_TO_NEW_ANNOTATION_FLOW_DISABLED_TOOLTIP' : 'SWITCH_TO_NEW_ANNOTATION_FLOW_TOOLTIP')}>
              <IconButton onClick={updateAnnotationFlowPreference}>
                <NewUIIcon sx={styles.newUIIcon} />
              </IconButton>
            </Tooltip>
          </Box>
        )}

        <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
          <Grid style={styles.subscriptionContainer}>
            <Tooltip
              title={t('TOPBAR_SUBSCRIPTION_TOOLTIP')}
              disableHoverListener={!((user.isSubCompany || user.isPartnerSubCompany) && user.parent)}
            >
              <Typography
                sx={styles.subscriptionName}
                component={Button}
                onClick={clickOnSubscription}
              >
                {user.subscription}
              </Typography>
            </Tooltip>
            <Tooltip title={
              user.maxInvoices > 0
                ? t('TOPBAR_DOCUMENTS_UPLOADED', { invoicesCount: user.invoicesCount || 0, maxInvoices: user.maxInvoices || 0 })
                : t('TOPBAR_DOCUMENTS_UPLOADED_CUSTOM', { invoicesCount: user.invoicesCount || 0 })
              }
            >
              <button type="button" style={styles.subscriptionName}>
                <Grid container direction="row">
                  <ReceiptIcon style={styles.subsIcon} />
                  <span style={styles.subStatValue}>
                    {
                      user.maxInvoices > 0
                        ? `${user.invoicesCount || 0}/${user.maxInvoices || 0}`
                        : `${user.invoicesCount || 0}`
                    }
                  </span>
                </Grid>
              </button>
            </Tooltip>
            <Tooltip title={t('TOPBAR_USERS_USED', { usersCount: user.usersCount || 0, maxUsers: user.maxUsers || 0 })}>
              <button type="button" style={styles.subscriptionName}>
                <Grid container direction="row">
                  <PeopleIcon style={styles.subsIcon} />
                  <span style={styles.subStatValue}>
                    {`${user.usersCount || 0}/${user.maxUsers || 0}`}
                  </span>
                </Grid>
              </button>
            </Tooltip>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
          {renderKBLink()}
          {renderAnnounceKitNotifications()}
          <Button
            aria-controls="user-menu"
            aria-haspopup="true"
            onClick={handleOpenUserMenu}
            sx={{
              padding: '6px 8px',
              ".MuiButton-label": styles.profileBtn,
            }}
          >
            <Grid style={styles.profileBtnRoot}>
              <Grid style={styles.btnRow}>
                <Avatar alt="User" src={user.avatar} style={styles.avatar} />
                <SvgIcon fontSize="small">
                  <ExpandMoreIcon style={styles.expandMoreIcon} />
                </SvgIcon>
              </Grid>
              <Typography noWrap style={styles.companyName}>{user.company}</Typography>
            </Grid>
          </Button>
          {renderUserMenu()}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar
