/* eslint-disable no-nested-ternary */
import React, {
  useEffect, useState, useRef, useContext, useMemo
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import Axios from 'axios';
import FileSaver from 'file-saver';

import {
  Button, Grid, InputAdornment, SvgIcon, TextField, Tooltip, Typography,
  IconButton, useMediaQuery
} from '@mui/material';
import LogsIcon from '@mui/icons-material/CardTravel';
import AddCircle from '@mui/icons-material/AddCircle';
import SearchIcon from '@mui/icons-material/Search';
import ImportIcon from '@mui/icons-material/SystemUpdateAlt';
import ExportIcon from '@mui/icons-material/OpenInBrowser';

import Page from 'src/shared/screens/Page';
import CostCentersTable from 'src/dimensions/costcenters/components/CostCentersTable/CostCentersTable';
import LoadingIndicator from 'src/shared/components/LoadingIndicator';
import authService from 'src/utils/authService';
import {
  axiosHeaders, isActionPermitted, permissions
} from 'src/utils/helpers';
import CostCenterModal from 'src/dimensions/costcenters/components/CostCenterModal/CostCenterModal';
import DimensionExportPopper from 'src/dimensions/components/DimensionExportPopper/DimensionExportPopper';
import DimensionImportModal from 'src/dimensions/components/DimensionImportModal/DimensionImportModal';

import ConfigContext from 'src/contexts/ConfigContext';
import { fetchCostCenters } from 'src/dimensions/costcenters/actions';
import { useAuth } from 'src/hooks/useAuth';
import styles from './style';
import theme from "src/theme";

function CostCenters() {
  const { LOGIN_PANEL_URL, API, BRAND_NAME } = useContext(ConfigContext);
  const navigate = useNavigate();
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const _costCenters = useSelector((state) => state.costcenter.costCenters);
  const costCenters = useMemo(() => _costCenters, [_costCenters]);
  const total = useSelector((state) => state.costcenter.total);
  const loading = useSelector((state) => state.costcenter.loading);

  const [query, setQuery] = useState('');

  const [costCenterModalOpen, setCostCenterModalOpen] = useState(false);
  const [selectedCostCenter, setSelectedCostCenter] = useState(null);

  const [exportOptionsOpen, setExportOptionsOpen] = useState(false);
  const exportBtnRef = useRef(null);

  const [importModalOpen, setImportModalOpen] = useState(false);

  const enabled = true;
  const isTwinfield = user.accountingSystem === 'twinfield';

  const maxWidth = useMediaQuery('(min-width: 830px)');
  const maxWidth2 = useMediaQuery('(min-width: 530px)');

  const onCostCenterModalClose = () => {
    setCostCenterModalOpen(false);
    setTimeout(() => setSelectedCostCenter(null), 150);
  };

  const gotoItem = (code) => {
    if (!isTwinfield) {
      setSelectedCostCenter(code);
      setCostCenterModalOpen(true);
    }
  };

  const getAllCostCenters = async () => {
    dispatch(fetchCostCenters(user.companyID));
  };

  useEffect(() => {
    if (isActionPermitted(permissions.dimensionRead, user.permissions)) {
      getAllCostCenters();
    }
  }, []);

  const handleAdd = () => {
    if (!authService.validateToken()) {
      enqueueSnackbar(ready && t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
        persist: true
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }

    setSelectedCostCenter(null);
    setCostCenterModalOpen(true);
  };

  const handleQueryChange = (e) => {
    e.persist();
    setQuery(e.target.value);
  };

  const exportOptionsClose = (event) => {
    if (exportBtnRef.current && exportBtnRef.current.contains(event.target)) {
      return;
    }
    setExportOptionsOpen(false);
  };

  const handleImportModalClose = () => {
    setImportModalOpen(false);
  };

  const handleDimensionExport = async (format) => {
    try {
      const response = await Axios.get(
        `${API.dimensions}costcenters/export/${format}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      const fileName = `${user.company} Cost Centers.${format}`;
      const blob = new Blob([response.data], { type: 'text/plain;charset=utf-8' });
      FileSaver.saveAs(blob, fileName);
      setExportOptionsOpen(false);
    } catch (error) {
      enqueueSnackbar(t('DIMENSION_EXPORT_FAIL'), {
        variant: 'error',
        persist: false
      });
    }
  };

  const searchBar = (
    <Grid className={'search-bar'} style={styles.searchBar}>
      <Tooltip title={t('DASHBOARD_SEARCH_COST_CENTER_SEARCH_TOOLTIP')}>
        <TextField
          style={styles.queryField}
          onChange={handleQueryChange}
          placeholder={ready && t('DASHBOARD_SEARCH_COST_CENTER_SEARCH')}
          value={query}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon
                  fontSize="small"
                  color="action"
                  style={{ fill: theme.palette.icon.gray }}
                >
                  <SearchIcon style={{ color: theme.palette.icon.gray }} />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
          sx={{
            '& .MuiOutlinedInput-root': styles.searchBarInput,
          }}
        />
      </Tooltip>
    </Grid>
  );

  const logsList = (
    <Tooltip title={t('COST_CENTERS_LIST_VIEW_LOGS_TOOLTIP')}>
      <IconButton
        style={styles.logsIconContainer}
        onClick={() => navigate(`/dimensions/logs/costcenters/company/${user.companyID}`)}
      >
        <LogsIcon
          style={{ ...styles.logsIcon, fontSize: '1.8rem' }}
        />
      </IconButton>
    </Tooltip>
  );

  const titleText = (
    <Typography style={styles.title}>
      {ready && t('DASHBOARD_COST_CENTERS')}
    </Typography>
  );

  const addButton = (
    <Tooltip
      title={isActionPermitted(permissions.dimensionAdd, user.permissions)
        ? ready && t('COST_CENTERS_ADD_NEW_TOOLTIP')
        : ready && t('COST_CENTERS_ADD_NEW_TOOLTIP_PERMISSION')}
    >
      <Button
        color="secondary"
        type="submit"
        variant="contained"
        style={styles.addBtn}
        onClick={handleAdd}
        disabled={!isActionPermitted(permissions.dimensionAdd, user.permissions)}
      >
        <SvgIcon
          fontSize="small"
          style={styles.actionIcon}
        >
          <AddCircle />
        </SvgIcon>
        {ready && t('COST_CENTERS_ADD_NEW')}
      </Button>
    </Tooltip>
  );

  const importBtn = (
    <Tooltip
      title={!isActionPermitted(permissions.dimensionEdit, user.permissions)
        ? ready && t('DIMENSIONS_NO_PERMISSION_TO_MANAGE_TOOLTIP')
        : ''}
    >
      <span>
        <Button
          variant="outlined"
          style={styles.importExportBtn}
          onClick={() => setImportModalOpen(true)}
          disabled={!isActionPermitted(permissions.dimensionEdit, user.permissions)}
        >
          <SvgIcon
            fontSize="small"
            style={styles.importExportIcon}
            color={!isActionPermitted(permissions.dimensionEdit, user.permissions) ? 'rgba(0, 0, 0, 0.26)' : '#546e7a'}
          >
            <ImportIcon />
          </SvgIcon>
          {ready && t('DIMENSION_IMPORT_BTN')}
        </Button>
      </span>
    </Tooltip>
  );

  const exportBtn = (
    <Tooltip
      title={!isActionPermitted(permissions.dimensionEdit, user.permissions)
        ? ready && t('DIMENSIONS_NO_PERMISSION_TO_MANAGE_TOOLTIP')
        : costCenters.length === 0
          ? ready && t('CC_NO_DATA_TO_EXPORT')
          : ''}
    >
      <span>
        <Button
          ref={exportBtnRef}
          variant="outlined"
          style={styles.importExportBtn}
          onClick={() => { setExportOptionsOpen(true); }}
          disabled={!isActionPermitted(permissions.dimensionEdit, user.permissions) || costCenters.length === 0}
        >
          <SvgIcon
            fontSize="small"
            style={styles.importExportIcon}
            color={!isActionPermitted(permissions.dimensionEdit, user.permissions) ? 'rgba(0, 0, 0, 0.26)' : '#546e7a'}
          >
            <ExportIcon />
          </SvgIcon>
          {ready && t('DIMENSION_EXPORT_BTN')}
        </Button>
        <DimensionExportPopper
          exportOptionsOpen={exportOptionsOpen}
          exportBtnRef={exportBtnRef}
          exportOptionsClose={exportOptionsClose}
          t={t}
          onOptionClick={handleDimensionExport}
        />
      </span>
    </Tooltip>
  );

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Page
      style={styles.root}
      title={ready && `${t('COST_CENTER_LIST_PAGE_TITLE')} | ${BRAND_NAME}`}
    >
      <Grid style={{ ...styles.header, ...styles.headerMargin }}>
        {
          maxWidth ? (
            <Grid style={styles.header}>
              {titleText}
              {searchBar}
              {importBtn}
              {exportBtn}
              {
                !isTwinfield && (
                  <>
                    {enabled && logsList}
                    {addButton}
                  </>
                )
              }
            </Grid>
          ) : maxWidth2 ? (
            <Grid style={styles.headerColumn}>
              {titleText}
              <Grid style={styles.actionRow}>
                {searchBar}
                {enabled && logsList}
              </Grid>
              <Grid style={styles.importExportRow}>
                {importBtn}
                {exportBtn}
                {
                  !isTwinfield && addButton
                }
              </Grid>
            </Grid>
          ) : (
            <Grid style={styles.headerColumn}>
              {titleText}
              <Grid style={styles.actionRow}>
                {searchBar}
                {enabled && logsList}
              </Grid>
              <Grid style={styles.importExportRow}>
                {importBtn}
                {exportBtn}
              </Grid>
              {
                !isTwinfield && addButton
              }
            </Grid>
          )
        }
      </Grid>
      <CostCentersTable
        costCenters={costCenters}
        getAllCostCenters={getAllCostCenters}
        total={total}
        query={query}
        isTwinfield={isTwinfield}
        gotoItem={gotoItem}
      />
      <CostCenterModal
        costCenterModalOpen={costCenterModalOpen}
        selectedCostCenter={selectedCostCenter}
        handleClose={onCostCenterModalClose}
        getAllCostCenters={getAllCostCenters}
      />
      <DimensionImportModal
        dimensionImportModalOpen={importModalOpen}
        handleClose={handleImportModalClose}
        dimension="cc"
        fetchDimenstions={getAllCostCenters}
      />
    </Page>
  );
}

export default CostCenters;
