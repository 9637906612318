import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';

import {
  Box, Button, Card, CardContent, FormControl, FormHelperText, Grid, InputAdornment,
  MenuItem, Select, TextField, Tooltip, Autocomplete, Typography
} from '@mui/material';
import {
  Checklist as FieldsIcon, Edit as EditIcon, AddBox as PlusIcon
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import {
  axiosHeaders, isActionPermitted, isShowManageFields, permissions
} from 'src/utils/helpers';
import { supplierData, vatCodeTypes } from 'src/config';
import ConfigContext from 'src/contexts/ConfigContext';
import LoadingIndicator from 'src/shared/components/LoadingIndicator';
import ManageFieldsNew from 'src/shared/components/ManageFieldsNew/ManageFieldsNew';
import { CostCenterType, FieldType, GLAccountType, VatGroupType } from 'src/types';
import { FormikErrors } from 'formik';
import { filterAutocompleteOptionsCostCenter, filterAutocompleteOptionsGLA } from 'src/dimensions-new/utils';
import theme from 'src/theme';

type PropsType = {
  errors: Record<string, string>,
  handleBlur:React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleSubmit: () => void;
  setErrors: (_errors: FormikErrors<Record<string, string>>) => void;
  isSubmitting: boolean;
  touched: Record<string, string | boolean>;
  values: Record<string, string | boolean>;
  vatCode: VatGroupType | null;
  handleClose: () => void;
  handleChangeAutocomplete: (_e:React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | 
    React.SyntheticEvent<Element, Event>, _prop:string, _values:Record<string,string | boolean>, _handleChange:Function) => void;
  costCenters: CostCenterType[];
  glaccounts:  GLAccountType[];
  handleEditCostCenter: (_cc: string) => void;
  handleAddCostCenter: (_cc: string) => void;
  newlyAddedVal: string;
  newlyAddingProp: string;
  isDisabled: boolean;
}

const VatCodeStandard = (props: PropsType) => {
  const { t, ready } = useTranslation();

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setErrors,
    isSubmitting,
    touched,
    values,
    vatCode,
    handleClose,
    handleChangeAutocomplete,
    costCenters,
    glaccounts,
    handleEditCostCenter,
    handleAddCostCenter,
    newlyAddedVal,
    newlyAddingProp,
    isDisabled,
  } = props;
  const { API } = useContext(ConfigContext);
  
  const auth = useAuth();
  const {user} = auth;

  const [vatFields, setVatFields] = useState([]);
  const [showManageFields, setShowManageFields] = useState(false);
  const [loading, setLoading] = useState(false);

  const getVatFields = async () => {
    try {
      const response = await Axios.get(
        `${API.fields}/vat/${encodeURIComponent(user?.companyID as string)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setVatFields(response.data.data.filter((f:FieldType) => f.isAvailable));
          setLoading(false);
        } else {
          setVatFields([]);
          setLoading(false);
        }
      }
    } catch (error) {
      setVatFields([]);
      setLoading(false);
    }
  };

  const handleCloseManageFields = () => {
    setShowManageFields(false);
  };

  useEffect(() => {
    if (newlyAddedVal && newlyAddingProp) {
      handleChange({ target: { name: newlyAddingProp, value: newlyAddedVal } }  as unknown as React.ChangeEvent<HTMLInputElement 
      | HTMLTextAreaElement>
      );
    }
  }, [newlyAddedVal]);

  useEffect(() => {
    getVatFields();
  }, []);

  const isFieldAvailable = (field:FieldType) => {
    if (field.isMandatory) {
      return false;
    }
    if (field.isActive) {
      return true;
    }
    return false;
  };

  return (
    <>
      
      {
        !showManageFields ?
        <form style={styles.form}>
        {loading ? (<LoadingIndicator />) : (
          <Card sx={styles.cardContainer}>
            {isShowManageFields(user, 'applyParentFieldsVAT') && (
              <Grid mt={2} item xs={12} sx={styles.topManager}>
                <Button
                  variant="outlined"
                  sx={styles.manageFieldsBtn}
                  onClick={() => setShowManageFields(true)}
                >
                  <FieldsIcon sx={styles.btnIcon} />
                  {t('INVOICE_EDIT_FORM_MANAGE_FIELDS')}
                </Button>
              </Grid>
            )}
            <CardContent className="y-scroll" sx={styles.cardContent}>
              <Grid item key="name" sx={styles.eachRow}>
                <Typography>{ready && t('VAT_CODE_NAME')}</Typography>
                <TextField
                  disabled={isDisabled}
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && (ready && t(errors.name))}
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  value={values.name}
                  variant="outlined"
                  sx={styles.inputField}
                />
              </Grid>
              <Grid item key="ratePercentage" sx={styles.eachRow}>
                <Typography>{ready && t('VAT_CODE_RATE_PERCENTAGE')}</Typography>
                <TextField
                  disabled={isDisabled}
                  error={Boolean(touched.ratePercentage && errors.ratePercentage)}
                  fullWidth
                  helperText={touched.ratePercentage && (ready && t(errors.ratePercentage))}
                  name="ratePercentage"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  value={values.ratePercentage}
                  variant="outlined"
                  sx={styles.inputField}
                />
              </Grid>
              {
                vatFields.map((f: FieldType,index:number) => {
                  if (!isFieldAvailable(f)) {
                    return null;
                  }
                  switch (f.key) {
                    case 'type':
                      return (
                        <Grid item key={f.key+index} sx={styles.eachRow}>
                          <Typography>{ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}</Typography>
                          <FormControl variant="outlined" sx={styles.formControl}>
                            <Select
                              disabled={isDisabled}
                              labelId={`${f.key}-label`}
                              id={f.key}
                              error={Boolean(touched[f.key] && errors[f.key])}
                              value={values[f.key]}
                              onClick={() => setErrors({})}
                              onChange={handleChange as any}
                              name={f.key}
                              required={f.isRequired}
                              sx={styles.selectStyle}
                            >
                              {vatCodeTypes.map((s) => {
                                if (s) {
                                  return (
                                    <MenuItem key={s.name} value={s.name}>
                                      {t(s.label)}
                                    </MenuItem>
                                  );
                                }

                                return null;
                              })}
                            </Select>
                            <FormHelperText error>
                              {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      );
                    case 'ruleCostCenter':
                      return (
                        <Grid item key={f.key+index} sx={styles.eachRow} >
                          <Typography>
                            {ready && t(f.label)}
                          </Typography>
                          <FormControl variant="outlined" sx={styles.formControl}>
                            <Autocomplete
                              disabled={isDisabled}
                              fullWidth
                              onChange={(e, v) => handleChangeAutocomplete(e, 'ruleCostCenter', v as unknown as Record<string, any>, handleChange)}
                              value={costCenters && values.ruleCostCenter &&
                                costCenters.find((e: CostCenterType) => e.code === values.ruleCostCenter) || null}
                              options={costCenters}
                              getOptionLabel={(option) => option && option.code ? `${option.code} | ${option.name}` : ''}
                              isOptionEqualToValue={(option) => option.code === values.ruleCostCenter}
                              filterOptions={(options, state) => filterAutocompleteOptionsCostCenter(options, state)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (isDisabled ? <></> :
                                      <>
                                        {values[f.key] !== supplierData.defaultCostCenters[0].code && (
                                          <InputAdornment
                                            position="end"
                                            onClick={() => handleEditCostCenter(values[f.key] as string)}
                                            sx={styles.editIconContainer}
                                          >
                                            <EditIcon sx={styles.editDimensionIcon} />
                                          </InputAdornment>
                                        )}
                                        <InputAdornment position="end" sx={styles.plusIconContainer}>
                                          <PlusIcon sx={styles.plusIcon} onClick={() => handleAddCostCenter(f.key as string)} />
                                        </InputAdornment>
                                      </>
                                    ),
                                  }}
                                  sx={styles.autoCompleteInput}
                                />
                              )}
                            />
                            <FormHelperText error>
                              {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      );
                    case 'ruleAccount':
                      return (
                        <Grid item lg={6} key={f.key+index} sx={styles.eachRow} >
                          <Typography>{ready && t(f.label)}</Typography>
                          <FormControl variant="outlined" sx={styles.formControl}>
                            <Autocomplete
                              disabled={isDisabled}
                              fullWidth
                              onChange={(e, v) => handleChangeAutocomplete(e, 'ruleAccount', v as unknown as Record<string, any>, handleChange)}
                              value={glaccounts && values.ruleAccount && glaccounts.find((e: GLAccountType) => e?.code === values?.ruleAccount) || null}
                              options={glaccounts}
                              filterOptions={(options, state) => filterAutocompleteOptionsGLA(options, state)}
                              getOptionLabel={(option) => (option && option.code ? `${option.code} | ${option.name}` : '')}
                              isOptionEqualToValue={(option: GLAccountType) => option.code === values.ruleAccount}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  InputProps={{
                                    ...params.InputProps
                                  }}
                                  sx={styles.autoCompleteInput}
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                      );
                    default:
                      return (
                        <Tooltip key={f.key} title={vatCode && f.key === 'code' ? t('DIMENSION_CODE_CANNOT_EDIT') : ''}>
                          <Grid item sx={styles.eachRow}>
                            <Typography>
                              {ready && t(f.label)}
                            </Typography>
                            <TextField
                              error={Boolean(touched[f.key] && errors[f.key])}
                              fullWidth
                              helperText={touched[f.key] && (ready && t(errors[f.key]))}
                              name={f.key}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              required={f.isRequired}
                              value={values[f.key]}
                              variant="outlined"
                              sx={styles.inputField}
                              disabled={isDisabled ? true : vatCode ? f.key === 'code' : false}
                            />
                          </Grid>
                        </Tooltip>
                      );
                  }
                })
              }
              <Box sx={{ flex: 1 }} />
              {errors.submit && (
                <Grid item xs={12}>
                  <Box mt={3}>
                    <FormHelperText error>
                      {errors.submit}
                    </FormHelperText>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12}>
                {
                  isDisabled ? <></> :
                  <Box display="flex" justifyContent="flex-end" gap="10px" padding="10px 16px 0">
                    <Button
                      variant="text"
                      onClick={()=>handleClose()}
                      sx={{...styles.yearField,textTransform: 'none'}}
                    >
                      {ready && t('VAT_CODES_CANCEL')}
                    </Button>
                    <Button
                      sx={{ backgroundColor: '#3E8AFF',textTransform: 'none' }}
                      variant="contained"
                      disabled={isSubmitting || !isActionPermitted(permissions.dimensionEdit, user?.permissions)}
                      onClick={()=>handleSubmit()}
                    >
                      {ready && t(vatCode && vatCode?.code ?'VAT_CODES_SAVE_CHANGES': 'VAT_CODES_ADD_NEW')}
                    </Button>
                  </Box>
                }
              </Grid>
              
            </CardContent>
          </Card>
        )}
        </form>:
        <Box sx={styles.manageFieldsContainer}>
          <ManageFieldsNew
            documentType="dimension"
            section="vat"
            fields={vatFields}
            fetchFields={getVatFields}
            handleCloseManageFields={handleCloseManageFields}
            parameterCompany={user?.companyID || ''}
          />
        </Box>        
      }
      
    </>
  );
};

const styles = {
  form: {
    backgroundColor: '',
    height: '100%',
    maxHeight: 'calc(90dvh - 76px)',
  },
  cardContainer: {
    boxShadow: 'none',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px',
  },
  cardContent: {
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    height: '100%',
  },
  topManager: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '12px',
    padding: '12px 16px',
    borderBottom: '1px solid #4C4E641F',
    margin: '0',
    marginBottom: '10px'
  },
  eachRow: {
    display: 'flex',
    width: '100%',
    alignItems:'center',
    justifyContent: 'space-between',
    padding: '0 16px',
    '& > p': {
      fontSize: '13px',
      color: '#4C4E64DE',
    }
  },
  yearField: {
    marginLeft: '16px',
    color: theme.palette.secondary.main
  },
  formControl: {
    width: '300px'
  },
  autoCompleteInput: {
    width: '300px',
    color: '#4C4E64 !important',
    fontSize: '13px',
    lineHeight: '20px',
    backgroundColor: '#ffffff',
    '& .MuiAutocomplete-input': {
      color: '#4C4E64 !important',
    },
    '& .MuiOutlinedInput-root': {
      padding: '0px 2px 0px 0px !important',
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: `2px solid #C2CFE099`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid #C2CFE099`,
      }
    },
  },
  selectStyle: {
    color: '#4C4E64 !important',
    fontSize: '13px',
    width: '100%',
    lineHeight: '20px',
    backgroundColor: '#ffffff',
    '&.MuiAutocomplete-input': {
      color: '#4C4E64 !important',
    },
    '&.MuiOutlinedInput-root': {
      padding: '0px 2px 0px 0px !important',
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: `2px solid #C2CFE099`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid #C2CFE099`,
      }
    },
  },
  inputField: {
    width: '300px',
    '& .MuiOutlinedInput-input': {
      fontSize: '13px !important',
      lineHeight: '32px !important',
      color: '#4C4E64 !important',
      fontWeight: '400',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: `2px solid #C2CFE099`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid #C2CFE099`,
      },
    },
  },
  manageFieldsBtn: {
    height: 'fit-content',
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    color: '#3E8AFF',
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingLeft: '16px',
    paddingRight: '16px',
    gap: '10px',
    fontSize: '13px',
    fontWeight: '500',
    border: 'none',
    '&:hover': {
      border: 'none',
      backgroundColor: '#fff'
    }
  },
  btnIcon: {
    height: '20px',
    width: '20px',
    marginRight: '4px',
    color: '#3E8AFF',
  },
  plusIcon: {
    height: '24px',
    width: '24px',
    color: theme.palette.secondary.main,
    cursor: 'pointer'
  },
  plusIconContainer: {
    marginRight: '5px'
  },
  editDimensionIcon: {
    fontSize: '18px',
    color: '#757575',
    border: '1px solid #334D6E',
    borderRadius: '3px',
    cursor: 'default'
  },
  editIconContainer: {
    marginRight: '-8px'
  },
  manageFieldsContainer: {
    backgroundColor: '#fff',
    position: 'relative',
    top: '-20px',
    maxHeight: 'calc(90dvh - 70px)',
    minHeight: 'calc(90dvh - 70px)',
  }
};

export default VatCodeStandard;
