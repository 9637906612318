import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';

import { Autocomplete,Box, Button, CircularProgress, Dialog, IconButton, TextField, Typography } from '@mui/material';
import {
  Close as CloseIcon,
  CloudUpload as CloudUploadIcon,
} from '@mui/icons-material';

import CancelBtn from 'src/shared/buttons/CancelBtn';
import SubmitBtn from 'src/shared/buttons/SubmitBtn';
import { RootState } from 'src/types';
import styles from './style';

interface PropTypes {
  open: boolean;
  loading: boolean;
  handleUpload: (_files: File[] | null, _docType: string) => Promise<void>;
  handleClose: () => void;
}

const UploadPopup = (props: PropTypes) => {
  const { t } = useTranslation();

  const {
    open,
    loading,
    handleUpload: _handleUpload,
    handleClose: _handleClose
  } = props;
  
  const docTypesForUpload: Record<string, any>[] = useSelector((state: RootState) => state.docTypes.companyDocTypes);

  const [selectedDocType, setSelectedDocType] = useState<string | undefined>(docTypesForUpload.find((d) => d.isDefault)?.documentType);
  const [filesToUpload, setFilesToUpload] = useState<File[] | null>(null);

  const onDrop = (acceptedFiles: File[]) => {
    setFilesToUpload(acceptedFiles);
  };

  const { getRootProps, getInputProps, isDragActive, open: openFilePicker } = useDropzone({
    onDrop,
    multiple: true,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg", ".heic", ".tiff"],
      "application/pdf": [".pdf"]
    },
    maxSize: 25 * 1024 * 1024, // 5MB max file size
    noClick: true,
  });

  useEffect(() => setSelectedDocType(docTypesForUpload.find((d) => d.isDefault)?.documentType), [docTypesForUpload]);

  const handleChangeAutoComplete = (value: Record<string, any>) => {
    if (value && (value.documentType)) {
      setSelectedDocType(value.documentType);
    }
  };

  const handleClose = () => {
    setFilesToUpload(null);
    setSelectedDocType(docTypesForUpload.find((d) => d.isDefault)?.documentType);
    _handleClose();
  };

  const handleUpload = (files: File[] | null, docType: string) => {
    _handleUpload(files, docType as string);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      sx={styles.modal}
      onClose={handleClose}
    >
      <Box sx={styles.container}>
        <Box sx={styles.modalHeader}>
          <Box sx={styles.titleContainer}>
            <Typography sx={styles.headerTitle}>
              {t('DASHBOARD_UPLOAD_POPUP_TITLE')}
            </Typography>
            <IconButton sx={styles.closeBtn} onClick={handleClose}>
              <CloseIcon sx={styles.closeIcon} />
            </IconButton>
          </Box>
        </Box>
        <Box sx={styles.modalContent}>
          <Box sx={styles.filterRow}>
            <Typography sx={styles.filterLabel}>{t('DASHBOARD_FILTERS_DOC_TYPE')}</Typography>
            <Autocomplete
              fullWidth
              onChange={(_, val) => handleChangeAutoComplete(val as unknown as Record<string, any>)}
              value={docTypesForUpload.find((d) => d.documentType === selectedDocType) || null}
              options={docTypesForUpload.filter((d) => d.hasFields)}
              getOptionLabel={(option) => typeof option === 'string' ? option : t(option.name)}
              getOptionKey={(option) => option.documentType}
              filterOptions={(options, state) => options.filter((option) => {
                if (option.documentType && option.name) {
                  return option.documentType.toLowerCase().includes(state.inputValue.toLowerCase())
                    || option.name.toLowerCase().includes(state.inputValue.toLowerCase());
                }
                return false;
              })}
              sx={styles.autoComplete}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="name"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                  }}
                  sx={styles.autoCompleteTextField}
                />
              )}
            />
          </Box>
          <Box sx={styles.dropZoneContainer} {...getRootProps()}>
            <input {...getInputProps()} />
            <CloudUploadIcon sx={styles.uploadIcon} />
            {isDragActive
              ? <Typography sx={styles.dropZoneTitle}>{t('DASHBOARD_UPLOAD_DROP_ACTIVE_TITLE')}</Typography>
              : <Typography sx={styles.dropZoneTitle}>{t('DASHBOARD_UPLOAD_DROP_TITLE')}</Typography>}
            <Typography sx={styles.dropZoneOr}>{t('DASHBOARD_UPLOAD_DROP_OR')}</Typography>
            <Button
              sx={styles.clickToUploadBtn}
              onClick={openFilePicker}
            >
              {loading ? (
                <CircularProgress sx={styles.spinner} size={20} />
              ) : (
                t('DASHBOARD_UPLOAD_CLICK_HERE')
              )}
            </Button>
            <Box>
              {filesToUpload && filesToUpload.length > 0 ? (
                <Box sx={styles.filesToUploadContainer}>
                  <Typography sx={styles.filesToUploadName}>{t('DASHBOARD_UPLOAD_POPUP_FILES_TO_UPLOAD')}</Typography>
                  {Array.from(filesToUpload).map((file) => 
                    <Typography key={file.name} sx={styles.filesToUploadName}>{file.name}</Typography>
                  )}
                </Box>) : (
                <>
                  <Typography sx={styles.dropZoneDescr}>{t('DASHBOARD_UPLOAD_POPUP_SUPPORTED_FORMATS')}</Typography>
                  <Typography sx={styles.dropZoneDescr}>{t('DASHBOARD_UPLOAD_POPUP_FILE_SIZE')}</Typography>
                </>)
              }
            </Box>
          </Box>
        </Box>
        <Box sx={styles.modalFooter}>
          <Box display="flex" justifyContent="flex-end" gap="16px">
            <CancelBtn
              title={t('DASHBOARD_INVOICES_CANCEL')}
              onClick={handleClose}
            />
            <SubmitBtn
              title={t('DASHBOARD_UPLOAD_POPUP_SUBMIT_BTN')}
              disabled={!filesToUpload || filesToUpload.length < 1 || loading}
              onClick={() => handleUpload(filesToUpload, selectedDocType as string)}
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}

export default UploadPopup;
