import {
  FETCH_DOC_TYPES_START,
  FETCH_DOC_TYPES_SUCCESS,
  FETCH_DOC_TYPES_FAIL,
  FETCH_DOC_TYPES_FOR_IMPORT_FAIL,
  FETCH_DOC_TYPES_FOR_IMPORT_START,
  FETCH_DOC_TYPES_FOR_IMPORT_SUCCESS,
  FETCH_COMPANY_DOC_TYPES_FAIL,
  FETCH_COMPANY_DOC_TYPES_START,
  FETCH_COMPANY_DOC_TYPES_SUCCESS,
} from "src/actionTypes";

interface IState {
  docTypesLoading: boolean;
  docTypes: Record<string, any>[];
  companyDocTypes: Record<string, any>[];
  docTypesForImport: Record<string, any>[];
}

type ActionType =
  | {
      type: 'FETCH_DOC_TYPES_START' | 'FETCH_DOC_TYPES_FOR_IMPORT_START' | 'FETCH_COMPANY_DOC_TYPES_START',
    }
  | {
      type: 'FETCH_DOC_TYPES_SUCCESS' | 'FETCH_DOC_TYPES_FOR_IMPORT_SUCCESS' | 'FETCH_COMPANY_DOC_TYPES_SUCCESS',
      payload: Record<string, any>[]
    }
  | { type: 'FETCH_DOC_TYPES_FAIL' | 'FETCH_DOC_TYPES_FOR_IMPORT_FAIL' | 'FETCH_COMPANY_DOC_TYPES_FAIL' };

const initialState: IState = {
  docTypesLoading: true,
  docTypes: [],
  companyDocTypes: [],
  docTypesForImport: [],
};

const fieldsReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case FETCH_DOC_TYPES_START:
      return { ...state, docTypesLoading: true };
    case FETCH_DOC_TYPES_SUCCESS:
      return { ...state, docTypes: action.payload, docTypesLoading: false };
    case FETCH_DOC_TYPES_FAIL:
      return { ...state, docTypesLoading: false };

    case FETCH_COMPANY_DOC_TYPES_START:
      return state;
    case FETCH_COMPANY_DOC_TYPES_SUCCESS:
      return { ...state, companyDocTypes: action.payload };
    case FETCH_COMPANY_DOC_TYPES_FAIL:
      return state;

    case FETCH_DOC_TYPES_FOR_IMPORT_START:
      return { ...state, docTypesLoading: true };
    case FETCH_DOC_TYPES_FOR_IMPORT_SUCCESS:
      return { ...state, docTypesForImport: action.payload, docTypesLoading: false };
    case FETCH_DOC_TYPES_FOR_IMPORT_FAIL:
      return { ...state, docTypesLoading: false };

    default:
      return state;
  }
};

export default fieldsReducer;