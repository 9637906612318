import { useRef, useState, useEffect, useContext, useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import i18n from 'i18next';
import Axios from 'axios';

import {
  Typography, IconButton, Select, MenuItem, Popper, Grow, Paper, List, ListItem, ListItemText,
  Link, Card, Button, Tooltip, Grid, SelectChangeEvent
} from '@mui/material';
import {
  HelpOutline as HelpOutlineIcon,
  FiberManualRecord as FiberManualRecordIcon,
  Settings
} from '@mui/icons-material';

import { CustomError, DocumentDeliveryFormatType, ExportMappingType, FieldType, RootState } from 'src/types';
import ConfigContext from 'src/contexts/ConfigContext';
import { useAuth } from 'src/hooks/useAuth';
import { appendContactSupport, axiosHeaders, getLocalisedErrorString } from 'src/utils/helpers';
import authService from 'src/utils/authService';
import ManageExportFieldsNew from 'src/shared/components/ManageExportFieldsNew/ManageExportFieldsNew';
import { newAnnotationInterfaceStartDate } from 'src/config';
import styles from './style';

function SystemSettings() {
  const { ready, t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    BRAND_NAME, BRAND_URL, API, LOGIN_PANEL_URL
  } = useContext(ConfigContext);
  const auth = useAuth();
  const {user} = auth;

  const [loading, setLoading] = useState(false);

  const isPassportOrID = user?.documentType === 'id';
  const isParentDocTypeSame = user?.documentType === user?.parentDocType;
  const showAnnotationInterfaceSwitch =
    user?.mainCompanyCreatedAt && parseInt(user?.mainCompanyCreatedAt.concat('000')) < new Date(newAnnotationInterfaceStartDate).getTime();

  const interfaceOptions = [
    { label: 'ACCOUNT_SETTINGS_APP_DEFAULT_INTERFACE', key: 'standard_interface' },
    { label: 'ACCOUNT_SETTINGS_TWINFIELD_INTERFACE', key: 'twinfield_interface' }
  ];

  const commsLangOptions = [
    { label: 'ACCOUNT_SETTINGS_COMMS_ENGLISH', key: 'en' },
    { label: 'ACCOUNT_SETTINGS_COMMS_SPANISH', key: 'es' }
  ];

  const annotationInterfaceOptions = [
    { label: 'ACCOUNT_SETTINGS_ANNOTATION_INTERFACE_DEFAULT', key: 'default' },
    { label: 'ACCOUNT_SETTINGS_ANNOTATION_INTERFACE_OLD', key: 'old' }
  ];

  const [interfacePreference, setInterfacePreference] = useState(user?.interfacePreference || interfaceOptions[0].key);
  const [commsLanguage, setCommsLanguage] = useState(user?.commsLanguage || commsLangOptions[0].key);
  const [annotationInterface, setAnnotationInterface] = useState(
    user?.customisations.includes('oldAnnotationInterface') ? 'old' : 'default'
  );

  const _headerFields: FieldType[] = useSelector((state: RootState) => state.fields.headerFields);
  const headerFields: FieldType[] = useMemo(() => _headerFields, [_headerFields]);

  const _lineFields: FieldType[] = useSelector((state: RootState) => state.fields.lineFields);
  const lineFields: FieldType[] = useMemo(() => _lineFields, [_lineFields]);

  const _supplierFields: FieldType[] = useSelector((state: RootState) => state.fields.supplierFields);
  const supplierFields: FieldType[] = useMemo(() => _supplierFields, [_supplierFields]);

  const [excelExportFieldMapping, setExcelExportFieldMapping] = useState<ExportMappingType[]>([]);
  const [datExportFieldMapping, setDatExportFieldMapping] = useState<ExportMappingType[]>([]);
  const [csvExportFieldMapping, setCsvExportFieldMapping] = useState<ExportMappingType[]>([]);

  const [interfaceTooltipOpen, setInterfaceTooltipOpen] = useState<boolean>(false);
  const [interfaceTooltipOpen2, setInterfaceTooltip2Open] = useState<boolean>(false);
  const annoInterfaceTooltipRef = useRef(null);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(i18n.language.split('-')[0]);
  const [languages, setLanguages] = useState<Record<string, string>[]>([{ code: 'en', label: 'English', shortLabel: 'EN' }]);
  const [showExportFieldMapping, setShowExportFieldMapping] = useState<boolean>(false);

  const fetchLanguages = async () => {
    const response = await Axios.get(API.getLanguages);
    if (response) {
      const data = response.data.success && response.data.data ? response.data.data : [];
      const someLangs = [];
      for (let i = 0; i < data.length;) {
        someLangs.push({
          code: data[i].language,
          label: data[i].label,
          shortLabel: data[i].language.toUpperCase()
        });
        if (data[i].language === i18n.language.split('-')[0]) {
          setSelectedLanguage(data[i].language);
        }
        i += 1;
      }
      setLanguages(someLangs);
    }
  };

  const getExcelExportFieldMapping = async () => {
    try {
      const response = await Axios.get(
        `${API.exportFieldMapping}excel`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setExcelExportFieldMapping(response.data.data);
          return;
        }
      }
      setExcelExportFieldMapping([]);
    } catch (error) {
      setExcelExportFieldMapping([]);
    }
  };

  const getDatExportFieldMapping = async () => {
    try {
      const response = await Axios.get(
        `${API.exportFieldMapping}dat`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setDatExportFieldMapping(response.data.data);
          return;
        }
      }
      setDatExportFieldMapping([]);
    } catch (error) {
      setDatExportFieldMapping([]);
    }
  };

  const getCsvExportFieldMapping = async () => {
    try {
      const response = await Axios.get(
        `${API.exportFieldMapping}csv`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setCsvExportFieldMapping(response.data.data);
          return;
        }
      }
      setCsvExportFieldMapping([]);
    } catch (error) {
      setCsvExportFieldMapping([]);
    }
  };

  const handleCloseExportFieldMapping = async (updated?: boolean, format?: DocumentDeliveryFormatType) => {
    setShowExportFieldMapping(false);
    if (updated && format) {
      setTimeout(async () => {
        await getExcelExportFieldMapping();
        await getDatExportFieldMapping();
        await getCsvExportFieldMapping();
      }, 1000);
    }
  };

  useEffect(() => {
    fetchLanguages();
    getExcelExportFieldMapping();
    getDatExportFieldMapping();
    getCsvExportFieldMapping();
  }, []);

  const handleClose = () => {
    setInterfaceTooltipOpen(false);
  };

  const handleOpen = () => {
    setInterfaceTooltipOpen(true);
  };

  const handleClose2 = () => {
    setInterfaceTooltip2Open(false);
  };

  const handleOpen2 = () => {
    setInterfaceTooltip2Open(true);
  };

  const onChangeInterfacePreference = async (event: SelectChangeEvent<any>) => {
    setInterfacePreference(event.target.value);
    setLoading(true);
    if (!authService.validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }
    try {
      const response = await Axios.put(
        `${API.userProfile}?&application=procys`,
        {
          country: user?.country || '',
          email: user?.email || '',
          firstname: user?.firstname || '',
          lastname: user?.lastname || '',
          phone: user?.phone || '',
          city: user?.city || '',
          interfacePreference: event.target.value
        },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(t('INTERFACE_PREFERENCE_UPDATE_SUCCESS'), {
          variant: 'success',
        });
        setTimeout(() => {
          setLoading(false);
          window.location.reload();
        }, 2000);
      } else {
        setLoading(false);
        enqueueSnackbar(t('INTERFACE_PREFERENCE_UPDATE_FAILURE'), {
          variant: 'error',
        });
      }
    } catch (e) {
      const error = e as CustomError
      setLoading(false);
      enqueueSnackbar(appendContactSupport(
        window.config.support_email,
        getLocalisedErrorString(error?.response?.data?.i18n || 'INTERFACE_PREFERENCE_UPDATE_FAILURE', t),
        t
      ), {
        variant: 'error',
      });
    }
  };

  const onChangeCommsLanguagPreference = async (event: SelectChangeEvent<any>) => {
    setCommsLanguage(event.target.value);
    setLoading(true);
    if (!authService.validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }
    try {
      const response = await Axios.put(
        `${API.updateCommsLang}`,
        {
          company: user?.companyID,
          language: event.target.value,
        },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(t('COMMS_LANGUAGE_UPDATE_SUCCESS'), {
          variant: 'success',
        });
        setLoading(false);
      } else {
        setLoading(false);
        enqueueSnackbar(t('COMMS_LANGUAGE_UPDATE_FAILURE'), {
          variant: 'error',
        });
      }
    } catch (e) {
      const error = e as CustomError;
      setLoading(false);
      enqueueSnackbar(appendContactSupport(
        window.config.support_email,
        getLocalisedErrorString(error?.response?.data?.i18n || 'COMMS_LANGUAGE_UPDATE_FAILURE', t),
        t
      ), {
        variant: 'error',
      });
    }
  };

  const onChangeAnnotationInterface = async (event: SelectChangeEvent<any>) => {
    setAnnotationInterface(event.target.value as string);
    setLoading(true);
    if (!authService.validateToken()) {
      enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
        variant: 'error',
      });
      setTimeout(() => {
        authService.logout(LOGIN_PANEL_URL);
      }, 2000);
      return;
    }
    try {
      const response = await Axios.put(
        `${API.customisations}`,
        { customisation: 'oldAnnotationInterface', enabled: event.target.value === 'old' },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        enqueueSnackbar(t('ANNOTATION_INTERFACE_UPDATE_SUCCESS'), {
          variant: 'success',
        });
        setLoading(false);
      } else {
        setLoading(false);
        enqueueSnackbar(t('ANNOTATION_INTERFACE_UPDATE_FAILURE'), {
          variant: 'error',
        });
      }
    } catch (e) {
      const error = e as CustomError;
      setLoading(false);
      enqueueSnackbar(appendContactSupport(
        window.config.support_email,
        getLocalisedErrorString(error?.response?.data?.i18n || 'ANNOTATION_INTERFACE_UPDATE_FAILURE', t),
        t
      ), {
        variant: 'error',
      });
    }
  };

  const handleLangSelect = (e: SelectChangeEvent<any>) => {
    setSelectedLanguage(e.target.value);
    setLoading(true);
    i18n.changeLanguage(e.target.value, ((err) => {
      if (err) {
        setLoading(false);
        enqueueSnackbar(t('USER_PREFERENCE_LANGUAGE_CHANGE_FAILURE'), {
          variant: 'error',
        });
        return;
      }
      enqueueSnackbar(t('USER_PREFERENCE_LANGUAGE_CHANGE_SUCCESS'), {
        variant: 'success',
      });
      setTimeout(() => {
        setLoading(false);
        window.location.reload();
      }, 1000);
    }));
  };

  const handleManageExport = () => {
    setShowExportFieldMapping(true);
  };

  const isAllExportsManagedByParent = () => {
    if (!user?.isSubCompany) {
      return false;
    }
    if (isParentDocTypeSame && user?.customisations.includes('applyParentMappingsExcel')
      && user?.customisations.includes('applyParentMappingsCsv')
      && user?.customisations.includes('applyParentMappingsDat')) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Card sx={styles.root}>
        <Grid item xs={12} sm={12} sx={styles.formInputRow}>
          {!isPassportOrID && (
            <Grid item xs={12} sx={styles.formInputLeft}>
              <Grid sx={styles.annoInterfaceLabelContainer}>
                <Typography sx={styles.annoInterfaceLabel}>
                  {ready && t('ACCOUNT_SETTINGS_INTERFACE_PREFERENCE')}
                </Typography>
                <IconButton
                  ref={annoInterfaceTooltipRef}
                  sx={styles.annoInterfaceHelp}
                  onMouseEnter={handleOpen}
                  onMouseLeave={handleClose}
                >
                  <HelpOutlineIcon sx={styles.interfaceIcon} />
                </IconButton>
              </Grid>
              <Select
                disabled={loading}
                onChange={onChangeInterfacePreference}
                sx={styles.formInputSelect}
                value={interfacePreference}
                variant="outlined"
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                }}
              >
                {
                  interfaceOptions.map((ip) => (
                    <MenuItem sx={styles.inputText} key={ip.key} value={ip.key}>
                      {ready && t(ip.label, { brand: BRAND_NAME })}
                    </MenuItem>
                  ))
                }
              </Select>
            </Grid>
          )}
          <Grid item xs={12} sx={isPassportOrID ? styles.formInputLeft : styles.formInputRight}>
            <Grid sx={styles.annoInterfaceLabelContainer}>
              <Typography sx={styles.annoInterfaceLabel}>
                {ready && t('ACCOUNT_SETTINGS_COMMS_LANGUAGE')}
              </Typography>
              <Tooltip title={t('ACCOUNT_SETTINGS_COMMS_LANGUAGE_TOOLTIP')}>
                <IconButton
                  sx={styles.annoInterfaceHelp}
                >
                  <HelpOutlineIcon style={styles.interfaceIcon} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Select
              disabled={loading}
              onChange={onChangeCommsLanguagPreference}
              sx={styles.formInputSelect}
              value={commsLanguage}
              variant="outlined"
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left'
                },
              }}
            >
              {
                commsLangOptions.map((cl) => (
                  <MenuItem sx={styles.inputText} key={cl.key} value={cl.key}>
                    {ready && t(cl.label)}
                  </MenuItem>
                ))
              }
            </Select>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} sx={styles.formInputRow}>
          <Grid item xs={12} sm={6} sx={styles.formInputLeft}>
            <Grid sx={styles.annoInterfaceLabelContainer}>
              <Typography sx={styles.annoInterfaceLabel}>
                {ready && t('USER_PREFERENCE_LANGUAGE_LABEL')}
              </Typography>
              <Tooltip title={t('ACCOUNT_SETTINGS_BROWSER_UI_LANGUAGE_TOOLTIP')}>
                <IconButton
                  sx={styles.annoInterfaceHelp}
                >
                  <HelpOutlineIcon sx={styles.interfaceIcon} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Select
              disabled={loading}
              onChange={handleLangSelect}
              sx={styles.formInputSelect}
              value={selectedLanguage}
              variant="outlined"
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left'
                },
              }}
            >
              {
                languages.map((l) => (
                  <MenuItem sx={styles.inputText} key={l.code} value={l.code}>
                    {l.label}
                  </MenuItem>
                ))
              }
            </Select>
          </Grid>
          {showAnnotationInterfaceSwitch && (
            <Grid item xs={12} sm={6} sx={styles.formInputRight}>
              <Grid sx={styles.annoInterfaceLabelContainer}>
                <Typography sx={styles.annoInterfaceLabel}>
                  {ready && t('ACCOUNT_SETTINGS_ANNOTATION_INTERFACE')}
                </Typography>
                <Tooltip title={t('ACCOUNT_SETTINGS_ANNOTATION_INTERFACE_TOOLTIP')}>
                  <IconButton sx={styles.annoInterfaceHelp}>
                    <HelpOutlineIcon style={styles.interfaceIcon} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Select
                disabled={loading}
                onChange={onChangeAnnotationInterface}
                sx={styles.formInputSelect}
                value={annotationInterface}
                variant="outlined"
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                }}
              >
                {annotationInterfaceOptions.map((ai) => (
                  <MenuItem sx={styles.inputText} key={ai.key} value={ai.key}>
                    {ready && t(ai.label, { brand: BRAND_NAME })}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
        </Grid>
        {!isAllExportsManagedByParent() && (
          <Grid item xs={12} sm={6} sx={styles.formInput}>
            <Grid sx={styles.annoInterfaceLabelContainer}>
              <Typography sx={styles.annoInterfaceLabel}>
                {ready && t('MANAGE_XLSX_EXPORT_FIELD_NAMES')}
              </Typography>
            </Grid>
            <Button
              variant="outlined"
              sx={styles.manageExportBtn}
              onClick={handleManageExport}
              startIcon={<Settings />}
            >
              {ready && t('MANAGE_EXPORT_MAPPINGS')}
            </Button>
          </Grid>
        )}
      </Card>
      <Popper
        open={interfaceTooltipOpen || interfaceTooltipOpen2}
        anchorEl={annoInterfaceTooltipRef.current}
        transition
        sx={{ zIndex: 111111 }}
        onMouseEnter={handleOpen2}
        onMouseLeave={handleClose2}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper sx={styles.arPaper} variant="outlined">
              <Typography sx={styles.arSubTitle}>
                {ready && t('ACCOUNT_SETTINGS_INTERFACE_PREFERENCE_TOOLTIP_TITLE', { brand: BRAND_NAME })}
              </Typography>
              <List sx={styles.list}>
                <ListItem sx={styles.listItem}>
                  <FiberManualRecordIcon sx={styles.bulletPoint} />
                  <ListItemText
                    disableTypography
                    primary={(
                      <Typography sx={styles.listItemText}>
                        {ready && t('ACCOUNT_SETTINGS_INTERFACE_PREFERENCE_TOOLTIP_LINE_01', { brand: BRAND_NAME })}
                      </Typography>
                    )}
                  />
                </ListItem>
                <ListItem sx={styles.listItem}>
                  <FiberManualRecordIcon style={styles.bulletPoint} />
                  <ListItemText
                    disableTypography
                    primary={(
                      <Typography sx={styles.listItemText}>
                        {ready && t('ACCOUNT_SETTINGS_INTERFACE_PREFERENCE_TOOLTIP_LINE_02')}
                      </Typography>
                    )}
                  />
                </ListItem>
              </List>
              <Typography sx={styles.listItemTextWithBt}>
                {ready && (
                  <Trans i18nKey="ACCOUNT_SETTINGS_INTERFACE_PREFERENCE_TOOLTIP_LINE_03">
                    You can read more in
                    <Link href={BRAND_URL} rel="noreferrer" target="_blank">this</Link>
                    article.
                  </Trans>
                )}
              </Typography>
              <Typography sx={styles.listItemText}>
                {ready && t('ACCOUNT_SETTINGS_INTERFACE_PREFERENCE_TOOLTIP_LINE_04')}
              </Typography>
            </Paper>
          </Grow>
        )}
      </Popper>
      <ManageExportFieldsNew 
        open={showExportFieldMapping}
        handleClose={handleCloseExportFieldMapping}
        fields={headerFields}
        lineFields={lineFields}
        supplierFields={supplierFields}
        excelFieldMapping={excelExportFieldMapping}
        datFieldMapping={datExportFieldMapping}
        csvFieldMapping={csvExportFieldMapping}
        format={{} as DocumentDeliveryFormatType}
      />
    </>
  );
}

export default SystemSettings;
