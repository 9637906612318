/* eslint-disable no-prototype-builtins */
import {
  Fragment, useContext, useEffect, useRef, useState, useMemo
} from 'react';
import { Formik } from 'formik';
import Axios from 'axios';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import cc from 'currency-codes/data';
import { isMobile } from 'react-device-detect';
import { countries } from 'countries-list';

import {
  Box, Button, Grid, TextField, Hidden, Tooltip, Backdrop, CircularProgress, Typography,
  MenuItem, Select, Autocomplete, InputLabel, FormControl, IconButton, Checkbox, FormControlLabel,
  InputAdornment, SvgIcon, useMediaQuery
} from '@mui/material';
import {
  NavigateBefore as NavigateBeforeIcon,
  NavigateNext as NavigateNextIcon,
  OpenInNew as OpenInNewIcon,
  SettingsOutlined as SettingsIcon,
  AddBox as PlusIcon,
  Edit as EditIcon,
  Search as SearchIcon,
  ArrowDropDown as ArrowDropDownIcon,
  GetApp as DownloadIcon,
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon,
  ArrowDropUp as ArrowDropUpIcon,
  AddCircle as PlusCircleIcon,
  Delete as DeleteIcon
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import { fetchHeaderFields, fetchLineFields } from 'src/shared/redux/fields/fieldsActions';
import { fetchGlas } from 'src/dimensions/glas/actions';
import { fetchVatCodes } from 'src/dimensions/vatcodes/actions';
import { fetchCostCenters } from 'src/dimensions/costcenters/actions';
import InvoiceLineAddForm from 'src/invoice-edit/components/InvoiceLineAddForm/InvoiceLineAddForm';
import InvoiceLineEditForm from 'src/invoice-edit/components/InvoiceLineEditForm/InvoiceLineEditForm';
import authService from 'src/utils/authService';
import useIsInViewport from 'src/hooks/useIsInViewport';
import ConfigContext from 'src/contexts/ConfigContext';
import {
  addDays, appendContactSupport, axiosHeaders, axiosHeadersWithArrayBuffer, calculateBaseAmount, calculateDiscountRate,
  calculateESAmount, calculateLineAmountExtraCost1, calculateLineAmountExtraCost2, calculateLineAmountExtraCost3,
  calculateLineAmountExtraCost4, calculateLineTaxForVat, calculateSubtotal, calculateSumOfESLines, calculateSumOfVATLines,
  calculateVatAmount, formatAmountFields, formatDateCustomFormat, formatDateDDMMYYYY, getDeliverFormat, getLocalisedErrorString,
  getMonthShortString, isAmountTallyingWithLines, isInvoiceFieldAvailable, isOverlapping, isShowManageFields,
  isTaxNumberSwitchAvailable, isVatNumberInvalid, sendFeedback, validateEmail
} from 'src/utils/helpers';
import {
  documentTypes, makroSuppliers, nonNumericFieldsHeader, supplierData, userRoles,
  vatLineLabels, invoiceHelperText, invoiceTooltips, invoiceRegex, lineAmountRelatedFields, invoiceStatusCode,
  documentEditSelectValues, documentEditSelectLabelPrefixes, esLineLabels, headerFieldIds,
  secondaryDocumentTypes, lineFieldIds, lineColumnsReprocess, lineDateRelatedFields
} from 'src/config';
import { removeIsNotTrainedFieldsFromDetails } from 'src/document-edit/utils';
import Canvas from 'src/invoice-edit/components/Canvas/Canvas';
import ManageFields from 'src/shared/components/ManageFields/ManageFields';
import CreateSupplierModal from 'src/dimensions/suppliers/components/CreateSupplierModal/CreateSupplierModal';
import EditSupplierModal from 'src/dimensions/suppliers/components/EditSupplierModal/EditSupplierModal';
import AttachedDocuments from 'src/invoice-edit/components/AttachedDocuments/AttachedDocuments';
import SpreadInvoice from 'src/invoice-edit/components/SpreadInvoice/SpreadInvoice';
import DisplayLines from 'src/invoice-edit/components/DisplayLines/DisplayLines';
import ExportToEmailModal from 'src/documents/components/ExportToEmailModal/ExportToEmailModal';
import InvoiceEditFormActionButtons from 'src/invoice-edit/components/InvoiceEditFormActionButtons/InvoiceEditFormActionButtons';
import ManageExportFieldMappings from 'src/shared/components/ManageExportFieldMappings/ManageExportFieldMappings';
import PdfView from 'src/documents/components/PdfView/PdfView';
import DeleteLineModal from 'src/invoice-edit/components/DeleteLineModal/DeleteLineModal';
import styles from './style';

const FileSaver = require('file-saver');

function InvoiceEditForm({
  invoice,
  xml,
  renderHeader,
  xmlReceived,
  getInvoice,
  handleSelectStatus,
  statusChangeLoading,
  handleChooseValidatorModalOpen,
  isSupplierLabelWithVAT,
  isToValidateStatusAllowed,
  isAutoNavigationAllowed,
  previousInvoice,
  nextInvoice,
  navigateInvoice,
  parameterCompany,
  parameterDocCompany,
  setStatusChangedRef,
  customReceivers,
  isTwinfield,
  userDocType,
  decimalSeparator,
  dateFieldRule,
  calculateDeliveryDate,
  dueDaysAsDayOfWeek,
  calculateVat,
  custom2FieldInDocumentForm,
  disableLinesTrainingCheck,
  dontIncludeLinesToExport,
  receiverVatLimitedOptions,
  hasLines,
}) {
  const dispatch = useDispatch();
  const { LOGIN_PANEL_URL, API } = useContext(ConfigContext);

  const { user } = useAuth();
  const docType = useSelector((state) => state.fields.docType);

  const _headerFields = useSelector((state) => state.fields.headerFields);
  const headerFields = useMemo(() => _headerFields, [_headerFields]);

  const _lineFields = useSelector((state) => state.fields.lineFields);
  const lineFields = useMemo(() => _lineFields, [_lineFields]);

  const _supplierFields = useSelector((state) => state.fields.supplierFields);
  const supplierFields = useMemo(() => _supplierFields, [_supplierFields]);

  const _glAccounts = useSelector((state) => state.gla.glAccounts);
  const glAccounts = [...supplierData.defaultGLA, ...useMemo(() => _glAccounts, [_glAccounts])];

  const _vatGroups = useSelector((state) => state.vatcode.vatCodes);
  const vatGroups = [...supplierData.defaultVatGroup, ...useMemo(() => _vatGroups, [_vatGroups])];

  const _costCenters = useSelector((state) => state.costcenter.costCenters);
  const costCenters = [...supplierData.defaultCostCenters, ...useMemo(() => _costCenters, [_costCenters])];

  const [imgName, setImgName] = useState('');
  const [isSubmitClicked, setIsSubmitClicked] = useState(true);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [gotoPage, setGotoPage] = useState(1);
  const { enqueueSnackbar } = useSnackbar();
  const { t, ready } = useTranslation();
  const moveToNextRef = useRef(null);
  const setMoveToNext = (val) => {
    moveToNextRef.current = val;
  };

  const dateFieldFormat = 'DD-MM-YYYY';
  const dateFieldExample = '24-01-2021';
  // Regex patterns
  const amountRegex = new RegExp(`^-?\\d*[${decimalSeparator === '.' ? '.' : `.${decimalSeparator}`}]?\\d+$`);
  const dateFormat = /^((0?[1-9]|[12][0-9]|3[01])([/\-.])(0?[1-9]|1[0-2])\3\d{4}|\d{4}([/\-.])(0?[1-9]|1[0-2])\5(0?[1-9]|[12][0-9]|3[01]))$/;
  const noWhiteSpace = /.*\S.*$/;
  const leadingWhiteSpace = /^\s+.+$/;

  const [details, setDetails] = useState({
    id: invoice.id,
    assistantID: invoice.assistantID,
    status: invoice.status,
    documentNumber: invoice.documentNumber || '',
    documentDate: invoice.date || '',
    documentType: invoice.documentType || userDocType.toUpperCase(),
    secondaryDocumentType: invoice.secondaryDocumentType || secondaryDocumentTypes.invoice,
    dueDate: invoice.dueDate || '',
    currency: invoice.currency || '',
    amount: invoice.amount || '',
    company: invoice.company,
    email: invoice.email,
    vatAmount: invoice.vatAmount || '',
    discountAmount: invoice.discountAmount || '',
    vatPercentage: invoice.vatPercentage || '',
    paymentRefOGMVCS: invoice.paymentRefOGMVCS || '',
    poReferenceNumber: invoice.poReferenceNumber || '',
    receiverCompany: invoice.receiverCompany || '',
    receiverVatNumber: invoice.receiverVatNumber || '',
    receiverAddress: invoice.receiverAddress || '',
    receiverCity: invoice.receiverCity || '',
    receiverStreet: invoice.receiverStreet || '',
    receiverPostalCode: invoice.receiverPostalCode || '',
    receiverTaxNumber: invoice.receiverTaxNumber || '',
    senderCompany: invoice.senderCompany || '',
    senderVatNumber: invoice.senderVatNumber || '',
    senderAddress: invoice.senderAddress || '',
    senderCity: invoice.senderCity || '',
    senderStreet: invoice.senderStreet || '',
    senderPostalCode: invoice.senderPostalCode || '',
    senderWebsite: invoice.senderWebsite || '',
    senderEmail: invoice.senderEmail || '',
    senderKvk: invoice.senderKvk || '',
    senderIban: invoice.senderIban || '',
    senderPhone: invoice.senderPhone || '',
    senderFax: invoice.senderFax || '',
    senderTaxNumber: invoice.senderTaxNumber || '',
    lines: invoice.lines || [],
    supplier: invoice.supplier || '',
    owner: invoice.owner,
    description: invoice.description || '',
    period: invoice.period || '',
    doNotPay: invoice.doNotPay || false,
    referenceNumber: invoice.referenceNumber || '',
    ocrNumberSE: invoice.ocrNumberSE || '',
    vatAmount2: invoice.vatAmount2 || '',
    vatPercentage2: invoice.vatPercentage2 || '',
    clientCode: invoice.clientCode || '',
    totalWeight: invoice.totalWeight || '',
    packagesNumber: invoice.packagesNumber || '',
    subtotal: invoice.subtotal || '',
    bookingNumber: invoice.bookingNumber || '',
    deliveryDate: invoice.deliveryDate || '',
    receiverCountryCode: invoice.receiverCountryCode || '',
    senderCountryCode: invoice.senderCountryCode || '',
    baseAmount: invoice.baseAmount || '',
    discountRate: invoice.discountRate || '',
    paymentForm: invoice.paymentForm || '',
    equivalenceSurchargeAmount: invoice.equivalenceSurchargeAmount || '',
    equivalenceSurchargeRate: invoice.equivalenceSurchargeRate || '',
    operationalOffice: invoice.operationalOffice || '',
    typeOfOperation: invoice.typeOfOperation || '',
    retentionAmount: invoice.retentionAmount || '',
    retentionPercentage: invoice.retentionPercentage || '',
    terms: invoice.terms || '',
    gstAmount: invoice.gstAmount || '',
    pstAmount: invoice.pstAmount || '',
    deliveryType: invoice.deliveryType || '',
    typeOfTransaction: invoice.typeOfTransaction || '',
    archiveNumber: invoice.archiveNumber || '',
    contactNumber: invoice.contactNumber || '',
    estimationNumber: invoice.estimationNumber || '',
    placeAndDateOfEstimation: invoice.placeAndDateOfEstimation || '',
    estimationPeriod: invoice.estimationPeriod || '',
    ccoste: invoice.ccoste || '',
    deliveryNoteNumber: invoice.deliveryNoteNumber || '',
    dateOfIssue2: invoice.dateOfIssue2 || '',
    totalAmountBeforeDiscount: invoice.totalAmountBeforeDiscount || '',
    referenceNumber2: invoice.referenceNumber2 || '',
    contractNumber: invoice.contractNumber || '',
    mnjBaseAmount: invoice.mnjBaseAmount || '',
    mnjDescription: invoice.mnjDescription || '',
    senderContactName: invoice.senderContactName || '',
    mnjPeriodStart: invoice.mnjPeriodStart || '',
    mnjPeriodEnd: invoice.mnjPeriodEnd || '',
    mnjPlace: invoice.mnjPlace || '',
    dossierNumber: invoice.dossierNumber || '',
    mnjDeductionType: invoice.mnjDeductionType || '',
    mnjDeductionBaseAmount: invoice.mnjDeductionBaseAmount || '',
    mnjDeductionVATRate: invoice.mnjDeductionVATRate || '',
    mnjDeductionTotal: invoice.mnjDeductionTotal || '',
    mnjAfterDiscountAmount: invoice.mnjAfterDiscountAmount || '',
    cups: invoice.cups || '',
    name: invoice.name || '',
    surname: invoice.surname || '',
    nationality: invoice.nationality || '',
    countryIssued: invoice.countryIssued || '',
    dateOfBirth: invoice.dateOfBirth || '',
    dateOfIssue: invoice.dateOfIssue || '',
    dateOfExpiry: invoice.dateOfExpiry || '',
    sex: invoice.sex || '',
    placeOfIssue: invoice.placeOfIssue || '',
    mrz: invoice.mrz || '',
  });

  const [suppliers, setSuppliers] = useState(JSON.parse(window.sessionStorage.getItem('suppliers') || '[]'));
  const [supplier, setSupplier] = useState(null);
  const [supplierLabel, setSupplierLabel] = useState('');
  const [lineDescription, setLineDescription] = useState('');
  const [loading, setLoading] = useState(false);

  const [sendLines, setSendLines] = useState(!disableLinesTrainingCheck);
  const [addLineOpen, setAddLineOpen] = useState(false);
  const [editLineOpen, setEditLineOpen] = useState(false);
  const [editLine, setEditLine] = useState(null);
  const [deleteLineOpen, setDeleteLineOpen] = useState(false);
  const [deleteLine, setDeleteLine] = useState(null);
  const [includeLinesInExport, setIncludeLinesInExport] = useState(invoice?.exportLine
    ? invoice.exportLine
    : !dontIncludeLinesToExport
  );

  const [noSupplier, setNoSupplier] = useState('');
  const [selectedSupplierId, setSelectedSupplierId] = useState('-1');
  const [editSupplierOpen, setEditSupplierOpen] = useState(false);
  const [addSupplierOpen, setAddSupplierOpen] = useState(false);
  const [newSupplier, setNewSupplier] = useState({
    creditorName: invoice.senderCompany,
    website: invoice.senderWebsite,
    vatNumber: invoice.senderVatNumber,
    defaultCurrency: invoice.currency,
    iban: invoice.senderIban,
    telephoneNumber: invoice.senderPhone,
    faxNumber: invoice.senderFax,
    emailAddress: invoice.senderEmail,
    dueDays: 45,
    country: 'ES',
  });
  const glaYears = [];
  const glaPeriods = [];

  const dataFormatOptionsToEmailRef = useRef(null);
  const [sendEmailModalOpen, setSendEmailModalOpen] = useState(false);
  const [emails, setEmails] = useState(user?.deliverEmails?.length > 0 ? user.deliverEmails.join(', ') : user.email);
  const [selectedDataFormatToEmail, setSelectedDataFormatToEmail] = useState(user?.deliverFormat);
  const [dataFormatOptionsToEmailOpen, setDataFormatOptionsToEmailOpen] = useState(false);
  const [deliverFormats, setDeliverFormats] = useState([]);
  const [excelExportFieldMapping, setExcelExportFieldMapping] = useState([]);
  const [datExportFieldMapping, setDatExportFieldMapping] = useState([]);
  const [csvExportFieldMapping, setCsvExportFieldMapping] = useState([]);
  const [exportFormat, setExportFormat] = useState({});

  const [projects, setProjects] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [renderedFields, setRenderedFields] = useState([]);
  const [showManageFields, setShowManageFields] = useState(false);
  const [showExportFieldMapping, setShowExportFieldMapping] = useState(false);

  const [spreadInvoice, setSpreadInvoice] = useState(false);
  const [spreadSuccess, setSpreadSuccess] = useState(false);

  const [words, setWords] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  const [wordsForLines, setWordsForLines] = useState([]);
  const [page, setPage] = useState({});
  const [imageBinary, setImageBinary] = useState([]);

  const [xmlSelectedValue, setXmlSelectedValue] = useState('');
  const [textFieldSelectedValue, _setTextFieldSelectedValue] = useState('');
  const textFieldSelectedValueRef = useRef(textFieldSelectedValue);
  const setTextFieldSelectedValue = (val) => {
    textFieldSelectedValueRef.current = val;
    _setTextFieldSelectedValue(val);
  };
  const [textFieldSelectedKey, setTextFieldSelectedKey] = useState('');
  const [selectedDataType, setSelectedDataType] = useState('');
  const [clickOnCanvasCount, setClickOnCanvasCount] = useState(0);

  const [detailsXML, setDetailsXML] = useState({});
  const [detailsXMLi, setDetailsXMLi] = useState({});
  const [detail, setDetail] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  const [fileResponse, setFileResponse] = useState(null);
  const [fetchingFile, setFetchingFile] = useState(false);
  const splitButtonRef = useRef(null);
  const [attachmentsOpen, setAttachmentsOpen] = useState(false);

  const [mouseLeftSplitScrnBtn, _setMouseLeftSplitScrnBtn] = useState(true);
  const mouseLeftSplitScrnBtnRef = useRef(mouseLeftSplitScrnBtn);
  const setMouseLeftSplitScrnBtn = (val) => {
    mouseLeftSplitScrnBtnRef.current = val;
    _setMouseLeftSplitScrnBtn(val);
  };
  const [mouseLeftAttachmentsTooltip, _setMouseLeftAttachmentsTooltip] = useState(true);
  const mouseLeftAttachmentsTooltipRef = useRef(mouseLeftAttachmentsTooltip);
  const setMouseLeftAttachmentsTooltip = (val) => {
    mouseLeftAttachmentsTooltipRef.current = val;
    _setMouseLeftAttachmentsTooltip(val);
  };

  const [showVatDetails, setShowVatDetails] = useState(invoice.vat?.length > 0);
  const [vatLines, setVatLines] = useState([]);
  const [totalVatAmountError, setTotalVatAmountError] = useState(false);
  const [senderVatNumberInvalid, setSenderVatNumberInvalid] = useState(false);
  const [receiverVatNumberInvalid, setReceiverVatNumberInvalid] = useState(false);

  const [showSurchargeDetails, setShowSurchargeDetails] = useState(invoice.esLines?.length > 0);
  const [esLines, setESLines] = useState([]);
  const [totalSurchargeAmountError, setTotalSurchargeAmountError] = useState(false);

  const [countriesList, setCountriesList] = useState([]);

  const [qbTerms, setQBTerms] = useState([]);

  const minHeight = useMediaQuery('(min-height: 800px)');

  const [linesDataForReprocessing, setLinesDataForReprocessing] = useState({
    linesTotal: [],
    lineAll: [],
    lineArticle: [],
    lineQuantity: [],
    lineAmount: [],
    lineName: []
  });
  const [reprocessingLines, setReprocessingLines] = useState(false);

  const [invoiceAnnotateStart, setInvoiceAnnotateStart] = useState(null);
  const [invoiceAnnotateTime, setInvoiceAnnotateTime] = useState(0);
  const [annotateSelectedField, setAnnotateSelectedField] = useState(null);
  const [annotateFieldStart, setAnnotateFieldStart] = useState(null);

  const iAnnotateFieldsTime = {
    periodTime: 0,
    supplierTime: 0,
    descriptionTime: 0,
    poReferenceNumberTime: 0,
    amountTime: 0,
    discountAmountTime: 0,
    vatAmountTime: 0,
    vatPercentageTime: 0,
    currencyTime: 0,
    documentDateTime: 0,
    dueDateTime: 0,
    documentNumberTime: 0,
    paymentRefOGMVCSTime: 0,
    documentTypeTime: 0,
    secondaryDocumentTypeTime: 0,
    typeOfTransactionTime: 0,
    receiverCompanyTime: 0,
    receiverVatNumberTime: 0,
    receiverAddressTime: 0,
    receiverCityTime: 0,
    receiverPostalCodeTime: 0,
    receiverStreetTime: 0,
    receiverTaxNumberTime: 0,
    senderCompanyTime: 0,
    senderVatNumberTime: 0,
    senderIbanTime: 0,
    senderKvkTime: 0,
    senderAddressTime: 0,
    senderCityTime: 0,
    senderPostalCodeTime: 0,
    senderStreetTime: 0,
    senderEmailTime: 0,
    senderPhoneTime: 0,
    senderFaxTime: 0,
    senderWebsiteTime: 0,
    senderTaxNumberTime: 0,
    referenceNumberTime: 0,
    ocrNumberSETime: 0,
    paymentFormTime: 0,
    clientCodeTime: 0,
    totalWeightTime: 0,
    packagesNumberTime: 0,
    subtotalTime: 0,
    bookingNumberTime: 0,
    deliveryDateTime: 0,
    baseAmountTime: 0,
    discountRateTime: 0,
    equivalenceSurchargeAmountTime: 0,
    equivalenceSurchargeRateTime: 0,
    operationalOfficeTime: 0,
    typeOfOperationTime: 0,
    retentionAmountTime: 0,
    retentionPercentageTime: 0,
    termsTime: 0,
    gstAmountTime: 0,
    pstAmountTime: 0,
    deliveryTypeTime: 0,
    archiveNumberTime: 0,
    contactNumberTime: 0,
    estimationNumberTime: 0,
    placeAndDateOfEstimationTime: 0,
    estimationPeriodTime: 0,
    ccosteTime: 0,
    deliveryNoteNumberTime: 0,
    dateOfIssue2Time: 0,
    totalAmountBeforeDiscountTime: 0,
    referenceNumber2Time: 0,
    contractNumberTime: 0,
    mnjBaseAmountTime: 0,
    mnjDescriptionTime: 0,
    senderContactNameTime: 0,
    mnjPeriodStartTime: 0,
    mnjPeriodEndTime: 0,
    mnjPlaceTime: 0,
    dossierNumberTime: 0,
    mnjDeductionTypeTime: 0,
    mnjDeductionBaseAmountTime: 0,
    mnjDeductionVATRateTime: 0,
    mnjDeductionTotalTime: 0,
    cupsTime: 0,
    mnjAfterDiscountAmountTime: 0,
    nameTime: 0,
    surnameTime: 0,
    nationalityTime: 0,
    countryIssuedTime: 0,
    dateOfBirthTime: 0,
    dateOfIssueTime: 0,
    dateOfExpiryTime: 0,
    sexTime: 0,
    placeOfIssueTime: 0,
    mrzTime: 0,
    lineAmountTime: 0,
    linePriceTime: 0,
    lineUnitPriceTime: 0,
    lineDiscountTime: 0,
    lineQuantityTime: 0,
    lineCostCenterTime: 0,
    lineGLAccountTime: 0,
    lineVatGroupTime: 0,
    lineProjectTime: 0,
    lineVatrateTime: 0,
    lineTaxTime: 0,
    lineArticleTime: 0,
    lineReferenceNumberTime: 0,
    lineProductCountTime: 0,
    lineUnitMeasurementTime: 0,
    lineDescriptionTime: 0,
    lineArticleClientTime: 0,
    lineDiscountAmountOnUnitPriceTime: 0,
    lineDiscountAmountOnTotalPriceTime: 0,
    lineDiscountedPriceTime: 0,
    lineDepositTime: 0,
    lineVatCodeTime: 0,
    lineBaseAmountTime: 0,
    lineRetentionPercentageTime: 0,
    lineRetentionAmountTime: 0,
    lineCategoryTime: 0,
    lineProductCodeTime: 0,
    lineTotalPVTime: 0,
    lineMeasureTypeTime: 0,
    lineDelRecCompNameTime: 0,
    lineDelRecContNameTime: 0,
    lineDelRecAddressTime: 0,
    lineDelRecPhoneNumberTime: 0,
    lineDelRecEmailTime: 0,
    lineDeliveryDateTime: 0,
    lineTeilnrTime: 0,
    lineTeilnrDeliveryTime: 0,
    linePoReferenceNumberTime: 0,
    vatLineBaseAmountTime: 0,
    vatLineVatRateTime: 0,
    vatLineVatAmountTime: 0,
    vatLineTaxNameTime: 0,
    esLineBaseAmountTime: 0,
    esLineEsRateTime: 0,
    esLineEsAmountTime: 0,
  };

  const [annotateFieldsTime, setAnnotateFieldsTime] = useState(iAnnotateFieldsTime);

  const updateAnnotateFieldsTime = () => {
    if (annotateSelectedField && annotateFieldStart) {
      const timeKey = `${annotateSelectedField}Time`;
      if (annotateFieldsTime[timeKey] !== undefined) {
        setAnnotateFieldsTime({
          ...annotateFieldsTime,
          [timeKey]:
              annotateFieldsTime[timeKey] + (new Date().getTime() - annotateFieldStart)
        });
      }
    }
  };

  const pauseInvoiceAnnotateTime = () => {
    if (invoiceAnnotateStart) {
      const timeSpent = invoiceAnnotateTime + (new Date().getTime() - invoiceAnnotateStart);
      setInvoiceAnnotateTime(timeSpent);
      setInvoiceAnnotateStart(null);
    }
    updateAnnotateFieldsTime();
    setAnnotateSelectedField(null);
    setAnnotateFieldStart(null);
  };

  const restartInvoiceAnnotateTime = () => {
    setInvoiceAnnotateStart(new Date().getTime());
  };

  const getFieldsForManageFields = () => {
    dispatch(fetchHeaderFields(user?.companyID || '', docType, user.userRole === userRoles.annotator ? invoice.id : undefined));
  };

  const getLineFieldsForManageFields = () => {
    dispatch(fetchLineFields(user?.companyID || '', docType, user.userRole === userRoles.annotator ? invoice.id : undefined));
  };

  const validation = (item, regex) => {
    let isError = false;
    if (typeof item === 'string' && item !== '' && !!regex) {
      isError = !regex.test(item);
    }

    return isError;
  };

  const handleAttachmentsClose = () => {
    setAttachmentsOpen(false);
  };

  const handleMouseLeaveSplitScrnBtn = () => {
    setMouseLeftSplitScrnBtn(true);
    setTimeout(() => {
      if (mouseLeftAttachmentsTooltipRef.current && mouseLeftSplitScrnBtnRef.current) {
        handleAttachmentsClose();
      }
    }, 2000);
  };

  const handleMouseLeaveAttachmentsTooltip = () => {
    setMouseLeftAttachmentsTooltip(true);
    setTimeout(() => {
      if (mouseLeftSplitScrnBtnRef.current && mouseLeftAttachmentsTooltipRef.current) {
        handleAttachmentsClose();
      }
    }, 2000);
  };

  const handleShowAttachments = () => {
    setAttachmentsOpen((prevState) => !prevState);
  };

  const handleAttachmentClick = (attachment) => {
    window.open(`/files/attachment/${attachment.name}`);
    handleAttachmentsClose();
  };

  const handleSendLinesChange = () => {
    setSendLines((prevState) => !prevState);
  };

  const handleSpreadInvoiceChange = () => {
    setSpreadInvoice((prevState) => !prevState);
  };

  const isSpreadFieldsNotEmpty = () => (invoice.spreadFrom && invoice.spreadTo && invoice.gla);

  const findAllSuppliers = async () => {
    if (window.sessionStorage.getItem('suppliers') === null) {
      setLoading(true);
    }
    try {
      const resp = await Axios.get(
        `${API.findSuppliers}/${parameterDocCompany}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (resp.data.success) {
        window.sessionStorage.setItem('suppliers', JSON.stringify(resp.data.data));
        setSuppliers(resp.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getAllGLAccounts = () => {
    dispatch(fetchGlas(parameterDocCompany));
  };

  const getAllVatCodes = () => {
    dispatch(fetchVatCodes(parameterDocCompany));
  };

  const getAllCostCenters = async () => {
    dispatch(fetchCostCenters(parameterDocCompany));
  };

  const getAllProjects = async () => {
    try {
      const response = await Axios.get(
        `${API.projectsByCompany}/${encodeURIComponent(parameterDocCompany)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data.length > 0) {
          setProjects([...supplierData.defaultProjects, ...response.data.data]);
        }
      }
    } catch (error) {
      //
    }
  };

  const getQBTerms = async () => {
    try {
      const response = await Axios.get(
        `${API.qbTerms}/${encodeURIComponent(parameterCompany)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setQBTerms(response.data.data);
        } else {
          setQBTerms([]);
        }
      }
    } catch (error) {
      setQBTerms([]);
    }
  };

  const handleOpenManageFields = () => {
    setShowManageFields(true);
    pauseInvoiceAnnotateTime();
  };

  const handleCloseManageFields = () => {
    setShowManageFields(false);
    restartInvoiceAnnotateTime();
  };

  const getDescriptionFromTFSupplier = (descriptionRule) => {
    let description = descriptionRule;
    let descriptionChanged = false;

    if (description) {
      if (description.indexOf('<month>') >= 0 && details.documentDate !== '' && details.documentDate.indexOf('-') >= 0) {
        const dateParts = details.documentDate.split('-');
        if (dateParts.length >= 3) {
          description = description.replaceAll('<month>', getMonthShortString(dateParts[1]));
          descriptionChanged = true;
        }
      }

      if (description.indexOf('<year>') >= 0 && details.documentDate !== '' && details.documentDate.indexOf('-') >= 0) {
        const dateParts = details.documentDate.split('-');
        if (dateParts.length >= 3 && details.documentDate.indexOf('-') >= 4) {
          description = description.replaceAll('<year>', dateParts[0]);
          descriptionChanged = true;
        }

        if (dateParts.length >= 3 && details.documentDate.indexOf('-') === 2) {
          description = description.replaceAll('<year>', dateParts[2]);
          descriptionChanged = true;
        }
      }

      if (description.indexOf('<supplier code>') >= 0 && supplier?.code !== '') {
        description = description.replaceAll('<supplier code>', supplier?.code);
        descriptionChanged = true;
      }

      if (description.indexOf('<country>') >= 0 && supplier?.country !== '') {
        description = description.replaceAll('<country>', supplier?.country);
        descriptionChanged = true;
      }

      return descriptionChanged ? description : details.description;
    }

    return details.description;
  };

  const calculateVAT = async (vatGroup, amount) => {
    try {
      const response = await Axios.get(
        `${API.vatCodeByCode}/${vatGroup}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        const vatRate = response.data.data.ratePercentage;
        return calculateLineTaxForVat(amount, vatRate, decimalSeparator);
      }

      return '0.00'.replace('.', decimalSeparator);
    } catch (err) {
      return '0.00'.replace('.', decimalSeparator);
    }
  };

  const getTFSupplierByCode = async (id, displayLoading) => {
    try {
      if (displayLoading) {
        setLoading(true);
      }
      const response = await Axios.get(
        `${API.suppliersV2}/${id}/${parameterDocCompany}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setSupplier(response.data.data);
        if (displayLoading) {
          setLoading(false);
        }
        setLineDescription(getDescriptionFromTFSupplier(
          response.data.data?.invoiceLineDescriptionRule || supplier?.invoiceLineDescriptionRule
            || response.data.data?.invoiceDescriptionRule || supplier?.invoiceDescriptionRule || ''
        ));

        return response.data.data;
      }
      if (displayLoading) {
        setLoading(false);
      }
      return null;
    } catch (error) {
      if (displayLoading) {
        setLoading(false);
      }
      return null;
    }
  };

  const getImage = async (name) => {
    setFetchingFile(true);
    try {
      const response = await Axios.get(
        `${API.getInvoiceImage}${name}`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `bearer ${localStorage.getItem('PROCYS_accessToken')}`
          }
        }
      );
      const blob = new Blob([response.data], { type: 'application/pdf' });
      setFileResponse(window.URL.createObjectURL(blob));
      setFetchingFile(false);
    } catch (err) {
      setFileResponse(null);
      setFetchingFile(false);
    }
  };

  const getDeliverFormats = async () => {
    try {
      const response = await Axios.get(
        API.getDeliverFormats,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setDeliverFormats(response.data.data);
        }
      }
    } catch (error) {
      setDeliverFormats([]);
    }
  };

  const getExcelExportFieldMapping = async () => {
    try {
      const response = await Axios.get(
        `${API.exportFieldMapping}excel`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setExcelExportFieldMapping(response.data.data);
          return;
        }
      }
      setExcelExportFieldMapping([]);
    } catch (error) {
      setExcelExportFieldMapping([]);
    }
  };

  const getDatExportFieldMapping = async () => {
    try {
      const response = await Axios.get(
        `${API.exportFieldMapping}dat`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setDatExportFieldMapping(response.data.data);
          return;
        }
      }
      setDatExportFieldMapping([]);
    } catch (error) {
      setDatExportFieldMapping([]);
    }
  };

  const getCsvExportFieldMapping = async () => {
    try {
      const response = await Axios.get(
        `${API.exportFieldMapping}csv`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        if (response.data.data) {
          setCsvExportFieldMapping(response.data.data);
          return;
        }
      }
      setCsvExportFieldMapping([]);
    } catch (error) {
      setCsvExportFieldMapping([]);
    }
  };

  useEffect(() => {
    findAllSuppliers();
    getAllGLAccounts();
    getAllVatCodes();
    getAllCostCenters();
    getAllProjects();
    getQBTerms();

    getDeliverFormats();
    getExcelExportFieldMapping();
    getDatExportFieldMapping();
    getCsvExportFieldMapping();

    if (details.supplier) {
      getTFSupplierByCode(details.supplier);
    }

    const nameParts = invoice?.documentFile?.split('.');
    let imageName = '';
    if (nameParts && nameParts[nameParts.length - 1] !== 'pdf') {
      setImgName(`${nameParts[0]}-display.pdf`);
      imageName = `${nameParts[0]}-display.pdf`;
    } else {
      setImgName(invoice.documentFile);
      imageName = invoice.documentFile;
    }

    getImage(imageName);

    setCurrencies(
      cc.sort((a, b) => {
        if (a.code < b.code) {
          return -1;
        }
        return 1;
      })
    );

    const allCountries = Object.keys(countries).map((key) => ({
      isoCode: key,
      name: countries[key]?.name,
    }));
    setCountriesList([{
      isoCode: '',
      name: '-'
    }, ...allCountries]);

    if (invoice.vat) {
      setVatLines(invoice.vat?.map((v) => ({ ...v, error: false, invalid: false })));
    }

    if (invoice.esLines) {
      setESLines(invoice.esLines?.map((v) => ({ ...v, error: false, invalid: false })));
    }

    setInvoiceAnnotateStart(new Date().getTime());
  }, []);

  useEffect(() => {
    if (user.deliverEmails && user.deliverEmails.length > 0) {
      setEmails(user.deliverEmails.join(', '));
    }

    if (user.deliverFormat) {
      setSelectedDataFormatToEmail(user.deliverFormat);
    } else {
      const activeDeliverFormat = deliverFormats.find((o) => o.active);
      setSelectedDataFormatToEmail(activeDeliverFormat ? activeDeliverFormat.key : deliverFormats[0]?.key);
    }
  }, [user, deliverFormats]);

  useEffect(() => {
    setDetailsXML({
      ...detailsXML,
      terms: qbTerms
    });
    setDetailsXMLi({
      ...detailsXMLi,
      terms: qbTerms
    });
  }, [qbTerms]);

  const handleChangeSupplier = (s) => {
    setNoSupplier('');
    if (s && s.trim() !== '' && suppliers && suppliers.length > 0) {
      const search = s.trim();
      let isListEmpty = true;
      for (let i = 0; i < suppliers.length; i++) {
        if (suppliers[i]?.creditorCode?.toLowerCase().indexOf(search) >= 0 || suppliers[i]?.creditorName?.toLowerCase().indexOf(search) >= 0) {
          isListEmpty = false;
          break;
        }
      }

      if (isListEmpty) {
        setNoSupplier(search);
      }
    }
  };

  const addNewSupplier = () => {
    setNewSupplier({
      creditorCode: noSupplier,
      creditorName: details.senderCompany,
      website: details.senderWebsite,
      vatNumber: details.senderVatNumber,
      defaultCurrency: details.currency,
      iban: details.senderIban,
      telephoneNumber: details.senderPhone,
      faxNumber: details.senderFax,
      emailAddress: details.senderEmail,
      address1: details.senderAddress,
      city: details.senderCity,
      poBoxPostalCode: details.senderPostalCode,
      poBoxCity: details.senderCity,
      dueDays: '45',
      country: 'ES',
    });
    setAddSupplierOpen(true);
    pauseInvoiceAnnotateTime();
  };

  const fieldOpenInitial = Object.fromEntries(headerFieldIds.map((field) => [field, false]));

  const [fieldOpen, setFieldOpen] = useState(fieldOpenInitial);

  const fieldRef = {
    documentDate: useRef(null),
    dueDate: useRef(null),
    period: useRef(null),
    documentNumber: useRef(null),
    supplier: useRef(null),
    currency: useRef(null),
    amount: useRef(null),
    vatAmount: useRef(null),
    vatPercentage: useRef(null),
    discountAmount: useRef(null),
    description: useRef(null),
    paymentRefOGMVCS: useRef(null),
    poReferenceNumber: useRef(null),
    receiverCompany: useRef(null),
    receiverVatNumber: useRef(null),
    receiverAddress: useRef(null),
    receiverCity: useRef(null),
    receiverPostalCode: useRef(null),
    receiverStreet: useRef(null),
    receiverTaxNumber: useRef(null),
    senderCompany: useRef(null),
    senderVatNumber: useRef(null),
    senderAddress: useRef(null),
    senderCity: useRef(null),
    senderPostalCode: useRef(null),
    senderStreet: useRef(null),
    senderEmail: useRef(null),
    senderPhone: useRef(null),
    senderWebsite: useRef(null),
    senderFax: useRef(null),
    senderIban: useRef(null),
    senderKvk: useRef(null),
    senderTaxNumber: useRef(null),
    referenceNumber: useRef(null),
    ocrNumberSE: useRef(null),
    vatAmount2: useRef(null),
    vatPercentage2: useRef(null),
    clientCode: useRef(null),
    totalWeight: useRef(null),
    packagesNumber: useRef(null),
    subtotal: useRef(null),
    bookingNumber: useRef(null),
    deliveryDate: useRef(null),
    baseAmount: useRef(null),
    discountRate: useRef(null),
    equivalenceSurchargeAmount: useRef(null),
    equivalenceSurchargeRate: useRef(null),
    operationalOffice: useRef(null),
    typeOfOperation: useRef(null),
    retentionAmount: useRef(null),
    retentionPercentage: useRef(null),
    terms: useRef(null),
    gstAmount: useRef(null),
    pstAmount: useRef(null),
    deliveryType: useRef(null),
    archiveNumber: useRef(null),
    contactNumber: useRef(null),
    estimationNumber: useRef(null),
    placeAndDateOfEstimation: useRef(null),
    estimationPeriod: useRef(null),
    ccoste: useRef(null),
    deliveryNoteNumber: useRef(null),
    dateOfIssue2: useRef(null),
    totalAmountBeforeDiscount: useRef(null),
    referenceNumber2: useRef(null),
    contractNumber: useRef(null),
    mnjBaseAmount: useRef(null),
    mnjDescription: useRef(null),
    senderContactName: useRef(null),
    mnjPeriodStart: useRef(null),
    mnjPeriodEnd: useRef(null),
    mnjPlace: useRef(null),
    dossierNumber: useRef(null),
    mnjDeductionType: useRef(null),
    mnjDeductionBaseAmount: useRef(null),
    mnjDeductionVATRate: useRef(null),
    mnjDeductionTotal: useRef(null),
    mnjAfterDiscountAmount: useRef(null),
    cups: useRef(null),
    name: useRef(null),
    surname: useRef(null),
    nationality: useRef(null),
    dateOfBirth: useRef(null),
    dateOfIssue: useRef(null),
    dateOfExpiry: useRef(null),
    sex: useRef(null),
    placeOfIssue: useRef(null),
    mrz: useRef(null),
  };

  const vatDetailsRef = useRef(null);
  const surchargeDetailsRef = useRef(null);

  const isInViewPort = {
    documentDate: useIsInViewport(fieldRef.documentDate),
    dueDate: useIsInViewport(fieldRef.dueDate),
    period: useIsInViewport(fieldRef.period),
    documentNumber: useIsInViewport(fieldRef.documentNumber),
    supplier: useIsInViewport(fieldRef.supplier),
    currency: useIsInViewport(fieldRef.currency),
    amount: useIsInViewport(fieldRef.amount),
    vatAmount: useIsInViewport(fieldRef.vatAmount),
    vatPercentage: useIsInViewport(fieldRef.vatPercentage),
    discountAmount: useIsInViewport(fieldRef.discountAmount),
    description: useIsInViewport(fieldRef.description),
    paymentRefOGMVCS: useIsInViewport(fieldRef.paymentRefOGMVCS),
    poReferenceNumber: useIsInViewport(fieldRef.poReferenceNumber),
    receiverCompany: useIsInViewport(fieldRef.receiverCompany),
    receiverVatNumber: useIsInViewport(fieldRef.receiverVatNumber),
    receiverAddress: useIsInViewport(fieldRef.receiverAddress),
    receiverCity: useIsInViewport(fieldRef.receiverCity),
    receiverPostalCode: useIsInViewport(fieldRef.receiverPostalCode),
    receiverStreet: useIsInViewport(fieldRef.receiverStreet),
    receiverTaxNumber: useIsInViewport(fieldRef.receiverTaxNumber),
    senderCompany: useIsInViewport(fieldRef.senderCompany),
    senderVatNumber: useIsInViewport(fieldRef.senderVatNumber),
    senderAddress: useIsInViewport(fieldRef.senderAddress),
    senderCity: useIsInViewport(fieldRef.senderCity),
    senderPostalCode: useIsInViewport(fieldRef.senderPostalCode),
    senderStreet: useIsInViewport(fieldRef.senderStreet),
    senderEmail: useIsInViewport(fieldRef.senderEmail),
    senderPhone: useIsInViewport(fieldRef.senderPhone),
    senderWebsite: useIsInViewport(fieldRef.senderWebsite),
    senderFax: useIsInViewport(fieldRef.senderFax),
    senderIban: useIsInViewport(fieldRef.senderIban),
    senderKvk: useIsInViewport(fieldRef.senderKvk),
    senderTaxNumber: useIsInViewport(fieldRef.senderTaxNumber),
    referenceNumber: useIsInViewport(fieldRef.referenceNumber),
    ocrNumberSE: useIsInViewport(fieldRef.ocrNumberSE),
    vatAmount2: useIsInViewport(fieldRef.vatAmount2),
    vatPercentage2: useIsInViewport(fieldRef.vatPercentage2),
    clientCode: useIsInViewport(fieldRef.clientCode),
    totalWeight: useIsInViewport(fieldRef.totalWeight),
    packagesNumber: useIsInViewport(fieldRef.packagesNumber),
    subtotal: useIsInViewport(fieldRef.subtotal),
    bookingNumber: useIsInViewport(fieldRef.bookingNumber),
    deliveryDate: useIsInViewport(fieldRef.deliveryDate),
    baseAmount: useIsInViewport(fieldRef.baseAmount),
    discountRate: useIsInViewport(fieldRef.discountRate),
    equivalenceSurchargeAmount: useIsInViewport(fieldRef.equivalenceSurchargeAmount),
    equivalenceSurchargeRate: useIsInViewport(fieldRef.equivalenceSurchargeRate),
    operationalOffice: useIsInViewport(fieldRef.operationalOffice),
    typeOfOperation: useIsInViewport(fieldRef.typeOfOperation),
    retentionAmount: useIsInViewport(fieldRef.retentionAmount),
    retentionPercentage: useIsInViewport(fieldRef.retentionPercentage),
    terms: useIsInViewport(fieldRef.terms),
    gstAmount: useIsInViewport(fieldRef.gstAmount),
    pstAmount: useIsInViewport(fieldRef.pstAmount),
    deliveryType: useIsInViewport(fieldRef.deliveryType),
    archiveNumber: useIsInViewport(fieldRef.archiveNumber),
    contactNumber: useIsInViewport(fieldRef.contactNumber),
    estimationNumber: useIsInViewport(fieldRef.estimationNumber),
    placeAndDateOfEstimation: useIsInViewport(fieldRef.placeAndDateOfEstimation),
    estimationPeriod: useIsInViewport(fieldRef.estimationPeriod),
    ccoste: useIsInViewport(fieldRef.ccoste),
    deliveryNoteNumber: useIsInViewport(fieldRef.deliveryNoteNumber),
    dateOfIssue2: useIsInViewport(fieldRef.dateOfIssue2),
    totalAmountBeforeDiscount: useIsInViewport(fieldRef.totalAmountBeforeDiscount),
    referenceNumber2: useIsInViewport(fieldRef.referenceNumber2),
    contractNumber: useIsInViewport(fieldRef.contractNumber),
    mnjBaseAmount: useIsInViewport(fieldRef.mnjBaseAmount),
    mnjDescription: useIsInViewport(fieldRef.mnjDescription),
    senderContactName: useIsInViewport(fieldRef.senderContactName),
    mnjPeriodStart: useIsInViewport(fieldRef.mnjPeriodStart),
    mnjPeriodEnd: useIsInViewport(fieldRef.mnjPeriodEnd),
    mnjPlace: useIsInViewport(fieldRef.mnjPlace),
    dossierNumber: useIsInViewport(fieldRef.dossierNumber),
    mnjDeductionType: useIsInViewport(fieldRef.mnjDeductionType),
    mnjDeductionBaseAmount: useIsInViewport(fieldRef.mnjDeductionBaseAmount),
    mnjDeductionVATRate: useIsInViewport(fieldRef.mnjDeductionVATRate),
    mnjDeductionTotal: useIsInViewport(fieldRef.mnjDeductionTotal),
    mnjAfterDiscountAmount: useIsInViewport(fieldRef.mnjAfterDiscountAmount),
    cups: useIsInViewport(fieldRef.cups),
    name: useIsInViewport(fieldRef.name),
    surname: useIsInViewport(fieldRef.surname),
    nationality: useIsInViewport(fieldRef.nationality),
    dateOfBirth: useIsInViewport(fieldRef.dateOfBirth),
    dateOfIssue: useIsInViewport(fieldRef.dateOfIssue),
    dateOfExpiry: useIsInViewport(fieldRef.dateOfExpiry),
    sex: useIsInViewport(fieldRef.sex),
    placeOfIssue: useIsInViewport(fieldRef.placeOfIssue),
    mrz: useIsInViewport(fieldRef.mrz),
  };
  const vatDetailsInViewPort = useIsInViewport(vatDetailsRef);
  const surchargeDetailsInViewPort = useIsInViewport(surchargeDetailsRef);

  const updateDetailsXML = (obj, name, prop) => {
    if (obj[name] && invoice[prop]) {
      let found = false;
      for (let i = 0; i < obj[name].length; i++) {
        if (obj[name][i].value === invoice[prop]) {
          found = true;
          break;
        }
      }
      if (!found) {
        obj[name].unshift({ value: invoice[prop] });
      }
      return obj[name];
    }

    if (invoice[prop]) {
      return [{ value: invoice[prop] }];
    }

    if (!obj[name] && !invoice[prop]) {
      return [{ value: ' ' }];
    }

    return obj[name];
  };

  useEffect(() => {
    if (xmlReceived && !isLoaded) {
      const d = {};
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(xml, 'text/html');

      setImageBinary(htmlDoc.getElementsByTagName('jpg_base64'));
      setPage(htmlDoc.getElementsByTagName('page'));

      const all = htmlDoc.getElementsByTagName('*');

      let pageNum = -1;
      const cands = [];
      // Filter the details of html
      for (let i = 0; i < all.length; i++) {
        const listItem = all[i];
        if (listItem.tagName === 'PAGE') {
          pageNum++;
        }
        if (listItem.tagName === 'CAND') {
          const atter = {};
          atter.value = listItem.getAttribute('resp_value') || ' ';
          atter.proba = listItem.getAttribute('proba') || 0;
          atter.xmax = listItem.getAttribute('xmax') || 0;
          atter.xmin = listItem.getAttribute('xmin') || 0;
          atter.ymax = listItem.getAttribute('ymax') || 0;
          atter.ymin = listItem.getAttribute('ymin') || 0;
          atter.innerVal = listItem.innerHTML;

          cands.push({ ...atter, name: listItem.getAttribute('resp_name'), page: pageNum });

          if (!d.hasOwnProperty(listItem.getAttribute('resp_name'))) {
            d[listItem.getAttribute('resp_name')] = [];
          }
          if (!d[listItem.getAttribute('resp_name')].some((item) => item.value === atter.value)) {
            d[listItem.getAttribute('resp_name')].push(atter);
          }
        }
      }

      setCandidates(cands);
      setNumPages(pageNum + 1);

      pageNum = -1;
      let invoiceLine = 0;
      const blocks = [];

      for (let i = 0; i < all.length; i++) {
        const item = all[i];
        if (item.tagName === 'PAGE') {
          pageNum++;
          invoiceLine = 0;
        }
        if (item.tagName === 'INVOICELINE') {
          invoiceLine++;
        }
        if (item.tagName === 'BLOCK') {
          const lineItem = {};
          lineItem.page = pageNum;
          lineItem.lineNo = invoiceLine;
          lineItem.proba = item.getAttribute('resp_proba');
          lineItem.name = item.getAttribute('resp_name');
          lineItem.xmax = item.getAttribute('xmax');
          lineItem.xmin = item.getAttribute('xmin');
          lineItem.ymax = item.getAttribute('ymax');
          lineItem.ymin = item.getAttribute('ymin');
          lineItem.value = item.innerHTML;
          blocks.push(lineItem);
        }
      }

      setLineItems(blocks);

      const specialCands = [];

      cands.forEach((cand) => {
        if (cand.name === 'date') {
          if (!specialCands.some((o) => o.name === 'date')) {
            specialCands.push(cand);
          } else if (specialCands.filter((o) => o.name === 'date')[0]?.proba < cand.proba) {
            specialCands.push(cand);
          }
        } else if (cand.name === 'due_date') {
          if (!specialCands.some((o) => o.name === 'due_date')) {
            specialCands.push(cand);
          } else if (specialCands.filter((o) => o.name === 'due_date')[0]?.proba < cand.proba) {
            specialCands.push(cand);
          }
        } else if (cand.name === 'amount') {
          if (!specialCands.some((o) => o.name === 'amount')) {
            specialCands.push(cand);
          } else if (specialCands.filter((o) => o.name === 'amount')[0]?.proba < cand.proba) {
            specialCands.push(cand);
          }
        } else if (cand.name === 'vat_amount') {
          if (!specialCands.some((o) => o.name === 'vat_amount')) {
            specialCands.push(cand);
          } else if (specialCands.filter((o) => o.name === 'vat_amount')[0]?.proba < cand.proba) {
            specialCands.push(cand);
          }
        } else if (cand.name === 'discount_amount') {
          if (!specialCands.some((o) => o.name === 'discount_amount')) {
            specialCands.push(cand);
          } else if (specialCands.filter((o) => o.name === 'discount_amount')[0]?.proba < cand.proba) {
            specialCands.push(cand);
          }
        }
      });

      pageNum = -1;

      const coords = [];
      // Get the coordinates for the bounding boxes
      for (let i = 0; i < all.length; i++) {
        const item = all[i];
        if (item.tagName === 'PAGE') {
          pageNum++;
        }
        if (item.tagName === 'WORD') {
          const itemPresent = specialCands.find((elem) => isOverlapping(parseFloat(item.getAttribute('xmin')),
            parseFloat(item.getAttribute('ymin')),
            parseFloat(item.getAttribute('xmax')),
            parseFloat(item.getAttribute('ymax')),
            parseFloat(elem.xmin),
            parseFloat(elem.ymin),
            parseFloat(elem.xmax),
            parseFloat(elem.ymax)));

          if (!itemPresent) {
            const point = {};
            point.page = pageNum;
            point.xmax = item.getAttribute('xmax');
            point.xmin = item.getAttribute('xmin');
            point.ymax = item.getAttribute('ymax');
            point.ymin = item.getAttribute('ymin');
            point.value = item.innerHTML;
            coords.push(point);
          } else if (!coords.some((o) => o.name === itemPresent.name)) {
            coords.push(itemPresent);
          }
        }
      }

      setWords(coords);

      const specialLineItems = [];

      blocks.forEach((item) => {
        if (item.name === 'line_name') {
          specialLineItems.push(item);
        }
      });

      pageNum = -1;

      const coordsForLines = [];
      // Get the coordinates for the bounding boxes
      for (let i = 0; i < all.length; i++) {
        const item = all[i];
        if (item.tagName === 'PAGE') {
          pageNum++;
        }
        if (item.tagName === 'WORD') {
          // This is commented at the moment to draw boxes around all words in line edit/add form
          // const itemPresent = specialLineItems.find((elem) => (parseInt(item.getAttribute('xmin'), 10) <= (parseInt(elem.xmin, 10) + 2)
          //     && parseInt(item.getAttribute('xmax'), 10) >= (parseInt(elem.xmax, 10) - 2)
          //     && ((elem.ymin < item.getAttribute('ymin') && item.getAttribute('ymin') < elem.ymax)
          //       || (elem.ymin < item.getAttribute('ymax') && item.getAttribute('ymax') < elem.ymax)))
          //   || ((parseInt(elem.xmin, 10) - 2) <= parseInt(item.getAttribute('xmin'), 10)
          //     && (parseInt(elem.xmax, 10) + 2) >= parseInt(item.getAttribute('xmax'), 10)
          //     && ((item.getAttribute('ymin') < elem.ymin && elem.ymin < item.getAttribute('ymax'))
          //       || (item.getAttribute('ymin') < elem.ymax && elem.ymax < item.getAttribute('ymax')))));

          // if (!itemPresent) {
          const point = {};
          point.page = pageNum;
          point.xmax = item.getAttribute('xmax');
          point.xmin = item.getAttribute('xmin');
          point.ymax = item.getAttribute('ymax');
          point.ymin = item.getAttribute('ymin');
          point.value = item.innerHTML;
          coordsForLines.push(point);
          // }
        }
      }

      setWordsForLines(coordsForLines);

      Object.keys(d).forEach((attr) => {
        const arr = d[attr];
        Object.keys(arr).forEach(() => {
          arr.sort((a, b) => {
            const keyA = new Date(a.proba);
            const keyB = new Date(b.proba);

            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });
        });
        detail[attr] = arr[0].value;
      });

      if (d.amount) {
        setIsLoaded(true);

        d.number = updateDetailsXML(d, 'number', 'documentNumber');
        d.date = updateDetailsXML(d, 'date', 'documentDate');
        d.due_date = updateDetailsXML(d, 'due_date', 'dueDate');
        d.currency = updateDetailsXML(d, 'currency', 'currency');
        d.amount = updateDetailsXML(d, 'amount', 'amount');
        d.vat_amount = updateDetailsXML(d, 'vat_amount', 'vatAmount');
        d.discount_amount = updateDetailsXML(d, 'discount_amount', 'discountAmount');
        d.vat_percentage = updateDetailsXML(d, 'vat_percentage', 'vatPercentage');
        d.payment_ref_OGM_VCS = updateDetailsXML(d, 'payment_ref_OGM_VCS', 'paymentRefOGMVCS');
        d.PO_reference_number = updateDetailsXML(d, 'PO_reference_number', 'poReferenceNumber');
        d.receiver_company = updateDetailsXML(d, 'receiver_company', 'receiverCompany');
        d.receiver_VAT_number = updateDetailsXML(d, 'receiver_VAT_number', 'receiverVatNumber');
        d.receiver_address = updateDetailsXML(d, 'receiver_address', 'receiverAddress');
        d.receiver_city = updateDetailsXML(d, 'receiver_city', 'receiverCity');
        d.receiver_street = updateDetailsXML(d, 'receiver_street', 'receiverStreet');
        d.receiver_postal_code = updateDetailsXML(d, 'receiver_postal_code', 'receiverPostalCode');
        d.sender_company = updateDetailsXML(d, 'sender_company', 'senderCompany');
        d.sender_VAT_number = updateDetailsXML(d, 'sender_VAT_number', 'senderVatNumber');
        d.sender_address = updateDetailsXML(d, 'sender_address', 'senderAddress');
        d.sender_city = updateDetailsXML(d, 'sender_city', 'senderCity');
        d.sender_street = updateDetailsXML(d, 'sender_street', 'senderStreet');
        d.sender_postal_code = updateDetailsXML(d, 'sender_postal_code', 'senderPostalCode');
        d.sender_website = updateDetailsXML(d, 'sender_website', 'senderWebsite');
        d.sender_email = updateDetailsXML(d, 'sender_email', 'senderEmail');
        d.sender_kvk = updateDetailsXML(d, 'sender_kvk', 'senderKvk');
        d.sender_iban = updateDetailsXML(d, 'sender_iban', 'senderIban');
        d.sender_phone = updateDetailsXML(d, 'sender_phone', 'senderPhone');
        d.sender_fax = updateDetailsXML(d, 'sender_fax', 'senderFax');
        d.reference_number = updateDetailsXML(d, 'reference_number', 'referenceNumber');
        d.client_code = updateDetailsXML(d, 'client_code', 'clientCode');
        d.total_weight = updateDetailsXML(d, 'total_weight', 'totalWeight');
        d.packages_number = updateDetailsXML(d, 'packages_number', 'packagesNumber');
        d.subtotal = updateDetailsXML(d, 'subtotal', 'subtotal');
        d.booking_number = updateDetailsXML(d, 'booking_number', 'bookingNumber');
        d.baseAmount = updateDetailsXML(d, 'base_amount', 'baseAmount');
        d.discountRate = updateDetailsXML(d, 'discount_rate', 'discountRate');
        d.equivalence_surcharge_amount = updateDetailsXML(d, 'equivalence_surcharge_amount', 'equivalenceSurchargeAmount');
        d.equivalence_surcharge_rate = updateDetailsXML(d, 'equivalence_surcharge_rate', 'equivalenceSurchargeRate');
        d.operational_office = updateDetailsXML(d, 'operational_office', 'operationalOffice');
        d.retention_amount = updateDetailsXML(d, 'retention_amount', 'retentionAmount');
        d.retention_percentage = updateDetailsXML(d, 'retention_percentage', 'retentionPercentage');
        d.terms = updateDetailsXML(d, 'terms', 'terms');
        d.gst_amount = updateDetailsXML(d, 'gst_amount', 'gstAmount');
        d.pst_amount = updateDetailsXML(d, 'pst_amount', 'pstAmount');

        setDetailsXML(d);
        setDetailsXMLi(d);
        setIsLoaded(true);
      }
    }
  }, [xmlReceived]);

  const handleChange = (value, prop) => {
    setIsSubmitClicked(false);
    setDetails({ ...details, [prop]: value });
  };

  const handleChangeMultiple = (values, props) => {
    setIsSubmitClicked(false);
    const newDetails = { ...details };
    for (let i = 0; i < values.length; i++) {
      newDetails[props[i]] = values[i];
    }
    setDetails(newDetails);
  };

  const handleChangeXML = (name, values) => {
    setIsSubmitClicked(false);
    // Note: Removed condition - detail.hasOwnProperty(name)
    if (values) {
      detail[name] = values.value;
      setDetail({
        ...detail,
        [name]: values.value
      });
    }
  };

  const handleChangeMain = async (event, prop, name, values) => {
    const formattedVal = { value: values ? values.value : event.target.value };
    if (nonNumericFieldsHeader.includes(prop) && leadingWhiteSpace.test(formattedVal.value)) {
      formattedVal.value = formattedVal.value.trimStart();
    }

    if (prop === 'documentDate' && !values) {
      handleChange('', prop);
      return;
    }

    if (values && values.value) {
      if (prop === 'documentDate') {
        const d = values.value;

        if (!dateFieldRule && d && d.indexOf('-') === 2) {
          const parts = d.split('-');

          if (parts.length >= 3) {
            handleChangeMultiple([`${parts[2].substring(0, 4)}/${parts[1]}`, values.value], ['period', 'prop']);
          }
        }
        handleChange(d, prop);
      } else if (prop === 'senderVatNumber' || prop === 'receiverVatNumber') {
        const firstTwo = formattedVal.value.substring(0, 2);
        if (countriesList.some((c) => c.isoCode === firstTwo.toUpperCase())) {
          handleChangeMultiple([firstTwo.toUpperCase(), formattedVal.value.substring(2)], [`${prop.replace('VatNumber', '')}CountryCode`, prop]);
        } else {
          handleChange(formattedVal.value, prop);
        }
      } else if (prop === 'amount') {
        if (details?.vatAmount) {
          const baseAmount = calculateBaseAmount(formattedVal.value, details.vatAmount, decimalSeparator);
          const valArr = [formattedVal.value, baseAmount];
          const propArr = ['amount', 'baseAmount'];
          if (details?.discountAmount) {
            valArr.push(calculateDiscountRate(details.discountAmount, baseAmount, decimalSeparator));
            propArr.push('discountRate');
          }
          handleChangeMultiple(valArr, propArr);
        } else {
          handleChange(formattedVal.value, prop);
        }
      } else if (prop === 'vatAmount') {
        if (details?.amount) {
          const baseAmount = calculateBaseAmount(details.amount, formattedVal.value, decimalSeparator);
          const valArr = [formattedVal.value, baseAmount];
          const propArr = ['vatAmount', 'baseAmount'];
          if (details?.discountAmount) {
            valArr.push(calculateDiscountRate(details.discountAmount, baseAmount, decimalSeparator));
            propArr.push('discountRate');
          }
          handleChangeMultiple(valArr, propArr);
        } else {
          handleChange(formattedVal.value, prop);
        }
      } else if (prop === 'discountAmount') {
        if (details?.amount && details?.vatAmount) {
          const baseAmount = calculateBaseAmount(details.amount, details.vatAmount, decimalSeparator);
          const valArr = [formattedVal.value, calculateDiscountRate(formattedVal.value, baseAmount, decimalSeparator)];
          const propArr = ['discountAmount', 'discountRate'];
          handleChangeMultiple(valArr, propArr);
        } else {
          handleChange(formattedVal.value, prop);
        }
      } else {
        handleChange(formattedVal.value, prop);
      }

      handleChangeXML(name, formattedVal);
      return;
    }

    if (prop === 'supplier' && values?.creditorCode) {
      // Fetch supplier details
      if (details.supplier !== values?.creditorCode) {
        const supp = await getTFSupplierByCode(values?.creditorCode, true);
        const desc = getDescriptionFromTFSupplier(supp?.invoiceDescriptionRule || supplier?.invoiceDescriptionRule || '');
        const lineDesc = getDescriptionFromTFSupplier(
          supp?.invoiceLineDescriptionRule || supplier?.invoiceLineDescriptionRule
            || supp?.invoiceDescriptionRule || supplier?.invoiceDescriptionRule || ''
        );

        setLineDescription(lineDesc);

        let lines = details.lines;
        if (details?.lines?.length > 0) {
          if (isTwinfield) {
            const line = details.lines[0];
            line.lineDescription = lineDesc;
            line.lineGLAccount = supp?.generalLedgerAccLine || line.lineGLAccount;
            line.lineVatGroup = supp?.vatGroup || line.lineVatGroup;
            line.lineCostCenter = supp?.costCenter || line.lineCostCenter;
            line.lineProject = supp?.project || line.lineProject;
            line.lineTax = (await calculateVAT(line.lineVatGroup, line.lineAmount)).toString();
            lines.shift();

            lines = [line, ...lines];
          }
          const supplierProducts = supp?.inventories || [];
          for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            if (line.lineArticle && supplierProducts.some((p) => p.article === line.lineArticle)) {
              const supplierProduct = supplierProducts.find((p) => p.article === line.lineArticle);
              line.lineArticleClient = supplierProduct?.articleClient || line.lineArticleClient;
              line.lineUnitMeasurement = supplierProduct?.unitMeasurement || line.lineUnitMeasurement;
            } else if (line.lineArticleClient && supplierProducts.some((p) => p.articleClient === line.lineArticleClient)) {
              const supplierProduct = supplierProducts.find((p) => p.articleClient === line.lineArticleClient);
              line.lineArticle = supplierProduct?.article || line.lineArticle;
              line.lineUnitMeasurement = supplierProduct?.unitMeasurement || line.lineUnitMeasurement;
            }
          }
        }

        const newDetails = {
          ...details,
          supplier: values.creditorCode,
          description: isTwinfield ? desc : details.description,
          doNotPay: !supp?.autoPaymentStatus || supp?.autoPaymentStatus.toLowerCase() === supplierData.apStatuses[2].code.toLowerCase(),
          currency: supp?.defaultCurrency || details.currency,
          lines,
        };

        // Populate the sender company and VAT if it is available via supplier
        if (supp?.creditorName) {
          newDetails.senderCompany = supp?.creditorName;
        }
        if (supp?.vatNumber) {
          newDetails.senderVatNumber = supp.vatNumber;
          const firstTwo = supp.vatNumber?.substring(0, 2);
          if (countriesList.some((c) => c.isoCode === firstTwo.toUpperCase())) {
            newDetails.senderCountryCode = firstTwo.toUpperCase();
            newDetails.senderVatNumber = supp.vatNumber?.substring(2);
          } else if (supp?.country) {
            newDetails.senderVatNumber = supp.vatNumber;
            newDetails.senderCountryCode = supp.country;
          } else {
            newDetails.senderVatNumber = supp.vatNumber;
            newDetails.senderCountryCode = 'ES';
          }
        } else if (details.receiverVatNumber === details.senderVatNumber) {
          newDetails.senderVatNumber = '';
          newDetails.senderCountryCode = '';
        }

        if (calculateDeliveryDate && !!parseInt(supp?.dueDays, 10) && !dateFieldRule) {
          newDetails.deliveryDate = addDays(
            details.documentDate || new Date(),
            parseInt(supp?.dueDays, 10),
            dueDaysAsDayOfWeek
          );
        }

        if (custom2FieldInDocumentForm) {
          newDetails.clientCode = supp?.custom2;
        }

        // @TODO: Update lineUnitMeasurement field on supplier change

        setIsSubmitClicked(false);
        setDetails(newDetails);
        return;
      }

      handleChange(values.creditorCode, prop);
      return;
    }

    if (prop === 'currency' && values?.code) {
      handleChange(values.code, prop);
      return;
    }

    if (prop === 'terms' && values?.code) {
      handleChange(values.code, prop);
      return;
    }

    if (prop === 'owner' && values?.email) {
      handleChange(values.email, prop);
      return;
    }

    if (prop === 'description') {
      // let lines = details.lines; // eslint-disable-line
      // if (details?.lines?.length > 0) {
      //   const line = details.lines[0];
      //   line.lineDescription = event.target.value;
      //   lines.shift();

      //   lines = [line, ...lines];
      // }

      setIsSubmitClicked(false);
      setDetails({
        ...details,
        description: formattedVal.value,
        // lines,
      });
      return;
    }

    if ((prop === 'receiverCountryCode' || prop === 'senderCountryCode' || prop === 'countryIssued') && values?.isoCode) {
      handleChange(values.isoCode, prop);
      return;
    }

    if (event.target.value === undefined) {
      handleChange('', prop);
      handleChangeXML(name, { value: '' });
    } else {
      handleChange(formattedVal.value, prop);
      handleChangeXML(name, { value: formattedVal.value });
    }
  };

  const handleCloseSupplierModal = () => {
    setAddSupplierOpen(false);
    restartInvoiceAnnotateTime();
  };

  const onSupplierCreated = (creditorCode) => {
    handleCloseSupplierModal();
    findAllSuppliers();
    handleChangeMain({ target: { value: creditorCode } }, 'supplier', 'supplier', { creditorCode });
    handleChangeSupplier(creditorCode);
    setNoSupplier('');
  };

  const editSupplier = (id) => {
    if (details.supplier) {
      handleChangeMain({ target: { value: '' } }, 'supplier', 'supplier', { creditorCode: '' });
      setSelectedSupplierId(id);
      setEditSupplierOpen(true);
      pauseInvoiceAnnotateTime();
    }
  };

  const handleEditSupplierClose = () => {
    handleChangeMain({ target: { value: selectedSupplierId } }, 'supplier', 'supplier', { creditorCode: selectedSupplierId });
    setSelectedSupplierId('-1');
    setEditSupplierOpen(false);
    restartInvoiceAnnotateTime();
  };

  const handleChangeText = (prop, val, name) => {
    setIsSubmitClicked(false);
    let formattedVal = val;
    if (nonNumericFieldsHeader.includes(prop) && leadingWhiteSpace.test(val)) {
      formattedVal = val.trimStart();
    }
    if (formattedVal) {
      if (prop === 'documentDate') {
        const newDetails = details;
        const d = val;

        if (!dateFieldRule && d && d.indexOf('-') === 2) {
          const parts = d.split('-');

          if (parts.length >= 3) {
            newDetails.period = `${parts[2].substring(0, 4)}/${parts[1]}`;
          }
        }

        newDetails.documentDate = d;
        setDetails(newDetails);
      } else if (prop === 'amount' && details?.vatAmount) {
        const baseAmount = calculateBaseAmount(formattedVal, details.vatAmount, decimalSeparator);
        const newDetails = {
          ...details,
          amount: formattedVal,
          baseAmount,
        };
        if (details?.discountAmount) {
          newDetails.discountRate = calculateDiscountRate(details.discountAmount, baseAmount, decimalSeparator);
        }
        setDetails(newDetails);
      } else if (prop === 'vatAmount' && details?.amount) {
        const baseAmount = calculateBaseAmount(details.amount, formattedVal, decimalSeparator);
        const newDetails = {
          ...details,
          vatAmount: formattedVal,
          baseAmount
        };
        if (details?.discountAmount) {
          newDetails.discountRate = calculateDiscountRate(details.discountAmount, baseAmount, decimalSeparator);
        }
        setDetails(newDetails);
      } else if (prop === 'discountAmount' && details?.amount && details?.vatAmount) {
        const baseAmount = calculateBaseAmount(details.amount, details.vatAmount, decimalSeparator);
        const newDetails = {
          ...details,
          discountAmount: formattedVal,
          discountRate: calculateDiscountRate(formattedVal, baseAmount, decimalSeparator),
        };
        setDetails(newDetails);
      } else if (prop === 'documentNumber') {
        const archiveNumber = `${invoice.fileName} ${formattedVal}`;
        const newDetails = {
          ...details,
          documentNumber: formattedVal,
          archiveNumber,
        };
        setDetails(newDetails);
      } else if (prop === 'terms') {
        setDetails({ ...details, [prop]: formattedVal });
        detail[name] = formattedVal;
        setDetail({
          ...detail,
          [name]: formattedVal
        });
        setDetailsXML({
          ...detailsXML,
          [name]: detailsXMLi[name]
            ? [...detailsXMLi[name], formattedVal]
            : [formattedVal]
        });

        return;
      } else {
        setDetails({ ...details, [prop]: formattedVal });
      }

      detail[name] = formattedVal;
      setDetail({
        ...detail,
        [name]: formattedVal
      });
      setDetailsXML({
        ...detailsXML,
        [name]: detailsXMLi[name]
          ? [...detailsXMLi[name], { value: formattedVal }]
          : [{ value: formattedVal }]
      });

      return;
    }

    setDetails({ ...details, [prop]: val });
    detail[name] = val;
    setDetail({
      ...detail,
      [name]: val
    });
    setDetailsXML({
      ...detailsXML,
      [name]: detailsXMLi[name] ? detailsXMLi[name] : ['']
    });
  };

  const handleCustomReceiver = (e) => {
    setIsSubmitClicked(false);
    const receiver = e.target.value;
    let { vatNumber } = receiver;

    let countryCode = '';
    if (receiver.vatNumber && receiver.vatNumber.length > 2) {
      const firstTwo = receiver.vatNumber.substring(0, 2);
      if (countriesList.some((c) => c.isoCode === firstTwo.toUpperCase())) {
        countryCode = firstTwo.toUpperCase();
        vatNumber = receiver.vatNumber.substring(2);
      }
    }

    setDetails({
      ...details,
      receiverVatNumber: vatNumber,
      receiverCompany: receiver.company,
      receiverAddress: receiver.address,
      receiverCity: receiver.city,
      receiverStreet: receiver.street,
      receiverPostalCode: receiver.postalCode,
      receiverCountryCode: countryCode,
    });
    setDetail({
      ...detail,
      receiver_vat_number: vatNumber,
      receiver_company: receiver.company,
      receiver_address: receiver.address,
      receiver_city: receiver.city,
      receiver_street: receiver.street,
      receiver_postal_code: receiver.postalCode,
      receiver_country_code: countryCode,
    });
  };

  const handleAddVatLine = () => {
    if (vatLines.length < 20) {
      setVatLines((prevState) => [...vatLines, {
        line: prevState.length + 1, baseAmount: '0', vatRate: '0', vatAmount: '0', error: false, invalid: false
      }]);
      setIsSubmitClicked(false);
    } else {
      enqueueSnackbar(t('INVOICE_EDIT_FORM_ADD_VAT_LINE_LIMIT_TOOLTIP'), {
        variant: 'error',
      });
    }
  };

  const handleAddESLine = () => {
    if (esLines.length < 20) {
      setESLines((prevState) => [...esLines, {
        line: prevState.length + 1, baseAmount: '0', esRate: '0', esAmount: '0', error: false, invalid: false
      }]);
      setIsSubmitClicked(false);
    } else {
      enqueueSnackbar(t('INVOICE_EDIT_FORM_ADD_SURCHARGE_LINE_LIMIT_TOOLTIP'), {
        variant: 'error',
      });
    }
  };

  const handleChangeVatLine = (e, i, prop) => {
    setIsSubmitClicked(false);
    const val = e.target.value;
    const selectedVatLine = vatLines[i];
    const before = vatLines.slice(0, i);
    let after = [];
    if (vatLines.length > 1 && i < vatLines.length) {
      after = vatLines.slice(i + 1);
    }
    selectedVatLine[prop] = val;
    selectedVatLine.error = false;
    selectedVatLine.invalid = false;
    if (prop === 'baseAmount' && selectedVatLine.vatRate !== '' && val !== '') {
      selectedVatLine.vatAmount = calculateVatAmount(val, selectedVatLine.vatRate, decimalSeparator);
    }
    if (prop === 'vatRate' && selectedVatLine.baseAmount !== '' && val !== '') {
      selectedVatLine.vatAmount = calculateVatAmount(selectedVatLine.baseAmount, val, decimalSeparator);
    }
    if (prop === 'vatAmount') {
      const calculatedVatAmount = calculateVatAmount(selectedVatLine.baseAmount, selectedVatLine.vatRate, decimalSeparator);
      if (selectedVatLine.baseAmount === '' || selectedVatLine.vatRate === '' || parseFloat(val, 10) !== parseFloat(calculatedVatAmount, 10)) {
        selectedVatLine.error = true;
      }
    }
    if (['baseAmount', 'vatRate', 'vatAmount'].some((k) => !amountRegex.test(selectedVatLine[k]))) {
      selectedVatLine.invalid = true;
    }
    setVatLines([...before, selectedVatLine, ...after]);
    if (calculateVat) {
      const newVatAmount = [...before, selectedVatLine, ...after].reduce(
        (accumulator, currentValue) => accumulator + parseFloat((currentValue.vatAmount || '0').replace(decimalSeparator, '.')), 0
      );
      handleChange(newVatAmount.toFixed(2).replace('.', decimalSeparator), 'vatAmount');
    }
  };

  const handleChangeESLine = (e, i, prop) => {
    setIsSubmitClicked(false);
    const val = e.target.value;
    const selectedESLine = esLines[i];
    const before = esLines.slice(0, i);
    let after = [];
    if (esLines.length > 1 && i < esLines.length) {
      after = esLines.slice(i + 1);
    }
    selectedESLine[prop] = val;
    selectedESLine.error = false;
    selectedESLine.invalid = false;
    if (prop === 'baseAmount' && selectedESLine.esRate !== '' && val !== '') {
      selectedESLine.esAmount = calculateESAmount(val, selectedESLine.esRate, decimalSeparator);
    }
    if (prop === 'esRate' && selectedESLine.baseAmount !== '' && val !== '') {
      selectedESLine.esAmount = calculateESAmount(selectedESLine.baseAmount, val, decimalSeparator);
    }
    if (prop === 'esAmount') {
      const calculatedSurchargeAmount = calculateESAmount(selectedESLine.baseAmount, selectedESLine.esRate, decimalSeparator);
      if (selectedESLine.baseAmount === '' || selectedESLine.esRate === '' || parseFloat(val, 10) !== parseFloat(calculatedSurchargeAmount, 10)) {
        selectedESLine.error = true;
      }
    }
    if (['baseAmount', 'esRate', 'esAmount'].some((k) => !amountRegex.test(selectedESLine[k]))) {
      selectedESLine.invalid = true;
    }
    setESLines([...before, selectedESLine, ...after]);
  };

  const handleDeleteVatLine = (i) => {
    const filteredVatLines = [];
    for (let j = 0; j < i; j++) {
      filteredVatLines.push(vatLines[j]);
    }
    if ((i + 1) < vatLines.length) {
      for (let j = i + 1; j < vatLines.length; j++) {
        const newVl = {
          ...vatLines[j],
          line: (vatLines[j].line - 1)
        };
        filteredVatLines.push(newVl);
      }
    }

    if (calculateVAT) {
      const newVatAmount = filteredVatLines.reduce(
        (accumulator, currentValue) => accumulator + parseFloat((currentValue.vatAmount || '0').replace(decimalSeparator, '.')), 0
      );
      handleChange(newVatAmount.toFixed(2).replace('.', decimalSeparator), 'vatAmount');
    }

    setVatLines(filteredVatLines);
    setIsSubmitClicked(false);
  };

  const handleDeleteESLine = (i) => {
    const filteredESLines = [];
    for (let j = 0; j < i; j++) {
      filteredESLines.push(esLines[j]);
    }
    if ((i + 1) < esLines.length) {
      for (let j = i + 1; j < esLines.length; j++) {
        const newVl = {
          ...esLines[j],
          line: (esLines[j].line - 1)
        };
        filteredESLines.push(newVl);
      }
    }
    setESLines(filteredESLines);
    setIsSubmitClicked(false);
  };

  const handleChangeAmount = (newAmount) => {
    details.amount = newAmount;

    if (detailsXML && detailsXML.amount) {
      let isNotAvailable = true;
      for (let j = 0; j < detailsXML.amount.length; j++) {
        if (detailsXML.amount[j].value === newAmount) {
          isNotAvailable = false;
        }
      }

      if (isNotAvailable) {
        handleChangeText('amount', newAmount, 'amount');
      }
    }

    handleChangeMain({ target: { value: newAmount } }, 'amount', 'amount', { value: newAmount });
  };

  const adjustLinesOrder = (oldLine, newLine) => {
    const existingLines = details.lines;
    if (oldLine > newLine) {
      const newLines = [];
      for (let i = 0; i < existingLines.length; i++) {
        if (existingLines[i].line < oldLine && existingLines[i].line >= newLine) {
          existingLines[i].line += 1;
          newLines.push(existingLines[i]);
        } else {
          if (existingLines[i].line === oldLine) {
            existingLines[i].line = newLine;
            newLines.push(existingLines[i]);
          }
          newLines.push(existingLines[i]);
        }
      }
    }

    if (oldLine < newLine) {
      const newLines = [];
      for (let i = 0; i < existingLines.length; i++) {
        if (existingLines[i].line > oldLine && existingLines[i].line <= newLine) {
          existingLines[i].line -= 1;
          newLines.push(existingLines[i]);
        } else {
          if (existingLines[i].line === oldLine) {
            existingLines[i].line = newLine;
            newLines.push(existingLines[i]);
          }
          newLines.push(existingLines[i]);
        }
      }
    }

    const newDetails = {
      ...details,
      lines: existingLines,
    };

    if (user.isDocunecta && makroSuppliers.includes(details.senderVatNumber)) {
      newDetails.subtotal = calculateSubtotal(existingLines, decimalSeparator);
    }

    setDetails(newDetails);
    setIsSubmitClicked(false);
  };

  const onUpdateLine = async (line) => {
    let oldLine = null;
    let newLine = null;
    const newLines = details.lines;
    for (let i = 0; i < newLines.length; i++) {
      if ((newLines[i].id && line.id && newLines[i].id === line.id) || (newLines[i].id === undefined && newLines[i].line === line.line)) {
        if (newLines[i].lineExtraCost === undefined) {
          newLines[i].lineExtraCost = false;
        }

        if (newLines[i].lineAmount === line.lineAmount && newLines[i].lineExtraCost !== line.lineExtraCost && line.lineExtraCost) {
          handleChangeAmount(calculateLineAmountExtraCost1(details.amount, line.lineAmount, decimalSeparator));
        }

        if (newLines[i].lineAmount === line.lineAmount && newLines[i].lineExtraCost !== line.lineExtraCost && !line.lineExtraCost) {
          handleChangeAmount(calculateLineAmountExtraCost2(details.amount, line.lineAmount, decimalSeparator));
        }

        if (newLines[i].lineAmount !== line.lineAmount && line.lineExtraCost && newLines[i].lineExtraCost === line.lineExtraCost) {
          handleChangeAmount(calculateLineAmountExtraCost3(details.amount, line.lineAmount, newLines[i].lineAmount, decimalSeparator));
        }

        if (newLines[i].lineAmount !== line.lineAmount && !line.lineExtraCost && newLines[i].lineExtraCost !== line.lineExtraCost) {
          handleChangeAmount(calculateLineAmountExtraCost4(details.amount, newLines[i].lineAmount, decimalSeparator));
        }

        let lineTax = line.lineTax || '';
        if (isTwinfield && line.lineVatGroup && line.lineVatGroup !== 'No VAT') {
          lineTax = await calculateVAT(line.lineVatGroup, line.lineAmount);
        }

        newLines[i].lineDescription = line.lineDescription || '';
        newLines[i].lineGLAccount = line.lineGLAccount || '';
        newLines[i].lineVatGroup = line.lineVatGroup || '';
        newLines[i].lineCostCenter = line.lineCostCenter || '';
        newLines[i].lineProject = line.lineProject || '';
        newLines[i].lineUnitPrice = line.lineUnitPrice || '';
        newLines[i].lineQuantity = line.lineQuantity || '';
        newLines[i].lineDiscount = line.lineDiscount || '';
        newLines[i].linePrice = line.linePrice || '';
        newLines[i].lineVatrate = line.lineVatrate || '';
        newLines[i].lineArticle = line.lineArticle || '';
        newLines[i].lineArticleClient = line.lineArticleClient || '';
        newLines[i].lineVatcode = line.lineVatcode || '';
        newLines[i].lineDiscountedPrice = line.lineDiscountedPrice || '';
        newLines[i].lineDiscountAmountOnUnitPrice = line.lineDiscountAmountOnUnitPrice || '';
        newLines[i].lineDiscountAmountOnTotalPrice = line.lineDiscountAmountOnTotalPrice || '';
        newLines[i].lineTax = lineTax.toString();
        newLines[i].lineDeposit = line.lineDeposit || '';
        newLines[i].lineAmount = line.lineAmount || '';
        newLines[i].lineExtraCost = line.lineExtraCost;
        newLines[i].lineReferenceNumber = line.lineReferenceNumber || '';
        newLines[i].lineProductCount = line.lineProductCount || '';
        newLines[i].lineUnitMeasurement = line.lineUnitMeasurement || '';
        newLines[i].lineBaseAmount = line.lineBaseAmount || '';
        newLines[i].lineRetentionAmount = line.lineRetentionAmount || '';
        newLines[i].lineRetentionPercentage = line.lineRetentionPercentage || '';
        newLines[i].lineCategory = line.lineCategory || '';
        newLines[i].lineProductCode = line.lineProductCode || '';
        newLines[i].lineTotalPV = line.lineTotalPV || '';
        newLines[i].lineMeasureType = line.lineMeasureType || '';
        newLines[i].lineDelRecCompName = line.lineDelRecCompName || '';
        newLines[i].lineDelRecContName = line.lineDelRecContName || '';
        newLines[i].lineDelRecAddress = line.lineDelRecAddress || '';
        newLines[i].lineDelRecPhoneNumber = line.lineDelRecPhoneNumber || '';
        newLines[i].lineDelRecEmail = line.lineDelRecEmail || '';
        newLines[i].lineDeliveryDate = line.lineDeliveryDate || '';
        newLines[i].lineTeilnr = line.lineTeilnr || '';
        newLines[i].lineTeilnrDelivery = line.lineTeilnrDelivery || '';
        newLines[i].linePoReferenceNumber = line.linePoReferenceNumber || '';

        if (newLines[i].line !== line.line) {
          oldLine = parseInt(newLines[i].line, 10);
          newLine = parseInt(line.line, 10);
          adjustLinesOrder(oldLine, newLine);
        }

        handleChange(newLines, 'lines');
        setIsSubmitClicked(false);
      }
    }

    setEditLineOpen(false);
    setEditLine(null);
  };

  const onAddLineConfirm = async (line) => {
    let oldLine = null;
    let newLineNumber = null;
    if (line.lineExtraCost) {
      handleChangeAmount(calculateLineAmountExtraCost1(details.amount, line.lineAmount, decimalSeparator));
    }

    let lineTax = line.lineTax || '';
    if (isTwinfield && line.lineVatGroup && line.lineVatGroup !== 'No VAT') {
      lineTax = await calculateVAT(line.lineVatGroup, line.lineAmount);
    }

    const newLine = {
      line: 1,
      assistantID: details.assistantID,
      lineDescription: line.lineDescription || '',
      lineGLAccount: line.lineGLAccount || '',
      lineVatGroup: line.lineVatGroup || '',
      lineCostCenter: line.lineCostCenter || '',
      lineProject: line.lineProject || '',
      lineUnitPrice: line.lineUnitPrice || '',
      lineQuantity: line.lineQuantity || '',
      lineDiscount: line.lineDiscount || '',
      linePrice: line.linePrice || '',
      lineVatrate: line.lineVatrate || '',
      lineArticle: line.lineArticle || '',
      lineArticleClient: line.lineArticleClient || '',
      lineVatcode: line.lineVatcode || '',
      lineDiscountedPrice: line.lineDiscountedPrice || '',
      lineDiscountAmountOnUnitPrice: line.lineDiscountAmountOnUnitPrice || '',
      lineDiscountAmountOnTotalPrice: line.lineDiscountAmountOnTotalPrice || '',
      lineTax: lineTax.toString(),
      lineDeposit: line.lineDeposit || '',
      lineAmount: line.lineAmount || '',
      lineExtraCost: line.lineExtraCost,
      lineReferenceNumber: line.lineReferenceNumber || '',
      lineProductCount: line.lineProductCount || '',
      lineUnitMeasurement: line.lineUnitMeasurement || '',
      lineBaseAmount: line.lineBaseAmount || '',
      lineRetentionAmount: line.lineRetentionAmount || '',
      lineRetentionPercentage: line.lineRetentionPercentage || '',
      lineCategory: line.lineCategory || '',
      lineProductCode: line.lineProductCode || '',
      lineTotalPV: line.lineTotalPV || '',
      lineMeasureType: line.lineMeasureType || '',
      lineDelRecCompName: line.lineDelRecCompName || '',
      lineDelRecContName: line.lineDelRecContName || '',
      lineDelRecAddress: line.lineDelRecAddress || '',
      lineDelRecPhoneNumber: line.lineDelRecPhoneNumber || '',
      lineDelRecEmail: line.lineDelRecEmail || '',
      lineDeliveryDate: line.lineDeliveryDate || '',
      lineTeilnr: line.lineTeilnr || '',
      lineTeilnrDelivery: line.lineTeilnrDelivery || '',
      linePoReferenceNumber: line.linePoReferenceNumber || '',
    };

    if (details.lines && details.lines.length > 0) {
      const existingLines = [];
      for (let i = 0; i < details.lines.length; i++) {
        details.lines[i].line = i + 1;
        existingLines.push(details.lines[i]);
      }

      const linesLength = details.lines ? details.lines.length : 0;
      newLine.line = linesLength + 1;
      existingLines.push(newLine);
      details.lines = existingLines;

      oldLine = parseInt(newLine.line, 10);
      newLineNumber = parseInt(line.line, 10);
    } else {
      details.lines = [newLine];
    }

    adjustLinesOrder(oldLine, newLineNumber);

    setAddLineOpen(false);
  };

  const onDeleteLineConfirm = (line) => {
    const lines = [];
    let count = 1;
    for (let i = 0; i < details.lines.length; i++) {
      if ((line.id === undefined && line.line === details.lines[i].line) || (line.id && details.lines[i].id === line.id)) {
        if (line.lineExtraCost) {
          handleChangeAmount(calculateLineAmountExtraCost2(details.amount, line.lineAmount, decimalSeparator));
        }
      } else {
        // Update the line property??
        details.lines[i].line = count;
        count++;

        lines.push(details.lines[i]);
      }
    }

    details.lines = lines;
    if (user.isDocunecta && makroSuppliers.includes(details.senderVatNumber)) {
      details.subtotal = calculateSubtotal(lines, decimalSeparator);
    }

    handleChange(lines, 'lines');

    setDeleteLineOpen(false);
    setDeleteLine(null);
    setIsSubmitClicked(false);
  };

  const onDeleteLineClose = () => {
    setDeleteLineOpen(false);
  };

  useEffect(() => {
    const sumOfVatLines = calculateSumOfVATLines(vatLines, decimalSeparator);
    setTotalVatAmountError(vatLines.length > 0 && (details.vatAmount === '' || !(parseFloat(details.vatAmount) === parseFloat(sumOfVatLines))));
  }, [vatLines, details.vatAmount]);

  useEffect(() => {
    const sumOfESLines = calculateSumOfESLines(esLines, decimalSeparator);
    setTotalSurchargeAmountError(esLines.length
      && (details.equivalenceSurchargeAmount === '' || !(parseFloat(details.equivalenceSurchargeAmount) === parseFloat(sumOfESLines))));
  }, [esLines, details.equivalenceSurchargeAmount]);

  useEffect(() => {
    const vatLineFieldIds = Array(20).fill(0).map((_, i) => [
      `vatLine_${i}_baseAmount`,
      `vatLine_${i}_vatRate`,
      `vatLine_${i}_vatAmount`,
      `vatLine_${i}_taxName`
    ]).flat();
    const esLineFieldIds = Array(20).fill(0).map((_, i) => [`esLine_${i}_baseAmount`, `esLine_${i}_esRate`, `esLine_${i}_esAmount`]).flat();
    const invoiceLineFieldIds = details.lines.map((l) => lineFieldIds.map((f) => `invoiceLine_${l.line}_${f}`)).flat();

    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        setTextFieldSelectedKey('');
        setSelectedDataType('');
      }
    };

    const handleClick = (e) => {
      if (!['canvasId2',
        'nav_previous',
        'nav_next',
        ...headerFieldIds,
        ...vatLineFieldIds,
        ...esLineFieldIds,
        ...invoiceLineFieldIds,
        ...lineColumnsReprocess,
      ].some((elemId) => elemId === e.target.id)) {
        setTextFieldSelectedKey('');
        setSelectedDataType('');
        if (textFieldSelectedValueRef?.current === '') {
          setTextFieldSelectedValue(' ');
        } else {
          setTextFieldSelectedValue('');
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('click', handleClick);
    };
  }, [details.lines]);

  useEffect(() => {
    setRenderedFields(headerFields.filter((f) => f.isAvailable && f.isActive));
  }, [headerFields]);

  useEffect(() => {
    if (textFieldSelectedKey && xmlSelectedValue.trim() && xmlSelectedValue.trim() !== details[textFieldSelectedKey]) {
      setClickOnCanvasCount(0);
      if (selectedDataType === 'float' && (!amountRegex.test(xmlSelectedValue.trim()) || decimalSeparator !== '.')) {
        const asstKey = headerFields.find((f) => f.key === textFieldSelectedKey)?.assistantKey || '';
        handleChangeMain(
          { target: { value: formatAmountFields(xmlSelectedValue.trim(), decimalSeparator) } },
          textFieldSelectedKey,
          asstKey,
          { value: formatAmountFields(xmlSelectedValue.trim(), decimalSeparator) }
        );
      } else if (selectedDataType === 'date') {
        const fieldRule = user.fieldRules.find((fr) => fr.field === textFieldSelectedKey);
        const d = fieldRule?.format
          ? formatDateCustomFormat(xmlSelectedValue, fieldRule?.format)
          : formatDateDDMMYYYY(xmlSelectedValue);
        if (textFieldSelectedKey === 'documentDate' && !fieldRule && d && d.indexOf('-') === 2) {
          const parts = d.split('-');

          if (parts.length >= 3) {
            handleChangeMultiple([`${parts[2].substring(0, 4)}/${parts[1]}`, d], ['period', 'documentDate']);
          }
        }
        handleChange(d, textFieldSelectedKey);
      } else if (textFieldSelectedKey === 'supplier') {
        handleChangeMain({ target: { value: xmlSelectedValue.trim() } }, 'supplier', 'supplier', { creditorCode: xmlSelectedValue.trim() });
        handleChangeSupplier(xmlSelectedValue.trim());
      } else if (textFieldSelectedKey === 'description') {
        handleChange(xmlSelectedValue.trim(), textFieldSelectedKey);
      } else if (textFieldSelectedKey.startsWith('vatLine_')) {
        const [, i, prop] = textFieldSelectedKey.split('_');
        let val = xmlSelectedValue.trim();
        if (!textFieldSelectedKey.includes('taxName')) {
          val = formatAmountFields(val, decimalSeparator);
        }
        handleChangeVatLine({ target: { value: val } }, parseInt(i, 10), prop);
      } else if (textFieldSelectedKey.startsWith('esLine_')) {
        const [, i, prop] = textFieldSelectedKey.split('_');
        let val = xmlSelectedValue.trim();
        val = formatAmountFields(val, decimalSeparator);
        handleChangeESLine({ target: { value: val } }, parseInt(i, 10), prop);
      } else if (textFieldSelectedKey.startsWith('invoiceLine_')) {
        const [, i, prop] = textFieldSelectedKey.split('_');
        let val = xmlSelectedValue.trim();
        if (lineAmountRelatedFields.includes(prop)) {
          val = formatAmountFields(val, decimalSeparator);
        }
        if (lineDateRelatedFields.includes(prop)) {
          const fieldRule = user.fieldRules.find((fr) => fr.field === prop);
          const d = fieldRule?.format
            ? formatDateCustomFormat(val, fieldRule?.format)
            : formatDateDDMMYYYY(val);
          val = d;
        }
        let product;
        if (supplier) {
          const supplierProducts = supplier?.inventories || [];
          if (prop === 'lineArticle' && supplierProducts.some((p) => p.article === val)) {
            product = supplierProducts.find((p) => p.article === val);
          }
          if (prop === 'lineArticleClient' && supplierProducts.some((p) => p.articleClient === val)) {
            product = supplierProducts.find((p) => p.articleClient === val);
          }
        }
        let updatedLine;
        details.lines.forEach((l) => {
          if (l.line === parseInt(i, 10)) {
            updatedLine = {
              ...l,
              [prop]: val
            };
            if ((prop === 'lineArticle' || prop === 'lineArticleClient') && product) {
              updatedLine.lineArticle = product.article;
              updatedLine.lineArticleClient = product.articleClient;
              updatedLine.lineUnitMeasurement = product.unitMeasurement;
            }
          }
        });
        if (updatedLine.lineExtraCost === undefined) {
          updatedLine.lineExtraCost = false;
        }
        onUpdateLine(updatedLine);
      } else {
        const asstKey = headerFields.find((f) => f.key === textFieldSelectedKey)?.assistantKey || '';
        handleChangeMain({ target: { value: xmlSelectedValue.trim() } }, textFieldSelectedKey, asstKey, { value: xmlSelectedValue.trim() });
      }
    } else if (!textFieldSelectedKey.trim() && xmlSelectedValue.trim()) {
      let selectedKey = '';
      Object.keys(details).forEach((k) => {
        if (details[k] === xmlSelectedValue.trim() && renderedFields.some((f) => f.key === k)) {
          selectedKey = k;
        }
      });
      setTextFieldSelectedKey(selectedKey);
      setSelectedDataType(headerFields.find((f) => f.key === selectedKey)?.dataType || 'text');
      if (selectedKey === '') {
        if (clickOnCanvasCount > 0) {
          enqueueSnackbar(t('ANNOTATE_NO_FIELD_SELECTED'), {
            variant: 'warning',
          });
        }
        setClickOnCanvasCount((prevState) => prevState + 1);
      } else {
        setClickOnCanvasCount(0);
      }
    } else if (!xmlSelectedValue.trim()) {
      setClickOnCanvasCount(0);
    }
  }, [xmlSelectedValue]);

  useEffect(() => {
    if (textFieldSelectedKey === '') {
      updateAnnotateFieldsTime();
      setAnnotateSelectedField(null);
      setAnnotateFieldStart(null);
    }
    if (textFieldSelectedKey !== ''
        && !textFieldSelectedKey.startsWith('vatLine_')
        && !textFieldSelectedKey.startsWith('esLine_')
        && !textFieldSelectedKey.startsWith('invoiceLine_')
        && !isInViewPort[textFieldSelectedKey]
        && fieldRef[textFieldSelectedKey]
    ) {
      fieldRef[textFieldSelectedKey].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    if (textFieldSelectedKey.startsWith('vatLine_') && !vatDetailsInViewPort && vatDetailsRef.current) {
      vatDetailsRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    if (textFieldSelectedKey.startsWith('esLine_') && !surchargeDetailsInViewPort) {
      surchargeDetailsRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [textFieldSelectedKey]);

  const scrollXMLViewBy = (amount) => {
    const scrollMaxY = document.getElementById('xmlContainer').scrollHeight - document.getElementById('xmlContainer').clientHeight;
    document.getElementById('xmlContainer').scrollBy({ top: amount, behavior: 'smooth' });
    if ((isMobile || !minHeight) && (amount - scrollMaxY) > 100) {
      document.getElementById('formContainer').scrollBy({ top: amount - scrollMaxY, behavior: 'smooth' });
    }
  };

  const nextPage = () => {
    if (numPages > pageNumber + 1) {
      setPageNumber(pageNumber + 1);
      setGotoPage(gotoPage + 1);
      const elem = document.getElementById(textFieldSelectedKey);
      if (elem) {
        elem.focus();
      }
    }
  };

  const prevPage = () => {
    if (pageNumber + 1 > 1) {
      setPageNumber(pageNumber - 1);
      setGotoPage(gotoPage - 1);
      const elem = document.getElementById(textFieldSelectedKey);
      if (elem) {
        elem.focus();
      }
    }
  };

  // Move between pages with arrow keys and
  // Zoom in, out with minus, equal(plus) keys
  document.onkeydown = (e) => {
    if (e.code === 'ArrowRight') {
      nextPage();
    }
    if (e.code === 'ArrowLeft') {
      prevPage();
    }
    if (e.code === 'Tab') {
      const focused = renderedFields.some((f) => f.key === document.activeElement.id);
      if (!focused
        && !document.activeElement.id.includes('vatLine_')
        && !document.activeElement.id.includes('esLine_')
        && !document.activeElement.id.includes('invoiceLine_')
      ) {
        setTextFieldSelectedKey('');
        setSelectedDataType('text');
      }
    }
  };

  const getSupplierLabel = (code) => {
    for (let i = 0; i < suppliers?.length; i++) {
      if (code === suppliers[i].creditorCode) {
        if (isSupplierLabelWithVAT) {
          if (suppliers[i].vatNumber) {
            setSupplierLabel(`${suppliers[i].vatNumber} | ${suppliers[i].creditorName}`);
          } else {
            setSupplierLabel(suppliers[i].creditorName);
          }
        } else {
          setSupplierLabel(`${suppliers[i].creditorCode} | ${suppliers[i].creditorName}`);
        }
        return;
      }
    }

    setSupplierLabel(code);
  };

  useEffect(() => {
    getSupplierLabel(details.supplier);
  }, [details.supplier, suppliers]);

  const getCurrencyLabel = (code) => {
    for (let i = 0; i < currencies?.length; i++) {
      if (code === currencies[i].code) {
        return `${currencies[i].code} | ${currencies[i].currency}`;
      }
    }

    return code;
  };

  const getTermsLabel = (code) => {
    for (let i = 0; i < qbTerms?.length; i++) {
      if (code === qbTerms[i].code) {
        return `${qbTerms[i].code} | ${qbTerms[i].name}`;
      }
    }

    return code;
  };

  const onAddLine = () => {
    setAddLineOpen(true);
  };

  const onEditLine = (l) => {
    setEditLine(l);
    setEditLineOpen(true);
  };

  const onDeleteLine = (l) => {
    setDeleteLine(l);
    setDeleteLineOpen(true);
  };

  const onClickGotoPage = (event) => {
    if (event && event.key === 'Enter') {
      event.preventDefault();
      event.target.blur();

      if (gotoPage <= numPages && gotoPage >= 1) {
        setPageNumber(parseInt(gotoPage - 1, 10));
        return;
      }
      setPageNumber(0);
      setGotoPage(1);
    }
  };

  const handleGotoPage = (toPage) => {
    if (toPage <= numPages && toPage >= 1) {
      setGotoPage(parseInt(toPage, 10));
      setPageNumber(parseInt(toPage - 1, 10));
      return;
    }
    setPageNumber(0);
    setGotoPage(1);
  };

  const getTFLabel = (list, item) => {
    for (let i = 0; i < list.length; i++) {
      if (item === list[i].code) {
        return `${list[i].code}${list[i].name ? ` | ${list[i].name}` : ''}`;
      }
    }

    return item;
  };

  const handleDataFormatToEmailClick = (dataFormat) => {
    setSelectedDataFormatToEmail(dataFormat.key);
    setDataFormatOptionsToEmailOpen(false);
  };

  const handleDataformatOptionsToEmailToggle = () => {
    setDataFormatOptionsToEmailOpen((prevOpen) => !prevOpen);
  };

  const handleDataformatOptionsToEmailClose = (event) => {
    if (dataFormatOptionsToEmailRef.current && dataFormatOptionsToEmailRef.current.contains(event.target)) {
      return;
    }
    setDataFormatOptionsToEmailOpen(false);
  };

  const isFieldAvailable = (field, isActive) => isInvoiceFieldAvailable(user, field, isActive);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (!authService.validateToken()) {
        setLoading(false);
        enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
          variant: 'error',
        });
        setTimeout(() => {
          authService.logout(LOGIN_PANEL_URL);
        }, 2000);
        throw new Error(t('PROCYS_LOGIN_SESSION_EXPIRED'));
      }

      const timeSpent = invoiceAnnotateTime + (new Date().getTime() - invoiceAnnotateStart);
      setInvoiceAnnotateTime(timeSpent);

      const resp = await Axios.put(
        `${API.updateInvoice}`,
        {
          ...removeIsNotTrainedFieldsFromDetails(headerFields, details),
          sendLines,
          vat: vatLines.filter((vl) => (vl.baseAmount !== '0' || vl.vatRate !== '0' || vl.vatAmount !== '0'))
            .map(({ _error, _invalid, ...vl }) => (vl)),
          esLines: esLines.filter((vl) => (vl.baseAmount !== '0' || vl.esRate !== '0' || vl.esAmount !== '0')).map(({ _error, _invalid, ...vl }) => (vl)),
          times: {
            ...annotateFieldsTime,
            invoiceTime: timeSpent
          }
        },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );

      if (resp.data.success) {
        enqueueSnackbar(t('INVOICE_EDIT_FORM_SAVE_SUCCESS'), {
          variant: 'success',
        });
        setStatusChangedRef(true);
        setInvoiceAnnotateTime(0);
        setInvoiceAnnotateStart(new Date().getTime());
        setAnnotateFieldsTime(iAnnotateFieldsTime);
        setAnnotateSelectedField(null);
        setLoading(false);
        if (moveToNextRef.current === -1) {
          navigateInvoice(previousInvoice, -1);
        } else if (moveToNextRef.current === 1 || (isAutoNavigationAllowed && nextInvoice !== null)) {
          navigateInvoice(nextInvoice, 1);
        }
        await getInvoice();
        setMoveToNext(null);
      } else {
        setLoading(false);
        enqueueSnackbar(appendContactSupport(window.config.support_email, t('INVOICE_EDIT_FORM_SAVE_FAIL'), t), {
          variant: 'error',
        });
        throw new Error(t('INVOICE_EDIT_FORM_SAVE_FAIL'));
      }

      setIsSubmitClicked(true);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data) {
        enqueueSnackbar(
          getLocalisedErrorString(error.response.data.i18n || appendContactSupport(window.config.support_email, t('INVOICE_EDIT_FORM_SAVE_FAIL'), t), t),
          {
            variant: 'error',
          }
        );
      }
      throw new Error(t('INVOICE_EDIT_FORM_SAVE_FAIL'));
    }
  };

  const handleNavigateInvoice = async (toInvoice, direction) => {
    setMoveToNext(direction);
    if (!isSubmitClicked && (invoice.status === 500 || invoice.status === 501 || invoice.status === 509)) {
      try {
        await handleSubmit();
      } catch (error) {
        // do nothing
      }
    } else {
      setMoveToNext(null);
      navigateInvoice(toInvoice, direction);
    }
  };

  const handleSendToEmailClick = async () => {
    if (!emails || !emails.trim()) {
      enqueueSnackbar(t('PROCYS_VALIDATE_EMAIL_EMPTY'), {
        variant: 'error',
      });
      return;
    }
    const emailsList = [];
    if (emails.indexOf(',') >= 0) {
      const emailsAr = emails.split(',');
      for (let i = 0; i < emailsAr.length; i++) {
        if (emailsAr[i] && emailsAr[i].trim()) {
          const resp = validateEmail(emailsAr[i].trim());
          if (!resp.isValid) {
            enqueueSnackbar(t('PROCYS_VALIDATE_EMAIL_INVALID'), {
              variant: 'error',
            });
            return;
          }

          emailsList.push(emailsAr[i]);
        }
      }
    } else {
      const resp = validateEmail(emails.trim());
      if (!resp.isValid) {
        enqueueSnackbar(t(resp.errorMessage), {
          variant: 'error',
        });
        return;
      }

      emailsList.push(emails.trim());
    }

    try {
      setLoading(true);
      const resp = await Axios.post(
        `${API.exportToEmail}${selectedDataFormatToEmail}`,
        {
          ids: details.id ? [details.id] : [],
          emails: emailsList
        },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (resp.data.success) {
        setStatusChangedRef(true);
        sendFeedback(API.feedbackLogs, 'invoice export', user.email, user.companyID);
        enqueueSnackbar(t('INVOICE_SEND_TO_EMAIL_SUCCESS'), {
          variant: 'success',
        });
        getInvoice();
        setLoading(false);
        if (isAutoNavigationAllowed) {
          handleNavigateInvoice(nextInvoice, 1);
        }
      } else {
        enqueueSnackbar(t('INVOICE_SEND_TO_EMAIL_FAILURE'), {
          variant: 'error',
        });
        setLoading(false);
      }
    } catch (error) {
      enqueueSnackbar(
        appendContactSupport(window.config.support_email, getLocalisedErrorString(error?.response?.data?.i18n || 'INVOICE_SEND_TO_EMAIL_FAILURE', t), t),
        {
          variant: 'error',
        }
      );
      setLoading(false);
    }

    setSendEmailModalOpen(false);
  };

  const handleDataFormatToExportClick = async (dataFormat, executeExport = false) => {
    if (((dataFormat.key === 'excel' && excelExportFieldMapping.length === 0)
      || (dataFormat.key === 'dat' && datExportFieldMapping.length === 0)
      || (dataFormat.key === 'csv' && csvExportFieldMapping.length === 0))
      && !executeExport) {
      setExportFormat(dataFormat);
      setShowExportFieldMapping(true);
      return;
    }

    try {
      setLoading(true);
      let url = '';
      let newWindow = null;

      url = `${API.export}${dataFormat.key}/${invoice.id}`;

      const header = (dataFormat.key !== 'quickbooks' && dataFormat.key !== 'holded' && dataFormat.key !== 'gstock' && dataFormat.key !== 'sftp-sender')
        ? axiosHeadersWithArrayBuffer(localStorage.getItem('PROCYS_accessToken'))
        : axiosHeaders(localStorage.getItem('PROCYS_accessToken'));

      const response = await Axios.get(
        url,
        header
      );
      if (dataFormat.key === 'csv') {
        newWindow = window.open(response.data, '_blank');
      }

      if (dataFormat.key !== 'quickbooks' && dataFormat.key !== 'holded' && dataFormat.key !== 'gstock' && dataFormat.key !== 'sftp-sender') {
        const contentDisposition = response.headers["content-disposition"];
        let fileName = details.id.toString().concat('.').concat(dataFormat.fileExtension);

        if (contentDisposition) {
          const match = contentDisposition.match(/filename="(.+)"/);
          if (match && match[1]) {
            fileName = match[1]; // Extracted filename from header
          }
        }

        const blob = new Blob([response.data], { type: 'blob' });
        FileSaver.saveAs(blob, fileName);
        if (newWindow) {
          newWindow.close();
        }
      } else {
        enqueueSnackbar(t('EXPORT_SCHEDULED_SUCCESS'), {
          variant: 'success'
        });
      }

      setStatusChangedRef(true);
      getInvoice();
      sendFeedback(API.feedbackLogs, 'invoice export', user.email, user.companyID);
      setLoading(false);
      if (isAutoNavigationAllowed) {
        handleNavigateInvoice(nextInvoice, 1);
      }
    } catch (error) {
      let errString = error?.response?.data?.i18n;

      if (dataFormat.key !== 'quickbooks' && dataFormat.key !== 'holded' && dataFormat.key !== 'gstock') {
        const buffer = error.response.data;
        const view = new Uint8Array(buffer);
        const decodedObject = String.fromCharCode.apply(null, view);
        const errorObject = JSON.parse(decodedObject);
        errString = errorObject?.i18n;
      }

      enqueueSnackbar(appendContactSupport(window.config.support_email, getLocalisedErrorString(errString || 'INVOICE_EXPORT_DATA_FAILURE', t), t), {
        variant: 'error',
      });
      getInvoice();
      setLoading(false);
    }
  };

  const handleAutoSaveInvoice = async (toStatus) => {
    if (!isSubmitClicked) {
      try {
        await handleSubmit();
        if (toStatus === 501) {
          handleChooseValidatorModalOpen();
        } else {
          handleSelectStatus(toStatus, invoice.id);
        }
        return;
      } catch (error) {
        return;
      }
    }
    if (toStatus === 501) {
      handleChooseValidatorModalOpen();
    } else {
      handleSelectStatus(toStatus, invoice.id);
    }
  };

  const saveFieldsSelection = async (newField, oldField) => {
    const selectedFields = [...renderedFields.filter((field) => field.key !== oldField).map((f) => f.key), newField];
    try {
      const data = [];
      for (let i = 0; i < selectedFields.length; i++) {
        data.push({ name: selectedFields[i], position: headerFields.find((f) => f.key === selectedFields[i])?.position });
      }
      let url = `${API.fieldsV2}/doc-types/${docType}/sections/header/${user.companyID}`;
      if (user?.userRole === userRoles.annotator) {
        url += `?docID=${invoice.assistantID}`;
      }
      const response = await Axios.post(
        url,
        { fields: data },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        getFieldsForManageFields();
        enqueueSnackbar(
          t('SAVE_FIELDS_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
      }
    } catch (error) {
      let errorMessage = appendContactSupport(window.config.support_email, t('SAVE_FIELDS_FAILURE'), t);
      if (error && error.response && error.response.data) {
        errorMessage = t(error.response.data.i18n);
      }

      enqueueSnackbar(
        errorMessage,
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  const onFocusField = (e, prop, dataType) => {
    if (xmlReceived && xml === null) {
      return;
    }
    if (e.target.id === prop) {
      updateAnnotateFieldsTime();
      setTextFieldSelectedKey(prop);
      setSelectedDataType(dataType);
      setAnnotateFieldStart(new Date().getTime());
      const propArr = prop.split('_');
      if (prop.startsWith('invoiceLineColumn')) {
        return;
      }
      if (prop.startsWith('vatLine')) {
        const capitalized = propArr[2][0].toUpperCase() + propArr[2].slice(1);
        setAnnotateSelectedField(`vatLine${capitalized}`);
        setTextFieldSelectedValue(vatLines[parseInt(propArr[1], 10)][propArr[2]]);
        return;
      }
      if (prop.startsWith('esLine')) {
        const capitalized = propArr[2][0].toUpperCase() + propArr[2].slice(1);
        setAnnotateSelectedField(`esLine${capitalized}`);
        setTextFieldSelectedValue(esLines[parseInt(propArr[1], 10)][propArr[2]]);
        return;
      }
      if (prop.startsWith('invoiceLine')) {
        setAnnotateSelectedField(propArr[2]);
        setTextFieldSelectedValue(details.lines[parseInt(propArr[1], 10) - 1][propArr[2]]);
        return;
      }
      setAnnotateSelectedField(prop);
      setTextFieldSelectedValue(details[prop]);
    }
  };

  const handleSendEmailModalClose = () => {
    setSendEmailModalOpen(false);
  };

  const displayOption = (option) => {
    if ((typeof option) === 'object' && Object.keys(option).length > 0) {
      return option[Object.keys(option)[0]];
    }

    return option;
  };

  const filterSupplierOptions = (options, state, val) => {
    if (options.length > 0) {
      const inputVal = state.inputValue || val;
      if ((typeof options[0]) === 'object') {
        if (inputVal) {
          return options.filter((option) => Object.values(option).some((op) => op?.toLowerCase().includes(inputVal.toLowerCase())));
        }
        return options;
      }
      return options.filter((option) => option?.toLowerCase().includes(inputVal.toLowerCase()));
    }
    return options;
  };

  const handleIncludeLinesInExportChange = async () => {
    setLoading(true);
    try {
      const resp = await Axios.put(
        `${API.updateIncludeLinesInExport}/${invoice.assistantID}`,
        { status: !includeLinesInExport },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (resp.data.success) {
        setIncludeLinesInExport(!includeLinesInExport);
        setLoading(false);
        enqueueSnackbar(appendContactSupport(window.config.support_email, t('INCLUDE_LINES_IN_EXPORT_SUCCESS'), t), {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(appendContactSupport(window.config.support_email, t('INCLUDE_LINES_IN_EXPORT_FAILURE'), t), {
          variant: 'error',
        });
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(getLocalisedErrorString(
        error.response ? error.response.data.i18n : appendContactSupport(window.config.support_email, 'INCLUDE_LINES_IN_EXPORT_FAILURE', t), t
      ), {
        variant: 'error',
      });
    }
  };

  const getFieldClassName = (key, values) => {
    if (textFieldSelectedKey === key) {
      return styles.textFieldHighlighted;
    }
    if (details[key] === values[key] || details[key] === undefined) {
      return styles.root;
    }
    return styles.textFieldSelect;
  };

  const getIsError = (key, dataType) => {
    let regexp = invoiceRegex[key] || noWhiteSpace;
    if (dataType === 'float') {
      regexp = amountRegex;
    }
    if (dataType === 'date') {
      return validation(
        details[key],
        dateFormat,
      );
    }
    const fieldRule = user.fieldRules.find((fr) => fr.field === key);
    if (fieldRule && fieldRule.regex) {
      regexp = new RegExp(fieldRule.regex);
    }
    const isError = validation(
      details[key],
      regexp
    );
    return isError;
  };

  const getIsOpen = (key, dataType) => {
    const isValid = !getIsError(key, dataType);
    return fieldOpen[key] && textFieldSelectedKey === key && isValid;
  };

  const getHelperText = (key, dataType) => {
    if (getIsError(key, dataType)) {
      if (dataType === 'float') {
        return t('INVALID_NUMERIC_INPUT_FOR_INVOICE');
      }
      if (dataType === 'date') {
        const fieldRule = user.fieldRules.find((fr) => fr.field === key);
        return t('INVALID_DATE_FORMAT_HELPER_TEXT_NEW', { dateFormat: fieldRule?.format || dateFieldFormat, example: fieldRule?.rule || dateFieldExample });
      }
      return t(invoiceHelperText[key] || 'NO_WHITE_SPACE_HELPER_TEXT');
    }
    if (key === 'amount' && !isAmountTallyingWithLines(details, decimalSeparator)) {
      return t('INVOICE_EDIT_FORM_AMOUNT_NOT_TALLYING_WITH_LINES');
    }
    if (key === 'senderVatNumber' && senderVatNumberInvalid) {
      return t('INVALID_VAT_NUMBER_HELPER_TEXT');
    }
    if (key === 'senderCountryCode' && senderVatNumberInvalid) {
      return ' ';
    }
    if (key === 'receiverVatNumber' && receiverVatNumberInvalid) {
      return t('INVALID_VAT_NUMBER_HELPER_TEXT');
    }
    if (key === 'receiverCountryCode' && receiverVatNumberInvalid) {
      return ' ';
    }
    return '';
  };

  const onVatNumberBlur = (val, cc, func) => {
    if (val) {
      func(isVatNumberInvalid(val, cc));
    }
  };

  const handleExportFieldMappingClose = async (updated, format) => {
    setShowExportFieldMapping(false);
    if (updated) {
      setTimeout(async () => {
        await getExcelExportFieldMapping();
        await getDatExportFieldMapping();
        await getCsvExportFieldMapping();
        handleDataFormatToExportClick({ key: format.key, fileExtension: format.fileExtension }, true);
      }, 1000);
    }
  };

  const handleSelectColumnForReprocessing = (x1, x2, y1, y2) => {
    let formattedKey = textFieldSelectedKey.replace('invoiceLineColumn_', '');
    if (formattedKey === 'lineDescription') {
      formattedKey = 'lineName';
    }
    if (linesDataForReprocessing[formattedKey]) {
      setLinesDataForReprocessing({
        ...linesDataForReprocessing,
        [formattedKey]: [
          ...linesDataForReprocessing[formattedKey],
          {
            page: pageNumber + 1,
            x1: parseInt(x1.toFixed(6), 10),
            y1: parseInt(y1.toFixed(6), 10),
            x2: parseInt(x2.toFixed(6), 10),
            y2: parseInt(y2.toFixed(6), 10)
          }],
      });
    }
  };

  const sendLinesForReprocessing = async () => {
    setReprocessingLines(true);
    const data = {
      assistantID: invoice.assistantID.toString(),
      ...linesDataForReprocessing
    };
    try {
      const response = await Axios.post(
        `${API.lineBoundaries}`,
        data,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success && response.data.data) {
        setLinesDataForReprocessing({
          linesTotal: [],
          lineAll: [],
          lineArticle: [],
          lineQuantity: [],
          lineAmount: [],
          lineName: []
        });
        const updatedLines = [];
        details.lines.forEach((line) => {
          const updatedLine = { ...line };
          const reprocessedLine = response.data.data.find((l) => l.line === line.line);
          if (reprocessedLine) {
            updatedLine.lineArticle = reprocessedLine.lineArticle;
            updatedLine.lineQuantity = reprocessedLine.lineQuantity;
            updatedLine.lineAmount = reprocessedLine.lineAmount;
            updatedLine.lineDescription = reprocessedLine.lineDescription;
          }
          updatedLines.push(updatedLine);
        });
        if (response.data.data.length > details.lines.length) {
          response.data.data.forEach((line) => {
            if (!details.lines.find((l) => l.line === line.line)) {
              updatedLines.push(line);
            }
          });
        }
        setDetails({ ...details, lines: updatedLines });
        setReprocessingLines(false);
        enqueueSnackbar(
          t('SEND_LINE_BOUNDARIES_SUCCESS'),
          {
            variant: 'success',
            autoHideDuration: 5000
          }
        );
      }
    } catch (error) {
      setReprocessingLines(false);
      enqueueSnackbar(
        t('SEND_LINE_BOUNDARIES_FAILURE'),
        {
          variant: 'error',
          autoHideDuration: 5000
        }
      );
    }
  };

  const isLinesReprocessingBtnDisabled = () => {
    let isDisabled = true;
    Object.keys(linesDataForReprocessing).forEach((key) => {
      if (linesDataForReprocessing[key].length > 0) {
        isDisabled = false;
      }
    });
    return isDisabled;
  };

  const isResetLineBoundariesBtnDisabled = (key) => {
    let formattedKey = key;
    if (formattedKey === 'lineDescription') {
      formattedKey = 'lineName';
    }
    return linesDataForReprocessing[formattedKey].length === 0;
  };

  const resetLineBoundaries = (key) => {
    let formattedKey = key;
    if (formattedKey === 'lineDescription') {
      formattedKey = 'lineName';
    }
    setLinesDataForReprocessing({
      ...linesDataForReprocessing,
      [formattedKey]: []
    });
  };

  const onDownload = async () => {
    try {
      const response = await Axios.get(
        `${API.getInvoiceImage}${invoice.documentFile}`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `bearer ${localStorage.getItem('PROCYS_accessToken')}`
          }
        }
      );
      const blob = new Blob([response.data], { type: 'application/octet-stream' });
      const nameParts = invoice?.documentFile?.split('.');
      FileSaver.saveAs(window.URL.createObjectURL(blob), `${invoice.id}.${nameParts[nameParts.length - 1]}`)
    } catch (err) {
      enqueueSnackbar(t('DOCUMENT_DOWNLOAD_FAILED'), {
        variant: 'error',
      });
    }
  };

  const displayLines = () => (
    <DisplayLines
      t={t}
      ready={ready}
      preferredFields={lineFields}
      getLinesDisplayFields={getLineFieldsForManageFields}
      sendLines={sendLines}
      handleSendLinesChange={handleSendLinesChange}
      onAddLine={onAddLine}
      details={details}
      isTwinfield={isTwinfield}
      adjustLinesOrder={adjustLinesOrder}
      getTFLabel={getTFLabel}
      glAccounts={glAccounts}
      vatGroups={vatGroups}
      costCenters={costCenters}
      projects={projects}
      onEditLine={onEditLine}
      onDeleteLine={onDeleteLine}
      includeLinesInExport={includeLinesInExport}
      handleIncludeLinesInExportChange={handleIncludeLinesInExportChange}
      onUpdateLine={onUpdateLine}
      parameterCompany={parameterCompany}
      onAddLineConfirm={onAddLineConfirm}
      textFieldSelectedKey={textFieldSelectedKey}
      onFocusField={onFocusField}
      supplier={supplier}
      setTextFieldSelectedKey={setTextFieldSelectedKey}
      reprocessingLines={reprocessingLines}
      sendLinesForReprocessing={sendLinesForReprocessing}
      isLinesReprocessingBtnDisabled={isLinesReprocessingBtnDisabled}
      isResetLineBoundariesBtnDisabled={isResetLineBoundariesBtnDisabled}
      resetLineBoundaries={resetLineBoundaries}
      decimalSeparator={decimalSeparator}
    />
  );

  const renderInvoiceXML = (_xmlSelectedValue, _setXmlSelectedValue, _textFieldSelectedValue, _scrollXMLViewBy, isLine, _textFieldSelectedKey) => (
    <>
      {
        numPages && (
          <Grid id="canvas-top-bar" container direction="row" alignItems="center" justifyContent="space-between" style={styles.topBar}>
            <Grid style={styles.xmlNavBtnsContainer}>
              <TextField
                type="number"
                variant="outlined"
                style={styles.searchInput}
                value={gotoPage}
                onChange={(e) => setGotoPage(e.target.value)}
                onKeyPress={(event) => onClickGotoPage(event)}
                sx={{
                  '& input[type=number]': {
                    'MozAppearance': 'textfield'
                  },
                  '& input[type=number]::-webkit-outer-spin-button': {
                    'WebkitAppearance': 'none',
                    margin: 0
                  },
                  '& input[type=number]::-webkit-inner-spin-button': {
                    'WebkitAppearance': 'none',
                    margin: 0
                  },
                  '& .MuiInputBase-input': {
                    textAlign: 'center',
                    backgroundColor: '#fcfcfb 0.5',
                    color: 'white!important',
                  },
                  '& .MuiOutlinedInput-root': {
                    height: '30px!important',
                    '& fieldset': {
                      borderColor: 'white',
                    },
                    '&:hover fieldset': {
                      borderColor: 'white',
                    },
                    '& .Mui-focused fieldset': {
                      borderColor: 'white'
                    },
                  },
                }}
              />
              <>{`/${numPages}`}</>
              {
                numPages && numPages > 1 && (
                  <>
                    <Button
                      style={styles.buttonCls}
                      disabled={pageNumber === 0}
                      onClick={() => prevPage()}
                      sx={{
                        '&:disabled': {
                          backgroundColor: 'transparent !important',
                        }
                      }}
                    >
                      <NavigateBeforeIcon id="nav_previous" />
                    </Button>
                    <Button
                      style={styles.buttonCls}
                      disabled={pageNumber === numPages}
                      onClick={() => nextPage()}
                      sx={{
                        '&:disabled': {
                          backgroundColor: 'transparent !important',
                        }
                      }}
                    >
                      <NavigateNextIcon id="nav_next" />
                    </Button>
                  </>
                )
              }
            </Grid>
            <Grid style={styles.pdfTop}>
              <Grid style={styles.instructionsContainer}>
                <Typography style={styles.instructions}>
                  {ready && t('ANNOTATE_INTRUCTIONS_LINE_1')}
                  <span style={styles.keyIcon}>shift</span>
                  {ready && t('ANNOTATE_INTRUCTIONS_LINE_2')}
                </Typography>
                <Typography style={styles.instructions}>
                  {ready && t('ANNOTATE_INTRUCTIONS_LINE_3')}
                  <span style={styles.keyIcon}>esc</span>
                  {ready && t('ANNOTATE_INTRUCTIONS_LINE_4')}
                </Typography>
              </Grid>
            </Grid>
            <Grid>
              <Button
                style={styles.buttonCls}
                onClick={() => onDownload()}
              >
                <DownloadIcon />
              </Button>
              <Button
                style={styles.buttonCls}
                onClick={() => window.open(`/files/image/${imgName}`)}
              >
                <OpenInNewIcon />
              </Button>
            </Grid>
          </Grid>
        )
      }
      <Grid id="xmlContainer" style={styles.pdfContainer}>
        <Box display="flex" justifyContent="center" style={styles.documentStyle}>
          <Canvas
            page={page}
            pageNumber={pageNumber}
            image={imageBinary[pageNumber] ? `data:image/jpeg;base64,${imageBinary[pageNumber]?.innerHTML}` : ''}
            words={isLine ? wordsForLines : words}
            xmlSelectedValue={_xmlSelectedValue}
            setXmlSelectedValue={_setXmlSelectedValue}
            candidates={candidates}
            textFieldSelectedValue={_textFieldSelectedValue}
            scrollXMLViewBy={_scrollXMLViewBy}
            isLine={isLine}
            lineItems={lineItems}
            isOverlapping={isOverlapping}
            topBarHeight={document.getElementById('canvas-top-bar')?.offsetHeight}
            handleGotoPage={handleGotoPage}
            textFieldSelectedKey={_textFieldSelectedKey}
            handleSelectColumnForReprocessing={handleSelectColumnForReprocessing}
            linesReprocessingData={Object.values(linesDataForReprocessing).flat()}
            decimalSeparator={decimalSeparator}
          />
        </Box>
      </Grid>
    </>
  );

  const renderInvoicePDF = () => (
    isMobile ? (
      <PdfView
        t={t}
        ready={ready}
        fileResponse={fileResponse}
        fetchingFile={fetchingFile}
        imgName={imgName}
        canOpenInNewTab
      />
    ) : (
      <iframe src={fileResponse} title="pdf" style={styles.pdfFrame} />
    )
  );

  const renderButtons = (touched) => (
    <InvoiceEditFormActionButtons
      invoice={invoice}
      setSendEmailModalOpen={setSendEmailModalOpen}
      isToValidateStatusAllowed={isToValidateStatusAllowed}
      touched={touched}
      handleAutoSaveInvoice={handleAutoSaveInvoice}
      statusChangeLoading={statusChangeLoading}
      deliverFormats={deliverFormats}
      handleDataFormatToExportClick={handleDataFormatToExportClick}
      isSubmitClicked={isSubmitClicked}
      details={details}
      isTwinfield={isTwinfield}
      vatLines={vatLines}
      esLines={esLines}
      handleSubmit={handleSubmit}
      renderedFields={renderedFields}
      getIsError={getIsError}
      decimalSeparator={decimalSeparator}
    />
  );

  const renderNextAndPrevious = () => (
    <Box mt={3} style={styles.navButtonContainer}>
      <Tooltip title={previousInvoice === null ? t('INVOICE_EDIT_FORM_NO_PREV_TOOLTIP') : ''}>
        <div>
          <Button
            variant="outlined"
            style={styles.navButton}
            disabled={previousInvoice === null}
            onClick={() => handleNavigateInvoice(previousInvoice, -1)}
          >
            <ArrowLeftIcon style={styles.btnIconArrowLeft} />
            {ready && t('INVOICE_EDIT_FORM_PREV_BUTTON')}
          </Button>
        </div>
      </Tooltip>
      <Tooltip title={nextInvoice === null ? t('INVOICE_EDIT_FORM_NO_NEXT_TOOLTIP') : ''}>
        <div>
          <Button
            variant="outlined"
            style={styles.navButton}
            disabled={nextInvoice === null}
            onClick={() => handleNavigateInvoice(nextInvoice, +1)}
          >
            {ready && t('INVOICE_EDIT_FORM_NEXT_BUTTON')}
            <ArrowRightIcon style={styles.btnIconArrowRight} />
          </Button>
        </div>
      </Tooltip>
    </Box>
  );

  return (
    <Formik
      enableReinitialize
      initialValues={details}
    >
      {({
        handleBlur,
        touched,
        values
      }) => (
        <form style={styles.root}>
          <Grid id="formContainer" container style={styles.container}>
            <Grid item xs={12} md={4} style={(isMobile || !minHeight) ? styles.leftContainerMobile : styles.leftContainer}>
              {renderHeader()}
              <Grid style={styles.cardContent}>
                <Grid
                  container
                  className="show-scroll"
                  style={(isMobile || !minHeight)
                    ? styles.inputContainerMobile : (invoice.status === invoiceStatusCode.toexport && invoice.exportFailReason)
                      ? styles.inputContainerWithExportFailReason : styles.inputContainer}
                >
                  {headerFields.map(({
                    key, label, assistantKey, isRequired, isActive, isAvailable, dataType, isCustomField
                  }) => {
                    if (isCustomField) return null;
                    if (!isFieldAvailable(key, (isAvailable && isActive))
                      || key === 'doNotPay'
                      || key === 'vatPercentage'
                      || key === 'equivalenceSurchargeRate'
                      || (key === 'clientCode' && custom2FieldInDocumentForm)
                    ) {
                      return null;
                    }
                    let field2;
                    if (key === 'vatAmount') {
                      field2 = headerFields.find((f) => f.key === 'vatPercentage');
                    } else if (key === 'supplier') {
                      field2 = headerFields.find((f) => f.key === 'doNotPay');
                    } else if (key === 'equivalenceSurchargeAmount') {
                      field2 = headerFields.find((f) => f.key === 'equivalenceSurchargeRate');
                    }
                    switch (key) {
                      case 'documentType': case 'secondaryDocumentType': case 'typeOfTransaction': case 'paymentForm':
                        return (
                          <Tooltip key={key} title={invoiceTooltips[key] ? t(invoiceTooltips[key]) : ''}>
                            <Grid item xs={12} style={styles.inputItem}>
                              <FormControl variant="outlined" style={styles.formControl}>
                                <InputLabel id={`${key}-label`}>{ready && t(label)}</InputLabel>
                                <Select
                                  id={key}
                                  labelId={`${key}-label`}
                                  label={ready && t(label)}
                                  name={key}
                                  onBlur={handleBlur}
                                  onFocus={(e) => onFocusField(e, key, dataType)}
                                  onChange={(e) => handleChangeMain(e, key)}
                                  style={{
                                      ...styles.inputText,
                                      ...styles[(details[key] === values[key] || details[key] === undefined) ? 'root' : 'textFieldSelect']
                                  }}
                                  value={details[key]}
                                  disabled={invoice.status < 500 || !xml}
                                  required
                                  MenuProps={{
                                    anchorOrigin: {
                                      vertical: 'bottom',
                                      horizontal: 'left'
                                    },
                                    transformOrigin: {
                                      vertical: 'top',
                                      horizontal: 'left'
                                    },
                                    getContentAnchorEl: null
                                  }}
                                >
                                  {
                                    documentEditSelectValues[key].map((s) => (
                                      <MenuItem style={styles.inputText} key={s} value={s}>
                                        {t(`${documentEditSelectLabelPrefixes[key]}${s}`)}
                                      </MenuItem>
                                    ))
                                  }
                                </Select>
                              </FormControl>
                            </Grid>
                          </Tooltip>
                        );

                      case 'documentDate':
                      case 'dueDate':
                      case 'deliveryDate':
                      case 'dateOfBirth':
                      case 'dateOfIssue':
                      case 'dateOfExpiry':
                      case 'mnjPeriodStart':
                      case 'mnjPeriodEnd':
                        return (
                          <Grid key={key} item xs={12} style={styles.inputItem}>
                            <Autocomplete
                              id={key}
                              open={getIsOpen(key, dataType)}
                              onOpen={() => setFieldOpen({ ...fieldOpen, [key]: true })}
                              onClose={() => setFieldOpen({ ...fieldOpen, [key]: false })}
                              required={isRequired}
                              fullWidth
                              freeSolo
                              name={key}
                              onChange={(e, v) => handleChangeMain(e, key, assistantKey, v)}
                              onBlur={handleBlur}
                              onFocus={(e) => onFocusField(e, key, dataType)}
                              value={details[key]}
                              options={detailsXML[assistantKey] ? detailsXML[assistantKey] : [invoice[key] ? invoice[key] : '']}
                              getOptionLabel={(option) => option && option.value ? option.value : option}
                              isOptionEqualToValue={
                                (option) => option.value === (details[key] ? details[key] : detail[assistantKey] ? detail[assistantKey] : ' ')
                              }
                              disabled={invoice.status < 500 || !xml}
                              renderInput={(params) => (
                                <TextField
                                  inputRef={fieldRef[key]}
                                  fullWidth
                                  error={getIsError(key, dataType)}
                                  helperText={getHelperText(key, dataType)}
                                  {...params}
                                  label={ready && t(label)}
                                  required={isRequired}
                                  variant="outlined"
                                  onChange={(e) => handleChangeText(key, e.target.value, assistantKey)}
                                  onClick={(e) => onFocusField(e, key, dataType)}
                                  InputProps={{ ...params.InputProps }}
                                  style={getFieldClassName(key, values)}
                                />
                              )}
                            />
                          </Grid>
                        );

                      case 'period': case 'description':
                        return (
                          <Tooltip key={key} title={invoiceTooltips[key] ? t(invoiceTooltips[key]) : ''}>
                            <Grid item xs={12} style={styles.inputItem}>
                              <TextField
                                id={key}
                                ref={fieldRef[key]}
                                fullWidth
                                label={ready && t(label)}
                                name={key}
                                value={details[key] || ''}
                                error={getIsError(key, dataType)}
                                helperText={getHelperText(key, dataType)}
                                onBlur={handleBlur}
                                onFocus={(e) => onFocusField(e, key, dataType)}
                                onChange={(e) => handleChangeMain(e, key)}
                                style={getFieldClassName(key, values)}
                                disabled={invoice.status < 500 || !xml}
                                variant="outlined"
                              />
                            </Grid>
                          </Tooltip>
                        );

                      case 'supplier':
                        return (
                          <Fragment key={key}>
                            <Grid item xs={12} style={{...styles[noSupplier ? 'inputItemCompress' : 'inputItem'], ...styles.inputRow}}>
                              <Tooltip
                                disableHoverListener={suppliers?.length > 0 && !validation(details.documentDate, dateFormat)}
                                disableFocusListener={suppliers?.length > 0 && !validation(details.documentDate, dateFormat)}
                                disableTouchListener={suppliers?.length > 0 && !validation(details.documentDate, dateFormat)}
                                title={
                                  invoice?.status < 500 ? t('INVOICE_EDIT_INVALID_INVOICE_STATUS_SUPPLIER')
                                    : !xml ? t('INVOICE_EDIT_XML_NOT_RECEIVED')
                                      : !suppliers || suppliers.length === 0 ? t('INVOICE_EDIT_NO_SUPPLIERS')
                                        : ''
                                }
                              >
                                <Autocomplete
                                  id="supplier"
                                  open={getIsOpen('supplier', 'text')}
                                  onOpen={() => setFieldOpen({ ...fieldOpen, supplier: true })}
                                  onClose={() => setFieldOpen({ ...fieldOpen, supplier: false })}
                                  fullWidth
                                  name="supplier"
                                  onChange={(e, v) => handleChangeMain(e, 'supplier', 'supplier', v)}
                                  onBlur={handleBlur}
                                  onFocus={(e) => { onFocusField(e, 'supplier', 'text'); setFieldOpen({ ...fieldOpen, supplier: true }); }}
                                  value={supplierLabel}
                                  options={suppliers}
                                  filterOptions={(options, state) => filterSupplierOptions(options, state, details.supplier)}
                                  getOptionLabel={
                                    (option) => option && option.creditorCode && !isSupplierLabelWithVAT
                                      ? `${option.creditorCode} | ${option.creditorName}`
                                      : option.creditorName && option.vatNumber
                                        ? `${option.vatNumber} | ${option.creditorName}`
                                        : option.creditorName
                                          ? option.creditorName
                                          : displayOption(option)
                                  }
                                  isOptionEqualToValue={(option) => option.creditorCode === (details.supplier ? details.supplier : ' ')}
                                  disabled={invoice.status < 500 || !xml}
                                  noOptionsText={ready && t('INVOICE_EDIT_FORM_NO_OPTION')}
                                  sx={{'& .MuiAutocomplete-inputRoot': {paddingRight: '65px !important'}}}
                                  freeSolo
                                  renderInput={(params) => (
                                    <TextField
                                      inputRef={fieldRef.supplier}
                                      fullWidth
                                      error={getIsError('supplier', 'text')}
                                      helperText={(getIsError('supplier', 'text') && t('NO_WHITE_SPACE_HELPER_TEXT'))}
                                      {...params}
                                      label={ready && t('INVOICE_EDIT_FORM_SUPPLIER')}
                                      variant="outlined"
                                      required={
                                        (isTwinfield && suppliers && suppliers.length > 0)
                                        && (details.documentType === documentTypes.invoice
                                        || details.documentType === documentTypes.creditNote)
                                      }
                                      onChange={(e) => handleChangeSupplier(e.target.value)}
                                      onClick={(e) => { onFocusField(e, 'supplier', dataType); setFieldOpen({ ...fieldOpen, supplier: true }); }}
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (user.userRole.toLowerCase() === userRoles.annotator
                                          ? (details.supplier && (
                                            <InputAdornment position="end" onClick={() => editSupplier(details.supplier)} style={styles.viewIconContainer}>
                                              <SearchIcon style={styles.viewDimensionIcon} />
                                            </InputAdornment>
                                          )) : (
                                            <>
                                              {details.supplier && (
                                                <InputAdornment position="end" onClick={() => editSupplier(details.supplier)} style={styles.editIconContainer}>
                                                  <EditIcon style={styles.editDimensionIcon} />
                                                </InputAdornment>
                                              )}
                                              <InputAdornment position="end" onClick={addNewSupplier} style={styles.plusIconContainer}>
                                                <PlusIcon style={styles.plusIcon} />
                                              </InputAdornment>
                                            </>
                                          )
                                        ),
                                      }}
                                      style={getFieldClassName(key, values)}
                                    />
                                  )}
                                />
                              </Tooltip>
                              {isFieldAvailable('doNotPay', (field2.isAvailable && field2.isActive)) && (
                                <FormControlLabel
                                  value="top"
                                  control={(
                                    <Checkbox
                                      style={styles.checkBox}
                                      checked={details.doNotPay || false}
                                      onChange={(e) => handleChange(e.target.checked, 'doNotPay')}
                                    />
                                  )}
                                  onFocus={() => setTextFieldSelectedKey('')}
                                  label={ready && t('INVOICE_EDIT_FORM_DO_NOT_PAY')}
                                  labelPlacement="start"
                                  style={{
                                    ...styles[(details.doNotPay === values.doNotPay || details.doNotPay === undefined)
                                        ? 'root' : 'textFieldSelect'],
                                    ...styles.checkBoxContainer
                                  }}
                                  classes={{
                                    label: styles.checkBoxLabel
                                  }}
                                />
                              )}
                            </Grid>
                            {custom2FieldInDocumentForm && (
                              <Tooltip title={t('INVOICE_EDIT_CUSTOM_2')}>
                                <Grid item xs={12} style={styles.inputItem}>
                                  <Autocomplete
                                    id="clientCode"
                                    open={getIsOpen('clientCode', 'text')}
                                    onOpen={() => setFieldOpen({ ...fieldOpen, clientCode: true })}
                                    onClose={() => setFieldOpen({ ...fieldOpen, clientCode: false })}
                                    required={isRequired}
                                    fullWidth
                                    freeSolo
                                    name="clientCode"
                                    onChange={(e, v) => handleChangeMain(e, 'clientCode', 'client_code', v)}
                                    onBlur={handleBlur}
                                    onFocus={(e) => onFocusField(e, 'clientCode', 'text')}
                                    value={details.clientCode}
                                    options={detailsXML.client_code ? detailsXML.client_code : [invoice.clientCode ? invoice.clientCode : '']}
                                    getOptionLabel={(option) => option && option.value ? option.value : option}
                                    isOptionEqualToValue={(option) => option.value === (details.clientCode
                                      ? details.clientCode
                                      : detail.client_code
                                        ? detail.client_code
                                        : ' ')}
                                    disabled={invoice.status < 500 || !xml}
                                    renderInput={(params) => (
                                      <TextField
                                        inputRef={fieldRef.clientCode}
                                        fullWidth
                                        error={getIsError('clientCode', 'text')}
                                        helperText={(getIsError('clientCode', 'text') && t('NO_WHITE_SPACE_HELPER_TEXT'))}
                                        required={isRequired}
                                        {...params}
                                        label={ready && t('INVOICE_EDIT_FORM_CLIENT_CODE')}
                                        variant="outlined"
                                        onChange={(e) => handleChangeText('clientCode', e.target.value, 'client_code')}
                                        onClick={(e) => onFocusField(e, key, 'text')}
                                        InputProps={{ ...params.InputProps }}
                                        style={getFieldClassName('clientCode', values)}
                                      />
                                    )}
                                  />
                                </Grid>
                              </Tooltip>
                            )}
                          </Fragment>
                        );

                      case 'currency':
                        return (
                          <Grid key={key} item xs={12} style={styles.inputItem}>
                            <Autocomplete
                              id="currency"
                              open={getIsOpen('currency', dataType)}
                              onOpen={() => setFieldOpen({ ...fieldOpen, currency: true })}
                              onClose={() => setFieldOpen({ ...fieldOpen, currency: false })}
                              required={isRequired}
                              fullWidth
                              name="currency"
                              onChange={(e, v) => handleChangeMain(e, 'currency', 'currency', v)}
                              onBlur={handleBlur}
                              onFocus={(e) => onFocusField(e, 'currency', dataType)}
                              value={getCurrencyLabel(details.currency)}
                              options={currencies}
                              getOptionLabel={(option) => option && option.code ? `${option.code} | ${option.currency}` : option}
                              isOptionEqualToValue={
                                (option) => option.value === (details.currency ? details.currency : detail.currency ? detail.currency : ' ')
                              }
                              disabled={invoice.status < 500 || !xml}
                              renderInput={(params) => (
                                <TextField
                                  inputRef={fieldRef.currency}
                                  fullWidth
                                  error={getIsError('currency', dataType)}
                                  helperText={(getIsError('currency', dataType) && t('INVALID_CURRENCY_HELPER_TEXT'))}
                                  {...params}
                                  label={ready && t('INVOICE_EDIT_FORM_CURRENCY')}
                                  required={isRequired}
                                  variant="outlined"
                                  InputProps={{ ...params.InputProps }}
                                  style={getFieldClassName(key, values)}
                                  onClick={(e) => onFocusField(e, 'currency', dataType)}
                                />
                              )}
                            />
                          </Grid>
                        );

                      case 'terms':
                        return (
                          <Grid key={key} item xs={12} style={styles.inputItem}>
                            <Autocomplete
                              id="terms"
                              open={getIsOpen(key, dataType)}
                              onOpen={() => setFieldOpen({ ...fieldOpen, terms: true })}
                              onClose={() => setFieldOpen({ ...fieldOpen, terms: false })}
                              required={isRequired}
                              fullWidth
                              freeSolo
                              name="terms"
                              onChange={(e, v) => handleChangeMain(e, 'terms', 'terms', v)}
                              onBlur={handleBlur}
                              onFocus={(e) => onFocusField(e, 'terms', dataType)}
                              value={getTermsLabel(details.terms)}
                              options={detailsXML.terms ? detailsXML.terms : [invoice.terms ? invoice.terms : '']}
                              getOptionLabel={(option) => option && option.code ? `${option.code} | ${option.name}` : option}
                              isOptionEqualToValue={(option) => option.value === (details.terms ? details.terms : detail.terms ? detail.terms : ' ')}
                              disabled={invoice.status < 500 || !xml}
                              renderInput={(params) => (
                                <TextField
                                  inputRef={fieldRef.terms}
                                  fullWidth
                                  error={getIsError(key, dataType)}
                                  helperText={getHelperText(key, dataType)}
                                  onChange={(e) => handleChangeText(key, e.target.value, assistantKey)}
                                  onClick={(e) => onFocusField(e, key, dataType)}
                                  {...params}
                                  label={ready && t('INVOICE_EDIT_FORM_TERMS')}
                                  required={isRequired}
                                  variant="outlined"
                                  InputProps={{ ...params.InputProps }}
                                  style={getFieldClassName(key, values)}
                                />
                              )}
                            />
                          </Grid>
                        );

                      case 'vatAmount':
                        return (
                          <Fragment key={key}>
                            <Tooltip title={t('INVOICE_EDIT_FORM_VAT_AMOUNT_TOOLTIP')}>
                              <Grid item xs={12} style={styles.vatDetailsContainerTop}>
                                <Grid>
                                  <Grid style={styles.elem1} />
                                  <Grid style={styles.elem2} />
                                </Grid>
                                <Autocomplete
                                  id="vatAmount"
                                  open={getIsOpen('vatAmount', dataType)}
                                  onOpen={() => setFieldOpen({ ...fieldOpen, [key]: true })}
                                  onClose={() => setFieldOpen({ ...fieldOpen, [key]: false })}
                                  required={isRequired}
                                  fullWidth
                                  freeSolo
                                  name="vatAmount"
                                  onChange={(e, v) => handleChangeMain(e, 'vatAmount', 'vat_amount', v)}
                                  onBlur={handleBlur}
                                  onFocus={(e) => onFocusField(e, 'vatAmount', dataType)}
                                  value={details.vatAmount}
                                  options={detailsXML.vat_amount ? detailsXML.vat_amount : [invoice.vatAmount ? invoice.vatAmount : '']}
                                  getOptionLabel={(option) => option && option.value ? option.value : option}
                                  isOptionEqualToValue={(option) => option.value === (details.vatAmount
                                    ? details.vatAmount
                                    : detail.vat_amount
                                      ? detail.vat_amount
                                      : ' ')}
                                  disabled={invoice.status < 500 || !xml}
                                  renderInput={(params) => (
                                    <TextField
                                      inputRef={fieldRef.vatAmount}
                                      fullWidth
                                      error={getIsError('vatAmount', dataType) || totalVatAmountError}
                                      helperText={(getIsError('vatAmount', dataType) ? t('INVALID_NUMERIC_INPUT_FOR_INVOICE')
                                        : totalVatAmountError ? t('INVOICE_EDIT_FORM_VAT_DETAILS_INVALID_TOTAL') : '')}
                                      {...params}
                                      label={ready && t('INVOICE_EDIT_FORM_VAT_AMOUNT')}
                                      required={isRequired}
                                      variant="outlined"
                                      onChange={(e) => handleChangeText('vatAmount', e.target.value, 'vat_amount')}
                                      onClick={(e) => onFocusField(e, 'vatAmount', dataType)}
                                      InputProps={{
                                        ...params.InputProps,
                                        style: { backgroundColor: textFieldSelectedKey === 'vatAmount' ? '#d7e7fc' : totalVatAmountError ? '#fcc9a7' : '' }
                                      }}
                                      style={getFieldClassName(key, values)}
                                    />
                                  )}
                                />
                              </Grid>
                            </Tooltip>
                            <Grid style={styles.vatDetailsContainer}>
                              {isFieldAvailable('vatPercentage', (field2.isAvailable && field2.isActive)) && (
                                <Tooltip title={t('INVOICE_EDIT_FORM_VAT_PERCENTAGE_TOOLTIP')}>
                                  <Grid item xs={12} style={styles.inputItem}>
                                    <Autocomplete
                                      id="vatPercentage"
                                      open={getIsOpen('vatPercentage', 'float')}
                                      onOpen={() => setFieldOpen({ ...fieldOpen, [key]: true })}
                                      onClose={() => setFieldOpen({ ...fieldOpen, [key]: false })}
                                      fullWidth
                                      freeSolo
                                      required={isRequired}
                                      name="vatPercentage"
                                      onChange={(e, v) => handleChangeMain(e, 'vatPercentage', 'vat_percentage', v)}
                                      onBlur={handleBlur}
                                      onFocus={(e) => onFocusField(e, 'vatPercentage', 'float')}
                                      value={details.vatPercentage}
                                      options={detailsXML.vat_percentage ? detailsXML.vat_percentage : [invoice.vatPercentage ? invoice.vatPercentage : '']}
                                      getOptionLabel={(option) => option && option.value ? option.value : option}
                                      isOptionEqualToValue={(option) => option.value === (details.vatPercentage
                                        ? details.vatPercentage
                                        : detail.vat_percentage
                                          ? detail.vat_percentage
                                          : ' ')}
                                      disabled={invoice.status < 500 || !xml}
                                      renderInput={(params) => (
                                        <TextField
                                          inputRef={fieldRef.vatPercentage}
                                          fullWidth
                                          error={getIsError('vatPercentage', 'float')}
                                          helperText={(getIsError('vatPercentage', 'float') && t('INVALID_NUMERIC_INPUT_FOR_INVOICE'))}
                                          {...params}
                                          label={ready && t('INVOICE_EDIT_FORM_VAT_PERCENTAGE')}
                                          required={isRequired}
                                          variant="outlined"
                                          onChange={(e) => handleChangeText('vatPercentage', e.target.value, 'vat_percentage')}
                                          onClick={(e) => onFocusField(e, 'vatPercentage', 'float')}
                                          InputProps={{ ...params.InputProps }}
                                          style={getFieldClassName('vatPercentage', values)}
                                        />
                                      )}
                                    />
                                  </Grid>
                                </Tooltip>
                              )}
                              {showVatDetails && (
                              <Grid innerRef={vatDetailsRef}>
                                {vatLines.map((vatLine, i) => (
                                  <>
                                    <Grid style={styles.inputItemRowSpace}>
                                      <TextField
                                        fullWidth
                                        key={`vatLine_${i}_taxName`}
                                        id={`vatLine_${i}_taxName`}
                                        error={!noWhiteSpace.test(vatLine.taxName)}
                                        label={ready && t(vatLineLabels.taxName)}
                                        name={`vatLine_${i}_taxName`}
                                        onBlur={handleBlur}
                                        onFocus={(e) => onFocusField(e, `vatLine_${i}_taxName`, 'text')}
                                        onClick={(e) => onFocusField(e, `vatLine_${i}_taxName`, 'text')}
                                        onChange={(e) => handleChangeVatLine(e, i, 'taxName')}
                                        className={
                                          textFieldSelectedKey === `vatLine_${i}_taxName` ? styles.vatLineFieldHighlightedTaxName
                                            : (!noWhiteSpace.test(vatLine.taxName) || vatLine.error || vatLine.vatError)
                                              ? styles.vatLineFieldErrorTaxName : styles.vatLineFieldTaxName
                                        }
                                        value={vatLine.taxName}
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid style={styles.inputItemRowSpace}>
                                      {['baseAmount', 'vatRate', 'vatAmount'].map((vtlkey) => (
                                        <TextField
                                          key={`vatLine_${i}_${vtlkey}`}
                                          id={`vatLine_${i}_${vtlkey}`}
                                          error={!amountRegex.test(vatLine[vtlkey]) || vatLine.error || vatLine.vatError}
                                          label={ready && t(vatLineLabels[vtlkey])}
                                          name={`vatLine_${i}_${vtlkey}`}
                                          onBlur={handleBlur}
                                          onFocus={(e) => onFocusField(e, `vatLine_${i}_${vtlkey}`, 'float')}
                                          onClick={(e) => onFocusField(e, `vatLine_${i}_${vtlkey}`, 'float')}
                                          onChange={(e) => handleChangeVatLine(e, i, vtlkey)}
                                          style={
                                            textFieldSelectedKey === `vatLine_${i}_${vtlkey}` ? styles.vatLineFieldHighlighted
                                              : (!amountRegex.test(vatLine[vtlkey]) || vatLine.error || vatLine.vatError)
                                                ? styles.vatLineFieldError : styles.vatLineField
                                          }
                                          value={vatLine[vtlkey]}
                                          variant="outlined"
                                        />
                                      ))}
                                      <Tooltip title={t('INVOICE_EDIT_FORM_DELETE_VAT_LINE')}>
                                        <IconButton style={styles.deleteIconContainer} onClick={() => handleDeleteVatLine(i)}>
                                          <SvgIcon style={{ display: 'flex' }}>
                                            <DeleteIcon style={styles.deleteIcon} />
                                          </SvgIcon>
                                        </IconButton>
                                      </Tooltip>
                                    </Grid>
                                    {(vatLine.error || vatLine.invalid || vatLine.vatError) && (
                                      <Grid item xs={12} style={styles.errorRow}>
                                        <Typography style={styles.errorText}>
                                          {t(vatLine.invalid ? 'INVALID_NUMERIC_INPUT_FOR_INVOICE' : 'INVOICE_EDIT_FORM_VAT_DETAILS_INVALID_LINE')}
                                        </Typography>
                                      </Grid>
                                    )}
                                  </>
                                ))}
                                <Grid item xs={12} style={styles.inputItemRow}>
                                  <Button
                                    style={styles.action}
                                    variant="contained"
                                    onClick={handleAddVatLine}
                                  >
                                    <SvgIcon
                                      fontSize="small"
                                      style={styles.actionIcon}
                                    >
                                      <PlusCircleIcon />
                                    </SvgIcon>
                                    {ready && t('INVOICE_EDIT_FORM_ADD_VAT_LINE')}
                                  </Button>
                                </Grid>
                              </Grid>
                              )}
                            </Grid>
                            <Grid item xs={12} style={styles.inputItemRowEnd}>
                              <Grid style={{ height: '100%' }}>
                                <Grid style={styles.elem3} />
                                <Grid style={styles.elem4} />
                              </Grid>
                              <div style={styles.dividerDiv} />
                              <span style={styles.dividerSpan}>
                                <Button
                                  style={styles.vatDetailsBtn}
                                  onClick={() => setShowVatDetails(!showVatDetails)}
                                >
                                  <Typography style={styles.vatTableTitle}>{ready && t('INVOICE_EDIT_FORM_VAT_DETAILS')}</Typography>
                                  <div style={styles.dividerDivBtn} />
                                  <Grid style={styles.expandIconContainer}>
                                    {showVatDetails ? <ArrowDropUpIcon style={styles.expandIcon} /> : <ArrowDropDownIcon style={styles.expandIcon} />}
                                  </Grid>
                                </Button>
                              </span>
                            </Grid>
                          </Fragment>
                        );

                      case 'equivalenceSurchargeAmount':
                        return (
                          <Fragment key={key}>
                            <Tooltip title={t('INVOICE_EDIT_FORM_SURCHARGE_AMOUNT_TOOLTIP')}>
                              <Grid item xs={12} style={styles.vatDetailsContainerTop}>
                                <Grid>
                                  <Grid style={styles.elem1} />
                                  <Grid style={styles.elem2} />
                                </Grid>
                                <Autocomplete
                                  id="equivalenceSurchargeAmount"
                                  open={getIsOpen('equivalenceSurchargeAmount', dataType)}
                                  onOpen={() => setFieldOpen({ ...fieldOpen, [key]: true })}
                                  onClose={() => setFieldOpen({ ...fieldOpen, [key]: false })}
                                  required={isRequired}
                                  fullWidth
                                  freeSolo
                                  name="equivalenceSurchargeAmount"
                                  onChange={(e, v) => handleChangeMain(e, 'equivalenceSurchargeAmount', 'equivalence_surcharge_amount', v)}
                                  onBlur={handleBlur}
                                  onFocus={(e) => onFocusField(e, 'equivalenceSurchargeAmount', dataType)}
                                  value={details.equivalenceSurchargeAmount}
                                  options={detailsXML.equivalence_surcharge_amount
                                    ? detailsXML.equivalence_surcharge_amount
                                    : [invoice.equivalenceSurchargeAmount
                                      ? invoice.equivalenceSurchargeAmount
                                      : '']}
                                  getOptionLabel={(option) => option && option.value ? option.value : option}
                                  isOptionEqualToValue={(option) => option.value === (details.equivalenceSurchargeAmount
                                    ? details.equivalenceSurchargeAmount
                                    : detail.equivalence_surcharge_amount
                                      ? detail.equivalence_surcharge_amount
                                      : ' ')}
                                  disabled={invoice.status < 500 || !xml}
                                  renderInput={(params) => (
                                    <TextField
                                      inputRef={fieldRef.equivalenceSurchargeAmount}
                                      fullWidth
                                      error={getIsError('equivalenceSurchargeAmount', dataType) || totalSurchargeAmountError}
                                      helperText={(getIsError('equivalenceSurchargeAmount', dataType) ? t('INVALID_NUMERIC_INPUT_FOR_INVOICE')
                                        : totalSurchargeAmountError ? t('INVOICE_EDIT_FORM_SURCHARGE_DETAILS_INVALID_TOTAL') : '')}
                                      {...params}
                                      label={ready && t('INVOICE_EDIT_FORM_EQUIVALENCE_SURCHARGE_AMOUNT')}
                                      required={isRequired}
                                      variant="outlined"
                                      onChange={(e) => handleChangeText('equivalenceSurchargeAmount', e.target.value, 'equivalence_surcharge_amount')}
                                      onClick={(e) => onFocusField(e, 'equivalenceSurchargeAmount', dataType)}
                                      InputProps={{
                                        ...params.InputProps,
                                        style: {
                                          backgroundColor: textFieldSelectedKey === 'equivalenceSurchargeAmount'
                                            ? '#d7e7fc' : totalSurchargeAmountError
                                              ? '#fcc9a7' : ''
                                        }
                                      }}
                                      style={getFieldClassName(key, values)}
                                    />
                                  )}
                                />
                              </Grid>
                            </Tooltip>
                            <Grid style={styles.vatDetailsContainer}>
                              {isFieldAvailable('equivalenceSurchargeRate', (field2.isAvailable && field2.isActive)) && (
                                <Tooltip title={t('INVOICE_EDIT_FORM_SURCHARGE_RATE_TOOLTIP')}>
                                  <Grid item xs={12} style={styles.inputItem}>
                                    <Autocomplete
                                      id="equivalenceSurchargeRate"
                                      open={getIsOpen('equivalenceSurchargeRate', 'float')}
                                      onOpen={() => setFieldOpen({ ...fieldOpen, [key]: true })}
                                      onClose={() => setFieldOpen({ ...fieldOpen, [key]: false })}
                                      fullWidth
                                      freeSolo
                                      required={isRequired}
                                      name="equivalenceSurchargeRate"
                                      onChange={(e, v) => handleChangeMain(e, 'equivalenceSurchargeRate', 'equivalence_surcharge_rate', v)}
                                      onBlur={handleBlur}
                                      onFocus={(e) => onFocusField(e, 'equivalenceSurchargeRate', 'float')}
                                      value={details.equivalenceSurchargeRate}
                                      options={detailsXML.equivalence_surcharge_rate
                                        ? detailsXML.equivalence_surcharge_rate
                                        : [invoice.equivalenceSurchargeRate
                                          ? invoice.equivalenceSurchargeRate
                                          : '']}
                                      getOptionLabel={(option) => option && option.value ? option.value : option}
                                      isOptionEqualToValue={(option) => option.value === (details.equivalenceSurchargeRate
                                        ? details.equivalenceSurchargeRate
                                        : detail.equivalence_surcharge_rate
                                          ? detail.equivalence_surcharge_rate
                                          : ' ')}
                                      disabled={invoice.status < 500 || !xml}
                                      renderInput={(params) => (
                                        <TextField
                                          inputRef={fieldRef.equivalenceSurchargeRate}
                                          fullWidth
                                          error={getIsError('equivalenceSurchargeRate', 'float')}
                                          helperText={(getIsError('equivalenceSurchargeRate', 'float') && t('INVALID_NUMERIC_INPUT_FOR_INVOICE'))}
                                          {...params}
                                          label={ready && t('INVOICE_EDIT_FORM_EQUIVALENCE_SURCHARGE_RATE')}
                                          required={isRequired}
                                          variant="outlined"
                                          onChange={(e) => handleChangeText('equivalenceSurchargeRate', e.target.value, 'equivalence_surcharge_rate')}
                                          onClick={(e) => onFocusField(e, 'equivalenceSurchargeRate', 'float')}
                                          InputProps={{ ...params.InputProps }}
                                          style={getFieldClassName('equivalenceSurchargeRate', values)}
                                        />
                                      )}
                                    />
                                  </Grid>
                                </Tooltip>
                              )}
                              {showSurchargeDetails && (
                                <Grid innerRef={surchargeDetailsRef}>
                                  {esLines.map((esLine, i) => (
                                    <>
                                      <Grid style={styles.inputItemRowSpace}>
                                        {['baseAmount', 'esRate', 'esAmount'].map((vtlkey) => (
                                          <TextField
                                            key={`esLine_${i}_${vtlkey}`}
                                            id={`esLine_${i}_${vtlkey}`}
                                            error={!amountRegex.test(esLine[vtlkey]) || esLine.error}
                                            label={ready && t(esLineLabels[vtlkey])}
                                            name={`esLine_${i}_${vtlkey}`}
                                            onBlur={handleBlur}
                                            onFocus={(e) => onFocusField(e, `esLine_${i}_${vtlkey}`, 'float')}
                                            onClick={(e) => onFocusField(e, `esLine_${i}_${vtlkey}`, 'float')}
                                            onChange={(e) => handleChangeESLine(e, i, vtlkey)}
                                            style={
                                              textFieldSelectedKey === `esLine_${i}_${vtlkey}` ? styles.vatLineFieldHighlighted
                                                : (!amountRegex.test(esLine[vtlkey]) || esLine.error)
                                                  ? styles.vatLineFieldError : styles.vatLineField
                                            }
                                            value={esLine[vtlkey]}
                                            variant="outlined"
                                          />
                                        ))}
                                        <Tooltip title={t('INVOICE_EDIT_FORM_DELETE_SURCHARGE_LINE')}>
                                          <IconButton style={styles.deleteIconContainer} onClick={() => handleDeleteESLine(i)}>
                                            <SvgIcon style={{ display: 'flex' }}>
                                              <DeleteIcon style={styles.deleteIcon} />
                                            </SvgIcon>
                                          </IconButton>
                                        </Tooltip>
                                      </Grid>
                                      {(esLine.error || esLine.invalid) && (
                                        <Grid item xs={12} style={styles.errorRow}>
                                          <Typography style={styles.errorText}>
                                            {t(esLine.invalid ? 'INVALID_NUMERIC_INPUT_FOR_INVOICE' : 'INVOICE_EDIT_FORM_SURCHARGE_DETAILS_INVALID_LINE')}
                                          </Typography>
                                        </Grid>
                                      )}
                                    </>
                                  ))}
                                  <Grid item xs={12} style={styles.inputItemRow}>
                                    <Button
                                      style={styles.action}
                                      variant="contained"
                                      onClick={handleAddESLine}
                                    >
                                      <SvgIcon
                                        fontSize="small"
                                        style={styles.actionIcon}
                                      >
                                        <PlusCircleIcon />
                                      </SvgIcon>
                                      {ready && t('INVOICE_EDIT_FORM_ADD_SURCHARGE_LINE')}
                                    </Button>
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>
                            <Grid item xs={12} style={styles.inputItemRowEnd}>
                              <Grid style={{ height: '100%' }}>
                                <Grid style={styles.elem3} />
                                <Grid style={styles.elem4} />
                              </Grid>
                              <div style={styles.dividerDiv} />
                              <span style={styles.dividerSpan}>
                                <Button
                                  style={styles.surchargeDetailsBtn}
                                  onClick={() => setShowSurchargeDetails(!showSurchargeDetails)}
                                >
                                  <Typography style={styles.vatTableTitle}>{ready && t('INVOICE_EDIT_FORM_SURCHARGE_DETAILS')}</Typography>
                                  <div style={styles.dividerDivBtn} />
                                  <Grid style={styles.expandIconContainer}>
                                    {showSurchargeDetails ? <ArrowDropUpIcon style={styles.expandIcon} /> : <ArrowDropDownIcon style={styles.expandIcon} />}
                                  </Grid>
                                </Button>
                              </span>
                            </Grid>
                          </Fragment>
                        );

                      case 'senderVatNumber':
                        return (
                          <Grid key={key} item xs={12} style={styles.inputItem}>
                            <Grid style={styles.vatNumberRow}>
                              <Tooltip title={t('INVOICE_EDIT_FORM_SENDER_COUNTRY_CODE_TOOLTIP')}>
                                <Autocomplete
                                  id="senderCountryCode"
                                  required={isRequired}
                                  disableClearable
                                  style={styles.countryCodeField}
                                  classes={{
                                    paper: styles.countryCodePaper,
                                  }}
                                  name="senderCountryCode"
                                  onChange={(e, v) => handleChangeMain(e, 'senderCountryCode', 'sender_country_code', v)}
                                  onBlur={handleBlur}
                                  onFocus={(e) => onFocusField(e, 'senderVatNumber', 'text')}
                                  value={details.senderCountryCode ? countriesList.find((o) => o.isoCode === details.senderCountryCode) : null}
                                  options={countriesList}
                                  getOptionLabel={(option) => option.isoCode ? `${option.isoCode} | ${option.name}` : option.name}
                                  isOptionEqualToValue={(option) => option.isoCode === (details.senderCountryCode ? details.senderCountryCode : null)}
                                  disabled={invoice.status < 500 || !xml}
                                  renderInput={(params) => (
                                    <TextField
                                      fullWidth
                                      {...params}
                                      required={isRequired}
                                      variant="outlined"
                                      label={ready && t('INVOICE_EDIT_FORM_SENDER_COUNTRY_CODE')}
                                      InputProps={{ ...params.InputProps }}
                                      helperText={getHelperText('senderCountryCode', 'text')}
                                      onBlur={(e) => onVatNumberBlur(details.senderVatNumber, e.target.value?.slice(0, 2), setSenderVatNumberInvalid)}
                                      onClick={() => setTextFieldSelectedKey('')}
                                    />
                                  )}
                                />
                              </Tooltip>
                              <Tooltip title={t('INVOICE_EDIT_FORM_SENDER_VAT_NUM_TOOLTIP')}>
                                <Autocomplete
                                  id="senderVatNumber"
                                  open={getIsOpen('senderVatNumber', dataType)}
                                  onOpen={() => setFieldOpen({ ...fieldOpen, senderVatNumber: true })}
                                  onClose={() => setFieldOpen({ ...fieldOpen, senderVatNumber: false })}
                                  required={isRequired}
                                  fullWidth
                                  freeSolo
                                  name="senderVatNumber"
                                  onChange={(e, v) => handleChangeMain(e, 'senderVatNumber', 'sender_VAT_number', v)}
                                  onBlur={handleBlur}
                                  onFocus={(e) => onFocusField(e, 'senderVatNumber', dataType)}
                                  value={details.senderVatNumber}
                                  options={detailsXML.sender_VAT_number
                                    ? detailsXML.sender_VAT_number
                                    : [invoice.senderVatNumber
                                      ? invoice.senderVatNumber
                                      : '']}
                                  getOptionLabel={(option) => option && option.value ? option.value : option}
                                  isOptionEqualToValue={(option) => option.value === (details.senderVatNumber
                                    ? details.senderVatNumber
                                    : detail.sender_VAT_number
                                      ? detail.sender_VAT_number
                                      : ' ')}
                                  disabled={invoice.status < 500 || !xml}
                                  renderInput={(params) => (
                                    <TextField
                                      inputRef={fieldRef.senderVatNumber}
                                      fullWidth
                                      error={getIsError('senderVatNumber', dataType)}
                                      helperText={getHelperText('senderVatNumber', dataType)}
                                      {...params}
                                      label={ready && t('INVOICE_EDIT_FORM_SENDER_VAT_NUM')}
                                      required={isRequired}
                                      variant="outlined"
                                      onChange={(e) => handleChangeText('senderVatNumber', e.target.value, 'sender_VAT_number')}
                                      onClick={(e) => onFocusField(e, 'senderVatNumber', dataType)}
                                      onBlur={(e) => onVatNumberBlur(e.target.value, details.senderCountryCode, setSenderVatNumberInvalid)}
                                      InputProps={{ ...params.InputProps }}
                                      FormHelperTextProps={{ style: senderVatNumberInvalid ? styles.orangeHelperText : {} }}
                                      style={getFieldClassName('senderVatNumber', values)}
                                    />
                                  )}
                                />
                              </Tooltip>
                            </Grid>
                            {isTaxNumberSwitchAvailable(key, renderedFields)
                                && <Typography onClick={() => saveFieldsSelection('senderTaxNumber', 'senderVatNumber')} style={styles.taxNumberSwitchText}>
                                  {t('INVOICE_EDIT_SWITCH_TO_TAX_NUMBER')}
                                </Typography>}
                          </Grid>
                        );

                      case 'receiverVatNumber':
                        return (
                          <Grid key={key} item xs={12} style={styles.inputItem}>
                            {receiverVatLimitedOptions ? (
                              <Tooltip title={t('INVOICE_EDIT_FORM_RECEIVER_VAT_TOOLTIP')}>
                                <FormControl variant="outlined" style={styles.formControl}>
                                  <InputLabel id="receiver-vat-label">{ready && t('INVOICE_EDIT_FORM_RECEIVER_VAT')}</InputLabel>
                                  <Select
                                    id="receiverVatNumber"
                                    labelId="receiver-vat-label"
                                    label={ready && t('INVOICE_EDIT_FORM_RECEIVER_VAT')}
                                    required={isRequired}
                                    name="receiverVatNumber"
                                    onBlur={handleBlur}
                                    onFocus={(e) => onFocusField(e, 'receiverVatNumber', 'text')}
                                    onChange={(e) => handleCustomReceiver(e)}
                                    style={{
                                        ...styles.inputText,
                                        ...styles[(details.receiverVatNumber === values.receiverVatNumber || details.receiverVatNumber === undefined)
                                          ? 'root'
                                          : 'textFieldSelect']
                                    }}
                                    value={customReceivers.find(
                                      (r) => r.vatNumber === `${details.receiverCountryCode}${details.receiverVatNumber}`
                                        || r.vatNumber === `${details.receiverVatNumber}`
                                    )}
                                    disabled={invoice.status < 500 || !xml}
                                    MenuProps={{
                                      anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left'
                                      },
                                      transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left'
                                      },
                                      getContentAnchorEl: null
                                    }}
                                  >
                                    {
                                      customReceivers.map((s) => (
                                        <MenuItem style={styles.inputText} key={s.vatNumber} value={s}>
                                          {`${s.vatNumber} | ${s.company}`}
                                        </MenuItem>
                                      ))
                                    }
                                  </Select>
                                </FormControl>
                              </Tooltip>
                            ) : (
                              <Grid style={styles.vatNumberRow}>
                                <Tooltip title={t('INVOICE_EDIT_FORM_RECEIVER_COUNTRY_CODE_TOOLTIP')}>
                                  <Autocomplete
                                    id="receiverCountryCode"
                                    required={isRequired}
                                    disableClearable
                                    style={styles.countryCodeField}
                                    classes={{
                                      paper: styles.countryCodePaper,
                                    }}
                                    name="receiverCountryCode"
                                    onChange={(e, v) => handleChangeMain(e, 'receiverCountryCode', 'receiver_country_code', v)}
                                    onBlur={handleBlur}
                                    onFocus={(e) => onFocusField(e, 'receiverVatNumber', 'text')}
                                    value={details.receiverCountryCode ? countriesList.find((o) => o.isoCode === details.receiverCountryCode) : null}
                                    options={countriesList}
                                    getOptionLabel={(option) => option.isoCode ? `${option.isoCode} | ${option.name}` : option.name}
                                    isOptionEqualToValue={(option) => option.isoCode === (details.receiverCountryCode ? details.receiverCountryCode : null)}
                                    disabled={invoice.status < 500 || !xml}
                                    renderInput={(params) => (
                                      <TextField
                                        fullWidth
                                        {...params}
                                        required={isRequired}
                                        variant="outlined"
                                        label={ready && t('INVOICE_EDIT_FORM_RECEIVER_COUNTRY_CODE')}
                                        InputProps={{ ...params.InputProps }}
                                        helperText={getHelperText('receiverCountryCode', 'text')}
                                        onBlur={(e) => onVatNumberBlur(details.receiverVatNumber, e.target.value?.slice(0, 2), setReceiverVatNumberInvalid)}
                                        onClick={() => setTextFieldSelectedKey('')}
                                      />
                                    )}
                                  />
                                </Tooltip>
                                <Tooltip title={t('INVOICE_EDIT_FORM_RECEIVER_VAT_TOOLTIP')}>
                                  <Autocomplete
                                    id="receiverVatNumber"
                                    open={getIsOpen('receiverVatNumber', dataType)}
                                    onOpen={() => setFieldOpen({ ...fieldOpen, receiverVatNumber: true })}
                                    onClose={() => setFieldOpen({ ...fieldOpen, receiverVatNumber: false })}
                                    required={isRequired}
                                    fullWidth
                                    freeSolo
                                    name="receiverVatNumber"
                                    onChange={(e, v) => handleChangeMain(e, 'receiverVatNumber', 'receiver_VAT_number', v)}
                                    onBlur={handleBlur}
                                    onFocus={(e) => onFocusField(e, 'receiverVatNumber', dataType)}
                                    value={details.receiverVatNumber}
                                    options={detailsXML.receiver_VAT_number
                                      ? detailsXML.receiver_VAT_number
                                      : [invoice.receiverVatNumber
                                        ? invoice.receiverVatNumber
                                        : '']}
                                    getOptionLabel={(option) => option && option.value ? option.value : option}
                                    isOptionEqualToValue={(option) => option.value === (details.receiverVatNumber
                                      ? details.receiverVatNumber
                                      : detail.receiver_VAT_number
                                        ? detail.receiver_VAT_number
                                        : ' ')}
                                    disabled={invoice.status < 500 || !xml}
                                    renderInput={(params) => (
                                      <TextField
                                        inputRef={fieldRef.receiverVatNumber}
                                        fullWidth
                                        error={getIsError('receiverVatNumber', dataType)}
                                        helperText={getHelperText('receiverVatNumber', dataType)}
                                        {...params}
                                        label={ready && t('INVOICE_EDIT_FORM_RECEIVER_VAT')}
                                        required={isRequired}
                                        variant="outlined"
                                        onChange={(e) => handleChangeText('receiverVatNumber', e.target.value, 'receiver_VAT_number')}
                                        onClick={(e) => onFocusField(e, 'receiverVatNumber', dataType)}
                                        onBlur={(e) => onVatNumberBlur(e.target.value, details.receiverCountryCode, setReceiverVatNumberInvalid)}
                                        InputProps={{ ...params.InputProps }}
                                        FormHelperTextProps={{ style: receiverVatNumberInvalid ? styles.orangeHelperText : {} }}
                                        style={getFieldClassName(key, values)}
                                      />
                                    )}
                                  />
                                </Tooltip>
                              </Grid>
                            )}
                            {isTaxNumberSwitchAvailable(key, renderedFields)
                              && <Typography onClick={() => saveFieldsSelection('receiverTaxNumber', 'receiverVatNumber')} style={styles.taxNumberSwitchText}>
                                {t('INVOICE_EDIT_SWITCH_TO_TAX_NUMBER')}
                              </Typography>}
                          </Grid>
                        );

                      case 'countryIssued':
                        return (
                          <Grid key={key} item xs={12} style={styles.inputItem}>
                            <Autocomplete
                              id="countryIssued"
                              required={isRequired}
                              fullWidth
                              name="countryIssued"
                              onChange={(e, v) => handleChangeMain(e, 'countryIssued', 'country', v)}
                              onBlur={handleBlur}
                              onFocus={(e) => onFocusField(e, key, dataType)}
                              value={details.countryIssued ? countriesList.find((o) => o.isoCode === details.countryIssued) : null}
                              options={countriesList}
                              getOptionLabel={(option) => option.isoCode ? `${option.isoCode} | ${option.name}` : option.name}
                              isOptionEqualToValue={(option) => option.isoCode === (details.countryIssued ? details.countryIssued : null)}
                              disabled={invoice.status < 500 || !xml}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputRef={fieldRef[key]}
                                  fullWidth
                                  error={getIsError(key, dataType)}
                                  helperText={getHelperText(key, dataType)}
                                  required={isRequired}
                                  variant="outlined"
                                  label={ready && t(label)}
                                  InputProps={{ ...params.InputProps }}
                                  style={getFieldClassName(key, values)}
                                  onClick={() => setTextFieldSelectedKey('')}
                                />
                              )}
                            />
                          </Grid>
                        );

                      default:
                        return (
                          <Tooltip key={key} title={invoiceTooltips[key] ? t(invoiceTooltips[key]) : ''}>
                            <Grid item xs={12} style={styles.inputItem}>
                              <Autocomplete
                                id={key}
                                open={getIsOpen(key, dataType)}
                                onOpen={() => setFieldOpen({ ...fieldOpen, [key]: true })}
                                onClose={() => setFieldOpen({ ...fieldOpen, [key]: false })}
                                required={isRequired}
                                fullWidth
                                freeSolo
                                name={key}
                                onChange={(e, v) => handleChangeMain(e, key, assistantKey, v)}
                                onBlur={handleBlur}
                                onFocus={(e) => onFocusField(e, key, dataType)}
                                value={details[key]}
                                options={detailsXML[assistantKey] ? detailsXML[assistantKey] : [invoice[key] ? invoice[key] : '']}
                                getOptionLabel={(option) => option && option.value ? option.value : option}
                                isOptionEqualToValue={(option) => option.value === (details[key]
                                  ? details[key]
                                  : detail[assistantKey]
                                    ? detail[assistantKey]
                                    : ' ')}
                                disabled={invoice.status < 500 || !xml}
                                renderInput={(params) => (
                                  <TextField
                                    inputRef={fieldRef[key]}
                                    fullWidth
                                    error={getIsError(key, dataType)}
                                    helperText={getHelperText(key, dataType)}
                                    FormHelperTextProps={{
                                      style: (key === 'amount' && !isAmountTallyingWithLines(details, decimalSeparator))
                                        ? styles.orangeHelperText
                                        : {},
                                    }}
                                    required={isRequired}
                                    {...params}
                                    label={ready && `${t(label)} ${isRequired ? '*' : ''}`}
                                    variant="outlined"
                                    onChange={(e) => handleChangeText(key, e.target.value, assistantKey)}
                                    onClick={(e) => onFocusField(e, key, dataType)}
                                    InputProps={{ ...params.InputProps }}
                                    style={getFieldClassName(key, values)}
                                  />
                                )}
                              />
                              {isTaxNumberSwitchAvailable(key, renderedFields)
                                && <Typography onClick={() => saveFieldsSelection(key.replace('Tax', 'Vat'), key)} style={styles.taxNumberSwitchText}>
                                  {t('INVOICE_EDIT_SWITCH_TO_VAT_NUMBER')}
                                </Typography>}
                            </Grid>
                          </Tooltip>
                        );
                    }
                  })}
                  {isTwinfield && (invoice.status === 503 || isSpreadFieldsNotEmpty()) && (
                    <Grid style={styles.spreadInvoiceContainer}>
                      <FormControlLabel
                        value="top"
                        control={(
                          <Checkbox
                            style={styles.checkBox}
                            checked={spreadInvoice || isSpreadFieldsNotEmpty() || spreadSuccess}
                            onChange={handleSpreadInvoiceChange}
                          />
                        )}
                        label={ready && t('INVOICE_EDIT_FORM_SPREAD_INVOICE')}
                        labelPlacement="start"
                        style={styles.checkBoxContainer}
                        classes={{ label: styles.checkBoxLabel }}
                      />
                    </Grid>
                  )}
                  {(spreadInvoice || isSpreadFieldsNotEmpty()) && (
                    <SpreadInvoice
                      enqueueSnackbar={enqueueSnackbar}
                      t={t}
                      ready={ready}
                      invoice={invoice}
                      details={details}
                      noWhiteSpace={noWhiteSpace}
                      validation={validation}
                      handleBlur={handleBlur}
                      glAccounts={glAccounts}
                      isSpreadFieldsNotEmpty={isSpreadFieldsNotEmpty}
                      spreadSuccess={spreadSuccess}
                      setSpreadSuccess={setSpreadSuccess}
                    />
                  )}
                </Grid>
                <Grid item xs={12} style={styles.manageFieldsContainer}>
                  {isShowManageFields(user, userDocType === 'id' ? 'applyParentFieldsIDs' : 'applyParentFieldsHeaders') && (
                    <Button
                      variant="outlined"
                      style={styles.manageFieldsBtn}
                      onClick={handleOpenManageFields}
                    >
                      <SettingsIcon style={styles.btnIcon} />
                      {ready && t('INVOICE_EDIT_FORM_MANAGE_FIELDS')}
                    </Button>
                  )}
                  {invoice?.attachedDocuments?.length > 0 && (
                    <AttachedDocuments
                      t={t}
                      handleSplitBtnClick={handleShowAttachments}
                      splitButtonRef={splitButtonRef}
                      setMouseLeftSplitScrnBtn={setMouseLeftSplitScrnBtn}
                      handleMouseLeaveSplitScrnBtn={handleMouseLeaveSplitScrnBtn}
                      invoice={invoice}
                      attachmentsOpen={attachmentsOpen}
                      setMouseLeftAttachmentsTooltip={setMouseLeftAttachmentsTooltip}
                      handleMouseLeaveAttachmentsTooltip={handleMouseLeaveAttachmentsTooltip}
                      handleAttachmentsClose={handleAttachmentsClose}
                      handleAttachmentClick={handleAttachmentClick}
                    />
                  )}
                </Grid>
                {renderButtons(touched)}
                {renderNextAndPrevious()}
              </Grid>
            </Grid>
            <Hidden mdUp>
              {hasLines && displayLines()}
            </Hidden>
            <Grid item xs={12} md={8} style={styles.invoiceContainer}>
              {xmlReceived && (xml !== null
                ? renderInvoiceXML(xmlSelectedValue, setXmlSelectedValue, textFieldSelectedValue, scrollXMLViewBy, false, textFieldSelectedKey)
                : renderInvoicePDF())}
            </Grid>
            <Hidden smDown>
              {hasLines && displayLines()}
            </Hidden>
          </Grid>
          <Backdrop style={styles.backdrop} open={!xmlReceived || loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <InvoiceLineEditForm
            fields={lineFields}
            getFields={getLineFieldsForManageFields}
            user={user}
            editLine={editLine}
            handleClose={() => setEditLineOpen(false)}
            onGetInvoice={getInvoice}
            glas={glAccounts}
            vatGroups={vatGroups}
            costCenters={costCenters}
            projects={projects}
            onUpdateLine={onUpdateLine}
            isTwinfield={isTwinfield}
            renderInvoicePDF={renderInvoiceXML}
            editLineOpen={editLineOpen}
            setEditLineOpen={setEditLineOpen}
            getAllGLAccounts={getAllGLAccounts}
            getAllVatCodes={getAllVatCodes}
            getAllCostCenters={getAllCostCenters}
            glaYears={glaYears}
            glaPeriods={glaPeriods}
            parameterCompany={parameterCompany}
            senderVatNumber={details.senderVatNumber}
            scrollXMLViewBy={scrollXMLViewBy}
            supplier={supplier}
            updateAnnotateFieldsTime={updateAnnotateFieldsTime}
            setAnnotateSelectedField={setAnnotateSelectedField}
            setAnnotateFieldStart={setAnnotateFieldStart}
            decimalSeparator={decimalSeparator}
          />
          <InvoiceLineAddForm
            fields={lineFields}
            getFields={getLineFieldsForManageFields}
            user={user}
            handleClose={() => setAddLineOpen(false)}
            onGetInvoice={getInvoice}
            glas={glAccounts}
            vatGroups={vatGroups}
            costCenters={costCenters}
            projects={projects}
            onAddLine={onAddLineConfirm}
            supplier={supplier}
            description={lineDescription}
            isTwinfield={isTwinfield}
            linesLength={details?.lines?.length || 0}
            renderInvoicePDF={renderInvoiceXML}
            addLineOpen={addLineOpen}
            setAddLineOpen={setAddLineOpen}
            getAllGLAccounts={getAllGLAccounts}
            getAllVatCodes={getAllVatCodes}
            getAllCostCenters={getAllCostCenters}
            glaYears={glaYears}
            glaPeriods={glaPeriods}
            parameterCompany={parameterCompany}
            senderVatNumber={details.senderVatNumber}
            scrollXMLViewBy={scrollXMLViewBy}
            updateAnnotateFieldsTime={updateAnnotateFieldsTime}
            setAnnotateSelectedField={setAnnotateSelectedField}
            setAnnotateFieldStart={setAnnotateFieldStart}
            decimalSeparator={decimalSeparator}
          />
          <DeleteLineModal
            deleteLineOpen={deleteLineOpen}
            deleteLine={deleteLine}
            onDeleteLineConfirm={onDeleteLineConfirm}
            onDeleteLineClose={onDeleteLineClose}
          />
          <CreateSupplierModal
            addSupplierOpen={addSupplierOpen}
            handleClose={handleCloseSupplierModal}
            newSupplier={newSupplier}
            onSupplierCreated={onSupplierCreated}
            isInvoiceEditForm
            renderInvoiceXML={renderInvoiceXML}
            scrollXMLViewBy={scrollXMLViewBy}
          />
          <EditSupplierModal
            editSupplierOpen={editSupplierOpen}
            setEditSupplierOpen={setEditSupplierOpen}
            supplierId={selectedSupplierId}
            handleEditSupplierClose={handleEditSupplierClose}
            getAllSuppliers={findAllSuppliers}
            isInvoiceEditForm
            renderInvoiceXML={renderInvoiceXML}
            scrollXMLViewBy={scrollXMLViewBy}
            company={parameterDocCompany}
          />
          <ExportToEmailModal
            sendEmailModalOpen={sendEmailModalOpen}
            handleSendEmailModalClose={handleSendEmailModalClose}
            emails={emails}
            setEmails={setEmails}
            dataFormatOptionsToEmailRef={dataFormatOptionsToEmailRef}
            handleDataformatOptionsToEmailToggle={handleDataformatOptionsToEmailToggle}
            getDeliverFormat={getDeliverFormat}
            selectedDataFormatToEmail={selectedDataFormatToEmail}
            deliverFormats={deliverFormats}
            dataFormatOptionsToEmailOpen={dataFormatOptionsToEmailOpen}
            handleDataformatOptionsToEmailClose={handleDataformatOptionsToEmailClose}
            handleDataFormatToEmailClick={handleDataFormatToEmailClick}
            handleSendToEmailClick={handleSendToEmailClick}
          />
          <ManageFields
            open={showManageFields}
            documentType={userDocType}
            section="header"
            fields={headerFields}
            docID={invoice.assistantID}
            fetchFields={getFieldsForManageFields}
            closeModal={handleCloseManageFields}
            parameterCompany={parameterCompany}
          />
          <ManageExportFieldMappings
            format={exportFormat}
            exportFieldMappingsOpen={showExportFieldMapping}
            onModalClose={handleExportFieldMappingClose}
            loading={loading}
            fields={headerFields}
            lineFields={lineFields}
            supplierFields={supplierFields}
            excelFieldMapping={excelExportFieldMapping}
            datFieldMapping={datExportFieldMapping}
            csvFieldMapping={csvExportFieldMapping}
          />
        </form>
      )}
    </Formik>
  );
}

export default InvoiceEditForm;
