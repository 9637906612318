import React,{ useEffect, useState, useContext, useRef, useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import Axios from 'axios';

import { Grid, Box } from '@mui/material';

import ConfigContext from 'src/contexts/ConfigContext';
import authService from 'src/utils/authService';
import { useAuth } from 'src/hooks/useAuth';
import {
  appendContactSupport, axiosHeaders, calculateBaseAmount, calculateDiscountRate, getLocalisedErrorString, sendFeedback
} from 'src/utils/helpers';
import {
  lineColumnsReprocess, userRoles,
} from 'src/config';
import {
  CustomError, FieldType, DocType, RootState, UserDataType, FieldRuleType,
} from 'src/types';
import CDCDocFields from 'src/cdc-document-edit-new/components/CDCDocFields/CDCDocFields';
import InfoModal from 'src/document-edit/components/InfoModal/InfoModal';
import DeleteDocumentModal from 'src/document-edit/components/DeleteDocumentModal/DeleteDocumentModal';
import ChooseValidatorModal from 'src/document-edit/components/ChooseValidatorModal/ChooseValidatorModal';
import PdfViewer from 'src/document-edit/components/PdfViewer/PdfViewer';
import { StatusChangeRequest } from 'src/document-edit/documentTypes';
import {
  annotateTimeVatEsMap, formatFieldTrustScores, getDocumentEditFormValues, getIsError,
  initializeAnnotateFieldTimes, getDocumentEditFormCandidates, getDocumentEditFormXMLValues,
  getFieldTrustScores,
} from 'src/document-edit/utils';
import { IHighlight } from 'src/pdf-highligher';
import useWidth from 'src/hooks/useWidth';
import styles from './style';

const metrics: string[] = [
  'fat', 'protein', 'cell', 'bact', 'adj', 'lab', 'cuota'
];

type PropsType ={
  id:string;
  handleClose?:Function;
  setLoading:(_loading: boolean) => void;
  moveNext?:Function;
  movePrevious?:Function;
  disableMove?:boolean[];
  setLoadingPdf?:Function;
}

const CDCDocumentEdit = ( props: PropsType) => {
  const{
    id,
    handleClose,
    setLoading,
    moveNext,
    movePrevious,
    disableMove,
  } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();

  const { API, LOGIN_PANEL_URL } = useContext(ConfigContext);
  const { user } = useAuth();

  const leadingWhiteSpace = /^\s+.+$/;

  const [isAutoNavigationAllowed, setIsAutoNavigationAllowed] = useState(user?.customisations.includes('autoNavigation') || false);
  const [decimalSeparator, setDecimalSeparator] = useState(user?.monetaryDecimalSeparator || '.');
  const [dateFieldRule, setDateFieldRule] = useState(user?.fieldRules?.find((fr) => fr.field === 'documentDate'));

  const resizeRef = useRef<HTMLDivElement | null>(null)

  const _headerFields: FieldType[] = useSelector((state: RootState) => state.fields.headerFields);
  const headerFields = useMemo(() => _headerFields, [_headerFields]);

  const _lineFields: FieldType[] = useSelector((state: RootState) => state.fields.lineFields);
  const lineFields = useMemo(() => _lineFields, [_lineFields]);

  const [doc, setDoc] = useState<DocType>({ id: 0, status: 0, lines: [] });
  const [xml, setXml] = useState<string>('');
  const [details, _setDetails] = useState<Record<string, string>>({
    ...headerFields.reduce((acc, item) => {
      acc[item.key] = '';
      return acc;
    }, {} as Record<string, string>),
  });
  const detailsRef = useRef(details);
  const setDetails = (val: Record<string, string>) => {
    detailsRef.current = val;
    _setDetails(val);
  };
  const [detailsXML, setDetailsXML] = useState<Record<string, string[]>>({
    ...headerFields.reduce((acc, item) => {
      if (item.assistantKey) {
        acc[item.assistantKey] = [];
      } else {
        acc[item.key] = [];
      }
      return acc;
    }, {} as Record<string, string[]>),
  });
  const [detailsCandidates, setDetailsCandidates] = useState<Record<string, string[]>>(detailsXML);
  const [tags, setTags] = useState<string[]>(doc.documentTags?.split(',') || []);

  const [previousInvoice, setPreviousInvoice] = useState<DocType | null>(null);
  const [nextInvoice, setNextInvoice] = useState<DocType | null>(null);
  const [selectedValidator, setSelectedValidator] = useState<UserDataType | null>(null);
  const [newlyAddedUser, setNewlyAddedUser] = useState<UserDataType | null>(null);
  const [users, setUsers] = useState<UserDataType[]>([]);
  const [renderedFields, _setRenderedFields] = useState<FieldType[]>([]);
  const renderedFieldsRef = useRef(renderedFields);
  const setRenderedFields = (val: FieldType[]) => {
    renderedFieldsRef.current = val;
    _setRenderedFields(val);
  };

  const [fieldTrustScores, _setFieldTrustScores] = useState<Record<string, number>>(headerFields.reduce((acc, item) => {
    acc[item.key] = -1;
    return acc;
  }, {} as Record<string, number>));
  const fieldTrustScoresRef = useRef(fieldTrustScores);
  const setFieldTrustScores = (val: Record<string, number>) => {
    fieldTrustScoresRef.current = val;
    _setFieldTrustScores(val);
  };
  const [annotateFieldsTime, _setAnnotateFieldsTime] = useState<Record<string, number>>({});
  const annotateFieldsTimeRef = useRef(annotateFieldsTime);
  const setAnnotateFieldsTime = (val: Record<string, number>) => {
    annotateFieldsTimeRef.current = val;
    _setAnnotateFieldsTime(val);
  };
  const [invoiceAnnotateStart, setInvoiceAnnotateStart] = useState<number | null>(null);
  const [invoiceAnnotateTime, setInvoiceAnnotateTime] = useState(0);
  const [annotateSelectedField, setAnnotateSelectedField] = useState<string | null>(null);
  const [annotateFieldStart, setAnnotateFieldStart] = useState<number | null>(null);

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [statusChangeLoading, setStatusChangeLoading] = useState<boolean>(false);
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const [openDeleteConf, setOpenDeleteConf] = useState<boolean>(false);
  const [chooseValidatorOpen, setChooseValidatorOpen] = useState<boolean>(false);
  const [isSubmitClicked, _setIsSubmitClicked] = useState<boolean>(true);
  const isSubmitClickedRef = useRef(isSubmitClicked);
  const setIsSubmitClicked = (val: boolean) => {
    isSubmitClickedRef.current = val;
    _setIsSubmitClicked(val);
  };

  const [highlights, _setHighlights] = useState<Array<IHighlight>>([]);
  const highlightsRef = useRef(highlights);
  const setHighlights = (val: Array<IHighlight>) => {
    highlightsRef.current = val;
    _setHighlights(val);
  };
  const [selectedTextField, _setSelectedTextField] = useState<FieldType>({
    key: '',
    assistantKey: '',
    label: '',
    appDbField: '',
    dataType: '',
    exportMapKey: '',
    position: -1,
  });
  const selectedTextFieldRef = useRef(selectedTextField);
  const setSelectedTextField = (val: FieldType) => {
    selectedTextFieldRef.current = val;
    _setSelectedTextField(val);
  };

  const statusChangedRef: React.MutableRefObject<boolean | null> = useRef<boolean>(null);
  // view height - pdf actions height (60px) - padding (24px)
  const [pdfViewerHeight] = useState(`calc(100vh - 84px)`);
  const fieldsContainerRef = useRef<HTMLDivElement>(null);
  const fieldsContainerWidth = useWidth(fieldsContainerRef, 0);
  const pdfViewerWidth = `calc(100vw - 65px - ${fieldsContainerWidth}px - 4px)`;
  const [isMouseDown, _setIsMouseDown] = useState<boolean>(false);
  const isMouseDownRef = useRef(isMouseDown);
  const setIsMouseDown = (val: boolean) => {
    isMouseDownRef.current = val;
    _setIsMouseDown(val);
  };

  const moveToNextRef = useRef<number | null>(null);
  const setMoveToNext = (val: number | null) => {
    moveToNextRef.current = val;
  };
  const tipOriginalValue = useRef<string>('');

  const setTipOriginalValue = (val: string) => {
    tipOriginalValue.current = val;
  };

  const updateSelectedValidator = (newValidator: UserDataType) => {
    setSelectedValidator(newValidator);
    setNewlyAddedUser(null);
  };

  const handleChooseValidatorModalOpen = () => {
    setChooseValidatorOpen(!chooseValidatorOpen);
    pauseInvoiceAnnotateTime();
  };

  const handleChooseValidatorModalClose = () => {
    setChooseValidatorOpen(false);
    restartInvoiceAnnotateTime();
  };

  const getCompanyCustomisations = async (companyID: string) => {
    try {
      const resp = await Axios.get(
        `${API.customisations}/${companyID}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (resp.data.success) {
        const { data } = resp.data;
        if (data.customisations?.length) {
          setIsAutoNavigationAllowed(data.customisations.includes('autoNavigation') || false);
        }
        if (data.fieldRules?.length) {
          setDateFieldRule(data.fieldRules?.find((fr: FieldRuleType) => fr.field === 'documentDate'));
        }
        const _monetaryDecimalSeparator = data.monetaryDecimalSeparator || '.';
        setDecimalSeparator(_monetaryDecimalSeparator);
      }
    } catch (e) {
      // failed
    }
  };

  const getDocument = async () => {
    try {
      const resp = await Axios.get(
        `${API.getDocumentByID}${id}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (resp.data.success) {
        if (user?.userRole === userRoles.annotator) {
          getCompanyCustomisations(resp.data.data.company);
        }
        setDoc(resp.data.data);
        setLoading(false);

        if (resp.data.data.status === 500) {
          sendFeedback(API.feedbackLogs, 'work with invoice', user?.email, user?.companyID);
        }
      } else {
        navigate('/documents');
      }
    } catch (e) {
      const error = e as CustomError;
      if (error?.response?.data?.i18n) {
        enqueueSnackbar(getLocalisedErrorString(error.response.data.i18n, t), {
          variant: 'error',
          autoHideDuration: 5000
        });
      }
      navigate('/documents');
    }
  };

  const getOtherInvoice = async (invoicePage: number) => {
    const queryVal = new URLSearchParams(location.search);
    const thisSearch = queryVal.get('query');
    const thisFilter = queryVal.get('status');
    const thisTime = queryVal.get('time');
    const supplierFilter = JSON.parse(queryVal.get('supplier_filter') || 'false');
    const suppliersList = JSON.parse(window.sessionStorage.getItem('selectedSuppliers') || '[]');
    const ownerFilter = JSON.parse(queryVal.get('owner_filter') || 'false');
    const ownersList = JSON.parse(window.sessionStorage.getItem('selectedOwners') || '[]');
    const companyFilter = JSON.parse(queryVal.get('company_filter') || 'false');
    const companiesList = JSON.parse(window.sessionStorage.getItem('selectedCompanies') || '[]');
    const thisStartTime = queryVal.get('start_time');
    const thisEndTime = queryVal.get('end_time');
    const failedToExport = JSON.parse(queryVal.get('failed_to_export') || 'false');
    const sortBy = queryVal.get('sort_by');
    const order = queryVal.get('order');
    let thisSuppliers = '';
    if (supplierFilter && Array.isArray(suppliersList)) {
      thisSuppliers = suppliersList.map((supplier) => supplier.creditorCode).join(',');
    } else if (supplierFilter && suppliersList) {
      thisSuppliers = suppliersList;
    }
    let thisOwners = '';
    if (ownerFilter && Array.isArray(ownersList)) {
      thisOwners = ownersList.map((owner) => owner.email).join(',');
    } else if (ownerFilter && ownersList) {
      thisOwners = ownersList;
    }
    let thisCompanies = [];
    if (companyFilter && companiesList.length > 0 && companiesList[0].companyId) {
      thisCompanies = companiesList.map((company: {name: string, companyId: string}) => company.companyId);
    } else if (companyFilter && companiesList.length > 0) {
      thisCompanies = companiesList;
    }
    try {
      let url = `${API.getAllInvoices}/${encodeURIComponent(user?.companyID || '')}?page=${invoicePage}&limit=1${thisSearch !== null
        ? `&search=${thisSearch}` : ''}&order=${sortBy
        ? `${order}&sortby=${sortBy}` : 'desc'}${thisFilter !== null
        ? `&filter=${thisFilter}` : ''}${failedToExport
        ? '&failedToExport=true' : ''}`;
      if (thisStartTime !== null && thisEndTime !== null) {
        url = `${url}&startTime=${thisStartTime}&endTime=${thisEndTime}`;
      } else {
        url = `${url}${thisTime !== null ? `&time=${thisTime}` : ''}`;
      }
      const body = { suppliers: thisSuppliers, owners: thisOwners, companies: thisCompanies };
      const response = await Axios.post(url, body, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (response.data.success && response.data.data.length > 0) {
        return response.data.data[0];
      }
      return null;
    } catch (error) {
      return null;
    }
  };

  const fetchPrevAndNext = async () => {
    const queryVal = new URLSearchParams(location.search);
    const invoicePage = queryVal.get('document_page');
    const total = parseInt(queryVal.get('total') || '1', 10);
    if (invoicePage) {
      const currentInvoice = parseInt(invoicePage, 10);
      if (currentInvoice > 1) {
        const previousInvoice = await getOtherInvoice(currentInvoice - 1);
        setPreviousInvoice(previousInvoice);
      }
      if (currentInvoice < total) {
        const nextInvoice = await getOtherInvoice(currentInvoice + 1);
        setNextInvoice(nextInvoice);
      }
    }
  };

  const getXML = async () => {
    try {
      const xmlPage = await Axios.get(
        `${API.getDocumentXML}${id}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (xmlPage.data.success && xmlPage.data.data) {
        setXml(xmlPage.data.data.xml);
        if (xmlPage.data.data.candidates) {
          const newDetailsXml = getDocumentEditFormXMLValues(headerFields, xmlPage.data.data.xml);
          setDetailsXML(newDetailsXml);
          setDetailsCandidates(getDocumentEditFormCandidates(headerFields, newDetailsXml, detailsRef.current));
        }
      } else {
        setXml('');
      }
    } catch (error) {
      setXml('');
    }
  };

  const getUsers = async () => {
    try {
      const url = `${API.getUsersByCompanies}${encodeURIComponent(user?.companyID || '')}?permission=invoice_validate`;
      const resp = await Axios.get(url, axiosHeaders(localStorage.getItem('PROCYS_accessToken')));
      if (resp.data.success) {
        setUsers(resp.data.data);
        const newUser = resp.data.data?.find((u: UserDataType) => u.email === newlyAddedUser?.email);
        const invoiceOwner = resp.data.data?.find((u: UserDataType) => u.email === doc?.owner);
        if (newlyAddedUser && newUser) {
          updateSelectedValidator(newUser);
        } else if (invoiceOwner) {
          updateSelectedValidator(invoiceOwner);
        } else {
          updateSelectedValidator(resp.data.data[0]);
        }
      }
    } catch (error) {
      //
    }
  };

  const loadData = async () => {
    getXML();
    await getDocument();
    fetchPrevAndNext();
    await getUsers();
    setAnnotateFieldsTime(initializeAnnotateFieldTimes([...headerFields, ...lineFields].filter((f) => f.isActive || f.isMandatory)));
    setInvoiceAnnotateStart(new Date().getTime());
  };

  const updateAnnotateFieldsTime = () => {
    if (annotateSelectedField && annotateFieldStart) {
      if (annotateFieldsTime[annotateSelectedField] !== undefined) {
        setAnnotateFieldsTime({
          ...annotateFieldsTime,
          [annotateSelectedField]:
              annotateFieldsTime[annotateSelectedField] + (new Date().getTime() - annotateFieldStart)
        });
      }
    }
  };

  const pauseInvoiceAnnotateTime = () => {
    if (invoiceAnnotateStart) {
      const timeSpent = invoiceAnnotateTime + (new Date().getTime() - invoiceAnnotateStart);
      setInvoiceAnnotateTime(timeSpent);
      setInvoiceAnnotateStart(null);
    }
    updateAnnotateFieldsTime();
    setAnnotateSelectedField(null);
    setAnnotateFieldStart(null);
  };

  const restartInvoiceAnnotateTime = () => {
    setInvoiceAnnotateStart(new Date().getTime());
  };

  const revertTipText = (key: string, dataType: string, value: string) => {
    let val = value;
    if (!value) {
      return;
    }
    const isErrorVal = getIsError(key, dataType, value, user, decimalSeparator);
    if (isErrorVal) {
      val = '';
    }
    handleChangeMain(selectedTextFieldRef.current, val);

    if (val !== '') {
      setFieldTrustScores({
        ...fieldTrustScoresRef.current,
        [key]: 2
      });
    }
  };

  const calculateFieldTrustscores = useCallback(() => {
    setFieldTrustScores(getFieldTrustScores(headerFields, doc.trustScores));
  }, [headerFields, doc]);

  useEffect(() => {
    calculateFieldTrustscores();
  }, [calculateFieldTrustscores]);

  useEffect(() => {
    if (annotateSelectedField && annotateFieldStart) {
      if (annotateFieldsTime[annotateSelectedField] !== undefined) {
        setAnnotateFieldsTime({
          ...annotateFieldsTime,
          [annotateSelectedField]: annotateFieldsTime[annotateSelectedField] + (new Date().getTime() - annotateFieldStart)
        });
      }
    }
    if (selectedTextField.key === '') {
      setAnnotateSelectedField(null);
      setAnnotateFieldStart(null);
    } else {
      if (selectedTextField.key.includes('VATLine') || selectedTextField.key.includes('ESLine')) {
        const splitted = selectedTextField.key.split('_');
        setAnnotateSelectedField(annotateTimeVatEsMap[`${splitted[0]}${splitted[2]}`]);
      } else if (selectedTextField.key.startsWith('line_')) {
        const splitted = selectedTextField.key.split('_');
        setAnnotateSelectedField(`${splitted[2]}Time`);
      } else {
        setAnnotateSelectedField(`${selectedTextField.key}Time`);
      }
      setAnnotateFieldStart(new Date().getTime());
    }
  }, [selectedTextField.key]);

  useEffect(() => {
    if(id){
      setLoading(true);
      if (!authService.validateToken()) {
        enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
          variant: 'error',
          autoHideDuration: 5000
        });
        setTimeout(() => {
          authService.logout(LOGIN_PANEL_URL);
        }, 2000);
        return;
      }
      loadData();
    }
  }, [id]);

  useEffect(() => {
    if (headerFields.length > 0) {
      setDetails(getDocumentEditFormValues(user, doc, headerFields));
      setDetailsCandidates(getDocumentEditFormCandidates(headerFields, detailsXML, getDocumentEditFormValues(user, doc, headerFields)));
    }
    setTags(doc.documentTags?.split(',').map((t: string) => t.trim()) || []);
  }, [doc, headerFields]);

  useEffect(() => {
    const renderedFieldIds = renderedFields.map((f) => f.key);
    const renderedLineFieldIds: string[] = [];

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        revertTipText(selectedTextFieldRef.current.key, selectedTextFieldRef.current.dataType, tipOriginalValue.current);
        setSelectedTextField({
          key: '',
          assistantKey: '',
          label: '',
          appDbField: '',
          dataType: '',
          exportMapKey: '',
          position: -1,
        });
      } else if (e.key === 'Tab') {
        e.preventDefault();
        revertTipText(selectedTextFieldRef.current.key, selectedTextFieldRef.current.dataType, tipOriginalValue.current);
        const currKey = selectedTextFieldRef.current.key;
        const index = renderedFieldsRef.current.findIndex((field) => field.key === currKey);
        if (index === -1 || (e.shiftKey && index === 0) || (!e.shiftKey && index === renderedFieldsRef.current.length - 1)) {
          setSelectedTextField({
            key: '',
            assistantKey: '',
            label: '',
            appDbField: '',
            dataType: '',
            exportMapKey: '',
            position: -1,
          });
          return;
        }
        let newIndex = index + 1;
        if (e.shiftKey) {
          newIndex = index - 1;
        }
        const field = renderedFieldsRef.current[newIndex];

        const element = document.getElementById(field.key);
        if (element) element.focus();
        setSelectedTextField(field);
      }
    };

    const handleClick = (e: MouseEvent) => {
      const target = e.target as HTMLInputElement;
      if (!(target?.id.includes('highlight-')
        || lineColumnsReprocess.includes(target?.id)
        || ["tip__textarea",
          "Tip__submit",
          "Tip__cancel",
          "PdfHighlighter",
          ...renderedFieldIds,
          ...renderedLineFieldIds,
        ].some((elemId) => elemId === target?.id))) {
        if (!getIsError(
          selectedTextFieldRef.current.key,
          selectedTextFieldRef.current.dataType,
          detailsRef.current[selectedTextFieldRef.current.key],
          user,
          decimalSeparator,
        )) {
          setFieldTrustScores({
            ...fieldTrustScoresRef.current,
            [selectedTextFieldRef.current.key]: 2
          });
        }
        revertTipText(selectedTextFieldRef.current.key, selectedTextFieldRef.current.dataType, tipOriginalValue.current);
        setSelectedTextField({
          key: '',
          assistantKey: '',
          label: '',
          appDbField: '',
          dataType: '',
          exportMapKey: '',
          position: -1,
        });
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('click', handleClick);
    };
  }, [renderedFields, user]);

  const calculateRenderedFields = useCallback(() => {
    const renderedHeaderFields = headerFields.filter((f) => f.isAvailable && f.isActive).filter((f) => f.section === 'header');
    const newRenderedFields: FieldType[] = [];
    for (let i = 0; i < renderedHeaderFields.length; i++) {
      newRenderedFields.push(renderedHeaderFields[i]);
    }
    metrics.forEach((m) => {
      const sectionFeilds = headerFields.filter((f) => f.isAvailable && f.isActive).filter((f) => f.section === m);
      sectionFeilds.forEach((f) => {
        newRenderedFields.push(f);
      });
    })
    setRenderedFields(newRenderedFields);
  }, [headerFields]);

  useEffect(() => {
    calculateRenderedFields();
  }, [calculateRenderedFields]);

  useEffect(()=>{
    let resizeableDiv = fieldsContainerRef.current as HTMLDivElement;
    let resizeRight = resizeRef.current as HTMLDivElement;
    let styles = window.getComputedStyle(resizeableDiv);
    let width = parseInt(styles.width,10);
    let x = 68 + width;
    let preX = x;

    const onMouseMoveRightResize = (e: MouseEvent)=>{
      if(isMouseDownRef.current){ //if the mouse remains down
        let newX = e.clientX;
        let maxWidth = window.innerWidth - 65;
        styles = window.getComputedStyle(resizeableDiv);
        let newWidth = parseInt(styles.width,10) ;
        if(newX > preX) newWidth = newWidth + (newX - (newWidth + 75)) + 2; //check if the cursor is moving right
        else newWidth = newWidth + (newX - (newWidth + 76)); //check if the cursor is moving left

        if(newWidth > Math.floor(maxWidth/2)) return; //check if width is at helf the parent width
        resizeableDiv.style.width = `${newWidth}px`;
        preX = newX;
      }
    }

    const onMouseUpRightResize = () =>{
      //when the the mouse is up.
      setIsMouseDown(false);
      document.addEventListener('mousemove',(e:MouseEvent)=>{
        onMouseMoveRightResize(e);
      });
    }

    const onMouseDownRightResize = (e: MouseEvent) =>{
      // When the small resize component is clicked down
      if(e.target === resizeRight) setIsMouseDown(true);
      document.addEventListener('mousemove',(e:MouseEvent)=>{
        //when the mouse is moved when the small resize component is still clicked down.
        onMouseMoveRightResize(e);
      })
      document.addEventListener('mouseup',()=>{
        //when the mouse is moved up
        setIsMouseDown(false);
        onMouseUpRightResize();
      });
    }
    //if the small resize component is available
    if(resizeRight) resizeRight.addEventListener('mousedown',onMouseDownRightResize)

    return ()=>{
      if(resizeRef) resizeRight.removeEventListener('mousedown',onMouseDownRightResize);
    }

  },[fieldsContainerRef.current, resizeRef.current])

  const setStatusChangedRef = (val: boolean) => {
    statusChangedRef.current = val;
  };

  const getInvoiceAfterStatusChange = async () => {
    try {
      const resp = await Axios.get(
        `${API.getDocumentByID}${id}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (resp.data.success) {
        const newInvoice = resp.data.data;
        setDoc({
          ...doc,
          status: newInvoice.status,
          owner: newInvoice.owner,
          ownerName: newInvoice.ownerName,
          exportFailReason: newInvoice.exportFailReason,
        });
      } else {
        navigate('/documents');
      }
    } catch (e) {
      const error = e as CustomError;
      if (error?.response?.data?.i18n) {
        enqueueSnackbar(getLocalisedErrorString(error.response.data.i18n, t), {
          variant: 'error',
          autoHideDuration: 5000
        });
      }
      navigate('/documents');
    }
  };

  const handleStatusChange = async (status: number, id: number) => {
    if (status === 900) {
      setOpenDeleteConf(true);
      return;
    }

    if (status === 501 && !chooseValidatorOpen) {
      setChooseValidatorOpen(true);
      return;
    }
    if (chooseValidatorOpen) {
      setChooseValidatorOpen(false);
    }

    setStatusChangeLoading(true);

    try {
      const body : StatusChangeRequest = {
        id,
        toStatus: status.toString()
      };

      if (status === 501 && selectedValidator?.email && selectedValidator.email !== doc.owner) {
        body.assignee = selectedValidator.email;
      }

      const response = await Axios.post(
        API.documentStatus,
        body,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        await getDocument();
        enqueueSnackbar(status === 501
          ? t('INVOICE_STATUS_CHANGE_TO_VALIDATE')
          : (user?.isDocunecta && status === 503) ? t('DOCUNECTA_EXPORT_SUCCESS')
            : t('PROCYS_STATUS_CHANGE_SUCCESS'), {
          variant: 'success',
          autoHideDuration: 5000
        });
        if (status === 501) {
          sendFeedback(API.feedbackLogs, 'send to validate', user?.email, user?.companyID);
        }
        if (status === 502) {
          sendFeedback(API.feedbackLogs, 'validate invoice', user?.email, user?.companyID);
        }
        if (status === 503) {
          sendFeedback(API.feedbackLogs, 'invoice export', user?.email, user?.companyID);
          const currentUrlParams = new URLSearchParams(location.search);
          if (!user?.exported || user?.exported === 0) {
            currentUrlParams.set('feedback-first-export', 'success');
            navigate(`${window.location.pathname}?${currentUrlParams.toString()}`);
          }
          if (user?.exported === 9) {
            currentUrlParams.set('feedback-tenth-export', 'success');
            navigate(`${window.location.pathname}?${currentUrlParams.toString()}`);
          }
        }
        setStatusChangedRef(true);
        if (isAutoNavigationAllowed && nextInvoice) {
          navigateInvoice(nextInvoice, 1);
        }
      }

      setStatusChangeLoading(false);
    } catch (e) {
      const error = e as CustomError;
      enqueueSnackbar(getLocalisedErrorString(error?.response?.data?.i18n, t)
        || appendContactSupport(window.config.support_email, t((user?.isDocunecta && status === 503)
          ? 'DOCUNECTA_EXPORT_FAILURE' : 'INVOICE_STATUS_CHANGE_FAILED'), t),
      {
        variant: 'error',
        autoHideDuration: 5000
      });
      getInvoiceAfterStatusChange();
      setStatusChangeLoading(false);
    }
  };

  const openInfoModal = () => {
    setShowInfoModal(true);
  };

  const closeInfoModal = () => {
    setShowInfoModal(false);
  };

  const onCancelDelete = () => {
    setOpenDeleteConf(false);
  };

  const handleChange = (val: string, prop: string) => {
    setIsSubmitClicked(false);
    setDetails({
      ...detailsRef.current,
      [prop]: val,
    });
  };

  const handleChangeMultiple = (values: string[], props: string[]) => {
    setIsSubmitClicked(false);
    const newDetails = { ...detailsRef.current };
    for (let i = 0; i < values.length; i++) {
      newDetails[props[i]] = values[i];
    }
    setDetails(newDetails);
  };

  const handleChangeCandidates = (name: string, val: string) => {
    const candidates = [...detailsXML[name]];
    const found = candidates.some(c => {
      return c === val;
    });
    const newCandidates = !found ? [val, ...candidates] : candidates;

    setDetailsCandidates({
      ...detailsCandidates,
      [name]: newCandidates
    });
  };

  const handleChangeMultipleCandidates = (names: string[], vals: string[]) => {
    const d = {...detailsCandidates};
    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const val = vals[i];
      const candidates = [...detailsXML[name]];
      const found = candidates.some(c => c === val);
      const newCandidates = !found ? [val, ...candidates] : candidates;

      d[name] = newCandidates;
    }

    setDetailsCandidates(d);
  };

  const handleChangeMain = async (field: FieldType, value: string | null) => {
    const { key: prop, assistantKey: name, dataType } = field;
    let formattedVal = value || '';
    if (dataType === 'text' && leadingWhiteSpace.test(formattedVal)) {
      formattedVal = formattedVal.trimStart();
    }

    if (formattedVal) {
      if (prop === 'documentDate') {
        const d = formattedVal;
        let periodVal = details.period || '';

        const parts = d?.split('-') || [];
        if (!dateFieldRule && parts.length >= 3) {
          if (d.indexOf('-') === 2) {
            periodVal = `${parts[2].substring(0, 4)}/${parts[1]}`;
          } else if (d.indexOf('-') === 4) {
            periodVal = `${parts[0].substring(0, 4)}/${parts[1]}`;
          }
          handleChangeMultiple([periodVal, formattedVal], ['period', 'documentDate']);
          return;
        }
        handleChange(d, prop);
      } else if (prop === 'amount') {
        if (details?.vatAmount) {
          const baseAmount = calculateBaseAmount(formattedVal, details.vatAmount, decimalSeparator);
          const valArr = [formattedVal, baseAmount];
          const propArr = ['amount', 'baseAmount'];
          const valXmlArr = [baseAmount];
          const nameArr = ['base_amount'];
          if (details?.discountAmount) {
            const discountRate = calculateDiscountRate(details.discountAmount, baseAmount, decimalSeparator);
            valArr.push(discountRate);
            propArr.push('discountRate');
            valXmlArr.push(discountRate);
            nameArr.push('discount_rate');
          }
          handleChangeMultiple(valArr, propArr);
          handleChangeMultipleCandidates(nameArr, valXmlArr);
        } else {
          handleChange(formattedVal, prop);
        }
      } else {
        handleChange(formattedVal, prop);
      }
      return;
    }
    handleChangeCandidates(name, formattedVal);
    handleChange(formattedVal, prop);
  };

  const handleChangeText = (field: FieldType, val: string) => {
    const { key: prop, assistantKey: name, dataType } = field;
    setIsSubmitClicked(false);
    let formattedVal = val;
    if (dataType === 'text' && leadingWhiteSpace.test(val)) {
      formattedVal = val.trimStart();
    }

    if (formattedVal) {
      if (prop === 'documentDate') {
        const d = formattedVal;
        let periodVal = details.period || '';

        const parts = d?.split('-') || [];
        if (!dateFieldRule && parts.length >= 3) {
          if (d.indexOf('-') === 2) {
            periodVal = `${parts[2].substring(0, 4)}/${parts[1]}`;
          } else if (d.indexOf('-') === 4) {
            periodVal = `${parts[0].substring(0, 4)}/${parts[1]}`;
          }
        }

        const valArr = [d, periodVal];
        const propArr = ['documentDate', 'period'];
        const valXmlArr = [d];
        const nameArr = ['date'];

        handleChangeMultiple(valArr, propArr);
        handleChangeMultipleCandidates(nameArr, valXmlArr);
      } else if (prop === 'amount' && details?.vatAmount) {
        const baseAmount = calculateBaseAmount(formattedVal, details.vatAmount, decimalSeparator);
        let discountRate = details.discountRate || '';

        if (details?.discountAmount) {
          discountRate = calculateDiscountRate(details.discountAmount, baseAmount, decimalSeparator);
        }

        const valArr = [formattedVal, discountRate];
        const propArr = ['amount', 'discountRate'];
        const valXmlArr = [formattedVal, discountRate];
        const nameArr = ['amount', 'discount_rate'];

        handleChangeMultiple(valArr, propArr);
        handleChangeMultipleCandidates(nameArr, valXmlArr);
      } else if (prop === 'vatAmount' && details?.amount) {
        const baseAmount = calculateBaseAmount(details.amount, formattedVal, decimalSeparator);
        let discountRate = details.discountRate || '';
        if (details?.discountAmount) {
          discountRate = calculateDiscountRate(details.discountAmount, baseAmount, decimalSeparator);
        }

        const valArr = [formattedVal, discountRate];
        const propArr = ['vatAmount', 'discountRate'];
        const valXmlArr = [formattedVal, discountRate];
        const nameArr = ['vat_amount', 'discount_rate'];

        handleChangeMultiple(valArr, propArr);
        handleChangeMultipleCandidates(nameArr, valXmlArr);
      } else if (prop === 'discountAmount' && details?.amount && details?.vatAmount) {
        const baseAmount = calculateBaseAmount(details.amount, details.vatAmount, decimalSeparator);
        const discountRate = calculateDiscountRate(formattedVal, baseAmount, decimalSeparator)

        const valArr = [formattedVal, discountRate];
        const propArr = ['discountAmount', 'discountRate'];
        const valXmlArr = [formattedVal, discountRate];
        const nameArr = ['discount_amount', 'discount_rate'];

        handleChangeMultiple(valArr, propArr);
        handleChangeMultipleCandidates(nameArr, valXmlArr);
      } else if (prop === 'documentNumber') {
        const archiveNumber = `${doc.fileName} ${formattedVal}`;

        const valArr = [formattedVal, archiveNumber];
        const propArr = ['documentNumber', 'archiveNumber'];
        const valXmlArr = [formattedVal, archiveNumber];
        const nameArr = ['number', 'archive_number'];

        handleChangeMultiple(valArr, propArr);
        handleChangeMultipleCandidates(nameArr, valXmlArr);
      } else {
        handleChange(formattedVal, prop);
        handleChangeCandidates(name, formattedVal);
      }

      return;
    }

    handleChangeCandidates(name, val);
    handleChange(val, prop);
  };

  const updateFieldBoundaries = async () => {
    const updatedFieldBoundaries: Record<string, Record<string, string>> = {};
    const candidateHighlights = highlightsRef.current.filter((highlight) => highlight.isCandidate);
    for (const highlight of candidateHighlights) {
      const { procysValues, position } = highlight;
      const { boundingRect, pageNumber } = position;
      if (procysValues && procysValues.length > 0 && boundingRect) {
        procysValues.forEach((pv) => {
          const procysKey = pv.key;
          updatedFieldBoundaries[`${procysKey}Boundary`] = {
            page: pageNumber.toString(),
            xmin: boundingRect.x1.toString(),
            ymin: boundingRect.y1.toString(),
            xmax: boundingRect.x2.toString(),
            ymax: boundingRect.y2.toString(),
            height: (boundingRect.y2 - boundingRect.y1).toString(),
            width: (boundingRect.x2 - boundingRect.x1).toString()
          };
        });
      }
    }

    try {
      const resp = await Axios.put(
        `${API.getDocumentBoundaries}${doc.id}`,
        updatedFieldBoundaries,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (resp.data.success && resp.data.data) {
        //
      }
    } catch (e) {
      //
    }

  };

  const handleSubmit = async (bgSave?: boolean) => {
    if (isSubmitClickedRef.current) {
      return;
    }
    setSubmitting(true);
    try {
      if (!authService.validateToken()) {
        setSubmitting(false);
        enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
          variant: 'error',
          autoHideDuration: 5000
        });
        setTimeout(() => {
          authService.logout(LOGIN_PANEL_URL);
        }, 2000);
        throw new Error(t('PROCYS_LOGIN_SESSION_EXPIRED'));
      }

      let timeSpent = 0;
      if (invoiceAnnotateStart) {
        timeSpent = invoiceAnnotateTime + (new Date().getTime() - Number(invoiceAnnotateStart));
      }
      setInvoiceAnnotateTime(timeSpent);

      await updateFieldBoundaries();

      const resp = await Axios.put(
        `${API.updateInvoice}`,
        {
          ...detailsRef.current,
          autoSave: bgSave,
          assistantID: doc.assistantID,
          id: doc.id,
          company: doc.company,
          email: doc.email,
          times: {
            ...annotateFieldsTimeRef.current,
            invoiceTime: timeSpent
          },
          trustScores: formatFieldTrustScores(fieldTrustScoresRef.current),
          documentTags: tags.join(', '),
        },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );

      if (resp.data.success) {
        setStatusChangedRef(true);
        setInvoiceAnnotateTime(0);
        setInvoiceAnnotateStart(new Date().getTime());
        setAnnotateFieldsTime(initializeAnnotateFieldTimes([...headerFields, ...lineFields]));
        setAnnotateSelectedField(null);
        setSubmitting(false);
        if (!bgSave) {
          enqueueSnackbar(t('INVOICE_EDIT_FORM_SAVE_SUCCESS'), {
            variant: 'success',
            autoHideDuration: 5000
          });
          if (moveToNextRef.current === -1) {
            navigateInvoice(previousInvoice, -1);
          } else if (moveToNextRef.current === 1 || (isAutoNavigationAllowed && nextInvoice !== null)) {
            navigateInvoice(nextInvoice, 1);
          }
          setMoveToNext(null);
        }
        await getDocument();
      } else {
        setSubmitting(false);
        if (!bgSave) {
          enqueueSnackbar(appendContactSupport(window.config.support_email, t('INVOICE_EDIT_FORM_SAVE_FAIL'), t), {
            variant: 'error',
            autoHideDuration: 5000
          });
          throw new Error(t('INVOICE_EDIT_FORM_SAVE_FAIL'));
        }
      }

      if (!bgSave) setIsSubmitClicked(true);
    } catch (e) {
      setSubmitting(false);
      if (!bgSave) {
        const error = e as CustomError;
        if (error?.response?.data) {
          enqueueSnackbar(
            getLocalisedErrorString(error.response.data.i18n || appendContactSupport(window.config.support_email, t('INVOICE_EDIT_FORM_SAVE_FAIL'), t), t),
            {
              variant: 'error',
              autoHideDuration: 5000
            }
          );
        }
        throw new Error(t('INVOICE_EDIT_FORM_SAVE_FAIL'));
      }
    }
  };

  const navigateInvoice = (navTo: DocType | null, increment: number) => {
    const statusChanged = statusChangedRef.current;
    const queryVal = new URLSearchParams(location.search);
    const status = queryVal.get('status');
    const filters = queryVal.get('filters');
    const query = queryVal.get('query');
    const time = queryVal.get('time');
    const thisPage = queryVal.get('page');
    const limit = queryVal.get('limit');
    const supplierFilter = JSON.parse(queryVal.get('supplier_filter') || 'null');
    const ownerFilter = JSON.parse(queryVal.get('owner_filter') || 'null');
    const companyFilter = JSON.parse(queryVal.get('company_filter') || 'null');
    const startTime = queryVal.get('start_time');
    const endTime = queryVal.get('end_time');
    const failedToExport = JSON.parse(queryVal.get('failed_to_export') || 'false');
    const documentsSelected = JSON.parse(queryVal.get('documents_selected') || 'false');
    const invoicePage = JSON.parse(queryVal.get('document_page') || '1')
      + increment
      + (increment > 0 && statusChanged && status !== 'INVOICE_STATUS_ALL' ? -1 : 0);
    const total = JSON.parse(queryVal.get('total') || '1') + (statusChanged && status !== 'INVOICE_STATUS_ALL' ? -1 : 0);
    navigate(
      `/doc/${navTo?.id}/edit?${status !== null
        ? `&status=${status}` : ''}${filters !== null ? `&filters=${filters}` : ''}${query !== null
        ? `&query=${query}` : ''}${time !== null ? `&time=${time}` : ''}${thisPage !== null
        ? `&page=${thisPage}` : ''}${limit !== null ? `&limit=${limit}` : ''}${supplierFilter
        ? `&supplier_filter=${supplierFilter}` : ''}${companyFilter ? `&company_filter=${companyFilter}` : ''}${ownerFilter
        ? `&owner_filter=${ownerFilter}` : ''}${startTime !== null ? `&start_time=${startTime}` : ''}${endTime !== null
        ? `&end_time=${endTime}` : ''}${failedToExport ? '&failed_to_export=true' : ''}${documentsSelected
        ? '&documents_selected=true' : ''}&document_page=${invoicePage}&total=${total}`
    );
    navigate(0);
  };

  return (
    <Grid sx={styles.root}>
      <Grid ref={fieldsContainerRef} sx={styles.documentDataContainer}>
        <CDCDocFields
          fields={headerFields}
          fieldsToDisplay={headerFields}
          doc={doc}
          details={details}
          detailsCandidates={detailsCandidates}
          statusChangeLoading={statusChangeLoading}
          previousInvoice={previousInvoice}
          nextInvoice={nextInvoice}
          qbTerms={[]}
          isSubmitClicked={isSubmitClicked}
          fieldTrustScores={fieldTrustScores}
          fieldTrustScoresRef={fieldTrustScoresRef}
          tags={tags}
          selectedTextField={selectedTextField}
          submitting={submitting}
          selectedTextFieldRef={selectedTextFieldRef}
          isMouseDown={isMouseDown}
          isMouseDownRef={isMouseDownRef}
          decimalSeparator={decimalSeparator}
          handleChange={handleChange}
          handleChangeMain={handleChangeMain}
          handleChangeText={handleChangeText}
          handleSubmit={handleSubmit}
          setLoading={setLoading}
          setStatusChangedRef={setStatusChangedRef}
          getDocument={getDocument}
          navigateInvoice={navigateInvoice}
          handleStatusChange={handleStatusChange}
          openInfoModal={openInfoModal}
          setTags={setTags}
          setIsSubmitClicked={setIsSubmitClicked}
          setMoveToNext={setMoveToNext}
          handleChooseValidatorModalOpen={handleChooseValidatorModalOpen}
          setSelectedTextField={setSelectedTextField}
          setFieldTrustScores={setFieldTrustScores}
          handleClose={handleClose}
          moveNext={moveNext}
          movePrevious={movePrevious}
          disableMove={disableMove}
        />
        <Box ref={resizeRef} sx={styles.resizeContainer} ></Box>
      </Grid>
      <Grid className="dashed-grid-paper" sx={styles.documentDisplayContainer}>
        <PdfViewer
          pdfDoc={doc}
          details={details}
          xml={xml}
          viewerHeight={pdfViewerHeight}
          renderedFields={renderedFields}
          renderedFieldsRef={renderedFieldsRef}
          selectedTextField={selectedTextField}
          selectedTextFieldRef={selectedTextFieldRef}
          highlights={highlights}
          highlightsRef={highlightsRef}
          pdfViewerWidth={pdfViewerWidth}
          viewLines={false}
          tipOriginalValue={tipOriginalValue}
          fieldTrustScoresRef={fieldTrustScoresRef}
          decimalSeparator={decimalSeparator}
          handleChangeMain={handleChangeMain}
          setSelectedTextField={setSelectedTextField}
          handleChangeVatEsLine={() => {}}
          handleChangeLines={() => {}}
          setHighlights={setHighlights}
          handleLinesOnBlur={() => {}}
          setFieldTrustScores={setFieldTrustScores}
          handleLinesDataForReprocessingChange={() => {}}
          setLoading={(type:boolean)=>{
            setLoading(type)
          }}
          setTipOriginalValue={setTipOriginalValue}
          revertTipText={revertTipText}
        />
      </Grid>
      <InfoModal
        open={showInfoModal}
        document={doc}
        handleClose={closeInfoModal}
      />
      <DeleteDocumentModal
        open={openDeleteConf}
        id={doc.id}
        handleClose={onCancelDelete}
      />
      <ChooseValidatorModal
        open={chooseValidatorOpen}
        users={users}
        selectedValidator={selectedValidator}
        id={doc.id || 0}
        handleClose={handleChooseValidatorModalClose}
        setSelectedValidator={setSelectedValidator}
        handleStatusChange={handleStatusChange}
      />
    </Grid>
  );
}

export default CDCDocumentEdit;
