import React, {useEffect} from 'react'
import { useTranslation } from 'react-i18next';

import { Grid, Typography, TextField, Autocomplete, FormHelperText, InputAdornment, FormControl, 
  Select, MenuItem, Tooltip, Button, Box, Switch
} from '@mui/material';
import { Edit as EditIcon, AddBox as PlusIcon } from '@mui/icons-material';

import { supplierData, userRoles  } from 'src/config';
import LoadingIndicator from 'src/shared/components/LoadingIndicator';
import { useAuth } from 'src/hooks/useAuth';
import { useConfig } from 'src/hooks/useConfig';
import { VatGroupType, ProjectType, CostCenterType, GLAccountType, CountryType, CurrencyType, FieldType } 
from 'src/types';
import {
  filterAutocompleteOptionsGLA, filterAutocompleteOptionsVatGroups, filterAutocompleteOptionsCountry,
  showEditIcon, getAutoCompleteValue, filterAutocompleteOptionsCostCenter, filterAutocompleteOptionsCurrency
} from 'src/dimensions-new/utils';
import theme from 'src/theme';

type PropsType = {
  values: Record<string, string | boolean>;
  errors:Record<string, string>;
  touched:Record<string, boolean>;
  handleBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;
  setTextFieldSelectedKey: (_key: string) => void;
  textFieldSelectedKey: string;
  isEdit: boolean;
  setTextFieldSelectedValue: (_value: string) => void;
  handleChangeAutocomplete: (_e:React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | 
    React.SyntheticEvent<Element, Event>, _prop:string, _value:Record<string, any>, _changeFunc:Function) => void;
  glAccounts: GLAccountType[];
  handleEditGLA: (_prop: string) => void;
  handleAddGLA: (_prop: string) => void;
  vatGroups: VatGroupType[],
  handleAddVATCode: (_id: string) => void;
  handleEditVATCode: (_id: string) => void;
  supplierFields: FieldType[];
  projects:ProjectType[];
  handleEditCostCenter: (_cc: string) => void;
  handleAddCostCenter: (_c: string) => void;
  costCenters: CostCenterType[];
  countries: CountryType[];
  currencies: CurrencyType[];
  handleClose: () => void;
  handleSubmit: () => void;
  isSubmitting: boolean;
  preferredFieldsLoading: boolean;
  skip: boolean | undefined;
  handleSkip: () => void;
  isDisabled: boolean;
  newlyAddedVal: string;
  newlyAddingProp:string;
  selectedVatCode: VatGroupType | null;
}

const ClientStandardForm = (props:PropsType) => {
  const {
    touched,
    errors,
    values,
    handleBlur,
    handleChange,
    setTextFieldSelectedKey,
    isEdit,
    setTextFieldSelectedValue,
    handleChangeAutocomplete,
    glAccounts,
    handleEditGLA,
    handleAddGLA,
    vatGroups,
    handleAddVATCode,
    handleEditVATCode,
    supplierFields,
    projects,
    handleEditCostCenter,
    handleAddCostCenter,
    costCenters,
    countries,
    currencies,
    handleClose,
    handleSubmit,
    isSubmitting,
    preferredFieldsLoading,
    skip,
    handleSkip,
    isDisabled,
    newlyAddedVal,
    newlyAddingProp,
  } = props;
  
  const { t , ready } = useTranslation();
  const { user } = useAuth();
  const { BRAND_NAME } = useConfig();
  const label = { inputProps: { 'aria-label': 'Check Skip' } };

  const isFieldAvailable = (field: FieldType) => {
    if (field.isMandatory) {
      return false;
    }
    if (field.isActive) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (newlyAddedVal && newlyAddingProp) {
      handleChange({ target: { name: newlyAddingProp, value: newlyAddedVal } } as 
        React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>);
    }
  }, [newlyAddedVal]);
  
  return (
    <form style={styles.form} className="y-scroll">
      {preferredFieldsLoading
        ? (<LoadingIndicator />) 
        : <Box sx={styles.root}>
            <Box sx={styles.skipDiv}>
              <Switch {...label} disabled={isDisabled} checked={skip} onChange={()=>handleSkip()} sx={styles.skipSwitch} />
              <Tooltip
                title={isDisabled?'':t('TOOLTIP_SKIP_REVIEW_FOR_SUPPLIER', { brand: BRAND_NAME })}
              >
                <span onClick={isDisabled?()=>{}:()=>handleSkip()}>
                  <Typography sx={styles.skipText}>
                    {ready && t('SKIP_REVIEW_FOR_SUPPLIER_LABEL')}
                  </Typography>
                </span>
              </Tooltip>
            </Box>
            <Grid item key="creditorName" sx={styles.eachField}>
              <Typography>{ready && t('SUPPLIERS_CREDITOR_NAME')}</Typography>
              <TextField
                id="creditorNameId"
                error={Boolean(touched.creditorName && errors.creditorName)}
                fullWidth
                helperText={touched.creditorName && (ready && t(errors.creditorName))}
                name="creditorName"
                onBlur={handleBlur}
                onChange={handleChange}
                required
                value={values.creditorName}
                variant="outlined"
                sx={styles.inputField}
                onClick={() => { setTextFieldSelectedKey('creditorName'); setTextFieldSelectedValue(values.creditorName as string); }}
                disabled={isDisabled}
              />
            </Grid>
            <Grid item key="generalLedgerAccount" sx={styles.eachField}>
              <Typography>{ready && t('SUPPLIERS_GENERAL_LEDGER_ACCOUNT')}</Typography>
              <FormControl variant="outlined" sx={styles.formControl}>
                <Autocomplete
                  fullWidth
                  onChange={(e, v) => handleChangeAutocomplete(e, 'generalLedgerAccount', v as unknown as Record<string, any>, handleChange)}
                  value={glAccounts && values.generalLedgerAccount && glAccounts.find((e: GLAccountType) => e.code === values?.generalLedgerAccount) || null}
                  options={glAccounts}
                  getOptionLabel={(option: GLAccountType) => (option && option.code ? `${option.code} | ${option.name}` : '')}
                  isOptionEqualToValue={(option: GLAccountType) => option.code === values.generalLedgerAccount}
                  disabled={isDisabled}
                  filterOptions={(options, state) => filterAutocompleteOptionsGLA(options, state)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (isDisabled ? <></> :
                          <>
                            {values.generalLedgerAccount !== supplierData.defaultGLA[0].code && ( values?.generalLedgerAccount ?
                              <InputAdornment
                                position="end"
                                onClick={() => handleEditGLA(values.generalLedgerAccount as string)}
                                sx={styles.editIconContainer}
                              >
                                <EditIcon sx={styles.editDimensionIcon} />
                              </InputAdornment>:<></>
                            )}
                            <InputAdornment position="end" sx={styles.plusIconContainer}>
                              <PlusIcon sx={styles.plusIcon} onClick={() => handleAddGLA('generalLedgerAccount')} />
                            </InputAdornment>
                          </>
                        ),
                      }}
                      sx={styles.autoCompleteInput}
                      onClick={() => { setTextFieldSelectedKey('generalLedgerAccount'); setTextFieldSelectedValue(values.generalLedgerAccount as string); }}
                      disabled={isDisabled}
                    />
                  )}
                />
                <FormHelperText error>
                  {touched.generalLedgerAccount && errors.generalLedgerAccount && ready ? t(errors.generalLedgerAccount) : ''}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item key="vatGroup" sx={styles.eachField}>
              <Typography>{ready && t('SUPPLIERS_VAT_GROUP')}</Typography>
              <FormControl variant="outlined" sx={styles.formControl}>
                <Autocomplete
                  fullWidth
                  onChange={(e, v) => handleChangeAutocomplete(e, 'vatGroup', v as unknown as Record<string, any>, handleChange)}
                  value={vatGroups && values.vatGroup && vatGroups.find((e: VatGroupType) => e.code === values?.vatGroup) || null}
                  options={vatGroups}
                  getOptionLabel={(option: VatGroupType) => (option && option.code ? `${option.code} | ${option.name}` : '')}
                  isOptionEqualToValue={(option: VatGroupType) => option.code === values.vatGroup}
                  disabled={isDisabled}
                  filterOptions={(options, state) => filterAutocompleteOptionsVatGroups(options, state)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (isDisabled ? <></> :
                          <>
                            {values.vatGroup !== supplierData.defaultVatGroup[0].code && (values?.vatGroup ?
                              <InputAdornment
                                position="end"
                                onClick={() => handleEditVATCode(values.vatGroup as string)}
                                sx={styles.editIconContainer}
                              >
                                <EditIcon sx={styles.editDimensionIcon} />
                              </InputAdornment>:<></>
                            )}
                            <InputAdornment position="end" sx={styles.plusIconContainer}>
                              <PlusIcon sx={styles.plusIcon} onClick={() => handleAddVATCode('vatGroup')} />
                            </InputAdornment>
                          </>
                        ),
                      }}
                      sx={styles.autoCompleteInput}
                      onClick={() => { setTextFieldSelectedKey('vatGroup'); setTextFieldSelectedValue(values.vatGroup as string); }}
                      disabled={isDisabled}
                    />
                  )}
                />
                <FormHelperText error>
                  {touched.generalLedgerAccount && errors.generalLedgerAccount && ready ? t(errors.generalLedgerAccount) : ''}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item key="autoPaymentStatus" sx={styles.eachField}>
              <Typography>{ready && t('SUPPLIERS_AUTO_PAYMENT_STATUS')}</Typography>
              <FormControl variant="outlined" sx={styles.formControl}>
                <Select
                  disabled={isDisabled}
                  labelId="autoPaymentStatus-label"
                  id="autoPaymentStatus"
                  error={Boolean(touched.autoPaymentStatus && errors.autoPaymentStatus)}
                  value={values.autoPaymentStatus}
                  onChange={handleChange as any}
                  name="autoPaymentStatus"
                  sx={styles.selectStyle}
                >
                  {
                  supplierData.apStatuses && supplierData.apStatuses.length > 0
                  && supplierData.apStatuses.map((s) => (
                    <MenuItem key={s.code} value={s.code}>
                      {s.name}
                    </MenuItem>
                  ))
                }
                </Select>
                <FormHelperText error>
                  {touched.autoPaymentStatus && errors.autoPaymentStatus && ready ? t(errors.autoPaymentStatus) : ''}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item key="country" sx={styles.eachField}>
              <Typography>{ready && t('SUPPLIERS_COUNTRY')}</Typography>
              <FormControl variant="outlined" sx={styles.formControl}>
                <Autocomplete
                  fullWidth
                  onChange={(e:React.SyntheticEvent<Element, Event>, v) => 
                    handleChangeAutocomplete(e, 'country', v as unknown as Record<string, any>, handleChange)
                  }
                  value={(values.country && countries.length > 0)
                    ? countries.find((o) => o.isoCode.toLowerCase() === values.country.toString().toLowerCase()) : null
                  }
                  options={countries}
                  getOptionLabel={(option) => `${option.isoCode} | ${option.name}`}
                  filterOptions={(options, state) => filterAutocompleteOptionsCountry(options, state)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                      }}
                      sx={styles.autoCompleteInput}
                      disabled={isDisabled}
                    />
                  )}
                  disabled={isDisabled}
                />
                <FormHelperText error>
                  {touched.country && errors.country && ready ? t(errors.country) : ''}
                </FormHelperText>
              </FormControl>
            </Grid>
            {
              supplierFields?.map((f:FieldType) => {
                if (!isFieldAvailable(f)) {
                  return null;
                }
                switch (f.key) {
                  case 'status':
                    return (
                      <Grid item  key={f.key} sx={styles.eachField}>
                        <Typography>{ready && t(f.label)}</Typography>
                        <FormControl variant="outlined" sx={styles.formControl}>
                          <Select
                            disabled={isDisabled}
                            labelId={`${f.key}-label`}
                            id={f.key}
                            error={Boolean(touched[f.key] && errors[f.key])}
                            value={values[f.key]}
                            onChange={handleChange as any}
                            name={f.key}
                            required={f.isRequired}
                            sx={{ ...styles.selectStyle, ...styles.capitalizeText }}
                          >
                            {
                              supplierData.statuses && supplierData.statuses.length > 0
                                && supplierData.statuses.map((s) => (
                                  <MenuItem key={s} value={s} sx={styles.capitalizeText}>
                                    {s}
                                  </MenuItem>
                                ))
                            }
                          </Select>
                          <FormHelperText error>
                            {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    );
                  case 'generalLedgerAccLine': case 'vatGroupLine': case 'costCenter':
                    return (
                      <Grid item key={f.key} sx={styles.eachField}>
                        <Typography>{ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}</Typography>
                        <FormControl variant="outlined" sx={styles.formControl}>
                          <Autocomplete
                            disabled={isDisabled}
                            fullWidth
                            onChange={(e, v) => handleChangeAutocomplete(e, f.key, v as unknown as Record<string, any>, handleChange)}
                            value={getAutoCompleteValue(values[f.key] as string, f.key === 'generalLedgerAccLine'
                              ? glAccounts : f.key === 'vatGroupLine' ? vatGroups : costCenters)}
                            options={f.key === 'generalLedgerAccLine' ? glAccounts : f.key === 'vatGroupLine' ? vatGroups : costCenters}
                            filterOptions={(options, state) => f.key === 'generalLedgerAccLine'
                              ? filterAutocompleteOptionsGLA(options, state) : f.key === 'vatGroupLine'
                                ? filterAutocompleteOptionsVatGroups(options, state) : filterAutocompleteOptionsCostCenter(options, state)}
                            getOptionLabel={(option) => (option && option.code ? `${option.code} | ${option.name}` : '')}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (isDisabled ? <></> :
                                    <>
                                      {showEditIcon(f.key, values) ?
                                        <InputAdornment
                                          position="end"
                                          onClick={() => f.key === 'generalLedgerAccLine'
                                            ? handleEditGLA(values[f.key] as string) : f.key === 'vatGroupLine'
                                             ? handleEditVATCode(values[f.key] as string) : handleEditCostCenter(values[f.key] as string)}
                                          sx={styles.editIconContainer}
                                        >
                                          <EditIcon sx={styles.editDimensionIcon} />
                                        </InputAdornment>
                                        : <></>
                                      }
                                      <InputAdornment position="end" sx={styles.plusIconContainer}>
                                        <PlusIcon
                                          sx={styles.plusIcon}
                                          onClick={() => f.key === 'generalLedgerAccLine'
                                            ? handleAddGLA(f.key) : f.key === 'vatGroupLine'
                                              ? handleAddVATCode(f.key) : handleAddCostCenter(f.key)}
                                        />
                                      </InputAdornment>
                                    </>
                                  ),
                                }}
                                sx={styles.autoCompleteInput}
                                onClick={() => {
                                  setTextFieldSelectedKey(f.key);
                                  setTextFieldSelectedValue(values[f.key] as string);
                                }}
                                required={f.isRequired}
                                disabled={isDisabled}
                              />
                            )}
                          />
                          <FormHelperText error>
                            {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    );
                  case 'project':
                    return (
                      <Grid item key={f.key} sx={styles.eachField}>
                        <Typography>{ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}</Typography>
                        <FormControl variant="outlined" sx={styles.formControl}>
                          <Select
                            disabled={isDisabled}
                            labelId={`${f.key}-label`}
                            id={f.key}
                            error={Boolean(touched[f.key] && errors[f.key])}
                            value={values[f.key]}
                            onChange={handleChange as any}
                            name={f.key}
                            required={f.isRequired}
                            sx={styles.selectStyle}
                          >
                            {
                              projects.map((s:ProjectType) => {
                                if (s) {
                                  return (
                                    <MenuItem key={s.code} value={s.code}>
                                      {`${s.code}${s.name ? ` | ${s.name}` : ''}`}
                                    </MenuItem>
                                  );
                                }

                                return null;
                              })
                            }
                          </Select>
                          <FormHelperText error>
                            {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    );
                  case 'defaultCurrency':
                    return (
                      <Grid item key={f.key} sx={styles.eachField}>
                        <Typography>{ready && `${t(f.label)}${f.isRequired ? '*' : ''}`}</Typography>
                        <FormControl variant="outlined" sx={styles.formControl}>
                          <Autocomplete
                            disabled={isDisabled}
                            fullWidth
                            onChange={(e, v) => handleChangeAutocomplete(e, f.key, v as unknown as Record<string, any>, handleChange)}
                            value={(values[f.key] && currencies.length > 0) ? currencies.find((o) => o.code === values[f.key]) : null}
                            options={currencies}
                            getOptionLabel={(option) => `${option.code} | ${option.currency}`}
                            filterOptions={(options, state) => filterAutocompleteOptionsCurrency(options, state)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                }}
                                sx={styles.autoCompleteInput}
                              />
                            )}
                          />
                          <FormHelperText error>
                            {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    );
                  case 'bankCountry':
                    let isRequired = f.isRequired; // eslint-disable-line
                    if (f.key === 'bankCountry' && values.autoPaymentStatus === supplierData.apStatuses[2].code) {
                      isRequired = false;
                    }

                    return (
                      <Grid item key={f.key} sx={styles.eachField}>
                        <Typography>{ready && `${t(f.label)}${isRequired ? '*' : ''}`}</Typography>
                        <FormControl variant="outlined" sx={styles.formControl}>
                          <Autocomplete
                            disabled={isDisabled}
                            fullWidth
                            onChange={(e, v) => handleChangeAutocomplete(e, f.key, v as unknown as Record<string, any>, handleChange)}
                            value={(values[f.key] && countries.length > 0) ? countries.find((o) => o.isoCode === values[f.key]) : null}
                            options={countries}
                            getOptionLabel={(option) => `${option.isoCode} | ${option.name}`}
                            filterOptions={(options, state) => filterAutocompleteOptionsCountry(options, state)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                }}
                                sx={styles.autoCompleteInput}
                              />
                            )}
                          />
                          <FormHelperText error>
                            {touched[f.key] && errors[f.key] && ready ? t(errors[f.key]) : ''}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    );
                  default:
                    return (
                      <Tooltip key={f.key} title={f.key === 'creditorCode' && isEdit ? t('DIMENSION_CODE_CANNOT_EDIT') : ''}>
                        <Grid item key={f.key} sx={styles.eachField}>
                          <Typography>{ready && t(f.label)}</Typography>
                          <TextField
                            id={`${f.key}Id`}
                            error={Boolean(touched[f.key] && errors[f.key])}
                            fullWidth
                            helperText={touched[f.key] && (ready && t(errors[f.key]))}
                            name={f.key}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required={
                              f.isRequired
                              || ((f.key === 'ascription' || f.key === 'bicSwift')
                                && (
                                  values.autoPaymentStatus === supplierData.apStatuses[0].code
                                || values.autoPaymentStatus === supplierData.apStatuses[1].code
                                )
                              )
                              || (f.key === 'iban' && values.autoPaymentStatus === supplierData.apStatuses[0].code)
                              || ((f.key === 'bankName' || f.key === 'bankCity' || f.key === 'bankStreet'
                                || f.key === 'bankHouseNumber' || f.key === 'city' || f.key === 'address1')
                                && values.autoPaymentStatus === supplierData.apStatuses[1].code
                              )
                            }
                            value={values[f.key]}
                            variant="outlined"
                            disabled={isDisabled ? true : (isEdit && f.key === 'creditorCode')}
                            sx={styles.inputField}
                            onClick={() => { setTextFieldSelectedKey(f.key); setTextFieldSelectedValue(values[f.key] as string); }}
                          />
                        </Grid>
                      </Tooltip>
                    );
                }
              })
            }
            <Box sx={{ flex: 1 }} />
            {user?.userRole.toLowerCase() !== userRoles.annotator && (
              isDisabled ? <></> :
              <Grid item xs={12} sx={{ marginBottom: isEdit ? '10px' : '0px' }}>
                <Box display="flex" justifyContent="flex-end" p="20px 15px 5px">
                  <Button
                    variant="text"
                    onClick={() => handleClose()}
                    sx={{...styles.yearField,textTransform: 'none'}}
                  >
                    {ready && t('SUPPLIERS_CANCEL')}
                  </Button>
                  <Button
                    sx={{ backgroundColor: '#3E8AFF',textTransform: 'none' }}
                    variant="contained"
                    onClick={() => handleSubmit()}
                    disabled={isSubmitting}
                  >
                    {ready && t(isEdit?'SUPPLIERS_SAVE_CHANGES': 'DIMENSION_ADD_CLIENT')}
                  </Button>
                  
                </Box>
              </Grid>
            )}
          </Box>
      }
    </form>
  )
}

export default ClientStandardForm

const styles = {
  form: {
    padding: '10px 0 20px',
    height: '100%',
  },
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  eachField: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '3px 16px',
    '& > p': {
      color: '#4C4E64DE',
      fontSize: '13px'
    }
  },
  inputField: {
    width: '300px',
    '& .MuiOutlinedInput-input': {
      fontSize: '13px !important',
      lineHeight: '32px !important',
      color: '#4C4E64 !important',
      fontWeight: '400',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: `2px solid #C2CFE099`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid #C2CFE099`,
      },
    },
  },
  formControl: {
    width: '300px'
  },
  formControlRoot: {
    backgroundColor: '#ffffff'
  },
  autoCompleteInput: {
    width: '300px',
    color: '#4C4E64 !important',
    fontSize: '13px',
    lineHeight: '20px',
    backgroundColor: '#ffffff',
    '& .MuiAutocomplete-input': {
      color: '#4C4E64 !important',
    },
    '& .MuiOutlinedInput-root': {
      padding: '0px 2px 0px 0px !important',
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: `2px solid #C2CFE099`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid #C2CFE099`,
      }
    },
  },
  selectStyle: {
    color: '#4C4E64 !important',
    fontSize: '13px',
    width: '100%',
    lineHeight: '20px',
    backgroundColor: '#ffffff',
    '&.MuiAutocomplete-input': {
      color: '#4C4E64 !important',
    },
    '&.MuiOutlinedInput-root': {
      padding: '0px 2px 0px 0px !important',
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: `2px solid #C2CFE099`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid #C2CFE099`,
      }
    },
  },
  editIconContainer: {
    marginRight: '-8px'
  },
  editDimensionIcon: {
    fontSize: '18px',
    color: '#757575',
    border: '1px solid #334D6E !important',
    borderRadius: '3px',
    cursor: 'default'
  },
  plusIcon: {
    height: '24px',
    width: '24px',
    color: theme.palette.secondary.main,
    cursor: 'pointer'
  },
  plusIconContainer: {
    marginRight: '5px'
  },
  plusIconSelect: {
    height: '24px',
    width: '24px',
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    marginRight: '5px',
  },
  capitalizeText: {
    textTransform: 'capitalize'
  },
  yearField: {
    marginLeft: '16px',
    color: theme.palette.secondary.main
  },
  skipDiv: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px',
    gap: '12px'
  },
  skipText: {
    fontSize: '14px',
    color: '#4C4E64'
  },
  skipSwitch: {
    '& .MuiSwitch-switchBase': {},
    '&.MuiSwitch-root': {}
  },
}