import theme from "src/theme";


const styles = {
  container:{
    display:'flex',
    flexDirection:'column',
    width:'100%',
    gap:'15px',
    padding:'20px',
    backgroundColor:'#fff',
  },
  title:{
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '32px',
    letterSpacing: '-0.25px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    color:'#4C4E64',
    margin:'10px 0 0',
  },
  searchBar: {
    display:'flex',
    flex: 1,
    margin:'10px 0 0',
    gap:'20px'
  },
  queryField: {
    width: '100%',
    backgroundColor: '#fff',
    border: 'none',
    borderRadius: 0,
    fontSize:'13px',
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: `2px solid #C2CFE099!important`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `2px solid #C2CFE099!important`,
      }
    },
  },
  searchBarInput: {
    height: '36px',
    color:'#4C4E64',
    width: '100%',
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF',
      '& fieldset': {
        border: `2px solid #C2CFE099!important`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `2px solid #C2CFE099!important`,
      }
    },
  },
  btnsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '8px'
  },
  downloadAllBtn: {
    backgroundColor: '#3E8AFF1A',
    padding: '4px 9px 4px 9px',
    borderRadius: '4px',
    height: '36px',
    textTransform: 'none',
    color: '#3E8AFF',
    fontWeight: '500',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#3E8AFF1A',
      boxShadow: '0px 8px 16px -8px #4C4E646B',
    }
  },
  downloadAllIcon: {
    color: '#3E8AFF',
    width: '20px',
    height: '20px',
  },
  periodBtn:{
    display:'flex',
    color:'#3E8AFF',
    backgroundColor:'#3E8AFF1A',
    fontSize:'14px',
    fontWeight:500,
    flexShrink:0,
    textTransform:'none',
  },
  periodIcon:{
    width:'20px',
    height:'20px',
    color:'#3E8AFF'
  },
  tableWrapper:{
    width:'100%',
    backgroundColor:'#fff',
  },
  filterBtn: {
    fontSize: '13px',
    color: '#90A0B7',
    textTransform: 'none'
  },
  filterBtnSelected: {
    fontSize: '13px',
    color: theme.palette.secondary.main,
    textTransform: 'none',
    borderRadius: 8,
    border: '1px solid #3E8EFF',
    padding: '4px 6px',
  },
  filterMenu: {
    width:'200px',
    backgroundColor:'#fff',
    boxShadow: '0px 2px 4px 0px rgb(0 0 0 / 8%)',
    border: '1px solid #DDDDDD'
  },
  filterMenuItem: {
    fontSize: 13,
    padding:'8px 10px',
    width:'100%'
  },
  selectedFilterMenuItem: {
    backgroundColor: 'rgba(0, 0, 0, 0.08) !important;'
  },
  backdrop: {
    zIndex: 111111111,
    color: '#ffffff'
  },
}

export default styles;