const theme = {
  palette: {
    primary: {
      main: '#3E8AFF',
      dark: '#367BE3',
      light: '#3E8AFF14',
    },
    error: {
      main: '#FF4D49',
      light: '#FF4D4914',
    },
    text: {
      primary: '#4C4E64',
      secondary: '#6D788D',
      white: '#FFFFFF',
      disabled: '#4C4E6480',
    },
    background: {
      white: '#FFFFFF',
      tableHeader: '#F5F5F7',
      grayLight: '#262B4314',
      primaryLight: '#1F7CFF29'
    },
    border: {
      input: '#262B4338',
      header: '#4C4E641F',
      footer: '#E9EAEC',
      cancel: '#4C4E6433',
    },
    boxShadow: {
      primary: '#4C4E6438',
      cancel: '#4C4E646B',
    },
    code: {
      light: '#f4f4f4',
    }
  }
}

export default theme