import theme from "src/themeNew";

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.white,
    height: '100%',
    width: '400px',
    padding: '8px 0px 0px 0px',
    boxShadow: `0px 2px 10px 0px ${theme.palette.boxShadow.primary}`,
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0px 0px 12px 0px',
    borderBottom: `1px solid ${theme.palette.border.header}`,
  },
  headerRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 16px',
    maxWidth: '100%',
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  backIconContainer: {
    padding: '4px 4px 4px 0px',
  },
  backIcon: {
    color: theme.palette.text.secondary,
    height: '24px',
    width: '24px',
  },
  headerText: {
    color: theme.palette.text.primary,
    fontSize: '18px',
    fontWeight: '500',
    marginLeft: '8px',
    lineHeight: '28px',
  },
  subHeaderText: {
    color: theme.palette.text.secondary,
    fontSize: '12px',
    fontWeight: '500',
    marginLeft: '8px',
    lineHeight: '22px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  apiBtn: {
    border: `1px solid ${theme.palette.primary.main} !important`,
    borderRadius: '6px',
    height: '24px',
  },
  apiBtnText: {
    fontSize: '13px',
    fontWeight: '500',
    color: theme.palette.primary.main,
  },
  addFieldBtnContainer: {
    padding: '12px 8px',
  },
  searchBox: {
    backgroundColor: theme.palette.background.white,
    borderWidth: '0px',
    borderRadius: '6px',
    margin: '0px',
    padding: '0 8px',
    minWidth: '100px'
  },
  searchInput: {
    padding: '4px !important',
    border: `1px solid ${theme.palette.border.input} !important`,
    '&::placeholder': {
      color: theme.palette.text.disabled,
      fontSize: '15px',
      lineHeight: '24px',
      fontWeight: '400',
    }
  },
  searchBarInput: {
    padding: '8px 16px !important',
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '0px 16px 8px 16px',
    borderBottom: `1px solid ${theme.palette.border.header}`,
  },
  headerFieldsContainer: {
    borderBottom: `2px solid ${theme.palette.border.header}`,
    paddingBottom: '8px',
    marginBottom: '8px',
  },
  sectionFieldsContainer: {
    marginBottom: '16px',
  },
  fieldItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 0px',
    flex: 1,
  },
  dragIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 8px 0 0'
  },
  dragIcon: {
    color: theme.palette.text.secondary,
    width: '20px',
    height: '20px',
  },
  fieldName: {
    color: theme.palette.text.primary,
    fontSize: '15px',
    fontWeight: '500',
    lineHeight: '22px',
    flex: 1,
    textAlign: 'left',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px'
  },
  actionBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '16px',
    padding: '20px',
    height: '72px',
    boxShadow: `0px 2px 6px 0px ${theme.palette.boxShadow.primary}`,
    border: `1px solid ${theme.palette.border.footer}`,
  },
}

export default styles;
