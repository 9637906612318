import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';

import {
  Box, Dialog, IconButton, Typography
} from '@mui/material';
import {
  Close as CloseIcon,
  FileCopyOutlined as CopyIcon,
  CheckCircleOutline as CheckIcon,
} from '@mui/icons-material';

import styles from './style';

interface PropTypes {
  open: boolean;
  docType: Record<string, any> | null;
  fieldsList: Record<string, any>[];
  sectionFieldsList: Record<string, Record<string, any>[]>;
  handleClose: () => void;
}

const APIRef = (props: PropTypes) => {
  const { i18n, t, ready } = useTranslation();

  const { open, docType, fieldsList, sectionFieldsList, handleClose } = props;

  const [copyObj, setCopyObj] = useState<Record<string, boolean>>({
    docType: false,
    fields: false,
  });

  const onChangeCopy = (prop: string) => {
    const iCopyObj: Record<string, boolean> = {
      docType: false,
      fields: false,
    };

    iCopyObj[prop] = true;

    setCopyObj(iCopyObj);
  };
  
  const copyToClipboard = (prop: string, value: string) => {
    copy(value, {
      // @ts-ignore
      onCopy: onChangeCopy(prop)
    });
  };

  const data: Record<string, any> = {};
  fieldsList.forEach((field) => {
    if (field.key && field.name) {
      let val = field.name;
      if (i18n.exists(val)) {
        val = t(val);
      }
      data[field.key] = val;
    }
  });

  Object.keys(sectionFieldsList).forEach((section) => {
    data[section] = {};
    sectionFieldsList[section].forEach((field) => {
      if (field.key && field.name) {
        let val = field.name;
        if (i18n.exists(val)) {
          val = t(val);
        }
        data[section][field.key] = val;
      }
    });
  });

  return (
    <Dialog
      open={open}
      sx={styles.modal}
      onClose={handleClose}
    >
      <Box sx={styles.container}>
        <Box sx={styles.modalHeader}>
          <Box sx={styles.titleContainer}>
            <Typography sx={styles.headerTitle}>
              {t('DOC_TYPES_API_REF_TITLE')}
            </Typography>
            <IconButton sx={styles.closeBtn} onClick={handleClose}>
              <CloseIcon sx={styles.closeIcon} />
            </IconButton>
          </Box>
        </Box>
        <Box sx={styles.modalContent}>
          <Typography sx={styles.labelText}>{ready && t('API_REF_DOC_TYPE_INTERNAL_KEY_LABEL')}</Typography>
          <Box sx={styles.detailRow}>
            <input
              type="text"
              value={docType?.documentType}
              style={styles.inputField}
              disabled
            />
            <IconButton sx={styles.copyBtn} onClick={() => copyToClipboard('docType', docType?.documentType || '')}>
              <CopyIcon sx={styles.copyIcon} />
            </IconButton>
            {copyObj.docType && <CheckIcon style={styles.checkIcon} />}
          </Box>
          <Typography sx={styles.contentText}>
            {t('DOC_TYPES_API_REF_DESCRIPTION')}
          </Typography>
          <Box sx={styles.jsonContainer}>
            <pre style={styles.jsonPre}>
              {JSON.stringify(data, null, 2)}
            </pre>
            <Box sx={styles.copyIconContainer}>
              <IconButton sx={styles.copyBtn} onClick={() => copyToClipboard('fields', JSON.stringify(data, null, 2))}>
                <CopyIcon sx={styles.copyIcon} />
              </IconButton>
              {copyObj.fields && <CheckIcon sx={styles.checkIcon} />}
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}

export default APIRef;
