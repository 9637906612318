import React from 'react';
import { Button } from '@mui/material';
import theme from 'src/themeNew';

const SubmitBtn = (props: {
  title: string,
  onClick: (_e?: any) => void,
  disabled?: boolean,
  startIcon?: React.ReactNode,
}) => (
  <Button
    variant="contained"
    sx={styles.submitBtn}
    disabled={props.disabled}
    onClick={props.onClick}
    startIcon={props.startIcon}
  >
    {props.title}
  </Button>
);

export default SubmitBtn;

const styles = {
  submitBtn: {
    color: theme.palette.text.white,
    fontSize: '14px',
    lineHeight: '24px',
    minWidth: '108px',
    height: '38px',
    textTransform: 'none',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '4px',
    boxShadow: '0px 2px 6px 0px #262B4324',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: 'none',
    },
    '&:disabled': {
      color: theme.palette.text.white,
      backgroundColor: theme.palette.primary.main,
      opacity: 0.45,
    }
  },
};
