import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  Autocomplete, Box, Button, Dialog, IconButton, MenuItem, Select, TextField, Typography
} from '@mui/material';
import {
  Close as CloseIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import useWidth from 'src/hooks/useWidth';
import { periodFilterOptions, statusFilters } from 'src/dashboard-new/utils';
import { invoiceStatusesKeys } from 'src/config';
import { UserDataType, OwnerType, SupplierType, CompanyType, RootState } from 'src/types';
import { getSupplierLabel } from 'src/document-edit/utils';
import CustomPeriodFilter from 'src/shared/components/CustomPeriodFilter/CustomPeriodFilter';
import OwnerFilter from 'src/documents-new/components/FiltersModal/components/OwnerFilter';
import SupplierFilter from 'src/documents-new/components/FiltersModal/components/SupplierFilter';
import CompanyFilter from 'src/documents-new/components/FiltersModal/components/CompanyFilter';
import DocTypeFilter from 'src/documents-new/components/FiltersModal/components/DocTypeFilter';
import styles from './style';

interface PropTypes {
  open: boolean;
  selectedStatus: string;
  selectedPeriod: string;
  users: UserDataType[];
  filteredOwnersList: UserDataType[];
  selectedOwnersList: OwnerType[];
  filteredSuppliersList: SupplierType[],
  selectedSuppliersList: SupplierType[],
  supplierSearch: string;
  supplierFilterPage: number;
  supplierFilterTotal: number;
  docTypeSearch: string;
  docTypeFilterPage: number;
  docTypeFilterTotal: number;
  managedCompanies: CompanyType[];
  filteredCompaniesList: CompanyType[];
  selectedCompaniesList: CompanyType[];
  filteredDocTypesList: Record<string, any>[];
  selectedDocTypesList: Record<string, any>[];
  setFilteredOwnersList: React.Dispatch<React.SetStateAction<UserDataType[]>>;
  setFilteredCompaniesList: React.Dispatch<React.SetStateAction<CompanyType[]>>;
  setFilteredSuppliersList: React.Dispatch<React.SetStateAction<SupplierType[]>>;
  setFilteredDocTypesList: React.Dispatch<React.SetStateAction<Record<string, any>[]>>;
  getAllSuppliers: (_search?: string, _sPage?: number) => Promise<void>;
  getAllDocTypes: (_search?: string, _sPage?: number) => Promise<void>;
  handleClose: () => void;
  handleApplyFilters: (
    _docType: string,
    _period: string,
    _startTime: number,
    _endTime: number,
    _selectedSuppliersList: SupplierType[],
    _selectedOwnersList: OwnerType[],
    _selectedCompaniesList: CompanyType[],
    _selectedDocTypesList: Record<string, any>[],
  ) => void;
}

const FiltersModal = (props: PropTypes) => {
  const { ready, t } = useTranslation();
  const location = useLocation();

  const {
    open,
    selectedStatus,
    selectedPeriod,
    users,
    filteredOwnersList,
    selectedOwnersList: _selectedOwnersList,
    filteredSuppliersList,
    selectedSuppliersList: _selectedSuppliersList,
    supplierSearch,
    supplierFilterPage,
    supplierFilterTotal,
    docTypeSearch,
    docTypeFilterPage,
    docTypeFilterTotal,
    managedCompanies,
    filteredCompaniesList,
    selectedCompaniesList: _selectedCompaniesList,
    filteredDocTypesList,
    selectedDocTypesList: _selectedDocTypesList,
    setFilteredCompaniesList,
    setFilteredOwnersList,
    setFilteredSuppliersList,
    setFilteredDocTypesList,
    getAllSuppliers,
    getAllDocTypes,
    handleClose: _handleClose,
    handleApplyFilters,
  } = props;

  const { user } = useAuth();
  const isSupplierLabelWithVAT = user?.customisations.includes('supplierLabelWithVAT') || false;
  const isToValidateStatusAllowed = user?.customisations.includes('toValidateStatus');

  const companyDocTypes: Record<string, any>[] = useSelector((state: RootState) => state.docTypes.companyDocTypes);

  const [anchorElCustomPeriod, setAnchorElCustomPeriod] = useState<HTMLElement | null>(null);

  const [statusFilter, setStatusFilter] = useState<string>(selectedStatus || statusFilters[0].id);

  const [periodFilter, setPeriodFilter] = useState<string>(selectedPeriod || periodFilterOptions[0].value);
  const [startTimeStamp, setStartTimestamp] = useState<number>(0);
  const [endTimeStamp, setEndTimestamp] = useState<number>(0);

  const docTypesRef = useRef<any>(null);
  const [docTypesFilterOpen, setDocTypesFilterOpen] = useState(false);
  const [selectedDocTypesList, setSelectedDocTypesList] = useState(_selectedDocTypesList);

  const ownersRef = useRef<any>(null);
  const ownerFilterBtnWidth = useWidth(ownersRef, -1);
  const [ownersFilterOpen, setOwnersFilterOpen] = useState(false);
  const [ownerSearch, setOwnerSearch] = useState('');
  const [selectedOwnersList, setSelectedOwnersList] = useState(_selectedOwnersList);

  const suppliersRef = useRef<any>(null);
  const [suppliersFilterOpen, setSuppliersFilterOpen] = useState(false);
  const [selectedSuppliersList, setSelectedSuppliersList] = useState(_selectedSuppliersList);

  const companyRef = useRef<any>(null);
  const [companiesFilterOpen, setCompaniesFilterOpen] = useState(false);
  const [companySearch, setCompanySearch] = useState('');
  const [selectedCompaniesList, setSelectedCompaniesList] = useState(_selectedCompaniesList);

  const isInDocumentsPage = location.pathname.includes('/documents');
  const [selectedDocType, setSelectedDocType] = useState<string | undefined>(
    _selectedDocTypesList[0]?.documentType || companyDocTypes.find((d) => d.isDefault)?.documentType
  );

  useEffect(() => setStatusFilter(selectedStatus || statusFilters[0].id), [selectedStatus]);
  useEffect(() => setPeriodFilter(selectedPeriod || periodFilterOptions[0].value), [selectedPeriod]);
  useEffect(() => setSelectedOwnersList(_selectedOwnersList), [_selectedOwnersList]);
  useEffect(() => setSelectedSuppliersList(_selectedSuppliersList), [_selectedSuppliersList]);
  useEffect(() => setSelectedCompaniesList(_selectedCompaniesList), [_selectedCompaniesList]);
  useEffect(() => {
    setSelectedDocTypesList(_selectedDocTypesList);
    setSelectedDocType(_selectedDocTypesList[0]?.documentType || companyDocTypes.find((d) => d.isDefault)?.documentType);
  }, [_selectedDocTypesList]);

  useEffect(() => {
    if (isInDocumentsPage && !selectedDocType) {
      setSelectedDocType(companyDocTypes.find((d) => d.isDefault)?.documentType);
    }
  }, [companyDocTypes]);

  const handleStatusChange = (val: string) => {
    setStatusFilter(val);
    if (val === invoiceStatusesKeys.archived) {
      setSelectedCompaniesList([]);
    }
  };

  const handleClose = () => {
    setStatusFilter(selectedStatus || statusFilters[0].id);
    setPeriodFilter(selectedPeriod || periodFilterOptions[0].value);
    setStartTimestamp(0);
    setEndTimestamp(0);
    setSelectedOwnersList(_selectedOwnersList);
    setSelectedSuppliersList(_selectedSuppliersList);
    setSelectedCompaniesList(_selectedCompaniesList);
    setSelectedDocTypesList(_selectedDocTypesList);
    _handleClose();
  };

  const handleCustomPeriodClick = () => {
    setAnchorElCustomPeriod(document.getElementById('period-filter'));
  };

  const handleCustomPeriodClose = () => {
    setAnchorElCustomPeriod(null);
  };

  const handleCustomPeriodApply = (start: number, end: number) => {
    setAnchorElCustomPeriod(null);
    const fromTimeStamp = Math.floor(new Date(start).getTime() / 1000);
    const toTimeStamp = Math.floor(new Date(end).getTime() / 1000);
    setStartTimestamp(fromTimeStamp);
    setEndTimestamp(toTimeStamp);
  };

  const handleOwnerSearch = (val: string) => {
    setOwnerSearch(val);
    const searchVal = val.toLowerCase();
    if (searchVal) {
      const newFilteredOwners = users
        .filter((owner) => owner.firstname?.toLowerCase().includes(searchVal) || owner.lastname?.toLowerCase().includes(searchVal));
      setFilteredOwnersList(newFilteredOwners);
    } else {
      setFilteredOwnersList(users);
    }
  };

  const handleOwnerClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent> | React.MouseEvent<SVGSVGElement, MouseEvent>,
    owner: OwnerType
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (selectedOwnersList.some((s) => s.email === owner.email)) {
      setSelectedOwnersList((prevState) => prevState.filter((s) => s.email !== owner.email));
    } else {
      setSelectedOwnersList((prevState) => [...prevState, owner]);
    }
  };

  const handleSupplierFilterPageChange = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setFilteredSuppliersList([]);
    setSelectedSuppliersList([]);

    getAllSuppliers(supplierSearch, newPage);
  };

  const handleSupplierSearch = (val: string) => {
    const searchVal = val.toLowerCase();
    getAllSuppliers(searchVal);
  };

  const handleSupplierClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent> | React.MouseEvent<SVGSVGElement, MouseEvent>,
    supplier: SupplierType
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (selectedSuppliersList.some((s) => s.creditorCode === supplier.creditorCode)) {
      setSelectedSuppliersList((prevState) => prevState.filter((s) => s.creditorCode !== supplier.creditorCode));
    } else {
      setSelectedSuppliersList((prevState) => [...prevState, supplier]);
    }
  };

  const handleDocTypeFilterPageChange = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setFilteredDocTypesList([]);
    setSelectedDocTypesList([]);

    getAllDocTypes(docTypeSearch, newPage);
  };

  const handleDocTypeSearch = (val: string) => {
    const searchVal = val.toLowerCase();
    getAllDocTypes(searchVal);
  };

  const handleDocTypeClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent> | React.MouseEvent<SVGSVGElement, MouseEvent>,
    docType: Record<string, any>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (selectedDocTypesList.some((s) => s.documentType === docType.documentType)) {
      setSelectedDocTypesList((prevState) => prevState.filter((s) => s.documentType !== docType.documentType));
    } else {
      setSelectedDocTypesList((prevState) => [...prevState, docType]);
    }
  };

  const handleCompanySearch = (val: string) => {
    setCompanySearch(val);
    const searchVal = val.toLowerCase();
    if (searchVal) {
      const newFilteredCompanies = managedCompanies
        .filter((company) => company.name?.toLowerCase().includes(searchVal) || company.companyId?.toLowerCase().includes(searchVal));
      setFilteredCompaniesList(newFilteredCompanies);
    } else {
      setFilteredCompaniesList(managedCompanies);
    }
  };

  const handleCompanyClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent> | React.MouseEvent<SVGSVGElement, MouseEvent>,
    company: CompanyType
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (selectedCompaniesList.some((c) => c.companyId === company.companyId)) {
      setSelectedCompaniesList((prevState) => prevState.filter((c) => c.companyId !== company.companyId));
    } else {
      if (selectedCompaniesList.length === 5) {
        return;
      }
      setSelectedCompaniesList((prevState) => [...prevState, company]);
    }
  };

  const handleChangeAutoComplete = (value: Record<string, any>) => {
    if (value && (value.documentType)) {
      setSelectedDocType(value.documentType);
      setSelectedDocTypesList([value]);
    }
  };

  return (
    <Dialog
      open={open}
      sx={styles.modal}
      onClose={handleClose}
    >
      <Box sx={styles.container}>
        <Box sx={styles.modalHeader}>
          <Box sx={styles.titleContainer}>
            <Typography sx={styles.headerTitle}>
              {t('DASHBOARD_INVOICES_FILTERED')}
            </Typography>
            <IconButton sx={styles.closeBtn} onClick={handleClose}>
              <CloseIcon sx={styles.closeIcon} />
            </IconButton>
          </Box>
        </Box>
        <Box sx={styles.modalContent}>
          <Box sx={isInDocumentsPage ? styles.filterRowSelect : styles.filterRow}>
            <Typography sx={styles.filterLabel}>{t('DASHBOARD_FILTERS_DOC_TYPE')}</Typography>
            {
              isInDocumentsPage
                ? (
                  <Autocomplete
                    fullWidth
                    onChange={(_, val) => handleChangeAutoComplete(val as unknown as Record<string, any>)}
                    value={filteredDocTypesList.find((d) => d.documentType === selectedDocType) || null}
                    options={filteredDocTypesList.filter((d) => d.hasFields)}
                    getOptionLabel={(option) => typeof option === 'string' ? option : t(option.name)}
                    getOptionKey={(option) => option.documentType}
                    filterOptions={(options, state) => options.filter((option) => {
                      if (option.documentType && option.name) {
                        return option.documentType.toLowerCase().includes(state.inputValue.toLowerCase())
                          || option.name.toLowerCase().includes(state.inputValue.toLowerCase());
                      }
                      return false;
                    })}
                    sx={styles.autoComplete}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="name"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                        }}
                        sx={styles.autoCompleteTextField}
                      />
                    )}
                  />
                )
                : (
                  <>
                    <Button
                      ref={docTypesRef}
                      onClick={() => setDocTypesFilterOpen(true)}
                      sx={{
                        ...styles.filterBtn,
                        justifyContent: selectedDocTypesList.length === 0 ? 'space-between' : 'flex-end'
                      }}
                      endIcon={docTypesFilterOpen ? <ArrowDropUpIcon sx={styles.sortIcon} /> :  <ArrowDropDownIcon sx={styles.sortIcon} />}
                    >
                      <Box sx={styles.filterBtnContent}>
                        {selectedDocTypesList.length === 0 && t('DASHBOARD_FILTERS_NOT_SELECTED')}
                        {selectedDocTypesList.map((docType) => (
                          <Box key={docType.documentType} marginRight="6px" display="flex" alignItems="center" justifyContent="space-between">
                            {docType.name}
                            <CloseIcon sx={styles.closeIconText} onClick={(e) => handleDocTypeClick(e, docType)} />
                          </Box>
                        ))}
                      </Box>
                    </Button>
                    <DocTypeFilter
                      open={docTypesFilterOpen}
                      filterRef={docTypesRef}
                      btnWidth={ownerFilterBtnWidth}
                      searchQuery={docTypeSearch}
                      filteredList={filteredDocTypesList}
                      selectedList={selectedDocTypesList}
                      page={docTypeFilterPage}
                      total={docTypeFilterTotal}
                      handleClose={() => setDocTypesFilterOpen(false)}
                      handleSearch={handleDocTypeSearch}
                      handleClick={handleDocTypeClick}
                      handlePageChange={handleDocTypeFilterPageChange}
                    />
                  </>
                )
            }
          </Box>
          {
            !isInDocumentsPage &&
              <Box sx={styles.supplierFilterActions}>
                <Typography
                  sx={styles.supplierFilterActionText}
                  onClick={() => setSelectedDocTypesList([])}
                >
                  {ready && t('DASHBOARD_INVOICES_SUPPLIERS_REMOVE_ALL')}
                </Typography>
              </Box>
          }
          <Box sx={{ ...styles.filterRow, marginBottom: '8px' }}>
            <Typography sx={styles.filterLabel}>{t('DASHBOARD_FILTERS_STATUS')}</Typography>
            <Select
              onChange={(e) => handleStatusChange(e.target.value)}
              sx={styles.select}
              value={statusFilter}
              variant="outlined"
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left'
                },
                sx: styles.ownerMenuContainer
              }}
            >
              {statusFilters.map((f) => {
                if (isToValidateStatusAllowed && f.id === invoiceStatusesKeys.reviewed) {
                  return null;
                }
                if (!isToValidateStatusAllowed && f.id === invoiceStatusesKeys.tovalidate) {
                  return null;
                }
                return (
                  <MenuItem sx={styles.menuItem} key={f.id} value={f.id}>
                    {t(f.label)}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <Box id="period-filter" sx={{ ...styles.filterRow, marginBottom: '8px' }}>
            <Typography sx={styles.filterLabel}>{t('DASHBOARD_INVOICES_PERIOD')}</Typography>
            <Select
              onChange={(e) => setPeriodFilter(e.target.value)}
              sx={styles.select}
              value={periodFilter}
              variant="outlined"
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left'
                },
                sx: styles.ownerMenuContainer
              }}
            >
              {periodFilterOptions.map((f) => (
                <MenuItem onClick={() => f.value === 'custom' ? handleCustomPeriodClick() : () => {}} sx={styles.menuItem} key={f.value} value={f.value}>
                  {t(f.label)}
                </MenuItem>
              ))}
            </Select>
            <CustomPeriodFilter
              anchorElCustomPeriod={anchorElCustomPeriod}
              handleCustomPeriodClose={handleCustomPeriodClose}
              handleCustomPeriodApply={handleCustomPeriodApply}
            />
          </Box>
          <Box sx={styles.filterRow}>
            <Typography sx={styles.filterLabel}>{t('DASHBOARD_INVOICES_USERS_FILTER')}</Typography>
            <Button
              ref={ownersRef}
              onClick={() => setOwnersFilterOpen(true)}
              sx={{
                ...styles.filterBtn,
                justifyContent: (selectedOwnersList.length === filteredOwnersList.length || selectedOwnersList.length === 0) ? 'space-between' : 'flex-end'
              }}
              endIcon={ownersFilterOpen ? <ArrowDropUpIcon sx={styles.sortIcon} /> :  <ArrowDropDownIcon sx={styles.sortIcon} />}
            >
              <Box sx={styles.filterBtnContent}>
                {(selectedOwnersList.length === filteredOwnersList.length || selectedOwnersList.length === 0) && t('DASHBOARD_FILTERS_NOT_SELECTED')}
                {!(selectedOwnersList.length === filteredOwnersList.length || selectedOwnersList.length === 0)
                  && selectedOwnersList.map((u) => {
                    const owner = filteredOwnersList.find((o) => o.email === u.email);
                    return owner &&
                      <Box key={owner.email} marginRight="6px" display="flex" alignItems="center" justifyContent="space-between">
                        {`${owner.firstname} ${owner.lastname}`}
                        <CloseIcon sx={styles.closeIconText} onClick={(e) => handleOwnerClick(e, u)} />
                      </Box>;
                  })}
              </Box>
            </Button>
            <OwnerFilter
              open={ownersFilterOpen}
              filterRef={ownersRef}
              btnWidth={ownerFilterBtnWidth}
              searchQuery={ownerSearch}
              filteredList={filteredOwnersList}
              selectedList={selectedOwnersList}
              handleClose={() => setOwnersFilterOpen(false)}
              handleSearch={handleOwnerSearch}
              handleClick={handleOwnerClick}
            />
          </Box>
          <Box sx={styles.supplierFilterActions}>
            <Typography
              sx={styles.supplierFilterActionText}
              onClick={() => setSelectedOwnersList(filteredOwnersList)}
            >
              {ready && t('DASHBOARD_INVOICES_SUPPLIERS_CHOOSE_ALL')}
            </Typography>
            <Typography
              sx={styles.supplierFilterActionText}
              onClick={() => setSelectedOwnersList([])}
            >
              {ready && t('DASHBOARD_INVOICES_SUPPLIERS_REMOVE_ALL')}
            </Typography>
          </Box>
          <Box sx={styles.filterRow}>
            <Typography sx={styles.filterLabel}>{t('DASHBOARD_INVOICES_SUPPLIERS')}</Typography>
            <Button
              ref={suppliersRef}
              onClick={() => setSuppliersFilterOpen(true)}
              sx={{
                ...styles.filterBtn,
                justifyContent: selectedSuppliersList.length === 0 ? 'space-between' : 'flex-end'
              }}
              endIcon={suppliersFilterOpen ? <ArrowDropUpIcon sx={styles.sortIcon} /> :  <ArrowDropDownIcon sx={styles.sortIcon} />}
            >
              <Box sx={styles.filterBtnContent}>
                {selectedSuppliersList.length === 0 && t('DASHBOARD_FILTERS_NOT_SELECTED')}
                {selectedSuppliersList.map((supplier) => (
                  <Box key={supplier.creditorCode} marginRight="6px" display="flex" alignItems="center" justifyContent="space-between">
                    {getSupplierLabel(supplier, isSupplierLabelWithVAT)}
                    <CloseIcon sx={styles.closeIconText} onClick={(e) => handleSupplierClick(e, supplier)} />
                  </Box>
                ))}
              </Box>
            </Button>
            <SupplierFilter
              open={suppliersFilterOpen}
              filterRef={suppliersRef}
              btnWidth={ownerFilterBtnWidth}
              searchQuery={supplierSearch}
              filteredList={filteredSuppliersList}
              selectedList={selectedSuppliersList}
              page={supplierFilterPage}
              total={supplierFilterTotal}
              handleClose={() => setSuppliersFilterOpen(false)}
              handleSearch={handleSupplierSearch}
              handleClick={handleSupplierClick}
              handlePageChange={handleSupplierFilterPageChange}
            />
          </Box>
          <Box sx={styles.supplierFilterActions}>
            <Typography
              sx={styles.supplierFilterActionText}
              onClick={() => setSelectedSuppliersList([])}
            >
              {ready && t('DASHBOARD_INVOICES_SUPPLIERS_REMOVE_ALL')}
            </Typography>
          </Box>
          {((user?.companyID === '9800424377' || !user?.isSubCompany) && statusFilter !== invoiceStatusesKeys.archived) && (
            <>
              <Box sx={styles.filterRow}>
                <Typography sx={styles.filterLabel}>{t('DASHBOARD_INVOICES_COMPANIES')}</Typography>
                <Button
                  ref={companyRef}
                  onClick={() => setCompaniesFilterOpen(true)}
                  sx={{
                    ...styles.filterBtn,
                    justifyContent: selectedCompaniesList.length === 0 ? 'space-between' : 'flex-end'
                  }}
                  endIcon={companiesFilterOpen ? <ArrowDropUpIcon sx={styles.sortIcon} /> :  <ArrowDropDownIcon sx={styles.sortIcon} />}
                >
                  <Box sx={styles.filterBtnContent}>
                    {selectedCompaniesList.length === 0 && t('DASHBOARD_FILTERS_NOT_SELECTED')}
                    {selectedCompaniesList.map((company) => (
                      <Box key={company.companyId} marginRight="6px" display="flex" alignItems="center" justifyContent="space-between">
                        {company.name}
                        <CloseIcon sx={styles.closeIconText} onClick={(e) => handleCompanyClick(e, company)} />
                      </Box>
                    ))}
                  </Box>
                </Button>
                <CompanyFilter
                  open={companiesFilterOpen}
                  filterRef={companyRef}
                  btnWidth={ownerFilterBtnWidth}
                  searchQuery={companySearch}
                  filteredList={filteredCompaniesList}
                  selectedList={selectedCompaniesList}
                  handleClose={() => setCompaniesFilterOpen(false)}
                  handleSearch={handleCompanySearch}
                  handleClick={handleCompanyClick}
                />
              </Box>
              <Box sx={styles.supplierFilterActions}>
                <Typography
                  sx={styles.supplierFilterActionText}
                  onClick={() => setSelectedCompaniesList([])}
                >
                  {ready && t('DASHBOARD_INVOICES_SUPPLIERS_REMOVE_ALL')}
                </Typography>
              </Box>
            </>
          )}
        </Box>
        <Box display="flex" justifyContent="flex-end" sx={styles.modalFooter}>
          <Button
            variant="text"
            sx={styles.cancelBtn}
            onClick={() =>
              handleApplyFilters('', '', 0, 0, [], [], [], [])
            }
          >
            {t('DASHBOARD_FILTERS_RESET')}
          </Button>
          <Button
            variant="contained"
            sx={styles.submitBtn}
            onClick={() =>
              handleApplyFilters(
                statusFilter,
                periodFilter,
                startTimeStamp,
                endTimeStamp,
                selectedSuppliersList,
                selectedOwnersList,
                selectedCompaniesList,
                selectedDocTypesList
              )
            }
          >
            {t('DASHBOARD_INVOICES_APPLY')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default FiltersModal
