import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import theme from 'src/theme';

function EmptyText({ str }) {
  const { t, ready } = useTranslation();

  return (
    <div style={styles.root}>
      <Box width={800}>
        <Typography align="center" sx={styles.text}>
          {ready && t(str)}
        </Typography>
      </Box>
    </div>
  );
}

export default EmptyText;

const styles = {
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    maxHeight: 'calc(100vh - 56px)',
    justifyContent: 'center',
    padding: theme.spacing(3)
  },
  text: {
    color: theme.palette.text.primary,
    fontSize: '16px',
    fontWeight: '500',
  }
}
