import heic2any from 'heic2any';
import { ResponseType } from 'axios';

import { Box } from '@mui/material';
import {
  Check as TrustedIcon,
  ErrorOutline as NotSureIcon,
  WarningAmber as ErrorIcon,
  Timelapse as ToReviewIcon,
  Block as RejectedIcon,
  PlaylistAddCheck as ToValidateIcon,
  CloudDownload as ExportedIcon,
  ReportGmailerrorred as ErroredIcon,
  Inventory as ArchivedIcon,
  DeleteOutline as DeleteIcon,
  RateReview as ReviewedIcon,
  ContentCut as SplitIcon,
  Lock as OutOfLimitIcon,
} from '@mui/icons-material';

import AnnotatedIcon from 'src/document-edit/icons/AnnotatedIcon';
import ToExportIcon from 'src/document-edit/icons/ExportNotes';
import PossibleDuplicateIcon from 'src/document-edit/icons/PossibleDuplicateIcon';
import { invoiceStatusesKeys } from 'src/config';

export const statusFilters = [
  {
    id: invoiceStatusesKeys.all,
    label: 'DASHBOARD_INVOICES_ALL',
  },
  {
    id: invoiceStatusesKeys.duplicated,
    label: 'DASHBOARD_INVOICES_DUPLICATED',
  },
  {
    id: invoiceStatusesKeys.toreview,
    label: 'DASHBOARD_INVOICES_REVIEW',
  },
  {
    id: invoiceStatusesKeys.reviewed,
    label: 'DASHBOARD_INVOICES_REVIEWED',
  },
  {
    id: invoiceStatusesKeys.tovalidate,
    label: 'DASHBOARD_INVOICES_VALIDATE',
  },
  {
    id: invoiceStatusesKeys.toexport,
    label: 'DASHBOARD_INVOICES_TO_EXPORT',
  },
  {
    id: invoiceStatusesKeys.exported,
    label: 'DASHBOARD_INVOICES_EXPORTED',
  },
  {
    id: invoiceStatusesKeys.rejected,
    label: 'DASHBOARD_INVOICES_REJECTED',
  },
  {
    id: invoiceStatusesKeys.errored,
    label: 'DASHBOARD_INVOICES_ERRORED',
  },
  {
    id: invoiceStatusesKeys.archived,
    label: 'DASHBOARD_INVOICES_ARCHIVED',
  },
  {
    // rename the i18n key
    id: invoiceStatusesKeys.splitRequest,
    label: 'DASHBOARD_INVOICES_SPLT',
  },
];

export const getHeader = (e: ProgressEvent<FileReader>) => {
  const res = e.target?.result;
  let header = '';
  if (res) {
    const arr = (new Uint8Array(res as ArrayBufferLike)).subarray(0, 4);
    for (let j = 0; j < arr.length; j++) {
      header += arr[j].toString(16);
    }
  }

  return header;
};

export const getConvertedFile = async (file: File) => {
  const res = await fetch(URL.createObjectURL(file));
  const blob = await res.blob();
  const conversionResult = await heic2any({ blob, toType: 'image/jpeg' });

  return conversionResult as Blob;
};

export const readFileAsync = async (file: File) => new Promise((resolve, reject) => {
  const fileReader = new FileReader();
  fileReader.onloadend = async (e) => {
    const header = getHeader(e);
    resolve(header);
  };
  fileReader.onerror = reject;
  fileReader.readAsArrayBuffer(file);
});

export const kbLinks: Record<string, string> = {
  Procys: 'https://procys.com__LANG__/help-center',
  Extrable: 'https://extrable.es/#FormularioExtrable'
};

export const invoiceStatusCodesList = [500, 501, 502, 503, 510, 600, 900];

export const documentStatusLabels = {
  all: 'INVOICE_STATUS_ALL',
  uploading: 'INVOICE_STATUS_UPLOADING',
  digitized: 'INVOICE_STATUS_DIGITIZED',
  toreview: 'INVOICE_STATUS_TO_REVIEW',
  reviewed: 'INVOICE_STATUS_TO_REVIEWED',
  tovalidate: 'INVOICE_STATUS_TO_VALIDATE',
  toexport: 'INVOICE_STATUS_TO_EXPORT',
  exported: 'INVOICE_STATUS_EXPORTED',
  rejected: 'INVOICE_STATUS_REJECTED',
  archived: 'INVOICE_STATUS_ARCHIVED',
  outOfLimit: 'INVOICE_STATUS_OUT_OF_LIMIT',
  deleted: 'INVOICE_STATUS_DELETED',
  errored: 'INVOICE_STATUS_ERRORED',
  splitRequest: 'DASHBOARD_INVOICES_SPLT',
  possibleDuplicate: 'DASHBOARD_INVOICES_DUPLICATED'
};

export const invoiceStatusCode: Record<string, number> = {
  digitized: 16,
  splitRequest: 200,
  duplicated: 201,
  toreview: 500,
  reviewed: 509,
  tovalidate: 501,
  toexport: 502,
  exported: 503,
  rejected: 510,
  archived: 600,
  outOfLimit: 999,
  errored: 100,
};

export const getDocumentStatusLabel = (status: number) => {
  if (!status || status < 16) {
    return documentStatusLabels.uploading;
  }

  switch (status) {
    case 16:
      return documentStatusLabels.digitized;
    case 500:
      return documentStatusLabels.toreview;
    case 509:
      return documentStatusLabels.reviewed;
    case 501:
      return documentStatusLabels.tovalidate;
    case 502:
      return documentStatusLabels.toexport;
    case 503:
      return documentStatusLabels.exported;
    case 510:
      return documentStatusLabels.rejected;
    case 600:
      return documentStatusLabels.archived;
    case 999:
      return documentStatusLabels.outOfLimit;
    case 900:
      return documentStatusLabels.deleted;
    case 200:
      return documentStatusLabels.splitRequest;
    case 201:
      return documentStatusLabels.possibleDuplicate;

    default:
      return documentStatusLabels.errored;
  }
};

export const getIconOnTrustScore = (trustScore: number, isError: boolean, isDashboard: boolean, isWarning: boolean) => {
  if (isError) {
    return <ErrorIcon sx={styles.errorIcon} />;
  }
  if (trustScore === 0 || isWarning) {
    return <NotSureIcon sx={styles.notSureIcon} />;
  }
  if (trustScore === 1) {
    return <TrustedIcon sx={styles.trustedIcon} />
  }
  if (trustScore === 2) {
    return <AnnotatedIcon />
  }
  return isDashboard ? null : <Box sx={styles.labelIconPlaceholder} />;
};

export const getStatusIcon = (status: number) => {
  switch(status) {
    case 100:
      return <ErroredIcon sx={{ color: '#FF4D49', ...styles.statusIcon }} />;
    case 500:
      return <ToReviewIcon sx={{ color: '#3E8AFF', ...styles.statusIcon }} />
    case 509:
      return <ReviewedIcon sx={{ color: '#9747FF', ...styles.statusIcon }} />
    case 501:
      return <ToValidateIcon sx={{ color: '#FDB528', ...styles.statusIcon }} />
    case 502:
      return <ToExportIcon scale={0.8} />
    case 503:
      return <ExportedIcon sx={{ color: '#3E8AFF', ...styles.statusIcon }} />
    case 510:
      return <RejectedIcon sx={{ color: '#FF4D49', ...styles.statusIcon }} />
    case 600:
      return <ArchivedIcon sx={{ color: '#6D788D', ...styles.statusIcon }} />
    case 900:
      return <DeleteIcon sx={{ color: '#FF4D49', ...styles.statusIcon }} />
    case 999:
      return <OutOfLimitIcon sx={{ color: '#4242424D', ...styles.statusIcon }} />
    case 200:
      return <SplitIcon sx={{ color: '#3E8AFF', rotate: '270deg', ...styles.statusIcon }} />
    case 201:
      return <PossibleDuplicateIcon />
  }
};

const styles = {
  labelIconPlaceholder: {
    width: '18px',
    height: '18px',
  },
  errorIcon: {
    width: '18px',
    height: '18px',
    color: '#FF4D49',
  },
  notSureIcon: {
    width: '18px',
    height: '18px',
    color: '#FDB528',
  },
  trustedIcon: {
    width: '18px',
    height: '18px',
    color: '#3E8AFF',
  },
  statusIcon: {
    size: '20px',
    width: '20px',
    height: '20px'
  },
};

export const axiosHeadersWithArrayBuffer = (token: string | null) => (
  {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`
    },
    responseType: 'arraybuffer' as ResponseType
  }
);

export const periodFilterOptions = [
  {
    key: 100,
    value: 'all',
    label: 'DASHBOARD_INVOICES_PERIOD_ALL'
  },
  {
    key: 1,
    value: 'tweek',
    label: 'DASHBOARD_INVOICES_PERIOD_THIS_WEEK'
  },
  {
    key: 2,
    value: 'lweek',
    label: 'DASHBOARD_INVOICES_PERIOD_LAST_WEEK'
  },
  {
    key: 3,
    value: 'tmonth',
    label: 'DASHBOARD_INVOICES_PERIOD_THIS_MONTH'
  },
  {
    key: 4,
    value: 'lmonth',
    label: 'DASHBOARD_INVOICES_PERIOD_LAST_MONTH'
  },
  {
    key: 5,
    value: 'tquater',
    label: 'DASHBOARD_INVOICES_PERIOD_THIS_QUARTER'
  },
  {
    key: 6,
    value: 'lquater',
    label: 'DASHBOARD_INVOICES_PERIOD_LAST_QUARTER'
  },
  {
    key: 7,
    value: 'tyear',
    label: 'DASHBOARD_INVOICES_PERIOD_THIS_YEAR'
  },
  {
    key: 8,
    value: 'lyear',
    label: 'DASHBOARD_INVOICES_PERIOD_LAST_YEAR'
  },
  {
    key: 99,
    value: 'custom',
    label: 'DASHBOARD_INVOICES_PERIOD_CUSTOM'
  }
];

export const getDocumentTypeLabel = (type: string, docTypes: Record<string, any>[]) => {
  const docType = docTypes.find((dt) => dt.documentType === type);
  return docType ? docType.name : type;
}

export const getCurrentTimeInyyyymmdd_hhmmssFormat = () => {
  const now = new Date();

  const pad = (num: number) => num.toString().padStart(2, "0");

  const year = now.getFullYear();
  const month = pad(now.getMonth() + 1);
  const day = pad(now.getDate());
  const hours = pad(now.getHours());
  const minutes = pad(now.getMinutes());
  const seconds = pad(now.getSeconds());

  return `${year}${month}${day}_${hours}${minutes}${seconds}`;
};
