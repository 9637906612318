import { Dispatch, SetStateAction, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import FileSaver from 'file-saver';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Grid, Backdrop, CircularProgress } from '@mui/material';

import VatCodeModal from 'src/dimensions-new/vatcodes/components/VatCodeModal';
import { useAuth } from 'src/hooks/useAuth';
import { fetchVatCodeList } from 'src/dimensions/vatcodes/actions';
import { axiosDeleteHeaders, axiosHeaders, isActionPermitted, permissions } from 'src/utils/helpers';
import { useConfig } from 'src/hooks/useConfig';
import TopDisplay from 'src/dimensions-new/components/TopDisplay';
import Search from 'src/dimensions-new/components/Search';
import VatCodesTable from 'src/dimensions-new/components/DimensionsTable';
import LoadingIndicator from 'src/shared/components/LoadingIndicator';
import TablePagination from 'src/dimensions-new/components/TablePagination';
import { UnknownAction } from 'redux';

type VatCodeType = {
  code: string;
  name: string;
  ratePercentage: string;
  ruleAccount: string;
};

type StateType = {
  vatcode: {
    vatCodeList: VatCodeType[];
    total: number;
  };
};

const VatCodes = (props: { showImport: boolean, setShowImport: Dispatch<SetStateAction<boolean>> }) => {
  const { showImport, setShowImport } = props;
  const { ready, t } = useTranslation();
  const [vatCodeModalOpen, setVatCodeModalOpen] = useState<boolean>(false);
  const [selectedVATGroupCode,setSelectedVATGroupCode] = useState<VatCodeType | null>(null);
  const [, setNewlyAddedVal] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [data, setData] = useState<VatCodeType[]>([]);
  const [query, setQuery] = useState<string>('');
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const title = ready ? t('DASHBOARD_NAV_VAT_CODES') : '';
  const { user } = useAuth();
  const { API } = useConfig();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [resetSearch] = useState<boolean>(false);

  const _vatCodeList = useSelector((state: StateType) => state.vatcode.vatCodeList);
  const vatCodeList = useMemo(() => _vatCodeList, [_vatCodeList]);
  const total = useSelector((state: StateType) => state.vatcode.total);

  const onVatCoddeModalClose = () => {
    setVatCodeModalOpen(false);
    setSelectedVATGroupCode(null);
  };

  const handleShowAddVatCode = () => {
    setIsDisabled(false);
    setSelectedVATGroupCode(null);
    setVatCodeModalOpen(true);
  };

  const handleChange = (str: string) => {
    setQuery(str);
    getAllVatCodes(0, limit, str, false);
  };

  const getAllVatCodes = async (
    page = 0,
    limit = 10,
    search = query,
    loadFullScreen = true
  ) => {
    if (loadFullScreen) {
      setLoading(true);
    }
    dispatch(fetchVatCodeList(user?.companyID, page, limit, search) as unknown as UnknownAction);
    setLoading(false);
  };

  const onVatCodeCreated = (val: string) => {
    setNewlyAddedVal(val);
  };

  const onLimitChange = async (limit: number) => {
    setLimit(limit);
    setShowLoading(true);
    await getAllVatCodes(0, limit, query, false);
    setShowLoading(false);
  };

  const onPageChange = async (page: number) => {
    setPage(page);
    setShowLoading(true);
    await getAllVatCodes(page, limit, query, false);
    setShowLoading(false);
  };

  const handleEdit = (data: VatCodeType) => {
    setSelectedVATGroupCode(data);
    setIsDisabled(false);
    setVatCodeModalOpen(true);
  };
  
  const handleView = (data: VatCodeType) => {
    setSelectedVATGroupCode(data);
    setIsDisabled(true);
    setVatCodeModalOpen(true);
  };

  const handleDelete = async (selectedVatCode: VatCodeType) => {
    try {
      setLoading(true);
      const response = await Axios.delete(
        `${API.vatCodesByCompany}/${user?.companyID}`,
        axiosDeleteHeaders(localStorage.getItem('PROCYS_accessToken'), selectedVatCode),
      );

      if (response.data.success) {
        getAllVatCodes(page);
        enqueueSnackbar(t('DIMENSION_DELETE_SUCCESS'), {
          variant: 'success',
          persist: false
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(t('DIMENSION_DELETE_FAILED'), {
        variant: 'error',
        persist: false
      });
    }
  };

  const handleExport = async (format: string, closeMode: () => void) => {
    try {
      const response = await Axios.get(
        `${API.dimensions}vatcodes/export/${format}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      const fileName = `${user?.company} VAT Rates.${format}`;
      const blob = new Blob([response.data], {
        type: 'text/plain;charset=utf-8'
      });
      FileSaver.saveAs(blob, fileName);
      closeMode();
    } catch (error) {
      closeMode();
      enqueueSnackbar(t('DIMENSION_EXPORT_FAIL'), {
        variant: 'error',
        persist: false
      });
    }
  };

  useEffect(() => {
    if (isActionPermitted(permissions.dimensionRead, user?.permissions)) {
      getAllVatCodes(page);
    }
  }, []);

  useEffect(() => {
    if (vatCodeList && Array.isArray(vatCodeList)) {
      setData(vatCodeList.slice(0, limit));
    }
  }, [vatCodeList]);

  return (
    <>
      <>
        {!showLoading ? (
          <Box sx={styles.root} className="x-scroll">
            <Grid sx={styles.wrapper}>
              <TopDisplay
                title={title}
                showImport={showImport}
                setShowImport={setShowImport}
                type="vatcodes"
                onExport={handleExport}
                onAdd={handleShowAddVatCode}
              />
              <Search onChange={handleChange} reset={resetSearch} />
              <VatCodesTable
                data={data}
                type="vatrate"
                onDelete={handleDelete}
                deleteText="DIMENSION_DELETE_VATRATE_BTN"
                onEdit={handleEdit}
                onView={handleView}
              />
              <TablePagination
                total={total || 0}
                limit={limit || 10}
                page={page || 0}
                onLimitChange={onLimitChange}
                onPageChange={onPageChange}
              />
            </Grid>
          </Box>
        ) : (
          <LoadingIndicator />
        )}
        <Backdrop style={styles.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
      <VatCodeModal
        vatCodeModalOpen={vatCodeModalOpen}
        handleClose={onVatCoddeModalClose}
        getAllVatCodes={getAllVatCodes}
        onSuccess={onVatCodeCreated}
        selectedVatCode={selectedVATGroupCode}
        isDisabled={isDisabled}
      />
    </>
  );
};
const styles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '20px'
  },
  wrapper: {
    backgroundColor: '#fff',
    minWidth: '520px'
  },
  top: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '5px 0',
    gap: '10px',
    marginBlock: '0 20px'
  },
  topLeft: {
    fontSize: '16px',
    color: '#4C4E64',
    fontWeight: '500',
    marginInlineEnd: 'auto'
  },
  btns: {
    gap: '8px',
    color: '#3E8AFF',
    textTransform: 'Capitalize',
    fontSize: '13px',
    paddingInline: '10px'
  },
  addBtn: {
    background: '#3E8AFF',
    color: '#fff',
    paddingInline: '15px',
    gap: '8px',
    textTransform: 'Capitalize',
    fontSize: '13px',
    '&:hover': {
      backgroundColor: '#3E8AFF',
      color: '#fff'
    }
  },
  menuContainer: {
    boxShadow: ' 0px 4px 8px -4px #4C4E646B',
    width: '200px'
  },
  exportIconContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    fontSize: '14px',
    color: '#4C4E64DE'
  },
  backdrop: {
    zIndex: 111111,
    color: '#ffffff'
  }
};
export default VatCodes;
