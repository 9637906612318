import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PDFDocument } from 'pdf-lib';
import { useSnackbar } from 'notistack';
import Axios from 'axios';

import {
  Backdrop, Box, Button, CircularProgress,
} from '@mui/material';
import { RiAddCircleLine } from '@remixicon/react';

import { useConfig } from 'src/hooks/useConfig';
import { CustomError } from 'src/types';
import { appendContactSupport, axiosHeadersFormData, getLocalisedErrorString, isFileMIMETypeHEIC } from 'src/utils/helpers';
import { getConvertedFile, getHeader } from 'src/dashboard-new/utils';
import PdfView from 'src/documents-new/components/SplitMenu/PdfView/PdfView';
import ManageFieldsSideMenu from '../ManageFieldsSideMenu/ManageFieldsSideMenu';
import styles from './style';
import { hasUserModifiedData } from 'src/doc-types/utils';
import ConfirmCancelPopup from '../ConfirmCancelPopup/ConfirmCancelPopup';

type PropsType = {
  open: boolean;
  selectedDocType: Record<string, any> | null;
  fields: Record<string, any>[];
  sectionFields: Record<string, Record<string, any>[]>;
  fetchDocTypes: (_pageNo?: number, _pageLimit?: number) => Promise<void>;
  handleClose: () => void;
}

const DocTypeManageFields = (props: PropsType) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { API } = useConfig();
  const { open, selectedDocType, fields, sectionFields, fetchDocTypes, handleClose: _handleClose } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [document, setDocument] = useState<any>(null);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [fieldsList, setFieldsList] = useState<Record<string, any>[]>(fields);
  const [sectionFieldsList, setSectionFieldsList] = useState<Record<string, Record<string, any>[]>>(sectionFields || {});
  const [selectedField, setSelectedField] = useState<Record<string, any> | null>(null);
  const [addNewOpen, setAddNewOpen] = useState<boolean>(false);
  const [showAPIRef, setShowAPIRef] = useState<boolean>(false);
  const [showClosePopup, setShowClosePopup] = useState<boolean>(false);

  const handleClose = () => {
    if (hasUserModifiedData(fields, fieldsList, sectionFields, sectionFieldsList)) {
      setShowClosePopup(true);
    } else {
      proceedToClose();
    }
  };

  const proceedToClose = () => {
    setDocument(null);
    setTotalPages(0);
    setFieldsList([]);
    setSectionFieldsList({});
    setSelectedField(null);
    setAddNewOpen(false);
    _handleClose();
    setShowClosePopup(false);
  };
 
  useEffect(() => setFieldsList(fields), [fields]);

  useEffect(() => setSectionFieldsList(sectionFields || {}), [sectionFields]);

  async function getPageCount(res: any) : Promise<number>{
    let formPdfBytes = await res.arrayBuffer();
    const pdfDoc = await PDFDocument.load(formPdfBytes);
    const pageCount = pdfDoc.getPageCount();
    return pageCount;
  }

  const getImage = async (name: string | undefined) => {
    if (!name) {
      return;
    }
    setLoading(true);
    try {
      const response = await Axios.get(
        `${API.documentTypes}/file/${name}`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `bearer ${localStorage.getItem('PROCYS_accessToken')}`
          }
        }
      );
      const blob = response.data;
      const file = window.URL.createObjectURL(blob);
      let doc = await getPageCount(blob);
      setTotalPages(doc);
      setDocument(file);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(()=>{
    if (open) {
      getImage(selectedDocType?.documentType);
    }
  }, [selectedDocType]);

  const addDoc = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const { files } = e.target;
    if (files && files.length === 1) {
      const file = files[0];
      try {
        if (file) {
          const fileReader = new FileReader();
          fileReader.onloadend = async (e) => {
            const header = getHeader(e);
            try {
              let f: File | Blob = file;
              if (file && isFileMIMETypeHEIC(header, file.type)) {
                f = await getConvertedFile(file);
              }

              const data: any = new FormData();
              data.append('file', f);

              const response = await Axios.post(
                `${API.documentTypes}/file/${selectedDocType?.documentType}`,
                data,
                axiosHeadersFormData(localStorage.getItem('PROCYS_accessToken'), data)
              );
              if (response.data.success) {
                getImage(selectedDocType?.documentType);
                enqueueSnackbar(t('PROCYS_FILE_UPLOAD_SUCCESS'), {
                  variant: 'success',
                });
              }
              setLoading(false);
            } catch (e) {
              const error = e as CustomError;
              enqueueSnackbar(appendContactSupport(
                window.config.support_email,
                getLocalisedErrorString(error?.response?.data?.i18n || 'PROCYS_FILE_UPLOAD_FAILURE', t),
                t
              ), {
                variant: 'error',
              });
              setLoading(false);
            }
          };
          fileReader.readAsArrayBuffer(file);
        } else {
          setLoading(false);
        }
      } catch (e) {
        const error = e as CustomError;
        enqueueSnackbar(appendContactSupport(
          window.config.support_email,
          getLocalisedErrorString(error?.response?.data?.i18n || 'PROCYS_FILE_UPLOAD_FAILURE', t),
          t
        ), {
          variant: 'error',
        });
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Box sx={styles.root} id='pdf-viewer-div' className="dashed-grid-paper">
        <ManageFieldsSideMenu
          selectedDocType={selectedDocType}
          fieldsList={fieldsList}
          sectionFieldsList={sectionFieldsList}
          selectedField={selectedField}
          addNewOpen={addNewOpen}
          showAPIRef={showAPIRef}
          fetchDocTypes={fetchDocTypes}
          setShowAPIRef={setShowAPIRef}
          setLoading={setLoading}
          setAddNewOpen={setAddNewOpen}
          setSelectedField={setSelectedField}
          setFieldsList={setFieldsList}
          setSectionFieldsList={setSectionFieldsList}
          handleClose={handleClose}
          handleCloseOnSave={proceedToClose}
        />
        <PdfView document={document} totalPages={totalPages} noDocMessage={t('DOC_TYPE_DOCUMENT_NO_DOC_UPLOADED')} />
      </Box>
      <Backdrop style={styles.backdrop} open={loading}>
        <CircularProgress sx={styles.spinner} />
      </Backdrop>
      {showClosePopup && <ConfirmCancelPopup
        open={showClosePopup}
        handleClose={() => setShowClosePopup(false)}
        proceedClose={proceedToClose}
      />}
      {open && !addNewOpen && !showAPIRef && <Box sx={styles.addDocBtnContainer}>
        <Button
          variant="outlined"
          sx={styles.addDocBtn}
          disabled={loading}
          component="label"
          startIcon={<RiAddCircleLine size={20} />}
        >
          <input
            id="upload-input"
            accept="application/pdf"
            type="file"
            onChange={addDoc}
            style={{ display: 'none' }}
            required
          />
          {t('DOC_TYPES_FIELDS_MANAGE_ADD_DOC')}
        </Button>
      </Box>}
    </>
  )
}

export default DocTypeManageFields