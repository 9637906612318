import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import {
  Box, Grid, Typography, Tooltip, Button, IconButton,
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
  InfoOutlined as InfoIcon,
  SettingsOutlined as SettingsIcon,
  ModeEdit as EditIcon,
  ErrorOutlineOutlined as ErroredIcon,
  Inventory as ArchiveIcon,
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import { AttachedDocumentsType, DocType, ExportFailReason, FieldType } from 'src/types';
import { isActionPermitted, isShowManageFields, permissions } from 'src/utils/helpers';
import DocumentTag from '../DocumentTag/DocumentTag';
import EditTagsModal from '../EditTagsModal/EditTagsModal';
import AttachedDocuments from '../AttachedDocuments/AttachedDocuments';
import { formatExportFailReason, getIsError, truncateFileName } from 'src/document-edit/utils';
import StatusChangeBtn from '../StatusChangeBtn/StatusChangeBtn';
import styles from './style';

interface PropTypes {
  doc: DocType;
  statusChangeLoading: boolean;
  tags: string[];
  fieldsContainerWidth: number;
  handleClose?: Function;
  disableProps?: any;
  details: Record<string, string>;
  renderedFields: FieldType[];
  decimalSeparator: string;
  submitting: boolean;
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
  handleStatusChange: (_status: number, _id: number) => void;
  handleOpenManageFields: (_entity: string) => void;
  openInfoModal: () => void;
  setIsSubmitClicked: (_val: boolean) => void;
}

const DocumentHeader = (props: PropTypes) => {
  const { ready, t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();

  const isPassportOrID = user?.documentType === 'id';

  const {
    doc: doc,
    statusChangeLoading,
    tags,
    fieldsContainerWidth,
    setTags,
    handleStatusChange: _handleStatusChange,
    handleOpenManageFields,
    openInfoModal,
    setIsSubmitClicked,
    handleClose,
    submitting,
    renderedFields,
    details,
    decimalSeparator,
  } = props;

  const [editTagsOpen, setEditTagsOpen] = useState<boolean>(false);
  const [attachmentsOpen, setAttachmentsOpen] = useState<boolean>(false);
  const [statusMenuOpen, setStatusMenuOpen] = useState<boolean>(false);

  const [mouseLeftSplitScrnBtn, _setMouseLeftSplitScrnBtn] = useState<boolean>(true);
  const mouseLeftSplitScrnBtnRef = useRef(mouseLeftSplitScrnBtn);
  const setMouseLeftSplitScrnBtn = (val: boolean) => {
    mouseLeftSplitScrnBtnRef.current = val;
    _setMouseLeftSplitScrnBtn(val);
  };
  const [mouseLeftAttachmentsTooltip, _setMouseLeftAttachmentsTooltip] = useState<boolean>(true);
  const mouseLeftAttachmentsTooltipRef = useRef(mouseLeftAttachmentsTooltip);
  const setMouseLeftAttachmentsTooltip = (val: boolean) => {
    mouseLeftAttachmentsTooltipRef.current = val;
    _setMouseLeftAttachmentsTooltip(val);
  };
  const isHacienda = user?.integrations.includes('hacienda-certification');

  const splitButtonRef = useRef(null);

  const documentStatus = doc.status;

  const isBtnDisabledClosedPeriod = isHacienda && doc.closedPeriod;

  const handleStatusMenuClose = () => {
    setStatusMenuOpen(false);
  };

  const handleStatusMenuToggle = () => {
    setStatusMenuOpen(!statusMenuOpen);
  };

  const handleAttachmentsClose = () => {
    setAttachmentsOpen(false);
  };

  const isButtonDisabled = () => doc.status < 500
    || submitting
    || renderedFields.some((field) => getIsError(field.key, field.dataType, details[field.key], user, decimalSeparator));

  const handleMouseLeaveSplitScrnBtn = () => {
    setMouseLeftSplitScrnBtn(true);
    setTimeout(() => {
      if (mouseLeftAttachmentsTooltipRef.current && mouseLeftSplitScrnBtnRef.current) {
        handleAttachmentsClose();
      }
    }, 2000);
  };

  const handleMouseLeaveAttachmentsTooltip = () => {
    setMouseLeftAttachmentsTooltip(true);
    setTimeout(() => {
      if (mouseLeftSplitScrnBtnRef.current && mouseLeftAttachmentsTooltipRef.current) {
        handleAttachmentsClose();
      }
    }, 2000);
  };

  const handleShowAttachments = () => {
    setAttachmentsOpen((prevState) => !prevState);
  };

  const handleAttachmentClick = (attachment: AttachedDocumentsType) => {
    window.open(`/files/attachment/${attachment.name}`);
    handleAttachmentsClose();
  };

  const handleCloseEditTags = () => {
    setEditTagsOpen(false);
    setIsSubmitClicked(false);
  };

  const gotoInvoiceList = () => {
    navigate(`/documents${location.search}`);
  };

  const handleStatusChange = (status: number, id: number) => {
    handleStatusMenuClose();
    _handleStatusChange(status, id);
  };

  const displayExportFaileReasons = () => {
    return <Grid>
      {
        doc.exportFailReasons.map((reason: ExportFailReason) => {
          return <Typography sx={styles.exportFailReason}>{`${reason.fieldName}: ${reason.reason}`}</Typography>
        })
      }
    </Grid>
  };

  const archiveBtn = () => (
    <Tooltip title={!isActionPermitted(permissions.invoiceArchive, user?.permissions)
      ? t('INVOICE_EDIT_FORM_ARCHIVE_TOOLTIP_PERMISSION')
      : isBtnDisabledClosedPeriod
        ? t('ACTION_DISABLED_PERIOD_CLOSED_HACIENDA')
        : t('INVOICE_EDIT_FORM_ARCHIVE_TOOLTIP')}
    >
      <IconButton
        sx={styles.manageFieldsBtn}
        onClick={() => handleStatusChange(600, doc.id || 0)}
        disabled={isBtnDisabledClosedPeriod || isButtonDisabled() || !isActionPermitted(permissions.invoiceArchive, user?.permissions) || statusChangeLoading}
      >
        <ArchiveIcon sx={styles.manageFieldsIcon} />
      </IconButton>
    </Tooltip>
  );

  return (
    <Grid sx={styles.main}>
      <Grid sx={styles.topContainer}>
        <IconButton onClick={() => handleClose ? handleClose() : gotoInvoiceList()} sx={styles.backBtn}>
          <ArrowBackIcon sx={styles.backIcon} />
        </IconButton>
        <Grid sx={styles.headerInfoContainer}>
          <Box sx={styles.titleContainer}>
            <Typography noWrap sx={styles.docName}>{truncateFileName(doc.fileName || '')}</Typography>
            <IconButton onClick={openInfoModal} sx={{ padding: 0 }}>
              <InfoIcon sx={styles.infoIcon} />
            </IconButton>
          </Box>
          <Box sx={styles.tagsContainer}>
            <Box display="flex" paddingLeft="8px" overflow="hidden" sx={{ maxWidth: `calc(${fieldsContainerWidth}px - 58px)` }}>
              {doc.isDuplicate && (<DocumentTag name={t('DOCUMENT_HEADER_DUPLICATED')} isSystemGenerated />)}
              {doc.closedPeriod && (<DocumentTag name={doc.closedPeriod} isSystemGenerated />)}
              {tags.map((tag, i) => (<DocumentTag key={`${tag}_${i}`} name={tag} />))}
            </Box>
            {!doc.isDuplicate && tags.length === 0 ? (
              <Button
                sx={styles.addTagBtn}
                onClick={() => setEditTagsOpen(true)}
              >
                {ready && t('INVOICE_EDIT_FORM_ADD_TAG_BTN')}
              </Button>
            ) : (
              <Tooltip title={ready && t('DOCUMENT_HEADER_EDIT_TAGS')}>
                <IconButton onClick={() => setEditTagsOpen(true)}>
                  <EditIcon sx={styles.editTagsIcon} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid sx={styles.statusContainer}>
        <StatusChangeBtn
          statusChangeLoading={statusChangeLoading}
          doc={doc}
          documentStatus={documentStatus}
          statusMenuOpen={statusMenuOpen}
          handleStatusMenuToggle={handleStatusMenuToggle}
          handleStatusMenuClose={handleStatusMenuClose}
          handleStatusChange={handleStatusChange}
        />
        {
          ((doc.errorReason && doc.errorReason > 0) || doc.exportFailReason || (doc.exportFailReasons && doc.exportFailReasons.length > 0)) && (
            <Tooltip
              title={
                doc.errorReason
                  ? t(`INVOICE_ERROR_STATUS_${doc.errorReason}`)
                  : doc.exportFailReason
                    ? t(formatExportFailReason(t, doc.exportFailReason))
                    : (doc.exportFailReasons && doc.exportFailReasons.length > 0)
                      ? displayExportFaileReasons()
                      : ''
              }
            >
              <ErroredIcon style={styles.errorReasonIcon} />
            </Tooltip>
          )
        }
        {isShowManageFields(user, isPassportOrID ? 'applyParentFieldsIDs' : 'applyParentFieldsHeaders') && (
          <IconButton
            sx={styles.manageFieldsBtn}
            onClick={() => handleOpenManageFields('header')}
          >
            <SettingsIcon style={styles.manageFieldsIcon} />
          </IconButton>
        )}
        {doc?.attachedDocuments?.length !== undefined && doc?.attachedDocuments?.length > 0 && (
          <AttachedDocuments
            handleSplitBtnClick={handleShowAttachments}
            splitButtonRef={splitButtonRef}
            setMouseLeftSplitScrnBtn={setMouseLeftSplitScrnBtn}
            handleMouseLeaveSplitScrnBtn={handleMouseLeaveSplitScrnBtn}
            document={doc}
            attachmentsOpen={attachmentsOpen}
            setMouseLeftAttachmentsTooltip={setMouseLeftAttachmentsTooltip}
            handleMouseLeaveAttachmentsTooltip={handleMouseLeaveAttachmentsTooltip}
            handleAttachmentsClose={handleAttachmentsClose}
            handleAttachmentClick={handleAttachmentClick}
          />
        )}
        {(documentStatus === 503 || documentStatus === 510) && archiveBtn()}
      </Grid>
      <EditTagsModal
        open={editTagsOpen}
        tags={tags}
        setTags={setTags}
        handleClose={handleCloseEditTags}
      />
    </Grid>
  );
};

export default DocumentHeader;
