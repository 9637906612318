import theme from "src/theme";

const styles = {
  caption: {
    marginTop: '4px',
    color: theme.palette.text.primary,
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: '500',
    maxWidth: '480px',
    lineHeight: '14px',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
  },
  captionSelected: {
    marginTop: '4px',
    color: '#FFFFFF', // Take from theme
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: '500',
    maxWidth: '480px',
    lineHeight: '14px',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
  },
  legacy: {
    marginTop: '4px',
    color:'#4C4E6499', // Take from theme
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: '500',
    maxWidth: '480px',
    lineHeight: '14px',
  },
  legacySelected: {
    marginTop: '4px',
    color:'#FFFFFF99', // Take from theme
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: '500',
    maxWidth: '480px',
    lineHeight: '14px',
  },
  listItem: {
    border: '1px #e4e4e4 solid',
    borderRadius: '8px',
    padding: '8px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '192px',
  },
  listItemSelected: {
    border: '1px #e4e4e4 solid',
    borderRadius: '8px',
    padding: '8px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '192px',
    backgroundColor: theme.palette.secondary.main,
  },
  listItemTitle: {
    fontSize: '15px',
    fontWeight: '600',
    textAlign: 'center',
    lineHeight: '22px',
    color: theme.palette.text.primary,
    alignItems: 'center',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
  },
  listItemTitleSelected: {
    fontSize: '15px',
    fontWeight: '600',
    textAlign: 'center',
    lineHeight: '22px',
    color: '#FFFFFF',
    alignItems: 'center',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
  },
  pointsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '12px 6px',
  },
  itemPoint: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    overflow: 'hidden',
    width: '100%',
    padding: '2px',
  },
  itemPointSelected: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    overflow: 'hidden',
    width: '100%',
    padding: '2px',
  },
  listItemIcon: {
    color: '#3CB573',
    fontSize: '12px',
    marginTop: '3px',
    marginRight: '4px',
    marginLeft: '0px'
  },
  listItemIconSelected: {
    color: '#FFFFFF',
    fontSize: '12px',
    marginTop: '3px',
    marginRight: '4px',
    marginLeft: '0px'
  },
  listItemPoint: {
    fontSize: '11px',
    color: theme.palette.text.primary,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 6,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
  },
  listItemPointSelected: {
    fontSize: '11px',
    color: '#FFFFFF',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 6,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
  },
};

export default styles;
