import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { countries as AllCountries, getEmojiFlag, TCountryCode } from 'countries-list';
import Axios from 'axios';

import { Autocomplete, Box, Button, Card, CardContent, FormHelperText, Grid, TextField, Typography } from '@mui/material';

import { useAuth } from 'src/hooks/useAuth';
import authService from 'src/utils/authService';
import { appendContactSupport, axiosHeaders, getLocalisedErrorString } from 'src/utils/helpers';
import { useConfig } from 'src/hooks/useConfig';
import { CustomError, UserDataType } from 'src/types';
import styles from './style';

type CCountryType = {
  capital: string;
  continent: string;
  currency: string[];
  languages: string[];
  name: string;
  native: string;
  phone: number[]
}

type CountryType = {
  isoCode: string;
  name: string;
}

function MyProfile() {
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { LOGIN_PANEL_URL, API } = useConfig();
  const auth = useAuth();
  const {user, setUser} = auth;
  const aCountries: Record<string,CCountryType> =  AllCountries as unknown as Record<string,CCountryType> ;

  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [countriesList, setCountriesList] = useState<CountryType[]>([]);

  useEffect(() => {
    setCountriesList(Object.keys(aCountries).map((key: string) => ({
      isoCode: key,
      name: aCountries[key as keyof CCountryType]?.name
    })));
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        country: user?.country || '',
        email: user?.email || '',
        firstname: user?.firstname || '',
        lastname: user?.lastname || '',
        phone: user?.phone || '',
        city: user?.city || '',
      }}
      validationSchema={Yup.object().shape({
        country: Yup.string().max(255),
        email: Yup.string().email(t('INVALID_EMAIL_ADDRESS_HELPER_TEXT')).max(255).required(t('EMAIL_ADDRESS_REQUIRED_HELPER_TEXT')),
        firstname: Yup.string().max(255).required(t('ACCOUNT_SETTINGS_USERS_FIRSTNAME_REQ')),
        lastname: Yup.string().max(255).required(t('ACCOUNT_SETTINGS_USERS_LASTNAME_REQ')),
        phone: Yup.string().matches(/^([+\d]\d*)$/, t('ACCOUNT_SETTINGS_MY_PROFILE_PHONE_INVALID'))
          .min(9, t('ACCOUNT_SETTINGS_MY_PROFILE_PHONE_MIN')).max(16, t('ACCOUNT_SETTINGS_MY_PROFILE_PHONE_MAX'))
      })}
      onSubmit={async (values) => {
        setLoading(true);
        if (!authService.validateToken()) {
          enqueueSnackbar(t('PROCYS_LOGIN_SESSION_EXPIRED'), {
            variant: 'error',
          });
          setTimeout(() => {
            authService.logout(LOGIN_PANEL_URL);
          }, 2000);
          return;
        }
        try {
          const response = await Axios.put(
            `${API.userProfile}?&application=procys`,
            {
              ...values,
              interfacePreference: user?.interfacePreference
            },
            axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
          );
          if (response.data.success) {
            enqueueSnackbar(t('ACCOUNT_SETTINGS_MY_PROFILE_UPDATE_SUCCESS'), {
              variant: 'success',
            });
            if(setUser)
              setUser({ ...user, ...values } as UserDataType);
            setLoading(false);
          } else {
            setLoading(false);
            enqueueSnackbar(t('ACCOUNT_SETTINGS_MY_PROFILE_UPDATE_FAILURE'), {
              variant: 'error',
            });
          }
        } catch (e) {
          const error = e as CustomError;
          setLoading(false);
          setError(appendContactSupport(
            window.config.support_email as unknown as string,
            getLocalisedErrorString(error?.response?.data?.i18n || 'ACCOUNT_SETTINGS_MY_PROFILE_UPDATE_FAILURE', t),
            t
          ));
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card sx={styles.root}>
            <Typography sx={styles.header}>
              {ready && t('ACCOUNT_SETTINGS_MY_PROFILE_OVERVIEW')}
            </Typography>
            <CardContent sx={styles.formContainer}>
              <Grid container spacing={4}>
                <Grid item xs={12} sx={styles.formInput}>
                  <TextField
                    error={Boolean(touched.firstname && errors.firstname)}
                    fullWidth
                    helperText={touched.firstname && errors.firstname}
                    placeholder={t('ACCOUNT_SETTINGS_MY_PROFILE_FIRST_NAME')}
                    name="firstname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="firstname"
                    value={values.firstname}
                    variant="outlined"
                    sx={styles.textField}
                  />
                </Grid>
                <Grid item xs={12} sx={styles.formInput}>
                  <TextField
                    error={Boolean(touched.lastname && errors.lastname)}
                    fullWidth
                    helperText={touched.lastname && errors.lastname}
                    placeholder={t('ACCOUNT_SETTINGS_MY_PROFILE_LAST_NAME')}
                    name="lastname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="lastname"
                    value={values.lastname}
                    variant="outlined"
                    sx={styles.textField}
                  />
                </Grid>
                <Grid item xs={12} sx={styles.formInput}>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={
                    touched.email && errors.email
                      ? errors.email : t('ACCOUNT_SETTINGS_MY_PROFILE_WE_WILL_USE_THIS_EMAIL_TO_CONTACT_YOU')
                    }
                    placeholder={t('ACCOUNT_SETTINGS_MY_PROFILE_EMAIL')}
                    name="email"
                    onBlur={handleBlur}
                    onChange={(e) => handleChange({ target: { name: 'email', value: e.target.value.toLowerCase() } })}
                    type="email"
                    value={values.email}
                    disabled
                    variant="outlined"
                    sx={styles.textFieldDisabled}
                  />
                </Grid>
                <Grid item xs={12} sx={styles.formInput}>
                  <TextField
                    error={Boolean(touched.phone && errors.phone)}
                    fullWidth
                    helperText={touched.phone && errors.phone}
                    placeholder={t('ACCOUNT_SETTINGS_MY_PROFILE_PHONE')}
                    name="phone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phone}
                    variant="outlined"
                    sx={styles.textField}
                  />
                </Grid>
                <Grid item xs={12} sx={styles.formInput}>
                  <TextField
                    error={Boolean(touched.city && errors.city)}
                    fullWidth
                    helperText={touched.city && errors.city}
                    placeholder={t('ACCOUNT_SETTINGS_MY_PROFILE_CITY')}
                    name="city"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.city}
                    variant="outlined"
                    sx={styles.textField}
                  />
                </Grid>
                <Grid item xs={12} sx={styles.formInput}>
                  <Autocomplete
                    fullWidth
                    onChange={(e, v) => v && handleChange({ target: { name: 'country', value: v.name } })}
                    value={countriesList.find((o) => o.name.toLowerCase().includes(values.country?.toLowerCase())) || null}
                    inputValue={countriesList?.find((o) => o.name === values.country)?.name || ''}
                    options={countriesList}
                    getOptionLabel={(option) => `${getEmojiFlag(option?.isoCode as unknown as TCountryCode)} ${option.name}`}
                    isOptionEqualToValue={(option, value) => option.isoCode === value.isoCode}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={`${t('ACCOUNT_SETTINGS_MY_PROFILE_COUNTRY')}`}
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                        }}
                        sx={styles.selectStyle}
                      />
                    )}
                  />
                  <FormHelperText error>
                    {touched.country && errors.country && ready ? t(errors.country) : ''}
                  </FormHelperText>
                </Grid>
              </Grid>
              {error && (
                <Box mt={3}>
                  <FormHelperText error>
                    {error}
                  </FormHelperText>
                </Box>
              )}
            </CardContent>
            <Box
              p="15px 20px"
              display="flex"
              sx={styles.updateBtnContainer}
            >
              <Button
                disabled={loading}
                type="submit"
                variant="contained"
                sx={styles.updateBtn}
              >
                {ready && t('ACCOUNT_SETTINGS_MY_PROFILE_UPDATE')}
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
}

export default MyProfile;
