import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#clip0_222_34001)"
        stroke="#3E8AFF"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M14 3v4a1 1 0 001 1h4" />
        <path d="M5 12V5a2 2 0 012-2h7l5 5v4M5 21h14M5 18h14M5 15h14" />
      </g>
      <defs>
        <clipPath id="clip0_222_34001">
          <path fill="#fff" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgComponent
