import React from 'react';
import { Button } from '@mui/material';
import theme from 'src/themeNew';

const CancelBtn = (props: {
  title: string,
  onClick: (_e?: any) => void,
  disabled?: boolean,
  startIcon?: React.ReactNode,
}) => (
  <Button
    variant="outlined"
    sx={styles.cancelBtn}
    disabled={props.disabled}
    onClick={props.onClick}
    startIcon={props.startIcon}
  >
    {props.title}
  </Button>
);

export default CancelBtn;

const styles = {
  cancelBtn: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    lineHeight: '24px',
    minWidth: '108px',
    height: '38px',
    textTransform: 'none',
    borderRadius: '4px',
    boxShadow: `0px 4px 8px -4px ${theme.palette.boxShadow.cancel}`,
    border: `1px ${theme.palette.border.cancel} solid`,
    '&:hover': {
      backgroundColor: theme.palette.background.tableHeader,
      border: `1px ${theme.palette.border.cancel} solid`,
    },
    '&:focus': {
      backgroundColor: theme.palette.background.tableHeader,
      border: `1px ${theme.palette.border.cancel} solid`,
    },
    '&:active': {
      backgroundColor: theme.palette.background.tableHeader,
      border: `1px ${theme.palette.border.cancel} solid`,
    },
    '&:disabled': {
      color: theme.palette.error.main,
      border: `1px ${theme.palette.border.cancel} solid`,
      opacity: 0.45,
    }
  },
};
