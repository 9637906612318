import { useContext } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Axios from 'axios';

import {
  Avatar, Button, ButtonGroup, Chip, CircularProgress, ClickAwayListener, Grid, Grow, Menu, MenuItem, MenuList,
  Paper, Popper, Tooltip, Typography
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon, ArrowDropDown as ArrowDropDownIcon, ChevronRight as ChevronRightIcon,
} from '@mui/icons-material';

import { useAuth } from 'src/hooks/useAuth';
import { isStatusChangeAble, isStatusChangeValid } from 'src/utils/invoiceStateChangeValidations';
import {
  invoiceStatusCode, invoiceStatusCodesList,
} from 'src/config';
import {
  appendContactSupport, axiosHeaders, getInvoiceStatus, getInvoiceStatusForDropdown, getInvoiceStatusKey,
  getLocalisedErrorString, getPermissionByInvoiceStatus, i18nByStatus, isActionPermitted, permissions
} from 'src/utils/helpers';
import ConfigContext from 'src/contexts/ConfigContext';
import styles from './style';

const DocumentHeader = (props) => {
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    invoice,
    statusMenuOpen,
    users,
    statusChangeLoading,
    isToValidateStatusAllowed,
    gotoInvoiceList,
    statusMenuRef,
    handleStatusMenuToggle,
    handleStatusMenuClose,
    handleStatusChange,
    handleUserMenuOpen,
    usersMenuOpen,
    usersMenuAnchor,
    handleUserMenuClose,
    getInvoice,
  } = props;
  const { BRAND_NAME, API } = useContext(ConfigContext);

  const auth = useAuth();
  const {user} = auth;

  const filteredUsers = users && users.length > 0 ? users.filter(
    (u) => isStatusChangeAble(invoice.status, u?.userRole) && invoice.owner !== u.email
  ) : [];

  const handleSelectOwner = async (owner, id) => {
    handleUserMenuClose();

    try {
      const body = {
        id,
        owner: owner.email
      };

      const response = await Axios.post(
        API.documentOwner,
        body,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        getInvoice();
        enqueueSnackbar(t('PROCYS_OWNER_CHANGE_SUCCESS'), {
          variant: 'success',
        });
      }
    } catch (error) {
      enqueueSnackbar(getLocalisedErrorString(error?.response?.data?.i18n, t)
      || appendContactSupport(window.config.support_email, t('PROCYS_OWNER_CHANGE_FAILED'), t), {
        variant: 'error',
      });
    }
  };

  const formatExportFailReason = (reason) => {
    const str = `${t('FAILED_TO_EXPORT_DOCUMENT')}:`;
    if (reason.includes('__MESSAGE__')) {
      const arr = reason.split('__MESSAGE__');
      if (arr.length === 2) {
        return appendContactSupport(window.config.support_email, `${str} ${t(arr[0])}. ${arr[1]}`, t);
      }
      if (arr.length === 3) {
        return appendContactSupport(window.config.support_email, `${str} ${t(arr[0])}. ${arr[1]}, ${arr[2]}`, t);
      }
    }
    if (reason.includes('__BR__')) {
      const arr = reason.split('__BR__');
      if (arr.length === 2) {
        return appendContactSupport(window.config.support_email, `${str} ${t(arr[0])}. ${t(arr[1])}`, t);
      }
      if (arr.length === 3) {
        return appendContactSupport(window.config.support_email, `${str} ${t(arr[0])}. ${t(arr[1])}, ${t(arr[2])}`, t);
      }
      if (arr.length === 4) {
        if (arr[2].includes('LINE')) {
          return appendContactSupport(
            window.config.support_email,
            `${str} ${t(arr[0])}. ${t(arr[1])}, ${t(arr[2])} - ${t('DOCUMENT_EXPORT_ERROR_LINE_NO')} ${arr[3]}`,
            t
          );
        }
        return appendContactSupport(window.config.support_email, `${str} ${t(arr[0])}. ${t(arr[1])}, ${t(arr[2])} ${t(arr[3])}`, t);
      }
    }
    return appendContactSupport(window.config.support_email, str, t);
  };

  const renderUserMenu = () => {
    if (filteredUsers && filteredUsers.length === 0) {
      return null;
    }

    return (
      <Menu
        id="user-menu"
        anchorEl={usersMenuAnchor}
        keepMounted
        open={usersMenuOpen}
        onClose={handleUserMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiMenu-paper': styles.paper,
          '& .MuiMenu-list': styles.menuPadding
        }}
      >
        {
          filteredUsers.map((u, i) => (
            <MenuItem
              key={u.email}
              onClick={() => handleSelectOwner(u, invoice.id)}
              style={
                i === 0
                  ? styles.menuActionContainerTop
                  : styles.menuActionContainer
              }
            >
              {`${u.firstname} ${u.lastname} | ${u.email}`}
            </MenuItem>
          ))
        }
      </Menu>
    );
  };

  return (
    <Grid style={styles.titleContainer}>
      <Grid>
        <Button style={styles.backBtn} onClick={gotoInvoiceList}>
          <ArrowBackIcon style={styles.icon} />
          {ready && t('INVOICE_EDIT_BACK_TO_OVERVIEW')}
        </Button>
      </Grid>
      <Grid style={styles.assistantIDBox}>
        <Typography style={styles.assistantID}>{t('INVOICE_EDIT_PROCYS_NUMBER', { brand: BRAND_NAME, assistantID: invoice.assistantID })}</Typography>
        {
          !user.customisations.includes('dontIdentifyDuplicateDoc') && invoice.isDuplicate && (
            <Tooltip
              title={invoice?.supplier ? t('INVOICE_DUPLICATE_TOOLTIP_WITH_SUPPLIER') : t('INVOICE_DUPLICATE_TOOLTIP')}
            >
              <span style={styles.duplicate}>{t('INVOICE_DUPLICATE')}</span>
            </Tooltip>
          )
        }
        {
          invoice.errorReason && invoice.errorReason > 0 && (
            <Tooltip
              title={t(`INVOICE_ERROR_STATUS_${invoice.errorReason}`)}
            >
              <span style={styles.errorReason}>!</span>
            </Tooltip>
          )
        }
      </Grid>
      <Grid style={styles.titleBox}>
        <Typography style={styles.title}>
          {ready && `${t('INVOICE_EDIT_INVOICE')} ${t('INVOICE_EDIT_INVOICE_NO')}: ${invoice.documentNumber}`}
        </Typography>
        {(invoice.status === invoiceStatusCode.toexport && invoice.exportFailReason) && <span style={styles.exportFailed}>!</span>}
      </Grid>
      {(invoice.status === invoiceStatusCode.toexport && invoice.exportFailReason) && (
        <Grid style={styles.exportFailedReasonContainer}>
          <Typography style={styles.exportFailedReason}>
            {formatExportFailReason(invoice.exportFailReason)}
          </Typography>
        </Grid>
      )}
      <Grid style={styles.infoBox}>
        <Typography style={styles.subtitle} component="span">
          {ready && `${t('INVOICE_EDIT_UPLOADED')} ${moment.unix(invoice.appUploadedDate).format('DD/MM/YYYY')} ${t('INVOICE_EDIT_UPLOADED_BY')}`}
          <Chip
            size="small"
            avatar={<Avatar alt="" src={invoice.appUserImage} />}
            label={invoice.appUsername}
            sx={{
              '& .MuiChip-root': styles.userChip,
              '& .MuiChip-avatar': styles.chipAvatar,
              '& .MuiChip-label': styles.chipLabel
            }}
          />
        </Typography>
        {
          statusChangeLoading
            ? (
              <Grid style={styles.action}>
                <CircularProgress size={20} />
              </Grid>
            ) : (
              <>
                <ButtonGroup variant="outlined" ref={statusMenuRef} aria-label="split button">
                  <Tooltip title={!isActionPermitted(getPermissionByInvoiceStatus(invoice.status), user.permissions)
                    ? t(`INVOICE_STATUS_CHANGE_TOOLTIP_NO_PERMISSION_${i18nByStatus(invoice.status)}`)
                    : ''}
                  >
                    <div>
                      <Button
                        className="no-border"
                        style={{ ...styles[getInvoiceStatusKey(invoice.status)], ...styles.ublButton }}
                        variant="contained"
                        onClick={handleStatusMenuToggle}
                        disabled={!isActionPermitted(getPermissionByInvoiceStatus(invoice.status), user.permissions)}
                        endIcon={<ArrowDropDownIcon />}
                      >
                        {ready && t(getInvoiceStatus(invoice.status))}
                      </Button>
                    </div>
                  </Tooltip>
                </ButtonGroup>
                <Popper
                  open={statusMenuOpen}
                  anchorEl={statusMenuRef.current}
                  role={undefined}
                  transition
                  style={{ zIndex: 111111, width: 150 }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleStatusMenuClose}>
                          <MenuList id="split-button-menu" autoFocusItem>
                            {
                              invoiceStatusCodesList.map((s) => {
                                if (
                                  getInvoiceStatus(invoice.status) !== getInvoiceStatus(s)
                                  && isStatusChangeValid(invoice.status, s, isToValidateStatusAllowed)
                                ) {
                                  return (
                                    <MenuItem
                                      key={s}
                                      onClick={(e) => handleStatusChange(s, invoice.id, e)}
                                      style={styles.groupBtn}
                                    >
                                      {ready && t(getInvoiceStatusForDropdown(s))}
                                    </MenuItem>
                                  );
                                }
                                return null;
                              })
                            }
                            {
                              ((invoice.status > 16 && invoice.status <= 100) || invoice.status === 600) && (
                              <MenuItem
                                key={900}
                                onClick={(e) => handleStatusChange(900, invoice.id, invoice.id, e)}
                                disabled={!isActionPermitted(permissions.invoiceDelete, user.permissions)}
                                style={styles.groupBtn}
                              >
                                {ready && t(getInvoiceStatusForDropdown(900))}
                              </MenuItem>
                              )
                            }
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </>
            )
        }
      </Grid>
      <Grid style={styles.infoBox}>
        <Typography style={styles.subtitleOwnedInfo} component="span">
          <Grid style={styles.subtitleOwned}>
            {ready && t('INVOICE_EDIT_OWNED_BY')}
          </Grid>
          <Tooltip title={ready ? t('INVOICE_EDIT_OWNER_NO_PERMISSION') : ''} disableHoverListener={filteredUsers && filteredUsers.length > 0}>
            <Grid>
              <Chip
                size="small"
                avatar={<Avatar alt="" src={invoice.ownerAvatar} />}
                label={(
                  <Typography style={styles.subtitleChip} component="span">
                    {
                      invoice.ownerName && invoice.ownerName !== ' '
                        ? invoice.ownerName
                        : t('INVOICE_EDIT_UNASSIGNED')
                    }
                    <ChevronRightIcon style={styles.moreRightIcon} />
                  </Typography>
                )}
                sx={{
                  '& .MuiChip-root': styles.userChip,
                  '& .MuiChip-avatar': styles.chipAvatar,
                  '& .MuiChip-label': styles.chipLabel
                }}
                onClick={handleUserMenuOpen}
              />
            </Grid>
          </Tooltip>
          {renderUserMenu(filteredUsers)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DocumentHeader;
