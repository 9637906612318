import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Box, Grid } from '@mui/material';

import { useConfig } from 'src/hooks/useConfig';
import { sections } from 'src/settings/utils';
import { SectionType } from 'src/settings/settingsTypes';
import SideBarLayout from 'src/shared/layouts/SideBarLayout';
import SettingsTopBar from 'src/settings/components/SettingsTopBar/SettingsTopBar';
import LeftPanel from 'src/settings/components/LeftPanel/LeftPanel';
import ContentContainer from 'src/settings/components/ContentContainer/ContentContainer';

const Settings = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { BRAND_NAME } = useConfig();

  const queryVal = new URLSearchParams(location.search);
  const [selectedSection, setSelectedSection] =
    useState<SectionType>(queryVal.get('section') ? sections.find((s) => s.id === queryVal.get('section')) || sections[0] : sections[0]);
  
  return (
    <SideBarLayout title={`${t('ACCOUNT_SETTINGS_TITLE')} | ${BRAND_NAME}`}>
      <Grid sx={styles.root}>
        <Grid sx={styles.container}>
          <SettingsTopBar title={selectedSection.title} />
          <Box sx={styles.contentWrapper}>
            <LeftPanel selectedSection={selectedSection} setSelectedSection={setSelectedSection} />
            <ContentContainer selectedSection={selectedSection} />
          </Box>
        </Grid>
      </Grid>
    </SideBarLayout> 
  )
}

export default Settings

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    backgroundColor: '#F3F5F9',
    minHeight: '100%'
  },
  container:{
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '0px',
    backgroundColor: '#FFFFFF',
    height: '100%',
    boxShadow: '0px 2px 10px 0px #4C4E6438',
    borderRadius: '10px',
  },
  contentWrapper: {
    display: 'flex',
    flex: 1,
    height: '100%',
  }
}