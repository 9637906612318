import { Dispatch } from 'redux';
import Axios from "axios";
import {
  FETCH_HEADER_FIELDS_START,
  FETCH_HEADER_FIELDS_SUCCESS,
  FETCH_HEADER_FIELDS_FAIL,
  FETCH_LINE_FIELDS_START,
  FETCH_LINE_FIELDS_SUCCESS,
  FETCH_LINE_FIELDS_FAIL,
  FETCH_DOC_OVERVIEW_FIELDS_START,
  FETCH_DOC_OVERVIEW_FIELDS_SUCCESS,
  FETCH_DOC_OVERVIEW_FIELDS_FAIL,
  FETCH_SUPPLIER_FIELDS_SUCCESS,
  FETCH_SUPPLIER_FIELDS_FAIL,
  FETCH_SUPPLIER_FIELDS_START,
} from "src/actionTypes";
import { FieldType } from "src/types";
import { axiosHeaders } from "src/utils/helpers";

const fetchHeaderFieldsStart = (entity: string) => {
  return {
    type: FETCH_HEADER_FIELDS_START,
    payload: entity,
  };
};

const fetchHeaderFieldsSuccess = (fields: FieldType[]) => {
  return {
    type: FETCH_HEADER_FIELDS_SUCCESS,
    payload: fields,
  };
};

const fetchHeaderFieldsFail = () => {
  return {
    type: FETCH_HEADER_FIELDS_FAIL,
  };
}

export const fetchHeaderFields = (companyID: string, _docType: string, docID?: string) => {
  const docType = _docType || 'invoice';
  return async (dispatch: Dispatch) => {
    dispatch(fetchHeaderFieldsStart(docType));
    let url = '';
    if (docType === 'cdc-document') {
      url = `${window.config.backend.procys_service}v1/fields/${docType}/${encodeURIComponent(companyID)}`
    } else {
      url = `${window.config.backend.procys_service}v2/fields/doc-types/${docType}/sections/header/${encodeURIComponent(companyID)}`;
    }
    if (docID) {
      url += `?docID=${docID}`;

      if (location.pathname.includes('invoices')) {
        url = `${window.config.backend.procys_service}v1/fields/invoice/${encodeURIComponent(companyID)}?docID=${docID}`;
      }
    }
    try {
      const response = await Axios.get(
        url,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response?.data.success) {
        dispatch(fetchHeaderFieldsSuccess(response.data));
        localStorage.setItem('headerFields', JSON.stringify(response.data.data));
      } else {
        dispatch(fetchHeaderFieldsFail());
      }
    } catch (error) {
      dispatch(fetchHeaderFieldsFail());
    }
  };
};

const fetchLineFieldsStart = () => {
  return {
    type: FETCH_LINE_FIELDS_START,
  };
};

const fetchLineFieldsSuccess = (fields: FieldType[]) => {
  return {
    type: FETCH_LINE_FIELDS_SUCCESS,
    payload: fields,
  };
};

const fetchLineFieldsFail = () => {
  return {
    type: FETCH_LINE_FIELDS_FAIL,
  };
}

export const fetchLineFields = (companyID: string, _docType: string, docID?: string) => {
  const docType = _docType || 'invoice';
  return async (dispatch: Dispatch) => {
    if (docType === 'cdc-document') {
      return;
    }
    dispatch(fetchLineFieldsStart());
    let url = `${window.config.backend.procys_service}v2/fields/doc-types/${docType}/sections/lines/${encodeURIComponent(companyID)}`;
    if (docID) {
      url += `?docID=${docID}`;
    }
    try {
      const response = await Axios.get(
        url,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response?.data.success) {
        dispatch(fetchLineFieldsSuccess(response.data.data));
        localStorage.setItem('lineFields', JSON.stringify(response.data.data));
      } else {
        dispatch(fetchLineFieldsFail());
      }
    } catch (error) {
      dispatch(fetchLineFieldsFail());
    }
  };
};

const fetchDocOverviewFieldsStart = () => {
  return {
    type: FETCH_DOC_OVERVIEW_FIELDS_START,
  };
};

const fetchDocOverviewFieldsSuccess = (fields: FieldType[], docType: string) => {
  return {
    type: FETCH_DOC_OVERVIEW_FIELDS_SUCCESS,
    payload: {
      data: fields,
      docType
    },
  };
};

const fetchDocOverviewFieldsFail = () => {
  return {
    type: FETCH_DOC_OVERVIEW_FIELDS_FAIL,
  };
}

export const fetchDocOverviewFields = (companyID: string, docType: string) => {
  return async (dispatch: Dispatch) => {
    let url = '';
    if (docType === 'cdc-document') {
      url = `${window.config.backend.procys_service}v1/fields/document-overview/${encodeURIComponent(companyID)}`
    } else {
      url = `${window.config.backend.procys_service}v2/fields/doc-types/${docType}/sections/overview/${encodeURIComponent(companyID)}`;
    }
    dispatch(fetchDocOverviewFieldsStart());
    try {
      const response = await Axios.get(
        url,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response?.data.success) {
        const data = response.data.data as FieldType[];
        const activeFields: FieldType[] = [];
        const inactiveFields: FieldType[] = [];
        data.forEach((field) => {
          if (field.isActive) {
            activeFields.push(field);
          } else {
            inactiveFields.push(field);
          }
        });
        dispatch(fetchDocOverviewFieldsSuccess([ ...activeFields, ...inactiveFields ], response.data.docType));
        localStorage.setItem('docOverviewFields', JSON.stringify([ ...activeFields, ...inactiveFields ]));
      } else {
        dispatch(fetchDocOverviewFieldsFail());
      }
    } catch (error) {
      dispatch(fetchDocOverviewFieldsFail());
    }
  };
};

const fetchSupplierFieldsStart = () => {
  return {
    type: FETCH_SUPPLIER_FIELDS_START,
  };
};

const fetchSupplierFieldsSuccess = (fields: FieldType[]) => {
  return {
    type: FETCH_SUPPLIER_FIELDS_SUCCESS,
    payload: fields,
  };
};

const fetchSupplierFieldsFail = () => {
  return {
    type: FETCH_SUPPLIER_FIELDS_FAIL,
  };
}

export const fetchSupplierFields = (companyID: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchSupplierFieldsStart());
    try {
      const response = await Axios.get(
        `${window.config.backend.procys_service}v1/fields/supplier/${encodeURIComponent(companyID)}`,
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response?.data.success) {
        const data = response.data.data as FieldType[];
        const mandatoryFields: FieldType[] = [];
        const activeFields: FieldType[] = [];
        const inactiveFields: FieldType[] = [];
        data.forEach((field) => {
          if (field.isMandatory) {
            mandatoryFields.push(field);
          } else
          if (field.isActive) {
            activeFields.push(field);
          } else {
            inactiveFields.push(field);
          }
        });
        dispatch(fetchSupplierFieldsSuccess([ ...mandatoryFields, ...activeFields, ...inactiveFields ]));
        localStorage.setItem('supplierFields', JSON.stringify([ ...mandatoryFields, ...activeFields, ...inactiveFields ]));
      } else {
        dispatch(fetchSupplierFieldsFail());
      }
    } catch (error) {
      dispatch(fetchSupplierFieldsFail());
    }
  };
};
