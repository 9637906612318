import React, { useContext, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import Axios from 'axios';

import { Autocomplete, Box, Button, Grid, TextField, Typography } from '@mui/material';

import { appendContactSupport, axiosHeaders, getLocalisedErrorString } from 'src/utils/helpers';
import ConfigContext from 'src/contexts/ConfigContext';
import { CustomError, GLAccountType, DocType, RootState } from 'src/types';
import { validation } from 'src/document-edit/utils';
import { supplierData } from 'src/config';
import styles from './style';

interface PropTypes {
  document: DocType;
  details: Record<string, string>;
  isSpreadFieldsNotEmpty: () => boolean;
  spreadSuccess: boolean;
  setSpreadSuccess: React.Dispatch<React.SetStateAction<boolean>>;
}

const SpreadInvoice = (props: PropTypes) => {
  const { API } = useContext(ConfigContext);
  const { t, ready } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const noWhiteSpace = /.*\S.*$/;

  const {
    document, details, isSpreadFieldsNotEmpty, spreadSuccess, setSpreadSuccess
  } = props;

  const _glAccounts: GLAccountType[] = useSelector((state: RootState) => state.gla.glAccounts);
  const glAccounts: GLAccountType[] = [...supplierData.defaultGLA, ...useMemo(() => _glAccounts, [_glAccounts])];

  const [spreadFrom, setSpreadFrom] = useState(document.spreadFrom || details.period);
  const [spreadTo, setSpreadTo] = useState(document.spreadTo || '');
  const [glaSpread, setGlaSpread] = useState(glAccounts.find((gla) => gla.code === document.gla) || null);
  const [glaSpreadOpen, setGlaSpreadOpen] = useState(false);
  const [spreadFromError, setSpreadFromError] = useState(false);
  const [spreadToError, setSpreadToError] = useState(false);
  const [spreadGlaError, setSpreadGlaError] = useState(false);
  const [spreadInfoSubmitting, setSpreadInfoSubmitting] = useState(false);

  const handleGlaSpreadChange = (e: React.SyntheticEvent<Element, Event>, v: GLAccountType | null) => {
    const val = v?.code || e.currentTarget.textContent || '';
    if (val) {
      setSpreadGlaError(false);
    }
    setGlaSpread(v);
  };

  const submitSpreadInfo = async () => {
    setSpreadInfoSubmitting(true);
    const isSpreadToError = validation(spreadTo, noWhiteSpace) || spreadTo === '';
    const isSpreadFromError = validation(spreadFrom, noWhiteSpace) || spreadFrom === '';
    const isSpreadGlaError = validation(glaSpread?.code, noWhiteSpace) || glaSpread?.code === '';

    setSpreadToError(isSpreadToError);
    setSpreadFromError(isSpreadFromError);
    setSpreadGlaError(isSpreadGlaError);

    if (isSpreadToError || isSpreadFromError || isSpreadGlaError) {
      setSpreadInfoSubmitting(false);
      return;
    }
    try {
      const response = await Axios.post(
        `${API.invoices}${document.assistantID}/actions/spread`,
        {
          spreadTo,
          spreadFrom,
          gla: glaSpread
        },
        axiosHeaders(localStorage.getItem('PROCYS_accessToken'))
      );
      if (response.data.success) {
        setSpreadSuccess(true);
        enqueueSnackbar(t('INVOICE_EDIT_FORM_SPREAD_SUCCESS'), {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(t('INVOICE_EDIT_FORM_SPREAD_FAIL'), {
          variant: 'error',
        });
      }
      setSpreadInfoSubmitting(false);
    } catch (e) {
      const error = e as CustomError;
      enqueueSnackbar(getLocalisedErrorString(
        error.response ? error.response.data?.i18n : appendContactSupport(window.config.support_email, error.message, t), t
      ), {
        variant: 'error',
      });
      setSpreadInfoSubmitting(false);
    }
  };

  return (
    <>
      <Grid container sx={styles.field}>
        <Grid item xs={6} sx={styles.fieldLabelContainer}>
          <Box sx={styles.labelIconPlaceholder} />
          <Typography sx={styles.fieldLabel}>{ready && t('INVOICE_EDIT_FORM_SPREAD_FROM')}</Typography>
        </Grid>
        <Grid item xs={6} sx={styles.fieldValueContainer}>
          <TextField
            id="spreadFrom"
            fullWidth
            error={spreadFromError || validation(
              spreadFrom,
              noWhiteSpace,
            )}
            helperText={(spreadFromError || validation(
              spreadFrom,
              noWhiteSpace
            )) && t('NO_WHITE_SPACE_HELPER_TEXT')}
            name="spreadFrom"
            onChange={(e) => { setSpreadFrom(e.target.value); setSpreadFromError(false); }}
            sx={styles.textField}
            value={spreadFrom || ''}
            variant="outlined"
            disabled={isSpreadFieldsNotEmpty() || spreadSuccess}
            required
          />
        </Grid>
      </Grid>
      <Grid container sx={styles.field}>
        <Grid item xs={6} sx={styles.fieldLabelContainer}>
          <Box sx={styles.labelIconPlaceholder} />
          <Typography sx={styles.fieldLabel}>{ready && t('INVOICE_EDIT_FORM_SPREAD_TO')}</Typography>
        </Grid>
        <Grid item xs={6} sx={styles.fieldValueContainer}>
          <TextField
            id="spreadTo"
            fullWidth
            error={spreadToError || validation(
              spreadTo,
              noWhiteSpace
            )}
            helperText={(spreadToError || validation(
              spreadTo,
              noWhiteSpace
            )) && t('NO_WHITE_SPACE_HELPER_TEXT')}
            name="spreadTo"
            onChange={(e) => { setSpreadTo(e.target.value); setSpreadToError(false); }}
            sx={styles.textField}
            value={spreadTo || ''}
            variant="outlined"
            disabled={isSpreadFieldsNotEmpty() || spreadSuccess}
            required
          />
        </Grid>
      </Grid>
      <Grid container sx={styles.field}>
        <Grid item xs={6} sx={styles.fieldLabelContainer}>
          <Box sx={styles.labelIconPlaceholder} />
          <Typography sx={styles.fieldLabel}>{ready && t('INVOICE_EDIT_FORM_SPREAD_GLA')}</Typography>
        </Grid>
        <Grid item xs={6} sx={styles.fieldValueContainer}>
          <Autocomplete
            id="glaSpread"
            open={glaSpreadOpen && !validation(
              glaSpread?.code,
              noWhiteSpace
            )}
            onOpen={() => setGlaSpreadOpen(true)}
            onClose={() => setGlaSpreadOpen(false)}
            fullWidth
            onChange={(e, v) => handleGlaSpreadChange(e, v)}
            value={glaSpread}
            options={glAccounts}
            getOptionLabel={(option) => (option && option.code ? `${option.code}${option.name ? ` | ${option.name}` : ''}` : option.code)}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            disabled={isSpreadFieldsNotEmpty() || spreadSuccess}
            sx={styles.autocomplete}
            renderInput={(params) => (
              <TextField
                required
                error={spreadGlaError || validation(
                  glaSpread?.code,
                  noWhiteSpace
                )}
                helperText={(spreadGlaError || validation(
                  glaSpread?.code,
                  noWhiteSpace
                )) && t('NO_WHITE_SPACE_HELPER_TEXT')}
                {...params}
                variant="outlined"
                InputProps={{
                  ...params.InputProps
                }}
                sx={styles.textField}
              />
            )}
          />
        </Grid>
      </Grid>
      {(!isSpreadFieldsNotEmpty() && !spreadSuccess) && (
        <Grid style={styles.spreadInvoiceContainer}>
          <Button
            variant="outlined"
            style={styles.confirmSpreadBtn}
            onClick={submitSpreadInfo}
            disabled={spreadInfoSubmitting}
          >
            {ready && t('INVOICE_EDIT_FORM_SPREAD_CONFIRM')}
          </Button>
        </Grid>
      )}
    </>
  );
};

export default SpreadInvoice;
