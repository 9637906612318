import theme from "src/themeNew";

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0',
    margin: '0px',
    '& .MuiDialog-paper': {
      margin: '0px',
      borderRadius: '0',
    },
    '& .MuiPaper-root': {
      height: '100%',
      maxHeight: '100%',
    }
  },
  container: {
    height: '100%',
    width: '370px',
    display: 'flex',
    flexDirection: 'column'
  },
  modalHeader: {
    height: '72px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderBottom: `1px solid ${theme.palette.border.header}`,
    padding: '16px',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerTitle: {
    fontSize: '18px',
    fontWeight: '500',
    color: theme.palette.text.primary,
    lineHeight: '28px',
    letter: '-0.25px',
  },
  closeBtn: {
    padding: '0px',
  },
  closeIcon: {
    height: '18px',
    width: '18px',
    color: theme.palette.text.secondary,
  },
  modalContent: {
    flex: 1,
    padding: '20px 24px',
  },
  newFieldsChipContainer: {
    flex: 1,
    padding: '0 24px 20px 24px',
    minHeight: '108px',
  },
  newFieldsSectionContainer: {
    borderTop: `1px solid ${theme.palette.border.footer}`,
    padding: '12px 0',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  sectionTitle: {
    color: theme.palette.text.secondary,
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '22px',
    textTransform: 'capitalize',
  },
  newFieldsChips: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
  },
  chipBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '32px',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '500px',
    padding: '6px 6px 6px 12px',
    gap: '4px',
  },
  chipText: {
    fontSize: '13px',
    fontWeight: '500',
    color: theme.palette.primary.main,
    lineHeight: '20px',
  },
  chipIconBtn: {
    padding: '2px',
  },
  addNewForm: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  fieldContainer: {
  },
  textField: {
    fontSize:'14px',
    color: theme.palette.text.primary,
    width: '100%',
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background.white,
      padding: '4px !important',
      '& fieldset': {
        border: `1px solid ${theme.palette.border.input} !important`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.border.input} !important`,
      }
    },
  },
  textFieldMulti: {
    fontSize:'14px',
    color: theme.palette.text.primary,
    width: '100%',
    marginBottom: '16px',
    '& .MuiOutlinedInput-input': {
      padding: '8px 12px !important',
    },
    '& .MuiOutlinedInput-root': {
      height: 'unset',
      backgroundColor: theme.palette.background.white,
      padding: '12px 4px !important',
      '& fieldset': {
        border: `1px solid ${theme.palette.border.input} !important`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.border.input} !important`,
      }
    },
  },
  selectStyle: {
    height: '36px',
    width: '100%',
    borderRadius: '4px',
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.border.input} !important`,
    },
  },
  selectItem: {
    fontSize: '14px !important',
    color: theme.palette.text.primary,
  },
  autoComplete: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      padding: '0px 2px 0px 0px !important',
      backgroundColor: theme.palette.background.white,
      '& fieldset': {
        border: `1px solid ${theme.palette.border.input} !important`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.border.input} !important`,
      },
    }
  },
  autoCompleteTextField: {
    '& .MuiOutlinedInput-input': {
      fontSize:'14px',
      color: theme.palette.text.primary,
    }
  },
  modalFooter: {
    height: '102px',
    width: '100%',
    padding: '32px 20px',
    border: `1px solid ${theme.palette.border.footer}`,
    boxShadow: `0px 2px 6px 0px ${theme.palette.boxShadow.primary}`
  },
  formControlLabel: {
    '&.MuiFormControlLabel-root': {
      justifyContent: 'space-between',
      marginLeft: '0px',
    }
  }
}

export default styles;
